import v1 from './v1';
import v2 from './v2';

/**
 * Migrations for the overall "website" object of the calendar site editor.
 */
export default function websiteMigrations(website: $TSFixMe) {
  let newWebsite = { ...website };
  // Version 0 -> 1.
  if (!newWebsite.calendarWebsiteVersion) {
    newWebsite = v1(newWebsite);
  }
  // Version 1 -> 2.
  if (newWebsite.calendarWebsiteVersion === 1) {
    newWebsite = v2(newWebsite);
  }

  return newWebsite;
}
