import { SET_LOCALE } from 'nucleus-widgets';

export const LOAD_LOCALIZED_USER_TEXT =
  'nucleus-guestside-site/localizedUserText/LOAD_LOCALIZED_USER_TEXT';

export function loadLocalizedUserText(locale: any, localizedUserText: any) {
  return {
    type: LOAD_LOCALIZED_USER_TEXT,
    payload: {
      locale,
      localizedUserText
    }
  };
}

/**
 * The reducer for the localized user text.
 */
function localizedUserTextReducer(
  state = {
    localizations: {}
  },
  action: any
) {
  switch (action.type) {
    case SET_LOCALE:
      return {
        ...state,
        currentLocale: action.payload.locale
      };
    case LOAD_LOCALIZED_USER_TEXT:
      return {
        ...state,
        localizations: {
          ...state.localizations,
          [action.payload.locale]: {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            ...state.localizations[action.payload.locale],
            ...action.payload.localizedUserText
          }
        }
      };
    default:
      return state;
  }
}

export { localizedUserTextReducer };
