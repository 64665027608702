import merge from 'lodash/merge';

import { getStyleObjectWithPseudoState } from './getStyleObjectWithPseudoState';
import { convertThemePaletteColor } from '../fields/convertThemePaletteColor';

function getBorderColor({ styleObject, customStyleSettings, colorPalette }: any) {
  const mergedStyleObject = merge({}, styleObject, customStyleSettings);
  if (!mergedStyleObject || !mergedStyleObject.border) {
    return {};
  }
  let { color } = mergedStyleObject.border;
  color = convertThemePaletteColor(colorPalette, color);
  const borderColor = `${color || '#FFFFFF'}`;
  return { borderColor };
}

export function getBorder(styleObject: any, customStyleSettings: any, colorPalette: any) {
  const mergedStyleObject = merge({}, styleObject, customStyleSettings);
  if (!mergedStyleObject || !mergedStyleObject.border) {
    return {};
  }
  const { thickness, borderStyle } = mergedStyleObject.border;
  if (thickness === undefined) {
    return {};
  }
  let { color } = mergedStyleObject.border;
  if (colorPalette && colorPalette[color]) {
    color = colorPalette[color];
  }
  const borderWidth = thickness;
  const borderColor = `${color || '#FFFFFF'}`;

  const borderHoverColor = getBorderColor({
    ...getStyleObjectWithPseudoState(styleObject, customStyleSettings, 'hover'),
    colorPalette
  });

  let object;
  if (color && thickness !== 0) {
    object = {
      borderWidth,
      borderStyle,
      borderColor,
      hover: borderHoverColor
    };
  } else if (thickness === 0) {
    object = {
      borderWidth,
      borderColor,
      // Outlook Compatibility [NUKE-8407] Set border style to none explicitly since thickness is zero
      borderStyle: 'none',
      hover: borderHoverColor
    };
  } else {
    object = {
      borderWidth,
      borderStyle,
      hover: borderHoverColor
    };
  }
  return object;
}
