import merge from 'lodash/merge';
import { WebsiteV8 } from './WebsiteV8';
import { createBlocksPalette } from '../../../utils';
import { NEW_SECTION_THEME_IDS, buildSectionTheme } from '../../../utils';

/**
 * Adds new section themes for each "block" that is generated by the blocks library.
 */
export function addBlocksSectionThemes(website: WebsiteV8) {
  const blocks = website.theme.global.blocks;
  if (!blocks) {
    return website;
  }

  const blocksPalette = createBlocksPalette(blocks.themeColors, blocks.safeMode);
  const newSectionThemes = NEW_SECTION_THEME_IDS.reduce((accumulator, blockName) => {
    return Object.assign(accumulator, {
      [blockName]: buildSectionTheme(blockName, blocksPalette[blockName])
    });
  }, {});

  return merge({}, website, {
    theme: {
      sections: {
        ...website.theme.sections,
        ...newSectionThemes
      }
    }
  });
}
