/**
 * A combineReducers alternative that preserves unknown properties
 *
 * This function behaves like combineReducers from redux but does not remove properties that don't match a reducer.
 *
 * For example,
 *   const f = combineReducers({ a: x => x+1, b: x => x+2 });
 *   f({ a: 0, b: 0, c: 0 });
 * returns
 *   { a: 1, b: 2 }
 * and
 *   const f = combineReducersPreservingUnknownProperties({ a: x => x+1, b: x => x+2 });
 *   f({ a: 0, b: 0, c: 0 });
 * returns
 *   { a: 1, b: 2, c: 0 }
 */
export function combineReducersPreservingUnknownProperties(reducers: any) {
  for (const reducerKey in reducers) {
    if (reducers.hasOwnProperty(reducerKey) && typeof reducers[reducerKey] !== 'function') {
      throw new Error(
        `reducer at key ${reducerKey} has value ${reducers[reducerKey]} which is not a function`
      );
    }
  }

  return function combinedReducer(state = {}, action: any) {
    const newState = {};
    let hasChanged = false;
    for (const reducerKey in reducers) {
      if (reducers.hasOwnProperty(reducerKey)) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newState[reducerKey] = reducers[reducerKey](state[reducerKey], action);
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        if (typeof newState[reducerKey] === 'undefined') {
          throw new Error(
            `reducer at key ${reducerKey} returned undefined for action ${JSON.stringify(action)}`
          );
        }
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        hasChanged = hasChanged || newState[reducerKey] !== state[reducerKey];
      }
    }
    if (!hasChanged) {
      return state;
    }
    for (const key in state) {
      if (state.hasOwnProperty(key) && !newState.hasOwnProperty(key)) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        newState[key] = state[key];
      }
    }
    return newState;
  };
}
