/* eslint react/prop-types: [2, { ignore: ["setFocus", "onChange", "fieldName", "id", "name"] }] */
import React from 'react';

import PropTypes from 'prop-types';

import {
  rgbToHsl,
  hexStringToRgb,
  hslToRgb,
  rgbToHexString,
  rgbColorEqual,
  hslColorEqual
} from './conversions';

/**
Base class of the ColorPicker components.
Allows us to pass in a theme palette of colors to choose from in
addition to the normal color picker.
**/
export class ColorFormBase extends React.Component {
  static displayName = 'ColorFormBase';
  static propTypes = {
    classes: PropTypes.object,
    /** The current color value. Ignored if paletteId is set. */
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        r: PropTypes.number,
        g: PropTypes.number,
        b: PropTypes.number
      }),
      PropTypes.shape({
        h: PropTypes.number,
        s: PropTypes.number,
        l: PropTypes.number
      })
    ]),
    /** The type of color value you are providing. */
    valueType: PropTypes.oneOf(['hsl', 'rgb', 'hex']),
    /** The selected palette color ID, if applicable. */
    paletteId: PropTypes.string,
    /**
     * An array of objects with the following parameters:
     *   name - The localized label to render for this palette color.
     *   id - The unique ID to identify this palette color.
     *   value - The value of this palette color. Should match the valueType of this ColorPicker instance.
     */
    palette: PropTypes.array,
    /** Label for the palette. */
    paletteLabel: PropTypes.node,
    /**
     * Does nothing on initial component mount. This is useful when using a valueType other than hsl and
     * the component needs to respond to a prop change (need hsl to correctly maintain saturation on the ColorPicker)
     */
    internalHsl: PropTypes.object,
    /** What to use for the trigger in large or small sizes */
    triggerItem: PropTypes.oneOf(['box', 'input']),
    /** Label for the form element. */
    label: PropTypes.node,
    /** Whether to open/close the ColorPicker on focus/blur. */
    openWithFocus: PropTypes.bool,
    /**
     * Callback function for onChange. Takes 4 parameters:
     *   fieldName - The fieldName of the field being changed.
     *   newValue - The processed new color value, formatted according to the valueType property.
     *   hslValue - The raw hsl color value. For use in the parent component to maintain the internal hsl value.
     *   paletteId - The selected palette color ID, if one is selected.
     */
    onChange: PropTypes.func,
    /** Callback function for onClick. */
    onClick: PropTypes.func,
    /** Callback function for onFocus. */
    onFocus: PropTypes.func,
    /** Callback function for onBlur. */
    onBlur: PropTypes.func,
    /** The child content to display after the HexColorInput. */
    children: PropTypes.node,
    /** Size of the color saturation square **/
    size: PropTypes.number,
    /** Available Style Keys. */
    style: PropTypes.shape({
      /** Style object applied to the Trigger component. */
      trigger: PropTypes.object,
      /** Style object applied to the HexColorInput component. */
      hexColorInput: PropTypes.object,
      /** Style object applied to the ColorPicker component. */
      colorPicker: PropTypes.object
    })
  };
  static defaultProps = {
    valueType: 'hex',
    maxLength: 7,
    openWithFocus: true,
    allowToggle: false,
    allowMouse: false,
    size: 150
  };

  constructor(props: any) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.getHslValue = this.getHslValue.bind(this);
    this.getValue = this.getValue.bind(this);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { value, valueType, paletteId, palette } = this.props;
    let paletteColor;
    if (paletteId !== undefined && palette) {
      paletteColor = palette.find((color: any) => color.id === value);
    }
    const selectedValue = paletteColor ? paletteColor.value : value;
    this.state = {
      hsl: this.getHslValue(selectedValue, valueType)
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { internalHsl, value, valueType, paletteId, palette } = nextProps;
    if (internalHsl) {
      this.setState({
        hsl: internalHsl
      });
      return;
    }
    let paletteColor;
    if (paletteId !== undefined && palette) {
      paletteColor = palette.find((color: any) => color.id === value);
    }
    const selectedValue = paletteColor ? paletteColor.value : value;
    this.setState({
      hsl: this.getHslValue(selectedValue, valueType)
    });
  }

  onChange(hslValue: any, paletteColorId: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { value, valueType, fieldName, paletteId, onChange } = this.props;
    this.setState({ hsl: hslValue }, () => {
      const newValue = this.getValue(hslValue, valueType);
      switch (valueType) {
        case 'rgb':
          if (!rgbColorEqual(value, newValue) || paletteColorId !== paletteId) {
            onChange(fieldName, newValue, paletteColorId, hslValue);
          }
          break;
        case 'hex':
          if (newValue !== value || paletteColorId !== paletteId) {
            onChange(fieldName, newValue, paletteColorId, hslValue);
          }
          break;
        default:
          if (!hslColorEqual(value, newValue) || paletteColorId !== paletteId) {
            onChange(fieldName, newValue, paletteColorId, hslValue);
          }
      }
    });
  }

  onClick(e: any) {
    if (this.refs.trigger) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleShow' does not exist on type 'Reac... Remove this comment to see the full error message
      this.refs.trigger.handleShow();
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClick' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.props.onClick) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onClick' does not exist on type 'Readonl... Remove this comment to see the full error message
      this.props.onClick(e);
    }
  }

  onFocus(e: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'openWithFocus' does not exist on type 'R... Remove this comment to see the full error message
    if (this.props.openWithFocus && this.refs.trigger) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleShow' does not exist on type 'Reac... Remove this comment to see the full error message
      this.refs.trigger.handleShow();
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFocus' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.props.onFocus) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFocus' does not exist on type 'Readonl... Remove this comment to see the full error message
      this.props.onFocus(e);
    }
  }

  onBlur(e: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'openWithFocus' does not exist on type 'R... Remove this comment to see the full error message
    if (this.props.openWithFocus && this.refs.trigger) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleHide' does not exist on type 'Reac... Remove this comment to see the full error message
      this.refs.trigger.handleHide();
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBlur' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.props.onBlur) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBlur' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.onBlur(e);
    }
  }

  getHslValue(value: any, valueType: any) {
    if (!value) {
      return { h: 0, s: 0, l: 1 };
    }
    switch (valueType) {
      case 'hsl':
        return value;
      case 'rgb':
        return rgbToHsl(value);
      case 'hex':
        return rgbToHsl(hexStringToRgb(value));
      default:
        return value;
    }
  }

  getValue(hslValue: any, valueType: any) {
    if (!hslValue) {
      return null;
    }
    switch (valueType) {
      case 'hsl':
        return hslValue;
      case 'rgb':
        return hslToRgb(hslValue);
      case 'hex':
        return rgbToHexString(hslToRgb(hslValue));
      default:
        return hslValue;
    }
  }
}
