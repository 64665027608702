import React from 'react';

import Style from './Placeholder.less';

type PlaceholderProps = {
  srcImage: string | undefined;
  instUpper: string;
  instLower: string;
};

export const Placeholder = (props: PlaceholderProps): JSX.Element => {
  return (
    <div className={Style.placeholderContainer}>
      <img src={props.srcImage} />
      <div className={Style.instruction1}>{props.instUpper}</div>
      <div className={Style.instruction2}>{props.instLower}</div>
    </div>
  );
};
