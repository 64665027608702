import React, { useState, useEffect, useRef } from 'react';

import { Button } from '@cvent/carina/components/Button';
import { LoadingIcon } from '@cvent/carina/components/LoadingIcon';
import { Textarea } from '@cvent/carina/components/Textarea';
import { ThemeProvider } from '@cvent/carina/components/ThemeProvider/ThemeProvider';
import { getDefaultTheme } from '@cvent/carina/utils/tokens';
import debounce from 'lodash/debounce';
import { connect } from 'react-redux';

import { addComment, preserveDraft } from '../../redux/modules/comments';
import Classes from './CommentSubmit.less';
import { Dispatch } from 'redux';

const isCommentLengthValid = (count: any, maxCount: any) => count > 0 && count <= maxCount;
const isCommentMessageValid = (count: any, maxCount: any) => count >= 0 && count <= maxCount;
export const DEFAULT_MAX_TEXT_COUNT = 500;

const validateResponse = (
  translate: any,
  count: any,
  maxCount: any
): { text: string | React.ReactNode; type?: 'info' | 'error' } => ({
  text: translate('NucleusWidgets_Comments_CharactersLeft__resx', {
    charCount: maxCount - count
  }),
  type: isCommentMessageValid(count, maxCount) ? 'info' : 'error'
});

type CommentSubmitProps = {
  dispatch: Dispatch;
  draft: string;
  isGuest: boolean;
  maxTextCount: number;
  translate: any;
  widgetId: string;
  onCancel: () => void;
  commentEndRef: React.RefObject<any>;
};

const CommentSubmitBase = (props: CommentSubmitProps) => {
  const {
    dispatch,
    maxTextCount = DEFAULT_MAX_TEXT_COUNT,
    widgetId,
    translate,
    draft,
    onCancel,
    commentEndRef
  } = props;
  const [textAreaValue, setTextAreaValue] = useState(draft);
  const [commentSaving, setCommentSaving] = useState(false);
  const textCount = textAreaValue.length;
  const postDisabled = !isCommentLengthValid(textCount, maxTextCount);
  const debounceDraft = debounce(value => dispatch(preserveDraft(widgetId, value)), 500);
  const textBox = useRef<any>(null);

  const submitComment = async () => {
    setCommentSaving(true);
    // @ts-expect-error ts(2554) Expected 3 arguments, but got 4.
    const hasError = await dispatch(addComment(widgetId, textAreaValue, setCommentSaving));
    if (!hasError) {
      dispatch(preserveDraft(widgetId, ''));
      setTextAreaValue('');
      commentEndRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start'
      });
    }
    setCommentSaving(false);
  };
  useEffect(() => {
    if (textBox.current) {
      textBox.current.focus({ preventScroll: true });
    }
    if (draft.length === 0) {
      setTextAreaValue('');
    }
  }, [draft]);

  return (
    <ThemeProvider theme={getDefaultTheme()}>
      <div className={Classes.commentWrapper}>
        <Textarea
          id="comment_textarea"
          testID="comment"
          forwardedRef={textBox}
          hasError={validateResponse(translate, textCount, maxTextCount).type === 'error'}
          onChangeText={(value: any) => {
            setTextAreaValue(value);
            debounceDraft(value);
          }}
          canExceedCharacterLimit
          resizable={false}
          placeholder={translate('NucleusWidgets_Comments_PostCommentTextAreaDescription__resx')}
          value={textAreaValue}
          maxLength={maxTextCount}
          remainingCharactersMessage={(_): string =>
            translate('NucleusWidgets_Comments_CharactersLeft__resx', {
              charCount: maxTextCount - textCount
            })
          }
        />
        <div className={Classes.commentSubmitButtons}>
          <Button
            className={Classes.commentSubmitPost}
            text={!commentSaving && translate('NucleusWidgets_Comments_Post__resx')}
            aria-label={translate('NucleusWidgets_Comments_PostCommentDescription__resx')}
            appearance="filled"
            onClick={submitComment}
            disabled={postDisabled}
            icon={commentSaving ? LoadingIcon : undefined}
            testID={
              isCommentLengthValid(textCount, maxTextCount)
                ? 'post-comment-button-enabled'
                : 'post-comment-button-disabled'
            }
          />
          <Button
            className={Classes.commentSubmitCancel}
            text={translate('NucleusWidgets_Comments_Cancel__resx')}
            aria-label={translate('NucleusWidgets_Comments_Cancel__resx')}
            appearance="lined"
            onClick={() => {
              dispatch(preserveDraft(widgetId, ''));
              if (onCancel) {
                onCancel();
              }
            }}
            testID="cancel-post-button"
          />
        </div>
      </div>
    </ThemeProvider>
  );
};

export const CommentSubmit = connect((state: any, props: any) => {
  const widgetId = props.widgetId;
  const draft = state.comments.drafts?.[widgetId] || '';

  return { draft };
})(CommentSubmitBase);
