import { BlockTypes } from '@cvent/rich-text-renderer';

import { CustomStyleFields } from './CustomStyleFields';
import { Elements, ElementsWithPseudoStates, ElementsWithPseudoStatesV2 } from './Elements';
import { Headers } from './Headers';
import { StyleFields } from './StyleFields';
import { StyleMenuFields } from './StyleMenuFields';

const SharedFields = {
  ...Elements,
  ...StyleMenuFields,
  ...StyleFields,
  ...CustomStyleFields,
  ...Headers,
  baseBlockType: {
    fieldName: 'baseBlockType',
    component: 'SelectField',
    options: {
      label: 'NucleusWidgets_CarinaRichTextEditor_BaseBlockTypeField_Label__resx',
      tooltip: 'NucleusWidgets_CarinaRichTextEditor_BaseBlockTypeField_HelpText__resx',
      optionArray: [
        {
          name: 'P',
          value: BlockTypes.UNSTYLED
        },
        {
          name: 'H1',
          value: BlockTypes.HEADER_ONE
        },
        {
          name: 'H2',
          value: BlockTypes.HEADER_TWO
        },
        {
          name: 'H3',
          value: BlockTypes.HEADER_THREE
        },
        {
          name: 'H4',
          value: BlockTypes.HEADER_FOUR
        },
        {
          name: 'H5',
          value: BlockTypes.HEADER_FIVE
        },
        {
          name: 'H6',
          value: BlockTypes.HEADER_SIX
        }
      ]
    }
  }
};

/**
 * Editor fields config object that includes pseudo state tabs.
 * @file Automatically generated by barrelsby.
 */
const SharedFieldsWithPseudoStates = {
  ...SharedFields,
  ...ElementsWithPseudoStates
};

/**
 * Editor fields config object that includes pseudo-state tabs and pseudo-state style modes.
 */
const SharedFieldsWithPseudoStatesV2 = {
  ...SharedFields,
  ...ElementsWithPseudoStatesV2
};

export { SharedFields, SharedFieldsWithPseudoStates, SharedFieldsWithPseudoStatesV2 };
export * from './CustomStyleFields';
export * from './Elements';
export * from './FieldGroupings';
export * from './Headers';
export * from './ImagePreviewTooltip';
export * from './StyleFields';
export * from './StyleMenuFields';
export * from './getStyleObjectValue';
export * from './BaseField/index';
export * from './Fonts/index';
