import React, { cloneElement } from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

type OwnProps = {
  /** The initial open index for AccordionPanel. Starts at 0. */
  initialOpenIndex?: number;
  /** Determines whether to allow all Accordion panels to be close at the same time. */
  allowCloseAll?: boolean;
  style?: {
    accordion?: any;
    panel?: any;
    header?: any;
    indicatorDown?: any;
    indicatorLeft?: any;
    body?: any;
    content?: any;
  };
  classes?: any;
};

type State = any;

type Props = React.PropsWithChildren<OwnProps & typeof Accordion.defaultProps>;

/**
Accordion
**/
export class Accordion extends React.Component<Props, State> {
  static displayName = 'Accordion';
  static defaultProps = {
    initialOpenIndex: 0,
    allowCloseAll: false
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpenIndexes: React.Children.map(props.children, (child, index) => {
        return index === props.initialOpenIndex;
      })
    };
    this.onToggle = this.onToggle.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (
      this.props.initialOpenIndex !== nextProps.initialOpenIndex ||
      this.props.children !== nextProps.children
    ) {
      this.setState({
        isOpenIndexes: React.Children.map(nextProps.children, (child, index) => {
          return index === nextProps.initialOpenIndex;
        })
      });
    }
  }

  onToggle(selectedIndex: any) {
    return (isOpen: any) => {
      if (isOpen && this.props.allowCloseAll) {
        this.setState({
          isOpenIndexes: React.Children.map(this.props.children, () => false)
        });
        return;
      } else if (!isOpen) {
        this.setState({
          isOpenIndexes: React.Children.map(this.props.children, (child, index) => {
            return index === selectedIndex;
          })
        });
      }
    };
  }

  render() {
    const { classes, style, allowCloseAll } = this.props;
    const children = React.Children.map(this.props.children, (child, index) => {
      const isOpen = this.state.isOpenIndexes[index];
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      return cloneElement(child, {
        isOpen,
        key: 'panel' + index,
        ...resolveTestId(this.props, '-panel-' + index),
        onToggle: this.onToggle(index),
        // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
        classes: Object.assign({}, child.props.classes, classes),
        // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
        style: Object.assign({}, child.props.style, style),
        ariaDisabled: !allowCloseAll && isOpen
      });
    });
    return (
      <div {...resolveTestId(this.props)} {...resolve(this.props, 'accordion')}>
        {children}
      </div>
    );
  }
}
