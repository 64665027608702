import { TEMPLATE_TOP_BOTTOM, IWebsitePage } from '../../renderers';

/**
 * Get all the template (the page that has header and footer) pages given all the pages.
 */
interface ITemplatePages {
  [key: string]: IWebsitePage;
}
export const getTemplatePages = (pages: ITemplatePages) => {
  return Object.keys(pages).reduce<ITemplatePages>((accumulator, key) => {
    const acc: any = accumulator;
    if (pages[key].type === TEMPLATE_TOP_BOTTOM) {
      acc[key] = pages[key];
    }
    return acc;
  }, {});
};
