import {
  SET_QUESTIONS_PATH,
  CREATE_FOLDER,
  SET_FOLDER_QUESTIONS,
  SET_SHOW_ALERT,
  SET_SAVED_FOLDER,
  SET_SAVED_SOURCE,
  SET_SAVED_SEARCH,
  SET_SHOW_SELECTED,
  SET_SAVED_CURRENT_PAGE,
  SET_SAVED_SELECTED_QUESTIONS,
  SET_QUESTION_ARRAY,
  BULK_REPLACE_FOLDERS,
  SET_ALLOWED_QUESTIONS,
  SET_QUESTION_LIBRARY_SELECTED
} from './actions';

const initialState = {
  /**
   * folders: {
   *  folderUUID1: {
   *    name: string,
   *     questions: [
   *       {
   *        id: uuid,
   *        ...
   *       }
   *     ]
   *  } ...
   * } ...
   */
  questionsMap: {},
  path: '',
  folders: {},
  savedSource: 'NucleusWidgets_QuestionLibrarySourceDropdown_resx',
  savedFolder: '',
  savedSearch: '',
  showSelected: false,
  selectedQuestions: [],
  questionArray: [],
  currentPage: 1,
  showAlert: false,
  isQuestionLibrarySelected: true
};

export function questionLibraryReducer(state: any = initialState, action: any) {
  switch (action.type) {
    case SET_ALLOWED_QUESTIONS: {
      return {
        ...state,
        questionsMap: action.payload.questionsMap
      };
    }
    case SET_QUESTIONS_PATH: {
      return {
        ...state,
        path: action.payload.path
      };
    }
    case CREATE_FOLDER: {
      const folders = state.folders;
      return {
        ...state,
        folders: {
          ...folders,
          [action.payload.uuid]: {
            name: action.payload.folderName,
            questions: []
          }
        }
      };
    }
    case SET_SHOW_ALERT: {
      return {
        ...state,
        showAlert: action.payload.shouldShowAlert
      };
    }
    case SET_SAVED_FOLDER: {
      return {
        ...state,
        savedFolder: action.payload.savedFolder
      };
    }
    case SET_SAVED_SEARCH: {
      return {
        ...state,
        savedSearch: action.payload.savedSearch
      };
    }
    case SET_SHOW_SELECTED: {
      return {
        ...state,
        showSelected: action.payload.showSelected
      };
    }
    case SET_SAVED_SELECTED_QUESTIONS: {
      return {
        ...state,
        selectedQuestions: action.payload.selectedQuestions
      };
    }
    case SET_QUESTION_ARRAY: {
      return {
        ...state,
        questionArray: action.payload.questionArray
      };
    }
    case SET_SAVED_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload.currentPage
      };
    }
    case SET_SAVED_SOURCE: {
      return {
        ...state,
        savedSource: action.payload.savedSource
      };
    }
    case SET_FOLDER_QUESTIONS: {
      const folders = state.folders;
      const folderId = action.payload.folderId;
      const newQuestions = action.payload.questionArray;
      return {
        ...state,
        folders: {
          ...folders,
          [folderId]: {
            ...state.folders[folderId],
            questions: newQuestions
          }
        }
      };
    }
    case BULK_REPLACE_FOLDERS: {
      return {
        ...state,
        folders: action.payload.newFolders
      };
    }
    case SET_QUESTION_LIBRARY_SELECTED: {
      return {
        ...state,
        isQuestionLibrarySelected: action.payload.questionLibrarySelected
      };
    }
    default:
      return state;
  }
}
