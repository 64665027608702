import React from 'react';

/**
 * NewCommentIcon
 * Format from carina-icon, until this is officially added as a component
 */
const NewCommentIcon = ({ title }: any): JSX.Element => (
  <svg
    focusable="false"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={'-4 -4 32 32'}
    role="img"
    aria-hidden={!title ?? false}
  >
    {title && <title>{title}</title>}
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2_180)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.3 19H13.2C15.1 19 16.9 18.5 18.6 17.6C21.8 15.9 24 12.8 24 9.5C24 4.2 18.6 0 12 0C5.4 0 0 4.2 0 9.5C0 11.7 0.8 13.9 2.3 15.5L1 20.8C0.8 21.6 1.6 22.3 2.4 21.9L8.2 19H8.3ZM2 9.5C2 11.2 2.6 12.8 3.7 14C4.3 14.2 4.6 14.7 4.4 15.2L3.4 19.1L7.5 17.1C7.7 17 7.9 17 8.1 17H8.3H13.2C14.7 17 16.2 16.6 17.6 15.9C20.3 14.4 22 12 22 9.5C22 5.4 17.6 2 12 2C6.4 2 2 5.4 2 9.5ZM12 6C12.5523 6 13 6.44772 13 7V9H15C15.5523 9 16 9.44772 16 10C16 10.5523 15.5523 11 15 11H13V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V11H9C8.44771 11 8 10.5523 8 10C8 9.44772 8.44771 9 9 9H11V7C11 6.44772 11.4477 6 12 6Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_180">
          <rect width="24" height="22" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </svg>
);

export { NewCommentIcon };
