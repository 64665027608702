import { Component } from 'react';

/**
 * The base class for the Video widgets containing YouTube, Wistia, or Vimeo videos
 */
export class EmbeddedVideoBase extends Component {
  node: any;
  window: any;
  componentDidMount() {
    // This is to make the widget appear on Preview Mode since, this is inside an Iframe
    try {
      const node = this.node;
      if (node) {
        this.window = node && node.ownerDocument.defaultView;
      } else {
        this.window = window;
      }
    } catch (e) {
      this.window = window;
    }
  }
}
