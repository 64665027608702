/**
 * Loads Intl polyfill data for an Chinese based locale.
 * @param {string} locale - the Chinese locale string to use.
 * @param {function} cb - the callback function to execute after loading the locale data.
 */
export default function loadChinese(locale: any, cb: any) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'IntlPolyfill' does not exist on type 'Gl... Remove this comment to see the full error message
  if (!global.IntlPolyfill) {
    cb();
    return;
  }
  const localeTokens = locale.replace('_', '-').split('-');
  switch (localeTokens[1] ? localeTokens[1].toUpperCase() : null) {
    case 'CHT':
    case 'HK':
    case 'TW':
      require.ensure(['intl/locale-data/jsonp/zh-Hant'], require => {
        require('intl/locale-data/jsonp/zh-Hant');
        cb();
      });
      return;
    case 'CN':
    default:
      require.ensure(['intl/locale-data/jsonp/zh-Hans'], require => {
        require('intl/locale-data/jsonp/zh-Hans');
        cb();
      });
  }
}
