/**
 * If the provided `paletteKey` is a theme palette key, return the corresponding theme palette color value.
 * Otherwise, return the `paletteKey`.
 */
export function convertThemePaletteColor(
  themePalette: Record<string, string>,
  paletteKey: string | undefined,
  paletteKeyPrefix?: string
): string | undefined {
  if (!paletteKey) {
    return undefined;
  }

  let color = paletteKey;
  if (themePalette?.[paletteKey]) {
    color = themePalette[paletteKey];
  }

  if (paletteKeyPrefix && themePalette?.[`${paletteKeyPrefix}.${paletteKey}`]) {
    color = themePalette[`${paletteKeyPrefix}.${paletteKey}`];
  }

  return themePalette?.[color] ?? color;
}
