import Logger from '@cvent/nucleus-logging';
import groupBy from 'lodash/groupBy';
import { FetchClient } from '@cvent/fetch';
import { Comment, Reviewer } from '../types/comment';

const LOG = new Logger('nucleus-widgets/clients/CommentsClient');

// We don't have pagination with comments loading yet and backend by default return 100 records.
const RECORD_LIMIT = 100000;

const apiToUiSchema = (comments: any) => {
  return groupBy(comments, 'entityId');
};

const sortCommentsByDate = (comments: Array<Comment>) => {
  comments.sort((a: Comment, b: Comment) => {
    if (a.created > b.created) {
      return 1;
    } else if (a.created < b.created) {
      return -1;
    }
    return 0;
  });
  return comments;
};

export type CommentsClientParams = {
  baseUrl: string;
  appId: string;
  environment: string;
  authorizationToken: string;
  reviewer: Reviewer;
};

export class CommentsClient {
  fetchClient: FetchClient;
  reviewer: Reviewer;

  constructor(private params: CommentsClientParams) {
    this.fetchClient = new FetchClient({
      init: {
        headers: {
          Authorization: params.authorizationToken,
          'Content-Type': 'application/json'
        }
      }
    });

    this.reviewer = params.reviewer;
  }

  async createComment(widgetId: string, commentData: any) {
    const payLoad = {
      text: commentData.text,
      appId: this.params.appId,
      entityId: widgetId,
      active: true,
      reviewer: this.params.reviewer
    };
    const newUrl = new URL(`${this.params.baseUrl}/comments`);
    newUrl.searchParams.append('environment', this.params.environment);

    try {
      const response = await this.fetchClient.post(newUrl.toString(), JSON.stringify(payLoad));
      if (response.status !== 201) {
        throw new Error(`Create comment failed. Status: ${response.status}.`);
      }

      return await response.json();
    } catch (error) {
      LOG.error('Error creating the comment', error);
      throw error;
    }
  }

  async get(widgetIds: string[]) {
    const len = widgetIds.length;
    const filterQuery = widgetIds.map((id: any, index: any) =>
      index + 1 < len ? `(entityId eq '${id}') OR` : `(entityId eq '${id}')`
    );

    // eslint-disable-next-line @typescript-eslint/quotes
    filterQuery.push(`AND (active eq 'true')`);
    const payload = { filter: filterQuery.join(' ') };

    const newUrl = new URL(`${this.params.baseUrl}/apps/${this.params.appId}/comments/filter`);
    newUrl.searchParams.append('environment', this.params.environment);
    newUrl.searchParams.append('limit', RECORD_LIMIT.toString());
    try {
      const response = await this.fetchClient.post(newUrl.toString(), JSON.stringify(payload));
      const body = await response.json();

      if (response.status !== 200) {
        throw new Error(`Load comments failed. Status: ${response.status}`);
      }
      const sortedComments = sortCommentsByDate(body.data);
      return apiToUiSchema(sortedComments);
    } catch (error) {
      LOG.error('Error retriving the comments', error);
      throw error;
    }
  }

  async deleteComment(commentId: string) {
    const newUrl = new URL(`${this.params.baseUrl}/comments/${commentId}`);
    newUrl.searchParams.append('environment', this.params.environment);

    try {
      const response = await this.fetchClient.delete(newUrl.toString());
      if (response.status !== 204) {
        throw new Error(`Delete comment failed. Status: ${response.status}`);
      }
    } catch (error) {
      LOG.error(`Error deleting the comment ${commentId}`, error);
      throw error;
    }
  }

  deleteAllCommentsOnWidgets(widgetIds: any) {
    for (const widgetId of widgetIds) {
      const newUrl = new URL(
        `${this.params.baseUrl}/apps/${this.params.appId}/entities/${widgetId}/comments`
      );
      newUrl.searchParams.append('environment', this.params.environment);
      this.fetchClient.delete(newUrl.toString());
    }
  }

  async resolve(commentId: string, isResolved: boolean) {
    const newUrl = new URL(`${this.params.baseUrl}/comments/${commentId}/resolve`);
    newUrl.searchParams.append('environment', this.params.environment);
    newUrl.searchParams.append('resolved', isResolved.toString());

    try {
      const response = await this.fetchClient.post(newUrl.toString(), '');
      if (response.status !== 204) {
        throw new Error(`Comment resolve/unresolve failed. Status: ${response.status}.`);
      }
    } catch (error) {
      LOG.error(`Error resolving the comment ${commentId}`, error);
      throw error;
    }
  }

  async getAggregateCount(widgetIds?: string[]) {
    const payload = {
      type: 'count',
      entityIds: widgetIds ?? [],
      appIds: [this.params.appId],
      elements: ['app', 'entity']
    };
    const newUrl = new URL(`${this.params.baseUrl}/comments/aggregation`);
    newUrl.searchParams.append('environment', this.params.environment);

    try {
      const response = await this.fetchClient.post(newUrl.toString(), JSON.stringify(payload));
      const body = await response.json();
      if (response.status !== 200) {
        throw new Error(
          `Getting aggregate comments count failed for ${widgetIds}. Status: ${response.status}.`
        );
      }
      return body.items[0]?.count ?? 0;
    } catch (error) {
      LOG.error(`Error getting aggregate comments count for ${widgetIds}`, error);
      throw error;
    }
  }
}
