import isObject from 'lodash/isObject';

// @ts-expect-error ts-migrate(7023) FIXME: 'getSubChanges' implicitly has return type 'any' b... Remove this comment to see the full error message
function getSubChanges(propertyNames: any, newValue: any, rootObject: any) {
  if (!propertyNames.length) {
    return newValue;
  }
  const propertyObject = !isObject(rootObject) ? {} : rootObject;
  const propertyName = propertyNames.shift();
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const originalValue = propertyObject[propertyName];
  if (propertyObject instanceof Array) {
    const arrayCopy = propertyObject.slice(0);
    arrayCopy.splice(
      parseInt(propertyName, 10),
      1,
      getSubChanges(propertyNames, newValue, originalValue)
    );
    return arrayCopy;
  }
  return {
    ...propertyObject,
    [propertyName]: getSubChanges(propertyNames, newValue, originalValue)
  };
}

/**
 * Creates a copy of the provided root object with the value at the specified path
 * replaced with the provided new value. The original root object will not be modified.
 * @param {object} rootObject - The root object the field is contained in.
 * @param {string|array} path - The path to the desired value in the root object.
 *     Can either be a string with keys separated by periods, or an array of keys.
 * @param {any} newValue - The new value to set.
 * @returns - a copy of the rootObject with the specified field changed.
 **/
export function setJSONValue(rootObject = {}, path: any, newValue: any) {
  let keys;
  if (path instanceof Array) {
    keys = path.slice(0);
  } else {
    keys = path.split('.');
  }
  return getSubChanges(keys, newValue, rootObject);
}
