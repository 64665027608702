import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import Icon from '@cvent/nucleus-icon';
import PropTypes from 'prop-types';

/**
 * Renders a list of data passed to the modal.
 * ex: date, time, title, description, location etc.
 **/
const ItemDetail = (props: $TSFixMe) => {
  const { icon, fieldObj, children } = props;
  const { fieldName, fieldType, showFieldName, isAdditionalField } = fieldObj;
  const isIconVisible = icon && icon.isVisible;
  const resolvedStyles: $TSFixMe = isIconVisible ? resolve(props, 'smallIcon') : {};

  return (
    <div {...resolve(props, 'fieldRow', !isAdditionalField ? fieldType : null)}>
      {showFieldName && <h6 {...resolve(props, 'fieldName')}>{fieldName}</h6>}
      <div
        {...resolve(
          props,
          isAdditionalField ? fieldType : null,
          // eslint-disable-next-line react/prop-types
          isIconVisible ? icon.className : null
        )}
      >
        {isIconVisible && (
          <Icon modifier={resolvedStyles.className} style={resolvedStyles.style} icon={icon.imageName} fallbackText={icon.fallbackText} />
        )}
        {children}
      </div>
    </div>
  );
};

ItemDetail.propTypes = {
  icon: PropTypes.shape({
    isVisible: PropTypes.bool,
    imageName: PropTypes.string,
    fallbackText: PropTypes.string,
    className: PropTypes.string
  }),
  fieldObj: PropTypes.shape({
    fieldName: PropTypes.string,
    isAdditionalField: PropTypes.bool,
    showFieldName: PropTypes.bool,
    fieldType: PropTypes.string
  }).isRequired,
  children: PropTypes.element,
  dateTimeFormat: PropTypes.object,
  classes: PropTypes.object,
  style: PropTypes.shape({
    fieldName: PropTypes.object
  })
};

ItemDetail.displayName = 'ItemDetail';

export default ItemDetail;
