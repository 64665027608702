import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';

import { FeedbackMessage } from './FeedbackMessage';

type Props = {
  type?: 'success' | 'warning' | 'error';
  style?: {
    container?: any;
    successArrow?: any;
    warningArrow?: any;
    errorArrow?: any;
  };
  classes?: any;
};

/**
Composes the FeedbackMessage component with Cvent specific styles
to mimic guest side inline validation/success messages.
**/
const InlineMessage = (props: Props) => {
  const { type = 'success', classes, style } = props;
  const newClasses = !classes ? undefined : Object.assign({}, classes, { message: classes[type] });
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const newStyles = !style ? undefined : Object.assign({}, style, { message: style[type] });
  return (
    <div {...resolve(props, 'container')}>
      <span {...resolve(props, type + 'Arrow')}></span>
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '({ container?: any; successArrow?: any; warn... Remove this comment to see the full error message */}
      <FeedbackMessage {...props} classes={newClasses} style={newStyles} />
    </div>
  );
};
InlineMessage.displayName = 'InlineMessage';

export { InlineMessage };
