import { css } from '@emotion/css';

/**
 * Because pseudo classes can't be set in inline styles,
 * we use emotion.js to convert the style object on the `pseudoState` property, such as `hover`, into CSS,
 * and add it to the <style> tag added by emotion.js inside <head>,
 * then replace the value of the pseudoState property with the CSS selector (a string) returned by emotion.js.
 *
 * @param {object} styles
 */
export function convertToPseudoClasses({
  styles,
  isSelectedItem,
  pseudoState,
  key,
  currentElementKey,
  styleMapping
}: any) {
  if (!styles || !styles.hasOwnProperty('hover') || Object.keys(styles.hover).length === 0) {
    return styles;
  }
  const pseudoStyles = styles.hover;
  const newPseudoStyles = {};
  Object.keys(pseudoStyles).forEach(styleKey => {
    if (
      !pseudoStyles[styleKey] ||
      // When pseudo state style is the same as the default state, there's no need to
      // add the pseudo state style.
      (styles[styleKey] && styles[styleKey] === pseudoStyles[styleKey])
    ) {
      return;
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    newPseudoStyles[styleKey] = `${pseudoStyles[styleKey]} !important`;
  });

  let newStyles = {
    ...styles,
    hover: css({
      '&:hover, &.hover': newPseudoStyles
    })
  };

  if (
    pseudoState === 'hover' &&
    (currentElementKey === styleMapping || (isSelectedItem && currentElementKey === key))
  ) {
    newStyles = {
      ...newStyles,
      hover: `${newStyles.hover} hover`
    };
  }

  return newStyles;
}
