import React from 'react';

import flatten from 'lodash/flatten';
import map from 'lodash/map';

import {
  formatDateToString,
  getLocaleDateString,
  DATE_CHANGE_SUCCESS,
  START_DATE_CHANGED,
  END_DATE_CHANGED,
  DATE_CHANGE_ERROR_INVALID,
  EMPTY_DATE
} from '@cvent/nucleus-core-datetime-utils';
import { select, resolve } from '@cvent/nucleus-dynamic-css';
import Icon from '@cvent/nucleus-icon';
import { DateUtils } from 'nucleus-react-day-picker';
import PropTypes from 'prop-types';

import { Trigger, removeTriggerProps } from '../../containers/Trigger';
import { Caption } from '../../daypicker/Caption';
import {
  NucleusDayPicker,
  removeDayPickerProps as removeCalendarProps
} from '../../daypicker/DayPicker';
import { isRequiredIf } from '../../utils/isRequiredIf';
import { isTouchEnabled } from '../../utils/isTouchEnabled';
import { removeKeys } from '../../utils/removeKeys';
import { FormElement, removeFormElementProps } from '../FormElement';
import { PickADateBase } from '../PickADateBase';
import { TextboxFormElement as Textbox } from './Textbox';

function getLastValidInitialMonth(
  value: any,
  defaultValue: any,
  minDate: any,
  maxDate: any,
  openToMaxDate: any
) {
  let initialCalendarDate = new Date();
  const [startDate = null, endDate = null] = value;

  if (startDate && startDate instanceof Date) {
    initialCalendarDate = startDate;
  } else if (endDate && endDate instanceof Date && endDate.getMonth() !== defaultValue.getMonth()) {
    const newDate = new Date(endDate.getTime());
    newDate.setMonth(endDate.getMonth() - 1);
    initialCalendarDate = newDate;
  } else if (
    (minDate && !maxDate && minDate <= defaultValue) ||
    (maxDate && !minDate && defaultValue <= maxDate) ||
    (maxDate && minDate && minDate <= defaultValue && defaultValue <= maxDate)
  ) {
    initialCalendarDate = defaultValue;
  } else if (openToMaxDate && maxDate) {
    const newDate = new Date(maxDate.getTime());
    newDate.setMonth(maxDate.getMonth() - 1);
    initialCalendarDate = newDate;
  } else if (minDate) {
    initialCalendarDate = minDate;
  } else if (maxDate) {
    const newDate = new Date(maxDate.getTime());
    newDate.setMonth(maxDate.getMonth() - 1);
    initialCalendarDate = newDate;
  }
  return new Date(initialCalendarDate.getFullYear(), initialCalendarDate.getMonth(), 1);
}

/**
Display a DateRange picker form element composed of Trigger, Textbox, and Calendar components.

onChange = function(fieldName, newValue, textValue, statusCode, dateName)
**/

class DateRange extends PickADateBase {
  static propTypes = {
    ...PickADateBase.propTypes,
    // Array contains start and end dates
    value: PropTypes.array,
    /** Label for start date input box. Should exist if translate is not provided. */
    startDateLabel: isRequiredIf(
      PropTypes.string,
      (props: any) => !props.hasOwnProperty('translate')
    ),
    /** Label for end date input box. Should exist if translate is not provided. */
    endDateLabel: isRequiredIf(
      PropTypes.string,
      (props: any) => !props.hasOwnProperty('translate')
    ),
    /** Default text resource id for start date input box's label */
    startDateLabelTextResourceId: PropTypes.string,
    /** Default text resource id for end date input box's label */
    endDateLabelTextResourceId: PropTypes.string,
    /** Placeholder text for start date input box */
    startDatePlaceholder: PropTypes.string,
    /** Placeholder text for end date input box */
    endDatePlaceholder: PropTypes.string,
    /** Available Style Keys */
    style: PropTypes.shape({
      /** Style object applied to the Textbox component. */
      textbox: PropTypes.object,
      /** Style object applied to the Trigger component. */
      trigger: PropTypes.object,
      /** Style object applied to the DayPicker component. */
      calendar: PropTypes.object
    }),
    /** Function to localize text with given text resource id */
    translate: isRequiredIf(
      PropTypes.func,
      (props: any) =>
        !props.hasOwnProperty('navButtonPreviousMonthLabel') ||
        !props.hasOwnProperty('navButtonNextMonthLabel') ||
        !props.hasOwnProperty('startDateLabel') ||
        !props.hasOwnProperty('endDateLabel')
    ),
    /** Flag to make the maxDate have priority over minDate when determining which month the flyout should open to */
    openToMaxDate: PropTypes.bool,
    /** Flag to show or hide calendar icons. Defaults to false */
    showIcon: PropTypes.bool,
    /** Calendar icon name. Defaults to "scheduleFilled" */
    calendarIcon: PropTypes.string,
    /** A footer shown at the bottom of calendar flyout */
    calendarFooter: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /** A callback when the calendar flyout closes */
    onClose: PropTypes.func
  };

  static defaultProps = {
    ...PickADateBase.defaultProps,
    startDateLabelTextResourceId: 'NucleusCoreComponent_DateRange_StartDateLabel__resx',
    endDateLabelTextResourceId: 'NucleusCoreComponent_DateRange_EndDateLabel__resx',
    showIcon: false,
    openToMaxDate: false,
    calendarIcon: 'scheduleFilled'
  };

  _userIsTypingEndDate: any;
  _userIsTypingStartDate: any;
  endDateTextbox: any;
  startDateTextbox: any;

  constructor(props: any) {
    super(props);

    this.onDayMouseEnter = this.onDayMouseEnter.bind(this);
    this.getSelectedDays = this.getSelectedDays.bind(this);
    this.onFocusStartDate = this.onFocusStartDate.bind(this);
    this.onFocusEndDate = this.onFocusEndDate.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onBlurStartDate = this.onBlurStartDate.bind(this);
    this.onBlurEndDate = this.onBlurEndDate.bind(this);

    const { value, minDate, maxDate, openToMaxDate } = this.props;
    const [startDate = null, endDate = null] = value;
    this.state = {
      startDateTextInput: this.getStringFromDate(startDate),
      endDateTextInput: this.getStringFromDate(endDate),
      touchEnabled: isTouchEnabled(),
      lastValidInitialMonth: getLastValidInitialMonth(
        value,
        new Date(),
        minDate,
        maxDate,
        openToMaxDate
      ),
      possibleEdgeDate: null,
      isEditingStartDate: false,
      isEditingEndDate: false,
      startDateStatusCode: null,
      endDateStatusCode: null
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { value, minDate, maxDate, openToMaxDate } = nextProps;
    const newState = {
      lastValidInitialMonth: getLastValidInitialMonth(
        value,
        this.state.lastValidInitialMonth,
        minDate,
        maxDate,
        openToMaxDate
      )
    };

    const [startDate = null, endDate = null] = value;
    const { isEditingStartDate, isEditingEndDate, startDateStatusCode, endDateStatusCode } =
      this.state;
    if (
      !this._userIsTypingStartDate &&
      (startDateStatusCode !== DATE_CHANGE_ERROR_INVALID ||
        isEditingStartDate ||
        startDate === EMPTY_DATE)
    ) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'startDateTextInput' does not exist on ty... Remove this comment to see the full error message
      newState.startDateTextInput = this.getStringFromDate(startDate);
    }
    if (
      !this._userIsTypingEndDate &&
      (endDateStatusCode !== DATE_CHANGE_ERROR_INVALID ||
        isEditingEndDate ||
        endDate === EMPTY_DATE)
    ) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'endDateTextInput' does not exist on type... Remove this comment to see the full error message
      newState.endDateTextInput = this.getStringFromDate(endDate);
    }
    this.setState(newState);
  }

  onFocusStartDate(event: any) {
    const { startDateTextInput = null } = this.state;
    let { lastValidInitialMonth } = this.state;
    if (startDateTextInput) {
      const format =
        this.props.format || getLocaleDateString(this.props.locale, this.props.formatOptions);
      lastValidInitialMonth = getLastValidInitialMonth(
        [this.props.parseDate(startDateTextInput, format)],
        new Date(),
        this.props.minDate,
        this.props.maxDate,
        this.props.openToMaxDate
      );
    }
    this.onFocus(event);
    // Open native date picker on mobile by clicking on the icon
    if (this.startDateTextbox) {
      this.startDateTextbox.focus();
    }
    this.setState({
      isEditingStartDate: true,
      isEditingEndDate: false,
      lastValidInitialMonth
    });
  }

  onFocusEndDate(event: any) {
    const { endDateTextInput = null } = this.state;
    let { lastValidInitialMonth } = this.state;
    this.onFocus(event);
    // Open native date picker on mobile by clicking on the icon
    if (this.endDateTextbox) {
      this.endDateTextbox.focus();
    }
    if (endDateTextInput) {
      const format =
        this.props.format || getLocaleDateString(this.props.locale, this.props.formatOptions);
      const endDate = this.props.parseDate(endDateTextInput, format);
      lastValidInitialMonth = getLastValidInitialMonth(
        [null, endDate],
        new Date(),
        this.props.minDate,
        this.props.maxDate,
        this.props.openToMaxDate
      );
    }
    this.setState({
      isEditingStartDate: false,
      isEditingEndDate: true,
      lastValidInitialMonth
    });
  }

  onBlur(e: any) {
    this._userIsTypingStartDate = false;
    this._userIsTypingEndDate = false;
    this.onLoseFocus();
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  // Remove highlight on mobile device's native date picker
  onBlurStartDate(e: any) {
    this.setState({
      isEditingStartDate: false
    });
    this.onBlur(e);
  }

  // Remove highlight on mobile device's native date picker
  onBlurEndDate(e: any) {
    this.setState({
      isEditingEndDate: false
    });
    this.onBlur(e);
  }

  onClose() {
    this.setState({
      isEditingStartDate: false,
      isEditingEndDate: false
    });
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  /* @ts-expect-error Class 'PickADateBase' defines instance member property 'onCalendarClick', but extended class 'DateRange' defines it as instance member function.  */
  onCalendarClick(e: any, day: any, { disabled }: any) {
    this._userIsTypingStartDate = false;
    this._userIsTypingEndDate = false;
    if (disabled) {
      return;
    }
    const dateString = this.getStringFromDate(day);
    const { isEditingStartDate, isEditingEndDate } = this.state;
    const { fieldName, value } = this.props;
    const [startDate, endDate] = this.getDates(value);

    // Update start date
    if (isEditingStartDate && day <= endDate) {
      this.setState({
        isEditingStartDate: false,
        isEditingEndDate: true,
        startDateStatusCode: DATE_CHANGE_SUCCESS
      });
      if (this.props.hasOwnProperty('onNativeChange')) {
        this.props.onNativeChange({
          value: [day, endDate],
          textValue: dateString,
          statusCode: DATE_CHANGE_SUCCESS,
          dateChanged: START_DATE_CHANGED
        });
        return;
      }
      this.props.onChange(
        fieldName,
        [day, endDate],
        dateString,
        DATE_CHANGE_SUCCESS,
        START_DATE_CHANGED
      );
    } else if (isEditingStartDate && day > endDate) {
      // Update start date, and clear end date
      this.setState({
        isEditingStartDate: false,
        isEditingEndDate: true,
        temporaryDate: null,
        endDateStatusCode: DATE_CHANGE_SUCCESS
      });
      if (this.props.hasOwnProperty('onNativeChange')) {
        this.props.onNativeChange({
          value: [day, null],
          textValue: dateString,
          statusCode: DATE_CHANGE_SUCCESS,
          dateChanged: END_DATE_CHANGED
        });
        return;
      }
      this.props.onChange(
        fieldName,
        [day, null],
        dateString,
        DATE_CHANGE_SUCCESS,
        START_DATE_CHANGED
      );
    } else if (day >= startDate && isEditingEndDate) {
      // Update end date and close flyout
      this.setState({
        isEditingStartDate: false,
        isEditingEndDate: false,
        temporaryDate: null,
        endDateStatusCode: DATE_CHANGE_SUCCESS
      });
      if (this.props.hasOwnProperty('onNativeChange')) {
        this.props.onNativeChange({
          value: [startDate, day],
          textValue: dateString,
          statusCode: DATE_CHANGE_SUCCESS,
          dateChanged: END_DATE_CHANGED
        });
        // Close calendar flyout
        if (this.trigger) {
          this.trigger.handleHide();
        }
        return;
      }
      this.props.onChange(
        fieldName,
        [startDate, day],
        dateString,
        DATE_CHANGE_SUCCESS,
        END_DATE_CHANGED
      );
      // Close calendar flyout
      if (this.trigger) {
        this.trigger.handleHide();
      }
    } else if (isEditingEndDate && day < startDate) {
      // Update start date, and clear end date
      this.setState({
        temporaryDate: null,
        startDateStatusCode: DATE_CHANGE_SUCCESS
      });
      if (this.props.hasOwnProperty('onNativeChange')) {
        this.props.onNativeChange({
          value: [day, null],
          textValue: dateString,
          statusCode: DATE_CHANGE_SUCCESS,
          dateChanged: START_DATE_CHANGED
        });
        return;
      }
      this.props.onChange(
        fieldName,
        [day, null],
        dateString,
        DATE_CHANGE_SUCCESS,
        START_DATE_CHANGED
      );
    }
  }

  onDayMouseEnter(e: any, day: any) {
    const [startDate, endDate] = this.getDates(this.props.value);
    if (!startDate || !endDate) {
      this.setState({
        temporaryDate: day
      });
      return;
    }
  }

  getSelectedDays(day: any) {
    const [startDate, endDate] = this.getDates(this.props.value);
    const { temporaryDate } = this.state;
    if (startDate && endDate && startDate <= endDate) {
      return DateUtils.isDayInRange(day, { from: startDate, to: endDate });
    } else if (startDate && !endDate) {
      return DateUtils.isDayInRange(day, { from: startDate, to: temporaryDate });
    } else if (!startDate && endDate) {
      DateUtils.isDayInRange(day, { from: temporaryDate, to: endDate });
    }
  }

  getTextBoxStyles(styleName: any, isEditing: any, validationState: any) {
    const textboxStyles = select(this.props, 'textbox');
    const styleKey = styleName === 'classes' ? 'className' : 'style';
    return {
      ...textboxStyles[styleName],
      textbox: resolve(
        textboxStyles,
        'textbox',
        isEditing ? 'textboxHighlighted' : null,
        validationState
      )[styleKey]
    };
  }

  getDates(value: any) {
    let [startDate = null, endDate = null] = value;
    startDate = startDate === EMPTY_DATE ? null : startDate;
    endDate = endDate === EMPTY_DATE ? null : endDate;
    return [startDate, endDate];
  }

  render() {
    const {
      minDate,
      maxDate,
      value,
      fieldName,
      startDateLabel,
      endDateLabel,
      validationState,
      startDatePlaceholder,
      endDatePlaceholder,
      navButtonNextMonthLabel,
      navButtonPreviousMonthLabel,
      navButtonPreviousMonthLabelTextResourceId,
      navButtonNextMonthLabelTextResourceId,
      translate,
      startDateLabelTextResourceId,
      endDateLabelTextResourceId,
      showIcon,
      calendarIcon,
      calendarFooter,
      modifiers,
      ...rest
    } = removeKeys(this.props, [
      'format',
      'formatDate',
      'parseDate',
      'locale',
      'formatOptions',
      'navButtonNextMonthElement',
      'navButtonPreviousMonthElement'
    ]);
    const { isEditingStartDate, isEditingEndDate } = this.state;
    const { disabled, readOnly } = rest;
    const [startDate, endDate] = this.getDates(value);
    const allModifiers = {
      startDate: (day: any) => DateUtils.isSameDay(day, startDate),
      endDate: (day: any) => DateUtils.isSameDay(day, endDate),
      ...modifiers
    };

    const dateType = this.checkForDateType();
    let textboxProps = removeTriggerProps(removeCalendarProps(rest));
    if (this.props.hasOwnProperty('onNativeChange')) {
      // this is necessary! Because Textbox also suports NucleusField, if we do not remove onNativeChange from props,
      // this.onChange/onChangeMobile will not be called properly
      delete textboxProps.onNativeChange;
    }
    textboxProps = removeKeys(textboxProps, ['errorMessages']);
    const formatString = getLocaleDateString(this.props.locale, this.props.formatOptions);
    const localizedFormatString = translate
      ? translate(
          `NucleusCoreComponent_PickADate_TextboxPlaceholder_${formatString}__resx`,
          undefined,
          () => formatString
        )
      : formatString;
    if (dateType === 'date' && this.state.touchEnabled === true && this.isMobile()) {
      // The icon is for sight users to add visual hint, and it's intentional not to make it focusable.
      return (
        <div>
          <Textbox
            // @ts-expect-error 'children' are specified twice
            {...textboxProps}
            ref={(c: any) => (this.startDateTextbox = c)}
            // @ts-expect-error No overload matches this call.
            classes={this.getTextBoxStyles(
              'classes',
              isEditingStartDate,
              validationState.startDate
            )}
            style={this.getTextBoxStyles('style', isEditingStartDate, validationState.startDate)}
            min={minDate ? formatDateToString(minDate, 'yy-mm-dd') : undefined}
            max={maxDate ? formatDateToString(maxDate, 'yy-mm-dd') : undefined}
            value={
              startDate && startDate instanceof Date
                ? formatDateToString(startDate, 'yy-mm-dd')
                : ''
            }
            label={startDateLabel || (translate ? translate(startDateLabelTextResourceId) : '')}
            fieldName={`${fieldName}StartDate`}
            onChange={(textBoxFieldName: any, newValue: any) =>
              this.onChangeMobile(textBoxFieldName, newValue, START_DATE_CHANGED)
            }
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocusStartDate}
            onBlur={this.onBlurStartDate}
            type={dateType}
            placeholder={startDatePlaceholder || localizedFormatString}
            validationState={validationState.startDate}
            children={undefined}
          >
            {showIcon && (
              <span {...resolve(this.props, 'iconWrapper')} onClick={this.onFocusStartDate}>
                <Icon icon={calendarIcon} />
              </span>
            )}
          </Textbox>
          <span {...resolve(this.props, 'dash')}>&mdash;</span>
          <Textbox
            // @ts-expect-error 'children' are specified twice
            {...textboxProps}
            ref={(c: any) => (this.endDateTextbox = c)}
            // @ts-expect-error No overload matches this call.
            classes={this.getTextBoxStyles('classes', isEditingEndDate, validationState.endDate)}
            style={this.getTextBoxStyles('style', isEditingEndDate, validationState.endDate)}
            min={minDate ? formatDateToString(minDate, 'yy-mm-dd') : undefined}
            max={maxDate ? formatDateToString(maxDate, 'yy-mm-dd') : undefined}
            value={
              endDate && endDate instanceof Date ? formatDateToString(endDate, 'yy-mm-dd') : ''
            }
            label={endDateLabel || (translate ? translate(endDateLabelTextResourceId) : '')}
            fieldName={`${fieldName}EndDate`}
            onChange={(textBoxFieldName: any, newValue: any) =>
              this.onChangeMobile(textBoxFieldName, newValue, END_DATE_CHANGED)
            }
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocusEndDate}
            onBlur={this.onBlurEndDate}
            type={dateType}
            placeholder={endDatePlaceholder || localizedFormatString}
            validationState={validationState.endDate}
            children={undefined}
          >
            {showIcon && (
              <span {...resolve(this.props, 'iconWrapper')} onClick={this.onFocusEndDate}>
                <Icon icon={calendarIcon} />
              </span>
            )}
          </Textbox>
        </div>
      );
    }

    // The icon is for sight users to add visual hint, and it's intentional not to make it focusable.
    const triggerElement = (
      <div>
        <Textbox
          // @ts-expect-error 'children' are specified twice
          {...textboxProps}
          // @ts-expect-error No overload matches this call.
          classes={this.getTextBoxStyles('classes', isEditingStartDate, validationState.startDate)}
          style={this.getTextBoxStyles('style', isEditingStartDate, validationState.startDate)}
          label={startDateLabel || (translate ? translate(startDateLabelTextResourceId) : '')}
          value={this.state.startDateTextInput || ''}
          onChange={(textBoxFieldName: any, newValue: any) =>
            this.onChange(textBoxFieldName, newValue, START_DATE_CHANGED)
          }
          onKeyDown={this.onKeyDown}
          onFocus={this.onFocusStartDate}
          onBlur={this.onBlur}
          fieldName={`${fieldName}StartDate`}
          validationState={validationState.startDate}
          placeholder={startDatePlaceholder || localizedFormatString}
          children={undefined}
        >
          {showIcon && (
            <span {...resolve(this.props, 'iconWrapper')} onClick={this.onFocusStartDate}>
              <Icon icon={calendarIcon} />
            </span>
          )}
        </Textbox>
        <span {...resolve(this.props, 'dash')}>&mdash;</span>
        <Textbox
          // @ts-expect-error 'children' are specified twice
          {...textboxProps}
          // @ts-expect-error No overload matches this call.
          classes={this.getTextBoxStyles('classes', isEditingEndDate, validationState.endDate)}
          style={this.getTextBoxStyles('style', isEditingEndDate, validationState.endDate)}
          label={endDateLabel || (translate ? translate(endDateLabelTextResourceId) : '')}
          value={this.state.endDateTextInput || ''}
          onChange={(textBoxFieldName: any, newValue: any) =>
            this.onChange(textBoxFieldName, newValue, END_DATE_CHANGED)
          }
          onKeyDown={this.onKeyDown}
          onFocus={this.onFocusEndDate}
          onBlur={this.onBlur}
          fieldName={`${fieldName}EndDate`}
          validationState={validationState.endDate}
          placeholder={endDatePlaceholder || localizedFormatString}
          children={undefined}
          tabIndex={-1}
        >
          {showIcon && (
            <span {...resolve(this.props, 'iconWrapper')} onClick={this.onFocusEndDate}>
              <Icon icon={calendarIcon} />
            </span>
          )}
        </Textbox>
      </div>
    );
    // If the input is disabled or readOnly, do not render the trigger or calendar components.
    if (disabled || readOnly) {
      return triggerElement;
    }

    const captionElement = <Caption yearFirst={this.isYearFirst()} />;

    const resolvedProps = removeKeys(removeFormElementProps(removeTriggerProps(this.props)), [
      'minDate',
      'maxDate',
      'openToMaxDate',
      'formatDate',
      'formatOptions',
      'dateStringFormat',
      'displayValid',
      'parseDate',
      'onChange',
      'startDateLabel',
      'endDateLabel',
      'validationState',
      'translate',
      'navButtonNextMonthLabelTextResourceId',
      'navButtonPreviousMonthLabelTextResourceId',
      'startDateLabelTextResourceId',
      'endDateLabelTextResourceId',
      'showIcon',
      'calendarIcon',
      'calendarFooter',
      'startDatePlaceholder',
      'endDatePlaceholder',
      'value'
    ]);

    let dayPicker = (
      // @ts-expect-error ts-migrate(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message
      <NucleusDayPicker
        {...resolvedProps}
        {...select(this.props, 'calendar')}
        onKeyDown={this.props.onKeyDown}
        selectedDays={this.getSelectedDays}
        onDayFocus={this.onDayMouseEnter}
        modifiers={allModifiers}
        onDayClick={this.onCalendarClick}
        onDayMouseEnter={this.onDayMouseEnter}
        disabledDays={(day: any) => this.isDisabledDays(day, minDate, maxDate)}
        initialMonth={this.state.lastValidInitialMonth}
        fromMonth={minDate}
        toMonth={maxDate}
        numberOfMonths={2}
        navButtonNextMonthLabel={
          navButtonNextMonthLabel ||
          (translate ? translate(navButtonNextMonthLabelTextResourceId) : '')
        }
        navButtonPreviousMonthLabel={
          navButtonPreviousMonthLabel ||
          (translate ? translate(navButtonPreviousMonthLabelTextResourceId) : '')
        }
        tabIndex={-1}
        captionElement={captionElement}
      />
    );
    if (calendarFooter) {
      dayPicker = (
        <div {...resolve(this.props, 'calendarFlyout')}>
          {dayPicker}
          {calendarFooter}
        </div>
      );
    }
    return (
      <div
        ref={el => {
          this.calendarWrapper = el;
        }}
        onKeyDown={this.onKeyDownCalendar}
      >
        <Trigger
          {...this.props}
          {...select(this.props, 'trigger')}
          ref={c => (this.trigger = c)}
          onClose={this.onClose}
        >
          {triggerElement}
          {dayPicker}
        </Trigger>
      </div>
    );
  }
}

type Props = {
  children?: React.ReactNode;
  style?: {
    input?: any;
  };
  displayValid?: boolean;
  errorMessages?: {
    startDate?: {
      [key: string]: string;
    };
    endDate?: {
      [key: string]: string;
    };
    group?: {
      [key: string]: string;
    };
  };
};

/**
FormElement wrapper around DateRange. This is the default export.
**/
const DateRangeFormElement = (props: Props) => {
  const { children, errorMessages, ...rest } = props;
  const { displayValid } = rest;
  const validState = displayValid ? 'valid' : undefined;
  const validationState = {};
  if (errorMessages) {
    const startDateErrorPresent =
      errorMessages.startDate && Object.keys(errorMessages.startDate).length > 0;
    const endDateErrorPresent =
      errorMessages.endDate && Object.keys(errorMessages.endDate).length > 0;
    const groupErrorPresent = errorMessages.group && Object.keys(errorMessages.group).length > 0;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'startDate' does not exist on type '{}'.
    validationState.startDate = groupErrorPresent || startDateErrorPresent ? 'error' : validState;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'endDate' does not exist on type '{}'.
    validationState.endDate = groupErrorPresent || endDateErrorPresent ? 'error' : validState;
  }

  const flatErrorMessages = flatten(map(errorMessages, group => map(group, m => m)));

  return (
    /** @ts-expect-error no overload matches this call */
    <FormElement {...rest} errorMessages={flatErrorMessages} isFieldSet>
      <DateRange {...rest} {...select(props, 'input')} validationState={validationState} />
      {children}
    </FormElement>
  );
};
DateRangeFormElement.displayName = 'DateRangeFormElement';

export { DateRangeFormElement };
