/**
 * This file combines Calendar.js and index.js from react-big-calendar@v0.14.0.
 *
 * The BigCalendar component extends the controlled Calendar component with the following changes:
 *
 * 1) References to "className" and "style" are removed from the render function copied from the base component,
 *    and replaced by "resolve" and "select" to apply styles and pass styles down to child components.
 * 2) As only "Month" view is needed, variable View is pointing to the Month component directly.
 *    Additional views can be added per request.
 * 3) Default "popup" prop to be true.
 * 4) Add keepFlyoutOpenOnDocumentClick prop to indicate whether to close the trigger content
 *    via clicking on the document.
 *
 * There are no functional changes.
 */

import React from 'react';

import defaults from 'lodash/defaults';
import omit from 'lodash/omit';

import { resolve, select } from '@cvent/nucleus-dynamic-css';
import PropTypes from 'prop-types';
import BackgroundWrapper from 'react-big-calendar/lib/BackgroundWrapper';
import Calendar from 'react-big-calendar/lib/Calendar';
import EventWrapper from 'react-big-calendar/lib/EventWrapper';
import { set as setLocalizer } from 'react-big-calendar/lib/localizer';
import globalizeLocalizer from 'react-big-calendar/lib/localizers/globalize';
import momentLocalizer from 'react-big-calendar/lib/localizers/moment';
import { views, navigate } from 'react-big-calendar/lib/utils/constants';
import move from 'react-big-calendar/lib/utils/move';
import viewLabel from 'react-big-calendar/lib/utils/viewLabel';

import { luxonLocalizer, defaultFormats } from './customLocalizer/luxonLocalizer';
import { CalendarMonth } from './Month';
import { Toolbar, toolbarStyleKeys as ToolbarStyles } from './Toolbar';

function viewNames(_views: any) {
  return !Array.isArray(_views) ? Object.keys(_views) : _views;
}

class BigCalendar extends Calendar.ControlledComponent {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  _showMore = () => {};
  static displayName = 'Calendar';
  static propTypes = {
    ...Calendar.ControlledComponent.propTypes,
    style: PropTypes.object,
    classes: PropTypes.object,
    // Add date specific cell style or className
    dateCellStyleGetter: PropTypes.func,
    // Flag indicating whether to allow closing the trigger content via clicking on the document.
    keepFlyoutOpenOnDocumentClick: PropTypes.bool
  };
  static defaultProps = {
    ...Calendar.ControlledComponent.defaultProps,
    drilldownView: null,
    views: ['month'],
    popup: true,
    keepFlyoutOpenOnDocumentClick: true
  };

  render() {
    const {
      view,
      toolbar,
      events,
      culture,
      components = {},
      elementProps,
      date: current,
      ...props
    } = this.props;

    let { formats = {} } = this.props;

    formats = defaultFormats(formats);

    // let View = this.getView();
    const View = CalendarMonth;
    const names = viewNames(this.props.views);

    const viewComponents = defaults(components[view] || {}, omit(components, names), {
      eventWrapper: EventWrapper,
      dayWrapper: BackgroundWrapper,
      dateCellWrapper: BackgroundWrapper
    });

    const ToolbarToRender = components.toolbar || Toolbar;

    return (
      <div {...elementProps} {...resolve(this.props, 'calendarContainer')}>
        {toolbar && (
          <ToolbarToRender
            date={current}
            view={view}
            views={names}
            label={viewLabel(current, view, formats, culture)}
            onViewChange={this.handleViewChange}
            onNavigate={this.handleNavigate}
            messages={this.props.messages}
            {...select(this.props, ...ToolbarStyles)}
          />
        )}
        {/* @ts-expect-error 'View' cannot be used as a JSX component. */}
        <View
          ref="view"
          {...props}
          {...formats}
          culture={culture}
          formats={undefined}
          events={events}
          date={current}
          components={viewComponents}
          getDrilldownView={this.getDrilldownView}
          onNavigate={this.handleNavigate}
          onDrillDown={this.handleDrillDown}
          onSelectEvent={this.handleSelectEvent}
          onSelectSlot={this.handleSelectSlot}
          onShowMore={this._showMore}
          dateCellStyleGetter={this.props.dateCellStyleGetter}
        />
      </div>
    );
  }
}

Object.assign(BigCalendar, {
  setLocalizer,
  globalizeLocalizer,
  // keep moment localizer for apps that want to use moment still
  momentLocalizer,
  luxonLocalizer,
  label: viewLabel,
  views,
  Views: views,
  Navigate: navigate,
  move,
  components: {
    eventWrapper: EventWrapper,
    dayWrapper: BackgroundWrapper,
    dateCellWrapper: BackgroundWrapper
  }
});

export { BigCalendar };
