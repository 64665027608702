import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';

type Props = React.PropsWithChildren<{
  digit?: number;
  total?: number;
  success?: boolean;
  style?: {
    container?: any;
    indicator?: any;
    bar?: any;
    successBar?: any;
    message?: any;
  };
  ariaLabel?: string;
  addProgressBarAttributes?: boolean;
  role?: string; // adding role as prop, because JAWS cannot traverse with role="progressbar"
  classes?: Record<string, string>;
}>;
interface AriaAttributes {
  'aria-valuenow': number | undefined;
  'aria-valuemin': number;
  'aria-valuemax': number | undefined;
  'aria-label'?: string;
}

/**
Display a Progress Bar Indicator item
**/
export class ProgressBar extends React.Component<Props> {
  static displayName = 'ProgressBar';
  static defaultProps = {
    addProgressBarAttributes: true,
    role: 'progressbar'
  };
  render() {
    let counterDigit = this.props.digit;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    while (counterDigit > this.props.total) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      counterDigit = counterDigit - this.props.total; // Mostly used for days; prevent counter from looking wrong
    }
    const barStyle = {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      width: Math.floor((counterDigit / this.props.total) * 100) + '%',
      ...resolve(this.props, 'bar', this.props.success ? 'successBar' : null).style
    };
    const ariaAttributes: AriaAttributes = {
      'aria-valuenow': counterDigit,
      'aria-valuemin': 0,
      'aria-valuemax': this.props.total
    };
    return (
      <div {...resolve(this.props, 'container')}>
        <div
          {...resolve(this.props, 'indicator')}
          role="progressbar"
          {...(this.props.addProgressBarAttributes ? ariaAttributes : {})}
          {...(this.props.ariaLabel ? { 'aria-label': this.props.ariaLabel } : {})}
          tabIndex={0}
        >
          <div
            {...resolve(this.props, 'bar', this.props.success ? 'successBar' : null)}
            style={barStyle}
          />
          <div {...resolve(this.props, 'message')}>
            {this.props.children ? this.props.children : null}
          </div>
        </div>
      </div>
    );
  }
}
