import { useLayoutEffect, RefObject, useEffect, useState } from 'react';

function getShrinkHeight(contentDocument: HTMLDocument): number {
  const body = contentDocument.getElementsByTagName('body')[0];
  let maxY = 0;
  if (body) {
    for (let i = 0; i < body.children.length; i++) {
      const { bottom } = body.children[i].getBoundingClientRect();
      maxY = Math.max(maxY, bottom);
    }
  }
  return maxY;
}

function resetIframeSize(iframeContainerRef: RefObject<HTMLIFrameElement>): void {
  if (!iframeContainerRef.current) return;
  const iframes = iframeContainerRef.current.getElementsByTagName('iframe');
  if (iframes && iframes.length > 0) {
    const iframe = iframes[0];
    if (!iframe.contentDocument) return;

    // Shrink the iframe to the size of the body content to allow the height to re-adjust
    const shrinkHeight = getShrinkHeight(iframe.contentDocument);

    iframe.style.height = shrinkHeight === 0 ? shrinkHeight.toString() : shrinkHeight + 'px';

    const newHeight = iframe.contentDocument.documentElement.scrollHeight + 'px';

    // Change height of both the iframe and the overlay
    for (let j = 0; j < iframeContainerRef.current.childNodes.length; j++) {
      const node = iframeContainerRef.current.childNodes[j] as HTMLElement;
      node.style.height = newHeight;
    }
  }
}

function useElementResize(
  iframeContainerRef: RefObject<HTMLIFrameElement>,
  resizeCallback?: () => void
): void {
  useLayoutEffect(() => {
    if (typeof window !== 'object') return;
    const onResize = () =>
      window.requestAnimationFrame(() => {
        resetIframeSize(iframeContainerRef);
        resizeCallback?.();
      });
    if (window.ResizeObserver && iframeContainerRef.current) {
      // Support for real browsers and Edge
      const observer = new ResizeObserver(onResize);

      observer.observe(iframeContainerRef.current);

      return () => observer.disconnect();
    }
  });
}

function manipulateIncomingHtmlContent(content: string): string {
  const parser = new DOMParser();
  const parsedContent = parser.parseFromString(content, 'text/html');
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  enableExternalLinkInNewWindow(parsedContent);
  return String(parsedContent.documentElement.innerHTML);
}

function enableExternalLinkInNewWindow(parsedContent: Document): void {
  const aElements = parsedContent.getElementsByTagName('body')[0].getElementsByTagName('a');
  if (aElements?.length) {
    for (let i = 0; i < aElements.length; i++) {
      const aElement = aElements[i];
      aElement.setAttribute('target', '_blank');
    }
  }
}

export function useIframe(
  iframeContainerRef: RefObject<HTMLIFrameElement>,
  content: string,
  title: string | undefined,
  resizeCallback?: () => void
): void {
  const [isVisible, setIsVisible] = useState(false);

  useLayoutEffect(() => {
    if (!isVisible) return;
    const iframe = window.document.createElement('iframe');
    if (title) iframe.setAttribute('title', title);
    if (!iframeContainerRef.current) return;

    iframeContainerRef.current.appendChild(iframe);

    const iframeDocument = iframe.contentDocument || iframe.contentWindow?.document;
    if (!iframeDocument) return;

    let updatedContent = content;
    if (!updatedContent.trim().includes('<body')) {
      updatedContent = `<body>${updatedContent}</body>`;
    }
    updatedContent = manipulateIncomingHtmlContent(updatedContent);

    iframeDocument.open();
    iframeDocument.write(updatedContent);
    iframeDocument.close();

    const resetSize = () =>
      window.requestAnimationFrame(() => {
        resetIframeSize(iframeContainerRef);
        resizeCallback?.();
      });

    const contentChange = new MutationObserver(resetSize);

    // Observe everything about iframe body and all contents
    contentChange.observe(iframeDocument.documentElement, {
      attributes: true,
      attributeOldValue: false,
      characterData: true,
      characterDataOldValue: false,
      childList: true,
      subtree: true
    });

    iframeDocument.documentElement.addEventListener('load', resetSize, { capture: true });

    resetSize();

    return () => {
      contentChange.disconnect();
      iframe.parentNode?.removeChild(iframe);
      iframeDocument.documentElement.removeEventListener('load', resetSize, { capture: true });
    };
  }, [iframeContainerRef, content, resizeCallback, isVisible, title]);

  useEffect(() => {
    if (!iframeContainerRef.current) return;
    /**
     * create an intersection observer to get the viewport of the current screen
     * and set isVisible to true when in range to start rendering the code widget
     * iframe
     */
    const observer = new IntersectionObserver(entries => {
      const [iframe] = entries;
      if (!isVisible) {
        if (
          typeof iframe.isIntersecting !== 'undefined'
            ? iframe.isIntersecting
            : iframe.intersectionRatio > 0
        ) {
          setIsVisible(true);
          observer.disconnect();
        }
      }
    }, {});

    if (iframeContainerRef.current) {
      observer.observe(iframeContainerRef.current);
    }
    const iframeRef = iframeContainerRef.current;
    return () => {
      observer.unobserve(iframeRef);
    };
  }, [isVisible, iframeContainerRef]);
  useElementResize(iframeContainerRef, resizeCallback);
}
