import React from 'react';

import merge from 'lodash/merge';
import { resolve } from '@cvent/nucleus-dynamic-css';
import { Dialog, removeKeys } from 'nucleus-core';
import DialogTransitionUpStyle from 'nucleus-core/less/cv/Dialog.transitionUp.less';
import {
  LoadingIndicator,
  evaluateFunctionOrValue,
  getInlineStyle,
  resetStylesToDefault,
  ThemeableComponent,
  ThemeableComponentProps
} from 'nucleus-widgets';
import { connect } from 'react-redux';
import { defaultMemoize } from 'reselect';

import DialogStyles from './styles/Dialog.less';
import Variables from './styles/Variables.less';

const defaultLoadingDialogConfig = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  requestClose: () => {},
  dialogId: 'LoadingProgressIndicatorDialog',
  header: <div />
};

// This is the same as the dialog styles being added to all our base themes.
const defaultDialogThemeObject = {
  background: {
    borderRadius: 0
  },
  header: {
    styleMapping: 'custom',
    background: {
      color: 'secondary'
    },
    spacing: {
      padding: {
        paddingTop: 15,
        paddingBottom: 15,
        paddingLeft: 25,
        paddingRight: 25
      }
    }
  },
  headerText: {
    styleMapping: 'header3'
  },
  body: {
    styleMapping: 'custom',
    background: {
      color: 'primary'
    },
    spacing: {
      padding: {
        paddingTop: 15,
        paddingBottom: 30,
        paddingLeft: 20,
        paddingRight: 20
      }
    }
  }
};

export const getGlobalThemeElements = defaultMemoize((theme: any) => {
  return theme?.global?.elements ? Object.keys(theme.global.elements) : [];
});

export const getDialogStyleObject = defaultMemoize(
  (dialog = defaultDialogThemeObject, theme: any, customFonts?: any) => {
    const globalThemeElements = getGlobalThemeElements(theme);

    const globalThemeElementStyleObject = globalThemeElements.reduce((styleObject, element) => {
      return {
        ...styleObject,
        [element]: { styleMapping: element }
      };
    }, {});

    return merge(
      {},
      resetStylesToDefault(theme.global),
      { ...globalThemeElementStyleObject, ...dialog },
      {
        customFonts
      }
    );
  }
);

export const getDialogContainerStyle = defaultMemoize((style: any, theme: any) => {
  return getInlineStyle(
    style,
    {},
    theme.global.palette,
    theme.global.fontPalette,
    {},
    { useText: false, useSpacing: false }
  );
});

interface DialogContainerProps extends ThemeableComponentProps {
  loading: number;
  delayRenderTime?: number;
  delayHideRenderTime?: number;
  dialogConfig?: any; // object
  loadingConfig?: any; // object
  isThemedDialog?: boolean;
  pageTransitionLoading?: any;
  theme?: any;
  dialogContainerContent: any;
  spinnerMessage?: string;
  ariaLabel?: string;
}

export class DialogContainerBase extends ThemeableComponent<DialogContainerProps> {
  static displayName = 'DialogContainer';
  static defaultProps = {
    delayRenderTime: 1500,
    delayHideRenderTime: 1000,
    style: {}
  };
  _delayHideRenderTimer: any;
  _delayRenderTimer: any;
  _doNotCloseDialog: any;
  constructor(props: any) {
    super(props);
    this.state = {
      visible: false,
      delayHide: false
    };
    this._doNotCloseDialog = false;
    this._delayRenderTimer = null;
    this._delayHideRenderTimer = null;
    this.setDelayRender = this.setDelayRender.bind(this);
    this.setDelayHideRender = this.setDelayHideRender.bind(this);
    this.removeDelayRender = this.removeDelayRender.bind(this);
  }
  setDelayRender(delayRenderTime: any, delayHideRenderTime: any) {
    this._delayRenderTimer = setTimeout(() => {
      this.setState({ visible: true, delayHide: true });
      this.setDelayHideRender(delayHideRenderTime);
    }, delayRenderTime);
  }
  removeDelayRender() {
    clearTimeout(this._delayRenderTimer);
    this._delayRenderTimer = null;
    this.setState({ visible: false });
  }
  setDelayHideRender(delayHideRenderTime: any) {
    this._delayHideRenderTimer = setTimeout(() => {
      this.setState({ delayHide: false });
    }, delayHideRenderTime);
  }
  componentDidMount() {
    // Add a delay before showing progress indicator dialog so that it
    // does not show a flicker in the screen
    if ((this.props as any).loading > 0) {
      this.setDelayRender(
        (this.props as any).delayRenderTime,
        (this.props as any).delayHideRenderTime
      );
    }
  }
  componentDidUpdate(prevProps: any) {
    if ((this.props as any).loading > 0 && prevProps.loading === 0) {
      this.setDelayRender(
        (this.props as any).delayRenderTime,
        (this.props as any).delayHideRenderTime
      );
    } else if ((this.props as any).loading === 0 && prevProps.loading > 0) {
      this.removeDelayRender();
    }
    this._doNotCloseDialog =
      prevProps.loading === 0 &&
      prevProps.dialogConfig.isOpen === (this.props as any).dialogConfig.isOpen &&
      (this.props as any).dialogConfig.isOpen;
  }
  componentWillUnmount() {
    clearTimeout(this._delayRenderTimer);
    clearTimeout(this._delayHideRenderTimer);
  }

  getStyleObject = (): any => {
    const { style, theme } = this.props;
    return this._getStyleObject(style, theme);
  };

  _getStyleObject = defaultMemoize((style: any, theme: any): any => {
    if (!theme) {
      return {};
    }
    const globalThemeElements = getGlobalThemeElements(theme);
    const globalThemeElementStyleObject = globalThemeElements.reduce((styleObject, element) => {
      return {
        ...styleObject,
        ...(style[element] ? { [element]: this.getElementInlineStyle(element) } : {})
      };
    }, {});
    return {
      ...globalThemeElementStyleObject,
      dragContainer: getDialogContainerStyle(style, theme),
      dragHandle: this.getElementInlineStyle('header'),
      header: this.getElementText('headerText'),
      exit: {
        color: this.getElementText('headerText').color
      },
      content: this.getElementInlineStyle('body')
    };
  });

  // When `header` prop isn't passed into `dialogConfig`,
  // `dragHandle` and `header` styles need to be removed from the `style` object that's passed into Dialog,
  // to avoid double headers.
  // paddings on `content` need to be removed too, since content is used as the container of the entire dialog.
  getStyleObjectForDialogWithNoHeader = defaultMemoize((dialogThemeStyles: any) => {
    return {
      ...removeKeys(dialogThemeStyles, ['dragHandle', 'header']),
      ...(dialogThemeStyles.content
        ? {
            content: removeKeys(dialogThemeStyles.content, [
              'paddingTop',
              'paddingBottom',
              'paddingLeft',
              'paddingRight'
            ])
          }
        : {})
    };
  });
  render() {
    const {
      loading,
      dialogConfig,
      loadingConfig,
      dialogContainerContent,
      isThemedDialog,
      pageTransitionLoading,
      translate,
      spinnerMessage,
      ariaLabel
    } = this.props;
    const isLoading = loading > 0;
    const showLoading = ((this.state as any).visible && isLoading) || (this.state as any).delayHide;
    const showDialog =
      showLoading || (dialogConfig.isOpen && (!isLoading || this._doNotCloseDialog));
    const loadingDialogConfig = {
      ...defaultLoadingDialogConfig,
      ...loadingConfig,
      isAnimatable: false
    };
    const dialogContainerStyling =
      (this.props as any).classes &&
      (dialogConfig.isOpen ||
        ((this.state as any).visible && (this.props as any).loading > 0) ||
        (this.state as any).delayHide)
        ? this.props
        : { classes: DialogStyles };
    const dialogThemeStyles = this.getStyleObject();
    let { style: dialogStyles } = dialogConfig;
    if (isThemedDialog) {
      dialogStyles = {
        ...(dialogConfig.header
          ? dialogThemeStyles
          : this.getStyleObjectForDialogWithNoHeader(dialogThemeStyles)),
        ...dialogStyles
      };
    }
    const aLabel = showLoading
      ? spinnerMessage && translate(spinnerMessage)
      : ariaLabel || dialogConfig.ariaLabel;
    return (
      <Dialog
        {...(showLoading
          ? { ...loadingDialogConfig }
          : {
              ...dialogConfig,
              style: dialogStyles
            })}
        isOpen={showDialog}
        ariaLabel={aLabel}
        scrollPageToTop={pageTransitionLoading > 0}
      >
        <div {...resolve(dialogContainerStyling, 'dialogContainer')}>
          {showLoading && (
            <div className="loadingChildren">{dialogContainerContent.loadingChildren}</div>
          )}
          <div className={showLoading ? DialogStyles.hidden : 'dialogChildren'}>
            {isThemedDialog
              ? evaluateFunctionOrValue(dialogContainerContent.dialogChildren, dialogThemeStyles)
              : dialogContainerContent.dialogChildren}
          </div>
        </div>
      </Dialog>
    );
  }
}

export const DialogContainer = connect((state, props) => {
  const translate = (state as any).text.translate;
  const {
    dialog: {
      children: dialogChildren,
      isThemedDialog,
      header,
      fallbackDialogThemeObject,
      ...otherDialogConfig
    },
    isLoading,
    pageTransitionLoading
  } = (state as any).dialogContainer;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'spinnerMessage' does not exist on type '... Remove this comment to see the full error message
  const { spinnerMessage } = props;
  const { theme } = (state as any).website || {};

  return {
    isThemedDialog,
    loading: isLoading + pageTransitionLoading,
    pageTransitionLoading,
    classes: otherDialogConfig.classes,
    theme,
    style:
      theme && theme.global && (theme.global.dialog || fallbackDialogThemeObject)
        ? getDialogStyleObject(
            theme.global.dialog || fallbackDialogThemeObject,
            theme,
            (state as any).customFonts
          )
        : {},
    dialogConfig: {
      ...otherDialogConfig,
      header: translate(header),
      classes: {
        ...DialogStyles,
        transition: DialogTransitionUpStyle,
        ...otherDialogConfig.classes
      }
    },
    dialogContainerContent: {
      dialogChildren,
      loadingChildren: (
        <LoadingIndicator
          spinnerMessage={translate(spinnerMessage)}
          classes={DialogStyles}
          colorPalette={theme.global.spinner}
        />
      )
    },
    loadingConfig: {
      classes: {
        ...DialogStyles,
        dragContainer: DialogStyles.loadingDialogDragContainer,
        overlay: DialogStyles.loadingDialogOverlay,
        transition: DialogTransitionUpStyle
      },
      style: {
        dragContainer: {
          height: Variables.dialogHeight
        }
      }
    },
    translate
  };
})(DialogContainerBase as any);
