import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { hexStringToRgb, lightenRGB, Spinner } from 'nucleus-core';
import SpinnerStyle from './LoadingIndicator.less';

interface Props {
  classes?: any;
  spinnerMessage?: string;
  message?: string;
  colorPalette?: any;
}

export function LoadingIndicator(props: Props) {
  const { spinnerMessage, message, colorPalette = {} } = props;
  const { r, g, b } = hexStringToRgb(colorPalette?.outerBorderColorPalette);
  const { lightenR, lightenG, lightenB } = lightenRGB(r, g, b, 0.8);

  return (
    <div {...resolve(props, 'loadingMessage')}>
      <Spinner
        classes={SpinnerStyle}
        fallbackText={spinnerMessage}
        numberOfCircles={1}
        hasBackground
        useTheme
        outerCircleBackgroundColor={colorPalette?.backgroundPalette}
        outerCircleColor={`rgb(${r}, ${g}, ${b})`}
        outerCircleLightendColor={`rgb(${lightenR}, ${lightenG}, ${lightenB})`}
      />
      {message && <p {...resolve(props, 'loadingMessageText')}>{message}</p>}
    </div>
  );
}
