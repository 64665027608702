import { ROW } from './LayoutTypes';

/**
 * Traverses down through the tree of layout items to collect all row
 * instances and push them into the 'rows' parameter.
 * @param {object} layoutItems - the layoutItems portion of application state.
 * @param {object} item - the top level layout item to traverse down from.
 * @param {array} rows - array where all of the discovered child rows get pushed into.
 */
export function getAllChildRowsInItem(layoutItems: any, item: any, rows = []) {
  if (!item?.layout?.childIds) return;
  item.layout.childIds.forEach((childId: any) => {
    if (layoutItems[childId].layout.type === ROW) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      rows.push(layoutItems[childId]);
    }
    getAllChildRowsInItem(layoutItems, layoutItems[childId], rows);
  });
}
