import React, { Component } from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import VideoStyle from './Video.less';
import { createReference } from './videoUtils';

type Props = {
  videoPath?: string;
  recordVideoViewActivity?: ((...args: any[]) => any) | undefined;
};

/**
 * The basic UI implementation for the Video widget when it contains a tweet
 */
export class EmbeddedTwitter extends Component<Props> {
  window: any;
  wrapper: any;

  componentDidMount() {
    // To make the widget appear on Preview Mode since, this is inside an Iframe
    createReference.call(this);
    this.appendEmbedScript();
    this.registerVideoPlayer();
  }

  componentWillUnmount() {
    this.window.twttrScripts = {};
  }

  appendEmbedScript() {
    if (typeof this.window.twttr === 'undefined') {
      if (!document.querySelector('#twitter-wjs')) {
        // Adding the script to the page to embed Twitter plugin when this widget gets mounted on the DOM
        const twitterScript = this.window.document.createElement('script');
        twitterScript.src = '//platform.twitter.com/widgets.js';
        twitterScript.async = true;
        twitterScript.id = 'twitter-wjs';
        this.window.document.body.appendChild(twitterScript);
      }
    } else {
      // Re-loading the plugin when the widget gets re-mounted with existing videoPath for it
      this.window.twttr.widgets.load();
    }
  }

  registerVideoPlayer() {
    const script = document.querySelector('#twitter-wjs');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    script.addEventListener('load', () => {
      this.bindPlayerEvents();
    });
  }

  bindPlayerEvents() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'twttr' does not exist on type 'Window & ... Remove this comment to see the full error message
    if (window.twttr) {
      const { recordVideoViewActivity } = this.props;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'twttr' does not exist on type 'Window & ... Remove this comment to see the full error message
      window.twttr.events.bind('rendered', (event: any) => {
        const twitterContainer = event.target;
        const twitterIFrame = twitterContainer.children[0];
        if (twitterIFrame) {
          const tweetId = twitterIFrame.getAttribute('data-tweet-id');
          this.window.twttrScripts = this.window.twttrScripts || {};

          this.window.twttrScripts[tweetId] = () => {
            /*
             * When focus is removed from the window and the current focus is the twitter
             * twitter iframe, continue.
             */
            if (document.activeElement === twitterIFrame) {
              if (recordVideoViewActivity) {
                recordVideoViewActivity(`Twitter Video ${tweetId}`);
              }
              // Remove the script from the object, because we only want this to fire once per page load.
              delete this.window.twttrScripts[tweetId];
            }
          };

          /*
           * Due to Twitter's limited api and the inability to attach events onto/into
           * iframe components cross-site, we must monitor the removal of focus from the
           * window object when the iframe is clicked to observe the iframe onclick by proxy.
           */
          twitterContainer.addEventListener('mouseenter', () => {
            /*
             * Ensuring focus is on the window if it isn't already as the user scrolls into the
             * twitter container in case the user has clicked elsewhere on the page first before
             * moving to click on the twitter card.
             */
            if (document.activeElement !== document.body) {
              window.focus();
            }
          });

          // Adding a de facto click event when the focus is removed from the window.
          window.addEventListener('blur', () =>
            Object.keys(this.window.twttrScripts).forEach(key => {
              const script = this.window.twttrScripts[key];
              script();
            })
          );
        }
      });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.videoPath !== this.props.videoPath) {
      // Re-loading the plugin when videoPath is updated by the user
      if (typeof this.window.twttr !== 'undefined') {
        this.window.twttr.widgets.load();
      }
    }
  }

  /**
   * Returns the code which will help embed the plugin on the page.
   * @param {*} videoPath Path to the status which needs to be embedded
   */
  getTwitterEmbedPage(videoPath: any) {
    let videoPathSubstr = videoPath;
    if (videoPath && videoPath[videoPath.length - 1] === '/') {
      // Trims the trailing slash if one exists
      videoPathSubstr = videoPath.substring(0, videoPath.length - 1);
    }

    // This div will be replaced by the content from the Twitter script.
    return {
      __html: `
        <blockquote
          id="twitter_tweet_${videoPath}"
          class="twitter-tweet"
          data-lang="en">
          <a href="https://twitter.com/${videoPathSubstr}"></a>
        </blockquote>`
    };
  }

  render() {
    const { videoPath } = this.props;
    const videoStyles = { classes: VideoStyle };

    return (
      <div ref={this.wrapper}>
        <div
          {...resolve(videoStyles, 'twitterContainer')}
          dangerouslySetInnerHTML={this.getTwitterEmbedPage(videoPath)}
        />
      </div>
    );
  }
}
