import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import ReactSortable from 'react-sortablejs';

type Props = React.PropsWithChildren<{
  /** react-sortablejs v1 options */
  options?: {
    group?:
      | string
      | {
          name?: string;
          pull?: boolean | 'clone' | ((...args: any[]) => any);
          put?: boolean | any[] | ((...args: any[]) => any);
        };
    tag?: string;
    sort?: boolean;
    delay?: number;
    disabled?: boolean;
    store?: boolean;
    animation?: number;
    handle?: string;
    filter?: string | ((...args: any[]) => any);
    draggable?: string;
    ghostClass?: string;
    chosenClass?: string;
    dataIdAttr?: string;
    forceFallback?: boolean;
    fallbackClass?: string;
    fallbackOnBody?: boolean;
    scroll?: boolean;
    scrollSensitivity?: number;
    scrollSpeed?: number;
    onStart?: (...args: any[]) => any;
    onEnd?: (...args: any[]) => any;
    onAdd?: (...args: any[]) => any;
    onUpdate?: (...args: any[]) => any;
    onSort?: (...args: any[]) => any;
    onRemove?: (...args: any[]) => any;
    onFilter?: (...args: any[]) => any;
    onMove?: (...args: any[]) => any;
  };
  classes?: any;
  style?: {
    list?: any;
    item?: any;
    dragging?: any;
    ghost?: any;
  };
  itemStyleKeys?: any[];
  listStyleKeys?: any[];
}>;

/**
 The SortList Component can be used to add drag n drop functionality with sorting to any collection of items.
 Simply wrap the component around your collection.
 **/
export class SortList extends React.Component<Props> {
  static displayName = 'SortList';
  render() {
    const { children, itemStyleKeys = [], listStyleKeys = [], options } = this.props;

    if (options) {
      if (options.chosenClass) {
        options.chosenClass = resolve(this.props, options.chosenClass).className;
      }

      if (options.ghostClass) {
        options.ghostClass = resolve(this.props, options.ghostClass).className;
      }
    }

    const modifiedChildren = React.Children.map(children, (child, index) => {
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      return React.cloneElement(child, {
        ...resolveTestId(this.props, `-child-${index}`),
        // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
        className: `${child.props.className} ${
          resolve(this.props, 'item', ...itemStyleKeys).className
        }`,
        // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
        style: Object.assign({}, child.props.style, resolve(this.props, 'item', ...itemStyleKeys))
      });
    });

    return (
      <ReactSortable
        {...resolve(this.props, 'list', ...listStyleKeys)}
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        tag={options.tag}
        options={options}
      >
        {modifiedChildren}
      </ReactSortable>
    );
  }
}
