import memoize from 'lodash/memoize';

function shellProjectTextFunc(text: any, location: any) {
  // eslint-disable-next-line no-console
  console.warn(
    `"shellProjectText('${text}')" is being called in "${location}".
    This is just placeholder text for the base shell project,`,
    'please replace this function call with a new resource key.'
  );
  return text;
}

// Use lodash's memoize function because it has an unlimited cache size, we only need to see
// the warning once per instance.
export const shellProjectText = memoize((text, location) => shellProjectTextFunc(text, location));
