import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';

import { InteractiveElement } from '../containers/InteractiveElement';

type Props = {
  selectedDate?: any; // TODO: PropTypes.instanceOf(Date)
  day: number;
  month: number;
  year: number;
  isActive: boolean;
  onClick: (...args: any[]) => any;
  style?: {
    inactive?: any;
    selected?: any;
    today?: any;
    day?: any;
  };
};

/**
Represents a single day in a calendar component.
**/
const DayItem = (props: Props) => {
  const { selectedDate, day, month, year, isActive, onClick } = props;
  const today = new Date();
  let itemStyle;
  let handleClick = onClick.bind(null, day, month, year);
  if (!isActive) {
    itemStyle = 'inactive';
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleClick = () => {};
  } else if (
    selectedDate &&
    year === selectedDate.getFullYear() &&
    month === selectedDate.getMonth() &&
    day === selectedDate.getDate()
  ) {
    itemStyle = 'selected';
  } else if (
    year === today.getFullYear() &&
    month === today.getMonth() &&
    day === today.getDate()
  ) {
    itemStyle = 'today';
  } else {
    itemStyle = 'day';
  }
  return (
    <td {...resolve(props, itemStyle)}>
      <InteractiveElement element="span" onClick={handleClick}>
        {day}
      </InteractiveElement>
    </td>
  );
};
DayItem.displayName = 'DayItem';

export { DayItem };
