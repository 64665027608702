import React from 'react';

import {
  resolveDateFromString,
  parseMobileDateString,
  getLocaleDateString
} from '@cvent/nucleus-core-datetime-utils';
import {
  EMPTY_DATE,
  DATE_CHANGE_SUCCESS,
  DATE_CHANGE_EMPTY_DATE,
  DATE_CHANGE_ERROR_INVALID,
  DATE_CHANGE_ERROR_OUT_OF_RANGE,
  START_DATE_CHANGED,
  END_DATE_CHANGED,
  switchLocale
} from '@cvent/nucleus-core-datetime-utils';
import { DateUtils } from 'nucleus-react-day-picker';
import PropTypes from 'prop-types';

import { isRequiredIf } from '../utils/isRequiredIf';
import { removeKeys } from '../utils/removeKeys';

// offsetHour Sets date to 12:00:00 to match default value of the date picker selection when formatted.
const offsetHour = 12;
/**
 * Removes any specific properties to this component from a props object that would be invalid
 * if applied to a base DOM node. Useful when composing multiple components to prevent
 * passing a component props that it doesn't use.
 */
export function removeDatePickerProps(props: any) {
  return removeKeys(props, [
    'fieldName',
    'dateStringFormat',
    'parseDate',
    'showIcon',
    'hideLabel',
    'formatOptions',
    'minDate',
    'maxDate',
    'openToMaxDate',
    'minRange',
    'maxRange',
    'months',
    'weekdaysShort',
    'weekdaysLong',
    'formatDate',
    'onChange',
    'locale',
    'changeYearMonth',
    'changeYearLabel',
    'changeMonthLabel',
    'changeYearLabelTextResourceId',
    'changeMonthLabelTextResourceId',
    'validationState',
    'navButtonPreviousMonthLabel',
    'navButtonNextMonthLabel',
    'navButtonNextMonthLabelTextResourceId',
    'navButtonPreviousMonthLabelTextResourceId',
    'calendarIcon',
    'onDayFocus',
    'modifiers',
    'calendarFooter'
  ]);
}

/**
A base component extended by PickADate and DateRange components
**/
export class PickADateBase extends React.Component<any, any> {
  static displayName = 'PickADateBase';
  static propTypes = {
    /** locale that formatDate and getLocaleDateString use to get locale-aware values */
    locale: PropTypes.string.isRequired,
    /** Function used to format javascript Date objects into strings. */
    formatDate: PropTypes.func,
    /** Function used to parse javascript Date objects from strings,
      based on the format defined by the format parameter */
    parseDate: PropTypes.func,
    /** Format parameter for parseDate function. Defaults to 'mm/dd/yyyy'. */
    format: PropTypes.string,
    /** Format options to pass onto getLocaleDateString to get format.
     * Defaults to { year: 'numeric', month: '2-digit', day: '2-digit' }
     * See below for more format options on Intl.DateTimeFormat
     * https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat */
    formatOptions: PropTypes.object,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    // Selected date
    value: (props: any, propName: any, componentName: any) => {
      if (
        !(
          props[propName] instanceof Date ||
          props[propName] === null ||
          props[propName] === EMPTY_DATE
        )
      ) {
        return new Error(`Invalid value is assigned to prop "${propName}" in ${componentName}.
          Expecting one of null, a valid Date or the EMPTY_DATE constant.`);
      }
    },
    minDate: PropTypes.instanceOf(Date),
    maxDate: PropTypes.instanceOf(Date),
    /** Label for previous month button/icon in the nav bar. Should exist if translate is not provided. */
    navButtonPreviousMonthLabel: isRequiredIf(
      PropTypes.string,
      (props: any) => !props.hasOwnProperty('translate')
    ),
    /** Label for next month button/icon in the nav bar. Should exist if translate is not provided. */
    navButtonNextMonthLabel: isRequiredIf(
      PropTypes.string,
      (props: any) => !props.hasOwnProperty('translate')
    ),
    /** Default text resource id for previous month button label */
    navButtonPreviousMonthLabelTextResourceId: PropTypes.string,
    /** Default text resource id for next month button label */
    navButtonNextMonthLabelTextResourceId: PropTypes.string
  };

  static defaultProps = {
    parseDate: resolveDateFromString,
    allowToggle: false,
    allowMouse: false,
    formatOptions: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    },
    navButtonNextMonthLabelTextResourceId:
      'NucleusCoreComponent_PickADate_NextMonthNavButtonLabel__resx',
    navButtonPreviousMonthLabelTextResourceId:
      'NucleusCoreComponent_PickADate_PreviousMonthNavButtonLabel__resx',
    autoComplete: 'off'
  };

  _userIsTyping: any;
  _userIsTypingEndDate: any;
  _userIsTypingStartDate: any;
  calendarWrapper: any;
  textbox: any;
  trigger: any;

  constructor(props: any) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onChangeMobile = this.onChangeMobile.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.checkForDateType = this.checkForDateType.bind(this);
    this.onCalendarClick = this.onCalendarClick.bind(this);
    this.onKeyDownCalendar = this.onKeyDownCalendar.bind(this);
    this.onLoseFocus = this.onLoseFocus.bind(this);
  }

  getStringFromDate(value: any, props = this.props, timeZone = undefined) {
    if (value === EMPTY_DATE) {
      return null;
    }
    // @ts-expect-error ts-migrate(2358) FIXME: The left-hand side of an 'instanceof' expression m... Remove this comment to see the full error message
    if (!value || !value instanceof Date) {
      return value;
    }
    if (props.formatDate) {
      return props.formatDate(value);
    }
    const options = timeZone ? { ...props.formatOptions, timeZone } : props.formatOptions;
    let dateString;
    try {
      dateString = new Intl.DateTimeFormat(props.locale, options).format(value);
    } catch (error) {
      dateString = new Intl.DateTimeFormat(switchLocale(props.locale), options).format(value);
    }
    return dateString;
  }

  // Call props onChange callback. Separate function to allow child class to override
  handleChange(fieldName: any, date: any, newValue: any, statusCode: any, dateName = '') {
    this.props.onChange(fieldName, date, newValue, statusCode, dateName);
  }

  // Get value from props. Separate function to allow child class to override
  getValue(props = this.props) {
    return props.value;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onBlur(_event: any) {}

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onCalendarClick(_event: any) {}

  onChange(fieldName: any, newValue: any, dateName = '') {
    const format =
      this.props.format || getLocaleDateString(this.props.locale, this.props.formatOptions);
    const date = this.props.parseDate(newValue, format);
    if (date && date.setHours) date.setHours(offsetHour, 0, 0, 0);
    // If the user types in a date, we have to check max and min dates manually.
    // Do fancy date parsing to zero out time on minDate and/or maxDate.
    const dateRangeValid =
      date &&
      (!this.props.minDate || date >= new Date(this.props.minDate.setHours(offsetHour, 0, 0, 0))) &&
      (!this.props.maxDate || date <= new Date(this.props.maxDate.setHours(offsetHour, 0, 0, 0)));

    let statusCode = DATE_CHANGE_SUCCESS;
    if (!date && !newValue) {
      statusCode = DATE_CHANGE_EMPTY_DATE;
    } else if (!date) {
      statusCode = DATE_CHANGE_ERROR_INVALID;
    } else if (!dateRangeValid) {
      statusCode = DATE_CHANGE_ERROR_OUT_OF_RANGE;
    }

    switch (dateName) {
      case START_DATE_CHANGED: {
        const [, endDate = null] = this.getValue();
        this._userIsTypingStartDate = true;
        this.setState({ startDateTextInput: newValue, startDateStatusCode: statusCode });
        if (this.props.hasOwnProperty('onNativeChange')) {
          this.props.onNativeChange({
            value: [date, endDate],
            textValue: newValue,
            statusCode,
            dateChanged: dateName
          });
          return;
        }
        this.handleChange(this.props.fieldName, [date, endDate], newValue, statusCode, dateName);
        break;
      }
      case END_DATE_CHANGED: {
        const [startDate = null] = this.getValue();
        this._userIsTypingEndDate = true;
        this.setState({ endDateTextInput: newValue, endDateStatusCode: statusCode });
        if (this.props.hasOwnProperty('onNativeChange')) {
          this.props.onNativeChange({
            value: [startDate, date],
            textValue: newValue,
            statusCode,
            dateChanged: dateName
          });
          return;
        }

        this.handleChange(this.props.fieldName, [startDate, date], newValue, statusCode, dateName);
        break;
      }
      default:
        this._userIsTyping = true;
        this.setState({ textInput: newValue, statusCode });
        if (this.props.hasOwnProperty('onNativeChange')) {
          this.props.onNativeChange(date);
          return;
        }
        this.handleChange(fieldName, date, newValue, statusCode);
    }
  }

  onChangeMobile(fieldName: any, newValue: any, dateName = '') {
    if (!newValue) {
      if (this.props.hasOwnProperty('onNativeChange')) {
        this.props.onNativeChange(null);
      } else {
        this.handleChange(fieldName, null, '', DATE_CHANGE_EMPTY_DATE, dateName);
      }
      return;
    }

    // Get the date.
    const date = parseMobileDateString(newValue);
    if (!date) {
      if (this.props.hasOwnProperty('onNativeChange')) {
        this.props.onNativeChange(null);
      } else {
        this.handleChange(fieldName, null, '', DATE_CHANGE_ERROR_INVALID, dateName);
      }
      return;
    }

    // Get the formatted date string.
    const dateString = this.getStringFromDate(date);

    // Mobile iOS does not implement min and max properties, so we must check manually.
    // Do fancy date parsing to zero out time on minDate and/or maxDate.
    const dateRangeValid =
      (!this.props.minDate || date >= new Date(Date.parse(this.props.minDate.toDateString()))) &&
      (!this.props.maxDate || date <= new Date(Date.parse(this.props.maxDate.toDateString())));

    const newDate = dateRangeValid ? date : null;
    const newStatus = dateRangeValid ? DATE_CHANGE_SUCCESS : DATE_CHANGE_ERROR_OUT_OF_RANGE;
    if (dateName === START_DATE_CHANGED) {
      // endDate should be on or after new startDate
      let endDate = this.getValue()[1];
      endDate = endDate === EMPTY_DATE ? null : endDate;
      endDate = newDate > endDate ? newDate : endDate;
      if (this.props.hasOwnProperty('onNativeChange')) {
        this.props.onNativeChange({
          value: [newDate, endDate],
          textValue: dateString,
          statusCode: newStatus,
          dateChanged: dateName
        });
        return;
      }
      this.handleChange(this.props.fieldName, [newDate, endDate], dateString, newStatus, dateName);
    } else if (dateName === END_DATE_CHANGED) {
      // startDate should be on or before new endDate
      let startDate = this.getValue()[0];
      startDate = startDate === EMPTY_DATE ? null : startDate;
      startDate = newDate < startDate ? newDate : startDate;
      if (this.props.hasOwnProperty('onNativeChange')) {
        this.props.onNativeChange({
          value: [startDate, newDate],
          textValue: dateString,
          statusCode: newStatus,
          dateChanged: dateName
        });
        return;
      }
      this.handleChange(
        this.props.fieldName,
        [startDate, newDate],
        dateString,
        newStatus,
        dateName
      );
    } else {
      if (this.props.hasOwnProperty('onNativeChange')) {
        this.props.onNativeChange(newDate);
        return;
      }
      this.handleChange(fieldName, newDate, dateString, newStatus);
    }
  }

  onFocus(e: any) {
    if (this.trigger) {
      this.trigger.handleShow();
    }
    if (this.textbox) {
      this.textbox.focus();
    }
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onKeyDown(event: any) {
    if (this.props.onKeyDown) {
      this.props.onKeyDown(event);
    }
  }

  onKeyDownCalendar(event: any) {
    if (event.key.toLowerCase() === 'tab') {
      this.onLoseFocus();
    }
  }

  onLoseFocus() {
    // TODO: investigate a solution without using setTimeout
    // hide flyout when focus is out of it
    setTimeout(() => {
      if (
        document &&
        this.trigger &&
        this.calendarWrapper &&
        !this.calendarWrapper.contains(document.activeElement)
      ) {
        this.trigger.handleHide();
      }
    }, 0);
  }

  checkForDateType() {
    if (!document) {
      return 'text';
    }
    const input = document.createElement('input');
    const notADateValue = 'not-a-date';
    let inputType = 'text';
    input.setAttribute('type', 'date');
    input.setAttribute('value', notADateValue);

    if (input.value !== notADateValue) {
      inputType = 'date';
    }

    return inputType;
  }

  // TODO: BAD! We shouldnt be doing this, but desktop browser implementations
  //  of input type="date" is spotty and inconsistent, so we're using an isMobile
  //  check on the user agent so that we only use the browser datepicker for mobile
  //  devices. Currently, the Chrome desktop has implementation flaws preventing us
  //  from dealing with it correctly - See https://jira/browse/CSN-23277
  isMobile() {
    // TODO: At some point in the near future we should be replacing this component with
    //  some third party datepicker or creating a better version of our own, at that point
    //  we need to stop doing this.
    if (navigator && navigator.userAgent) {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|CriOS|Tizen/i.test(
        navigator.userAgent
      );
    }
  }

  isDisabledDays(day: any, minDate: any, maxDate: any) {
    if (minDate && !DateUtils.isSameDay(day, minDate) && day < minDate) {
      return true;
    }
    if (maxDate && !DateUtils.isSameDay(day, maxDate) && day > maxDate) {
      return true;
    }
    return false;
  }

  isYearFirst() {
    const { format, locale, formatOptions } = this.props;
    const dateFormat = format || getLocaleDateString(locale, formatOptions);
    return dateFormat && dateFormat.toLowerCase().startsWith('y');
  }
}
