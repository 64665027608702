import { getCharacterCountWithLinebreaks } from './getCharacterCountWithLinebreaks';

/**
 * Truncates the provided string value to the provided max length, if required.
 * @param value {string} - the string value to truncate.
 * @param maxLength {number} - the max length the string value is allowed to have.
 * @returns {string} - the correctly sized string.
 */
export function truncateStringToSize(value: any, maxLength: any) {
  // If the remaining character count is less than zero, truncate the actual value
  // according to the remaining character count.
  const characterCountWithLinebreaks = getCharacterCountWithLinebreaks(value);
  const remainingCharacterCount = maxLength - characterCountWithLinebreaks;
  if (remainingCharacterCount < 0) {
    const lineBreaksCount = characterCountWithLinebreaks - value.length;
    return value.substring(0, maxLength - lineBreaksCount);
  }
  return value;
}
