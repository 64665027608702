import React from 'react';

import {
  formatDateToString,
  getLocaleDateString,
  DateInput,
  parseLocalISODate,
  parseUTCDate,
  DATE_CHANGE_SUCCESS,
  EMPTY_DATE,
  DATE_CHANGE_ERROR_INVALID
} from '@cvent/nucleus-core-datetime-utils';
import { select, resolve } from '@cvent/nucleus-dynamic-css';
import Icon from '@cvent/nucleus-icon';
import { injectTestId, resolveTestId } from '@cvent/nucleus-test-automation';
import { DateUtils } from 'nucleus-react-day-picker';
import PropTypes from 'prop-types';
import { defaultMemoize } from 'reselect';

import { Trigger, removeTriggerProps } from '../../containers/Trigger';
import { Caption } from '../../daypicker/Caption';
import {
  NucleusDayPicker,
  removeDayPickerProps as removeCalendarProps
} from '../../daypicker/DayPicker';
import { MonthYearDropdown } from '../../daypicker/MonthYearDropdown';
import { tapOrClick } from '../../touchEventHandlers';
import { isRequiredIf } from '../../utils/isRequiredIf';
import { isTouchEnabled } from '../../utils/isTouchEnabled';
import { removeKeys } from '../../utils/removeKeys';
import { FormElement, removeFormElementProps } from '../FormElement';
import { PickADateBase } from '../PickADateBase';
import { Textbox } from './Textbox';
import stylesButton from './Elements.less';

/**
Display a DayPicker form element composed of Trigger, Textbox, and DayPicker components.

onChange = function(fieldName, newValue, textValue, statusCode)
**/
export class PickADate extends PickADateBase {
  static displayName = 'PickADate';
  static propTypes = {
    ...PickADateBase.propTypes,
    // set the readOnly property of the textbox
    readOnlyTextbox: PropTypes.bool,
    // An array of dates to be disabled
    invalidDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    // The current value
    value: PropTypes.oneOfType([
      // DateInput class returned by this component as the fifth argument to onChange
      PropTypes.instanceOf(DateInput),
      // Date object
      PropTypes.instanceOf(Date),
      // Empty date symbol
      PropTypes.oneOf([EMPTY_DATE]),
      // Date string formatted as "YYYY-MM-DD HH:mm:ss"
      PropTypes.string
    ]),
    /** Function to localize text with given text resource id */
    translate: isRequiredIf(
      PropTypes.func,
      (props: any) =>
        (props.hasOwnProperty('changeYearMonth') &&
          (!props.hasOwnProperty('changeYearLabel') ||
            !props.hasOwnProperty('changeMonthLabel'))) ||
        !props.hasOwnProperty('navButtonPreviousMonthLabel') ||
        !props.hasOwnProperty('navButtonNextMonthLabel')
    ),
    /** Whether month and year should be rendered as dropdowns instead of text. Defaults to false */
    changeYearMonth: PropTypes.bool,
    /** Add Icon class to containing span of the trigger element. */
    showIcon: PropTypes.bool,
    /** Calendar icon name. Defaults to "scheduleFilled" */
    calendarIcon: PropTypes.string,
    /** Label for year's select dropdown. Should exist if changeYearMonth is true but translate is not provided. */
    changeYearLabel: isRequiredIf(
      PropTypes.string,
      (props: any) => props.hasOwnProperty('changeYearMonth') && !props.hasOwnProperty('translate')
    ),
    /** Label for month's select dropdown. Should exist if changeYearMonth is true but translate is not provided. */
    changeMonthLabel: isRequiredIf(
      PropTypes.string,
      (props: any) => props.hasOwnProperty('changeYearMonth') && !props.hasOwnProperty('translate')
    ),
    /** Default text resource id for year's dropdown label */
    changeYearLabelTextResourceId: PropTypes.string,
    /** Default text resource id for month's dropdown label */
    changeMonthLabelTextResourceId: PropTypes.string,
    /** Available Style Keys */
    style: PropTypes.shape({
      /** Style object applied to the Textbox component. */
      textbox: PropTypes.object,
      /** Style object applied to the Trigger component. */
      trigger: PropTypes.object,
      /** Icon style applied to the icon div. */
      icon: PropTypes.object,
      /** Style object applied to the DayPicker component. */
      calendar: PropTypes.object
    }),
    onDayFocus: PropTypes.func,
    /** A footer shown at the bottom of calendar flyout */
    calendarFooter: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    /** Force PickADate on mobile instead of native datepicker */
    hideNativeDatePicker: PropTypes.bool,
    showInstructionalText: PropTypes.bool
  };
  static defaultProps = {
    ...PickADateBase.defaultProps,
    showIcon: true,
    calendarIcon: 'scheduleFilled',
    changeYearLabelTextResourceId: 'NucleusCoreComponent_PickADate_YearDropdownLabel__resx',
    changeMonthLabelTextResourceId: 'NucleusCoreComponent_PickADate_MonthDropdownLabel__resx',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onDayFocus: () => {}
  };

  _userIsTyping: any;
  daypicker: any;

  constructor(props: any) {
    super(props);
    this.state = {
      textInput: this.getDateText(props),
      touchEnabled: isTouchEnabled(),
      lastValidInitialMonth: this.getLastValidInitialMonth(props),
      statusCode: null
    };

    this._userIsTyping = false;
    this.onMonthYearDropdownChange = this.onMonthYearDropdownChange.bind(this);
  }

  getValue(props = this.props) {
    if (props.value instanceof DateInput) {
      return props.value.date;
    }
    if (typeof props.value === 'string') {
      return parseLocalISODate(props.value);
    }
    return props.value;
  }

  getDateText(props: any) {
    if (props.value instanceof DateInput) {
      return props.value.dateText;
    }
    const state = this.state || {};
    if (
      !this._userIsTyping &&
      (state.statusCode !== DATE_CHANGE_ERROR_INVALID || props.value === EMPTY_DATE)
    ) {
      if (typeof props.value === 'string') {
        return this.getStringFromDate(parseUTCDate(props.value), props, 'UTC' as any);
      }
      return this.getStringFromDate(props.value, props);
    }
    return undefined;
  }

  handleChange(fieldName: any, date: any, newValue: any, statusCode: any, dateName: any) {
    this.props.onChange(
      fieldName,
      date,
      newValue,
      statusCode,
      dateName,
      new DateInput(date, statusCode, newValue)
    );
  }

  // Always return a consistent Date for each month so that react-day-picker doesn't reset the current month
  getInitialMonth = defaultMemoize((year: any, month: any) => {
    return new Date(year, month, 1);
  });

  getLastValidInitialMonth(props: any) {
    const value = this.getValue(props);
    const { minDate, maxDate } = props;

    const currentDate = new Date();
    let initialCalendarDate = new Date();

    if (value && value instanceof Date) {
      initialCalendarDate = new Date(value);
    } else if (
      (minDate && !maxDate && minDate <= currentDate) ||
      (maxDate && !minDate && currentDate <= maxDate) ||
      (maxDate && minDate && minDate <= currentDate && currentDate <= maxDate)
    ) {
      initialCalendarDate = currentDate;
    } else if (minDate) {
      initialCalendarDate = minDate;
    } else if (maxDate) {
      initialCalendarDate = maxDate;
    }

    return this.getInitialMonth(initialCalendarDate.getFullYear(), initialCalendarDate.getMonth());
  }

  changedDate(curdate: any, newDate: any) {
    const bothDateInstance = curdate instanceof Date && newDate instanceof Date;
    if (
      (bothDateInstance && !DateUtils.isSameDay(curdate, newDate)) ||
      (!bothDateInstance && curdate !== newDate)
    ) {
      return true;
    }
    return false;
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (
      this.changedDate(this.props.value, nextProps.value) ||
      this.changedDate(this.props.minDate, nextProps.minDate) ||
      this.changedDate(this.props.maxDate, nextProps.maxDate)
    ) {
      const newState = {
        lastValidInitialMonth: this.getLastValidInitialMonth(nextProps)
      };
      const textInput = this.getDateText(nextProps);
      if (textInput !== undefined) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'textInput' does not exist on type '{ las... Remove this comment to see the full error message
        newState.textInput = textInput;
      }
      this.setState(newState);
    }
  }

  onBlur(e: any) {
    this._userIsTyping = false;
    this.onLoseFocus();
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  /* @ts-expect-error Class 'PickADateBase' defines instance member property 'onCalendarClick', but extended class 'PickADate' defines it as instance member function. */
  onCalendarClick(e: any, day: any, { disabled }: any) {
    this._userIsTyping = false;
    if (disabled) {
      return;
    }
    const dateString = this.getStringFromDate(day);
    this.setState({ textInput: dateString, statusCode: DATE_CHANGE_SUCCESS }, () => {
      if (this.props.hasOwnProperty('onNativeChange')) {
        this.props.onNativeChange(day);
        if (this.trigger) {
          this.trigger.handleHide();
        }
        return;
      }
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 5 arguments, but got 4.
      this.handleChange(this.props.fieldName, day, dateString, DATE_CHANGE_SUCCESS);
      if (this.trigger) {
        this.trigger.handleHide();
      }
    });
  }

  /* @ts-expect-error Class 'PickADateBase' defines instance member property 'isDisabledDays', but extended class 'PickADate' defines it as instance member function. */
  isDisabledDays(day: any, minDate: any, maxDate: any, invalidDates: any) {
    if (minDate && !DateUtils.isSameDay(day, minDate) && day < minDate) {
      return true;
    }
    if (maxDate && !DateUtils.isSameDay(day, maxDate) && day > maxDate) {
      return true;
    }
    if (invalidDates) {
      for (const date of invalidDates) {
        if (DateUtils.isSameDay(day, date)) {
          return true;
        }
      }
    }
    return false;
  }

  onMonthYearDropdownChange(newDate: any) {
    this.setState({
      lastValidInitialMonth: this.getInitialMonth(newDate.getFullYear(), newDate.getMonth())
    });
  }

  render() {
    const {
      minDate,
      maxDate,
      minRange,
      maxRange,
      invalidDates,
      showIcon,
      changeYearMonth,
      calendarIcon,
      translate,
      changeMonthLabel,
      changeMonthLabelTextResourceId,
      changeYearLabel,
      changeYearLabelTextResourceId,
      validationState,
      navButtonNextMonthLabel,
      navButtonPreviousMonthLabel,
      navButtonNextMonthLabelTextResourceId,
      navButtonPreviousMonthLabelTextResourceId,
      calendarFooter,
      hideNativeDatePicker,
      readOnlyTextbox,
      ...rest
    } = removeKeys(this.props, [
      'format',
      'formatDate',
      'parseDate',
      'formatOptions',
      'locale',
      'navButtonNextMonthElement',
      'navButtonPreviousMonthElement'
    ]);
    const { disabled, readOnly } = rest;
    const dateType = this.checkForDateType();
    const value = this.getValue();

    let dateControl;
    const textboxProps = removeTriggerProps(removeCalendarProps(rest));
    if (this.props.hasOwnProperty('onNativeChange')) {
      // this is necessary! Because Textbox also suports NucleusField, if we do not remove onNativeChange from props,
      // this.onChange/onChangeMobile will not be called properly
      delete textboxProps.onNativeChange;
    }
    if (
      dateType === 'date' &&
      !hideNativeDatePicker &&
      this.state.touchEnabled === true &&
      this.isMobile()
    ) {
      // The icon is for sight users to add visual hint, and it's intentional not to make it focusable.
      dateControl = (
        <div {...resolve(this.props, showIcon ? 'dateInputWrapper' : null)} onClick={this.onFocus}>
          {showIcon && (
            <span {...resolve(this.props, 'iconWrapper')}>
              <Icon icon={calendarIcon} />
            </span>
          )}
          <Textbox
            {...textboxProps}
            {...resolve(this.props, 'textbox', validationState)}
            {...resolveTestId(this.props)}
            ref={(c: any) => (this.textbox = c)}
            min={minDate ? formatDateToString(minDate, 'yy-mm-dd') : undefined}
            max={maxDate ? formatDateToString(maxDate, 'yy-mm-dd') : undefined}
            value={value && value instanceof Date ? formatDateToString(value, 'yy-mm-dd') : ''}
            onChange={this.onChangeMobile}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            type={dateType}
            children={undefined}
          />
        </div>
      );
    } else {
      const formatString = getLocaleDateString(this.props.locale, this.props.formatOptions);
      const localizedFormatString = translate
        ? translate(
            `NucleusCoreComponent_PickADate_TextboxPlaceholder_${formatString}__resx`,
            undefined,
            () => formatString
          )
        : formatString;
      // The icon is for sight users to add visual hint, and it's intentional not to make it focusable.
      const triggerElement = (
        <div
          {...resolve(this.props, showIcon ? 'dateInputWrapper' : null)}
          {...tapOrClick(this.onFocus)}
        >
          {showIcon && (
            <span {...resolve(this.props, 'iconWrapper')}>
              <Icon icon={calendarIcon} />
            </span>
          )}
          <Textbox
            {...textboxProps}
            {...resolve(this.props, 'textbox', validationState)}
            {...resolveTestId(this.props)}
            value={this.state.textInput || ''}
            ref={(c: any) => (this.textbox = c)}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            {...tapOrClick(this.onFocus)}
            onBlur={this.onBlur}
            children={undefined}
            readOnly={readOnlyTextbox || this.isMobile()}
            placeholder={this.props.placeholder || localizedFormatString}
          />
          {this.props.showInstructionalText ? (
            <div id={this.props.fieldName} className={stylesButton.instructionalText}>
              {localizedFormatString}
            </div>
          ) : (
            ''
          )}
        </div>
      );

      // If the input is disabled or readOnly, do not render the trigger or calendar components.
      if (disabled || readOnly) {
        return triggerElement;
      }

      const resolvedProps = removeKeys(removeFormElementProps(removeTriggerProps(this.props)), [
        'dateStringFormat',
        'parseDate',
        'showIcon',
        'calendarIcon',
        'value',
        'minDate',
        'maxDate',
        'minRange',
        'maxRange',
        'formatDate',
        'formatOptions',
        'onChange',
        'changeYearMonth',
        'changeYearLabel',
        'changeMonthLabel',
        'changeYearLabelTextResourceId',
        'changeMonthLabelTextResourceId',
        'translate',
        'validationState',
        'navButtonPreviousMonthLabel',
        'navButtonNextMonthLabel',
        'navButtonNextMonthLabelTextResourceId',
        'navButtonPreviousMonthLabelTextResourceId',
        'calendarFooter',
        'hideNativeDatePicker',
        'invalidDates'
      ]);

      const yearFirst = this.isYearFirst();
      const captionElement = changeYearMonth ? (
        <MonthYearDropdown
          onChange={this.onMonthYearDropdownChange}
          changeYearLabel={
            changeYearLabel || (translate ? translate(changeYearLabelTextResourceId) : '')
          }
          changeMonthLabel={
            changeMonthLabel || (translate ? translate(changeMonthLabelTextResourceId) : '')
          }
          yearFirst={yearFirst}
          fromMonth={minDate}
          toMonth={maxDate}
          minRange={minRange}
          maxRange={maxRange}
        />
      ) : (
        <Caption yearFirst={yearFirst} />
      );
      let dayPicker = (
        // @ts-expect-error ts-migrate(2607) FIXME: JSX element class does not support attributes beca... Remove this comment to see the full error message
        <NucleusDayPicker
          ref={(el: any) => {
            this.daypicker = el;
          }}
          {...resolvedProps}
          {...select(this.props, 'calendar')}
          onKeyDown={this.props.onKeyDown}
          selectedDays={(day: any) =>
            value && value instanceof Date ? DateUtils.isSameDay(value, day) : false
          }
          onDayClick={this.onCalendarClick}
          onDayFocus={this.props.onDayFocus}
          enableOutsideDays
          initialMonth={this.state.lastValidInitialMonth}
          fromMonth={minDate}
          captionElement={captionElement}
          toMonth={maxDate}
          disabledDays={(day: any) => this.isDisabledDays(day, minDate, maxDate, invalidDates)}
          navButtonNextMonthLabel={
            navButtonNextMonthLabel ||
            (translate ? translate(navButtonNextMonthLabelTextResourceId) : '')
          }
          navButtonPreviousMonthLabel={
            navButtonPreviousMonthLabel ||
            (translate ? translate(navButtonPreviousMonthLabelTextResourceId) : '')
          }
          tabIndex={-1}
        />
      );
      if (calendarFooter) {
        dayPicker = (
          <div {...resolve(this.props, 'calendarFlyout')}>
            {dayPicker}
            {calendarFooter}
          </div>
        );
      }
      dateControl = (
        <Trigger
          {...this.props}
          {...injectTestId('trigger')}
          {...select(this.props, 'trigger')}
          ref={c => (this.trigger = c)}
        >
          {triggerElement}
          <div
            ref={el => {
              this.calendarWrapper = el;
            }}
            onKeyDown={this.onKeyDownCalendar}
          >
            {dayPicker}
          </div>
        </Trigger>
      );
    }
    return dateControl;
  }
}

type Props = {
  children?: React.ReactNode;
  style?: {
    input?: any;
  };
  displayValid?: boolean;
  validationState?: any; // TODO: PropTypes.oneOf(['valid', 'error', undefined])
};

/**
FormElement wrapper around PickADate. This is the default export.
**/
const PickADateFormElement = (props: Props) => {
  const { children, displayValid, validationState, ...rest } = props;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'errorMessages' does not exist on type '{... Remove this comment to see the full error message
  const { errorMessages } = rest;
  const errorsPresent = errorMessages && Object.keys(errorMessages).length > 0;
  const validState = displayValid ? 'valid' : undefined;
  const resolvedValidationState = validationState || (errorsPresent ? 'error' : validState);
  return (
    // @ts-expect-error error TS2769: No overload matches this call
    <FormElement {...rest}>
      <PickADate
        {...rest}
        {...select(props, 'input')}
        {...injectTestId('input')}
        validationState={resolvedValidationState}
        role="spinbutton"
      />
      {children}
    </FormElement>
  );
};
PickADateFormElement.displayName = 'PickADateFormElement';

export { PickADateFormElement };
