import sanitizeText from './sanitizeText';

/**
 * Sanitizes the input string, but returns it in an object form to be consumed
 * by the react standard attribute 'dangerouslySetInnerHTML'.
 **/
export default function sanitizeInnerHtml(inputText: any) {
  return {
    __html: sanitizeText(inputText)
  };
}
