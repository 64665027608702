import React from 'react';

import { resolve, select } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import { Button, ButtonGroup } from 'nucleus-core';

/**
 * The different positions a user may be in while navigating a linear process.
 */
export const BEGINNING = 'BEGINNING';
export const MIDDLE = 'MIDDLE';
export const END = 'END';
export const SINGLE_STEP = 'SINGLE_STEP';

/**
 * The different directions for a user to navigate through a linear process.
 */
export const FORWARD = 'FORWARD';
export const BACKWARD = 'BACKWARD';
export const COMPLETE = 'COMPLETE';
export const EXIT = 'EXIT';

const createNavigationButton = (
  id: any,
  displayText: any,
  onClick: any,
  props: any,
  disable = false,
  isLink = false,
  type: 'button' | 'submit' | 'reset' = 'button'
) => {
  return (
    <Button
      key={id}
      id={id}
      kind={isLink ? 'link' : undefined}
      onClick={onClick}
      disabled={disable}
      type={type}
      {...select(props, id)}
      classes={props.classes}
    >
      {displayText}
    </Button>
  );
};

type Props = {
  currentPosition: any; // TODO: PropTypes.oneOf([BEGINNING, MIDDLE, END, SINGLE_STEP])
  disableForwardNavigation?: boolean;
  onNavigateRequest: (...args: any[]) => any;
  displayText: {
    forward: string;
    backward: string;
    complete: string;
    exit: string;
  };
  textRenderer?: {
    forward?: (str: string) => JSX.Element;
    backward?: (str: string) => JSX.Element;
    complete?: (str: string) => JSX.Element;
    exit?: (str: string) => JSX.Element;
  };
  showCompleteForAllSteps?: boolean;
  showBackwardButton?: boolean;
  showExitButton?: boolean;
  renderExitAsLink?: boolean;
  reverseButtonOrderOnMobile?: boolean;
  disableAllButtons?: boolean;
  // Used in resolve function // TODO: determine neccesary fields for classes
  classes?: Record<string, unknown>;
  // Used in resolve function
  style?: Record<string, unknown>;
};

/**
 * A display component which allows a user to navigate forwards and backwards
 * to complete a linear process. The navigator also allows the user to exit
 * the process at any time via the exit option. The navigation options displayed
 * is determined by where the user is in the linear process.
 *
 * Note: A linear process will typically be, but is not limited to, a set of
 *       web pages which have a sequential order for a user to step through.
 */
export const LinearNavigator = (props: Props) => {
  const {
    currentPosition,
    onNavigateRequest,
    disableForwardNavigation,
    displayText,
    textRenderer,
    showCompleteForAllSteps,
    showExitButton,
    showBackwardButton,
    renderExitAsLink,
    reverseButtonOrderOnMobile,
    disableAllButtons = false
  } = props;

  const buttons = [];
  const onFirstPage = currentPosition === BEGINNING || currentPosition === SINGLE_STEP;
  const onLastPage = currentPosition === END || currentPosition === SINGLE_STEP;

  // Create the set of buttons available for navigation.
  if (!onFirstPage && showBackwardButton) {
    buttons.push(
      createNavigationButton(
        'backward',
        textRenderer?.backward ? textRenderer.backward(displayText.backward) : displayText.backward,
        () => onNavigateRequest(BACKWARD),
        props,
        disableAllButtons
      )
    );
  }
  if (showExitButton && !renderExitAsLink) {
    buttons.push(
      createNavigationButton(
        'exit',
        textRenderer?.exit ? textRenderer.exit(displayText.exit) : displayText.exit,
        () => onNavigateRequest(EXIT),
        props,
        disableAllButtons
      )
    );
  }
  if (!onLastPage) {
    buttons.push(
      createNavigationButton(
        'forward',
        textRenderer?.forward ? textRenderer.forward(displayText.forward) : displayText.forward,
        () => onNavigateRequest(FORWARD),
        props,
        disableAllButtons || disableForwardNavigation,
        false,
        'submit'
      )
    );
  }
  if (onLastPage || showCompleteForAllSteps) {
    buttons.push(
      createNavigationButton(
        'complete',
        textRenderer?.complete ? textRenderer.complete(displayText.complete) : displayText.complete,
        () => onNavigateRequest(COMPLETE),
        props,
        disableAllButtons || disableForwardNavigation
      )
    );
  }

  return (
    <div {...resolveTestId(props)} {...resolve(props, 'container')}>
      <ButtonGroup
        classes={props.classes}
        alignment="center"
        {...(reverseButtonOrderOnMobile ? { reverseButtonOrderOnMobile } : {})}
      >
        {buttons}
      </ButtonGroup>
      {showExitButton &&
        renderExitAsLink &&
        createNavigationButton(
          'exit',
          displayText.exit,
          () => onNavigateRequest(EXIT),
          props,
          false,
          true
        )}
    </div>
  );
};

LinearNavigator.displayName = 'LinearNavigator';
