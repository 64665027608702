/**
 * For the character count, carrige returns must always be treated as two characters
 * so that they work properly with windows based systems. The string.length
 * property in all browsers treats them as only one character, so we must make this
 * adjustment ourselves. (Some browsers implement the maxlength property so that it
 * recognizes this limitation and some don't, but all browsers treat it in the actual
 * value as one character)
 * @param value {string} - The string value to calculate character count for.
 * @returns {number} - The character count.
 */

export function getCharacterCountWithLinebreaks(value: any) {
  let characterCount = 0;
  if (value && value.length > 0) {
    const linebreakRegex = /[\r]?\n/g;
    const newValue = value.replace(linebreakRegex, String.fromCharCode(13, 10));
    characterCount = newValue.length;
  }
  return characterCount;
}
