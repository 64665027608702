import { PseudoStateStyleObject, PseudoStateStyleCalculator } from './types';

/**
 * Sets the pseudo-state style back to the base style.
 * @see PseudoStateStyleMode#NONE
 */
export const NoStyleModeCalculator: PseudoStateStyleCalculator = {
  updateStyle(
    baseStyle: Partial<PseudoStateStyleObject>,
    pseudoStateStyle: Partial<PseudoStateStyleObject>,
    themePalette: Record<string, string>
  ): Partial<PseudoStateStyleObject> {
    return baseStyle;
  }
};
