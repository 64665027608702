/* eslint react/prop-types: [2, { ignore: ["classes", "options", "onNativeChange"] }] */
/* eslint-disable react/no-multi-comp */
import React from 'react';

import { select, resolve } from '@cvent/nucleus-dynamic-css';

import { BreakPoint, Button } from '../../buttons/Button';
import { Trigger, removeTriggerProps } from '../../containers/Trigger';
import { removeKeys } from '../../utils/removeKeys';
import { FormElement, removeFormElementProps } from '../FormElement';
import {
  MultiSelectDropDownMenu,
  removeMultiSelectDropdownMenuProps as removeMenuProps
} from './MultiSelectDropDownMenu';

/*
(ts-migrate) TODO: Migrate the remaining prop types
...FormElement.propTypes
*/
type OwnMultiSelectDropDownProps = {
  selectedTextCallback: (...args: any[]) => any;
  breakPoint?: BreakPoint;
  inlineLabel?: string;
  inlineElement?: React.ReactElement;
  style?: {
    trigger?: any;
    menu?: any;
    button?: any;
  };
  selectedValues?: any; // TODO: isRequiredIf(PropTypes.array, props => props.hasOwnProperty('onNativeChange'))
};

type MultiSelectDropDownProps = OwnMultiSelectDropDownProps &
  typeof MultiSelectDropDown.defaultProps;

/**
MultiSelect Component
**/
export class MultiSelectDropDown extends React.Component<MultiSelectDropDownProps> {
  static displayName = 'MultiSelectDropDown';
  static defaultProps = {
    allowMouse: false
  };
  constructor(props: MultiSelectDropDownProps) {
    super(props);
    this.getSelectedText = this.getSelectedText.bind(this);
  }
  /**
   * Determines what text to display according to the currently selected options.
   */
  getSelectedText() {
    const selectedValues: any = [];
    const isNucleusField =
      this.props.hasOwnProperty('onNativeChange') && this.props.hasOwnProperty('selectedValues');
    if (this.props.inlineLabel) {
      return this.props.inlineLabel;
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'options' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.props.options.hasOwnProperty('selectedValues')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'options' does not exist on type 'Readonl... Remove this comment to see the full error message
      this.props.options.optionArray.forEach((option: any) => {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'options' does not exist on type 'Readonl... Remove this comment to see the full error message
        if (this.props.options.selectedValues.indexOf(option.value) >= 0) {
          selectedValues.push(option.name);
        }
      });
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'options' does not exist on type 'Readonl... Remove this comment to see the full error message
    } else if (this.props.options.length && this.props.options[0].hasOwnProperty('category')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'options' does not exist on type 'Readonl... Remove this comment to see the full error message
      this.props.options.forEach((category: any) => {
        category.items.forEach((option: any) => {
          if (isNucleusField) {
            if (this.props.selectedValues.indexOf(option.value) !== -1) {
              selectedValues.push(option.name);
            }
          } else if (option.checked) {
            selectedValues.push(option.name);
          }
        });
      });
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'options' does not exist on type 'Readonl... Remove this comment to see the full error message
      this.props.options.forEach((option: any) => {
        if (isNucleusField) {
          if (this.props.selectedValues.indexOf(option.value) !== -1) {
            selectedValues.push(option.name);
          }
        }
        if (option.checked) {
          selectedValues.push(option.name);
        }
      });
    }
    switch (selectedValues.length) {
      case 1:
        return selectedValues[0];
      case 0:
        return '';
      default:
        return this.props.selectedTextCallback(selectedValues.length);
    }
  }
  render() {
    const { classes, breakPoint, ...rest } = removeKeys(this.props, ['selectedTextCallback']);
    return (
      <Trigger {...this.props} {...select(this.props, 'trigger')}>
        <Button
          {...removeFormElementProps(removeMenuProps(removeTriggerProps(rest)))}
          classes={classes}
          breakPoint={breakPoint}
          {...resolve(this.props, 'button', breakPoint)}
        >
          {this.getSelectedText()}
          {this.props.inlineElement}
        </Button>
        <MultiSelectDropDownMenu {...this.props} {...select(this.props, 'menu')} />
      </Trigger>
    );
  }
}

type MultiSelectDropDownFormElementProps = {
  children?: React.ReactNode;
  breakPoint?: BreakPoint;
  style?: {
    multiselect?: any;
  };
};

/**
FormElement wrapper around MultiSelectDropDown. This is the default export.
**/
const MultiSelectDropDownFormElement = (props: MultiSelectDropDownFormElementProps) => {
  const { children, ...rest } = props;
  return (
    // @ts-expect-error error TS2769: No overload matches this call
    <FormElement {...rest}>
      <MultiSelectDropDown {...rest} {...select(props, 'multiselect')} />
      {children}
    </FormElement>
  );
};
MultiSelectDropDownFormElement.displayName = 'MultiSelectDropDownFormElement';

export { MultiSelectDropDownFormElement };
