/**
 * Version 2 migrations for the website.
 * - Migrate any spacing settings from the parent levels onto the individual widgets.
 * - Remove any spacing settings from sections.
 *
 * EDIT: The original migration here wasn't written correctly, and because of a mix up
 * of a feature built after it messing with version numbers, we can't safely run it
 * anymore, so just increment the version number.
 */
export function v2(website: any) {
  return {
    ...website,
    frameworkVersion: 2
  };
}
