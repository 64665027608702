/* @flow */

import { UPDATE_CRITERIA } from '../actionTypes';
import { LOADING_MONTH, LOAD_MONTH_SUCCESS, LOAD_MONTH_FAILURE } from '../actionTypes';
import type { Action, Criteria } from './types';

const TODAY = new Date();

export const updateCriteria = () => {
  return {
    type: UPDATE_CRITERIA
  };
};

/**
 * maintains the searchCriteria state for the calendar
 * @param state
 * @param action
 * @returns {*}
 */
export default function searchCriteria(
  state: Criteria = {
    keyword: '',
    fromDate: null,
    toDate: null,
    filters: {},
    currentMonth: new Date(TODAY.getFullYear(), TODAY.getMonth())
  },
  action: Action
): Criteria {
  switch (action.type) {
    case UPDATE_CRITERIA: {
      // @ts-expect-error TS(2339): Property 'criteria' does not exist on type 'Object... Remove this comment to see the full error message
      const { criteria } = action.payload;
      return {
        ...state,
        ...criteria
      };
    }
    case LOADING_MONTH: {
      // @ts-expect-error TS(2339): Property 'month' does not exist on type 'Object | ... Remove this comment to see the full error message
      const { month } = action.payload;
      return {
        ...state,
        currentMonth: month
      };
    }
    case LOAD_MONTH_SUCCESS: {
      // @ts-expect-error TS(2339): Property 'criteria' does not exist on type 'Object... Remove this comment to see the full error message
      const { criteria } = action.payload;
      return {
        ...state,
        fromDate: criteria.fromDate,
        toDate: criteria.toDate
      };
    }
    case LOAD_MONTH_FAILURE: {
      // @ts-expect-error TS(2339): Property 'navigating' does not exist on type 'Obje... Remove this comment to see the full error message
      const { navigating, month, forward } = action.payload;
      if (navigating) {
        // reset current month back
        const monthToLoad = new Date(month.getTime());
        if (forward) {
          monthToLoad.setMonth(monthToLoad.getMonth() - 1);
        } else {
          monthToLoad.setMonth(monthToLoad.getMonth() + 1);
        }
        return {
          ...state,
          currentMonth: monthToLoad
        };
      }
      return state;
    }
    default:
      return state;
  }
}
