import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { Spinner } from 'nucleus-core';
import ModifiedSpinnerStyles from './styles/ProcessingOverlay.less';

type Props = {
  classes: $TSFixMe;
  translate: (res: string, tokens?: Object) => string;
};

/**
 * ProcessingOverlay - Renders a processing overlay for the calendar widget
 **/
const ProcessingOverlay = (props: Props) => (
  <div
    {...resolve(props, 'searchOverlayContainer')}
    role="alert"
    aria-live="assertive"
    aria-label={props.translate('_calendarListView_viewMoreProcessing__resx')}
  >
    <div {...resolve(props, 'searchOverlayContent')}>
      <Spinner numberOfCircles={2} classes={ModifiedSpinnerStyles} />
    </div>
  </div>
);

ProcessingOverlay.displayName = 'ProcessingOverlay';
export default ProcessingOverlay;
