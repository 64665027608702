import React from 'react';

import { ThemeImagesContext, getBackground, isIE as isIEBrowser } from 'nucleus-widgets';

import AppContainerStyles from './AppContainer.less';
import { BrowserSupportMessage } from './BrowserSupportMessage';

type Props = {
  theme: any;
  children: any;
  imageLookup?: any;
  browserFeatures?: {
    supportsWebp?: boolean;
  };
  disableBrowserWarning?: boolean;
  customContainerStyle?: any;
};

type State = any;

/**
 * A container that should be present in the root of your guest side application
 * that applies global level theme settings to the page (backgrounds, widths, etc).
 * The children of this component should be your website content.
 */
export class AppContainer extends React.Component<Props, State> {
  static displayName = 'AppContainer';

  constructor(props: Props) {
    super(props);
    const isIE = isIEBrowser();
    this.state = {
      isIE,
      dismissBrowserWarning: isIE && localStorage.getItem('dismissBrowserWarning')
    };
  }

  hideBrowserWarning = () => {
    this.setState({ dismissBrowserWarning: true });
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean' is not assignable to pa... Remove this comment to see the full error message
    localStorage.setItem('dismissBrowserWarning', true);
  };

  render() {
    const {
      theme,
      children,
      imageLookup,
      browserFeatures: { supportsWebp } = {},
      disableBrowserWarning,
      customContainerStyle
    } = this.props;
    const { isIE, dismissBrowserWarning } = this.state;
    const browserWarning =
      isIE && !disableBrowserWarning && !dismissBrowserWarning ? (
        <BrowserSupportMessage onDismiss={this.hideBrowserWarning} />
      ) : undefined;
    const customStyles = (theme.customCSS?.enabled && theme.customCSS?.wrappedCustomCSSInput) || '';

    return (
      <ThemeImagesContext.Consumer>
        {(themeImages: any) => {
          const backgroundStyles = {
            ...getBackground(
              theme.global,
              {},
              theme.global.palette,
              themeImages,
              imageLookup,
              supportsWebp
            ),
            borderRadius: 0,
            ...customContainerStyle
          }; // Fixed position backgrounds require a different DOM structure.
          if (backgroundStyles.fixedPosition) {
            return (
              <div className={AppContainerStyles.wrapper}>
                <div
                  className={`${AppContainerStyles.container} ${AppContainerStyles.fixedBackground}`}
                  style={backgroundStyles}
                />
                {!customStyles ? null : <style type="text/css">{customStyles}</style>}
                {browserWarning}
                <div style={{ maxWidth: theme.global.dimensions.maxWidth, margin: 'auto' }}>
                  {children}
                </div>
              </div>
            );
          }
          return (
            <div className={AppContainerStyles.container} style={backgroundStyles}>
              {!customStyles ? null : <style type="text/css">{customStyles}</style>}
              {browserWarning}
              <div style={{ maxWidth: theme.global.dimensions.maxWidth, margin: 'auto' }}>
                {children}
              </div>
            </div>
          );
        }}
      </ThemeImagesContext.Consumer>
    );
  }
}
