import without from 'lodash/without';

import { getPseudoStateTabs } from '../../utils/fields/getPseudoStateTabs';
import { FieldGroupings } from './FieldGroupings';

const StyleMenuFields = {
  widgetStyles: {
    menu: '_fieldMenu_widget__resx',
    key: null,
    fields: [...FieldGroupings.widgetStyles]
  }
};

const elementStylesPseudoStateTabs = getPseudoStateTabs({
  defaultTabFields: [...FieldGroupings.elementStyles],
  hoverTabFields: [...FieldGroupings.elementHoverStyles]
});

const elementStylesPseudoStateTabsV2 = getPseudoStateTabs({
  defaultTabFields: [...FieldGroupings.elementStyles],
  hoverTabFields: [...FieldGroupings.elementHoverStylesV2]
});

const widgetElementStylesPseudoStateTabs = getPseudoStateTabs({
  defaultTabFields: [...without(FieldGroupings.widgetElementStyles, 'styleMapping')],
  hoverTabFields: [...FieldGroupings.elementHoverStyles]
});

const widgetElementStylesPseudoStateTabsV2 = getPseudoStateTabs({
  defaultTabFields: [...without(FieldGroupings.widgetElementStyles, 'styleMapping')],
  hoverTabFields: [...FieldGroupings.elementHoverStylesV2]
});

export {
  StyleMenuFields,
  elementStylesPseudoStateTabs,
  elementStylesPseudoStateTabsV2,
  widgetElementStylesPseudoStateTabs,
  widgetElementStylesPseudoStateTabsV2
};
