import { defaultMemoize } from 'reselect';

import { EditorPermissions as Permissions } from './EditorPermissions';

// See the `Permissions Object` section in `nucleus-site-editor/docs/PERMISSIONS.md`
// for descriptions of each permission.
const basePermissions = {
  general: {
    primaryActionBar: {}
  },
  canvas: {
    content: Permissions.FULL,
    itemSelection: Permissions.FULL,
    itemHover: Permissions.FULL,
    itemMenu: Permissions.FULL
  },
  fields: {
    layoutItem: {
      base: Permissions.FULL,
      fieldNamesRegex: {},
      layoutItemTypes: {}
    },
    theme: {
      base: Permissions.FULL,
      globalTheming: Permissions.FULL,
      sectionTheming: Permissions.FULL,
      RTETheming: Permissions.FULL,
      fieldNamesRegex: {},
      themeSectionTypes: {}
    }
  }
};

/**
 * A function to get the current application permissions object given the current state of the application.
 * Will merge our base permissions object with any user configured permissions from the editorConfig.getPermissions
 * function, and then apply any specialized framework rules if the permissions need to be altered.v
 */
export const getPermissions = defaultMemoize((state: any) => {
  const { editorConfig } = state;

  // Get the user defined permissions object.
  const userPermissions = editorConfig.getPermissions
    ? editorConfig.getPermissions(state) || {}
    : {};

  // Combine the user defined permissions object with our base permissions object.
  const resolvedPermissions = {
    ...basePermissions,
    ...userPermissions,
    general: {
      ...basePermissions.general,
      ...userPermissions.general
    },
    canvas: {
      ...basePermissions.canvas,
      ...userPermissions.canvas
    },
    fields: {
      ...basePermissions.fields,
      ...userPermissions.fields,
      layoutItem: {
        ...basePermissions.fields.layoutItem,
        ...(userPermissions.fields ? userPermissions.fields.layoutItem : {})
      },
      theme: {
        ...basePermissions.fields.theme,
        ...(userPermissions.fields ? userPermissions.fields.theme : {})
      }
    }
  };

  // If the build tab is off, force canvas permissions for content and item selection off.
  const editorTabs = editorConfig.getEditorPanelTabs(state);
  const hasBuildTab = editorTabs.find((tab: any) => tab.type === 'EditorTabTypes.BUILD');
  if (!hasBuildTab) {
    resolvedPermissions.canvas.content = Permissions.NONE;
    resolvedPermissions.canvas.itemSelection = Permissions.NONE;
  }

  return resolvedPermissions;
});
