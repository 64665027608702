import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import { removeKeys } from '../utils/removeKeys';

type Props = {
  classes?: any;
  dragStart?: (...args: any[]) => any;
  children?: React.ReactNode;
  style?: {
    dragHandle?: any;
  };
};

/**
DragHandle - A wrapper component to make the child content a handle for a draggable container.
Designed to be used as a direct child of a DragContainer component.
**/
const DragHandle = (props: Props) => {
  const { dragStart, children, ...rest } = removeKeys(props, ['classes']);
  return (
    <div
      {...rest}
      {...resolveTestId(props)}
      {...resolve(props, 'dragHandle')}
      onMouseDown={dragStart}
    >
      {children}
    </div>
  );
};

DragHandle.displayName = 'DragHandle';
export { DragHandle };
