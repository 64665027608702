import { WIDGET } from './LayoutTypes';

/**
 * Traverses down through the tree of layout items to collect all widget
 * instances and push them into the 'widgets' parameter.
 * @param {object} layoutItems - the layoutItems portion of application state.
 * @param {object} item - the top level layout item to traverse down from.
 * @param {array} widgets - array where all of the discovered child widgets get pushed into.
 * @param {array} includeDescendants - indicates whether you want to include child widget(s) of widgets or not.
 */
export function getAllChildWidgetsInItem(
  layoutItems: any,
  item: any,
  widgets: string[] = [],
  includeDescendants = false
) {
  item.layout.childIds.forEach((childId: string) => {
    if (includeDescendants) {
      const widget = layoutItems[childId];
      let isChildWidget = false;
      // Check if widget's grandparent is also a widget
      if (layoutItems[layoutItems[widget?.layout?.parentId]?.layout?.parentId]) {
        isChildWidget =
          layoutItems[layoutItems[widget?.layout?.parentId]?.layout?.parentId]?.layout?.type ===
          WIDGET;
      }

      if (widget?.layout?.type === WIDGET && !isChildWidget) {
        widgets.push(layoutItems[childId]);
      }
    } else {
      if (layoutItems[childId].layout.type === WIDGET) {
        widgets.push(layoutItems[childId]);
      }
    }
    getAllChildWidgetsInItem(layoutItems, layoutItems[childId], widgets, includeDescendants);
  });
}
