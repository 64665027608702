import React from 'react';

import { FlexGrid as Grid } from 'nucleus-core';

type Props = React.PropsWithChildren<Record<string, unknown>>;

/**
 * Default renderer for a CONTAINER type layout item.
 */
export class Container extends React.Component<Props> {
  static displayName = 'Container';
  render() {
    const { children } = this.props;
    return <Grid breakPoint="small">{children}</Grid>;
  }
}
