// ACTION TYPES
const LOAD_CUSTOM_FONTS = 'nucleus-widgets/customFonts/LOAD_CUSTOM_FONTS';

// These are the current font file formats we support.
const FONT_FORMATS = {
  woff2: 'woff2',
  woff: 'woff',
  ttf: 'truetype',
  otf: 'opentype'
};
export function getFileFormat(url: any) {
  const extension = url.split('.').pop();
  if (extension.startsWith('woff2')) {
    return FONT_FORMATS.woff2;
  }
  if (extension.startsWith('woff')) {
    return FONT_FORMATS.woff;
  }
  if (extension.startsWith('ttf')) {
    return FONT_FORMATS.ttf;
  }
  if (extension.startsWith('otf')) {
    return FONT_FORMATS.otf;
  }
  return undefined;
}

// The ranks we apply to each supported font format.
const FONT_FORMAT_RANKS = {
  [FONT_FORMATS.woff2]: 4,
  [FONT_FORMATS.woff]: 3,
  [FONT_FORMATS.ttf]: 2,
  [FONT_FORMATS.otf]: 1
};
/**
 * Sort the urls based on the rank precedence we assign to each font file type. The order for file types matters for
 * what order the browser tries to load the font file (it will stop on the first file it can load and read).
 * @param {*} url1
 * @param {*} url2
 */
function sortUrls(url1: any, url2: any) {
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const formatRank1 = FONT_FORMAT_RANKS[getFileFormat(url1)] || 0;
  // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
  const formatRank2 = FONT_FORMAT_RANKS[getFileFormat(url2)] || 0;
  if (formatRank1 > formatRank2) {
    return -1;
  }
  if (formatRank1 < formatRank2) {
    return 1;
  }
  return 0;
}

/**
 * Font weights are numbers from 100 - 900 in increments of 100, we want to display from low to high.
 * Font style is one of two values, 'normal' or 'italic'. Each font weight can be either style, and normal comes first.
 * @param {*} file1
 * @param {*} file2
 */
function sortFiles(file1: any, file2: any) {
  if (file1.fontWeight < file2.fontWeight) {
    return -1;
  }
  if (file1.fontWeight > file2.fontWeight) {
    return 1;
  }
  if (file1.fontStyle === 'normal' && file2.fontStyle === 'italic') {
    return -1;
  }
  if (file1.fontStyle === 'italic' && file2.fontStyle === 'normal') {
    return 1;
  }
  return 0;
}

// ACTIONS
/**
 * Takes in an array of account custom font information, normalizes them into an object the editor can use, inserts
 * custom CSS rules into the page for those fonts, and triggers an action to load the custom font data into the reducer.
 * This action is only intended to be called once, when an application first loads.
 * @param {*} customFonts - an array of custom font definitions. Each array entry should have a unique custom font ID.
 */
export function loadCustomFonts(customFonts: any) {
  // We want to normalize the passed in custom fonts array into an object so it is easier for us to work with.
  const normalizedCustomFonts = {};

  // All uploaded font files are saved separately, so we have to map them together for matching fontWieghts and
  // fontStyles with different URLs (for cases of allowing multiple font file types to be uploaded for a single
  // font combination)
  customFonts.forEach((customFont: any) => {
    const newFiles: any = [];
    customFont.files.forEach((file: any) => {
      const existingNewFile = newFiles.find(
        // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'newFile' implicitly has an 'any' type.
        newFile => newFile.fontWeight === file.fontWeight && newFile.fontStyle === file.fontStyle
      );
      if (existingNewFile) {
        existingNewFile.urls.push(file.url);
        existingNewFile.urls.sort(sortUrls);
      } else {
        newFiles.push({
          urls: [file.url],
          fontWeight: file.fontWeight,
          fontStyle: file.fontStyle
        });
      }
    });
    newFiles.sort(sortFiles);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    normalizedCustomFonts[customFont.id] = {
      ...customFont,
      fontFamily: "'" + customFont.fontFamily.trim() + "'",
      files: newFiles
    };
  });

  // Insert a custom style tag and manually insert the CSS font-face rules to allow the custom fonts to work.
  let styleTag = document.getElementById('custom-fonts');
  if (!styleTag) {
    styleTag = document.createElement('style');
    styleTag.setAttribute('id', 'custom-fonts');
    styleTag.setAttribute('type', 'text/css');
    document.head.appendChild(styleTag);
  }
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'sheet' does not exist on type 'HTMLEleme... Remove this comment to see the full error message
  if (styleTag.sheet) {
    Object.keys(normalizedCustomFonts).forEach(customFontId => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const customFont = normalizedCustomFonts[customFontId];
      customFont.files.forEach((file: any) => {
        const src = file.urls
          .map((url: any) => {
            const format = getFileFormat(url);
            return format ? `url('${url}') format('${format}')` : `url('${url}')`;
          })
          .join(', ');
        const cssRule = `@font-face { 
          font-display: swap;
          font-family: '${customFont.fontFamily.slice(1, -1)}';
          font-style: ${file.fontStyle}; 
          font-weight: ${file.fontWeight}; 
          src: ${src}; 
        }`;
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        styleTag.sheet.insertRule(cssRule, styleTag.sheet.cssRules.length);
      });
    });
  }

  // Trigger the action.
  return {
    type: LOAD_CUSTOM_FONTS,
    payload: {
      customFonts: normalizedCustomFonts
    }
  };
}

// REDUCER
/**
 * Reducer to hold our normalized custom font information so that we can use it throughout the editor.
 */
export function customFontsReducer(state = {}, action: any) {
  switch (action.type) {
    case LOAD_CUSTOM_FONTS: {
      return action.payload.customFonts;
    }
    default:
      return state;
  }
}
