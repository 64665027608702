import React from 'react';

import { FlexRow as Row } from 'nucleus-core';
import { defaultMemoize } from 'reselect';

import { ThemeImagesContext } from '../../../../context/ThemeImagesContext';
import { getInlineStyle } from '../../../../utils/style/getInlineStyle';
import { resetStylesToDefault } from '../../../../utils/style/resetStylesToDefault';
import merge from 'lodash/merge';

type Props = {
  section: any;
  theme: any;
  imageLookup?: any;
  supportsWebp?: boolean;
  contentWidth?: number;
  sectionContainerComponent: React.ReactElement;
  children?: React.ReactNode;
};

/**
 * Default renderer for a SECTION_ROW type layout item.
 * You can specify a container component to render your section,
 * and this wrapping class will provide the grid content and
 * generate the section style to pass to your component.
 */
export class SectionRow extends React.Component<Props> {
  static displayName = 'SectionRow';
  constructor(props: Props) {
    super(props);
    this.getContentStyle = this.getContentStyle.bind(this);
    this.getSectionStyle = this.getSectionStyle.bind(this);
  }
  getContentStyle() {
    const { contentWidth } = this.props;
    return this._getContentStyle(contentWidth);
  }
  _getContentStyle = defaultMemoize((contentWidth: any) => {
    if (contentWidth) {
      return {
        row: {
          margin: 'auto',
          maxWidth: `${contentWidth}px`
        }
      };
    }
    return {
      row: {
        width: '100%'
      }
    };
  });
  getSectionStyle(themeImages: any) {
    const { section, theme, imageLookup, supportsWebp } = this.props;
    return this._getSectionStyle(section, theme, themeImages, imageLookup, supportsWebp);
  }
  _getSectionStyle = defaultMemoize(
    (section: any, theme: any, themeImages: any, imageLookup: any, supportsWebp: any) => {
      const styleArray = [
        resetStylesToDefault(theme.global),
        theme.sections[section.config.themeId],
        section.config.style
      ];
      return getInlineStyle(
        styleArray,
        {},
        merge({}, theme.global.palette, theme.sections[section.config.themeId]?.palette),
        theme.global.fontPalette,
        themeImages,
        { useText: false, useSpacing: true, imageLookup, supportsWebp }
      );
    }
  );
  render() {
    const { children } = this.props;
    return (
      <ThemeImagesContext.Consumer>
        {themeImages => (
          // @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'this.props.sectionContainerCompo... Remove this comment to see the full error message
          <this.props.sectionContainerComponent
            sectionStyle={this.getSectionStyle(themeImages)}
            {...this.props}
          >
            <Row style={this.getContentStyle()}>{children}</Row>
          </this.props.sectionContainerComponent>
        )}
      </ThemeImagesContext.Consumer>
    );
  }
}
