/**
 * This component extends the BackgroundCells component from react-big-calendar@v0.14.0.
 *
 * 1) References to "className" and "style" are removed from the functions copied from the base component,
 *    and replaced by "resolve" and "select" to apply styles.
 *
 * 2) dateCellStyleGetter function is added as a prop to get custom styles for the BackgroundCell
 */

import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import PropTypes from 'prop-types';
import CalendarBackgroundCells from 'react-big-calendar/lib/BackgroundCells';
import dates from 'react-big-calendar/lib/utils/dates';
import { segStyle } from 'react-big-calendar/lib/utils/eventLevels';

export class BackgroundCells extends CalendarBackgroundCells {
  static displayName = 'BackgroundCells';
  static propTypes = {
    ...CalendarBackgroundCells.propTypes,
    // Add date specific cell style or className
    dateCellStyleGetter: PropTypes.func
  };
  props: any;
  state: any = {};

  render() {
    const { range, cellWrapperComponent: Wrapper, dateCellStyleGetter } = this.props;
    const { selecting, startIdx, endIdx } = this.state;
    return (
      <div {...resolve(this.props, 'rowBackground')}>
        {range.map((date: any, index: any) => {
          let dateCellStyle;
          let dateCellClassName;
          if (dateCellStyleGetter) {
            const customStyles = dateCellStyleGetter(date);
            if (customStyles) {
              dateCellStyle = customStyles.style;
              dateCellClassName = customStyles.className;
            }
          }
          const selected = selecting && index >= startIdx && index <= endIdx;
          const resolvedStyles = resolve(
            this.props,
            'dayBackground',
            dateCellClassName,
            selected ? 'selected' : undefined,
            dates.isToday(date) ? 'today' : undefined
          );
          return (
            <Wrapper key={index} value={date} range={range}>
              <div
                className={resolvedStyles.className}
                style={{
                  ...segStyle(1, range.length),
                  ...resolvedStyles.style,
                  ...dateCellStyle
                }}
              />
            </Wrapper>
          );
        })}
      </div>
    );
  }
}

export const backgroundCellsStyleKeys: Array<string> = [
  'rowBackground',
  'dayBackground',
  'selected',
  'today'
];
