import React, { useRef, useState, useEffect } from 'react';
import { resolve } from '@cvent/nucleus-dynamic-css';
import { useIframe } from './useIframe';
import { injectTestId } from '@cvent/nucleus-test-automation';

export type CodeProps = {
  script: string;
  disableInput: boolean;
  config?: {
    isolationMethod?: 'iframe' | 'shadowDom';
    script?: string;
    advancedSettings?: { iframeTitle: string };
  };
  title?: string;
  resizeCallback?: () => void;
  classes?: { container?: string; overlay?: string };
};

function IframeCodeWidget({
  script,
  disableInput,
  title,
  resizeCallback,
  ...rest
}: Omit<CodeProps, 'config'>): JSX.Element {
  const iframeContainerRef = useRef(null);
  useIframe(iframeContainerRef, script, title, resizeCallback);
  return (
    <div {...resolve(rest, 'container', 'containerIframe')} ref={iframeContainerRef}>
      {disableInput && <div {...resolve(rest, 'overlay')} />}
    </div>
  );
}

function ShadowDomCodeWidget({
  script,
  disableInput,
  resizeCallback: _resizeCallback,
  ...rest
}: Omit<CodeProps, 'config'>): JSX.Element {
  const [mountPoint, setMountPoint] = useState<ShadowRoot | null>(null);
  useEffect(() => {
    if (mountPoint) {
      mountPoint.innerHTML = script;
    }
  }, [mountPoint, script]);
  return (
    <div {...resolve(rest, 'container')}>
      {disableInput && <div {...resolve(rest, 'overlay')} />}
      <div
        {...injectTestId('code-widget-shadow-root')}
        ref={root => {
          if (root && !mountPoint) {
            const shadowRoot = root.attachShadow({ mode: 'open', delegatesFocus: true });
            setMountPoint(shadowRoot);
          }
        }}
      />
    </div>
  );
}

export const Code = React.memo(({ config, ...rest }: CodeProps): JSX.Element => {
  const isolationMethod = config?.isolationMethod ?? 'iframe';
  const title = config?.advancedSettings?.iframeTitle;
  if (isolationMethod === 'shadowDom') {
    return <ShadowDomCodeWidget {...rest} />;
  }
  return <IframeCodeWidget title={title} {...rest} />;
});
