/* eslint react/prop-types: [2, { ignore: ["setFocus", "onChange", "onNativeChange", "fieldName", "id", "name",
  "onBlur"] }] */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import { resolve } from '@cvent/nucleus-dynamic-css';
import { injectTestId, resolveTestId } from '@cvent/nucleus-test-automation';
import { getCharacterCountWithLinebreaks } from '../../utils/getCharacterCountWithLinebreaks';
import { removeKeys } from '../../utils/removeKeys';
import { truncateStringToSize } from '../../utils/truncateStringToSize';
import {
  FormElement,
  WithFormProps,
  removeFormElementProps,
  resolveValidationAccessibilityProps
} from '../FormElement';
import { RemainingCharacterCount } from '../RemainingCharacterCount';

/*
(ts-migrate) TODO: Migrate the remaining prop types
...FormElement.propTypes
*/
type OwnTextAreaProps = {
  onFocus?: (...args: any[]) => any;
  value: any;
  maxLength?: {
    limit: number;
    textCallback?: (...args: any[]) => any;
  };
  autoGrow?: boolean;
  minHeightInPixels?: number;
  placeholder?: string;
  style?: {
    textarea?: any;
    remainingCharacterCount?: any;
  };
};

type TextAreaState = any;

type TextAreaProps = OwnTextAreaProps & typeof TextArea.defaultProps;

/**
Textarea Component

onChange = function(fieldName, newValue)
**/

export class TextArea extends React.PureComponent<TextAreaProps, TextAreaState> {
  static displayName = 'TextArea';

  static defaultProps = {
    required: false,
    autoGrow: true,
    placeholder: '',
    minHeightInPixels: 0
  };

  textarea: HTMLTextAreaElement | null;

  constructor(props: TextAreaProps) {
    super(props);
    this.state = {
      charactersRemaining: props.maxLength
        ? props.maxLength.limit - getCharacterCountWithLinebreaks(props.value)
        : undefined
    };
    this.autoGrowTextArea = this.autoGrowTextArea.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.textarea = null;
  }

  componentDidMount() {
    if (!this.textarea) return;

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setFocus' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.setFocus) {
      this.focus();
    }
    if (this.props.autoGrow) {
      this.keyUpCheck();

      this.textarea.addEventListener(
        'keyup',
        () => {
          // If keyup is executed, stop resize observer because going forward keyup handler will resize TextArea
          this.keyUpCheck();
        },
        false
      );
    } else {
      this.textarea.style.overflow = 'auto';
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const oldMaxLength = this.props.maxLength ? this.props.maxLength.limit : null;
    if (
      nextProps.maxLength &&
      (this.props.value !== nextProps.value || oldMaxLength !== nextProps.maxLength.limit)
    ) {
      this.setState({
        charactersRemaining:
          nextProps.maxLength.limit - getCharacterCountWithLinebreaks(nextProps.value)
      });
    }
  }

  onChange(event: any) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const target = event.target ? event.target : window.event.srcElement;
    let newValue = target.value;
    if (this.props.maxLength) {
      newValue = truncateStringToSize(newValue, this.props.maxLength.limit);
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onNativeChange' does not exist on type '... Remove this comment to see the full error message
    if (this.props.onNativeChange) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onNativeChange' does not exist on type '... Remove this comment to see the full error message
      this.props.onNativeChange(event);
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(this.props.fieldName, newValue);
    }
  }

  onBlur(event: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBlur' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.props.onBlur) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBlur' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.onBlur(event);
    }
  }

  onFocus(e: any) {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  focus() {
    if (this.textarea) {
      this.textarea.focus();
    }
  }

  keyUpCheck() {
    if (!this.textarea) return;
    this.textarea.style.boxSizing = 'border-box';
    this.textarea.style.overflow = 'hidden';
    this.textarea.style.height = '0px';
    const height = this.autoGrowTextArea();

    this.textarea.style.height = `${height}px`;
    const stateHeight = this.state.height;
    if (height !== stateHeight) {
      this.setState({ height });
    }
  }

  autoGrowTextArea() {
    const { minHeightInPixels } = this.props;
    const scrollHeight = this.textarea?.scrollHeight || 0;

    return Math.max(minHeightInPixels, scrollHeight);
  }

  render() {
    const { id, name, size, fieldName, maxLength, errorMessages, ...rest } = removeKeys(
      this.props,
      ['autoGrow', 'minHeightInPixels']
    );
    let remainingCharacterCount;
    if (maxLength && maxLength.textCallback) {
      remainingCharacterCount = (
        <RemainingCharacterCount
          charactersRemaining={this.state.charactersRemaining}
          textCallback={maxLength.textCallback}
          {...rest}
        />
      );
    }

    const hasErrors = Object.keys(errorMessages || {}).length > 0;

    const textAreaStyle = {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'Readonl... Remove this comment to see the full error message
      classes: { ...this.props.classes },
      style: { ...this.props.style }
    };
    if (textAreaStyle.style.textarea) {
      if (this.state.height) {
        textAreaStyle.style.textarea = {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          ...this.props.style.textarea,
          height: this.state.height
        };
      } else {
        textAreaStyle.style.textarea = {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          ...this.props.style.textarea
        };
      }
    } else {
      if (this.state.height) {
        textAreaStyle.style.textarea = {
          height: this.state.height,
          boxSizing: 'border-box',
          overflow: 'hidden'
        };
      } else {
        textAreaStyle.style.textarea = {
          boxSizing: 'border-box',
          overflow: 'hidden'
        };
      }
    }

    return (
      <span>
        <textarea
          {...removeFormElementProps(rest)}
          {...resolveTestId(this.props)}
          ref={c => {
            this.textarea = c;
          }}
          id={id || fieldName}
          name={name || fieldName}
          maxLength={maxLength && maxLength.limit}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          {...resolve(textAreaStyle, 'textarea', size, hasErrors ? 'error' : '')}
          {...resolveValidationAccessibilityProps(id || fieldName, errorMessages)}
        />
        {remainingCharacterCount}
      </span>
    );
  }
}

type TextAreaFormElementProps = WithFormProps & {
  children?: React.ReactNode;
  size?: string;
  style?: {
    textarea?: any;
  };
};

/**
FormElement wrapper around TextArea. This is the default export.
**/
const TextAreaFormElement = ({ children, size = '', ...rest }: TextAreaFormElementProps) => {
  return (
    <FormElement size={size} {...rest}>
      {/* @ts-expect-error size is missing for prop validation */}
      <TextArea size={size} {...rest} {...injectTestId('input')} />
      {children}
    </FormElement>
  );
};
TextAreaFormElement.displayName = 'TextAreaFormElement';

export { TextAreaFormElement };
