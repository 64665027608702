import { convertToHtml } from '@cvent/rich-text-renderer';

/**
 * Function to get the output string to render to the guest for a field representing rich text content. We have two
 * different rich text scenarios that we need to support:
 * 1. Usages of nucleus-core RTE. When using the legacy rich text editor, the site editor would store an object of the
 *    form { htmlContent, editorState, colors }. In this case, we just translate the htmlContent (for the default text
 *    case) and return it.
 * 2. Usages of carina-rich-text-editor. For the current rich text editor, we have a versioned carina rich text renderer
 *    package to use for rendering the backing JSON object, so we just call that and translate it.
 */
export function renderRichText(
  value: any,
  translate: any,
  colorPalette: any,
  externalStyleObject: any,
  { baseBlockType }: any = {}
) {
  // If the value has the htmlContent property, meaning the value was created to be used with the nucleus-core
  // rich text editor, just return the htmlContent property.
  if (value && value.hasOwnProperty('htmlContent')) {
    return translate(value.htmlContent);
  }

  // If we get to this point, we the value should be a carina-rich-text-editor based object, and the the carina rich
  // text renderer function should handle any future modifications of the data object (migrations).
  return translate(
    convertToHtml(value, {
      directional: undefined,
      customEntityTransform: undefined,
      withStyles: true,
      externalStyleObject,
      baseBlockType,
      presetColors:
        colorPalette &&
        Object.keys(colorPalette).map(id => {
          return {
            hex: colorPalette[id],
            value: id
          };
        })
    })
  );
}
