import ButtonStyles from 'nucleus-core/less/cv/Button.less';
import ClassNames from './Button.less';
import { ButtonWidget } from './ButtonWidget';

const Classes = {
  ...ClassNames,
  button: {
    ...ButtonStyles,
    disabled: ClassNames.disabled
  }
};
const ContainerlessButtonClasses = {
  ...Classes,
  container: undefined
};

/**
 * Modified data wrapper for the default button widget that has no
 * container classes / style properties.
 */
export class ContainerlessButtonWidget extends ButtonWidget {
  static displayName = 'ContainerlessButtonWidget';
  static defaultProps = {
    classes: ContainerlessButtonClasses
  };
  getStyleObject() {
    return {
      ...super.getStyleObject(),
      container: undefined
    };
  }
}
