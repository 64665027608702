import reducer from './modules/reducer';

// using require instead of import for conditional compilation;
let createStoreWithMiddleware: $TSFixMe;
if (process.env.NODE_ENV === 'production') {
  createStoreWithMiddleware = require('nucleus-guestside-site/lib/redux/prodCreateStoreWithMiddleware').prodCreateStoreWithMiddleware;
} else {
  createStoreWithMiddleware = require('nucleus-guestside-site/lib/redux/devCreateStoreWithMiddleware').devCreateStoreWithMiddleware;
}

export default function configureStore(initialState: $TSFixMe) {
  return createStoreWithMiddleware(reducer, initialState);
}
