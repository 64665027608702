import { backgroundColor, backgroundColorAlpha, backgroundHeader } from './background';
import { borderColor, borderHeader } from './border';
import { textColor, textHeader } from './text';
import { PseudoStateStyleMode } from '../../../constants';
import { ContrastIcon, EditPencilIcon, SunIcon, XCircleIcon } from '@cvent/carina/components/Icon';
import { getStyleObjectValue } from '../getStyleObjectValue';
import {
  createBrightnessVariants,
  pseudoStateCascadedChange,
  convertThemePaletteColor
} from '../../../utils';
import merge from 'lodash/merge';

function hideCustomModeField(state: any, valueObject: any) {
  return valueObject?.hover?.styleMode !== PseudoStateStyleMode.CUSTOM;
}

export const hoverBackgroundColor = {
  ...backgroundColor,
  fieldName: 'hover.background.color',
  hideField: hideCustomModeField
};

export const hoverBackgroundColorAlpha = {
  ...backgroundColorAlpha,
  fieldName: 'hover.background.colorAlpha',
  hideField: hideCustomModeField
};

export const hoverBackgroundHeader = {
  ...backgroundHeader,
  hideField: hideCustomModeField
};

export const hoverBorderColor = {
  ...borderColor,
  fieldName: 'hover.border.color',
  hideField: hideCustomModeField
};

export const hoverBorderHeader = {
  ...borderHeader,
  hideField: hideCustomModeField
};

export const hoverTextColor = {
  ...textColor,
  fieldName: 'hover.text.color',
  hideField: hideCustomModeField
};

export const hoverTextFontBoldToggle = {
  fieldName: 'hover.text.fontBoldToggle',
  component: 'ToggleField',
  cascadedChange: (fieldName: any, value: any, onChange: any) => {
    const path = fieldName.substring(0, fieldName.lastIndexOf('.'));
    const boldText = 700;
    const standardText = 400;
    onChange(`${path}.fontWeight`, value ? boldText : standardText);
    onChange(`${path}.fontStyle`, 'normal');
  },
  options: {
    name: 'NucleusSiteEditor_HoverStyle_BoldText__resx'
  },
  hideField: hideCustomModeField
};

export const hoverTextHeader = {
  ...textHeader,
  hideField: hideCustomModeField
};

export const hoverStyleMode = {
  fieldName: 'hover.styleMode',
  component: 'PseudoStateStyleField',
  cascadedChange: pseudoStateCascadedChange(),
  options: {
    availableStyleModes: [
      {
        icon: XCircleIcon,
        label: 'NucleusSiteEditor_HoverStyle_NoMode__resx',
        value: PseudoStateStyleMode.NONE
      },
      {
        icon: SunIcon,
        label: 'NucleusSiteEditor_HoverStyle_BrightnessMode__resx',
        value: PseudoStateStyleMode.BRIGHTNESS
      },
      {
        icon: ContrastIcon,
        label: 'NucleusSiteEditor_HoverStyle_InvertedMode__resx',
        value: PseudoStateStyleMode.INVERTED
      },
      {
        icon: EditPencilIcon,
        label: 'NucleusSiteEditor_HoverStyle_CustomMode__resx',
        value: PseudoStateStyleMode.CUSTOM
      }
    ],
    defaultStyleMode: PseudoStateStyleMode.NONE,
    instructionalText: 'NucleusSiteEditor_HoverStyle_InstructionalText__resx'
  }
};

export const hoverBrightnessModifier = {
  fieldName: 'hover.brightnessModifier',
  component: 'BrightnessModifierField',
  hideField: (_: never, valueObject: any) =>
    valueObject?.hover?.styleMode !== PseudoStateStyleMode.BRIGHTNESS,
  cascadedChange: pseudoStateCascadedChange(),
  options: {
    defaultBrightnessLabel: 'NucleusSiteEditor_HoverStyle_DefaultBrightness__resx',
    headerLabel: 'NucleusSiteEditor_HoverStyle_BrightnessLabel__resx',
    getBrightnessVariants: (state: any, themeSectionId: string, props: any) => {
      const color = getStyleObjectValue(state, props, 'background')?.color;
      return createBrightnessVariants(
        convertThemePaletteColor(
          merge(
            {},
            state.website.theme.global.palette,
            themeSectionId ? state.website.theme.sections?.[themeSectionId]?.palette : {}
          ),
          color
        )
      );
    }
  }
};

export default {
  hoverBackgroundHeader,
  hoverBorderHeader,
  hoverTextHeader,
  'hover.background.color': hoverBackgroundColor,
  'hover.background.colorAlpha': hoverBackgroundColorAlpha,
  'hover.border.color': hoverBorderColor,
  'hover.text.color': hoverTextColor,
  'hover.text.fontBoldToggle': hoverTextFontBoldToggle,
  'hover.styleMode': hoverStyleMode,
  'hover.brightnessModifier': hoverBrightnessModifier
};
