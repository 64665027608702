import '@babel/polyfill';
import querystring from 'querystring';

import React from 'react';

import Logger from '@cvent/nucleus-logging';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import packageJson from '../../package.json';
import App from '../containers/App';
import { SHOW_SPINNER } from '../redux/actionTypes';
import configureStore from '../redux/configureStore';
import { CALENDAR_SPINNER_TIMEOUT, loadInitialCalendar } from '../redux/modules/calendar';
import WidgetFactory from '../widgets/GuestSideWidgetFactory';

const LOG = new Logger('website');

global.onunhandledrejection = function logUnhandledPromiseRejection(ex: $TSFixMe) {
  LOG.error('Unhandled promise rejection', ex);
};

// Make the current version available globally.
// @ts-ignore
global.version = packageJson.version;

function renderCodeSnippets({ codeSnippets }: $TSFixMe) {
  if (codeSnippets && codeSnippets.length) {
    const scriptContainer = document.createElement('div');
    // for each planner-side code snippet
    codeSnippets.forEach((snippetString: $TSFixMe) => {
      scriptContainer.innerHTML = snippetString;
      // for each script tag within one planner-side code snippet
      Array.from(scriptContainer.childNodes).forEach(script => {
        // planner is supposed to only put some script tags, mainly for the purpose of custom tracking
        const scriptNode = document.createElement('script');
        scriptNode.type = 'text/javascript';
        // @ts-expect-error TS(2339): Property 'src' does not exist on type 'ChildNode'.
        if (script.src) {
          // @ts-expect-error TS(2339): Property 'src' does not exist on type 'ChildNode'.
          scriptNode.src = script.src;
        }
        // @ts-expect-error TS(2339): Property 'innerHTML' does not exist on type 'Child... Remove this comment to see the full error message
        if (script.innerHTML) {
          // @ts-expect-error TS(2339): Property 'innerHTML' does not exist on type 'Child... Remove this comment to see the full error message
          scriptNode.innerHTML = script.innerHTML;
        }
        document.head.appendChild(scriptNode);
      });
    });
  }
}

async function startApp(appSettings: $TSFixMe) {
  // pre load moment locales because month view needs moment
  const loadMomentLocalesPromise: Promise<void> = new Promise(resolve => {
    // @ts-expect-error TS(2580): Cannot find name 'require'. Do you need to install... Remove this comment to see the full error message
    require.ensure([], (require: $TSFixMe) => {
      const moment = require('moment');
      moment.locale([
        'en',
        'en-ca',
        'en-gb',
        'en-au',
        'zh-cn',
        'zh-tw',
        'da',
        'nl',
        'fr',
        'de',
        'it',
        'ja',
        'ko',
        'pt',
        'pt-br',
        'ru',
        'es',
        'fi',
        'sv',
        'el',
        'tr',
        'vi',
        'bg',
        'nb',
        'pl',
        'ro',
        'th',
        'fr-ca'
      ]);
      resolve();
    });
  });
  const domain = '';
  // render/execute the code snippets added by planner
  renderCodeSnippets(appSettings);
  const { previewToken } = querystring.parse(window.location.search.slice(1));
  const { calendarId, authorization, isCustomFooterEnabled, datadogEnvironment } = appSettings;

  const store = configureStore({
    accessToken: `BEARER ${authorization}`,
    widgetFactory: new WidgetFactory(),
    isCustomFooterEnabled
  });

  // display spinner if calendar prop endpoint takes more than CALENDAR_SPINNER_TIMEOUT
  setTimeout(() => {
    store.dispatch({ type: SHOW_SPINNER });
  }, CALENDAR_SPINNER_TIMEOUT);

  store.dispatch(
    loadInitialCalendar(calendarId, domain, appSettings.isFixture, authorization, previewToken, false, false, datadogEnvironment, false)
  );
  await loadMomentLocalesPromise;

  const root = createRoot(document.getElementById('react-mount'));
  root.render(
    <Provider store={store}>
      <App assetRoot={''} />
    </Provider>
  );
}

export { renderCodeSnippets, startApp };

global.startApp = startApp;
