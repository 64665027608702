/**
 * Helpers for processing various HTTP method calls
 */
import Logger from '@cvent/nucleus-logging';
import { FetchClient } from '@cvent/fetch';
const LOG = new Logger('nucleus-widgets/utils/ApiHelpers');

/** @deprecated. Will be removed in next major release. Use @cvent/fetch directly */
export function get(url: any, authToken: any, headers = new Headers()) {
  const fetchClient = new FetchClient({
    init: {
      headers: { ...headers, Authentication: authToken }
    }
  });
  return fetchClient.get(url);
}

/** @deprecated. Will be removed in next major release. Use @cvent/fetch directly */
export function post(url: any, body: any, authToken: any, headers = new Headers()) {
  const fetchClient = new FetchClient({
    init: {
      headers: { ...headers, Authentication: authToken }
    }
  });
  return fetchClient.post(url, JSON.stringify(body));
}

/** @deprecated. Will be removed in next major release. Use @cvent/fetch directly */
export function put(url: any, body: any, authToken: any, headers = new Headers()) {
  const fetchClient = new FetchClient({
    init: {
      headers: { ...headers, Authentication: authToken }
    }
  });
  return fetchClient.put(url, JSON.stringify(body));
}

/** @deprecated. Will be removed in next major release. Use @cvent/fetch & @cvent/logging directly */
export function logError(method: any, response: any) {
  const responseCopy = response.clone();
  return response.text().then((text: any) => {
    LOG.error(`${method}: ${response.status} ${response.statusText}: ${text}`);
    return Promise.reject(responseCopy);
  });
}
