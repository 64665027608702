import React, { Component } from 'react';

import { resolve, select } from '@cvent/nucleus-dynamic-css';
import PropTypes from 'prop-types';
import message from 'react-big-calendar/lib/utils/messages';

import { InteractiveElement } from '../containers/InteractiveElement';
import { Trigger } from '../containers/Trigger';

type Props = {
  onClick?: (...args: any[]) => any;
  slot?: number;
  count?: number;
  popup?: boolean;
  renderOverlay?: (...args: any[]) => any;
  messages?: any;
  keepFlyoutOpenOnDocumentClick?: boolean;
};

type State1 = any;

/**
 * This component renders the show more events link,
 * and the flyout to show all the events in a day upon clicking on the link.
 */

class ShowMore extends Component<Props, State1> {
  static displayName = 'ShowMore';

  static State = {
    isFlyoutOpen: PropTypes.bool
  };

  link: any;
  trigger: any;

  state = {
    isFlyoutOpen: false
  };
  onOpen = () => {
    this.setState({
      isFlyoutOpen: true
    });
  };
  onClose = () => {
    this.setState({
      isFlyoutOpen: false
    });
  };
  onLinkClick = (event: any) => {
    if (this.trigger) {
      this.trigger.handleClick(event);
    }
  };
  onKeyDownTrigger = (event: any) => {
    // Handle escape key
    if (event.keyCode === 27 && this.link) {
      this.link.focus();
    }
  };
  onClick = (event: any) => {
    const { onClick, slot } = this.props;
    if (onClick) {
      onClick(slot, this.trigger, event);
    }
  };
  render() {
    const { slot, count, popup, renderOverlay, keepFlyoutOpenOnDocumentClick } = this.props;

    const messages = message(this.props.messages);
    if (!popup) {
      return (
        <InteractiveElement
          key={'sm_' + slot}
          {...resolve(this.props, 'showMoreLink')}
          onClick={this.onClick}
          element="span"
        >
          {messages.showMore(count)}
        </InteractiveElement>
      );
    }
    return (
      <Trigger
        key={'sm_' + slot}
        onOpen={this.onOpen}
        onClose={this.onClose}
        onClick={this.onClick}
        allowMouse={false}
        allowDocumentClick={keepFlyoutOpenOnDocumentClick}
        {...select(this.props, 'trigger')}
        ref={c => (this.trigger = c)}
        onKeyDown={this.onKeyDownTrigger}
      >
        <InteractiveElement
          {...resolve(this.props, 'showMoreLink')}
          element="span"
          onClick={this.onLinkClick}
          ref={c => (this.link = c)}
        >
          {messages.showMore(count)}
        </InteractiveElement>
        {this.state.isFlyoutOpen && renderOverlay !== undefined && renderOverlay()}
      </Trigger>
    );
  }
}

export { ShowMore };

export const showMoreStyleKeys = ['showMoreLink', 'trigger'];
