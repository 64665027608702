import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';

import { InteractiveElement } from '../containers/InteractiveElement';
import { PanelHeader } from './PanelHeader';

type Props = React.PropsWithChildren<{
  headerText?: string;
  onClearAll?: (...args: any[]) => any;
  translate?: (...args: any[]) => any;
  classes?: any;
  style?: {
    resetAllLink?: any;
    panelHeaderText?: any;
  };
}>;

/**
 * A component that handles the display of filters
 */
export class Panel extends React.Component<Props> {
  static displayName = 'Panel';

  constructor(props: Props) {
    super(props);
    this.onClearAllClick = this.onClearAllClick.bind(this);
  }

  onClearAllClick(e: any) {
    e.preventDefault();
    const { onClearAll } = this.props;
    // If the callback for clear all is provided
    if (onClearAll) {
      onClearAll();
    }
  }

  render() {
    const { headerText, translate, children, ...rest } = this.props;

    return (
      <div {...resolve(this.props, 'panel')}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ onClearAll?: ((...args: any[]) => any) | u... Remove this comment to see the full error message */}
        {headerText && <PanelHeader headerText={headerText} translate={translate} {...rest} />}
        {children}
        <InteractiveElement {...resolve(this.props, 'resetAllLink')} onClick={this.onClearAllClick}>
          {/* @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
          {translate('NucleusCore_Filters_ClearAllFilters__resx')}
        </InteractiveElement>
      </div>
    );
  }
}
