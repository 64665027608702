/**
 * A utility around date formats/options for calendar's website/widget editor/guest side.
 */

import moment from 'moment';

import { areTwoDatesEqual } from '../lib/CalendarView/utils/dateUtil';

const HOUR_12 = 1;
const DD_MONTH_YYYY = 3;
const DAY_DD_MONTH_YYYY = 6;
const DD_MM_YYYY = 7;

export const ISO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

function getHourCycle(timeFormatId: $TSFixMe) {
  if (timeFormatId === HOUR_12) {
    return {
      hourCycle: 'h12'
    };
  }
  return {
    hour12: false
  };
}

export function getDateFormatOptions(dateFormat: $TSFixMe, timeFormatId: $TSFixMe, dateFormatter: $TSFixMe, dateTimeDelimiter: $TSFixMe) {
  const hourCycle = getHourCycle(timeFormatId);
  return {
    formatter: dateFormatter,
    delimiter: dateTimeDelimiter,
    dateFormat,
    timeFormat: { hour: 'numeric', minute: 'numeric', ...hourCycle }
  };
}

/**
 * A helper function that returns the date formatter used by the calendar's widget
 */
export function getCalendarDateFormatter(timeFormatId: $TSFixMe, dateFormatter: $TSFixMe, dateTimeDelimiter: $TSFixMe) {
  return {
    ...getDateFormatOptions({ day: '2-digit', month: 'long', year: 'numeric' }, timeFormatId, dateFormatter, dateTimeDelimiter),
    headerFormat: { month: 'long', year: 'numeric' }
  };
}

/**
 * A helper function that returns the date format options per the date format id configured by the planner
 *
 * NOTE: These are legacy formats supported by our app. Subject to change based on the
 * new internationally accepted formats.
 */
export function getDateFormat(dateFormatId: $TSFixMe) {
  let dateFormatOptions = null;
  switch (dateFormatId) {
    case 1: {
      dateFormatOptions = { month: 'numeric', day: '2-digit', year: 'numeric' };
      break;
    }
    case 2: {
      dateFormatOptions = { month: 'long', day: '2-digit', year: 'numeric' };
      break;
    }
    case DD_MONTH_YYYY: {
      dateFormatOptions = { day: '2-digit', month: 'long', year: 'numeric' };
      break;
    }
    case 4: {
      dateFormatOptions = { weekday: 'long', month: 'numeric', day: '2-digit', year: 'numeric' };
      break;
    }
    case 5: {
      dateFormatOptions = { weekday: 'long', month: 'long', day: '2-digit', year: 'numeric' };
      break;
    }
    case DAY_DD_MONTH_YYYY: {
      dateFormatOptions = { weekday: 'long', day: '2-digit', month: 'long', year: 'numeric' };
      break;
    }
    case DD_MM_YYYY: {
      dateFormatOptions = { day: '2-digit', month: 'numeric', year: 'numeric' };
      break;
    }
    default: {
      dateFormatOptions = { weekday: 'long', month: 'long', day: 'numeric' };
      break;
    }
  }
  return dateFormatOptions;
}

/**
 * Returns the formatted date
 */
export function getFormattedDate(dateTimeFormat: $TSFixMe, dateField: $TSFixMe, translate: $TSFixMe, hideTime: $TSFixMe) {
  const { formatter, dateFormat, timeFormat, delimiter } = dateTimeFormat;
  if (typeof dateField === 'string') {
    const date = moment(dateField, ISO_DATE_FORMAT).toDate();
    return formatter(date, dateFormat) + (hideTime ? '' : delimiter + formatter(date, timeFormat));
  }

  let startDate = moment(dateField.startDate, ISO_DATE_FORMAT).toDate();
  let endDate = dateField.endDate ? moment(dateField.endDate, ISO_DATE_FORMAT).toDate() : null;
  const isSingleDay = endDate ? areTwoDatesEqual(startDate, endDate) : true;

  startDate = formatter(startDate, dateFormat) + (hideTime ? '' : delimiter + formatter(startDate, timeFormat));
  if (isSingleDay) {
    endDate = hideTime || !endDate ? null : formatter(endDate, timeFormat);
  } else {
    endDate = formatter(endDate, dateFormat) + (hideTime ? '' : delimiter + formatter(endDate, timeFormat));
  }
  return {
    startDate,
    endDate,
    timeZone: translate(dateField.timezone)
  };
}
