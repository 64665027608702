import merge from 'lodash/merge';

import { performStyleMappings } from './performStyleMappings';
import { resetStylesToDefault } from './resetStylesToDefault';

/**
 * Gets the style object to use when editing a themeSection. Merges all of
 * the necessary style objects together into a single object.
 */
export function getThemeStyleObject(state: any, themeSection: any) {
  const globalTheme = state.website.theme.global;
  const defaults = {
    palette: {
      ...(state.editorConfig.defaultDangerColor
        ? { danger: state.editorConfig.defaultDangerColor }
        : {})
    }
  };
  const cleanGlobalTheme = resetStylesToDefault(globalTheme, defaults);
  const mergedObject = merge({}, cleanGlobalTheme, themeSection);
  performStyleMappings(mergedObject, mergedObject);
  return mergedObject;
}
