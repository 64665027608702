import React, { cloneElement } from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

type Props = {
  children?: React.ReactNode;
  classes?: any;
  style?: {
    container?: any;
  };
};
/**
  Style Group Container Component - This component takes in classes and styles and merges them into the child objects
  inside. This will either override them on the childeren objects inside or apply them if they do not already exist.
**/
const StyleGroup = (props: Props) => {
  const { classes, style, children } = props;

  const elements = React.Children.map(children, child => {
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    return cloneElement(child, {
      // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
      classes: Object.assign({}, child.props.classes, classes),
      // @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'.
      style: Object.assign({}, child.props.style, style)
    });
  });

  return (
    <div {...resolveTestId(props)} {...resolve(props, 'container')}>
      {elements}
    </div>
  );
};
StyleGroup.displayName = 'StyleGroup';

/**
## Usage

labels = {
  container:
    {border: 1px solid #000}
  }
ovrde = {
  container:
    {border: 2px dashed #FF0000}
  }
extra = {
  container:
    {background: #AF00AF}
  }

Example 1:
  <StyleGroup style={ovrde}>
    <div style={labels}/>
  </StyleGroup>

  This would return a style of:
  {container:
    {border: 2px dashed #FF0000}
  }
  On each inner div or element.


  Example 2:
    <StyleGroup style={extra}>
      <div />
    </StyleGroup>

    This would return a style of:
    {container:
      {background: #AF00AF}
    }
    On each inner div or element.

  Example 3:
    <StyleGroup style={extra}>
      <div style={labels}/>
    </StyleGroup>

    This would return a style of:
    {container:
      {border: 1px solid #000,
       background: #AF00AF}
    }
    On each inner div or element.

```js
<StyleGroup
    children = {node} // child nodes to render
    style = {object} // Inline style object
    classes = {object} // CSS class object >
    {child nodes}
</StyleGroup>
```
**/

export { StyleGroup };
