import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { REMOVE_COMMENT_ERROR, UPDATE_COMMENT_ERROR } from './actions';

export const COMMENT_ERRORS = {
  Load: 'LOAD_COMMENTS',
  Post: 'POST_COMMENT',
  Delete: 'DELETE_COMMENT',
  Resolve: 'RESOLVE_COMMENT',
  GetPageLevelCommentCount: 'GET_PAGE_LEVEL_COMMENT_COUNT'
};

export function removeCommentError(id: string) {
  return { type: REMOVE_COMMENT_ERROR, payload: { id } };
}

export function setCommentErrorLoading(id: string, retrying: boolean) {
  return { type: UPDATE_COMMENT_ERROR, payload: { id, updatedValues: { retrying } } };
}

export function getCommentError(errors: any[], type: string, data: any) {
  return errors.find((err: any) => isEqual(pick(err, ['type', 'data']), { type, data }));
}
