/**
 * shapes the customized translations into a plain key-value pair object.
 * e.g. [{
         "resourceName": "_calendar_abc__resx",
         "translatedValue": "abc text"
       }]
 will be converted to { "_calendar_abc__resx": "abc text" }
 * @param data
 * @returns {{}}
 */
export default function convertFlatTranslationsToObject(data: any) {
  const customTranslations: { [key: string]: any } = {};
  if (data && data.length) {
    for (let i = 0; i < data.length; i++) {
      customTranslations[data[i].resourceName] = data[i].translatedValue;
    }
  }
  return customTranslations;
}
