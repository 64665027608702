import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { injectTestId, resolveTestId } from '@cvent/nucleus-test-automation';

import { isTouchEnabled } from '../../utils/isTouchEnabled';
import { removeKeys } from '../../utils/removeKeys';
import { FormElement, WithFormProps, removeFormElementProps } from '../FormElement';
import stylesButton from './Elements.less';

export const TIME_CHANGE_SUCCESS = 'TIME_CHANGE_SUCCESS';
export const TIME_CHANGE_EMPTY_TIME = 'TIME_CHANGE_EMPTY_TIME';
export const TIME_CHANGE_ERROR_INVALID = 'TIME_CHANGE_ERROR_INVALID';

/**
 * Removes any specific properties to this component from a props object that would be invalid
 * if applied to a base DOM node. Useful when composing multiple components to prevent
 * passing a component props that it doesn't use.
 */
export function removeTimePickerProps(props: any) {
  return removeKeys(props, [
    'fieldName',
    'hideLabel',
    'use24hours',
    'showSeconds',
    'formatTime',
    'getStatusCode',
    'placeholderSymbol',
    'separatorSymbol',
    'periodConfig'
  ]);
}

/*
 * @param {integer} caretPosition - value returned from selectionStart
 * @return {integer} - start position for selected text, e.g. hours or minutes
 */
function getCaretStartPosition(caretPosition: any) {
  return Math.floor(caretPosition / 3) * 3;
}

/*
 * Add leading 0 to single digit number
 * @param {integer} number
 * @return {string} - number padded with 0
 */
function padZero(number: any) {
  return number < 10 ? `0${number}` : `${number}`;
}

/*
 * Convert string into an object that contains properties of hour, minute, and second
 * @param {string} value - string to be formatted
 * @param {bool} showSeconds - flag to show/hide showSeconds
 * @param {bool} use24hours - flag to use 12 vs 24 hour format
 * @param {string} placeholderSymbol - default to dash
 * @param {string} separatorSymbol - default to colon
 * @param {object} periodConfig
 * @return {{hour: number, minute: number, second: number}}
 */
export function formatTime(
  value: any,
  showSeconds: any,
  use24hours: any,
  placeholderSymbol = '-',
  separatorSymbol = ':',
  { beforeNoonKey = 'a', afterNoonKey = 'p' } = {}
) {
  const placeholder = placeholderSymbol.repeat(2);

  const hourRegex = `([a-zA-Z0-9\\${placeholder}]+)`;
  const minuteOrSecondRegex = `(?:${separatorSymbol}(\\d{1,2}|\\${placeholder}))?`;
  const periodRegex = `(${beforeNoonKey}|${afterNoonKey})`;
  const regex = new RegExp(
    `${hourRegex}${minuteOrSecondRegex}${minuteOrSecondRegex}\\s*${periodRegex}?`,
    'i'
  );
  const array = value.match(new RegExp(regex, 'i'));

  let hour = parseInt(array[1], 10);
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
  hour = isNaN(hour) || hour > 23 || (!use24hours && hour > 12) ? undefined : hour;

  let minute = parseInt(array[2], 10);
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
  minute = isNaN(minute) || minute > 59 ? undefined : minute;

  let second = parseInt(array[3], 10);
  // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
  second = isNaN(second) || second > 59 ? undefined : second;

  if (array[4] && array[4].toLocaleLowerCase() === afterNoonKey) {
    if (hour !== undefined) {
      hour = hour < 12 ? hour + 12 : 12;
    }
  } else if (array[4] && array[4].toLocaleLowerCase() === beforeNoonKey) {
    if (hour !== undefined) {
      hour = hour === 12 ? 0 : hour;
    }
  }

  return { hour, minute, second };
}

/*
 * Validate whether a string is in a valid time format with Regex
 * @param {string} value - string to be validated
 * @param {bool} use24hours - flag for 12 or 24 hour format
 * @return {string} - status code
 */
function getStatusCode(
  value: any,
  use24hours: any,
  separatorSymbol = ':',
  { beforeNoonLabel = 'AM', afterNoonLabel = 'PM' } = {}
) {
  if (!value) {
    return TIME_CHANGE_EMPTY_TIME;
  }

  let isValid;
  const s = separatorSymbol;
  if (use24hours) {
    const regex = `^([0-1]?\\d|2[0-4])${s}([0-5]\\d)(${s}[0-5]\\d)?$`;
    isValid = new RegExp(regex).test(value);
  } else {
    const regex = `^(0[1-9]|1[0-2])${s}([0-5]\\d)(${s}[0-5]\\d)?\\s?(${beforeNoonLabel}|${afterNoonLabel})$`;
    isValid = new RegExp(regex, 'i').test(value);
  }

  return isValid ? TIME_CHANGE_SUCCESS : TIME_CHANGE_ERROR_INVALID;
}

/*
 * Convert object into the format that can be consumed by the component
 * @param {{hour: number, minute: number, second: number}} value
 * @param {bool} showSeconds - flag to show/hide showSeconds
 * @param {bool} use24hours - flag to use 12 vs 24 hour format
 * @param {string} placeholderSymbol - default to dash
 * @param {string} separatorSymbol - default to colon
 * @param {object} periodConfig
 * @return {string} - formatted string
 */
function getTimeFromObject(
  value: any,
  showSeconds: any,
  use24hours: any,
  placeholderSymbol = '-',
  separatorSymbol = ':',
  { beforeNoonLabel = 'AM', afterNoonLabel = 'PM' } = {}
) {
  const placeholder = placeholderSymbol.repeat(2);
  let { hour = placeholder, minute = placeholder, second = placeholder } = value || {};

  let period = beforeNoonLabel;
  if (!use24hours && !isNaN(hour)) {
    if (hour === 0) {
      hour = 12;
    } else if (hour > 12) {
      hour = hour - 12;
      period = afterNoonLabel;
    } else if (hour === 12) {
      period = afterNoonLabel;
    }
  }

  minute = isNaN(minute) || minute > 59 ? placeholder : minute;
  second = isNaN(second) || second > 59 ? placeholder : second;

  let time = `${padZero(hour)}${separatorSymbol}${padZero(minute)}`;
  time = showSeconds ? `${time}${separatorSymbol}${padZero(second)}` : time;
  time = use24hours ? time : `${time} ${period}`;

  return time;
}

/*
(ts-migrate) TODO: Migrate the remaining prop types
...FormElement.propTypes
*/
type OwnTimePickerProps = {
  onFocus?: (...args: any[]) => any;
  value: {
    hour?: number;
    minute?: number;
    second?: number;
  };
  disabled?: boolean;
  readOnly?: boolean;
  use24hours?: boolean;
  showSeconds?: boolean;
  formatTime?: (...args: any[]) => any;
  getStatusCode?: (...args: any[]) => any;
  placeholderSymbol?: string;
  separatorSymbol?: string;
  periodConfig?: {
    beforeNoonLabel?: string;
    beforeNoonKey?: string;
    afterNoonLabel?: string;
    afterNoonKey?: string;
  };
  style?: Record<string, unknown>;
  ariaDescribedby?: string;
};

type TimePickerState = any;

type TimePickerProps = OwnTimePickerProps & typeof TimePicker.defaultProps;

/**
Display TimePicker form element

onChange = function(fieldName, newValue, statusCode)
**/
export class TimePicker extends React.Component<TimePickerProps, TimePickerState> {
  static displayName = 'TimePicker';
  static defaultProps = {
    required: false,
    disabled: false,
    readOnly: false,
    use24hours: false,
    showSeconds: false,
    formatTime,
    getStatusCode,
    placeholderSymbol: '-',
    separatorSymbol: ':',
    periodConfig: {
      beforeNoonLabel: 'AM',
      beforeNoonKey: 'a',
      afterNoonLabel: 'PM',
      afterNoonKey: 'p'
    },
    showInstructionalText: false,
    timeInstructionalText: ''
  };
  caretStartPosition: any;
  invalidInput: any;
  isUserEditing: any;
  isUserPasting: any;
  previousEdits: any;
  textbox: any;
  upDownArrowPressed: any;
  constructor(props: TimePickerProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onChangeMobile = this.onChangeMobile.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onPaste = this.onPaste.bind(this);

    // Store the position where the selection highlight starts
    this.caretStartPosition = 0;

    /*
     * Store user's input, to determine when to move to the next unit.
     * e.g. if user enters 0, followed by 1 for hour, the selection moves to minutes.
     */
    this.previousEdits = [];

    const { value, showSeconds, use24hours, placeholderSymbol, separatorSymbol, periodConfig } =
      props;
    const isOnMobile = this.isMobile();
    let textInput;
    if (isOnMobile) {
      textInput = getTimeFromObject(value, showSeconds, true);
    } else {
      textInput = getTimeFromObject(
        value,
        showSeconds,
        use24hours,
        placeholderSymbol,
        separatorSymbol,
        periodConfig
      );
    }
    this.state = {
      textInput,
      touchEnabled: isTouchEnabled(),
      isOnMobile,
      /*
       * Track timeout in state to properly clear timeout in Safari
       * due to multiple onFocus events being fired (https://github.com/facebook/react/issues/10871)
       */
      timeout: null
    };
  }

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setFocus' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.setFocus && this.textbox) {
      this.textbox.focus();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { value, showSeconds, use24hours, placeholderSymbol, separatorSymbol, periodConfig } =
      nextProps;
    const time = getTimeFromObject(
      value,
      showSeconds,
      use24hours,
      placeholderSymbol,
      separatorSymbol,
      periodConfig
    );
    if (!this.isUserEditing) {
      this.setState({
        textInput: time
      });
    }
  }

  componentDidUpdate() {
    if (
      this.textbox &&
      this.textbox.setSelectionRange &&
      this.isUserEditing &&
      !this.props.readOnly
    ) {
      if (!this.invalidInput) {
        this.invalidInput = false;
        const unit = parseInt(this.state.textInput.substr(this.caretStartPosition, 2), 10);
        const { use24hours } = this.props;
        // Move caret to the next unit after user enters a number
        // a) greater than 1 for hours, using 12 hour format
        // b) greater than 2 for hours, using 24 hour format
        // c) greater than 5 for minutes and seconds
        if (
          !this.upDownArrowPressed &&
          (this.previousEdits.length > 1 ||
            (this.caretStartPosition === 0 && !use24hours && unit > 1) ||
            (this.caretStartPosition === 0 && use24hours && unit > 2) ||
            (this.caretStartPosition >= 3 && unit > 5))
        ) {
          this.moveCaretPosition();
          this.isUserEditing = false;
        }
      }
      this.textbox.setSelectionRange(this.caretStartPosition, this.getCaretEndPosition());
    }
  }

  /*
   * Increase or decrease the value of currently selected unit
   * @param {integer} increment
   */
  calculateTime(increment: any) {
    const { use24hours, showSeconds } = this.props;
    const currentValue = parseInt(this.state.textInput.substr(this.caretStartPosition, 2), 10);
    let time = 0;
    if (this.caretStartPosition === 0) {
      // increase or decrease hour
      if (use24hours) {
        time = isNaN(currentValue) ? 0 : currentValue + increment;
        if (time > 23) {
          time = 0;
        } else if (time < 0) {
          time = 23;
        }
      } else {
        time = isNaN(currentValue) ? 1 : currentValue + increment;
        if (time > 12) {
          time = 1;
        } else if (time < 1) {
          time = 12;
        }
      }
      return padZero(time);
    } else if (this.caretStartPosition === 3 || (showSeconds && this.caretStartPosition === 6)) {
      // increase or decrease minute/second
      time = isNaN(currentValue) ? 0 : currentValue + increment;
      if (time > 59) {
        time = 0;
      } else if (time < 0) {
        time = 59;
      }
      return padZero(time);
    } else if (
      (this.caretStartPosition === 6 && !this.props.showSeconds) ||
      this.caretStartPosition === 9
    ) {
      // update period
      const period = this.state.textInput.substr(this.caretStartPosition);
      const { beforeNoonLabel = 'AM', afterNoonLabel = 'PM' } = this.props.periodConfig
        ? this.props.periodConfig
        : {};
      if (period === this.props.placeholderSymbol.repeat(2)) {
        return increment > 0 ? beforeNoonLabel : afterNoonLabel;
      }
      return period === beforeNoonLabel ? afterNoonLabel : beforeNoonLabel;
    }
  }

  onKeyDown(e: any) {
    this.upDownArrowPressed = false;
    if (this.props.readOnly) return;
    switch (e.keyCode) {
      case 8:
      case 46: {
        // delete or backspace key is pressed
        // replace the current selection with "--"

        // cancel firing onChange event to avoid this.props.onChange being called twice
        e.preventDefault();
        if (
          (this.caretStartPosition === 6 && !this.props.showSeconds) ||
          this.caretStartPosition === 9
        ) {
          // No need to clear period. It should always have a value.
          return;
        }
        const newInput = this.updateTextInput(this.props.placeholderSymbol.repeat(2));
        this.handleChange(newInput);
        break;
      }
      case 37:
        // left arrow is pressed
        e.preventDefault();
        this.moveCaretPosition('left');
        this.textbox.setSelectionRange(this.caretStartPosition, this.getCaretEndPosition());
        break;
      case 38: {
        // up arrow is pressed
        e.preventDefault();
        this.upDownArrowPressed = true;
        const newInput = this.updateTextInput(this.calculateTime(1));
        this.handleChange(newInput);
        break;
      }
      case 39:
        // right arrow or tab keys are pressed
        e.preventDefault();
        if (
          this.caretStartPosition === 0 &&
          !this.props.use24hours &&
          this.state.textInput.substr(0, 2) === '00'
        ) {
          // update 00 to 12 for 12-hour format, when selection is moved off hour
          const newInput = this.updateTextInput(this.calculateTime(-1));
          this.handleChange(newInput);
        } else {
          this.moveCaretPosition('right');
          this.textbox.setSelectionRange(this.caretStartPosition, this.getCaretEndPosition());
        }
        break;
      case 40: {
        // down arrow is pressed
        e.preventDefault();
        this.upDownArrowPressed = true;
        const newInput = this.updateTextInput(this.calculateTime(-1));
        this.handleChange(newInput);
        break;
      }
      default:
        // Upon selecting all text, reset caret start position to 0
        if ((e.ctrlKey || e.metaKey) && e.keyCode === 65) {
          this.caretStartPosition = 0;
        }
    }
  }

  /*
   * Move caret to the start position of next selection
   * @param {string} direction - move to 'right' or 'left'
   */
  moveCaretPosition(direction = 'right') {
    if (direction === 'right') {
      let maxPosition = 3;
      maxPosition = this.props.showSeconds ? maxPosition + 3 : maxPosition;
      maxPosition = this.props.use24hours ? maxPosition : maxPosition + 3;
      if (this.caretStartPosition < maxPosition) {
        this.previousEdits.length = 0;
        this.caretStartPosition += 3;
      }
      this.updateHour();
    } else if (direction === 'left' && this.caretStartPosition > 0) {
      this.previousEdits.length = 0;
      this.caretStartPosition -= 3;
    }
  }

  updateHour() {
    if (!this.props.use24hours && this.state.textInput.substr(0, 2) === '00') {
      // update hour from 00 to 12, as "00:35 AM" isn't a valid time in 12-hour format
      const newInput = `12${this.state.textInput.substr(2)}`;
      this.handleChange(newInput);
    }
  }

  onBlur(e: any) {
    clearTimeout(this.state.timeout);
    this.setState({ timeout: null });
    this.caretStartPosition = 0;
    this.previousEdits.length = 0;
    this.isUserEditing = false;
    this.updateHour();
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBlur' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.props.onBlur) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBlur' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.onBlur(e);
    }
  }

  onClick(e: any) {
    if (!this.textbox || !this.textbox.setSelectionRange || this.props.readOnly) return;
    const newStartPosition = getCaretStartPosition(e.target.selectionStart);
    if (this.caretStartPosition !== newStartPosition) {
      this.previousEdits.length = 0;
      this.caretStartPosition = newStartPosition;
    }
    // Prevent second click deselecting range
    this.textbox.setSelectionRange(this.caretStartPosition, this.caretStartPosition);
    this.textbox.setSelectionRange(this.caretStartPosition, this.getCaretEndPosition());
  }

  onFocus(e: any) {
    if (this.textbox && this.textbox.setSelectionRange && !this.props.readOnly) {
      this.previousEdits.length = 0;
      // PROD-39585: Safari bug fix for multiple onFocus fires.
      // Clear timeout in onFocus and onBlur since order of extra fires isn't consistent.
      this.setState({
        timeout: setTimeout(() => {
          this.caretStartPosition = 0;
        }, 0)
      });
      clearTimeout(this.state.timeout);
    }
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  getHour(value: any) {
    const hour = parseInt(value.substr(0, 1), 10);
    const textInput = this.state.textInput;
    if (isNaN(hour)) return textInput.substr(0, 2);

    const { use24hours } = this.props;
    const prevHour = parseInt(textInput.substr(0, 2), 10);
    if (
      this.previousEdits.length > 1 &&
      ((!use24hours && prevHour === 1 && hour <= 2) ||
        (use24hours && prevHour === 1) ||
        (use24hours && prevHour === 2 && hour < 4))
    ) {
      return `${prevHour}${hour}`;
    }
    return padZero(hour);
  }

  getMinuteOrSecond(value: any) {
    const time = parseInt(value.substr(this.caretStartPosition, 1), 10);
    if (isNaN(time)) return this.state.textInput.substr(this.caretStartPosition, 2);

    const prevTime = parseInt(this.state.textInput.substr(this.caretStartPosition, 2), 10);
    if (this.previousEdits.length > 1 && prevTime < 6) {
      return `${prevTime}${time}`;
    }
    return padZero(time);
  }

  getPeriod(value: any) {
    const period = value.substr(-1).toLocaleLowerCase();
    const {
      beforeNoonLabel = 'AM',
      beforeNoonKey = 'a',
      afterNoonKey = 'p',
      afterNoonLabel = 'PM'
    } = this.props.periodConfig ? this.props.periodConfig : {};
    if (period !== beforeNoonKey && period !== afterNoonKey) {
      return this.state.textInput.substr(this.caretStartPosition);
    }

    if (period === beforeNoonKey) return beforeNoonLabel;
    if (period === afterNoonKey) return afterNoonLabel;
  }

  getCaretEndPosition() {
    const { showSeconds } = this.props;
    const { textInput } = this.state;
    return (!showSeconds && this.caretStartPosition === 6) ||
      (showSeconds && this.caretStartPosition === 9)
      ? textInput.length
      : this.caretStartPosition + 2;
  }

  /*
   * @param {string} newUnitValue - new value for a unit, e.g. "11" for hour
   * @return {string} - newly constructed string
   */
  updateTextInput(newUnitValue: any) {
    const prevValue = this.state.textInput;
    const updatePosition = this.caretStartPosition;
    /* eslint-disable max-len */
    return `${prevValue.substr(0, updatePosition)}${newUnitValue}${prevValue.substr(
      this.getCaretEndPosition()
    )}`;
    /* eslint-enable max-len */
  }

  onChange(event: any) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const target = event.target ? event.target : window.event.srcElement;
    if (this.isUserPasting) {
      this.isUserPasting = false;
      this.previousEdits.length = 0;
      const {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
        fieldName,
        use24hours,
        showSeconds,
        placeholderSymbol,
        separatorSymbol,
        periodConfig
      } = this.props;
      const value = this.props.formatTime(
        target.value,
        showSeconds,
        use24hours,
        placeholderSymbol,
        separatorSymbol,
        periodConfig
      );
      const textInput = getTimeFromObject(
        value,
        showSeconds,
        use24hours,
        placeholderSymbol,
        separatorSymbol,
        periodConfig
      );
      this.setState({ textInput });
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(
        fieldName,
        value,
        textInput,
        this.props.getStatusCode(textInput, use24hours, separatorSymbol, periodConfig)
      );
      return;
    }

    let newInput = target.value;
    const number = target.value.substr(this.caretStartPosition, 1);
    this.invalidInput = isNaN(number);
    if (!isNaN(number)) {
      this.previousEdits.push(number);
    }
    if (this.caretStartPosition === 0) {
      // Update hours
      newInput = this.updateTextInput(this.getHour(target.value));
    } else if (
      this.caretStartPosition === 3 ||
      (this.caretStartPosition === 6 && this.props.showSeconds)
    ) {
      // Update minutes or seconds
      newInput = this.updateTextInput(this.getMinuteOrSecond(target.value));
    } else if (
      (this.caretStartPosition === 6 && !this.props.showSeconds) ||
      this.caretStartPosition === 9
    ) {
      // Update AM/PM period
      const { beforeNoonKey = 'a', afterNoonKey = 'p' } = this.props.periodConfig
        ? this.props.periodConfig
        : {};
      this.invalidInput =
        target.value.substr(this.caretStartPosition, 1).toLocaleLowerCase() !== beforeNoonKey &&
        target.value.substr(this.caretStartPosition, 1).toLocaleLowerCase() !== afterNoonKey;
      this.previousEdits.length = 0;
      newInput = this.updateTextInput(this.getPeriod(target.value));
    }

    this.handleChange(newInput);
  }

  handleChange(newInput: any) {
    this.isUserEditing = true;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
    const { fieldName, use24hours, showSeconds, placeholderSymbol, separatorSymbol, periodConfig } =
      this.props;
    const value = this.props.formatTime(
      newInput,
      showSeconds,
      use24hours,
      placeholderSymbol,
      separatorSymbol,
      periodConfig
    );
    this.setState({
      textInput: newInput
    });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    this.props.onChange(
      fieldName,
      value,
      newInput,
      this.props.getStatusCode(newInput, use24hours, separatorSymbol, periodConfig)
    );
  }

  onPaste() {
    this.isUserPasting = true;
  }

  onChangeMobile(e: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
    const { fieldName, showSeconds } = this.props;
    const textInput = e.target.value;
    const value = this.props.formatTime(textInput, showSeconds, true);

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    this.props.onChange(fieldName, value, textInput, this.props.getStatusCode(textInput, true));
  }

  // TODO: BAD! We shouldn't be doing this, but except Chrome, all other desktop browsers
  // haven't implemented time picker yet, so we're using an isMobile function to
  // check on the user agent, so that we only use the browser time picker for mobile devices.
  isMobile() {
    let isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|CriOS|Tizen/i.test(
        navigator.userAgent
      );

    if (!isMobile) {
      const isMac = RegExp(/Macintosh/i).test(navigator.userAgent);

      if (isMac && navigator && navigator.maxTouchPoints && navigator.maxTouchPoints > 2) {
        isMobile = true;
      }
    }
    return isMobile;
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'Readonly<Tim... Remove this comment to see the full error message
    const { id, name, fieldName, showSeconds, value, required } = this.props;
    const resolvedProps = removeKeys(removeFormElementProps(this.props), [
      'maxLength',
      'use24hours',
      'showSeconds',
      'formatTime',
      'getStatusCode',
      'translate',
      'periodConfig',
      'placeholderSymbol',
      'separatorSymbol'
    ]);
    // NOTE: mobile safari doesn't support step attribute, so it doesn't show second
    if (this.state.touchEnabled && this.state.isOnMobile) {
      const newValue = {
        ...value,
        second: value.second === undefined ? 0 : value.second
      };
      return (
        <>
          <input
            {...resolvedProps}
            {...resolveTestId(this.props)}
            value={getTimeFromObject(newValue, showSeconds, true) || ''}
            type="time"
            step={showSeconds ? '1' : '60'}
            required={required}
            id={id || fieldName}
            name={name || fieldName}
            onChange={this.onChangeMobile}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            aria-labelledby={fieldName}
          />
          {this.props.showInstructionalText ? (
            <div id={fieldName} className={stylesButton.instructionalText}>
              {this.props.timeInstructionalText}
            </div>
          ) : (
            ''
          )}
        </>
      );
    }

    return (
      <>
        <input
          {...resolvedProps}
          {...resolveTestId(this.props)}
          value={this.state.textInput || ''}
          type="text"
          required={required}
          ref={c => (this.textbox = c)}
          id={id || fieldName}
          name={name || fieldName}
          onChange={this.onChange}
          onClick={this.onClick}
          onKeyDown={this.onKeyDown}
          onBlur={this.onBlur}
          onPaste={this.onPaste}
          onFocus={this.onFocus}
          aria-labelledby={fieldName}
        />
        {this.props.showInstructionalText ? (
          <div id={fieldName} className={stylesButton.instructionalText}>
            {this.props.timeInstructionalText}
          </div>
        ) : (
          ''
        )}
      </>
    );
  }
}

type TimePickerFormElementProps = WithFormProps & {
  children?: React.ReactNode;
  size?: string;
  style?: {
    textbox?: any;
  };
  errorMessages?: {
    [key: string]: string;
  };
  displayValid?: boolean;
  validationState?: any; // TODO: PropTypes.oneOf(['valid', 'error', undefined])
  placeholderSymbol?: string;
  separatorSymbol?: string;
  periodConfig?: {
    beforeNoonLabel?: string;
    beforeNoonKey?: string;
    afterNoonLabel?: string;
    afterNoonKey?: string;
  };
  value?: {
    hour?: number;
    minute?: number;
    second?: number;
  };
  translate: (...args: any[]) => any;
};

/**
FormElement wrapper around TimePicker. This is the default export.
**/
const TimePickerFormElement = (props: TimePickerFormElementProps) => {
  const {
    children,
    size = '',
    displayValid,
    validationState,
    errorMessages = {},
    ariaDescribedby,
    ...rest
  } = props;
  const formattedValue = getTimeFromObject(
    props?.value,
    false,
    true,
    props?.placeholderSymbol,
    props?.separatorSymbol,
    props?.periodConfig
  );
  const errorsPresent = Object.keys(errorMessages).length > 0;
  const validState = displayValid ? 'valid' : undefined;
  const resolvedValidationState = validationState || (errorsPresent ? 'error' : validState);
  return (
    <FormElement errorMessages={errorMessages} {...rest}>
      <TimePicker
        {...rest}
        {...resolve(props, 'textbox', size, resolvedValidationState)}
        {...injectTestId('input')}
        role="spinbutton"
        aria-describedby={ariaDescribedby}
        aria-valuenow={formattedValue || ''}
        aria-valuetext={formattedValue || ''}
        aria-valuemin="0100"
        aria-valuemax="1259"
      />
      {children}
    </FormElement>
  );
};
TimePickerFormElement.displayName = 'TimePickerFormElement';

export { TimePickerFormElement };
