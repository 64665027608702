import { initLogs } from './initializeLogging';
import { ServiceError } from './ServiceError';
/**
 * A log client that works with Datadog browser logging
 * See https://docs.datadoghq.com/logs/log_collection/javascript/
 */
export class BrowserDatadogLogClient {
  ddLogger: $TSFixMe;
  constructor(logger: $TSFixMe) {
    this.ddLogger = logger;
  }

  static init(config: $TSFixMe) {
    const logger = initLogs(config);
    if (config.isDebug) logger.setHandler('console');
    return new BrowserDatadogLogClient(logger);
  }

  getContext() {
    return this.ddLogger.getContext();
  }

  setContext(value: $TSFixMe) {
    this.ddLogger.setContext(value);
  }

  formatLogMessage(args: $TSFixMe) {
    const name = args[0];
    const message = args[1];
    const attributes = Object.assign({}, args.slice(2), { logger: { name } });
    return [message, attributes];
  }

  debug = (...args: $TSFixMe[]) => {
    this.ddLogger.debug(...this.formatLogMessage(args));
  };

  info = (...args: $TSFixMe[]) => {
    this.ddLogger.info(...this.formatLogMessage(args));
  };

  warn = (...args: $TSFixMe[]) => {
    this.ddLogger.warn(...this.formatLogMessage(args));
  };

  error = (...args: $TSFixMe[]) => {
    const filteredArgs = args.filter(arg => {
      if (arg instanceof Error) {
        this.ddLogger.setContextProperty('error', {
          ...arg,
          kind: arg.name,
          message: arg.message,
          stack: arg.stack
        });
        if (arg instanceof ServiceError) this.ddLogger.setContextProperty('httpLogRequestId', arg.httpLogRequestId);
        return false;
      }
      return true;
    });
    this.ddLogger.error(...this.formatLogMessage(filteredArgs));
  };
}
