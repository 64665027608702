import debounce from 'lodash/debounce';
import { Logger } from '@cvent/nucleus-logging';
import { FetchClient, FetchClientOptions } from '@cvent/fetch';
import isCrawlerBot from './isCrawlerBot';
const LOG = new Logger('nucleus-analytics-middleware');

interface MiddlewareConfig {
  hasInternetConnectivity?: boolean;
  analyticsReporter?: (p?: any) => any | void;
  // how long to debounce and collect up facts
  maxWaitMs?: number;
  // limit on how many facts to report in 'batch'. Extra facts are lost and
  // are reported as single nucleus-analytics-middleware/METRICS_THRESHOLD_VIOLATED.
  maxFactsPerInterval?: number;
  factProvider?: (prevState: any, action: any, nextState: any) => any;
}

export interface Fact {
  type: string;
  ts: number;
  deviceId?: string;
  location?: string;
}

interface Action {
  type?: string;
}

interface Store {
  getState(): any;
}

interface AnalyticsReporterConfig {
  authToken?: string;
  withCookies?: boolean;
  errorLogLimit?: number;
}

// get the current browser url.
function getLocation(): { location: string } | null {
  return typeof window !== 'undefined' && window.location
    ? { location: window.location.toString() }
    : null;
}

if (typeof window !== 'undefined' && typeof Headers === 'undefined') {
  // @ts-expect-error Polyfill window.Headers
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  window.Headers = () => {}; // polyfill in case this code is loaded during server side rendering.
}

let deviceId: string | null = null;
// look for a backend set deviceId from the cookie.
if (typeof window !== 'undefined' && window.document) {
  const cookies: string[] = window.document.cookie.split(';');
  let analyticsCookie = null;
  for (let i = 0; i < cookies.length; i++) {
    if (cookies[i].trim().indexOf('analytics=') === 0) {
      analyticsCookie = cookies[i];
      break;
    }
  }
  if (analyticsCookie !== null) {
    const vals: string[] = analyticsCookie.split('device-id=');
    if (vals.length > 1) {
      // TRACKING COOKIE FOUND
      deviceId = vals[1].split('&')[0];
    }
  }
}

// get the deviceId part of fact.
function getDeviceId(): { deviceId: string } | null {
  return deviceId ? { deviceId } : null;
}

// generates a default fact matching the expected format using state and action.
export function defaultFactProvider(prevState: Record<string, any>, action: Action): Fact | null {
  if (!action.type) {
    return null;
  }
  return {
    type: action.type,
    ...getLocation(),
    ...getDeviceId(),
    ts: new Date().getTime()
  };
}

// a fact provider that looks up a fact provider in the provide actionMap.
// actionMap is a Map<string, FactProviderFunction>
export function simpleFactProvider(actionMap: Map<string, () => any>) {
  return (prevState: Record<string, any>, action: Action, nextState: Record<string, any>) => {
    if (action.type && actionMap.has(action.type)) {
      // @ts-expect-error ts-migrate()
      return actionMap.get(action.type)!(prevState, action, nextState);
    }
  };
}

// a fact provider that provides no facts ever.
export function nullFactProvider(): null {
  return null;
}

// generate a tooManyFacts Fact when we have exceed our max per analytics batch call.
export function getTooManyFactsFact(facts: Array<Fact>) {
  const countMap: { [key: string]: number } = {};
  facts.forEach((fact: Fact) => {
    if (countMap.hasOwnProperty(fact.type)) {
      countMap[fact.type]++;
    } else {
      countMap[fact.type] = 1;
    }
  });
  return {
    ...countMap,
    ...defaultFactProvider({}, { type: 'nucleus-analytics-middleware/METRICS_THRESHOLD_VIOLATED' })
  };
}

// Mock / Log based analytics reporter.
export function mockAnalyticsReporter() {
  return (facts: Array<Fact>): void => {
    LOG.info('Mocked Analytic Facts To Upload:', JSON.stringify(facts));
  };
}

export class FactStore {
  pendingFacts: Array<Fact>;

  constructor() {
    this.pendingFacts = [];
  }

  /* eslint-disable @typescript-eslint/explicit-member-accessibility */
  add(fact: Fact | Array<Fact>) {
    const isArray = Array.isArray(fact);
    if (isArray && !(fact as Array<Fact>).length) {
      return;
    }

    if (isArray && (fact as Array<Fact>).length) {
      (fact as Array<Fact>).forEach(item => {
        this.pendingFacts.push(item);
      });
    } else {
      this.pendingFacts.push(fact as Fact);
    }
  }

  clear() {
    this.pendingFacts.length = 0;
  }

  get() {
    return this.pendingFacts;
  }
}

export function reportPendingFacts(factStore: FactStore, config: MiddlewareConfig) {
  const {
    analyticsReporter = mockAnalyticsReporter(), // default client is to a mock backend.
    // To use the real system, set see cventAnaytics.js.
    maxWaitMs = 5000, // how long to debounce and collect up facts
    maxFactsPerInterval = 20 // limit on how many facts to report in 'batch'. extra  facts are lost and
    // are reported as single nucleus-analytics-middleware/METRICS_THRESHOLD_VIOLATED.
  } = config;
  return debounce(
    () => {
      const pendingFacts: Array<Fact> = factStore.get();
      if (maxFactsPerInterval <= pendingFacts.length) {
        // if we blew our max, report fact with counts by fact type
        // @ts-expect-error ts-migrate()
        analyticsReporter([getTooManyFactsFact(pendingFacts)]);
      } else {
        analyticsReporter(pendingFacts);
      }
      factStore.clear();
    },
    maxWaitMs,
    { maxWait: maxWaitMs, leading: true }
  );
}

/*
 * function to report facts, if you don't have a redux application you can use this
 */
export function factReporter(
  fact: Fact | Array<Fact>,
  factStore: FactStore,
  config: MiddlewareConfig,
  reportPendingFactsCallback?: () => void
): void {
  const store = !factStore ? new FactStore() : factStore;
  const reportPendingFactsCB = !reportPendingFactsCallback
    ? reportPendingFacts(store, config)
    : reportPendingFactsCallback;
  const { hasInternetConnectivity = true } = config;
  if (fact) {
    store.add(fact);
    if (hasInternetConnectivity) {
      reportPendingFactsCB();
    }
  }
}

/** Redux middleware that can be configure to capture facts from you redux actions
 A provided factProvider is responsible for creating facts from redux actions it sees */
export function actionReporterMiddleware(config: MiddlewareConfig) {
  // initialize fact store
  const factStore = new FactStore();
  const {
    factProvider = nullFactProvider // default provider, this one generates no facts.
  } = config;
  const reportPendingFactsCallback = reportPendingFacts(factStore, config);
  return (store: Store) => (next: (param: any) => any) => (action: Record<string, any>) => {
    const prevState = store.getState();
    const result = next(action);
    const nextState = store.getState();
    try {
      const fact = factProvider(prevState, action, nextState);
      factReporter(fact, factStore, config, reportPendingFactsCallback);
    } catch (e: any) {
      const errorFact: Fact = {
        ...{ error: e.toString() },
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        ...defaultFactProvider({}, { type: 'nucleus-analytics-middleware/ERROR' })!
      };
      factReporter(errorFact, factStore, config, reportPendingFactsCallback);
      // consider call to new relic call here after next agent release.
    }
    return result;
  };
}

export function cventAnalyticsReporter(
  url?: string,
  { authToken, withCookies, errorLogLimit = 1 }: AnalyticsReporterConfig = {}
) {
  let requestUrl = url;
  if (typeof requestUrl === 'undefined') {
    requestUrl = '/api/analytics/batchfacts';
    LOG.warn(
      'The default parameter of url will be removed in 4.0.0.' +
        'Before updating to nucleus-analytics-middleware >= 4.0.0 set an environment specific URL.'
    );
  }
  let numberOfErrorsLogged = 0;
  const headers: { [key: string]: string } = {};
  if (authToken) {
    headers.Authentication = authToken;
  }
  const fetchClientOptions: FetchClientOptions = {
    init: { headers, mode: 'cors', cache: 'no-cache' }
  };
  if (withCookies && fetchClientOptions.init) {
    fetchClientOptions.init.credentials = 'include';
  }
  const fetchClient: FetchClient = new FetchClient(fetchClientOptions);
  return (facts: Fact[]) => {
    fetchClient
      .post(requestUrl || '', JSON.stringify(facts))
      .then((response: any) => {
        if (response.status !== 200 && response.status !== 201 && response.status !== 204) {
          if (numberOfErrorsLogged < errorLogLimit) {
            LOG.error(
              'fact api returned a non-200/201/204 return code',
              response.status,
              response.text()
            );
            numberOfErrorsLogged++;
            if (numberOfErrorsLogged === errorLogLimit) {
              LOG.info(
                'no more nucleus-analytics-middleware errors will be logged during this page load'
              );
            }
          }
        }
      })
      .catch((e: any) => {
        if (isCrawlerBot(navigator)) {
          // Skip log messages for bots. Several bots will cancel the request
          // causing a large amount of the error logs to spam splunk and logging
          // ingest tools.
          return;
        }
        if (numberOfErrorsLogged < errorLogLimit) {
          LOG.info('failed to upload facts; it was probably blocked by an ad-blocker', e);
          numberOfErrorsLogged++;
          if (numberOfErrorsLogged === errorLogLimit) {
            LOG.info(
              'no more nucleus-analytics-middleware errors will be logged during this page load'
            );
          }
        }
      });
  };
}

/**
    This function takes in 3 parameters
    1. analyticsEnabled -> used to decide if we need to report facts to analytics.
    2. baseFactProvider -> provides the base fact information which are present in all facts reported
    3. factProvider -> provides the app specific facts which are to be reported to analytics
    This function combines the baseFactProvider's fact definition and factProvider's fact definition
    to generate the final fact that will be used to publishing to the analytics platform.
    e.g baseFactProvider's fact definition -> { app_id: 'some_app_id'}
    factProvider's fact definition -> { appSpecificAttribute: 'some_value'}
    final fact generated through crateFactProvider -> { app_id: 'some_app_id', appSpecificAttribute: 'some_value' }
*/
export function createFactProvider(
  analyticsEnabled: any,
  baseFactProvider: (prevState: any, action: any, nextState: any) => any,
  appFactProvider: (prevState: any, action: any, nextState: any) => any
) {
  return (prevState: Record<string, any>, action: Action, nextState: Record<string, any>) => {
    if (!analyticsEnabled) {
      return null;
    }
    // Build the base information to apply to all facts.
    const baseFactInfo = {
      ...baseFactProvider(prevState, action, nextState)
    };

    let appFacts = appFactProvider(prevState, action, nextState);
    if (appFacts && !(appFacts instanceof Array)) {
      appFacts = [appFacts];
    }

    return (appFacts || []).map((fact: Record<string, any>) => ({ ...baseFactInfo, ...fact }));
  };
}
