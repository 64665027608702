import { siteInfoMigrations } from './siteInfo';

/**
 * Version 6 migrations for the website.
 * - Migrate new shared config settings into siteInfo for LanguageSelector widget.
 */
export function v6(website: any) {
  return {
    ...website,
    frameworkVersion: 6,
    siteInfo: siteInfoMigrations(website.siteInfo)
  };
}
