async function getBody(response: $TSFixMe) {
  const body = await response.text();
  try {
    return JSON.parse(body);
  } catch (ex) {
    return body;
  }
}

export class ServiceError extends Error {
  errorDateTime: $TSFixMe;
  httpLogPageLoadId: $TSFixMe;
  httpLogRequestId: $TSFixMe;
  requestMethod: $TSFixMe;
  requestUrl: $TSFixMe;
  responseBody: $TSFixMe;
  responseHeaders: $TSFixMe;
  responseStatus: $TSFixMe;
  constructor(message: $TSFixMe, response: $TSFixMe, request: $TSFixMe, body: $TSFixMe) {
    super(message);
    Object.setPrototypeOf(this, ServiceError.prototype);

    this.responseStatus = response.status;
    this.responseBody = body;
    this.httpLogRequestId = request.headers.get('HttpLogRequestId');
    this.httpLogPageLoadId = request.headers.get('HttpLogPageLoadId');
    this.errorDateTime = new Date();
    this.requestUrl = encodeURI(request.url);
    this.requestMethod = request.method;
    this.responseHeaders = response.headers;
    this.name = 'ServiceError';
  }

  static async create(message: $TSFixMe, response: $TSFixMe, request: $TSFixMe) {
    const body = await getBody(response);
    return new ServiceError(message, response, request, body);
  }
}
