/**
 * A container for the modal that displays the event information
 **/

import React from 'react';

import tagStyles from 'cvent-event-calendar/lib/CalendarView/styles/Tags.less';
import { getDateFormatOptions, getDateFormat } from 'cvent-event-calendar/utils/dateFormatUtil';
import { ThemeableComponent, getEms, getInlineStyle } from 'nucleus-widgets';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import CalendarModal from '../components/CalendarModal';
import calendarItemDetailViewStyles from './styles/CalendarItemDetailView.less';
import modalButtonStyles from './styles/ModalButton.less';
import { getParentWindowDimensions, scrollToTop } from '../redux/modules/embeddedSettings';
import { closeEventDetailsDialog } from '../redux/modules/eventDetails';
import { getTags } from '../redux/modules/reducer';

const defaultItemDetailClasses = {
  calendarItemDetailView: {
    ...tagStyles,
    ...calendarItemDetailViewStyles,
    button: modalButtonStyles
  }
};

class EventDetailsModal extends ThemeableComponent<$TSFixMe> {
  static propTypes = {
    style: PropTypes.any,
    getStyleObject: PropTypes.func,
    scrollToTop: PropTypes.func,
    getParentWindowDimensions: PropTypes.func
  };

  getElementInlineStyle: $TSFixMe;
  props: $TSFixMe;

  /*
   * The font size of the field header/label should always be 12.5% larger than the field value
   */
  getIncreasedFontSize() {
    const { style } = this.props;
    if (!style) {
      return null;
    }
    const { description } = style;
    const textStyles = description.styleMapping === 'custom' ? description.text : style.elements[description.styleMapping].text;
    const fontSizeInPixels = textStyles.fontSize + (textStyles.fontSize * 12.5) / 100;
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    return `${getEms(fontSizeInPixels)}em`;
  }

  /**
   * Gets the font size of the link in pixels
   */
  getLinksFontSizeInPixels() {
    const { style } = this.props;
    if (!style) {
      return null;
    }
    const { links } = style;
    const linkStyles = links.styleMapping === 'custom' ? links.text : style.elements[links.styleMapping].text;
    const fontSizeInPixels = linkStyles.fontSize;
    return `${fontSizeInPixels}px`;
  }

  getStyleObject() {
    if (this.props.getStyleObject) {
      return this.props.getStyleObject();
    }
    const { style } = this.props;
    const descriptionInlineStyles = this.getElementInlineStyle('description');
    const locationInlineStyles = this.getElementInlineStyle('location');
    const dateTimeInlineStyles = this.getElementInlineStyle('dateTime');
    const tagInlineStyles = style ? getInlineStyle(style.elements.body2, {}, style.palette) : {};

    return {
      calendarItemDetailView: {
        tag: {
          // @ts-expect-error TS(2339): Property 'fontSize' does not exist on type '{}'.
          fontSize: tagInlineStyles.fontSize,
          // @ts-expect-error TS(2339): Property 'fontFamily' does not exist on type '{}'.
          fontFamily: tagInlineStyles.fontFamily,
          // @ts-expect-error TS(2339): Property 'fontWeight' does not exist on type '{}'.
          fontWeight: tagInlineStyles.fontWeight,
          // @ts-expect-error TS(2339): Property 'fontStyle' does not exist on type '{}'.
          fontStyle: tagInlineStyles.fontStyle
        },
        title: this.getElementInlineStyle('title'),
        location: locationInlineStyles,
        eventDate: dateTimeInlineStyles,
        description: descriptionInlineStyles,
        html: descriptionInlineStyles,
        date: descriptionInlineStyles,
        addToCalendar: {
          ...this.getElementInlineStyle('links'),
          fontSize: this.getLinksFontSizeInPixels()
        },
        fieldName: {
          ...descriptionInlineStyles,
          margin: '1.25em 0 .55em',
          fontWeight: '700',
          fontSize: this.getIncreasedFontSize()
        },
        custom: descriptionInlineStyles,
        dateIcon: {
          icon: {
            color: dateTimeInlineStyles.color
          }
        },
        locationIcon: {
          icon: {
            color: locationInlineStyles.color
          }
        },
        button: {
          button: this.getElementInlineStyle('primaryButton')
        }
      },
      dialogStyles: {
        dragContainer: {
          backgroundColor: style && style.palette.secondary
        },
        closeButton: {
          color: style && style.palette.textAccent
        }
      }
    };
  }

  render() {
    const { translate, showIcons, popUpDialog } = this.props;
    const iconSettings = {
      showIcons,
      fallbackText: {
        location: translate('_calendarListView_location__resx'),
        date: translate('_calendarListView_date__resx')
      }
    };
    return (
      <CalendarModal
        {...this.props}
        classes={defaultItemDetailClasses}
        isModalOpen={popUpDialog}
        iconSettings={iconSettings}
        style={this.getStyleObject()}
        getParentWindowDimensions={this.props.getParentWindowDimensions}
        scrollToTop={this.props.scrollToTop}
        parentWindowDimensions={this.props.parentWindowDimensions}
      />
    );
  }
}

/**
 * A selector to get the date format
 *
 */
const getDateFormatSelector = createSelector(
  (state: $TSFixMe) => state.calendar.config.dateFormatId,
  (state: $TSFixMe) => state.calendar.config.timeFormatId,
  (state: $TSFixMe) => state.text.translate,
  (state: $TSFixMe) => state.text.resolver.date,
  (dateFormatId: $TSFixMe, timeFormatId: $TSFixMe, translate: $TSFixMeFunction, date: $TSFixMe) => {
    const dateFormat = getDateFormat(dateFormatId);
    const dateTimeDelimiter = ' ' + translate('_calendarList_dateTimeDelimiter__resx') + ' ';
    return getDateFormatOptions(dateFormat, timeFormatId, date, dateTimeDelimiter);
  }
);

export default connect(
  // @ts-expect-error TS(2339): Property 'isWebWidget' does not exist on type '{}'... Remove this comment to see the full error message
  (state: $TSFixMe, { isWebWidget, referenceId: webWidgetReferenceId }) => {
    const eventId = state.eventDetails.eventLoading;
    const existingEvent = eventId ? state.calendar.events[eventId] : null;

    return {
      scrollTop: state.eventDetails.scrollTop,
      translate: state.text.translate,
      eventDetails: {
        ...existingEvent,
        ...state.eventDetails[eventId],
        tags: getTags(state)
      },
      eventId,
      calendarId: state.calendar.id,
      dateTimeFormat: getDateFormatSelector(state),
      ...state.eventDetails.dialogConfig,
      referenceId: isWebWidget ? webWidgetReferenceId : state.calendar.config.referenceId,
      parentWindowDimensions: state.embeddedSettings.parentWindowDimensions
    };
  },
  { handleModalClose: closeEventDetailsDialog, getParentWindowDimensions, scrollToTop }
)(EventDetailsModal);
