import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { injectTestId, resolveTestId } from '@cvent/nucleus-test-automation';
import {
  FormElement,
  removeFormElementProps,
  resolveValidationAccessibilityProps,
  WithFormProps
} from '../FormElement';
import { BreakPoint } from '../../buttons';

/**
 * format value
 */
export const formatValue = (value: any, formatMask: any) => {
  const cleanVal = value.replace(/[^\d]/g, '');
  let i = 0;
  let lastReplacedIndex = -1;
  // iterate and replace '9' in mask with input value
  const filledMask = formatMask.replace(/9/g, (_: any, j: any) => {
    if (i >= cleanVal.length) {
      return '9';
    }
    lastReplacedIndex = j;
    return cleanVal[i++];
  });
  // only return valid replaced mask value
  return filledMask.substring(0, lastReplacedIndex + 1);
};
/*
 * find all occurance caret index of separator chars
 */
export const findSeparatorWithIndices = (mask: any) => {
  const separatorSet = new Set(mask.replace(/[0-9]/g, '').split(''));
  const indices = [];
  for (let i = 0; i < mask.length; i++) {
    if (separatorSet.has(mask[i])) indices.push({ separator: mask[i], index: i + 1 });
  }
  return indices;
};
/*
 * maintain input caret position if value increases.
 * reset caret position index when approaching 'separator'
 * in different senarios
 */
export const calculateCaretPosition = (
  maskSeparatorIndices: any,
  caretCurrentPosition: any,
  oldVal: any,
  newVal: any
) => {
  const isSeparatorFound = maskSeparatorIndices.find(
    (el: any) => el.index === caretCurrentPosition
  );
  let newCaret = caretCurrentPosition;
  const cleanOldVal = oldVal.replace(/[^\d]/g, '');
  const cleanNewVal = newVal.replace(/[^\d]/g, '');
  if (cleanOldVal.length < cleanNewVal.length) {
    // case increase
    if (typeof isSeparatorFound !== 'undefined') {
      if (cleanNewVal.length >= isSeparatorFound.index) {
        newCaret = caretCurrentPosition + 1;
      } else {
        newCaret = newVal.length;
      }
    }
  } else {
    // case decrease
    if (typeof isSeparatorFound !== 'undefined' && isSeparatorFound.index === 1) {
      // if replaced all by typing from beginning with separator
      newCaret = caretCurrentPosition + 1;
    }
  }
  return newCaret;
};
/*
 * Define the state key for preivous props
 */
const __SecretPrevPropValue = '__prevRenderPropValue';
/**
 * Wrapper around getDerivedStateFromProps to only react on props change and ignore setState calls
 */
export const ignoreState = (getDerivedStateFromProps: any) => (nextProps: any, prevState: any) => {
  if (
    !prevState.hasOwnProperty(__SecretPrevPropValue) ||
    prevState[__SecretPrevPropValue] !== nextProps.value
  ) {
    return {
      ...getDerivedStateFromProps(nextProps, prevState),
      [__SecretPrevPropValue]: nextProps.value
    };
  }
  return null;
};

/*
(ts-migrate) TODO: Migrate the remaining prop types
...FormElement.propTypes
*/
type OwnMaskTextboxProps = WithFormProps<{
  value: any;
  type?: 'text';
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  maxLength?: number;
  breakPoint?: BreakPoint;
  style?: Record<string, unknown>;
  formatMask: string;
}>;

type MaskTextboxState = any;

type MaskTextboxProps = React.PropsWithChildren<OwnMaskTextboxProps>;
/**
 * Display a masktextbox based on browser default input
 * The masktextbox will automatically format the input value
 * based on the value passed into the formatMask prop
 */
export class MaskTextbox extends React.PureComponent<MaskTextboxProps, MaskTextboxState> {
  static displayName = 'MaskTextbox';
  static defaultProps = {
    type: 'text',
    required: false,
    disabled: false,
    readOnly: false,
    placeholder: '',
    maxLength: null,
    formatMask: ''
  };
  constructor(props: MaskTextboxProps) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.isPaste = false;
    const { value, formatMask } = props;
    this.state = {
      textInput: formatValue(value, formatMask),
      maskSeparatorIndices: findSeparatorWithIndices(formatMask),
      newCaretPos: 0
    };
  }
  componentDidMount() {
    if (this.props.setFocus) {
      this.focus();
    }
  }
  /*
   * update ref caret position after componentDidUpdate
   */
  componentDidUpdate() {
    /*
     * only update caret by ref when modifying current MastTextbox
     * this is to deal with IE and Safari browser compatibility
     */
    if (
      typeof document !== 'undefined' &&
      this.textbox &&
      document.activeElement === this.textbox
    ) {
      this.setCaretPosition(this.state.newCaretPos);
    }
  }
  /*
   * getDerivedStateFromProps will trigger either prop or state updates
   * here we only update the state if changes is triggered from outside props
   */
  static getDerivedStateFromProps = ignoreState((nextProps: any, prevState: any) => {
    if (nextProps.value !== prevState.textInput) {
      return {
        textInput: formatValue(nextProps.value, nextProps.formatMask),
        newCaretPos: nextProps.value.length
      };
    }
    return null;
  });
  isPaste: any;
  textbox: any;
  onChange(event: any) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const target = event.target ? event.target : window.event.srcElement;
    const newInput = this.autoMask(target.value, target);

    this.props.onChange(this.props.fieldName, newInput);
  }
  onBlur(e: any) {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }
  onFocus(e: any) {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }
  select() {
    if (typeof document !== 'undefined' && this.textbox) {
      this.textbox.select();
    }
  }
  focus() {
    if (
      typeof document !== 'undefined' &&
      this.textbox &&
      document.activeElement !== this.textbox
    ) {
      // Firing "focus" opens native date picker on iOS, but does nothing on Android
      this.textbox.focus();
    }
  }
  /*
   * Perform auto mask when input text changed with provided formatMask
   */
  autoMask = (inputVal: any, target: any) => {
    const oldVal = this.state.textInput;
    let newVal = inputVal;
    const maxLength = this.props.formatMask.length;
    if (maxLength > 0) {
      if (oldVal.length === maxLength && inputVal.length > maxLength && !this.isPaste) {
        // maintain current value if input exceeds more than max length except if value is pasted
        newVal = oldVal;
      } else {
        // maintain input caret position when react input value changes.
        const caretCurrentPosition = target ? target.selectionStart : newVal.length;
        // only include numeric value
        newVal = formatValue(newVal, this.props.formatMask);
        const caretNewPosition = calculateCaretPosition(
          this.state.maskSeparatorIndices,
          caretCurrentPosition,
          oldVal,
          newVal
        );
        this.applyNewInputWithCaretPos(newVal, caretNewPosition);
      }
    } else {
      // if no format mask is defined
      this.applyNewInputWithCaretPos(newVal);
    }
    return newVal;
  };
  /*
   * Apply formatted input value to state
   * Reset caret position for react input in componentDidUpdate
   * Reset action flag for pasting
   */
  applyNewInputWithCaretPos = (newVal: any, newCaret = null) => {
    if (newCaret) {
      this.setState({ textInput: newVal, newCaretPos: newCaret });
    } else {
      this.setState({ textInput: newVal });
    }
    this.isPaste = false;
  };
  /*
   * reset input caret position based on ref
   */
  setCaretPosition = (newCaret: any) => {
    if (this.textbox) {
      this.textbox.selectionStart = this.textbox.selectionEnd = newCaret;
    }
  };
  onPaste = () => {
    this.isPaste = true;
  };
  render() {
    const { id, name, fieldName, required, errorMessages } = this.props;
    return (
      <input
        {...removeFormElementProps(this.props)}
        {...resolveTestId(this.props)}
        ref={c => {
          this.textbox = c;
        }}
        id={id || fieldName}
        name={name || fieldName}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        onPaste={this.onPaste}
        value={this.state.textInput || ''}
        required={required}
        aria-describedby={fieldName}
        {...resolveValidationAccessibilityProps(id ?? fieldName, errorMessages)}
      />
    );
  }
}

type OwnMaskTextboxFormElementProps = React.PropsWithChildren<{
  size?: string;
  breakPoint?: BreakPoint;
  style?: {
    textbox?: any;
  };
  errorMessages?: {
    [key: string]: string;
  };
  displayValid?: boolean;
  validationState?: any; // TODO: PropTypes.oneOf(['valid', 'error', undefined])
  maxLength?: number;
  formatMask?: string;
}>;

type MaskTextboxFormElementProps = React.PropsWithChildren<
  OwnMaskTextboxFormElementProps & typeof MaskTextboxFormElement.defaultProps
>;

/**
 * FormElement wrapper around MaskTextbox. This is the default export.
 *
 */
class MaskTextboxFormElement extends React.PureComponent<MaskTextboxFormElementProps> {
  static displayName = 'TextboxFormElement';
  static defaultProps = {
    size: '',
    errorMessages: {}
  };

  textbox: any;

  focus() {
    if (this.textbox) {
      this.textbox.focus();
    }
  }
  select() {
    if (this.textbox) {
      this.textbox.select();
    }
  }

  render() {
    const { children, size, breakPoint, displayValid, validationState, ...rest } = this.props;
    const { errorMessages } = rest;
    const errorsPresent = Object.keys(errorMessages).length > 0;
    const validState = displayValid ? 'valid' : undefined;
    const resolvedValidationState = validationState || (errorsPresent ? 'error' : validState);
    return (
      // @ts-expect-error error TS2769: No overload matches this call
      <FormElement {...rest}>
        <div {...resolve(this.props, 'textboxContainer')}>
          <MaskTextbox
            {...rest}
            {...resolve(this.props, 'textbox', breakPoint, size, resolvedValidationState)}
            {...injectTestId('input')}
            ref={(c: any) => (this.textbox = c)}
          />
          {children}
        </div>
      </FormElement>
    );
  }
}
export { MaskTextboxFormElement };
