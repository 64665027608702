import { Control, actions } from 'react-redux-form';

/**
 * Lifted from utils/resolve-model in react-redux-form to resolve a model
 * against it's parent form.
 */
function resolveModel(model: any, parentModel: any) {
  if (parentModel) {
    if (model[0] === '.' || model[0] === '[') {
      return `${parentModel}${model}`;
    }

    if (typeof model === 'function') {
      return (state: any) => model(state, parentModel);
    }
  }

  return model;
}

/**
 * A simple extenstion of the Control component that will reset the field to its initial state.
 * Validity is already reset by the control component.
 */
// @ts-expect-error ts-migrate(2314) FIXME: Generic type 'Control<T>' requires 1 type argument... Remove this comment to see the full error message
export class InternalControl extends Control {
  model: any;
  props: any;
  store: any;
  constructor(props: any, context: any) {
    super(props, context);
  }
  UNSAFE_componentWillMount() {
    this.store.dispatch(actions.setUntouched(resolveModel(this.props.model, this.model)));
  }
  componentWillUnmount() {
    this.store.dispatch(actions.setInitial(resolveModel(this.props.model, this.model)));
  }
}
