import { resolve } from '@cvent/nucleus-dynamic-css';

/**
 * Extracts the class names to use for the ReactCSSTransitionGroup from the
 * provided props object. If they dont exist in the props object, default
 * classnames are generated using the component name.
 * @param props {object} - The props object of the component.
 * @param componentName {string} - The name of the component
 * @returns {cssClassObject} - An object containing the relevant CSS classes to
 * pass to a ReactCSSTransitionGroup component.
 */
export function getReactCSSTransitionGroupClasses(props: any, componentName: any) {
  const enter = resolve(props, 'enter');
  const enterActive = resolve(props, 'enterActive');
  const leave = resolve(props, 'leave');
  const leaveActive = resolve(props, 'leaveActive');
  const appear = resolve(props, 'appear');
  const appearActive = resolve(props, 'appearActive');
  return {
    enter: enter && enter.className ? enter.className : componentName + '-enter',
    enterActive:
      enterActive && enterActive.className
        ? enterActive.className
        : componentName + '-enter-active',
    leave: leave && leave.className ? leave.className : componentName + '-leave',
    leaveActive:
      leaveActive && leaveActive.className
        ? leaveActive.className
        : componentName + '-leave-active',
    appear: appear && appear.className ? appear.className : componentName + '-appear',
    appearActive:
      appearActive && appearActive.className
        ? appearActive.className
        : componentName + '-appear-active'
  };
}
