/**
 * Component for displaying tags as a pill
 */

import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import PropTypes from 'prop-types';

import { tagsPropType } from '../propTypes';

const Pill = (props: $TSFixMe) => {
  const { tags, numberOfTagsToDisplay } = props;
  const tagStyles = resolve(props, 'tag', 'tagPill');
  let tagList = numberOfTagsToDisplay ? tags.slice(0, numberOfTagsToDisplay) : tags;

  return (
    
    <div {...resolve(props, 'tagContainer')}>
      {tagList.map((tag: $TSFixMe, i: $TSFixMe) => {
        const style = Object.assign({}, tagStyles.style, {
          backgroundColor: tag.backgroundColor,
          borderColor: tag.backgroundColor,
          color: tag.textColor
        });
        return (
          
          <span key={i} className={tagStyles.className} style={style} title={tag.displayName}>
            {tag.displayName}
          </span>
        );
      })}
    </div>
  );
};

Pill.propTypes = {
  tags: tagsPropType,
  classes: PropTypes.object,
  style: PropTypes.object,
  numberOfTagsToDisplay: PropTypes.number
};

Pill.displayName = 'Pill';

export default Pill;
