import React from 'react';
import Classes from './Classes.less';

type Props = {
  sectionStyle?: any;
  children?: React.ReactNode;
};

/**
 * Minimal renderer for a read only section, just applies the
 * given section style and built in class.
 */
export const SectionContainer = (props: Props) => {
  const customClass = props?.sectionStyle?.customClass || '';
  return (
    <div className={`${Classes.sectionContainer} ${customClass}`} style={props.sectionStyle}>
      {props.children}
    </div>
  );
};
SectionContainer.displayName = 'SectionContainer';
