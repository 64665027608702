import React from 'react';

import noop from 'lodash/noop';
import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import { ImageGallery } from 'nucleus-core';

// todo: remove. Using some nucleus core styles for now as placeholder
import ImageGalleryStyle from 'nucleus-core/less/cv/ImageGallery.less';
import imagePlaceholder from '../../../resources/images/imagePlaceholder.svg';
import { renderRichText } from '../../utils/renderRichText';
import ImageCarouselStyle from './ImageCarousel.less';

type OwnProps = {
  // Used in resolve function // TODO: determine neccesary fields for classes
  classes?: Record<string, unknown>;
  // Used in resolve function
  style?: Record<string, unknown>;
  activeImageIndexId?: string;
  autoplay?: boolean;
  breakpoints?: number[];
  transitionTime?: number;
  showForwardAndBackArrows?: boolean;
  showNavigationDots?: boolean;
  showThumbnails?: boolean;
  translate: (...args: any[]) => any;
  colorPalette?: any;
  displayPlaceholderText?: boolean;
  supportsWebp?: boolean;
  images: Array<{
    id?: string;
    description?: string;
    altText?: string;
    asset: {
      assetUri: string;
      width: number;
      thumbnailImageUri?: string;
    };
    assetUri?: string;
    name?: string;
    extension?: string;
    size?: number;
    width?: number;
    imageConfig: {
      decorativeImage?: boolean;
    };
    externalLinkUrl?: string;
    baseBlockType?: any; // TODO: PropTypes.oneOf(Object.values(BlockTypes))
  }>;
  supportLossy?: boolean;
};

type Props = OwnProps & typeof ImageCarousel.defaultProps;

/**
 * The Image carousel widget.
 */
export class ImageCarousel extends React.Component<Props> {
  static displayName = 'ImageCarousel';
  static defaultProps = {
    displayPlaceholderText: false
  };

  imageGallery: any;

  componentDidMount() {
    const { images } = this.props;
    if (this.imageGallery && images) {
      const imageIndexToBeDisplayed = this.getImageIndexToBeDisplayed();
      this.imageGallery.slideToIndex(imageIndexToBeDisplayed);
    }
  }
  componentDidUpdate() {
    const { images } = this.props;
    if (this.imageGallery && images) {
      const imageIndexToBeDisplayed = this.getImageIndexToBeDisplayed();
      this.imageGallery.slideToIndex(imageIndexToBeDisplayed);
    }
  }
  getImageIndexToBeDisplayed() {
    const { images, activeImageIndexId } = this.props;
    if (images && activeImageIndexId) {
      const imageIndex = images.findIndex(image => image.id === activeImageIndexId);
      return imageIndex === -1 ? 0 : imageIndex;
    }
    return 0;
  }
  render() {
    const {
      autoplay,
      breakpoints,
      transitionTime,
      showNavigationDots,
      showThumbnails,
      showForwardAndBackArrows,
      style,
      colorPalette,
      displayPlaceholderText,
      supportsWebp,
      supportLossy,
      translate
    } = this.props;
    const items = this.props?.images
      .filter(image => !!image.asset.assetUri)
      .map(image => {
        const description = renderRichText(
          image.description,
          translate,
          colorPalette,
          style?.descriptionBottom,
          { baseBlockType: image.baseBlockType }
        );
        return {
          original: image.asset.assetUri,
          width: image.asset.width,
          thumbnail: image.asset.thumbnailImageUri || image.asset.assetUri,
          description,
          altText: image.imageConfig?.decorativeImage ? null : image.altText,
          hyperlink: image.externalLinkUrl
        };
      });
    const isEmptyImageList = items.length === 0;
    if (isEmptyImageList && displayPlaceholderText) {
      items.push(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
        <div>
          <div className={ImageCarouselStyle.placeholder}>
            <img src={imagePlaceholder} className={ImageCarouselStyle.placeholderImage} />
            <p className={ImageCarouselStyle.placeholderText}>
              {translate('NucleusWidgets_ImageCarousel_Empty__resx')}
            </p>
          </div>
        </div>
      );
    } else if (isEmptyImageList) {
      items.push(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'Element' is not assignable to pa... Remove this comment to see the full error message
        <div>
          <div className={ImageCarouselStyle.placeholder}>
            <img src={imagePlaceholder} className={ImageCarouselStyle.placeholderImage} />
          </div>
        </div>
      );
    }
    const transitionTimeInMilliseconds = transitionTime ? transitionTime * 1000 : undefined;
    return (
      <div
        {...resolveTestId(this.props)}
        {...resolve(this.props, 'container')}
        aria-roledescription="carousel"
        role="region"
      >
        <ImageGallery
          classes={ImageGalleryStyle}
          style={style}
          // @ts-expect-error ts-migrate()
          items={items}
          breakpoints={breakpoints}
          autoPlay={autoplay}
          showIndex={false}
          supportsWebp={supportsWebp}
          supportLossy={supportLossy}
          slideOnThumbnailHover={false}
          lazyLoad={false}
          showBullets={!isEmptyImageList && showNavigationDots}
          infinite
          showThumbnails={!isEmptyImageList && showThumbnails}
          showNav={!isEmptyImageList && showForwardAndBackArrows}
          showDescriptionOnTop={false}
          overlayDescriptionOnImage={false}
          overlayNavigationDots={false}
          slideInterval={transitionTimeInMilliseconds}
          fullscreen={false}
          onImageLoad={noop}
          colorPalette={colorPalette}
          renderItem={
            isEmptyImageList
              ? (item: any) => {
                  return item;
                }
              : undefined
          }
          ref={(c: any) => (this.imageGallery = c)}
          translate={translate}
        />
      </div>
    );
  }
}
