import React, { useContext } from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { removeKeys } from '../../utils/removeKeys';
import { GridContext } from './Grid';

type Props = React.PropsWithChildren<{
  breakPoint?: 'large' | 'medium' | 'small';
  modifiers?: any[];
  classes?: any;
  style?: Record<string, any>;
  ariaRole?: string;
}>;

/**
 * Row component for the flexbox grid layout.
 * modifiers TBD - Flexbox properties added via CSS Modules.
 */
export function FlexRow(props: Props) {
  const { classes, style, breakPoint, children, modifiers = [], ariaRole } = props;
  const grid = useContext(GridContext);

  const resolvedStyleObject = {
    classes: classes || grid.classes,
    style: style || grid.style
  };
  const resolvedBreakPoint = breakPoint || grid.breakPoint;
  return (
    <div
      {...removeKeys(props, [
        'classes',
        'style',
        'breakPoint',
        'children',
        'modifiers',
        'ariaRole'
      ])}
      {...resolve(resolvedStyleObject, 'row', resolvedBreakPoint, ...modifiers)}
      {...(ariaRole !== undefined && { role: ariaRole })}
    >
      {children}
    </div>
  );
}

FlexRow.displayName = 'Row';
