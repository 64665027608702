import Logger from '@cvent/nucleus-logging';
const LOG = new Logger('nucleus-widgets/clients/QuestionLookupClient');
import { FetchClient } from '@cvent/fetch';
import { AllQuestions } from '../types/questions';
import omit from 'lodash/omit';

type ExtendedQuestionType = Partial<AllQuestions> & { [key: string]: any };

type onSaveQuestion = (questionTypeInfo: AllQuestions) => ExtendedQuestionType;

export type QuestionLookupClient = {
  baseUrl: string;
  environment: string;
  authorizationToken: string;
  accountId?: string;
  onSaveQuestion?: onSaveQuestion;
};
/**
 * API client for communicating with image lookup service.
 */
export class QuestionLibraryClient {
  environment: string;
  baseUrl: string;
  accountId?: string;
  onSaveQuestion?: onSaveQuestion;
  fetchClient: FetchClient;
  constructor({
    baseUrl,
    environment,
    authorizationToken,
    accountId,
    onSaveQuestion
  }: QuestionLookupClient) {
    this.baseUrl = baseUrl;
    this.environment = environment;
    this.accountId = accountId;
    this.onSaveQuestion = onSaveQuestion;

    const headers: { [key: string]: string } = {
      Authorization: authorizationToken,
      'Content-Type': 'application/json'
    };

    if (accountId) {
      headers['Account-Id'] = accountId;
    }

    this.fetchClient = new FetchClient({
      init: {
        headers
      }
    });
  }

  fetchQuestionFolders = async () => {
    if (this.baseUrl) {
      const newUrl = new URL(`${this.baseUrl}/folders`);
      newUrl.searchParams.append('environment', this.environment);

      try {
        const res = await this.fetchClient.get(newUrl.toString());
        return await res.json();
      } catch (e) {
        LOG.error('Error fetching QL folder', e);
        throw e;
      }
    }
  };

  fetchQuestions = async (folderId: string) => {
    if (this.baseUrl) {
      const newUrl = new URL(`${this.baseUrl}/folders/${folderId}/questions`);
      newUrl.searchParams.append('environment', this.environment);

      try {
        const res = await this.fetchClient.get(newUrl.toString());
        return await res.json();
      } catch (e) {
        LOG.error('Error fetch QL question', e);
        throw e;
      }
    }
  };
  async postCreateFolder(id: string, folderName: string) {
    const payLoad = {
      id,
      name: folderName
    };
    const newUrl = new URL(`${this.baseUrl}/folders`);
    newUrl.searchParams.append('environment', this.environment);

    try {
      const res = await this.fetchClient.post(newUrl.toString(), JSON.stringify(payLoad));
      if (res.status !== 201) {
        throw new Error(`Create folder failed. Status: ${res.status}.`);
      }

      return await res.json();
    } catch (error) {
      LOG.error('Error creating the folder', error);
      throw error;
    }
  }

  async postQuestionToFolder(folderId: string, questionTypeInfo: AllQuestions) {
    let customizedQuestionTypeInfo;
    if (this.onSaveQuestion) {
      customizedQuestionTypeInfo = omit(
        { ...questionTypeInfo, ...(this.onSaveQuestion && this.onSaveQuestion(questionTypeInfo)) },
        'questionData'
      );
    }

    const newUrl = new URL(`${this.baseUrl}/folders/${folderId}/questions`);
    newUrl.searchParams.append('environment', this.environment);

    try {
      const response = await this.fetchClient.post(
        newUrl.toString(),
        JSON.stringify(customizedQuestionTypeInfo)
      );

      if (response.status !== 201) {
        if (response.status === 409) {
          const errorData = await response.json();
          const errorObj = { ...errorData.error, status: 409 };
          throw errorObj;
        }
        throw new Error(`Add a question to a folder failed. Status: ${response.status}.`);
      }
      return await response.json();
    } catch (error) {
      LOG.error('Error adding a question to the folder', error);
      throw error;
    }
  }

  fetchSearchQuestions = async (filter: string, limit: number) => {
    if (this.baseUrl) {
      const newUrl = new URL(`${this.baseUrl}/questions`);
      newUrl.searchParams.append('environment', this.environment);
      newUrl.searchParams.append('filter', filter);
      newUrl.searchParams.append('limit', limit.toString());

      try {
        const res = await this.fetchClient.get(newUrl.toString());
        if (!res.ok) {
          throw res;
        }
        return await res.json();
      } catch (e) {
        LOG.error('Error fetching questions for search', e);
        throw e;
      }
    }
  };
}
