/*
 * common types used across different files
 */

// todo: enable flow check

import type { SelectedLocation } from 'cvent-event-calendar/lib/CalendarView/types';
// @ts-expect-error TS(2305): Module '"nucleus-core"' has no exported member 'Fi... Remove this comment to see the full error message
import { Filter } from 'nucleus-core';

export type Action = {
  type: string;
  payload?: Object;
};

export type Dispatch = (action: Action) => void;

export type Location = SelectedLocation & {
  radius: number;
};

export type SelectedFilter = {
  field: string;
  values: Array<string> | Location;
  type: string;
};

export type Criteria = {
  keyword: string;
  fromDate?: Date;
  toDate?: Date;
  currentMonth?: Date;
  filters: {
    // @ts-expect-error TS(1170): A computed property name in a type literal must re... Remove this comment to see the full error message
    [string]: SelectedFilter;
  };
};

export type State = any;

export type CalendarFilters = {
  filters: Array<Filter>;
  showFiltersDialog?: boolean;
};

export type GetState = () => State;
