import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

type Props = {
  label?: string;
  digit: number;
  style?: {
    text?: any;
    digit?: any;
    label?: any;
  };
};
/**
# TextCounter Component
**/
const TextCounter = (props: Props) => {
  const { label, digit } = props;
  return (
    <span {...resolveTestId(props)} {...resolve(props, 'text')}>
      <span {...resolve(props, 'digit')}>{digit}</span>
      <span {...resolve(props, 'label')}>{label}</span>
    </span>
  );
};

TextCounter.displayName = 'TextCounter';

export { TextCounter };
