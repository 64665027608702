import { SET_FOLDER_QUESTIONS } from './actions';
import { AllQuestions } from '../../../types/questions';
import { getTempId } from '../../../utils';
import { setFolderQuestions } from './setFolderQuestions';

export function questionIntoFolder(questionInfo: AllQuestions, folderId: string) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const client = state.clients.questionLibraryClient;
    const hasExistingData = state.questionLibrary.folders[folderId].questions.length;
    const text =
      questionInfo.html.content?.blocks.map((block: any) => block.text).join() || questionInfo.html;
    const questionTypeInfo =
      questionInfo.questionTypeInfo.questionType === 'FileUpload'
        ? {
            ...questionInfo.questionTypeInfo,
            categories: [{ allowedFileFormats: questionInfo.questionTypeInfo.allowedFileFormats }]
          }
        : questionInfo.questionTypeInfo;
    const hasError = true;
    const newQuestionInfo = {
      id: getTempId(),
      questionTypeInfo,
      text,
      code: questionInfo.code,
      note: questionInfo.note,
      html: JSON.stringify(questionInfo.html),
      required: questionInfo.required,
      additionalInfo: questionInfo.additionalInfo,
      questionTag:
        Object.keys(questionInfo?.questionTag ?? {}).length === 0
          ? null
          : questionInfo?.questionTag,
      // Removed before backend call
      questionData: questionInfo
    };

    try {
      if (!hasExistingData) {
        const res = await client.fetchQuestions(folderId);
        dispatch({ type: SET_FOLDER_QUESTIONS, payload: { folderId, questionArray: res.data } });
      }
      await client.postQuestionToFolder(folderId, newQuestionInfo);
      await dispatch(setFolderQuestions(folderId, false));
      return { hasError: !hasError, errMessageResx: '' };
    } catch (err: any) {
      if (err.status === 409) {
        const response = await err.response?.json();
        return { hasError, errMessageResx: response?.error.errorKey || '' };
      }
      return { hasError, errMessageResx: '' };
    }
  };
}
