import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';

import { SelectFormElement as Select } from '../forms/elements/Select';

type OwnProps = {
  months?: any[];
  date?: any; // TODO: PropTypes.instanceOf(Date)
  localeUtils?: {
    getMonths?: (...args: any[]) => any;
  };
  locale?: string;
  range?: number;
  minRange?: number;
  maxRange?: number;
  onChange?: (...args: any[]) => any;
  fromMonth?: any; // TODO: PropTypes.instanceOf(Date)
  toMonth?: any; // TODO: PropTypes.instanceOf(Date)
  changeYearLabel?: string;
  changeMonthLabel?: string;
  yearFirst?: boolean;
};

type Props = OwnProps & typeof MonthYearDropdown.defaultProps;

/**
  Display two dropdowns to change month and year
*/

class MonthYearDropdown extends React.Component<Props> {
  static defaultProps = {
    range: 10
  };

  constructor(props: Props) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  onChange(fieldName: any, newValue: any) {
    if (this.props.onChange) {
      if (fieldName === 'monthOptions') {
        this.props.onChange(new Date(this.props.date.getFullYear(), newValue));
      } else if (fieldName === 'yearOptions') {
        const selectedYear = newValue;
        // Update month to keep calendar within min/max date range
        let month = this.props.date.getMonth();
        const { fromMonth, toMonth } = this.props;
        if (fromMonth && fromMonth.getFullYear() === selectedYear && month < fromMonth.getMonth()) {
          month = fromMonth.getMonth();
        } else if (
          toMonth &&
          toMonth.getFullYear() === selectedYear &&
          month > toMonth.getMonth()
        ) {
          month = toMonth.getMonth();
        }
        this.props.onChange(new Date(newValue, month));
      }
    }
  }

  render() {
    const {
      date,
      localeUtils,
      fromMonth,
      toMonth,
      range,
      minRange,
      maxRange,
      months,
      locale,
      changeYearLabel,
      changeMonthLabel,
      yearFirst
    } = this.props;
    const selectedYear = date.getFullYear();
    const selectedMonth = date.getMonth();
    // Create an array of different month options.
    const monthOptions = [];
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const monthLabels = months || localeUtils.getMonths(locale);
    let monthStart =
      fromMonth && fromMonth.getFullYear() === selectedYear ? fromMonth.getMonth() : 0;
    let monthEnd = toMonth && toMonth.getFullYear() === selectedYear ? toMonth.getMonth() : 11;
    if (selectedMonth !== undefined && selectedMonth < monthStart) {
      monthStart = selectedMonth;
    }
    if (selectedMonth !== undefined && selectedMonth > monthEnd) {
      monthEnd = selectedMonth;
    }
    for (let i = monthStart; i <= monthEnd; i++) {
      monthOptions.push({ name: monthLabels[i], value: i });
    }

    // Creates an array of different year options.
    const years = [];
    const curYear = new Date().getFullYear();
    const minYear = fromMonth ? fromMonth.getFullYear() : curYear - (minRange || range);
    const maxYear = toMonth ? toMonth.getFullYear() : curYear + (maxRange || range);
    for (let i = minYear; i <= maxYear; i++) {
      years.push({ name: i, value: i });
    }
    // adds an option in the year dropdown if the selected year is out of the range already provided
    if (selectedYear < minYear || selectedYear > maxYear) {
      years.push({ name: selectedYear, value: selectedYear });
    }

    const classes = {
      element: resolve(this.props, 'captionDropdownWrapper').className,
      select: resolve(this.props, 'captionDropdown').className,
      label: {
        hidden: resolve(this.props, 'hiddenLabel').className
      }
    };
    const monthDropdown = (
      <Select
        key="monthOptions"
        label={changeMonthLabel}
        hideLabel
        classes={classes}
        fieldName="monthOptions"
        options={{
          selectedValue: date.getMonth(),
          optionArray: monthOptions
        }}
        onChange={this.onChange}
        tabIndex={-1}
      />
    );
    const yearDropdown = (
      <Select
        key="yearOptions"
        label={changeYearLabel}
        classes={classes}
        hideLabel
        fieldName="yearOptions"
        options={{
          selectedValue: selectedYear,
          optionArray: years
        }}
        onChange={this.onChange}
        tabIndex={-1}
      />
    );
    return (
      <div {...resolve(this.props, 'caption', 'dropdownContainer')}>
        {yearFirst ? [yearDropdown, monthDropdown] : [monthDropdown, yearDropdown]}
      </div>
    );
  }
}

export { MonthYearDropdown };
