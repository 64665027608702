import {
  convertToPseudoClasses,
  getBackground,
  getBorder,
  getInlineStyle,
  getJSONValue,
  getSpacing,
  getText
} from '../utils';

export const getStyleObject = (
  style: any,
  themeImages: any,
  disableInput: any,
  imageLookup: any,
  supportsWebp: any
): any => {
  return {
    container: {
      ...getInlineStyle(
        style,
        style.customSettings,
        style.palette,
        style.fontPalette,
        themeImages,
        {
          useText: false,
          imageLookup,
          supportsWebp
        }
      ),
      flex: '1 1 auto',
      pointerEvents: disableInput ? 'none' : undefined
    }
  };
};

export const getElementInlineStyle = (
  key: any,
  style: any,
  themeImages: any,
  pseudoState: any,
  isSelectedItem: any,
  currentElementKey: any,
  imageLookup: any,
  supportsWebp: any
) => {
  const styles = getJSONValue(style, key);

  if (!styles) {
    return {};
  }

  if (styles.styleMapping === 'custom') {
    return convertToPseudoClasses({
      styles: getInlineStyle(
        styles,
        styles.customSettings,
        style.palette,
        style.fontPalette,
        themeImages,
        {
          customFonts: style.customFonts,
          imageLookup,
          supportsWebp
        }
      ),
      styleMapping: styles.styleMapping,
      isSelectedItem,
      pseudoState,
      key,
      currentElementKey
    });
  }
  return convertToPseudoClasses({
    styles: getInlineStyle(
      style.elements[styles.styleMapping],
      styles.customSettings,
      style.palette,
      style.fontPalette,
      themeImages,
      {
        customFonts: style.customFonts,
        imageLookup,
        supportsWebp
      }
    ),
    styleMapping: styles.styleMapping,
    isSelectedItem,
    pseudoState,
    key,
    currentElementKey
  });
};

export const getElementBorder = (
  key: any,
  style: any,
  pseudoState: any,
  isSelectedItem: any,
  currentElementKey: any
) => {
  const styles = getJSONValue(style, key);
  if (!styles) {
    return {};
  }
  return styles.styleMapping === 'custom'
    ? convertToPseudoClasses({
        styles: getBorder(styles, styles.customSettings, style.palette),
        styleMapping: styles.styleMapping,
        isSelectedItem,
        pseudoState,
        key,
        currentElementKey
      })
    : convertToPseudoClasses({
        styles: getBorder(
          style.elements[styles.styleMapping],
          styles.customSettings,
          style.palette
        ),
        styleMapping: styles.styleMapping,
        isSelectedItem,
        pseudoState,
        key,
        currentElementKey
      });
};

export const getElementBackground = (
  key: any,
  style: any,
  themeImages: any,
  pseudoState: any,
  isSelectedItem: any,
  currentElementKey: any,
  imageLookup: any,
  supportsWebp: any
) => {
  const styles = getJSONValue(style, key);
  if (!styles) {
    return {};
  }
  return styles.styleMapping === 'custom'
    ? convertToPseudoClasses({
        styles: getBackground(
          styles,
          styles.customSettings,
          style.palette,
          themeImages,
          imageLookup,
          supportsWebp
        ),
        styleMapping: styles.styleMapping,
        isSelectedItem,
        pseudoState,
        key,
        currentElementKey
      })
    : convertToPseudoClasses({
        styles: getBackground(
          style.elements[styles.styleMapping],
          styles.customSettings,
          style.palette,
          themeImages,
          imageLookup,
          supportsWebp
        ),
        styleMapping: styles.styleMapping,
        isSelectedItem,
        pseudoState,
        key,
        currentElementKey
      });
};

export const getParentBackground = (
  style: any,
  themeImages: any,
  imageLookup: any,
  supportsWebp: any
) => {
  return getBackground(
    { background: style.background.parentBackground },
    {},
    style.palette,
    themeImages,
    imageLookup,
    supportsWebp
  );
};

export const getElementSpacing = (key: any, style: any) => {
  const styles = getJSONValue(style, key);
  if (!styles) {
    return {};
  }
  return styles.styleMapping === 'custom'
    ? getSpacing(styles, styles.customSettings)
    : getSpacing(style.elements[styles.styleMapping], styles.customSettings);
};

export const getElementText = (
  key: any,
  style: any,
  pseudoState: any,
  isSelectedItem: any,
  currentElementKey: any
) => {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 5.
  const styles = getJSONValue(style, key, pseudoState, isSelectedItem, currentElementKey);
  if (!styles) {
    return {};
  }
  return styles.styleMapping === 'custom'
    ? convertToPseudoClasses({
        styles: getText(
          styles,
          styles.customSettings,
          style.palette,
          style.fontPalette,
          style.customFonts
        ),
        styleMapping: styles.styleMapping,
        isSelectedItem,
        pseudoState,
        key,
        currentElementKey
      })
    : convertToPseudoClasses({
        styles: getText(
          style.elements[styles.styleMapping],
          styles.customSettings,
          style.palette,
          style.fontPalette,
          style.customFonts
        ),
        styleMapping: styles.styleMapping,
        isSelectedItem,
        pseudoState,
        key,
        currentElementKey
      });
};
