import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

import { Textbox } from '../forms/elements/Textbox';
import { tapOrClick } from '../touchEventHandlers';
import { rgbToHexString, hslColorEqual, hexStringToRgb, hslToRgb, rgbToHsl } from './conversions';

type OwnProps = {
  hsl: any;
  isBlank?: boolean;
  inFlyout?: boolean;
  fieldName?: string;
  hexLabel?: string;
  onColorChange?: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;
  onBlur?: (...args: any[]) => any;
  onFocus?: (...args: any[]) => any;
  style?: {
    inputContainer?: any;
    textbox?: any;
    selectedColor?: any;
  };
  classes?: any;
};

type State = any;

type Props = OwnProps & typeof HexColorInput.defaultProps;

/** Control to select a hsl color via an inputed hex value
    provides an on onClick callback for composition with color picker **/
class HexColorInput extends React.Component<Props, State> {
  static displayName = 'HexColorInput';
  static defaultProps = {
    hsl: { h: 0, s: 0, l: 0 },
    isBlank: false,
    inFlyout: false,
    hexLabel: 'HexInput'
  };

  constructor(props: any, context: any) {
    super(props, context);
    this.state = {
      hex: rgbToHexString(hslToRgb(props.hsl)),
      isBlank: this.props.isBlank
    };
    this.onTextChange = this.onTextChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.focusTextbox = this.focusTextbox.bind(this);
  }

  /** only update when the hsl provided triggers a new hex color code **/
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { hsl, isBlank } = nextProps;
    this.setState({
      hex: rgbToHexString(hslToRgb(hsl)),
      isBlank
    });
  }

  // reset input box if user tabs or focus's away to color of record.
  onBlur(e: any) {
    this.setState({ hex: rgbToHexString(hslToRgb(this.props.hsl)) });
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  /** on text change, if valid hex code and new color, call onColorChange **/
  onTextChange(fieldName: any, value: any) {
    let hex = value;
    if (!hex.startsWith('#')) {
      hex = '#' + hex;
    }

    const newRgb = hexStringToRgb(hex);
    this.setState({
      hex,
      isBlank: false
    });
    if (newRgb.error) {
      return;
    }

    const newHsl = rgbToHsl(newRgb);
    if (!hslColorEqual(newHsl, this.props.hsl) || this.props.isBlank) {
      if (this.props.onColorChange) {
        this.props.onColorChange(newHsl);
      }
    }
  }

  focusTextbox() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'textbox' does not exist on type 'ReactIn... Remove this comment to see the full error message
    this.refs.textbox.textbox.focus();
  }

  render() {
    const { hsl, fieldName, onClick, onFocus, classes, hexLabel, inFlyout } = this.props;
    const { isBlank } = this.state;
    const rgb = hslToRgb(hsl);
    const hex = rgbToHexString(rgb);
    const label = (
      <label htmlFor={fieldName || 'color'} {...resolve(this.props, 'hidden')}>
        {hexLabel}
      </label>
    );
    return (
      <div {...resolveTestId(this.props)}>
        {label}
        <Textbox
          {...resolveTestId(this.props, '-textbox')}
          ref="textbox"
          fieldName={fieldName || 'color'}
          onChange={this.onTextChange}
          onBlur={this.onBlur}
          onFocus={onFocus}
          value={isBlank ? '#' : this.state.hex}
          classes={classes}
          {...tapOrClick(this.focusTextbox)}
          {...resolve(this.props, 'textbox', 'hexInput', inFlyout ? 'textboxInFlyout' : null)}
        />
        <div
          title="hex-color"
          tabIndex="0"
          onFocus={onFocus}
          {...resolve(this.props, 'selectedColor', isBlank ? 'empty' : null)}
          style={{ background: isBlank ? '' : hex }}
          {...tapOrClick(onClick)}
        />
      </div>
    );
  }
}

export { HexColorInput };
