import React from 'react';

import noop from 'lodash/noop';

import { Dialog } from 'nucleus-core';
import { LoadingIndicator } from './LoadingIndicator';
import LoadingProgressIndicatorStyles from './LoadingProgressIndicator.less';

type OwnProps = {
  classes?: any;
  loadingMessages?: any;
  loading: number;
  popupConfig?: any;
  dialogStyles?: any;
  colorPalette?: any;
  delayRenderTime?: number;
  delayHideRenderTime?: number;
};

type State = any;

type Props = OwnProps & typeof LoadingProgressIndicator.defaultProps;

/**
 * A basic loading progress indicator dialog with progress spinner that can be wrapped by any
 * application specific implementation to show the loading indicator by sending isPending state
 * and populate the messages around the loader if any and add styling for the dialog.
 */
export class LoadingProgressIndicator extends React.Component<Props, State> {
  static displayName = 'LoadingProgressIndicator';
  static defaultProps = {
    classes: LoadingProgressIndicatorStyles,
    delayRenderTime: 500,
    delayHideRenderTime: 1000,
    popupConfig: {
      requestClose: noop,
      dialogId: 'LoadingProgressIndicatorDialog',
      isModal: true,
      isAnimatable: false,
      header: <div />
    }
  };
  _delayHideRenderTimer: any;
  _delayRenderTimer: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      delayHide: false
    };
    this._delayRenderTimer = null;
    this._delayHideRenderTimer = null;
    this.setDelayRender = this.setDelayRender.bind(this);
    this.setDelayHideRender = this.setDelayHideRender.bind(this);
    this.removeDelayRender = this.removeDelayRender.bind(this);
  }
  setDelayRender(delayRenderTime: any, delayHideRenderTime: any) {
    this._delayRenderTimer = setTimeout(() => {
      this.setState({ visible: true, delayHide: true });
      this.setDelayHideRender(delayHideRenderTime);
    }, delayRenderTime);
  }
  removeDelayRender() {
    clearTimeout(this._delayRenderTimer);
    this._delayRenderTimer = null;
    this.setState({ visible: false });
  }
  setDelayHideRender(delayHideRenderTime: any) {
    this._delayHideRenderTimer = setTimeout(() => {
      this.setState({ delayHide: false });
    }, delayHideRenderTime);
  }
  componentDidMount() {
    // Add a delay before showing progress indicator dialog so that it
    // does not show a flicker in the screen
    if (this.props.loading > 0) {
      this.setDelayRender(this.props.delayRenderTime, this.props.delayHideRenderTime);
    }
  }
  componentDidUpdate(prevProps: Props) {
    if (this.props.loading > 0 && prevProps.loading === 0) {
      this.setDelayRender(this.props.delayRenderTime, this.props.delayHideRenderTime);
    } else if (this.props.loading === 0 && prevProps.loading > 0) {
      this.removeDelayRender();
    }
  }
  componentWillUnmount() {
    clearTimeout(this._delayRenderTimer);
    clearTimeout(this._delayHideRenderTimer);
  }
  render() {
    const {
      loading,
      popupConfig,
      loadingMessages,
      dialogStyles: { base },
      colorPalette = {},
      classes
    } = this.props;
    const isLoading = loading > 0;
    const showLoading = (this.state.visible && isLoading) || this.state.delayHide;

    return (
      <Dialog
        isOpen={showLoading}
        {...popupConfig}
        classes={{
          ...base,
          ...(base.loadingDialogOverlay ? { overlay: base.loadingDialogOverlay } : {}),
          ...(base.loadingDialogDragContainer
            ? { dragContainer: base.loadingDialogDragContainer }
            : {})
        }}
      >
        <LoadingIndicator
          spinnerMessage={loadingMessages && loadingMessages.spinnerMessage}
          message={loadingMessages && loadingMessages.message}
          classes={classes}
          colorPalette={colorPalette}
        />
      </Dialog>
    );
  }
}
