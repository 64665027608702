import uniqueId from 'lodash/uniqueId';
import { Dispatch } from 'redux';
import { getAllWidgetsOnPage, getParentPage } from '../../../utils';
import {
  DELETE_ALL_PAGE_COMMENTS,
  DELETE_ALL_SECTION_COMMENTS,
  DELETE_ALL_WIDGET_COMMENTS,
  DELETE_COMMENT,
  COMMENT_DELETE_ERROR
} from './actions';
import {
  COMMENT_ERRORS,
  getCommentError,
  removeCommentError,
  setCommentErrorLoading
} from './errors';
import { getAllDeletedCommentWidgetIds } from './utils';

export function deleteAllWidgetComments(widgetId: string, pageId: string) {
  return { type: DELETE_ALL_WIDGET_COMMENTS, payload: { widgetId, pageId } };
}

export function deleteAllSectionComments(widgetIds: string[], pageId: string) {
  return { type: DELETE_ALL_SECTION_COMMENTS, payload: { widgetIds, pageId } };
}

export function deleteAllPageComments(pageId: string) {
  return (dispatch: Dispatch, getState: any) => {
    const website = getState().website;
    const widgetIds = getAllWidgetsOnPage(website, pageId).map((widget: any) => widget.id);

    dispatch({ type: DELETE_ALL_PAGE_COMMENTS, payload: { widgetIds, pageId } });
  };
}

function setDeleteCommentError(widgetId: string, commentId: string, isAlert = true) {
  const type = COMMENT_ERRORS.Delete;
  const data = { widgetId, commentId };

  return {
    type: COMMENT_DELETE_ERROR,
    payload: { id: uniqueId(), retrying: false, isAlert, type, data }
  };
}

function deleteAComment(widgetId: string, commentId: string, pageId?: string) {
  return async (dispatch: Dispatch<any>, getState: any) => {
    const client = getState().clients.commentsClient;

    try {
      await client.deleteComment(commentId);

      dispatch({ type: DELETE_COMMENT, payload: { widgetId, commentId, pageId } });

      return true;
    } catch (err) {
      return false;
    }
  };
}

export function deleteComment(widgetId: string, commentId: string) {
  return async (dispatch: Dispatch<any>, getState: any) => {
    const state = getState();
    const comments = state.comments;
    const isGuestSide = !!state.pathInfo?.currentPageId;

    const error = getCommentError(comments.errors, COMMENT_ERRORS.Delete, {
      widgetId,
      commentId
    });

    if (error) {
      dispatch(removeCommentError(error.id));
    }

    let pageId;
    let success;
    if (!isGuestSide) {
      pageId = getParentPage(state, state.website.layoutItems[widgetId]).id;
      success = await dispatch(deleteAComment(widgetId, commentId, pageId));
    } else {
      success = await dispatch(deleteAComment(widgetId, commentId));
    }

    if (!success) {
      dispatch(setDeleteCommentError(widgetId, commentId));
    }
  };
}

export function retryDeleteComment(widgetId: string, commentId: string) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const comments = state.comments;
    const pageId = getParentPage(state, state.website.layoutItems[widgetId]).id;

    const error = getCommentError(comments.errors, COMMENT_ERRORS.Delete, {
      widgetId,
      commentId
    });

    dispatch(setCommentErrorLoading(error.id, true));
    const success = await dispatch(deleteAComment(widgetId, commentId, pageId));

    if (success) {
      dispatch(removeCommentError(error.id));
    } else {
      dispatch(setCommentErrorLoading(error.id, false));
    }
  };
}

// This is a fire and forget method that's why we are not waiting for it to finish
// To not slow down the publish
export function deleteAllDeletedWidgetsComments() {
  return (dispatch: Dispatch<any>, getState: any) => {
    const state = getState();
    const client = state.clients.commentsClient;
    const comments = state.comments.comments || {};

    const allWidgetIds = getAllDeletedCommentWidgetIds(comments, state);

    if (allWidgetIds.length) {
      client.deleteAllCommentsOnWidgets(allWidgetIds);
      dispatch({ type: null });
    }
  };
}
