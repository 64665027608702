import React from 'react';

import DatatagRegex from '@cvent/nucleus-datatag-regex';
import { resolve } from '@cvent/nucleus-dynamic-css';
import ResponsiveImage from '@cvent/nucleus-image';

type Props = React.PropsWithChildren<{
  original?: string;
  originalAlt?: string;
  altText?: string;
  srcSet?: string;
  sizes?: string;
  supportsWebp?: boolean;
  width?: number;
  breakpoints?: number[];
  orientation?: 'portrait' | 'landscape';
  onLoad?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
  description?: string;
  placeHolderImageUrl?: React.ReactNode;
  itemId?: string;
  hyperlink?: string;
  preloadComponent?: React.ReactNode;
  showDescriptionOnTop?: boolean;
  overlayDescriptionOnImage?: boolean;
  style?: {
    item?: any;
    description?: any;
  };
}>;

type State = any;

/**
  Item for Image Gallery / Carousel
  Can be either an Image or Component
*/
export class Item extends React.Component<Props, State> {
  static displayName = 'Item';

  imageContainer: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoaded: false
    };
    this.onLoad = this.onLoad.bind(this);
    this.onError = this.onError.bind(this);
  }
  onLoad(e: any) {
    if (this.props.onLoad) {
      this.props.onLoad(e);
    }
    this.setState({
      isLoaded: true
    });
  }
  onError() {
    if (this.props.onError) {
      this.props.onError();
    }
  }
  addProtocol(url: any) {
    if (!url || !(typeof url === 'string' || url instanceof String)) {
      return undefined;
    }

    const trimmedUrl = url.trim();
    if (!trimmedUrl) {
      return undefined;
    }

    // Check if the url is a datatag. Datatags should already fully resolve with a protocol.
    const regex = DatatagRegex;
    regex.lastIndex = 0;
    const match = regex.exec(trimmedUrl);
    if (match !== null && trimmedUrl.length === match[0].length) {
      return trimmedUrl;
    }

    // Allow mailto links.
    if (trimmedUrl.startsWith('mailto:')) {
      return trimmedUrl;
    }

    // Allow http or https web links.
    if (trimmedUrl.startsWith('http://') || trimmedUrl.startsWith('https://')) {
      return trimmedUrl;
    }

    // Allow relative protocol links.
    if (trimmedUrl.startsWith('//')) {
      return trimmedUrl;
    }

    // Add the default protocol since there isnt one. Ideally this would be https, but this was originally
    // implemented as http and changing it could potentially break client websites.
    return `http://${trimmedUrl}`;
  }
  render() {
    const {
      description,
      original,
      originalAlt,
      altText,
      srcSet,
      sizes,
      supportsWebp,
      width,
      breakpoints,
      orientation,
      placeHolderImageUrl,
      preloadComponent,
      children,
      hyperlink,
      showDescriptionOnTop,
      overlayDescriptionOnImage,
      itemId = ''
    } = this.props;
    const { isLoaded } = this.state;

    let descriptionStyle;
    if (!showDescriptionOnTop) {
      if (!overlayDescriptionOnImage) {
        descriptionStyle = 'descriptionBottom';
      } else {
        descriptionStyle = 'descriptionOverlayedBottom';
      }
    } else {
      if (overlayDescriptionOnImage) {
        descriptionStyle = 'description';
      } else {
        descriptionStyle = 'descriptionTop';
      }
    }

    const descriptionNode = (
      <span {...resolve(this.props, descriptionStyle)}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message */}
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </span>
    );

    const placeHolderImageComponent = (
      <img
        {...resolve(this.props, orientation)}
        src={placeHolderImageUrl}
        alt={originalAlt || ''}
      />
    );

    let itemComponent = children;
    if (!itemComponent) {
      const imageComponent = (
        <ResponsiveImage
          {...resolve(this.props, orientation, isLoaded ? '' : 'loading')}
          src={original}
          alt={originalAlt || altText || ''}
          srcSet={srcSet}
          width={width}
          breakpoints={breakpoints}
          supportsWebp={supportsWebp}
          onLoad={this.onLoad}
          onError={this.onError}
          sizes={sizes}
        />
      );
      itemComponent = hyperlink ? (
        <a href={this.addProtocol(hyperlink)} target="_blank">
          {imageComponent}
        </a>
      ) : (
        imageComponent
      );
    }
    return (
      <div
        {...resolve(this.props, 'item')}
        ref={i => (this.imageContainer = i)}
        data-cvent-id={itemId}
      >
        {itemComponent}
        {isLoaded || children ? null : (
          <div {...resolve(this.props, 'relative')}>
            {placeHolderImageComponent}
            {preloadComponent || null}
          </div>
        )}
        {isLoaded && description ? descriptionNode : null}
      </div>
    );
  }
}
