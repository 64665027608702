/**
 * Contains clients to interact with calendar service
 **/
import Logger from '@cvent/nucleus-logging';
import { RequestBuilder } from '@cvent/nucleus-networking';
import { getLocalISODate } from 'cvent-event-calendar/lib/CalendarView/utils/dateUtil';

const LOG = new Logger('CalendarEventsClient');

// @ts-expect-error TS(2339): Property 'cvtDomain' does not exist on type 'Window & typeof globalThis'.
export const CALENDAR_URL = (window.cvtDomain || '') + '/api/calendar_events/v1';

async function callApi(builder: $TSFixMe, calendarId: $TSFixMe, accessToken: $TSFixMe, postBody: $TSFixMe) {
  let arrayToJson = getAndRemoveArrayToJson();
  const response = await fetch(builder.auth(accessToken).post().json(postBody).build());

  addArrayToJson(arrayToJson);

  if (!response.ok) {
    LOG.error('Error loading calendar data', response.status, response.text());
    throw new Error(`Calendar data load failed calendarId: ${String(calendarId)}`);
  }
  return response.json();
}

/**
 * Client to fetch more events to be displayed on the calendar
 *
 * @param calendarId id of the calendar to display the events on
 * @param searchId the id of the search criteria posted for search
 * @param offset the first record to be returned in the next set of events
 * @param accessToken
 */
export function loadMoreEvents(calendarId: string, searchId: string, offset: number, accessToken: string) {
  const builder = new RequestBuilder({
    url: `${CALENDAR_URL}/${calendarId}/events?searchId=${searchId}&offset=${offset}`
  });
  return callApi(builder, calendarId, accessToken, {});
}

/**
 * Client to fetch events matching the keyword entered
 *
 * @param calendarId id of the calendar to display the events on
 * @param keyword the text to search for
 * @param fromDate date cutoff start
 * @param toDate date cutoff end
 * @param filters to use for filtering
 * @param accessToken
 */
export function searchEvents(
  calendarId: string,
  { keyword = '', fromDate, toDate, filters }: $TSFixMe = {},
  accessToken: string,
  forMonth: boolean = false
) {
  /* eslint-disable no-param-reassign */
  fromDate = fromDate instanceof Date ? getLocalISODate(fromDate) : '';
  toDate = toDate instanceof Date ? getLocalISODate(toDate) : '';
  /* eslint-enable no-param-reassign */
  const builder = new RequestBuilder({
    url: `${CALENDAR_URL}/${calendarId}/events?forMonth=${forMonth}`
  });
  const postBody = {
    searchText: keyword,
    fromDate,
    toDate,
    filters: filters ? Object.values(filters) : []
  };
  return callApi(builder, calendarId, accessToken, postBody);
}

/**
 * Client to fetch event details by event id
 *
 * @param calendarId id of the calendar to display the events on
 * @param eventId the id of the event to get details of
 * @param type the type of event
 * @param accessToken
 */
// @ts-expect-error TS(1016): A required parameter cannot follow an optional par... Remove this comment to see the full error message
export async function getEventDetails(calendarId: string, eventId: string, type: string, parentId?: string, accessToken: string) {
  const typeQuery = `type=${type}`;
  const parentIdQuery = parentId ? `&parentId=${parentId}` : '';

  const builder = new RequestBuilder({
    url: `${CALENDAR_URL}/${calendarId}/events/${eventId}?${typeQuery}${parentIdQuery}`
  });
  const response = await fetch(builder.auth(accessToken).get().build());
  if (!response.ok) {
    LOG.error('Error loading event data', response.status, response.text());
    throw new Error(`Event data load failed eventId: ${String(eventId)}`);
  }
  return response.json();
}

/**
 * gets and removes toJSON function from Array prototype provided by prototype.js - for PROD-45702
 * @returns function
 */
function getAndRemoveArrayToJson() {
  let arrayToJson;
  // @ts-expect-error TS(2339): Property 'Prototype' does not exist on type 'Windo... Remove this comment to see the full error message
  if (window.Prototype && Array.prototype.toJSON) {
    // @ts-expect-error TS(2339): Property 'toJSON' does not exist on type 'any[]'.
    arrayToJson = Array.prototype.toJSON;
    // @ts-expect-error TS(2339): Property 'toJSON' does not exist on type 'any[]'.
    delete Array.prototype.toJSON; // avoid array conversion to string if toJSON is overridden
  }
  return arrayToJson;
}

/**
 * add toJSON to Array Prototype after request object is stringified  - for PROD-45702
 * @param arrayToJson - toJSON function
 */
function addArrayToJson(arrayToJson: $TSFixMe) {
  // @ts-expect-error TS(2339): Property 'Prototype' does not exist on type 'Windo... Remove this comment to see the full error message
  if (window.Prototype && arrayToJson) {
    // @ts-expect-error TS(2339): Property 'toJSON' does not exist on type 'any[]'.
    // eslint-disable-next-line
    Array.prototype.toJSON = arrayToJson;
  }
}
