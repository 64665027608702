import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';

import { BaseWidget } from '../BaseWidget';
import { Location } from './Location';
import ClassNames from './Location.less';
import { ThemeableComponentProps } from '../ThemeableComponent';

export interface LocationWidgetProps extends ThemeableComponentProps {
  // Required prop due to destructure in render function
  config: {
    displayAddressOption?: string;
  };
  classes: Record<string, unknown>;
  // Non-configurable appData props that should be added by the application specific wrapper.
  location?: string;
  address?: {
    address1?: string;
    address2?: string;
    address3?: string;
    city?: string;
    stateCode?: string;
    postalCode?: string;
    countryCode?: string;
    countryName?: string;
  };
}
/**
 * A basic location widget that can be wrapped by any application specific implementation to
 * populate the location fields.
 */
export class LocationWidget extends BaseWidget<LocationWidgetProps> {
  static displayName = 'LocationWidget';
  static defaultProps = {
    classes: ClassNames
  };
  getStyleObject() {
    return {
      ...super.getStyleObject(),
      name: this.getElementInlineStyle('location'),
      address: this.getElementInlineStyle('address')
    };
  }
  render() {
    const {
      location,
      classes,
      address = {},

      config: { displayAddressOption }
    } = this.props;

    // Remove sections of the address that will be ommitted for display
    let addressToDisplay = { ...address };
    if (displayAddressOption === 'location') {
      // @ts-expect-error 2322 - reassigning undefined to a variable with a type
      addressToDisplay = undefined;
    } else if (displayAddressOption === 'locationAddress' && address) {
      addressToDisplay.countryCode = undefined;
      addressToDisplay.countryName = undefined;
    }

    return (
      <Location
        {...resolveTestId(this.props)}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        classes={classes}
        style={this.getStyleObject()}
        location={location}
        address={addressToDisplay}
      />
    );
  }
}
