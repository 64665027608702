import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';

import { BaseWidget } from '../BaseWidget';
import { Description } from './Description';
import ClassNames from './Description.less';
import { ThemeableComponentProps } from '../ThemeableComponent';

export interface DescriptionWidgetProps extends ThemeableComponentProps {
  classes?: Record<string, unknown>;
  style?: Record<string, unknown>;
  // Non-configurable appData props that should be added by the application specific wrapper.
  text?: string;
  shouldRenderHtml?: boolean;
}
/**
 * A basic description widget that can be wrapped by any application specific implementation to
 * populate the description fields.
 */
export class DescriptionWidget extends BaseWidget<DescriptionWidgetProps> {
  static displayName = 'DescriptionWidget';
  static defaultProps = {
    classes: ClassNames
  };
  getStyleObject() {
    return {
      ...super.getStyleObject(),
      text: this.getElementInlineStyle('description')
    };
  }
  render() {
    const { text, classes, shouldRenderHtml } = this.props;
    return (
      <Description
        {...resolveTestId(this.props)}
        classes={classes}
        style={this.getStyleObject()}
        text={text}
        shouldRenderHtml={shouldRenderHtml}
      />
    );
  }
}
