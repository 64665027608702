import React, { useState, useEffect, useRef } from 'react';

import ArrangeIcon from '@cvent/carina/components/Icon/Arrange';
import {
  DragContainer,
  DragHandle,
  getBrowserDimensions,
  getElementPosition,
  FocusTrap
} from 'nucleus-core';
import { connect } from 'react-redux';
import { injectTestId } from '@cvent/nucleus-test-automation';

import Classes from './CommentListFlyout.less';
import { CommentSubmit } from './CommentSubmit';
import { CommentItem } from './CommentItem';
import { Comment } from '../../types/comment';

const FLYOUT_OFFSET_UP = 52;

export const CommentListFlyoutBase = ({
  comments = [],
  translate,
  widgetId,
  isGuest,
  onCancel,
  locale
}: any) => {
  /* Skip the initial render, which is a render with flyout in the wrong position if out of the browser window */
  const [shouldRender, startRender] = useState(false);

  /* The states that can detect if the flyout/caret's position needs to be adjusted based */
  const [newLeftPos, setNewLeftPos] = useState(0);
  const [newTopPos, setNewTopPos] = useState(0);
  const [caretOpensUp, setCaretPosition] = useState(false);
  const [useShadow, setShadow] = useState(false);
  const flyout = useRef<HTMLDivElement>(null);
  const caret = useRef(null);
  const dragContainer = useRef(null);
  const commentEndRef = useRef(null);

  const activeComments = comments.filter((comment: any) => comment.active);
  const totalComments = activeComments.length;
  const hasComments = totalComments > 0;

  useEffect(() => {
    function renderFlyout() {
      startRender(true);
    }
    renderFlyout();
  });

  function updateCaretPosNeeded() {
    return flyout.current && newTopPos === 0 && newLeftPos === 0 && !caretOpensUp;
  }

  function updateLeftPos() {
    const flyoutLeftMeasure = getElementPosition(flyout.current, undefined).x;
    if (flyoutLeftMeasure < 0) {
      setNewLeftPos(-getElementPosition(flyout.current, undefined).x);
    }
  }

  function updateTopPos() {
    const bottomPosition = Math.round(getElementPosition(flyout.current, undefined).bottom);
    const topPosition = Math.round(getElementPosition(flyout.current, undefined).top);
    const browserHeight = getBrowserDimensions(window, document).browserHeight;
    const flyoutHeight = flyout?.current?.offsetHeight ?? 0;
    if (bottomPosition > browserHeight) {
      // Check if flyout get adjusted to open upwards, will there be enough space.
      // If not, then just let it open downwards, even though browser is gonna cut it off a bit
      const commentHeaderHeight = document.getElementById('comments-header')?.offsetHeight ?? 0;
      const flyoutPosition =
        browserHeight - topPosition + FLYOUT_OFFSET_UP + flyoutHeight - commentHeaderHeight;
      if (topPosition + document.documentElement.scrollTop > flyoutPosition) {
        setCaretPosition(true);
        /* This calculation is for moving the flyout upwards using its height and a fixed offset relative to caret's position */
        setNewTopPos(-(flyoutHeight + FLYOUT_OFFSET_UP));
      }
    }

    // Update flyout's upward position && caret's position if comment added/deleted
    if (caretOpensUp) {
      setCaretPosition(true);
      setNewTopPos(-(flyoutHeight + FLYOUT_OFFSET_UP));
    }
  }
  /* When caret opens up, update top position when new comment is added  */
  useEffect(() => {
    if (
      shouldRender &&
      caretOpensUp &&
      // @ts-expect-error (2554) dragContainer?.current?.state
      dragContainer?.current?.state.left === newLeftPos &&
      // @ts-expect-error (2554) dragContainer?.current?.state
      dragContainer?.current?.state.top === newTopPos
    ) {
      updateTopPos();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments, shouldRender]);

  /* Update flyout's position upwards upon mounting, if needed */
  if (updateCaretPosNeeded()) {
    updateLeftPos();
    updateTopPos();
  }

  function renderCaret() {
    return (
      <span
        className={`${Classes.triangle} ${caretOpensUp ? Classes.openUp : ''}
        ${hasComments ? '' : Classes.noComments}`}
        ref={caret}
        {...injectTestId('comment-flyout-caret')}
      />
    );
  }

  function renderCommentsList(listComments: any) {
    const commentsList = listComments.map((comment: Comment) => (
      <CommentItem
        key={`comment-item${comment.id}`}
        additionalStyle={Classes}
        comment={comment}
        translate={translate}
        widgetId={widgetId}
        locale={locale}
      />
    ));
    return (
      <>
        {commentsList}
        <div ref={commentEndRef}></div>
      </>
    );
  }

  return (
    <div className={Classes.commentWrapper}>
      {renderCaret()}
      <FocusTrap>
        <DragContainer
          minTop={-Number.MAX_VALUE}
          minLeft={-Number.MAX_VALUE}
          initialLeft={newLeftPos}
          initialTop={newTopPos}
          onDragHandler={() => {
            // @ts-expect-error Object is possibly 'null'.ts(2531)
            caret.current.style.display = 'none';
          }}
          ref={dragContainer}
          allowOutOfViewport={false}
          containerHeight={flyout?.current?.offsetHeight}
          containerWidth={flyout?.current?.offsetWidth}
        >
          <div
            className={`${Classes.commentList} ${caretOpensUp ? Classes.openUp : ''} ${
              !hasComments ? Classes.noComments : ''
            }`}
            ref={flyout}
          >
            <div className={`${Classes.header} ${useShadow ? Classes.shadow : ''}`}>
              <DragHandle {...injectTestId('drag-handle-arrange-icon')}>
                <ArrangeIcon />
              </DragHandle>
              <span>
                {translate('NucleusWidgets_Comments_Flyout_Header')}{' '}
                {totalComments > 0 ? `(${totalComments})` : null}
              </span>
            </div>

            <div
              ref={ref => {
                const shadow = !!ref && ref.scrollHeight > ref.clientHeight;
                setShadow(shadow);
              }}
              className={`${Classes.list} ${!useShadow ? Classes.divider : ''}`}
            >
              {renderCommentsList(activeComments)}
            </div>

            <div className={`${Classes.footer} ${useShadow ? Classes.shadow : ''}`}>
              <CommentSubmit
                maxTextCount={500}
                translate={translate}
                widgetId={widgetId}
                isGuest={isGuest}
                onCancel={onCancel}
                commentEndRef={commentEndRef}
              />
            </div>
          </div>
        </DragContainer>
      </FocusTrap>
    </div>
  );
};

export const CommentListFlyout = connect((state: any) => {
  return {
    locale: state.text.locale,
    translate: state.text.translate
  };
})(CommentListFlyoutBase);
