import React from 'react';

import { resolve, select } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import { Button as NucleusButton } from 'nucleus-core';

type Props = {
  text?: string;
  onClick?: (...args: any[]) => any;
  kind?: string;
  link?: {
    text: string;
    onClick?: (...args: any[]) => any;
    enabled: boolean;
  };
  // Used in resolve function // TODO: determine neccesary fields for classes
  classes?: Record<string, unknown>;
  // Used in resolve function // TODO: determine neccesary fields for config
  style?: Record<string, unknown>;
  disabled?: boolean;
  isRichText?: boolean;
};

/**
 * The basic button widget UI implementation.
 */
export const Button = (props: Props) => {
  // Originally, buttons were implemented with rich text editors, but since then
  // they have been updated to be normal textboxes. So we have to allow the text
  // property to come in as rich text or normal plain text. https://jira/browse/FLEX-6581
  let textElement;
  if (props.isRichText) {
    textElement = (
      <span {...resolve(props, 'text')} dangerouslySetInnerHTML={{ __html: props.text }} />
    );
  } else {
    textElement = <span {...resolve(props, 'text')}>{props.text}</span>;
  }

  return (
    <div {...resolveTestId(props)} {...resolve(props, 'container')}>
      <div {...resolve(props, 'buttonWrapper')}>
        <NucleusButton
          {...select(props, 'button')}
          onClick={props.onClick}
          kind={props.kind}
          disabled={props.disabled}
        >
          {textElement}
        </NucleusButton>
      </div>
      {props.link && props.link.enabled && (
        <div {...resolve(props, 'linkWrapper')}>
          <a {...resolve(props, 'link', 'inlineBlock')} href="#" onClick={props.link.onClick}>
            {props.link.text}
          </a>
        </div>
      )}
    </div>
  );
};

Button.displayName = 'Button';
