import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import { InteractiveElement } from 'nucleus-core';
import CustomFooterStyles from './CustomFooter.less';

/* eslint-disable no-script-url */
const noOp = 'javascript:void(0);';

type OwnProps = {
  translate: (...args: any[]) => any;
  enableHyperlink?: boolean;
  showPrivacyPolicy?: boolean;
  privacyPolicyUrl?: string;
  additionalPolicyLinks?: any[];
  showComplianceText?: boolean;
  complianceText?: string;
  onComplianceClick?: (...args: any[]) => any;
  showManageCookiePreferences?: boolean;
  onManageCookiePreferences?: () => void;
  manageCookiePreferencesText?: string;
};

type Props = OwnProps & typeof CustomFooter.defaultProps;

/**
 * A component that display the custom footer content.
 */
export class CustomFooter extends React.Component<Props> {
  static displayName = 'CustomFooter';
  static defaultProps = {
    classes: CustomFooterStyles,
    privacyPolicyUrl: 'https://www.cvent.com/en/privacy-policy.shtml',
    showPrivacyPolicy: true,
    showComplianceText: false,
    onComplianceClick: noOp,
    complianceText: 'NucleusWidgets_Ccpa_CCPALinkText__resx',
    showManageCookiePreferences: false
  };

  getHrefValue = (href: any) => (this.props.enableHyperlink ? href : noOp);

  renderPrivacyPolicyLink() {
    return (
      <li key={'privacyPolicy'} {...resolve(this.props, 'list')}>
        <a
          {...resolve(this.props, 'link')}
          href={this.getHrefValue(this.props.privacyPolicyUrl)}
          target="_blank"
        >
          {this.props.translate('_cventFooter_privacyPolicy__resx')}
        </a>
      </li>
    );
  }

  renderLink(href: any, text: any, key: any) {
    return (
      <li {...resolve(this.props, 'list')} key={key}>
        <a {...resolve(this.props, 'link')} href={this.getHrefValue(href)} target="_blank">
          {this.props.translate(text)}
        </a>
      </li>
    );
  }
  renderLinkWithCallback(callback: any, localizationString: any) {
    return (
      <InteractiveElement
        {...resolve(this.props, 'callbackFuncLink', 'link')}
        element="span"
        onClick={() => {
          callback();
        }}
      >
        {this.props.translate(localizationString)}
      </InteractiveElement>
    );
  }

  render() {
    const {
      showPrivacyPolicy,
      additionalPolicyLinks,
      showComplianceText,
      onComplianceClick,
      complianceText,
      showManageCookiePreferences,
      onManageCookiePreferences,
      manageCookiePreferencesText
    } = this.props;
    const renderedAdditionalPolicyLinks =
      additionalPolicyLinks && additionalPolicyLinks.length >= 1
        ? additionalPolicyLinks.map((link, index) =>
            this.renderLink(link.url, link.text, 'policyLink' + index)
          )
        : [];
    return (
      <footer {...resolveTestId(this.props)} {...resolve(this.props, 'container')}>
        {showComplianceText && (
          <div>{this.renderLinkWithCallback(onComplianceClick, complianceText)}</div>
        )}
        <ul {...resolve(this.props, 'policyLinks')}>
          {[
            showPrivacyPolicy && this.renderPrivacyPolicyLink(),
            ...renderedAdditionalPolicyLinks,
            showManageCookiePreferences && (
              <li {...resolve(this.props, 'list')} key={manageCookiePreferencesText}>
                {this.renderLinkWithCallback(
                  onManageCookiePreferences,
                  manageCookiePreferencesText
                )}
              </li>
            )
          ]}
        </ul>
      </footer>
    );
  }
}
