import { isTouchEnabled } from './utils/isTouchEnabled';
const hasTouch = isTouchEnabled();

// http://ariatemplates.com/blog/2014/05/ghost-clicks-in-mobile-browsers/
const ghostClicks: any = [];

// Registers a ghost click for the given touch event.
function addGhostClickBuster(touchEvent: any) {
  if (!touchEvent || !('changedTouches' in touchEvent)) {
    throw new Error('No event or no changedTouches in event object');
  }
  ghostClicks.push({
    element: touchEvent.target,
    x: touchEvent.changedTouches[0].screenX,
    y: touchEvent.changedTouches[0].screenY
  });
}

// Removes ghost clicks for the given element.
function removeGhostClickBusterByElement(element: any) {
  for (let index = ghostClicks.length - 1; index >= 0; --index) {
    if (ghostClicks[index].element === element) {
      ghostClicks.splice(index, 1);
    }
  }
}

// Clear the touch state and remove ghost clicks for the element.
/* eslint-disable no-param-reassign */
function endTouch(element: any, state: any) {
  clearTimeout(state.touchTimeout);
  state.touches = 0;
  state.touchTimeout = null;
  removeGhostClickBusterByElement(element);
}
/* eslint-enable no-param-reassign */

// Prevents any ghost clicks.
function ghostClickBuster(event: any) {
  if (ghostClicks.length === 0) {
    return;
  }
  const x = ~~event.screenX;
  const y = ~~event.screenY;
  const xMin = x - 50;
  const xMax = x + 50;
  const yMin = y - 50;
  const yMax = y + 50;
  for (let index = 0; index < ghostClicks.length; ++index) {
    const click = ghostClicks[index];
    if (click.x > xMin && click.x < xMax && click.y > yMin && click.y < yMax) {
      event.preventDefault();
      event.stopImmediatePropagation();
      break;
    }
  }
}

// Add a global click handler on the document to prevent any ghost clicks that get registered via touch events.
if (typeof document !== 'undefined' && 'addEventListener' in document) {
  document.addEventListener('click', ghostClickBuster, true);
}

function createClickBustingHandlers(callback: any, timeout: any, timeoutBeforeNextTap: any) {
  const state = {
    touches: 0
  };
  return {
    touchStart: (event: any) => {
      if (event.defaultPrevented) {
        return;
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchTimeout' does not exist on type '{ ... Remove this comment to see the full error message
      clearTimeout(state.touchTimeout);
      state.touches++;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchTimeout' does not exist on type '{ ... Remove this comment to see the full error message
      state.touchTimeout = setTimeout(endTouch.bind(null, event.target, state), timeout);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'x' does not exist on type '{ touches: nu... Remove this comment to see the full error message
      state.x = event.changedTouches[0].screenX;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'y' does not exist on type '{ touches: nu... Remove this comment to see the full error message
      state.y = event.changedTouches[0].screenY;
    },
    touchEnd: (event: any) => {
      if (event.defaultPrevented || state.touches !== 1) {
        return;
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'x' does not exist on type '{ touches: nu... Remove this comment to see the full error message
      const deltaX = state.x - event.changedTouches[0].screenX;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'y' does not exist on type '{ touches: nu... Remove this comment to see the full error message
      const deltaY = state.y - event.changedTouches[0].screenY;

      if (Math.abs(deltaX) < 5 && Math.abs(deltaY) < 5) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchTimeout' does not exist on type '{ ... Remove this comment to see the full error message
        clearTimeout(state.touchTimeout);
        addGhostClickBuster(event);
        event.preventDefault();
        callback(event);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchTimeout' does not exist on type '{ ... Remove this comment to see the full error message
        state.touchTimeout = setTimeout(
          endTouch.bind(null, event.target, state),
          timeoutBeforeNextTap
        );
      }
    },
    click: (event: any) => {
      if (event.defaultPrevented || state.touches > 0) {
        return;
      }
      callback(event);
    }
  };
}

/**
 * Maps the provided callback function to touchStart, touchEnd, and onClick events.
 * If tapping on a touchscreen, the touchStart and touchEnd events will be used to emulate
 * a click, and the ghost click event that the browser would normally trigger gets cancelled.
 */
export function tapOrClick(callback: any, timeout = 250, timeoutBeforeNextTap = 425) {
  if (!callback) {
    return {};
  }
  if (typeof callback !== 'function') {
    throw new Error('First argument to tapOrClick must be a callback function');
  }
  if (!hasTouch) {
    return {
      onClick: callback
    };
  }
  const handlers = createClickBustingHandlers(callback, timeout, timeoutBeforeNextTap);
  return {
    onTouchStart: handlers.touchStart,
    onTouchEnd: handlers.touchEnd,
    onClick: handlers.click
  };
}

/**
 * Maps the provided callback function to touchStart, touchEnd, and onMouseUp events.
 * If tapping on a touchscreen, the touchStart and touchEnd events will be used to emulate
 * a mouseup, and the ghost mouseup event that the browser would normally trigger gets cancelled.
 */
export function tapOrMouseUp(callback: any, timeout = 250, timeoutBeforeNextTap = 425) {
  if (!callback) {
    return {};
  }
  if (typeof callback !== 'function') {
    throw new Error('First argument to tapOrMouseUp must be a callback function');
  }
  if (!hasTouch) {
    return {
      onMouseUp: callback
    };
  }
  const handlers = createClickBustingHandlers(callback, timeout, timeoutBeforeNextTap);
  return {
    onTouchStart: handlers.touchStart,
    onTouchEnd: handlers.touchEnd,
    onMouseUp: handlers.click
  };
}

/**
 * Maps the provided callback function to touchStart and touchEnd events.
 * If tapping on a touchscreen, the touchStart and touchEnd events will be
 * used to detect the "tap" action and call the appopriate callback.
 */
export function tap(callback: any, timeout = 250, timeoutBeforeNextTap = 425) {
  if (!callback) {
    return {};
  }
  if (typeof callback !== 'function') {
    throw new Error('First argument to tap must be a callback function');
  }
  if (!hasTouch) {
    return {
      onMouseUp: callback
    };
  }
  const state = { touches: 0 };
  return {
    onTouchStart: (event: any) => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchTimeout' does not exist on type '{ ... Remove this comment to see the full error message
      clearTimeout(state.touchTimeout);
      state.touches++;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchTimeout' does not exist on type '{ ... Remove this comment to see the full error message
      state.touchTimeout = setTimeout(endTouch.bind(null, event.target, state), timeout);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'x' does not exist on type '{ touches: nu... Remove this comment to see the full error message
      state.x = event.changedTouches[0].screenX;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'y' does not exist on type '{ touches: nu... Remove this comment to see the full error message
      state.y = event.changedTouches[0].screenY;
    },
    onTouchEnd: (event: any) => {
      if (state.touches !== 1) {
        return;
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'x' does not exist on type '{ touches: nu... Remove this comment to see the full error message
      const deltaX = state.x - event.changedTouches[0].screenX;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'y' does not exist on type '{ touches: nu... Remove this comment to see the full error message
      const deltaY = state.y - event.changedTouches[0].screenY;

      if (Math.abs(deltaX) < 5 && Math.abs(deltaY) < 5) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchTimeout' does not exist on type '{ ... Remove this comment to see the full error message
        clearTimeout(state.touchTimeout);
        callback(event);
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchTimeout' does not exist on type '{ ... Remove this comment to see the full error message
        state.touchTimeout = setTimeout(
          endTouch.bind(null, event.target, state),
          timeoutBeforeNextTap
        );
      }
    }
  };
}

/**
 * Maps the provided callback function to touchStart and touchEnd events.
 * If swiping on a touchscreen, the touchStart and touchEnd events will be
 * used to detect the "swipe" action and call the appopriate callback with
 * the swipe direction ('left', 'right', 'up', 'down').
 * @param {number} threshold - required min distance traveled to be considered swipe.
 * @param {number} restraint - maximum distance allowed at the same time in perpendicular direction.
 */
export function swipe(callback: any, timeout = 500, threshold = 150, restraint = 100) {
  if (!hasTouch || !callback) {
    return {};
  }
  if (typeof callback !== 'function') {
    throw new Error('First argument to tap must be a callback function');
  }

  const state = { touches: 0 };
  return {
    onTouchStart: (event: any) => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchTimeout' does not exist on type '{ ... Remove this comment to see the full error message
      clearTimeout(state.touchTimeout);
      state.touches++;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'touchTimeout' does not exist on type '{ ... Remove this comment to see the full error message
      state.touchTimeout = setTimeout(endTouch.bind(null, event.target, state), timeout);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'x' does not exist on type '{ touches: nu... Remove this comment to see the full error message
      state.x = event.changedTouches[0].screenX;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'y' does not exist on type '{ touches: nu... Remove this comment to see the full error message
      state.y = event.changedTouches[0].screenY;
    },
    onTouchEnd: (event: any) => {
      if (state.touches !== 1) {
        return;
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'x' does not exist on type '{ touches: nu... Remove this comment to see the full error message
      const deltaX = state.x - event.changedTouches[0].screenX;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'y' does not exist on type '{ touches: nu... Remove this comment to see the full error message
      const deltaY = state.y - event.changedTouches[0].screenY;
      let swipeDirection = null;
      if (Math.abs(deltaX) >= threshold && Math.abs(deltaY) <= restraint) {
        swipeDirection = deltaX < 0 ? 'left' : 'right';
      } else if (Math.abs(deltaY) >= threshold && Math.abs(deltaX) <= restraint) {
        swipeDirection = deltaY < 0 ? 'up' : 'down';
      }
      if (swipeDirection) {
        callback(event, swipeDirection);
        endTouch.bind(event.target, state);
      }
    }
  };
}
