import Logger from '@cvent/nucleus-logging';
const LOG = new Logger('nucleus-widgets/clients/ImageLookupClient');

/**
 * API client for communicating with image lookup service.
 */
export class ImageLookupClient {
  authorization: any;
  url: any;
  constructor({ url: baseUrl, environment, authorization }: any) {
    this.url = `${baseUrl}?environment=${environment}`;
    this.authorization = authorization;
  }

  /**
   * Takes in an array of image urls, and returns the lookup object returned from the image lookup service.
   *
   * To minimize user wait time, we suggest your service to call the image lookup service directly,
   * and send the image lookup object together with the website snapshot / appData to the browser.
   * This function should only be called when the lookup data isn't already sent from the service.
   * @param {*} imageArray - an array of image urls in the format that the image lookup service expects:
   *                         [{ url: imageUrl1 }, { url: imageUrl2 }]
   */
  fetchImageLookup = async (imageArray: any) => {
    if (this.url && imageArray && imageArray.length) {
      const options = {
        method: 'POST',
        headers: {
          authorization: this.authorization,
          'content-type': 'application/json'
        },
        body: JSON.stringify(imageArray)
      };
      try {
        const response = await (await fetch(this.url, options)).json();
        return response.data;
      } catch (e) {
        LOG.error('Error fetching image lookup object', e);
      }
    }
  };
}
