import { v1 } from './v1';
import { v2 } from './v2';
import { v3 } from './v3';
import { v4 } from './v4';
import { v5 } from './v5';
import { v6 } from './v6';
import { v7 } from './v7';
import { isFeatureFlagEnabled } from '@cvent/nucleus-platform';
import { v8 } from './v8';

export const FRAMEWORK_VERSION = 5;
export const PAGE_VERSION = 1;

/**
 * Migrations for the overall "website" object of the site editor.
 * These are core migrations to apply to existing websites so that
 * framework updates can be compatible with them.
 */
export function websiteMigrations(website: any, blocksThemingEnabled?: boolean) {
  let newWebsite = { ...website };

  // Framework Version 0 -> 1.
  if (!newWebsite.frameworkVersion) {
    newWebsite = v1(newWebsite);
  }

  // Framework Version 1 -> 2.
  if (newWebsite.frameworkVersion === 1) {
    newWebsite = v2(newWebsite);
  }

  // Framework Version 2 -> 3.
  if (newWebsite.frameworkVersion === 2) {
    newWebsite = v3(newWebsite);
  }

  // Framework Version 3 -> 4.
  if (newWebsite.frameworkVersion === 3) {
    newWebsite = v4(newWebsite);
  }

  // Framework Version 4 -> 5.
  if (newWebsite.frameworkVersion === 4) {
    newWebsite = v5(newWebsite);
  }

  // Framework Version 5 -> 6.
  if (newWebsite.frameworkVersion === 5) {
    newWebsite = v6(newWebsite);
  }

  // Framework Version 6 -> 7.
  if (newWebsite.frameworkVersion === 6) {
    newWebsite = v7(newWebsite);
  }
  // Framework Version 7 -> 8.
  if (
    newWebsite.frameworkVersion === 7 &&
    (isFeatureFlagEnabled('BlocksTheming') || blocksThemingEnabled)
  ) {
    newWebsite = v8(newWebsite);
  }

  return newWebsite;
}
