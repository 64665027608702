// Basic grid.js container.
export const CONTAINER = 'container';
// Basic grid.js row.
export const ROW = 'row';
// Custom sub container of rows that only renders its children.
export const SECTION_ROW = 'sectionRow';
// Custom sub container of rows that only renders its children.
export const SECTION_COLUMN = 'sectionColumn';
// Widget content that is automatically wrapped in a grid.js column.
export const WIDGET = 'widget';
