import merge from 'lodash/merge';
import { hexStringToRgb } from 'nucleus-core';

import { getStyleObjectWithPseudoState } from './getStyleObjectWithPseudoState';
import { convertThemePaletteColor } from '../fields/convertThemePaletteColor';

export function isIE() {
  const userAgent = window?.navigator?.userAgent;
  const msie = userAgent.indexOf('MSIE'); // IE10 or Older
  const trident = userAgent.indexOf('Trident'); // IE11
  return msie > 0 || trident > 0;
}

function getBackgroundColor({
  styleObject,
  customStyleSettings,
  colorPalette,
  // When `color` isn't defined in styleObject and customStyleSettings,
  // `baseColor` is used to calculate background color.
  // For example, when only opacity is changed in hover state,
  // there's no color value in styleObject and customStyleSettings.
  // So we use the color from the default state to get the final background color.
  baseColor
}: any) {
  const mergedStyleObject = merge({}, styleObject, customStyleSettings);
  if (!mergedStyleObject || !mergedStyleObject.background) {
    return {};
  }
  const { colorAlpha } = mergedStyleObject.background;
  let { color } = mergedStyleObject.background;
  color = convertThemePaletteColor(colorPalette, color ?? baseColor);

  let colorString;
  if (color) {
    const rgb = hexStringToRgb(color);
    const alpha = colorAlpha === undefined ? 1 : colorAlpha / 100;
    colorString = alpha === 1 ? color : `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
  } else {
    colorString = 'transparent';
  }
  return { backgroundColor: colorString };
}

export function getBackground(
  styleObject: any,
  customStyleSettings: any,
  colorPalette: any,
  themeImages: any,
  imageLookup = {},
  supportsWebp: any
) {
  const mergedStyleObject = merge({}, styleObject, customStyleSettings);
  if (!mergedStyleObject || !mergedStyleObject.background) {
    return {};
  }
  const { image, borderRadius, color } = mergedStyleObject.background;

  const settings = {
    ...getBackgroundColor({ styleObject, customStyleSettings, colorPalette }),
    borderRadius,
    hover: getBackgroundColor({
      ...getStyleObjectWithPseudoState(styleObject, customStyleSettings, 'hover'),
      colorPalette,
      baseColor: color
    })
  };

  if (!image || !image.url || !image.url.assetUri) {
    return settings;
  }

  const { url, size, scale, fixedPosition, position, repeat } = image;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'backgroundImage' does not exist on type ... Remove this comment to see the full error message
  settings.backgroundImage = `url(${
    themeImages && themeImages[url.assetUri]
      ? themeImages[url.assetUri]
      : // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        (imageLookup[url.assetUri] && imageLookup[url.assetUri].hashedURL) || url.assetUri
  }${supportsWebp ? '?f=webp' : ''})`;

  // Parse size settings.
  if (size && size.width && size.height) {
    const { width, height } = size;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'backgroundSize' does not exist on type '... Remove this comment to see the full error message
    settings.backgroundSize = `${width} ${height}`;
  } else if (scale || scale === undefined) {
    // If the scale setting is set to true or it is undefined, set the size to cover.
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'backgroundSize' does not exist on type '... Remove this comment to see the full error message
    settings.backgroundSize = 'cover';
  }

  // Parse repeat settings.
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'backgroundRepeat' does not exist on type... Remove this comment to see the full error message
  settings.backgroundRepeat = 'no-repeat';
  if (repeat) {
    if (repeat.x && repeat.y) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'backgroundRepeat' does not exist on type... Remove this comment to see the full error message
      settings.backgroundRepeat = 'repeat';
    } else if (repeat.x && !repeat.y) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'backgroundRepeat' does not exist on type... Remove this comment to see the full error message
      settings.backgroundRepeat = 'repeat-x';
    } else if (!repeat.x && repeat.y) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'backgroundRepeat' does not exist on type... Remove this comment to see the full error message
      settings.backgroundRepeat = 'repeat-y';
    }
  }

  // Parse position settings.
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'backgroundPosition' does not exist on ty... Remove this comment to see the full error message
  settings.backgroundPosition = 'left top';
  if (position) {
    const { xOffset, yOffset } = position;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'backgroundPosition' does not exist on ty... Remove this comment to see the full error message
    settings.backgroundPosition = `${xOffset || 'left'} ${yOffset || 'top'}`;
  }

  if (fixedPosition) {
    // IE workaround
    if (isIE()) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fixedPosition' does not exist on type '{... Remove this comment to see the full error message
      settings.fixedPosition = true;
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean' is not assignable to type '{ backgr... Remove this comment to see the full error message
      settings.hover = true;
      return settings;
    }
    const fixedSettings = {};
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fixedPosition' does not exist on type '{... Remove this comment to see the full error message
    fixedSettings.fixedPosition = true;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'hover' does not exist on type '{}'.
    fixedSettings.hover = settings.hover;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    fixedSettings['--background-image'] = settings.backgroundImage;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    fixedSettings['--background-color'] = settings.backgroundColor;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    fixedSettings['--background-size'] = settings.backgroundSize;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    fixedSettings['--background-repeat'] = settings.backgroundRepeat;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    fixedSettings['--background-position'] = settings.backgroundPosition;
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    fixedSettings['--border-radius'] = settings.borderRadius;
    return fixedSettings;
  }

  return settings;
}
