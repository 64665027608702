/**
 * A reducer for storing the event information displayed on the more info pop-up
 */

import Logger from '@cvent/nucleus-logging';
import { CALENDAR_ADDITIONAL_ITEM_TYPE } from 'cvent-event-calendar/utils/selectors';

import { getEventDetails } from '../../clients/CalendarEventsClient';

const LOG = new Logger('redux/modules/eventDetails');

const LOAD_EVENT_PENDING = 'calendar-guest-site/eventDetails/LOAD_EVENT_PENDING';
const LOAD_EVENT_SUCCESS = 'calendar-guest-site/eventDetails/LOAD_EVENT_SUCCESS';
const LOAD_EVENT_FAILURE = 'calendar-guest-site/eventDetails/LOAD_EVENT_FAILURE';
const OPEN_EVENT_DETAILS_DIALOG = 'calendar-guest-site/eventDetails/OPEN_EVENT_DETAILS_DIALOG';

// exported constants
export const CLOSE_EVENT_DETAILS_DIALOG = 'calendar-guest-site/eventDetails/CLOSE_EVENT_DETAILS_DIALOG';

const BUTTON_CONFIG = -99;
const CATEGORY_SEQUENCE = -98;
const TAG_SEQUENCE = -97;
// calendar item tag colors sequence
const CALENDAR_ITEM_TAG_COLORS_SEQUENCE = -96;

const EVENT_CATEGORY_WEBINAR = '18';

function typeHasAdditionalFields(type: string) {
  return type !== CALENDAR_ADDITIONAL_ITEM_TYPE;
}

function shouldFetchEventInformation(state: $TSFixMe, eventId: $TSFixMe, type: $TSFixMe) {
  if (typeof state[eventId] === 'undefined') {
    return true;
  }
  return typeHasAdditionalFields(type) && state[eventId].otherFields.length === 0 && !state[eventId].isFetching;
}

function getFilteredButtonConfigAndFields(fields: $TSFixMe) {
  const defaultFields = fields.filter(
    (field: $TSFixMe) =>
      field.fieldSequence !== BUTTON_CONFIG &&
      field.fieldSequence !== CATEGORY_SEQUENCE &&
      field.fieldSequence !== TAG_SEQUENCE &&
      field.fieldSequence !== CALENDAR_ITEM_TAG_COLORS_SEQUENCE &&
      field.fieldSequence < 0
  );
  const buttonConfigField = fields.filter((field: $TSFixMe) => field.fieldSequence === BUTTON_CONFIG);
  const categoryConfig = fields.filter((field: $TSFixMe) => field.fieldSequence === CATEGORY_SEQUENCE);
  const tagsField = fields.filter((field: $TSFixMe) => field.fieldSequence === TAG_SEQUENCE);
  const calendarItemTagColors = fields.filter((field: $TSFixMe) => field.fieldSequence === CALENDAR_ITEM_TAG_COLORS_SEQUENCE);
  const otherFields = fields.filter((field: $TSFixMe) => field.fieldSequence >= 0);
  let buttonConfig = null;
  if (buttonConfigField.length === 1) {
    buttonConfig = {
      url: buttonConfigField[0].fieldValue,
      title: buttonConfigField[0].fieldName
    };
  }
  let isWebinar = false;
  if (categoryConfig.length === 1) {
    isWebinar = categoryConfig[0].fieldValue === EVENT_CATEGORY_WEBINAR;
  }
  return {
    defaultFields,
    buttonConfig,
    isWebinar,
    otherFields,
    tagsField,
    calendarItemTagColors
  };
}

/**
 * Load event details
 **/
export function fetchEventInformationIfNeeded(eventId: string, type: string, dialogConfig: $TSFixMe) {
  return async (dispatch: $TSFixMe, getState: $TSFixMe) => {
    if (!shouldFetchEventInformation(getState().eventDetails, eventId, type)) {
      return dispatch({
        type: OPEN_EVENT_DETAILS_DIALOG,
        payload: {
          scrollTop: document.body.scrollTop,
          dialogConfig: {
            ...dialogConfig,
            popUpDialog: true
          },
          eventLoading: eventId
        }
      });
    }
    const { id: calendarId } = getState().calendar;
    dispatch({
      type: LOAD_EVENT_PENDING,
      payload: {
        scrollTop: document.body.scrollTop,
        dialogConfig: {
          ...dialogConfig,
          popUpDialog: true
        },
        eventLoading: eventId
      }
    });
    try {
      LOG.debug('fetchEventInfo', eventId);
      const { parentId } = getState().calendar.events[eventId];
      const eventFields = await getEventDetails(calendarId, eventId, type, parentId, getState().accessToken);

      dispatch({
        type: LOAD_EVENT_SUCCESS,
        payload: {
          eventFields,
          eventLoading: eventId
        }
      });
      LOG.debug('fetchEventInfo success');
    } catch (ex) {
      LOG.error('fetchEventInfo failed', ex);
      dispatch({
        type: LOAD_EVENT_FAILURE,
        payload: { eventLoading: eventId }
      });
    }
  };
}

export function closeEventDetailsDialog() {
  return {
    type: CLOSE_EVENT_DETAILS_DIALOG,
    payload: {
      dialogConfig: {
        popUpDialog: false
      },
      scrollTop: 0,
      eventLoading: null
    }
  };
}

/**
 * Reducer to keep track of the information displayed on the popup
 */
export default function eventDetails(state = { dialogConfig: { popUpDialog: false } }, action: $TSFixMe) {
  switch (action.type) {
    case OPEN_EVENT_DETAILS_DIALOG: {
      const { dialogConfig, scrollTop, eventLoading } = action.payload;
      return {
        ...state,
        dialogConfig,
        scrollTop,
        eventLoading
      };
    }
    case LOAD_EVENT_PENDING: {
      const { dialogConfig, scrollTop, eventLoading } = action.payload;
      return Object.assign({}, state, {
        [eventLoading]: {
          isFetching: true,
          defaultFields: [],
          otherFields: [],
          buttonConfig: null,
          isWebinar: false,
          tagsField: null,
          calendarItemTagColors: null
        },
        dialogConfig,
        scrollTop,
        eventLoading
      });
    }
    case LOAD_EVENT_FAILURE: {
      return Object.assign({}, state, {
        [action.payload.eventLoading]: {
          isFetching: false,
          defaultFields: [],
          otherFields: [],
          buttonConfig: null,
          isWebinar: false,
          tagsField: null,
          calendarItemTagColors: null
        }
      });
    }
    case LOAD_EVENT_SUCCESS: {
      const { eventFields, eventLoading: eventId } = action.payload;
      const filteredSet = getFilteredButtonConfigAndFields(eventFields);
      return Object.assign({}, state, {
        [eventId]: {
          isFetching: false,
          defaultFields: filteredSet.defaultFields,
          buttonConfig: filteredSet.buttonConfig,
          isWebinar: filteredSet.isWebinar,
          otherFields: filteredSet.otherFields,
          tagsField: filteredSet.tagsField,
          calendarItemTagColors: filteredSet.calendarItemTagColors[0]
        }
      });
    }
    case CLOSE_EVENT_DETAILS_DIALOG: {
      const { dialogConfig, scrollTop, eventLoading } = action.payload;
      return {
        ...state,
        dialogConfig,
        scrollTop,
        eventLoading
      };
    }
    default:
      return state;
  }
}
