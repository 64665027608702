import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import set from 'lodash/set';

function getPseudoStateObject({ valueObject, key, styleKeys }: any) {
  const pseudoStateObject = { ...valueObject[key] };
  styleKeys.forEach((styleKey: any) => {
    if (
      get(pseudoStateObject, styleKey) === undefined &&
      get(valueObject, styleKey) !== undefined
    ) {
      return set(pseudoStateObject, styleKey, get(valueObject, styleKey));
    }
  });
  return isEmpty(pseudoStateObject) ? undefined : { [key]: pseudoStateObject };
}

/**
 * If user hasn't made any changes, values for pseudo states don't exist in the valueObject yet.
 * We copy the values of the default states and add their duplicates under `hover` in the valueObject.
 * The modified valueObject is easier for editor fields and style calculation to work with.
 */
export const getValueObjectWithPseudoState =
  (
    key: any,
    styleKeys = ['text.color', 'background.color', 'background.colorAlpha', 'border.color']
  ) =>
  (valueObject: any) => {
    if (!key || !valueObject) {
      return valueObject;
    }

    let newValueObject = {
      ...valueObject,
      ...getPseudoStateObject({ valueObject, key, styleKeys })
    };

    if (valueObject.customSettings) {
      newValueObject = {
        ...newValueObject,
        customSettings: {
          ...valueObject.customSettings,
          ...getPseudoStateObject({
            valueObject: valueObject.customSettings,
            key,
            styleKeys
          })
        }
      };
    }
    return newValueObject;
  };
