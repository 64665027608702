const prefix = (key: string) => `nucleus-site-editor/comments/${key}`;

export const COMMENTS_LOAD_PENDING = prefix('COMMENTS_LOAD_PENDING');

export const COMMENTS_LOAD_SUCCESS = prefix('COMMENTS_LOAD_SUCCESS');

export const ADD_COMMENT = prefix('ADD_COMMENT');

export const DELETE_COMMENT = prefix('DELETE_COMMENT');

export const DELETE_ALL_WIDGET_COMMENTS = prefix('DELETE_ALL_WIDGET_COMMENTS');

export const DELETE_ALL_SECTION_COMMENTS = prefix('DELETE_ALL_SECTION_COMMENTS');

export const DELETE_ALL_PAGE_COMMENTS = prefix('DELETE_ALL_PAGE_COMMENTS');

export const PRESERVE_DRAFT = prefix('PRESERVE_DRAFT');

export const COMMENTS_LOAD_ERROR = prefix('COMMENTS_LOAD_ERROR');

export const COMMENT_POST_ERROR = prefix('COMMENT_POST_ERROR');

export const COMMENT_DELETE_ERROR = prefix('COMMENT_DELETE_ERROR');

export const COMMENT_RESOLVE_ERROR = prefix('COMMENT_RESOLVE_ERROR');

export const REMOVE_COMMENT_ERROR = prefix('REMOVE_COMMENT_ERROR');

export const UPDATE_COMMENT_ERROR = prefix('UPDATE_COMMENT_ERROR');

export const RESOLVE_COMMENT = prefix('RESOLVE_COMMENT');

export const GET_TEMPLATE_WIDGETS = prefix('GET_TEMPLATE_WIDGETS');

export const STORE_PAGE_LEVEL_COMMENT_COUNTS = prefix('STORE_PAGE_LEVEL_COMMENT_COUNTS');

export const STORE_PAGE_LEVEL_COMMENT_COUNTS_ERROR = prefix(
  'STORE_PAGE_LEVEL_COMMENT_COUNTS_ERROR'
);
