import { SET_SESSION_TIMEOUT_DIALOG_CONFIG } from './constants';

/**
 * Opens the session timeout dialog with the given refresh and end session functions.
 */
export function setSessionTimeoutDialogConfig(config: any) {
  return {
    type: SET_SESSION_TIMEOUT_DIALOG_CONFIG,
    payload: {
      config
    }
  };
}
