/**
 * Creates a copy of the passed in object, deleting any of the
 * specified keys from it.
 * @param {object} object - The object to copy.
 * @param {array} keys - The keys to delete from the copied object.
 * @return {object} - The copied object with the specified keys removed.
 */
export function removeKeys(object: Record<string, any>, keys: string[] = []) {
  const newObject = { ...object };
  keys.forEach(key => {
    if (newObject.hasOwnProperty(key)) {
      delete newObject[key];
    }
  });
  return newObject;
}
