import every from 'lodash/every';

/**
 * Memoize a function using a specific key for caching. Similar to defaultMemoize from reselect except that it can store
 * multiple previous values.
 * @param keyBuilder A function that takes the arguments to the memoized function and returns the key to use for caching
 * @param func The function to memoize
 * @param equalityCheck Optional function to check for equality. Defaults to reference equality
 * @returns A memoized version of the function
 */
export function memoizeOn(keyBuilder: any, func: any, equalityCheck = (a: any, b: any) => a === b) {
  const cache = {};
  return (...args: any[]) => {
    const key = keyBuilder(...args);
    if (cache.hasOwnProperty(key)) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      const { lastValue, lastArgs } = cache[key];
      if (every(args, (arg, i) => equalityCheck(arg, lastArgs[i]))) {
        return lastValue;
      }
    }
    const value = func(...args);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    cache[key] = { lastValue: value, lastArgs: args };
    return value;
  };
}
