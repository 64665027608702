/**
 * A reducer for storing the events to be displayed on the calendar
 */
import Logger from '@cvent/nucleus-logging';
import { RequestBuilder } from '@cvent/nucleus-networking';
import { datadogRum } from '@datadog/browser-rum';
import calendarReducer, { LIST_VIEW_PAGE_SIZE, loadCalendarData } from 'cvent-event-calendar/redux/modules/calendar';
import { loadFilters, reloadFilters } from 'cvent-event-calendar/redux/modules/calendarFilters';
import { setLocale } from 'nucleus-widgets';

import { Action, Criteria, Dispatch } from './types';
import { loadMoreEvents, searchEvents } from '../../clients/CalendarEventsClient';
import { registerTranslations, setUpLogging } from '../../main/appInitUtils';
import {
  // actions for paging (load more) in list view
  LOAD_EVENTS_PENDING,
  LOAD_EVENTS_SUCCESS,
  LOAD_EVENTS_FAILURE,

  // actions for keyword/date search
  SEARCH_EVENTS_PROCESSING,
  SEARCH_EVENTS_SUCCESS,
  SEARCH_EVENTS_FAILURE,
  SEARCH_EVENTS_STILL_PROCESSING,

  // action(s) for search/filter criteria
  UPDATE_CRITERIA,

  // actions for feeding data to month view
  LOADING_MONTH,
  LOAD_MONTH_SUCCESS,
  LOAD_MONTH_FAILURE,
  LOAD_PROPS_PENDING,
  LOAD_PROPS_SUCCESS,
  LOAD_CALENDAR_MIGRATIONS,
  LOAD_GOOGLE_MAP_API_KEY,
  SHOW_SPINNER
} from '../actionTypes';

const ConfigurationsFixture = require('../../../fixtures/CalendarConfigurations.json');

// display spinner if calendar prop endpoint takes more than CALENDAR_SPINNER_TIMEOUT
export const CALENDAR_SPINNER_TIMEOUT = 500;

let searchCounter = 0;

const LOG = new Logger('redux/modules/calendar');

const MILLISECONDS_BEFORE_SHOWING_SPINNER = 500;

let monthsAlreadyLoaded: $TSFixMe = [];
let monthsWithNoEvents: $TSFixMe = [];

function getMonthId(month: Date) {
  // @ts-expect-error TS(2339): Property 'getYear' does not exist on type 'Date'.
  return `${month.getYear()}~${month.getMonth()}`;
}

function hasKeywordOrFilter(criteria: Criteria) {
  return !!criteria.keyword || Object.values(criteria.filters).length > 0;
}

/**
 * Search for events matching the search input
 **/
export function searchCalendarEvents(criteria: Criteria, forMonth: boolean) {
  // @ts-expect-error TS(2304): Cannot find name 'Dispatch'.
  return async (dispatch: Dispatch, getState: GetState) => {
    const { id, config } = getState().calendar;
    dispatch({
      type: UPDATE_CRITERIA,
      payload: { criteria }
    });
    dispatch({
      type: SEARCH_EVENTS_PROCESSING,
      payload: { searchCriteria: criteria, forMonth }
    });
    setTimeout(() => {
      dispatch({
        type: SEARCH_EVENTS_STILL_PROCESSING
      });
    }, MILLISECONDS_BEFORE_SHOWING_SPINNER);
    try {
      LOG.debug('searchEvents', config);
      const searchTracker = ++searchCounter;
      const calendarData = await searchEvents(id, criteria, getState().accessToken, forMonth);
      if (searchTracker < searchCounter) {
        LOG.debug(`skipping a stale search. id: ${id}, criteria: ${JSON.stringify(criteria)}, forMonth: ${forMonth}`);
        return;
      }
      dispatch({
        type: SEARCH_EVENTS_SUCCESS,
        payload: { calendarData, forMonth, currentMonth: criteria.currentMonth }
      });
      // reload filters
      dispatchReloadFilters(dispatch, calendarData);
      // log success
      LOG.debug('searchEvents success');
    } catch (ex) {
      LOG.error('searchEvents failed', ex);
      dispatch({
        type: SEARCH_EVENTS_FAILURE
      });
    }
  };
}

/**
 * Load more calendar events if any
 **/
export function loadMoreCalendarEvents() {
  return async (dispatch: Dispatch, getState: $TSFixMe) => {
    const { searchId, id: calendarId, offset } = getState().calendar;
    dispatch({
      type: LOAD_EVENTS_PENDING
    });
    try {
      LOG.debug('loadMoreEvents', calendarId);
      const calendarData = await loadMoreEvents(calendarId, searchId, offset, getState().accessToken);
      dispatch({
        type: LOAD_EVENTS_SUCCESS,
        payload: { calendarData }
      });
      LOG.debug('loadMoreEvents success');
    } catch (ex) {
      LOG.error('loadMoreEvents failed', ex);
      dispatch({
        type: LOAD_EVENTS_FAILURE
      });
    }
  };
}

async function setupCalendar(
  dispatch: Dispatch,
  calendarConfigurations: $TSFixMe,
  domain: $TSFixMe,
  forWebWidget: $TSFixMe,
  text: $TSFixMe,
  datadogEnvironment: $TSFixMe,
  isWebWidget: $TSFixMe
) {
  const { calendar, translatedAndCustomizedText, latestSite, logUiErrorsToServerEnabled = false, googleMapsApiKey } = calendarConfigurations;

  const { events, searchId, totalCount, id, config } = calendar;
  const calendarCultureCode = config.cultureCode;

  // Create an object to hold the calendar data
  const calendarData = {
    id,
    totalCount,
    events,
    searchId,
    config
  };

  const loggerContext = {
    calendar: {
      id,
      name: config.name,
      version: config.version,
      cultureCode: calendarCultureCode
    }
  };
  setUpLogging(logUiErrorsToServerEnabled, domain, datadogEnvironment, loggerContext, isWebWidget);

  // set google maps api key to state
  dispatch({
    type: LOAD_GOOGLE_MAP_API_KEY,
    payload: { googleMapsApiKey }
  });

  // special handling for calendar websites
  if (!forWebWidget) {
    const { website } = latestSite;
    // set calendar website migrations
    dispatch({
      type: LOAD_CALENDAR_MIGRATIONS,
      payload: { website }
    });
    // Set the document's title
    document.title = config.name;
    // set document language
    document.getElementsByTagName('html')[0].lang = calendarCultureCode.split('-')[0];
  }
  dispatch(loadCalendarData(calendarData));
  dispatch(setLocale(calendarCultureCode));
  await registerTranslations(calendarCultureCode, text.resolver, translatedAndCustomizedText);

  if (config.filterFields) {
    // @ts-expect-error
    dispatch(loadFilters(config.filterFields));
  }

  // calendar loaded successfully, send log to data dog
  datadogRum.addAction('calendar loaded successfully', {
    status: 'Success',
    calendarId: id
  });
}

/**
 * Load calendar props for intial load
 * Gets initial data of the given calendar id
 **/
export function loadInitialCalendar(
  calendarId: $TSFixMe,
  domain: $TSFixMe,
  isFixture: $TSFixMe,
  accessToken: $TSFixMe,
  previewToken: $TSFixMe,
  forMonth: $TSFixMe,
  forWebWidget: $TSFixMe,
  datadogEnvironment: $TSFixMe,
  isWebWidget: $TSFixMe
) {
  return async (dispatch: Dispatch, getState: $TSFixMe) => {
    const preview = previewToken ? `&previewToken=${previewToken}` : '';
    const month = forMonth ? '&forMonth=true' : '';
    let calendarConfigurations;
    if (isFixture) {
      calendarConfigurations = ConfigurationsFixture;
      await setupCalendar(dispatch, calendarConfigurations, domain, forWebWidget, getState().text, datadogEnvironment, isWebWidget);
      dispatch({
        type: LOAD_PROPS_SUCCESS
      });
    } else {
      dispatch({
        type: LOAD_PROPS_PENDING
      });
      try {
        const propsUrl = `${String(domain)}/api/calendar_site_editor/v1/${String(calendarId)}/props?latest=false${preview}${month}`;
        LOG.debug('Load intial props', calendarId);
        const response = await fetch(new RequestBuilder({ url: propsUrl }).auth(`BEARER ${accessToken}`).build());
        if (!response.ok) {
          throw new Error(`Initial calendar load failed: ${String(calendarId)}`);
        }
        calendarConfigurations = await response.json();
        await setupCalendar(dispatch, calendarConfigurations, domain, forWebWidget, getState().text, datadogEnvironment, isWebWidget);
        dispatch({
          type: LOAD_PROPS_SUCCESS
        });
      } catch (e) {
        LOG.error('Calendar website load failed', e);
      }
    }
  };
}

/**
 * Dispatch call to reload filters
 **/
function dispatchReloadFilters(dispatch: Dispatch, calendarData: $TSFixMe) {
  const { config: newConfig } = calendarData;
  if (newConfig) {
    const { filterFields } = newConfig;
    if (filterFields && filterFields.length > 0) {
      // @ts-expect-error
      dispatch(reloadFilters(filterFields));
    }
  }
}

/**
 * Callback for month navigation or load
 * @param month the month to load
 * @param criteria containing the dates and keyword to consider while loading events
 * @param navigating if this is acting as the month navigation callback
 * @param forward when navigating, true indicates forward/next navigation, false indicates backward/back
 * @returns {function(*)}
 */
export function loadMonth(month: Date, criteria: Criteria, navigating: boolean, forward: boolean) {
  // @ts-expect-error TS(2304): Cannot find name 'Dispatch'.
  return async (dispatch: Dispatch, getState: GetState) => {
    const hasUserSearch = hasKeywordOrFilter(criteria);
    // dispatch to let the user know that the month is being loaded by loading the desired month cells
    dispatch({
      type: LOADING_MONTH,
      payload: { month, navigating, hasUserSearch }
    });
    // dispatch to give feedback (waiting spinner) to the user when some time has passed loading events
    setTimeout(() => {
      dispatch({
        type: SEARCH_EVENTS_STILL_PROCESSING
      });
    }, MILLISECONDS_BEFORE_SHOWING_SPINNER);
    try {
      const monthId = getMonthId(month);
      // check if month was already loaded, don't need to call api
      if (!hasUserSearch && monthsAlreadyLoaded.indexOf(monthId) > -1) {
        LOG.debug('month was already loaded, not calling api', month);
        dispatch({
          type: LOAD_MONTH_SUCCESS,
          payload: { criteria }
        });
      } else {
        LOG.debug('loading month', month);
        const calendarData = await searchEvents(getState().calendar.id, criteria, getState().accessToken, true);
        // keep track that data for this month has already been loaded
        if (!hasUserSearch) {
          monthsAlreadyLoaded.push(monthId);
        }
        dispatch({
          type: LOAD_MONTH_SUCCESS,
          payload: { criteria, calendarData, monthId }
        });
        // reload filters
        dispatchReloadFilters(dispatch, calendarData);
        LOG.debug('successfully loaded month', month);
      }
    } catch (ex) {
      LOG.error('failed to load month', ex);
      dispatch({
        type: LOAD_MONTH_FAILURE,
        payload: { navigating, month, forward }
      });
    }
  };
}

/**
 * whether the month has been loaded && it has no events to show
 * @param month
 * @returns {boolean}
 */
export function monthHasNoEvents(month: Date): boolean {
  const monthId = getMonthId(month);
  return monthsWithNoEvents.indexOf(monthId) > -1;
}

/**
 * Reducer to keep track of the information displayed on the calendar
 */
export default function reducer(state = {}, action: Action) {
  switch (action.type) {
    case LOAD_EVENTS_PENDING: {
      return {
        ...state,
        isLoading: true,
        hasErrorLoading: false
      };
    }
    case LOAD_EVENTS_SUCCESS: {
      // @ts-expect-error TS(2339): Property 'calendarData' does not exist on type 'Ob... Remove this comment to see the full error message
      const { calendarData } = action.payload;
      const newEvents = {
        // @ts-expect-error TS(2339): Property 'events' does not exist on type '{}'.
        ...state.events,
        ...calendarData.events
      };

      return {
        ...state,
        // @ts-expect-error TS(2339): Property 'offset' does not exist on type '{}'.
        offset: state.offset + LIST_VIEW_PAGE_SIZE,
        totalEventsCount: calendarData.totalCount,
        events: newEvents,
        isLoading: false,
        hasErrorLoading: false
      };
    }
    case LOAD_EVENTS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        hasErrorLoading: true
      };
    }
    case SEARCH_EVENTS_PROCESSING: {
      // @ts-expect-error TS(2339): Property 'searchCriteria' does not exist on type '... Remove this comment to see the full error message
      const { searchCriteria, forMonth } = action.payload;
      if (forMonth) {
        monthsWithNoEvents = [];
      }
      return {
        ...state,
        // @ts-expect-error TS(2339): Property 'events' does not exist on type '{}'.
        events: forMonth ? {} : state.events, // when user wants to search on something in month, clear out all events
        searchCriteria,
        offset: LIST_VIEW_PAGE_SIZE + 1,
        hasErrorSearching: false,
        hasErrorLoadingMonth: false,
        searchProcessing: true
      };
    }
    case SEARCH_EVENTS_STILL_PROCESSING: {
      return Object.assign({}, state, {
        ...state,
        // @ts-expect-error TS(2339): Property 'searchProcessing' does not exist on type... Remove this comment to see the full error message
        isSearchingPastWaitLimit: state.searchProcessing
      });
    }
    case SEARCH_EVENTS_SUCCESS: {
      // @ts-expect-error TS(2339): Property 'calendarData' does not exist on type 'Ob... Remove this comment to see the full error message
      const { calendarData, forMonth, currentMonth } = action.payload;
      if (forMonth && monthsAlreadyLoaded.length > 0) {
        /* Clear the variable that keeps track of loaded months when search happens
           since it wipes out the entire list of events */
        monthsAlreadyLoaded = [];
      }
      if (forMonth) {
        if (Object.values(calendarData.events).length === 0) {
          monthsWithNoEvents.push(getMonthId(currentMonth));
        } else {
          monthsWithNoEvents = [];
        }
      }
      return {
        ...state,
        searchId: calendarData.searchId,
        totalEventsCount: calendarData.totalCount,
        events: calendarData.events,
        isSearchingPastWaitLimit: false,
        searchProcessing: false
      };
    }
    case SEARCH_EVENTS_FAILURE: {
      return Object.assign({}, state, {
        ...state,
        hasErrorSearching: true,
        isSearchingPastWaitLimit: false,
        searchProcessing: false
      });
    }
    case LOADING_MONTH: {
      // @ts-expect-error TS(2339): Property 'hasUserSearch' does not exist on type 'O... Remove this comment to see the full error message
      const { hasUserSearch } = action.payload;
      if (hasUserSearch) {
        monthsWithNoEvents = [];
      }
      return {
        ...state,
        // @ts-expect-error TS(2339): Property 'events' does not exist on type '{}'.
        events: hasUserSearch ? {} : state.events, // when user navigates with keyword or filter, clear everything
        searchProcessing: true,
        hasErrorSearching: false,
        hasErrorLoadingMonth: false
      };
    }
    case LOAD_MONTH_SUCCESS: {
      // @ts-expect-error TS(2339): Property 'calendarData' does not exist on type 'Ob... Remove this comment to see the full error message
      const { calendarData, monthId } = action.payload;
      const statuses = {
        isSearchingPastWaitLimit: false,
        searchProcessing: false
      };
      if (calendarData) {
        if (Object.values(calendarData.events).length === 0 && monthsWithNoEvents.indexOf(monthId) === -1) {
          monthsWithNoEvents.push(monthId);
        }
        const newUniqueEvents = {
          ...calendarData.events,
          // @ts-expect-error TS(2339): Property 'events' does not exist on type '{}'.
          ...state.events
        };

        return {
          ...state,
          events: newUniqueEvents,
          ...statuses
        };
      }
      return {
        ...state,
        ...statuses
      };
    }
    case LOAD_MONTH_FAILURE: {
      return {
        ...state,
        isSearchingPastWaitLimit: false,
        searchProcessing: false,
        hasErrorSearching: true,
        hasErrorLoadingMonth: true
      };
    }
    case LOAD_PROPS_PENDING: {
      return {
        ...state,
        hasPropsLoaded: false
      };
    }
    case LOAD_PROPS_SUCCESS: {
      return {
        ...state,
        hasPropsLoaded: true
      };
    }
    case SHOW_SPINNER: {
      return {
        ...state,
        displaySpinner: true
      };
    }
    default: {
      return calendarReducer(state, action);
    }
  }
}
