import { PseudoStateStyleMode } from '../../constants';
import {
  PseudoStateValueObject,
  PseudoStateStyleObject,
  PseudoStateStyleCalculator,
  PseudoState
} from './types';
import get from 'lodash/get';
import set from 'lodash/set';
import { AutomaticStyleModeCalculator } from './AutomaticStyleModeCalculator';
import { BrightnessStyleModeCalculator } from './BrightnessStyleModeCalculator';
import { InvertedStyleModeCalculator } from './InvertedStyleModeCalculator';
import { NoStyleModeCalculator } from './NoStyleModeCalculator';

/**
 * The options to pass to `updatePseudoStateStyle`.
 */
export interface UpdatePseudoStateStyleOptions {
  // The prefix of the editor field type to be updated.
  fieldNamePrefix?: string;
  // Maps the set style mode for `pseudoState` to its relevant calculator.
  getCalculator?: (styleMode: PseudoStateStyleMode) => PseudoStateStyleCalculator | undefined;
  // A function to be called to update the value of an editor field.
  onChange: (fieldName: string, value: any) => void;
  // The pseudo-state whose style is to be updated.
  pseudoState: PseudoState;
  // The pseudo-state style properties to be updated.
  styleKeys?: string[];
  // The theme palette from the editor.
  themePalette: Record<string, string>;
  // The valueObject of the styled element.
  valueObject: Partial<PseudoStateValueObject>;
}

const DEFAULT_BRIGHTNESS_MODIFIER_VALUE = 0;
const DEFAULT_STYLE_KEYS = [
  'background.color',
  'background.colorAlpha',
  'border.color',
  'text.color'
];
const DEFAULT_STYLE_MODE_TO_CALCULATOR: Record<
  PseudoStateStyleMode,
  PseudoStateStyleCalculator | undefined
> = {
  [PseudoStateStyleMode.AUTOMATIC]: AutomaticStyleModeCalculator,
  [PseudoStateStyleMode.BRIGHTNESS]: BrightnessStyleModeCalculator,
  [PseudoStateStyleMode.INVERTED]: InvertedStyleModeCalculator,
  [PseudoStateStyleMode.NONE]: NoStyleModeCalculator,
  [PseudoStateStyleMode.CUSTOM]: undefined
};

/**
 * Extracts style values from the given `valueObject`, based on the provided `styleKeys` and `pseudoState`.
 *
 * @param {ValueObject} valueObject - the object that contains the style values for the
 *   base state and all pseudo-states.
 * @param {string[]} styleKeys - An array of style keys to be extracted from the value object.
 * @param {string} [pseudoState] - If provided, the styleKeys for this pseudo-state will be extracted. Otherwise,
 *  the styleKeys for the base state will be extracted.
 * @returns The extracted style values.
 */
function getStyleObject(
  valueObject: Partial<PseudoStateValueObject>,
  styleKeys: string[],
  pseudoState?: string
): Partial<PseudoStateStyleObject> {
  return styleKeys?.reduce((accumulator, styleKey) => {
    const path = pseudoState ? `${pseudoState}.${styleKey}` : styleKey;
    return set(accumulator, styleKey, get(valueObject, path));
  }, {});
}

/**
 * Updates the style of the given pseudo-state, based on the set style mode.
 */
export function updatePseudoStateStyle({
  fieldNamePrefix,
  getCalculator = styleMode => DEFAULT_STYLE_MODE_TO_CALCULATOR[styleMode],
  onChange,
  pseudoState,
  styleKeys = DEFAULT_STYLE_KEYS,
  themePalette,
  valueObject
}: UpdatePseudoStateStyleOptions): void {
  const styleMode = get(
    valueObject,
    [pseudoState, 'styleMode'],
    PseudoStateStyleMode.CUSTOM
  ) as PseudoStateStyleMode;
  if (styleMode === PseudoStateStyleMode.CUSTOM) {
    return;
  }

  const baseStyle = getStyleObject(valueObject, styleKeys);
  const pseudoStateStyle = getStyleObject(valueObject, styleKeys, pseudoState);
  const updatedPseudoStateStyle = getCalculator(styleMode)?.updateStyle(
    baseStyle,
    pseudoStateStyle,
    themePalette,
    {
      brightnessModifier: get(
        valueObject,
        [pseudoState, 'brightnessModifier'],
        DEFAULT_BRIGHTNESS_MODIFIER_VALUE
      )
    }
  );

  if (!updatedPseudoStateStyle || updatedPseudoStateStyle === pseudoStateStyle) {
    return;
  }

  for (const styleKey of styleKeys) {
    const previousStyle = get(pseudoStateStyle, styleKey);
    const updatedStyle = get(updatedPseudoStateStyle, styleKey);
    if (previousStyle !== updatedStyle) {
      onChange(`${fieldNamePrefix}.${pseudoState}.${styleKey}`, updatedStyle);
    }
  }
}
