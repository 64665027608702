/**
 * Formats a date string into a javascript date object. (Only month/day/year or day/month/year formats)
 *
 * @param dateString {string} The date to format. If null, this function will throw an exception.
 * @param format {string} optional. The format string. Defaults to assuming a month/day/year format.
 *      mm = month with 0, dd = day with 0, yy = 4 digit year, M = month, D = day, Y = 2 digit year.
 *      The year part must come at the last.
 *      The three parts (day, month, year) must be separated by either a slash (/) or a dash (-).
 *      Slashes and dashes cannot be mixed (e.g. mm-dd/yy is invalid).
 *      Examples of valid format: mm-dd-yy, dd/mm/yy, D/M/Y, M/D/Y.
 * @return {Object} The date properly formatted as a string as per the format provided. Will return
 *      null if the input is deemed to be invalid.
 */
export default function getDateFromString(dateString, format) {
  let inputStringArray = dateString.split('/');
  if (inputStringArray.length !== 3) {
    inputStringArray = dateString.split('-');
  }

  // Make sure there are three parts to the date, and make sure the year is either 2 or 4 digits.
  if (
    inputStringArray.length !== 3 ||
    (inputStringArray[2].length !== 2 && inputStringArray[2].length !== 4)
  ) {
    return null;
  }

  let monthIndex = 0;
  let dayIndex = 1;
  if (format && (format.indexOf('D') === 0 || format.indexOf('dd') === 0)) {
    monthIndex = 1;
    dayIndex = 0;
  }

  const month = Number(inputStringArray[monthIndex]);
  const day = Number(inputStringArray[dayIndex]);
  let year = Number(inputStringArray[2]);

  // Make sure the month, day, and year exist.
  if (!month || !day || !year) {
    return null;
  }

  // Adjust two digit years if necessary.
  if (year < 50) {
    year += 2000;
  } else if (year < 100) {
    year += 1900;
  }

  // Create the date object.
  const newDate = new Date(year, month - 1, day);

  // Checks to see if month and day are valid.
  if (newDate.getDate() !== day || newDate.getMonth() !== month - 1) {
    return null;
  }

  return newDate;
}
