// @ts-expect-error version difference
import IntlMessageFormat from 'intl-messageformat';
import sanitizeText from './sanitize/sanitizeText';
import sanitizeInnerHtml from './sanitize/sanitizeInnerHtml';
import getDataTagResolutions from './util/getDataTagResolutions';
import getAllRegExpMatches from './util/getAllRegExpMatches';
import escapeTextInObject from './util/escapeTextInObject';
import encodeHtml from './util/encodeHtml';
import DATA_TAG_REG_EXP from '@cvent/nucleus-datatag-regex';
import { SupportedLocales } from './types/supportedLocalizationTypes';

/** Optional options that can be overridden when calling new IntlMessageFormat() **/
export interface TextResolverFormats {
  number: Record<string, Intl.NumberFormatOptions>;
  date: Record<string, Intl.DateTimeFormatOptions>;
  time: Record<string, Intl.DateTimeFormatOptions>;
}

// Translations
const _translations: Record<string, Partial<Record<SupportedLocales, Record<string, string>>>> = {};

// DataTags
const _dataTags: Record<string, Partial<Record<SupportedLocales, Record<string, string>>>> = {};
const _dataTagsNeeded: Record<string, Partial<Record<SupportedLocales, string[]>>> = {};
const _dataTagsRetrieving: Record<string, Partial<Record<SupportedLocales, string[]>>> = {};

function resolutionNotFound(_context: string, _local: SupportedLocales, _dataTag: string) {
  return '';
}

/**
 * There is inconsistent browser support for date/time formatting
 * for traditional and simplified chinese language code.
 * This function is to switch to their equivalents if the provided code isn't supported.
 * @param {string} locale
 */
function switchLocale(locale: SupportedLocales) {
  let newLocale: SupportedLocales;
  switch (locale.toLowerCase()) {
    case 'zh-cht':
      newLocale = 'zh-hant';
      break;
    case 'zh-chs':
      newLocale = 'zh-hans';
      break;
    case 'zh-hant':
      newLocale = 'zh-cht';
      break;
    case 'zh-hans':
      newLocale = 'zh-chs';
      break;
    default:
      newLocale = locale;
  }
  return newLocale;
}
function _encodeTokens(tokens: any) {
  const encodedTokens: { [key: string]: any } = {};
  for (const key in tokens) {
    if (tokens.hasOwnProperty(key)) {
      if (!tokens[key]) {
        encodedTokens[key] = tokens[key];
      } else if (tokens[key].encodeHtml) {
        encodedTokens[key] = encodeHtml(tokens[key].value);
      } else {
        encodedTokens[key] = tokens[key].value || tokens[key];
      }
    }
  }
  return encodedTokens;
}
function _resolveLegacyTokens(text: string) {
  let resolvedText = text;
  const matches = text.match(/\[\&(\S)*\]/g);
  if (matches && matches.length) {
    matches.forEach((match: any) => {
      const replacedMatch = match.replace('[', '{').replace(']', '}');
      resolvedText = resolvedText.replace(match, replacedMatch);
    });
  }
  return resolvedText;
}
function _resolveNestedKeys(text: string, tokens: any, callCount: any, translateFunction: any) {
  let resolvedText = text;
  const matches = text.match(/{\$(\S)*}/g);
  if (matches && matches.length) {
    matches.forEach((match: any) => {
      resolvedText = resolvedText.replace(
        match,
        translateFunction(match.substring(2, match.length - 1), tokens, callCount + 1)
      );
    });
  }
  return resolvedText;
}
function _resolveDataTag(
  dataTag: string,
  context: string,
  locale: SupportedLocales,
  dataTagFallback: (context: string, locale: SupportedLocales, dataTag: string) => string
) {
  const resolution = _dataTags[context]?.[locale]?.[dataTag];
  if (resolution === undefined) {
    if (!_dataTagsNeeded[context][locale]?.includes(dataTag)) {
      _dataTagsNeeded[context][locale]?.push(dataTag);
    }
    return dataTagFallback(context, locale, dataTag);
  }
  return resolution;
}
function _initializeContextAndLocale(context: string, locale: SupportedLocales) {
  // Initialize translation objects.
  if (!_translations[context]) {
    _translations[context] = {};
  }
  if (!_translations[context][locale]) {
    _translations[context][locale] = {};
  }
  // Initialize data tag objects.
  if (!_dataTags[context]) {
    _dataTags[context] = { [locale]: {} };
  }
  if (!_dataTagsNeeded[context]) {
    _dataTagsNeeded[context] = {};
  }
  if (!_dataTagsRetrieving[context]) {
    _dataTagsRetrieving[context] = {};
  }
  if (!_dataTags[context][locale]) {
    _dataTags[context][locale] = {};
  }
  if (!_dataTagsNeeded[context][locale]) {
    _dataTagsNeeded[context][locale] = [];
  }
  if (!_dataTagsRetrieving[context][locale]) {
    _dataTagsRetrieving[context][locale] = [];
  }
}
function _subtractLists(allTags: string[], tagsToRemove: string[]) {
  return allTags.filter(it => !tagsToRemove.includes(it));
}
function _getDatatagsForFetchCall(context: string, locale: SupportedLocales) {
  return _subtractLists(
    _dataTagsNeeded[context][locale] ?? [],
    _dataTagsRetrieving[context][locale] ?? []
  );
}
function _getDatatagsForFetchAllCall(context: string, locale: SupportedLocales) {
  const dataTags = Object.keys(_dataTags[context][locale] || {});
  const missingTags = _dataTagsNeeded[context][locale] ?? [];

  const distictTags = Array.from(new Set(dataTags.concat(missingTags)));

  return distictTags;
}

type TextResolverProps = {
  /**
   * Locale associated with this instance.
   * @default 'en-US'
   */
  locale?: SupportedLocales;
  /**
   * Context associated with this instance. Intended to be used for applications that have multiple entities that
   * have separate translations/data tag resolutions/etc (for example, multiple events at once)
   * @default 'default'
   */
  context?: string;
  /**
   * Max number of nested translations to resolve when performing a text translation (keys within keys within keys).
   * Defaults to 10
   * @default 10
   */
  translationMaxNestedCallCount?: number;
  /**
   * Callback function to execute if the specified context/locale/key is not registered for translation.
   * The return value will be used as the translated text. By default, returns empty string.
   * @default resolutionNotFound
   */
  translationFallback?: Function;
  /**
   * Regular expression to match on any substrings in a translation that contain characters that must
   * be escaped. Defaults to the form /{\[[A-Z0-9/\-\s]+(:.*)?\]}/g (the default data tag form).
   * @default DATA_TAG_REG_EXP
   */
  translationEscapeRegExp?: RegExp;
  /**
   * An array of characters (strings) that need to be escaped in the text matches from translationEscapeRegExp.
   * @default "['{', '}']"
   */
  translationCharactersToEscape?: string[];
  /**
   * The character (string) to escape the translationCharactersToEscape with.
   * @default '\\'
   */
  translationEscapeCharacter?: string;
  /**
   * Number string formatting options for the Intl.NumberFormat API
   * See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
   * @default {}
   */
  numberFormatOptions?: object;
  /**
   * Currency code to use when formatting currency. Must match a value in the ISO 4217 currency codes.
   * See http://www.currency-iso.org/en/home/tables/table-a1.html
   * @default 'USD'
   */
  currencyCode?: string;
  /**
   * Default format for the date formatting. Possible Values: [ short, medium, long, full ]
   * @type {string}
   * OR
   * DateTime formatting options for the Intl.DateTimeFormat
   * See https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @default 'short'
   * @type {string}
   */
  dateFormat?: string;
  /**
   * Default format for the time formatting. Possible Values: [ short, medium, long, full ]
   * @default 'short'
   */
  timeFormat?: 'short' | 'medium' | 'long' | 'full';
  /**
   * Default format for the DateTime formatting. Possible Values: [ LT, LTS, l, LL, ll, LLL, lll, LLLL ]
   * Note: these formats mirror the possible localized formats from moment.js
   *   http://momentjs.com/docs/#/displaying/format/
   * @default 'lll'
   * @type {string}
   */
  dateTimeFormat?: string;
  /**
   * Overrides default formatting options listed here: https://github.com/formatjs/intl-messageformat/blob/master/src/core.ts#L111
   * Only used when calling new IntlMessageFormat() inside resolveTranslation function at the moment.
   * This could also be provided to all new IntlMessageFormat() calls being made in TextResolver class, if needed.
   * @default {}
   * @type {Partial<TextResolverFormats>}
   */
  overrideFormats?: Partial<TextResolverFormats>;
  /**
   * Callback function to execute if the specified context/locale/key is not registered for data tags.
   * The return value will be used as the data tag text. By default, returns empty string.
   * @type {function}
   * @default {resolutionNotFound}
   */
  dataTagFallback?: (context: string, locale: SupportedLocales, dataTag: string) => string;
  /**
   * Function to call to load new data tags.
   *  dataTagMakeServiceCall(dataTags, successCallback, failureCallback, context, locale, dataTagFormatRegExp)
   *    dataTags - array of data tags to get resolutions for.
   *    options - an object of additional parameters:
   *      successCallback - callback to fire on successful load of data tags. Takes a single parameter
   *        containing an array of the resolved text (in the same order as the passed in dataTags parameter).
   *      failureCallback - callback to fire on failed load of data tags. Takes a single error parameter.
   *      context - generally to be used to identify the entity you are resolving tags against in your service.
   *      locale - generally to be used to identify what language you are resolving tags against in your service.
   *      dataTagFormatRegExp - use if your service supports configuring this.
   *      translate - Text translation function. Generally used inside of the dataTagMakeServiceCall function if its
   *        implemented to return example resolutions. When calling an actual datatag service to get real resolutions,
   *        we would generally expect the service to use the locale property to return the correct text instead.
   * @type {function}
   */
  dataTagMakeServiceCall?: (dataTags: string[], options?: any) => void;
  /**
   * Optional function called on success of the dataTagMakeServiceCall function.
   * Takes a single parameter of the new data tag mappings.
   * @type {function}
   */
  dataTagFetchSuccessCallback?: (dataTagMappings?: { [key: string]: string }) => void;
  /**
   * Optional function called on failure of the dataTagMakeServiceCall function.
   * Takes a single error parameter.
   * @type {function}
   */
  dataTagFetchFailureCallback?: (error?: Error, dataTags?: string[]) => void;
  /**
   * Regular expression to match on any data tags contained in a string.
   * @type {RegExp}
   * @default {/{\[[A-Z0-9/\-\s]+(:.*)?\]}/g}
   */
  dataTagFormatRegExp?: RegExp;
  /**
   * Regular Expression to match on any logic tag expression present in the text.
   * @type {RegExp}
   */
  logicTagRegularExpression?: RegExp;
  /**
   * Example text to be replaced for logic tag expression on email editor.
   * @type {string}
   */
  logicTagExampleText?: string;
  /**
   * When `true`, resolveTranslation returns raw translation with placeholder intact when no placeholder value is provided.
   * @type {boolean}
   */
  skipPlaceholderResolution?: boolean;
};

/**
 * TextResolver class for handling text replacement / formatting tasks. Currently supports:
 * translations,
 * data tag replacement,
 * text sanitization,
 * currency formatting,
 * date formatting,
 * time formatting,
 * dateTime formatting
 *
 * Use of the TextResolver functions requires the browser to contain the Intl package.
 * Intl is not present in IE < 11 and Safari, so for those browsers (or any others that
 * dont have the package), Intl (and any necessary locale data) must be polyfilled into
 * your application. See https://github.com/andyearnshaw/Intl.js
 *
 * NOTE: We have seen some slight differences in how number and date/time formatting is implemented for different
 *    browsers. If we need completely consistent behavior in all browsers, then you could alternatively ALWAYS include
 *    the Intl polyfill, and replace the default browser formatters with the polyfill formatters. This isnt really
 *    desireable because of the size of the Intl polyfill (around 30kb).
 */
export default class TextResolver {
  public static memoizedResolveDataTagsResponse: Partial<
    Record<SupportedLocales, Record<string, string>>
  > = {};

  public locale: SupportedLocales;
  public context: string;
  public currencyCode: string;
  public dataTagFallback: (context: string, locale: SupportedLocales, dataTag: string) => string;
  public dataTagFetchFailureCallback?: any;
  public dataTagFetchSuccessCallback?: any;
  public dataTagFormatRegExp: RegExp;
  public dataTagMakeServiceCall?: any;
  public dateFormat?: 'short' | 'medium' | 'long' | 'full' | string;
  public dateTimeFormat?: 'lll' | string;
  public logicTagExampleText?: any;
  public logicTagRegularExpression?: any;
  public numberFormatOptions?: any;
  public timeFormat?: any;
  public overrideFormats?: Partial<TextResolverFormats>;
  public translationCharactersToEscape?: any;
  public translationEscapeCharacter?: any;
  public translationEscapeRegExp?: any;
  public translationFallback?: any;
  public translationMaxNestedCallCount?: any;
  public skipPlaceholderResolution?: boolean;
  /**
   * Creates a new instance of TextResolver with the provided options set as member variables.
   */
  constructor({
    // Globally used params
    locale = 'en-US',
    context = 'default',
    // Translation Params
    translationMaxNestedCallCount = 10,
    translationFallback = resolutionNotFound as (...args: any[]) => string,
    translationEscapeRegExp = DATA_TAG_REG_EXP,
    translationCharactersToEscape = ['{', '}'],
    translationEscapeCharacter = '\\',
    // Formatting params
    numberFormatOptions = {},
    currencyCode = 'USD',
    dateFormat = 'short',
    timeFormat = 'short',
    dateTimeFormat = 'lll',
    overrideFormats = {},
    // DataTag params
    dataTagFallback = resolutionNotFound,
    dataTagMakeServiceCall = (..._args: any[]): void => {
      return;
    },
    dataTagFetchSuccessCallback = (..._args: any[]): void => {
      return;
    },
    dataTagFetchFailureCallback = (..._args: any[]): void => {
      return;
    },
    dataTagFormatRegExp = DATA_TAG_REG_EXP,
    logicTagRegularExpression = undefined,
    logicTagExampleText = '',
    skipPlaceholderResolution = false
  }: TextResolverProps) {
    this.locale = locale as SupportedLocales;
    this.context = context;
    this.translationMaxNestedCallCount = translationMaxNestedCallCount;
    this.translationFallback = translationFallback;
    this.translationEscapeRegExp = translationEscapeRegExp;
    this.translationCharactersToEscape = translationCharactersToEscape;
    this.translationEscapeCharacter = translationEscapeCharacter;
    this.numberFormatOptions = numberFormatOptions;
    this.currencyCode = currencyCode;
    this.dateFormat = dateFormat;
    this.timeFormat = timeFormat;
    this.dateTimeFormat = dateTimeFormat;
    this.overrideFormats = overrideFormats;
    this.dataTagFallback = dataTagFallback;
    this.dataTagMakeServiceCall = dataTagMakeServiceCall;
    this.dataTagFetchSuccessCallback = dataTagFetchSuccessCallback;
    this.dataTagFetchFailureCallback = dataTagFetchFailureCallback;
    this.dataTagFormatRegExp = dataTagFormatRegExp;
    this.logicTagRegularExpression = logicTagRegularExpression;
    this.logicTagExampleText = logicTagExampleText;
    this.skipPlaceholderResolution = skipPlaceholderResolution;

    // Bind functions.
    this.copy = this.copy.bind(this);
    this.setContext = this.setContext.bind(this);
    this.setLocale = this.setLocale.bind(this);
    this.registerTranslations = this.registerTranslations.bind(this);
    this.registerDataTags = this.registerDataTags.bind(this);
    this.resolveTranslation = this.resolveTranslation.bind(this);
    this.resolveDataTags = this.resolveDataTags.bind(this);
    this.resolveLogicTags = this.resolveLogicTags.bind(this);
    this.resolveText = this.resolveText.bind(this);
    this.resolveSanitizedTranslation = this.resolveSanitizedTranslation.bind(this);
    this.resolveSanitizedDataTags = this.resolveSanitizedDataTags.bind(this);
    this.resolveSanitizedText = this.resolveSanitizedText.bind(this);
    this.resolveInnerHtmlTranslation = this.resolveInnerHtmlTranslation.bind(this);
    this.resolveInnerHtmlDataTags = this.resolveInnerHtmlDataTags.bind(this);
    this.resolveInnerHtmlText = this.resolveInnerHtmlText.bind(this);
    this.number = this.number.bind(this);
    this.currency = this.currency.bind(this);
    this.date = this.date.bind(this);
    this.time = this.time.bind(this);
    this.dateTime = this.dateTime.bind(this);
    this.fetchDataTags = this.fetchDataTags.bind(this);
    this.fetchAllDataTags = this.fetchAllDataTags.bind(this);
    this.shouldFetchDataTags = this.shouldFetchDataTags.bind(this);
    this.shouldFetchAllDataTags = this.shouldFetchAllDataTags.bind(this);

    // Initialize objects.
    this.initializeMemoizedResolveDataTagsResponse();
    _initializeContextAndLocale(this.context, this.locale);
  }

  /**
   * Creates a new instance of TextResolver with the same properties as this instance.
   */
  copy() {
    return new TextResolver({
      locale: this.locale,
      context: this.context,
      translationMaxNestedCallCount: this.translationMaxNestedCallCount,
      translationFallback: this.translationFallback,
      translationEscapeRegExp: this.translationEscapeRegExp,
      translationCharactersToEscape: this.translationCharactersToEscape,
      translationEscapeCharacter: this.translationEscapeCharacter,
      currencyCode: this.currencyCode,
      dateFormat: this.dateFormat,
      timeFormat: this.timeFormat,
      dateTimeFormat: this.dateTimeFormat,
      overrideFormats: this.overrideFormats,
      dataTagFallback: this.dataTagFallback,
      dataTagMakeServiceCall: this.dataTagMakeServiceCall,
      dataTagFetchSuccessCallback: this.dataTagFetchSuccessCallback,
      dataTagFetchFailureCallback: this.dataTagFetchFailureCallback,
      dataTagFormatRegExp: this.dataTagFormatRegExp,
      logicTagRegularExpression: this.logicTagRegularExpression,
      logicTagExampleText: this.logicTagExampleText
    });
  }

  /**
   * Changes the context of the TextResolver and initializes the associated
   * objects if needed.
   */
  setContext(context: any) {
    this.context = context;
    _initializeContextAndLocale(this.context, this.locale);
  }

  /**
   * Changes the locale of the TextResolver and initializes the associated
   * objects if needed.
   */
  setLocale(locale: SupportedLocales) {
    this.locale = locale;
    this.initializeMemoizedResolveDataTagsResponse();
    _initializeContextAndLocale(this.context, this.locale);
  }

  /**
   * Register translations with the global instance.
   * @param {object} translations - object of translations (must be flat).
   * @param {string} contextOverride - context to use instead of the context of this instance.
   * @param {SupportedLocales} localeOverride - locale to use instead of the locale of this instance.
   */
  registerTranslations(
    translations: any,
    contextOverride?: string,
    localeOverride?: SupportedLocales
  ) {
    const context = contextOverride || this.context;
    const locale = localeOverride || this.locale;
    const escapedTranslations = escapeTextInObject(
      translations,
      this.translationCharactersToEscape,
      this.translationEscapeCharacter,
      this.translationEscapeRegExp
    );
    const newTranslations = {
      ...(_translations?.[context]?.[locale] || {}),
      ...escapedTranslations
    };
    _translations[context][locale] = newTranslations;
  }

  /**
   * Register data tag resolutions with the global instance.
   * @param {object} dataTags - object of dataTags (must be flat).
   * @param {string} contextOverride - context to use instead of the context of this instance.
   * @param {string} localeOverride - locale to use instead of the locale of this instance.
   */
  registerDataTags(
    dataTags: Record<string, string>,
    contextOverride?: string,
    localeOverride?: SupportedLocales
  ) {
    const context = contextOverride || this.context;
    const locale = localeOverride || this.locale;
    _dataTags[context][locale] = { ..._dataTags[context][locale], ...dataTags };
  }

  /**
   * Resolve the specified key and tokens to its registered translation.
   * See http://formatjs.io/guides/message-syntax/ for possible formatting options.
   * @param {string} key - the localization key to use.
   * @param {object} tokens - object specifying values for any tokens in the localized message.
   *   object should be in the form { key: value } or { key: { value: value, encodeHtml: true } }
   * @param {function} fallbackOverride - Callback function to execute if the specified context/locale/key is not
   *   registered for translation. The return value will be used as the translated text. By default, returns
   *   empty string.
   * @param {number} _callCount - Internal field to keep track of how many times translate has been called
   *   on nested translation keys. (Shoudln't be used by external caller)
   * @param {ResolveTranslationOptions} options - Optional object to pass in additional options.
   * @returns {string} - the translated text string.
   */
  resolveTranslation(key: any, tokens?: any, fallbackOverride?: any, _callCount = 1) {
    if (_callCount > this.translationMaxNestedCallCount) {
      return '';
    }
    const value = _translations[this.context]?.[this.locale]?.[key];
    if (typeof value !== 'string') {
      const fallback = fallbackOverride || this.translationFallback;
      return fallback(this.context, this.locale, key, tokens);
    }
    const filteredTokens = { ...tokens };
    for (const obj in filteredTokens) {
      if (filteredTokens[obj] === 0) {
        filteredTokens[obj] = '0';
      }
    }
    const encodedTokens = _encodeTokens(filteredTokens);
    let text = _resolveLegacyTokens(value);
    text = _resolveNestedKeys(text, encodedTokens, _callCount, this.resolveTranslation);
    if (this.skipPlaceholderResolution && (!tokens || Object.keys(tokens).length === 0)) {
      return text;
    }
    let message;
    try {
      message = new IntlMessageFormat(text, this.locale, this.overrideFormats);
    } catch (error) {
      try {
        // Normal fallback with switch locale for zh-CHS and zh-CHT
        message = new IntlMessageFormat(text, switchLocale(this.locale), this.overrideFormats);
      } catch (nestedError) {
        // Normal fallback failed, to prevent hard error, escape syntax characters see: (https://github.com/formatjs/intl-messageformat/tree/v1.2.0#features)
        let escapedText = text.replace(/\{/gm, '\\{');
        escapedText = escapedText.replace(/\}/gm, '\\}');
        message = new IntlMessageFormat(escapedText, this.locale, this.overrideFormats);
        return message.format(encodedTokens);
      }
    }
    return message.format(encodedTokens);
  }

  /**
   * Resolve all data tags in a text string to their registered data tag mappings.
   * @param {string} text - the text string that may contain data tags to resolve.
   * @param {func} fallbackOverride - Callback function to execute if the specified context/locale/key is not
   *   registered for data tags. The return value will be used as the data tag text. By default, returns empty string.
   * @returns {string} the data tag resolved text string.
   */

  resolveDataTags(text: string, fallbackOverride?: any): string {
    const resolution = TextResolver.memoizedResolveDataTagsResponse[this.locale]?.[text];
    if (resolution === undefined) {
      const fallback = fallbackOverride || this.dataTagFallback;
      const dataTags = getAllRegExpMatches(this.dataTagFormatRegExp, text) as string[];
      if (!dataTags.length) {
        return text;
      }

      let resolved = true;
      const newText = dataTags.reduce((acc, dataTag) => {
        const resolvedDataTags = _resolveDataTag(dataTag, this.context, this.locale, fallback);
        resolved &&= !!resolvedDataTags;
        return acc.replace(dataTag, resolvedDataTags);
      }, text);

      if (resolved) {
        TextResolver.memoizedResolveDataTagsResponse[this.locale] = {
          ...TextResolver.memoizedResolveDataTagsResponse[this.locale],
          [text]: newText
        };
      }
      return newText;
    }
    return resolution;
  }

  /**
   * Resolve all logic tag expressions in a logic tag example text string.
   *
   * @param {text} - the text string that may contain logic tag expressions to resolve.
   * @returns {string} - the logic tag expression resolved text string.
   */
  resolveLogicTags(text: any) {
    let newText = text;
    if (this.logicTagRegularExpression) {
      const logicTags = getAllRegExpMatches(this.logicTagRegularExpression, text);
      logicTags.forEach(logicTag => {
        newText = newText.replace(logicTag, this.logicTagExampleText);
      });
    }
    return newText;
  }

  /**
   * Wrapper around the resolveTranslation (Translator) and resolveDataTags (DataTagResolver) functions.
   * Translate fires first, and then resolveText is executed on the result.
   * @param {string} key - the localization key to use.
   * @param {object} tokens - object specifying values for any tokens in the localized message.
   * @param {function} translationFallbackOverride - Callback function to execute if the specified context/locale/key
   *   is not registered for translation. The return value will be used as the translated text. By default, returns
   *   empty string.
   * @param {function} dataTagFallbackOverride - Callback function to execute if the specified context/locale/key is not
   *   registered for data tags. The return value will be used as the data tag text. By default, returns empty string.
   * @returns {string} - the fully resolved text string.
   */
  resolveText(
    key: any,
    tokens?: any,
    translationFallbackOverride?: any,
    dataTagFallbackOverride?: any
  ) {
    const translation = this.resolveTranslation(key, tokens, translationFallbackOverride);
    const translatedText = this.resolveDataTags(translation, dataTagFallbackOverride);
    return this.logicTagExampleText && this.logicTagRegularExpression
      ? this.resolveLogicTags(translatedText)
      : translatedText;
  }

  /**
   * Wrapper around the resolveTranslation function that also puts the output text string through sanitization.
   * @returns {string} - the fully resolved and sanitized text.
   */
  resolveSanitizedTranslation(key: any, tokens: any, translationFallbackOverride: any) {
    const resolvedTranslation = this.resolveTranslation(key, tokens, translationFallbackOverride);
    return sanitizeText(resolvedTranslation);
  }
  /**
   * Wrapper around the resolveDataTags function that also puts the output text string through sanitization.
   * @returns {string} - the fully resolved and sanitized text.
   */
  resolveSanitizedDataTags(text: any, dataTagFallbackOverride: any) {
    const resolvedDataTags = this.resolveDataTags(text, dataTagFallbackOverride);
    return sanitizeText(resolvedDataTags);
  }
  /**
   * Wrapper around the resolveText function that also puts the output text string through sanitization.
   * @returns {string} - the fully resolved and sanitized text.
   */
  resolveSanitizedText(
    key: any,
    tokens?: any,
    translationFallbackOverride?: any,
    dataTagFallbackOverride?: any
  ) {
    const resolvedText = this.resolveText(
      key,
      tokens,
      translationFallbackOverride,
      dataTagFallbackOverride
    );
    return sanitizeText(resolvedText);
  }

  /**
   * Wrapper around the resolveTranslation function that also puts the output text string through sanitization
   * and into an inner html object to be used with React's dangerouslySetInnerHTML property.
   * @returns {object} - an object with an '__html' field containing the fully resolved and sanitized text.
   */
  resolveInnerHtmlTranslation(key: any, tokens: any, translationFallbackOverride: any) {
    const resolvedTranslation = this.resolveTranslation(key, tokens, translationFallbackOverride);
    return sanitizeInnerHtml(resolvedTranslation);
  }
  /**
   * Wrapper around the resolveDataTags function that also puts the output text string through sanitization
   * and into an inner html object to be used with React's dangerouslySetInnerHTML property.
   * @returns {object} - an object with an '__html' field containing the fully resolved and sanitized text.
   */
  resolveInnerHtmlDataTags(text: any, dataTagFallbackOverride: any) {
    const resolvedDataTags = this.resolveDataTags(text, dataTagFallbackOverride);
    return sanitizeInnerHtml(resolvedDataTags);
  }
  /**
   * Wrapper around the resolveText function that also puts the output text string through sanitization
   * and into an inner html object to be used with React's dangerouslySetInnerHTML property.
   * @returns {object} - an object with an '__html' field containing the fully resolved and sanitized text.
   */
  resolveInnerHtmlText(
    key: any,
    tokens?: any,
    translationFallbackOverride?: any,
    dataTagFallbackOverride?: any
  ) {
    const resolvedText = this.resolveText(
      key,
      tokens,
      translationFallbackOverride,
      dataTagFallbackOverride
    );
    return sanitizeInnerHtml(resolvedText);
  }

  /**
   * Do generic number string formatting. See the following for the Intl.NumberFormat options API:
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
   * @param {number} value - the number value to display.
   * @param {object} optionsOverride - number format options that, if provided, override those on the class instance.
   * @returns {string} - the formatted number string.
   */
  number(value: any, optionsOverride?: any) {
    const options = optionsOverride || this.numberFormatOptions;
    const format = {
      number: {
        custom: {
          style: 'decimal',
          ...options
        }
      }
    };

    let message;
    try {
      message = new IntlMessageFormat('{value, number, custom}', this.locale, format);
    } catch (error) {
      message = new IntlMessageFormat('{value, number, custom}', switchLocale(this.locale), format);
    }

    return message.format({ value });
  }

  /**
   * Convenience method for the conventional currency formatting case. If you
   * need more fine grained control, use the number method directly.
   * @param {number} value - the currency value to display.
   * @param {string} currencyCodeOverride - must match a value in the ISO 4217 currency codes.
   *   See http://www.currency-iso.org/en/home/tables/table-a1.html
   * @returns {string} - the formatted currency string.
   */
  currency(value: any, currencyCodeOverride?: any) {
    const currencyCode = currencyCodeOverride || this.currencyCode;
    return this.number(value, { style: 'currency', currency: currencyCode });
  }

  /**
   * Do Date string formatting.
   * @param {Date} value - the date value to display.
   * @param {string} formatOverride - format for the date. Possible Values: [ short, medium, long, full ]
   * * Or {object} - DateTime formatting options for the Intl.DateTimeFormat. See the following for options:
   * * https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
   * @returns {string} - the translated date string.
   */
  date(value: any, formatOverride: any) {
    // Format override for any german locale to High German as per Cvent standards.
    const locale = this.locale.toLowerCase().includes('de-') ? 'de-DE' : this.locale;
    const format = formatOverride || this.dateFormat;
    if (typeof format === 'string') {
      let message;
      try {
        message = new IntlMessageFormat(`{value, date, ${format}}`, locale);
      } catch (error) {
        message = new IntlMessageFormat(`{value, date, ${format}}`, switchLocale(locale));
      }
      return message.format({ value });
    }

    let newDate;
    try {
      newDate = new Intl.DateTimeFormat(locale, format).format(value);
    } catch (error) {
      newDate = new Intl.DateTimeFormat(switchLocale(locale), format).format(value);
    }
    return newDate;
  }

  /**
   * Do Time string formatting.
   * @param {Date} value - the time value to display.
   * @param {string} formatOverride - format for the time. Possible Values: [ short, medium, long, full ]
   * @returns {string} - the translated time string.
   */
  time(value: any, formatOverride: any) {
    const format = formatOverride || this.timeFormat;
    let message;
    try {
      message = new IntlMessageFormat(`{value, time, ${format}}`, this.locale);
    } catch (error) {
      message = new IntlMessageFormat(`{value, time, ${format}}`, switchLocale(this.locale));
    }

    return message.format({ value });
  }

  /**
   * Do DateTime string formatting.
   * @param {Date} value - the DateTime value to display.
   * @param {string} formatOverride - format for the DateTime. Possible Values: [ LT, LTS, l, LL, ll, LLL, lll, LLLL ]
   *    Note: these formats mirror the possible localized formats from
   *    moment.js http://momentjs.com/docs/#/displaying/format/
   * @returns {string} - the translated date time string.
   */
  dateTime(value: any, formatOverride: any) {
    const format = formatOverride || this.dateTimeFormat;
    switch (format) {
      case 'LT': {
        let LT;
        try {
          LT = new IntlMessageFormat('{value, time, short}', this.locale);
        } catch (error) {
          LT = new IntlMessageFormat('{value, time, short}', switchLocale(this.locale));
        }
        return LT.format({ value });
      }
      case 'LTS': {
        let LTS;
        try {
          LTS = new IntlMessageFormat('{value, time, medium}', this.locale);
        } catch (error) {
          LTS = new IntlMessageFormat('{value, time, medium}', switchLocale(this.locale));
        }
        return LTS.format({ value });
      }
      case 'l': {
        let l;
        try {
          l = new IntlMessageFormat('{value, date, short}', this.locale);
        } catch (error) {
          l = new IntlMessageFormat('{value, date, short}', switchLocale(this.locale));
        }
        return l.format({ value });
      }
      case 'LL': {
        let LL;
        try {
          LL = new IntlMessageFormat('{value, date, long}', this.locale);
        } catch (error) {
          LL = new IntlMessageFormat('{value, date, long}', switchLocale(this.locale));
        }
        return LL.format({ value });
      }
      case 'll': {
        let ll;
        try {
          ll = new IntlMessageFormat('{value, date, medium}', this.locale);
        } catch (error) {
          ll = new IntlMessageFormat('{value, date, medium}', switchLocale(this.locale));
        }
        return ll.format({ value });
      }
      case 'LLL': {
        let LLLDate;
        try {
          LLLDate = new IntlMessageFormat('{value, date, long}', this.locale);
        } catch (error) {
          LLLDate = new IntlMessageFormat('{value, date, long}', switchLocale(this.locale));
        }

        let LLLTime;
        try {
          LLLTime = new IntlMessageFormat('{value, time, short}', this.locale);
        } catch (error) {
          LLLTime = new IntlMessageFormat('{value, time, short}', switchLocale(this.locale));
        }
        return LLLDate.format({ value }) + ' ' + LLLTime.format({ value });
      }
      case 'lll': {
        let lllDate;
        try {
          lllDate = new IntlMessageFormat('{value, date, medium}', this.locale);
        } catch (error) {
          lllDate = new IntlMessageFormat('{value, date, medium}', switchLocale(this.locale));
        }

        let lllTime;
        try {
          lllTime = new IntlMessageFormat('{value, time, short}', this.locale);
        } catch (error) {
          lllTime = new IntlMessageFormat('{value, time, short}', switchLocale(this.locale));
        }
        return lllDate.format({ value }) + ' ' + lllTime.format({ value });
      }
      case 'LLLL': {
        let LLLLDate;
        try {
          LLLLDate = new IntlMessageFormat('{value, date, full}', this.locale);
        } catch (error) {
          LLLLDate = new IntlMessageFormat('{value, date, full}', switchLocale(this.locale));
        }

        let LLLLTime;
        try {
          LLLLTime = new IntlMessageFormat('{value, time, short}', this.locale);
        } catch (error) {
          LLLLTime = new IntlMessageFormat('{value, time, short}', switchLocale(this.locale));
        }
        return LLLLDate.format({ value }) + ' ' + LLLLTime.format({ value });
      }
      default:
        throw new Error('DateTime format "' + format + '" is not supported!');
    }
  }

  /**
   * Makes a function call to load any data tags that have previously been attempted to be resolved
   * but were not registered yet, and registers them. Only loads data tags for the context/locale
   * combination of the current instance.
   * @param {function} dataTagMakeServiceCallOverride - optional function override for the
   *    dataTagMakeServiceCall function.
   * @param {function} successCallbackOverride - optional function called on success of the dataTagMakeServiceCall
   *    function. Takes a single parameter of the new data tag mappings.
   * @param {function} failureCallbackOverride - optional function called on failure of the dataTagMakeServiceCall
   *    function. Takes a single error parameter.
   */
  fetchDataTags(
    dataTagMakeServiceCallOverride?: any,
    successCallbackOverride?: any,
    failureCallbackOverride?: any
  ) {
    const dataTags = _getDatatagsForFetchCall(this.context, this.locale);
    if (!dataTags.length) {
      return;
    }

    _dataTagsRetrieving[this.context][this.locale] = [
      ...dataTags,
      ...(_dataTagsRetrieving[this.context][this.locale] || [])
    ];

    getDataTagResolutions(
      dataTagMakeServiceCallOverride || this.dataTagMakeServiceCall,
      dataTags,
      // @ts-expect-error type any
      dataTagMappings => {
        _dataTags[this.context][this.locale] = {
          ..._dataTags[this.context][this.locale],
          ...dataTagMappings
        };
        const newKeys = Object.keys(dataTagMappings);
        newKeys.forEach(key => {
          _dataTagsNeeded[this.context][this.locale] =
            _dataTagsNeeded[this.context][this.locale]?.filter(it => it !== key) ?? [];

          _dataTagsRetrieving[this.context][this.locale] =
            _dataTagsRetrieving[this.context][this.locale]?.filter(it => it !== key) ?? [];
        });
        const successCallback = successCallbackOverride || this.dataTagFetchSuccessCallback;
        if (successCallback) {
          successCallback(dataTagMappings);
        }
      },
      // @ts-expect-error type any
      error => {
        _dataTagsRetrieving[this.context][this.locale] = _subtractLists(
          _dataTagsRetrieving[this.context][this.locale] ?? [],
          dataTags
        );
        const failureCallback = failureCallbackOverride || this.dataTagFetchFailureCallback;
        if (failureCallback) {
          failureCallback(error, dataTags);
        }
      },
      this.context,
      this.locale,
      this.dataTagFormatRegExp,
      this.resolveTranslation
    );
  }

  /**
   * Similar to fetchDataTags, except that instead of only loading the data tags that are needed, it
   * re-loads any and all data tags that have been attempted to be resolved with the given context/locale
   * combination. Intended to be used when you've already loaded data tags, but you either know that
   * server data has changed or some other factor has occurred that should alter the data tags.
   * @param {function} successCallbackOverride - optional function called on success of the makeServiceCall function.
   *    Takes a single parameter of the new data tag mappings.
   * @param {function} failureCallbackOverride - optional function called on failure of the makeServiceCall function.
   *    Takes a single error parameter.
   */
  fetchAllDataTags(
    dataTagMakeServiceCallOverride?: any,
    successCallbackOverride?: any,
    failureCallbackOverride?: any
  ) {
    const dataTags = _getDatatagsForFetchAllCall(this.context, this.locale);
    if (!dataTags.length) {
      return;
    }

    getDataTagResolutions(
      dataTagMakeServiceCallOverride || this.dataTagMakeServiceCall,
      dataTags,
      // @ts-expect-error type any
      dataTagMappings => {
        _dataTags[this.context][this.locale] = {
          ..._dataTags[this.context][this.locale],
          ...dataTagMappings
        };
        const successCallback = successCallbackOverride || this.dataTagFetchSuccessCallback;
        if (successCallback) {
          successCallback(dataTagMappings);
        }
      },
      // @ts-expect-error type any
      error => {
        const failureCallback = failureCallbackOverride || this.dataTagFetchFailureCallback;
        if (failureCallback) {
          failureCallback(error, dataTags);
        }
      },
      this.context,
      this.locale,
      this.dataTagFormatRegExp,
      this.resolveTranslation
    );
  }

  /**
   * This function checks whether to fetch datatags or not based on current datatags internal state
   * @returns {boolean} True if should fetch datatags
   */
  shouldFetchDataTags() {
    const dataTags = _getDatatagsForFetchCall(this.context, this.locale);
    return !!dataTags.length;
  }

  /**
   * This function checks whether to fetch all datatags or not based on current datatags internal state
   * @returns {boolean} True if should fetch all datatags
   */
  shouldFetchAllDataTags() {
    const dataTags = _getDatatagsForFetchAllCall(this.context, this.locale);
    return !!dataTags.length;
  }

  /**
   * Initializes TextResolver.memoizedResolveDataTagsResponse
   */
  initializeMemoizedResolveDataTagsResponse() {
    if (!TextResolver.memoizedResolveDataTagsResponse[this.locale]) {
      TextResolver.memoizedResolveDataTagsResponse[this.locale] = {};
    }
  }

  /**
   * Invalidate TextResolver.memoizedResolveDataTagsResponse
   */
  invalidateMemoizedResolveDataTagsResponse() {
    if (TextResolver.memoizedResolveDataTagsResponse[this.locale]) {
      TextResolver.memoizedResolveDataTagsResponse[this.locale] = {};
    }
  }
}
