import getMatchingLocale from './util/getMatchingLocale';
import { loadIntlWithLocale } from './intlLoaders';
import TextResolver from './TextResolver';

async function registerLocale(
  resolver: any,
  localeName: any,
  locales: any,
  defaultLocale: any,
  defaultLocaleOverrides: any
) {
  const loader = getMatchingLocale(localeName, locales, defaultLocaleOverrides, defaultLocale);
  const locale = await loader();

  resolver.registerTranslations(locale.default, 'default', localeName);
  return locale;
}

function registerLocaleSync(
  resolver: any,
  localeName: any,
  locales: any,
  defaultLocale: any,
  defaultLocaleOverrides: any
) {
  const loader = getMatchingLocale(localeName, locales, defaultLocaleOverrides, defaultLocale);
  const locale = loader;

  resolver.registerTranslations(locale, 'default', localeName);
}

export async function initializeLocalization(
  locale: any,
  locales: any,
  textResolverOptions: any,
  defaultLocale: any,
  defaultLocaleOverrides: any
) {
  const textResolver = new TextResolver({
    locale,
    context: 'default',
    ...textResolverOptions
  });

  const loadIntlWithLocalePromise = Promise.all([
    new Promise(resolve => loadIntlWithLocale(locale, resolve))
  ]);

  const [localeObject] = await Promise.all([
    registerLocale(textResolver, locale, locales, defaultLocale, defaultLocaleOverrides),
    loadIntlWithLocalePromise
  ]);

  return { textResolver, localeObject };
}

export function initializeLocalizationSync(
  locale: any,
  locales: any,
  textResolverOptions: any,
  defaultLocale: any,
  defaultLocaleOverrides: any
) {
  const textResolver = new TextResolver({
    locale,
    context: 'default',
    ...textResolverOptions
  });

  registerLocaleSync(textResolver, locale, locales, defaultLocale, defaultLocaleOverrides);

  return textResolver;
}
