import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import ReactDOM from 'react-dom';

import { tap, tapOrClick } from '../touchEventHandlers';
import { removeKeys } from '../utils/removeKeys';
import { InteractiveElement } from './InteractiveElement';
import { Transition, removeTransitionProps, TransitionProps } from './Transition';

/**
 * Removes any Trigger specific properties from a props object that would be invalid
 * if applied to a base DOM node. Useful when composing multiple components to prevent
 * passing a component props that it doesn't use.
 */
export function removeTriggerProps(props: any) {
  return removeKeys(removeTransitionProps(props), [
    'allowToggle',
    'allowMouse',
    'mouseEnterTimeout',
    'mouseLeaveTimeout',
    'allowDocumentClick',
    'onOpen',
    'onClose',
    'classes'
  ]);
}

export type TriggerProps = React.PropsWithChildren<
  TransitionProps & {
    ariaDescribedby?: string;
    ariaLabelledby?: string;
    allowToggle?: boolean;
    allowMouse?: boolean;
    isTriggerFocusable?: boolean;
    useEventPosition?: boolean;
    mouseEnterTimeout?: number;
    mouseLeaveTimeout?: number;
    allowDocumentClick?: boolean;
    onBlur?: (...args: any[]) => any;
    onClick?: (...args: any[]) => any;
    onOpen?: (...args: any[]) => any;
    onClose?: (...args: any[]) => any;
    onKeyDown?: (...args: any[]) => any;
    shouldCloseFlyout?: (target: HTMLElement) => boolean | undefined;
    closeFlyoutOnEscapeKeyDown?: boolean;
    style?: {
      trigger?: any;
    };
    classes?: any;
  }
>;

type State = any;

/**
Trigger - A wrapper component to trigger the opening/closing of flyout content on click/mouseover/mouseout.
**/
export class Trigger extends React.Component<TriggerProps, State> {
  static displayName = 'Trigger';
  static defaultProps = {
    allowToggle: true,
    allowMouse: true,
    useEventPosition: false,
    mouseEnterTimeout: 0,
    mouseLeaveTimeout: 300,
    allowDocumentClick: true,
    closeFlyoutOnEscapeKeyDown: true
  };

  _isMounted: any;
  _mouseEnterTimer: any;
  _mouseLeaveTimer: any;
  documentTapHandlers: any;
  trigger: any;

  constructor(props: TriggerProps) {
    super(props);
    this.state = {
      showContent: false,
      shouldHideContent: true,
      mouse: {
        x: 0,
        y: 0
      }
    };
    Trigger.displayName = 'Trigger';
    this._mouseEnterTimer = null;
    this._mouseLeaveTimer = null;
    this.onDocumentClick = this.onDocumentClick.bind(this);
    this.onMouseEnter = this.onMouseEnter.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleHide = this.handleHide.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.documentTapHandlers = tap(this.onDocumentClick);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.addClickHandler(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps: TriggerProps) {
    if (nextProps.allowDocumentClick !== this.props.allowDocumentClick) {
      if (nextProps.allowDocumentClick) {
        this.addClickHandler(nextProps);
      } else {
        this.removeClickHandler();
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this._mouseLeaveTimer) {
      clearTimeout(this._mouseLeaveTimer);
      this._mouseLeaveTimer = null;
    }
    if (this._mouseEnterTimer) {
      clearTimeout(this._mouseEnterTimer);
      this._mouseEnterTimer = null;
    }
    this.removeClickHandler();
  }

  /**
   * Adds a click handler to the document if allowDocumentClick is configured.
   */
  addClickHandler(props: TriggerProps) {
    if (!props.allowDocumentClick) {
      return;
    }
    if (typeof document !== 'undefined' && document.addEventListener) {
      // Capture events are used so the click is checked against the UI before the
      // the UI has a chance to change.
      document.addEventListener('mousedown', this.onDocumentClick, true);
      document.addEventListener('touchstart', this.documentTapHandlers.onTouchStart, true);
      document.addEventListener('touchend', this.documentTapHandlers.onTouchEnd, true);
      document.addEventListener('keyup', this.onKeyDown, true);
    }
  }
  /**
   * Removes the click handler from the document if allowDocumentClick is configured.
   */
  removeClickHandler() {
    if (!this.props.allowDocumentClick) {
      return;
    }
    if (typeof document !== 'undefined' && document.removeEventListener) {
      document.removeEventListener('mousedown', this.onDocumentClick, true);
      document.removeEventListener('touchstart', this.documentTapHandlers.onTouchStart, true);
      document.removeEventListener('touchend', this.documentTapHandlers.onTouchEnd, true);
      document.removeEventListener('keyup', this.onKeyDown, true);
    }
  }

  /**
   * Handles hiding the content if allowDocumentClick is configured and the user clicks
   * anywhere outside of the trigger or its content.
   */
  onDocumentClick(event: MouseEvent) {
    if (!this.props.allowDocumentClick || !this._isMounted) {
      return;
    }
    const component = ReactDOM.findDOMNode(this as any) as HTMLElement;
    const target = this.getTarget(event) as HTMLElement;
    if (
      !component.contains(target) &&
      !(this.props.shouldCloseFlyout && this.props.shouldCloseFlyout(target))
    ) {
      this.handleHide();
    }
  }

  getTarget(event: Event) {
    if (typeof event.composedPath === 'function') {
      return event.composedPath()[0];
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return event.target ? event.target : window.event!.target;
  }

  onMouseEnter(event: React.MouseEvent) {
    event.persist();
    if (!this.props.allowMouse) {
      return;
    }

    // @ts-expect-error object is possibly undefined
    if (this.props.mouseEnterTimeout <= 0) {
      this.handleShow(event);
      return;
    }

    // Set flag indicating that the content should be hidden.
    this.setState({
      shouldShowContent: true
    });

    // If a mouse leave timer has already been set from a previous mouse leave, clear it.
    if (this._mouseEnterTimer) {
      clearTimeout(this._mouseEnterTimer);
    }

    // Set a new mouse leave timer to hide the content after the specified time as long
    // as the flag indicating that we should hide the content is still set.
    this._mouseEnterTimer = setTimeout(() => {
      if (this.state.shouldShowContent) {
        this.handleShow(event);
      }
    }, this.props.mouseEnterTimeout);
  }

  onMouseLeave() {
    if (!this.props.allowMouse) {
      return;
    }

    // @ts-expect-error object is possibly undefined
    if (this.props.mouseLeaveTimeout <= 0) {
      this.handleHide();
      return;
    }

    // Set flag indicating that the content should be hidden.
    this.setState({
      shouldHideContent: true
    });

    // If a mouse leave timer has already been set from a previous mouse leave, clear it.
    if (this._mouseLeaveTimer) {
      clearTimeout(this._mouseLeaveTimer);
    }

    // Set a new mouse leave timer to hide the content after the specified time as long
    // as the flag indicating that we should hide the content is still set.
    this._mouseLeaveTimer = setTimeout(() => {
      if (this.state.shouldHideContent) {
        this.handleHide();
      }
    }, this.props.mouseLeaveTimeout);
  }

  onKeyDown(e: KeyboardEvent) {
    const { isTriggerFocusable, onKeyDown, closeFlyoutOnEscapeKeyDown } = this.props;
    const component = ReactDOM.findDOMNode(this as any) as HTMLElement;
    const target = this.getTarget(e) as HTMLElement;
    if (!component.contains(target)) {
      this.handleHide();
    }
    if (onKeyDown) onKeyDown(e);
    // Handle escape key
    if (e.key === 'Escape' && closeFlyoutOnEscapeKeyDown) {
      this.handleHide();
      if (isTriggerFocusable && this.trigger) {
        this.trigger.focus();
      }
    }
  }
  /**
   * Sets state flags to show the content.
   */
  handleShow(event: any) {
    if (!this.state.showContent && this.props.onOpen) {
      this.props.onOpen();
    }
    let x = 0;
    let y = 0;
    if (event) {
      const elementRect = event.target.getBoundingClientRect();
      x = elementRect.left;
      y = elementRect.top;
    }
    this.setState({
      showContent: true,
      shouldShowContent: true,
      shouldHideContent: false,
      mouse: {
        x,
        y
      }
    });
  }

  /**
   * Sets state flags to hide the content.
   */
  handleHide() {
    if (this.state.showContent && this.props.onClose) {
      this.props.onClose();
    }
    this.setState({
      showContent: false,
      shouldShowContent: false,
      shouldHideContent: true
    });
  }

  /**
   * Toggles the state of the content between showing and hiding if allowToggle is configured.
   */
  handleClick(event: MouseEvent) {
    if (this.props.onClick) {
      this.props.onClick(event);
    }
    if (!this.props.allowToggle) {
      return;
    }
    event.stopPropagation();
    if (this.state.showContent) {
      this.handleHide();
    } else {
      this.handleShow(event);
    }
  }

  render() {
    const { useEventPosition, isTriggerFocusable, ariaDescribedby, ariaLabelledby } = this.props;
    // Triggers are not valid unless there are exactly two children.
    if (!this.props.children || React.Children.count(this.props.children) !== 2) {
      throw new Error('Exactly two children were not supplied to the Trigger!');
    }

    // Populate content if needed.
    let content: JSX.Element[] = [];
    let positionStyle: React.CSSProperties = {};
    if (useEventPosition) {
      positionStyle = {
        position: 'absolute',
        top: `${this.state.mouse.y}px`,
        left: `${this.state.mouse.x}px`
      };
    }
    if (this.state.showContent) {
      content = [
        <span
          key="content"
          style={useEventPosition ? positionStyle : undefined}
          onMouseEnter={event => (this.props.allowMouse ? this.handleShow(event) : null)}
          onMouseOver={event => (this.props.allowMouse ? this.handleShow(event) : null)}
          onMouseLeave={this.onMouseLeave}
        >
          {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          {this.props.children[1]}
        </span>
      ];
    }

    const TriggerElement = isTriggerFocusable ? InteractiveElement : 'span';
    const conditionalProps = isTriggerFocusable
      ? { ref: (c: any) => (this.trigger = c), 'aria-expanded': this.state.showContent }
      : undefined;
    return (
      <div
        {...resolveTestId(this.props)}
        {...resolve(this.props, 'trigger')}
        onKeyDown={this.onKeyDown}
      >
        <TriggerElement
          aria-describedby={ariaDescribedby}
          aria-labelledby={ariaLabelledby}
          {...tapOrClick(this.handleClick)}
          onBlur={this.props.onBlur}
          onMouseEnter={this.onMouseEnter}
          onMouseOver={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          {...conditionalProps}
        >
          {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          {this.props.children[0]}
        </TriggerElement>
        <Transition {...this.props} defaultName={Trigger.displayName}>
          {content}
        </Transition>
      </div>
    );
  }
}
