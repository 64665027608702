import React from 'react';

import merge from 'lodash/merge';
import { FlexColumn as Column } from 'nucleus-core';
import { defaultMemoize } from 'reselect';

import { getParentSection } from '../../../../utils/layout/getParentSection';

type Props = {
  widget: any;
  layoutItems: any;
  theme: any;
  widgetContainerComponent?: React.ReactElement | ((...args: any[]) => any) | undefined;
  canvasPermissions?: any;
  setHoveredItemId?: any;
  isHovered?: boolean;
  widgetMetaData?: any;
  widgetFactory?: any;
  guestTranslate?: (...args: any[]) => any;
  parent?: any;
  grandparent?: any;
  parentAvailableCellSize?: any;
  sectionIsHovered?: any;
  children?: React.ReactNode;
};

/**
 * Default renderer for a WIDGET type layout item.
 * You can specify a container component to render your widget,
 * and this wrapping class will provide the grid content and
 * generate the parent style to pass to your component.
 */
export class Widget extends React.Component<Props> {
  static displayName = 'Widget';
  constructor(props: Props) {
    super(props);
    this.getParentStyle = this.getParentStyle.bind(this);
  }
  getParentStyle() {
    const { widget, layoutItems, theme } = this.props;
    return this._getParentStyle(widget, layoutItems, theme);
  }
  _getParentStyle = defaultMemoize((widget: any, layoutItems: any, theme: any) => {
    const parentSection = getParentSection(layoutItems, widget);
    return merge(
      {},
      theme.global,
      theme.sections[parentSection.config.themeId],
      parentSection.config.style
    );
  });
  render() {
    const { widget, children } = this.props;
    return (
      <Column size={widget.layout.colspan}>
        {/* @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'this.props.widgetContainerCompon... Remove this comment to see the full error message */}
        <this.props.widgetContainerComponent parentStyle={this.getParentStyle()} {...this.props}>
          {children}
        </this.props.widgetContainerComponent>
      </Column>
    );
  }
}
