import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import Icon from '@cvent/nucleus-icon';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import { InteractiveElement, Trigger } from 'nucleus-core';

type OwnProps = {
  selectedPage?: {
    id?: string;
    name?: string;
  };
  hideNavigationMenu?: boolean;
  pageData?: any[];
  navigateToPage: (...args: any[]) => any;
  openNavigatorDialog?: (...args: any[]) => any;
  closeNavigatorDialog?: (...args: any[]) => any;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  translate?: (...args: any[]) => any;
  hamburgerMenuExpanded?: boolean;
};

type Props = OwnProps & typeof WebsiteNavigatorMobileView.defaultProps;
/**
 * Mobile View for the website navigator.
 */
export class WebsiteNavigatorMobileView extends React.Component<Props> {
  static displayName = 'WebsiteNavigatorMobileView';
  static defaultProps = {
    hideNavigationMenu: false
  };
  trigger: any;
  constructor(props: Props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.openPageDataDialog = this.openPageDataDialog.bind(this);
    this.renderTrigger = this.renderTrigger.bind(this);
    this.renderMenuItem = this.renderMenuItem.bind(this);
    this.renderSubMenuOrItem = this.renderSubMenuOrItem.bind(this);
    this.renderNavigation = this.renderNavigation.bind(this);
  }
  onClick(id: any) {
    return () => {
      const { navigateToPage, closeNavigatorDialog } = this.props;
      navigateToPage(id);
      if (closeNavigatorDialog) {
        closeNavigatorDialog();
      }
    };
  }
  openPageDataDialog() {
    const { openNavigatorDialog } = this.props;
    if (openNavigatorDialog) {
      const content = this.renderNavigation();
      const style = {
        ...resolve(this.props, 'pageMenu').style,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type 'Readonly<... Remove this comment to see the full error message
        backgroundColor: this.props.style.verticalMenu.backgroundColor
      };
      const pageLinks = resolve(this.props, 'pageLinks').style;
      const closeStyle = {
        ...pageLinks,
        backgroundImage: 'none',
        backgroundColor: 'none',
        fontSize: '1.25em',
        textAlign: 'right'
      };
      const headerStyle = {
        boxSizing: 'border-box',
        fontSize: '1.75em',
        textAlign: 'right',
        width: '100%',
        padding: '.313em .313em 0'
      };
      openNavigatorDialog(content, { dragContainer: style, exit: closeStyle, header: headerStyle });
    }
  }
  // Make trigger for dropdown or hamburger menu
  renderTrigger(menu: any, name: any, showAsSelectedGroup: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { selectedPage, classes, style } = this.props;
    const pageLinkStyles = resolve(this.props, 'pageLinks', showAsSelectedGroup ? 'selected' : '');
    pageLinkStyles.style.textAlign = 'left';

    return (
      <Trigger
        classes={classes}
        style={style}
        allowMouse={false}
        ref={(ref: any) => (this.trigger = ref)}
      >
        <div {...pageLinkStyles}>
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          <span className={classes.selectedPage}>{name || selectedPage.name}</span>
          <Icon icon={'expand'} modifier={classes.menuIcon} />
        </div>
        {menu}
      </Trigger>
    );
  }
  // Individual link item
  renderMenuItem(page: any, menuType: any) {
    const { selectedPage } = this.props;
    const pageLinks = resolve(
      this.props,
      'pageLinks',
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      page.id === selectedPage.id ? 'selected' : ''
    );
    pageLinks.style.backgroundImage = 'none';
    pageLinks.style.textAlign = 'left';

    return (
      <li
        key={page.id}
        {...resolve(this.props, 'menuItem', menuType)}
        aria-current={selectedPage?.id === page.id ? 'true' : null}
      >
        <InteractiveElement {...pageLinks} onClick={this.onClick(page.id)} role="link">
          {page.name}
        </InteractiveElement>
      </li>
    );
  }
  // Created Trigger and Sub Group pages
  renderSubMenuOrItem(item: any, menuType: any) {
    if (!item.childPages) {
      return this.renderMenuItem(item, menuType);
    }
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'boolean' ... Remove this comment to see the full error message
    if (!item.childPages.length > 0) {
      return null;
    }
    const subMenu = (
      <ul {...resolve(this.props, 'subMenu', menuType)}>
        {item.childPages.map((childItem: any) => this.renderSubMenuOrItem(childItem, 'sub'))}
      </ul>
    );
    const { selectedPage } = this.props;
    const selectedPageInGroup = item.childPages
      .map((childPage: any) => childPage.id)
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      .includes(selectedPage.id);
    return (
      <li
        key={item.id}
        aria-current={selectedPage?.id === item.id ? 'true' : null}
        {...resolve(this.props, 'menuItem', menuType)}
      >
        {this.renderTrigger(subMenu, item.name, selectedPageInGroup)}
      </li>
    );
  }
  // Parent Navigation UL - Create sub menu or direct link...
  renderNavigation() {
    const { pageData } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const textAlign = this.props.style.pageLinks.textAlign;
    return (
      <ul {...resolve(this.props, textAlign, 'menu', 'verticalMenu')}>
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {pageData.map(item => this.renderSubMenuOrItem(item, 'vertical'))}
      </ul>
    );
  }
  render() {
    const {
      leftContent,
      rightContent,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'Readonl... Remove this comment to see the full error message
      classes,
      translate,
      hideNavigationMenu,
      hamburgerMenuExpanded
    } = this.props;
    const mobilePageLinks = {
      ...resolve(this.props, 'pageLinks').style,
      textAlign: 'left',
      backgroundImage: 'none',
      borderStyle: 'none',
      padding: '0px'
    };
    const humburgerWrapperStyles = {
      ...resolve(this.props, 'hamburgerWrapper'),
      style: {
        ...resolve(this.props, 'hamburgerWrapper').style
      }
    };
    const content = (
      <div {...humburgerWrapperStyles}>
        <InteractiveElement
          // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
          aria-label={translate('_buttonText_menu__resx')}
          aria-expanded={hamburgerMenuExpanded}
          style={mobilePageLinks}
          onClick={this.openPageDataDialog}
        >
          <Icon icon={'menuFilled'} modifier={classes.menuIcon} />
        </InteractiveElement>
      </div>
    );

    return (
      <div {...resolveTestId(this.props)} {...resolve(this.props, 'container')}>
        <div className={classes.group}>
          {!hideNavigationMenu && <div {...resolve(this.props, 'navigation')}>{content}</div>}
          {rightContent && <div {...resolve(this.props, 'rightContent')}>{rightContent}</div>}
        </div>
        {leftContent && <div {...resolve(this.props, 'leftContent')}>{leftContent}</div>}
      </div>
    );
  }
}
