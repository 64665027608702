export const HIDE_FOOTER = 'calendar-guest-site/actions/HIDE_FOOTER';
export const GET_PARENT_WINDOW_DIMENSIONS = 'calendar-guest-site/actions/GET_PARENT_WINDOW_DIMENSIONS';
export const SET_FETCHING_WINDOW_DIMENSIONS = 'calendar-guest-site/actions/SET_FETCHING_WINDOW_DIMENSIONS';
export const RECEIVE_WINDOW_DIMENSIONS = 'calendar-guest-site/actions/RECEIVE_WINDOW_DIMENSIONS';
export const REGISTER_IFRAME_INSTANCE = 'calendar-guest-site/actions/REGISTER_IFRAME_INSTANCE';

// @ts-expect-error TS(2304): Cannot find name 'Action'.
export function hideFooter(): Action {
  return { type: HIDE_FOOTER, payload: { footerHidden: true } };
}

// @ts-expect-error TS(2304): Cannot find name 'Action'.
export function registerIframeInstance(iframeInstance: $TSFixMe): Action {
  return { type: REGISTER_IFRAME_INSTANCE, payload: iframeInstance };
}

// @ts-expect-error TS(2304): Cannot find name 'Action'.
export function doGetParentWindowDimensions(): Action {
  return { type: GET_PARENT_WINDOW_DIMENSIONS };
}

// @ts-expect-error TS(2304): Cannot find name 'Action'.
export function receiveParentWindowDimensions(parentWindowDimensions: $TSFixMe): Action {
  return { type: RECEIVE_WINDOW_DIMENSIONS, payload: parentWindowDimensions };
}

export function getParentWindowDimensions() {
  return function fetch(dispatch: $TSFixMe, getState: $TSFixMe) {
    const { embeddedSettings } = getState();
    if (!embeddedSettings.isFetchingDimensions) {
      dispatch(doGetParentWindowDimensions());
      embeddedSettings.iframeInstance.then((parent: $TSFixMe) => {
        parent.action({ action: 'dimensions' }).then((parentWindowDimensions: $TSFixMe) => {
          dispatch(receiveParentWindowDimensions(parentWindowDimensions));
        });
      });
    }
  };
}

export function scrollToTop() {
  return function fetch(dispatch: $TSFixMe, getState: $TSFixMe) {
    const { embeddedSettings } = getState();
    embeddedSettings.iframeInstance.then((parent: $TSFixMe) => {
      parent.action({ action: 'scrollToTop' });
    });
  };
}

export default function reducer(
  state = {
    footerHidden: false,
    iframeInstance: null,
    isFetchingDimensions: false,
    parentWindowDimensions: null
  },
  action = null
) {
  switch (action.type) {
    case HIDE_FOOTER:
      return {
        ...state,
        footerHidden: action.payload.footerHidden
      };
    case REGISTER_IFRAME_INSTANCE:
      return {
        ...state,
        iframeInstance: action.payload
      };
    case SET_FETCHING_WINDOW_DIMENSIONS:
      return {
        ...state,
        isFetchingDimensions: action.payload
      };
    case GET_PARENT_WINDOW_DIMENSIONS:
      return {
        ...state,
        parentWindowDimensions: null,
        isFetchingDimensions: true
      };
    case RECEIVE_WINDOW_DIMENSIONS:
      return {
        ...state,
        parentWindowDimensions: action.payload,
        isFetchingDimensions: false
      };
    default:
      return state;
  }
}
