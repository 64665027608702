import React from 'react';

import { Dialog } from 'nucleus-core';
import {
  SessionTimeoutDialog as BaseSessionTimeoutDialog,
  ThemeableComponent,
  ThemeableComponentProps
} from 'nucleus-widgets';
import { connect } from 'react-redux';
import { defaultMemoize } from 'reselect';

import { sessionTimeoutDialogIsOpen } from '../redux/modules/reducer';
import { setSessionTimeoutDialogConfig } from '../redux/modules/sessionTimeoutDialog/actions';
import { getDialogStyleObject, getDialogContainerStyle } from './DialogContainer';
import DialogStyles from './styles/Dialog.less';
import sessionTimeoutClasses from './styles/SessionTimeoutDialog.less';

interface SessionTimeoutDialogProps extends ThemeableComponentProps {
  theme?: any; // object
  sessionTimeoutDialogIsOpen?: boolean;
  setSessionTimeoutDialogConfig?: any;
  refreshSession?: (...args: any[]) => any; // func
  endSession?: (...args: any[]) => any; // func
  closeDialog?: (...args: any[]) => any; // func
}

export class SessionTimeoutDialogBase extends ThemeableComponent<SessionTimeoutDialogProps> {
  static displayName = 'SessionTimeoutDialog';

  getStyleObject = (): any => {
    const { style, theme } = this.props;
    return this._getStyleObject(style, theme);
  };

  _getStyleObject = defaultMemoize((style: any, theme: any): any => {
    if (!theme) {
      return {};
    }
    return {
      heroImage: {
        color: style?.palette?.text
      },
      primary: this.getElementInlineStyle('primaryButton'),
      secondary: this.getElementInlineStyle('secondaryButton'),
      header: this.getElementInlineStyle('header2'),
      description: this.getElementInlineStyle('body1'),
      dragContainer: getDialogContainerStyle(style, theme),
      content: this.getElementInlineStyle('body')
    };
  });
  render() {
    const style = this.getStyleObject();
    return (
      <Dialog
        isOpen={(this.props as any).sessionTimeoutDialogIsOpen}
        classes={DialogStyles}
        style={style}
        isAnimatable={false}
      >
        <BaseSessionTimeoutDialog
          classes={{
            ...sessionTimeoutClasses,
            buttons: {
              button: sessionTimeoutClasses.button
            }
          }}
          setSessionTimeoutDialogConfig={this.props.setSessionTimeoutDialogConfig}
          style={style}
          refreshSession={(this.props as any).refreshSession}
          endSession={(this.props as any).endSession}
          translate={(this.props as any).translate}
          closeDialog={(this.props as any).closeDialog}
        />
      </Dialog>
    );
  }
}

function closeDialog() {
  return setSessionTimeoutDialogConfig({ isOpen: false });
}

export const SessionTimeoutDialog = connect(
  state => {
    const { theme } = (state as any).website || {};
    return {
      theme,
      style:
        theme && theme.global && theme.global.dialog
          ? getDialogStyleObject(theme.global.dialog, theme, (state as any).customFonts)
          : {},
      sessionTimeoutDialogIsOpen: sessionTimeoutDialogIsOpen(state),
      refreshSession: (state as any).sessionTimeoutDialogConfig.refreshSession,
      endSession: (state as any).sessionTimeoutDialogConfig.endSession,
      translate: (state as any).text.translate
    };
  },
  { closeDialog }
)(SessionTimeoutDialogBase as any);
