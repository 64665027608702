import update from 'lodash/update';
import debounce from 'lodash/debounce';
import { updatePseudoStateStyle } from './updatePseudoStateStyle';
import { PseudoState } from './types';
import merge from 'lodash/merge';

/**
 * @see EditorField#cascadedChange
 */
export type CascadedChangeFunction = (
  // The field name of the related editor field.
  fieldName: string,
  // The current value of the field.
  value: any,
  // The function to call to trigger changes to the field in the application.
  onChange: (fieldName: string, value: any) => void,
  // The value object of the field.
  valueObject?: any,
  // The redux state.
  state?: any,
  // The prefix of the field name (see EditorFields#fieldNamePrefix).
  fieldNamePrefix?: string,
  themeSectionId?: string
) => void;

/**
 * The options to pass to `pseudoStateCascadedChange`.
 */
export interface PseudoStateCascadedChangeOptions {
  // A pass-through function that is called when a cascaded change is triggered.
  // This may be used to retain custom logic that may need to be passed into `cascadedChange` for a given field grouping.
  cascadedChange?: CascadedChangeFunction;
  // A list of pseudo-states whose styles should be updated when the cascaded change is triggered.
  triggeredPseudoStates?: PseudoState[];
  // Time in milliseconds to wait before calculating pseudo-state changes.
  debounceMs?: number;
}

const DEFAULT_DEBOUNCE_MILLIS = 50;

/**
 * Returns a function to be passed into `cascadedChange` for field groupings. When the value of an editor field is
 * changed, this function will update the styles for all given pseudo-states, according to the set pseudo-state
 * style mode of the related editor field. If no pseudo-state style mode is set, nothing will be updated.
 *
 * Example Usage:
 * In this example, every time the background color is updated, the hover style will also be updated appropriately.
 * ```
 * const backgroundColor = {
 *   fieldName: 'background.color',
 *   component: 'ColorPickerField',
 *   cascadedChange: pseudoStateCascadedChange()
 * };
 * ```
 */
export function pseudoStateCascadedChange(
  options?: PseudoStateCascadedChangeOptions
): CascadedChangeFunction {
  const {
    cascadedChange,
    debounceMs = DEFAULT_DEBOUNCE_MILLIS,
    triggeredPseudoStates = ['hover']
  } = options ?? {};

  const functionResult = (
    fieldName: string,
    value: any,
    onChange: (fieldName: string, value: any) => void,
    valueObject?: any,
    state?: any,
    fieldNamePrefix?: string,
    themeSectionId?: string
  ) => {
    cascadedChange?.(
      fieldName,
      value,
      onChange,
      valueObject,
      state,
      fieldNamePrefix,
      themeSectionId
    );

    // The `valueObject` given from EditorField#cascadedChange does not contain the latest changed `value`, so we need
    // to create a new `valueObject` and update the `value`. "`fieldName` minus `fieldNamePrefix`" should always give
    // the object path to `value` to be updated.
    const valueUpdatePath = fieldName.replace(`${fieldNamePrefix}.`, '');
    const updatedValueObject = update(valueObject, valueUpdatePath, _ => value);

    for (const pseudoState of triggeredPseudoStates) {
      updatePseudoStateStyle({
        fieldNamePrefix,
        onChange,
        pseudoState,
        themePalette: merge(
          {},
          state?.website?.theme?.global?.palette,
          themeSectionId ? state?.website?.theme?.sections?.[themeSectionId]?.palette : {}
        ),
        valueObject: updatedValueObject
      });
    }
  };

  return debounceMs ? debounce(functionResult, debounceMs) : functionResult;
}
