export function questionsFromSearch(searchedText: string) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const client = state.clients.questionLibraryClient;
    const searchText = `text contains '${searchedText}'`.replace(/\"/g, '\\"');
    const RESULT_LIMIT = 200;

    try {
      const res = await client.fetchSearchQuestions(searchText, RESULT_LIMIT);
      // Unused but required dispatch call
      dispatch({ type: true });
      return res;
    } catch (err) {
      return false;
    }
  };
}
