import { getLightMood } from '../../../utils';
import merge from 'lodash/merge';
import { WebsiteV8 } from './WebsiteV8';

/**
 * Adds default blocks mood data to the existing website theme. It utilizes the text and textAccent
 * palette colors to generate a light mood.
 */
export function addBlocksMood(website: WebsiteV8) {
  if (website.theme.global.blocks) {
    return website;
  }

  const palette = website.theme.global.palette;
  const mood = getLightMood({
    primary: palette.text,
    secondary: palette.textAccent
  });
  const blocks = {
    moodType: mood.id,
    safeMode: false,
    themeColors: mood.moodPalette
  };

  return merge({}, website, {
    theme: {
      global: {
        blocks
      }
    }
  });
}
