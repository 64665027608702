/* eslint react/prop-types: [2, { ignore: ["onChange", "onNativeChange", "fieldName", "onBlur",
  "onFocus", "onKeyDown", "value", "minDate", "maxDate"] }] */
/* eslint-disable react/no-multi-comp */
import React from 'react';

import {
  formatDateToString,
  getDateFromString,
  parseMobileDateString,
  DATE_CHANGE_SUCCESS,
  DATE_CHANGE_EMPTY_DATE,
  DATE_CHANGE_ERROR_INVALID,
  DATE_CHANGE_ERROR_OUT_OF_RANGE
} from '@cvent/nucleus-core-datetime-utils';
import { select, resolve } from '@cvent/nucleus-dynamic-css';

import { Calendar, removeCalendarProps } from '../../calendars/Calendar';
import { Trigger, removeTriggerProps } from '../../containers/Trigger';
import { isTouchEnabled } from '../../utils/isTouchEnabled';
import { removeKeys } from '../../utils/removeKeys';
import { FormElement, WithFormProps } from '../FormElement';
import { Textbox } from './Textbox';

/*
(ts-migrate) TODO: Migrate the remaining prop types
...FormElement.propTypes
*/
type OwnDatePickerProps = {
  format?: string;
  formatDate?: (...args: any[]) => any;
  parseDate?: (...args: any[]) => any;
  showIcon?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  style?: {
    textbox?: any;
    trigger?: any;
    icon?: any;
  };
};

type DatePickerState = any;

type DatePickerProps = OwnDatePickerProps & typeof DatePicker.defaultProps;

/**
Display a DatePicker form element composed of Trigger, Textbox, and Calendar components.

onChange = function(fieldName, newValue, textValue, statusCode)
**/
export class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
  static displayName = 'DatePicker';
  static defaultProps = {
    format: 'mm/dd/yy',
    formatDate: formatDateToString,
    parseDate: getDateFromString,
    showIcon: true,
    allowToggle: false,
    allowMouse: false
  };
  _userIsTyping: any;
  textbox: any;
  constructor(props: DatePickerProps) {
    super(props);
    this.state = {
      textInput:
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'DatePicke... Remove this comment to see the full error message
        props.value && props.value instanceof Date
          ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'DatePicke... Remove this comment to see the full error message
            props.formatDate(props.value, props.format)
          : // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'DatePicke... Remove this comment to see the full error message
            props.value,
      touchEnabled: isTouchEnabled()
    };
    this.onChange = this.onChange.bind(this);
    this.onChangeMobile = this.onChangeMobile.bind(this);
    this.onCalendarClick = this.onCalendarClick.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.checkForDateType = this.checkForDateType.bind(this);
    this._userIsTyping = false;
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (!this._userIsTyping) {
      this.setState({
        textInput:
          nextProps.value && nextProps.value instanceof Date
            ? nextProps.formatDate(nextProps.value, nextProps.format)
            : nextProps.value
      });
    }
  }

  onChange(fieldName: any, newValue: any) {
    this._userIsTyping = true;
    this.setState({ textInput: newValue });
    const date = this.props.parseDate(newValue, this.props.format);

    // If the user types in a date, we have to check max and min dates manually.
    // Do fancy date parsing to zero out time on minDate and/or maxDate.
    const dateRangeValid =
      date &&
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'minDate' does not exist on type 'Readonl... Remove this comment to see the full error message
      (!this.props.minDate || date >= new Date(Date.parse(this.props.minDate.toDateString()))) &&
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'maxDate' does not exist on type 'Readonl... Remove this comment to see the full error message
      (!this.props.maxDate || date <= new Date(Date.parse(this.props.maxDate.toDateString())));

    let statusCode = DATE_CHANGE_SUCCESS;
    if (!date && !newValue) {
      statusCode = DATE_CHANGE_EMPTY_DATE;
    } else if (!date) {
      statusCode = DATE_CHANGE_ERROR_INVALID;
    } else if (!dateRangeValid) {
      statusCode = DATE_CHANGE_ERROR_OUT_OF_RANGE;
    }

    if (this.props.hasOwnProperty('onNativeChange')) {
      if (statusCode !== DATE_CHANGE_SUCCESS) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onNativeChange' does not exist on type '... Remove this comment to see the full error message
        this.props.onNativeChange(newValue);
      } else {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onNativeChange' does not exist on type '... Remove this comment to see the full error message
        this.props.onNativeChange(date);
      }
      return;
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    this.props.onChange(fieldName, date, newValue, statusCode);
  }

  onChangeMobile(fieldName: any, newValue: any) {
    if (!newValue) {
      if (this.props.hasOwnProperty('onNativeChange')) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onNativeChange' does not exist on type '... Remove this comment to see the full error message
        this.props.onNativeChange(newValue);
      } else {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
        this.props.onChange(fieldName, null, '', DATE_CHANGE_EMPTY_DATE);
      }
      return;
    }

    // Get the date.
    const date = parseMobileDateString(newValue);
    if (!date) {
      if (this.props.hasOwnProperty('onNativeChange')) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onNativeChange' does not exist on type '... Remove this comment to see the full error message
        this.props.onNativeChange(newValue);
      } else {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
        this.props.onChange(fieldName, null, '', DATE_CHANGE_ERROR_INVALID);
      }
      return;
    }

    // Get the formatted date string.
    const dateString = this.props.formatDate(date, this.props.format);

    // Mobile iOS does not implement min and max properties, so we must check manually.
    // Do fancy date parsing to zero out time on minDate and/or maxDate.
    const dateRangeValid =
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'minDate' does not exist on type 'Readonl... Remove this comment to see the full error message
      (!this.props.minDate || date >= new Date(Date.parse(this.props.minDate.toDateString()))) &&
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'maxDate' does not exist on type 'Readonl... Remove this comment to see the full error message
      (!this.props.maxDate || date <= new Date(Date.parse(this.props.maxDate.toDateString())));

    if (this.props.hasOwnProperty('onNativeChange')) {
      if (!dateRangeValid) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onNativeChange' does not exist on type '... Remove this comment to see the full error message
        this.props.onNativeChange(newValue);
      } else {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'onNativeChange' does not exist on type '... Remove this comment to see the full error message
        this.props.onNativeChange(date);
      }
      return;
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    this.props.onChange(
      fieldName,
      dateRangeValid ? date : null,
      dateString,
      dateRangeValid ? DATE_CHANGE_SUCCESS : DATE_CHANGE_ERROR_OUT_OF_RANGE
    );
  }

  onCalendarClick(day: any, month: any, year: any) {
    this._userIsTyping = false;
    let newMonth;
    let newYear;
    if (month < 0) {
      newMonth = 11;
      newYear = year - 1;
    } else if (month > 11) {
      newMonth = 0;
      newYear = year + 1;
    } else {
      newMonth = month;
      newYear = year;
    }
    const chosen = new Date(newYear, newMonth, day);
    const dateString = this.props.formatDate(chosen, this.props.format);

    this.setState({ textInput: dateString });

    if (this.props.hasOwnProperty('onNativeChange')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onNativeChange' does not exist on type '... Remove this comment to see the full error message
      this.props.onNativeChange(chosen);
      if (this.refs.trigger) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleHide' does not exist on type 'Reac... Remove this comment to see the full error message
        this.refs.trigger.handleHide();
      }
      return;
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    this.props.onChange(this.props.fieldName, chosen, dateString, DATE_CHANGE_SUCCESS);
    if (this.refs.trigger) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleHide' does not exist on type 'Reac... Remove this comment to see the full error message
      this.refs.trigger.handleHide();
    }
  }

  onFocus(e: any) {
    if (this.refs.trigger) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleShow' does not exist on type 'Reac... Remove this comment to see the full error message
      this.refs.trigger.handleShow();
    }
    if (this.textbox) {
      this.textbox.focus();
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFocus' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.props.onFocus) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onFocus' does not exist on type 'Readonl... Remove this comment to see the full error message
      this.props.onFocus(e);
    }
  }

  onBlur(e: any) {
    this._userIsTyping = false;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBlur' does not exist on type 'Readonly... Remove this comment to see the full error message
    if (this.props.onBlur) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onBlur' does not exist on type 'Readonly... Remove this comment to see the full error message
      this.props.onBlur(e);
    }
  }

  onKeyDown(event: any) {
    // hide on tab out
    if (event.keyCode === 9 && this.refs.trigger) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleHide' does not exist on type 'Reac... Remove this comment to see the full error message
      this.refs.trigger.handleHide();
    }
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onKeyDown' does not exist on type 'Reado... Remove this comment to see the full error message
    if (this.props.onKeyDown) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onKeyDown' does not exist on type 'Reado... Remove this comment to see the full error message
      this.props.onKeyDown(event);
    }
  }

  checkForDateType() {
    if (!document) {
      return 'text';
    }
    const input = document.createElement('input');
    const notADateValue = 'not-a-date';
    let inputType = 'text';
    input.setAttribute('type', 'date');
    input.setAttribute('value', notADateValue);

    if (input.value !== notADateValue) {
      inputType = 'date';
    }

    return inputType;
  }
  // TODO: BAD! We shouldnt be doing this, but desktop browser implementations
  //  of input type="date" is spotty and inconsistent, so we're using an isMobile
  //  check on the user agent so that we only use the browser datepicker for mobile
  //  devices. Currently, the Chrome desktop has implementation flaws preventing us
  //  from dealing with it correctly - See https://jira/browse/CSN-23277
  isMobile() {
    // TODO: At some point in the near future we should be replacing this component with
    //  some third party datepicker or creating a better version of our own, at that point
    //  we need to stop doing this.
    if (navigator && navigator.userAgent) {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|CriOS|Tizen/i.test(
        navigator.userAgent
      );
    }
  }
  render() {
    const { value, minDate, maxDate, showIcon, ...rest } = removeKeys(this.props, [
      'format',
      'formatDate',
      'parseDate'
    ]);
    const { disabled, readOnly } = rest;
    const dateType = this.checkForDateType();

    let dateControl;
    const textboxProps = removeTriggerProps(removeCalendarProps(rest));
    if (this.props.hasOwnProperty('onNativeChange')) {
      // this is necessary! Because Textbox also suports NucleusField, if we do not remove onNativeChange from props,
      // this.onChange/onChangeMobile will not be called properly
      delete textboxProps.onNativeChange;
    }
    if (dateType === 'date' && this.state.touchEnabled === true && this.isMobile()) {
      // TODO: [nucleus-core@3.0.0] - switch to standalone Icon component with its own onClick and
      //   remove the onClick from the wrapping div.
      dateControl = (
        <div {...resolve(this.props, showIcon ? 'icon' : null)} onClick={this.onFocus}>
          <Textbox
            {...textboxProps}
            {...resolve(this.props, 'textbox')}
            ref={(c: any) => (this.textbox = c)}
            min={minDate ? formatDateToString(minDate, 'yy-mm-dd') : undefined}
            max={maxDate ? formatDateToString(maxDate, 'yy-mm-dd') : undefined}
            value={value && value instanceof Date ? formatDateToString(value, 'yy-mm-dd') : ''}
            onChange={this.onChangeMobile}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            type={dateType}
            children={undefined}
          />
        </div>
      );
    } else {
      // TODO: [nucleus-core@3.0.0] - switch to standalone Icon component with its own onClick and
      //   remove the onClick from the wrapping div.
      const triggerElement = (
        <div {...resolve(this.props, showIcon ? 'icon' : null)} onClick={this.onFocus}>
          <Textbox
            {...textboxProps}
            {...resolve(this.props, 'textbox')}
            value={this.state.textInput || ''}
            onChange={this.onChange}
            onKeyDown={this.onKeyDown}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            children={undefined}
          />
        </div>
      );

      // If the input is disabled or readOnly, do not render the trigger or calendar components.
      if (disabled || readOnly) {
        return triggerElement;
      }

      dateControl = (
        <Trigger {...this.props} {...select(this.props, 'trigger')} ref="trigger">
          {triggerElement}
          <Calendar
            {...this.props}
            {...select(this.props, 'calendar')}
            selectedDate={value && value instanceof Date ? value : null}
            onClick={this.onCalendarClick}
            children={undefined}
          />
        </Trigger>
      );
    }
    return dateControl;
  }
}

type DatePickerFormElementProps = WithFormProps & {
  children?: React.ReactNode;
  style?: {
    input?: any;
  };
};

/**
FormElement wrapper around DatePicker. This is the default export.
**/
const DatePickerFormElement = (props: DatePickerFormElementProps) => {
  const { children, ...rest } = props;
  return (
    <FormElement {...rest}>
      <DatePicker {...rest} {...select(props, 'input')} />
      {children}
    </FormElement>
  );
};
DatePickerFormElement.displayName = 'DatePickerFormElement';

export { DatePickerFormElement };
