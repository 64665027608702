import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';

type Props = React.PropsWithChildren<{
  success?: boolean;
  style?: {
    container?: any;
    indicator?: any;
    message?: any;
    successSpinner?: any;
    successMessage?: any;
  };
}>;

/**
Progress Spinner displays a child message with options for success state
**/
export class ProgressSpinner extends React.Component<Props> {
  static displayName = 'ProgressSpinner';

  render() {
    return (
      <div {...resolve(this.props, 'container')}>
        <div
          {...resolve(this.props, 'indicator', this.props.success ? 'successSpinner' : null)}
        ></div>
        <div {...resolve(this.props, 'message', this.props.success ? 'successMessage' : null)}>
          {this.props.children ? this.props.children : null}
        </div>
      </div>
    );
  }
}
