import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import { removeKeys } from '../utils/removeKeys';

export type BreakPoint = 'mediaSmall' | 'mediaMedium' | 'mediaLarge' | 'mediaAll';
export type ButtonType = 'button' | 'submit' | 'reset';

type Props = React.PropsWithChildren<{
  classes?: {
    button?: string;
    primary?: string;
    secondary?: string;
    disabled?: string;
    link?: string;
  };
  /** The text and title of the Button component, if children is not provided */
  title?: React.ReactNode | string;
  /** Determines if the Button is disabled */
  disabled?: boolean;
  onClick?: (...args: any[]) => any;
  onMouseOver?: (...args: any[]) => any;
  onMouseOut?: (...args: any[]) => any;
  /** Type of the Button component. */
  type?: ButtonType;
  /** Applies styles for the button kind. */
  kind?: string;
  /** Sets the window-width at which Button should transition from a stacked-title layout, to a title-on-the-side layout. */
  breakPoint?: BreakPoint;
  /** Reference to be applied to button element. */
  forwardedRef?: any;
  style?: {
    button?: any;
    primary?: any;
    secondary?: any;
    disabled?: any;
    link?: any;
  };
}>;

/**
  Button is a simple component that will render either the title or child objects
  passed by props.
 **/
class Button extends React.Component<Props> {
  static displayName = 'Button';

  button: any;

  constructor(props: Props) {
    super(props);
  }

  focus = () => {
    if (this.button) this.button.focus();
  };

  render() {
    const {
      children,
      disabled,
      kind = 'primary',
      type = 'button',
      title,
      breakPoint,
      forwardedRef,
      ...rest
    } = removeKeys(this.props, ['classes']);
    return (
      <button
        {...rest}
        ref={forwardedRef || (c => (this.button = c))}
        {...resolve(this.props, 'button', kind, disabled ? 'disabled' : null, breakPoint)}
        {...resolveTestId(this.props)}
        disabled={disabled}
        title={title}
        type={type}
      >
        {children || <span>{title}</span>}
      </button>
    );
  }
}

export { Button };
