import React from 'react';

type Props = {
  sectionRow: any;
  activeDropzone: any;
  parent: any;
  component?: (...args: any[]) => any;
};

function DropItem(props: Props) {
  const { sectionRow, activeDropzone, parent } = props;
  return (
    // @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'props.component' does not have a... Remove this comment to see the full error message
    <props.component
      quadrant={activeDropzone.quadrant}
      canDrop={activeDropzone.canDrop.value}
      target={sectionRow}
      parent={parent}
    />
  );
}
DropItem.displayName = 'DropItem';

/**
 * Generates a drop item component if necessary and returns it
 * in an array with the provided seciton row content at the correct
 * index for proper rendering.
 */
export function addSectionRowDropItem(sectionRowContent: any, props: any) {
  const { sectionRow, activeDropzone } = props;
  const content = [sectionRowContent];
  if (activeDropzone && activeDropzone.id === sectionRow.id) {
    const dropItem = (
      <React.Fragment key={`${sectionRow.id}-${activeDropzone.quadrant}`}>
        <DropItem {...props} />
      </React.Fragment>
    );
    if (activeDropzone.quadrant === 'top') {
      content.unshift(dropItem);
    } else if (activeDropzone.quadrant === 'bottom') {
      content.push(dropItem);
    }
  }
  return content;
}
