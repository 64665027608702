import { pickShadesFromStack } from '@cvent/blocks/formulas';
import { createColorStack } from '@cvent/carina/utils/themeBuilder';
import chroma from 'chroma-js';

export type MoodType = string;
export type MoodPaletteColor = string;
export interface MoodPalette {
  primary: MoodPaletteColor;
  secondary: MoodPaletteColor;
  background: MoodPaletteColor;
  mood: MoodPaletteColor;
}

export interface Mood {
  id: MoodType;
  title: string;
  subtitle: string;
  accessibilityLabel: string;
  moodPalette: MoodPalette;
  moodTileColors: MoodPaletteColor[];
}

function convertToMoodTileOrder(moodPalette: MoodPalette): MoodPaletteColor[] {
  return [
    moodPalette.primary,
    moodPalette.mood,
    moodPalette.background,
    moodPalette.mood,
    moodPalette.secondary
  ];
}

/**
 * Generates a light mood.
 *
 * @param primary the primary color
 * @param secondary the secondary color
 */
export function getLightMood({
  primary,
  secondary
}: {
  primary: MoodPaletteColor;
  secondary: MoodPaletteColor;
}): Mood {
  const moodPalette: MoodPalette = {
    primary,
    secondary,
    background: '#F4F4F4',
    mood: '#FFFFFF'
  };

  return {
    id: 'Light',
    title: 'NucleusSiteEditor_GlobalTheme_Mood1_title__resx',
    subtitle: 'NucleusSiteEditor_GlobalTheme_Mood1_subtitle__resx',
    accessibilityLabel: 'NucleusSiteEditor_GlobalTheme_Mood1_accessibilityLabel__resx',
    moodPalette,
    moodTileColors: convertToMoodTileOrder(moodPalette)
  };
}

/**
 * Generates a dark mood.
 *
 * @param primary the primary color
 * @param secondary the secondary color
 */
export function getDarkMood({
  primary,
  secondary
}: {
  primary: MoodPaletteColor;
  secondary: MoodPaletteColor;
}): Mood {
  const moodPalette: MoodPalette = {
    primary,
    secondary,
    background: '#000000',
    mood: '#212121'
  };

  return {
    id: 'Dark',
    title: 'NucleusSiteEditor_GlobalTheme_Mood2_title__resx',
    subtitle: 'NucleusSiteEditor_GlobalTheme_Mood2_subtitle__resx',
    accessibilityLabel: 'NucleusSiteEditor_GlobalTheme_Mood2_accessibilityLabel__resx',
    moodPalette,
    moodTileColors: convertToMoodTileOrder(moodPalette)
  };
}

/**
 * Generates a color mood.
 *
 * @param primary the primary color
 * @param secondary the secondary color
 */
export function getColorMood({
  primary,
  secondary
}: {
  primary: MoodPaletteColor;
  secondary: MoodPaletteColor;
}): Mood {
  const { accent, container } = pickShadesFromStack(
    createColorStack(primary, 'block', true)?.default,
    false
  );

  const moodPalette: MoodPalette = {
    primary,
    secondary,
    background: chroma(container as string)
      .hex()
      .toUpperCase(),
    mood: chroma(accent as string)
      .hex()
      .toUpperCase()
  };

  return {
    id: 'Color',
    title: 'NucleusSiteEditor_GlobalTheme_Mood3_title__resx',
    subtitle: 'NucleusSiteEditor_GlobalTheme_Mood3_subtitle__resx',
    accessibilityLabel: 'NucleusSiteEditor_GlobalTheme_Mood3_accessibilityLabel__resx',
    moodPalette,
    moodTileColors: convertToMoodTileOrder(moodPalette)
  };
}
