import React from 'react';

import { FlexColumn as Column } from 'nucleus-core';

import { EditorPermissions as Permissions } from '../../../permissions/EditorPermissions';

type Props = {
  layoutItem: any;
  emptyContentComponent?: React.ReactElement;
  canvasPermissions?: any;
  children?: React.ReactNode;
};

/**
 * Default renderer for a SECTION_COLUMN type layout item.
 */
export class SectionColumn extends React.Component<Props> {
  static displayName = 'SectionColumn';
  render() {
    const { layoutItem, children, canvasPermissions } = this.props;
    const showEmptyContent =
      this.props.emptyContentComponent &&
      !React.Children.count(children) &&
      (!canvasPermissions || canvasPermissions.content !== Permissions.NONE);
    return (
      <Column size={layoutItem.layout.colspan}>
        {/* @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'this.props.emptyContentComponent... Remove this comment to see the full error message */}
        {showEmptyContent ? <this.props.emptyContentComponent layoutItem={layoutItem} /> : children}
      </Column>
    );
  }
}
