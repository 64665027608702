import React from 'react';

import isEqual from 'lodash/isEqual';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import MediaQuery from 'react-responsive';

import { BaseWidget } from '../BaseWidget';
import { WebsiteNavigator } from './WebsiteNavigator';
import ClassNames from './WebsiteNavigator.less';
import { WebsiteNavigatorMobileView } from './WebsiteNavigatorMobileView';
import { ThemeableComponentProps } from '../ThemeableComponent';

export interface WebsiteNavigatorProps extends ThemeableComponentProps {
  // Required config due to destructuring in render code
  config: {
    shared: {
      pageLinksArrangement?: 'HORIZONTAL' | 'HAMBURGER';
    };
  };
  classes?: Record<string, unknown>;
  selectedPage?: {
    id: string;
    name: string;
  };
  hideNavigationMenu?: boolean;
  pageData: Array<{
    id?: string;
    name?: string;
    childPages: Record<string, unknown>[];
  }>;
  navigateToPage: (...args: any[]) => any;
  openNavigatorDialog?: (...args: any[]) => any;
  closeNavigatorDialog?: (...args: any[]) => any;
  leftContent?: JSX.Element;
  rightContent?: JSX.Element;
  hamburgerMenuExpanded?: boolean;
}
/**
 * Generic website navigator widget for displaying navigation on
 * a website.
 */
export class WebsiteNavigatorWidget extends BaseWidget<WebsiteNavigatorProps> {
  static displayName = 'WebsiteNavigatorWidget';
  static defaultProps = {
    hideNavigationMenu: false
  };
  // @ts-expect-error ts-migrate(2300) FIXME: Duplicate identifier 'defaultProps'.
  static defaultProps = {
    classes: ClassNames
  };
  shouldComponentUpdate(nextProps: any) {
    return !isEqual(this.props, nextProps);
  }
  getStyleObject() {
    const containerStyles = super.getStyleObject();
    const backgroundColor =
      containerStyles.container.backgroundColor &&
      containerStyles.container.backgroundColor !== 'transparent'
        ? containerStyles.container.backgroundColor
        : // @ts-expect-error ts-migrate(2339) FIXME: Property 'backgroundColor' does not exist on type ... Remove this comment to see the full error message
          this.getParentBackground().backgroundColor ||
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          this.getParentBackground()['--background-color'];
    const pageLinks = this.getElementInlineStyle('pageLinks');
    return {
      ...containerStyles,
      navigation: {
        textAlign: this.getElementText('pageLinks').textAlign
      },
      pageLinks,
      header: {
        ...pageLinks,
        textAlign: 'left'
      },
      verticalMenu: { backgroundColor },
      subMenu: { backgroundColor }
    };
  }
  render() {
    const {
      classes,
      config,
      selectedPage,
      pageData,
      navigateToPage,
      hideNavigationMenu,
      openNavigatorDialog,
      closeNavigatorDialog,
      leftContent,
      rightContent,
      translate,
      style,
      hamburgerMenuExpanded
    } = this.props;
    const { pageLinksArrangement } = config.shared;
    return (
      <MediaQuery query="only screen and (max-width: 30em)">
        {(matches: any) => {
          const Component = matches ? WebsiteNavigatorMobileView : WebsiteNavigator;
          return (
            <Component
              {...resolveTestId(this.props)}
              selectedPage={selectedPage}
              hideNavigationMenu={hideNavigationMenu}
              pageData={pageData}
              navigateToPage={navigateToPage}
              openNavigatorDialog={openNavigatorDialog}
              closeNavigatorDialog={closeNavigatorDialog}
              pageLinksArrangement={pageLinksArrangement}
              leftContent={leftContent}
              rightContent={rightContent}
              classes={classes}
              translate={matches ? translate : undefined}
              style={this.getStyleObject()}
              dimensions={style?.dimensions}
              hamburgerMenuExpanded={hamburgerMenuExpanded}
            />
          );
        }}
      </MediaQuery>
    );
  }
}
