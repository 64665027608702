import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';

import { BaseWidget } from '../BaseWidget';
import { Divider } from './Divider';
import { ThemeableComponentProps } from '../ThemeableComponent';

interface DividerWidgetProps extends ThemeableComponentProps {
  style: Record<string, unknown>;
}
/**
 * Data wrapper for the divider widget.
 */
export class DividerWidget extends BaseWidget<DividerWidgetProps> {
  static displayName = 'DividerWidget';
  getStyleObject() {
    return {
      ...super.getStyleObject(),
      divider: this.getElementInlineStyle('divider')
    };
  }
  render() {
    const { style } = this.props;
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1
    return <Divider {...resolveTestId(this.props)} style={this.getStyleObject(style)} />;
  }
}
