import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';

import { getBrowserDimensions } from '../utils/getBrowserDimensions';
import { getElementSize } from '../utils/getElementSize';
import { getFloatPosition } from '../utils/getFloatPosition';

export type FlyoutMessagePlacementProps = {
  flyoutDirection?: 'horizontal' | 'vertical';
  forceDirection?: {
    horz?: 'left' | 'right' | 'center' | 'auto';
    vert?: 'top' | 'bottom' | 'auto';
  };
};

type Props = React.PropsWithChildren<
  FlyoutMessagePlacementProps & {
    triggerSize?: (...args: any[]) => any;
    style?: {
      container?: any;
      arrow?: any;
      topleft?: any;
      topright?: any;
      bottomleft?: any;
      bottomright?: any;
    };
  }
>;

/**
  Informational Flyout Message Component that is used with InfoFlyout.js.
  Float direction calculated by postition, message size and browser viewport size.
  Basic calculations for mobile browsers.
**/
export class FlyoutMessage extends React.Component<Props> {
  static displayName = 'FlyoutMessage';

  flyout = React.createRef<HTMLDivElement>();

  componentDidMount() {
    // Get elements for inline and className change based on position
    const flyoutElement = this.flyout.current;

    if (!flyoutElement) return;

    const { flyoutDirection, forceDirection = {} } = this.props;
    const { vert, horz } = forceDirection;

    // Calculate float position based on element to browser viewport
    const myFloatPosition: any = {};
    const FloatPosition = getFloatPosition(flyoutElement);

    // Check for overrides before setting float position
    myFloatPosition.vert = !vert || vert === 'auto' ? FloatPosition.vert : vert;
    myFloatPosition.horz = !horz || horz === 'auto' ? FloatPosition.horz : horz;

    const flyoutRect = getElementSize(flyoutElement);
    const elementBoundingClientRect = flyoutElement.getBoundingClientRect();
    const browserMeasures = getBrowserDimensions(window, document);

    const padding = 5;
    if (flyoutDirection === 'horizontal') {
      // Horizontal Position flyout on which direction, size and flyoutOffset //
      flyoutElement.style.top = myFloatPosition.vert === 'bottom' ? '0px' : 'auto';
      flyoutElement.style.bottom = myFloatPosition.vert === 'top' ? '0px' : 'auto';

      flyoutElement.style.left =
        myFloatPosition.horz === 'left' ? `-${flyoutRect.width + padding}px` : 'auto';
      flyoutElement.style.right =
        myFloatPosition.horz === 'right' ? `-${flyoutRect.width + padding}px` : 'auto';
    } else {
      // Vertical Position flyout on which direction, size and flyoutOffset //
      flyoutElement.style.bottom =
        myFloatPosition.vert === 'bottom' ? `-${flyoutRect.height}px` : 'auto';
      flyoutElement.style.top = myFloatPosition.vert === 'top' ? `-${flyoutRect.height}px` : 'auto';

      flyoutElement.style.left = 'auto';
      flyoutElement.style.right = 'auto';
      if (myFloatPosition.horz === 'right') {
        flyoutElement.style.right = `-${padding}px`;
      } else if (myFloatPosition.horz === 'left') {
        flyoutElement.style.left = `-${padding}px`;
      } else if (forceDirection.horz === 'center') {
        myFloatPosition.horz = 'center';
        if (elementBoundingClientRect.left < 0) {
          // Move flyout to the right
          myFloatPosition.horz = 'right';
          flyoutElement.style.transform = 'none';
          flyoutElement.style.marginLeft = 'auto';
        } else if (
          elementBoundingClientRect.left + elementBoundingClientRect.width >
          browserMeasures.browserWidth
        ) {
          // Move flyout to the left
          myFloatPosition.horz = 'left';
          flyoutElement.style.transform = 'none';
        }
      }
    }
  }

  render() {
    const {
      flyoutDirection,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'horz' does not exist on type '{ horz?: "... Remove this comment to see the full error message
      forceDirection: { horz }
    } = this.props;
    const key = flyoutDirection === 'vertical' && horz === 'center' ? 'horizontallyCentered' : '';
    const styles = resolve(this.props, 'container', key);
    return (
      <div {...styles} ref={this.flyout}>
        {this.props.children}
      </div>
    );
  }
}

export const flyoutMessageStyleKeys = [
  'container',
  'horizontallyCentered',
  'vertical',
  'topcenter',
  'bottomcenter',
  'horizontal',
  'bottomright',
  'bottomleft',
  'topright',
  'topleft'
];
