import { unsetV1HoverStyling } from './unsetV1HoverStyling';
import { WebsiteV7 } from './WebsiteV7';

/**
 * Version 7 migrations for the website.
 */
export function v7(website: WebsiteV7) {
  return {
    ...unsetV1HoverStyling(website),
    frameworkVersion: 7
  };
}
