import { Comment } from '../../../types/comment';
import { getAllWidgetsOnPage } from '../../../utils';

export function getAllDeletedCommentWidgetIds(
  commentsByWidgetId: Record<string, Comment[]>,
  state: any
) {
  const widgetsWithComments: string[] = Object.keys(commentsByWidgetId || {});
  let allWidgetIds: string[] = [];

  for (const pageId of Object.keys(state.website.pages)) {
    allWidgetIds = allWidgetIds.concat(
      getAllWidgetsOnPage(state.website, pageId).map((widget: any) => widget.id)
    );
  }

  return widgetsWithComments.filter((widgetId: string) => !allWidgetIds.includes(widgetId));
}

export function getEnabledCommentCount(comments: Comment[]) {
  return comments.reduce((count, comment) => (comment.active ? count + 1 : count), 0);
}

export function hasEnabledComment(comments: Comment[] = []) {
  return comments.some(comment => comment.active);
}

export function toggleCommentActiveStatus(
  commentsByWidgetId: Record<string, Comment[]>,
  widgetIds: string[]
) {
  let found = false;
  const updatedComments = { ...commentsByWidgetId };
  let updatedCommentsCount = 0;

  widgetIds
    .filter(widgetId => Array.isArray(updatedComments[widgetId]))
    .forEach(widgetId => {
      const comments = updatedComments[widgetId];
      let clonedComments: Comment[] = [];

      for (const comment of comments) {
        clonedComments = [...clonedComments, { ...comment, active: !comment.active }];
        found = true;
        updatedCommentsCount++;
      }
      updatedComments[widgetId] = clonedComments;
    });

  return { updatedComments, found, updatedCommentsCount };
}
