const prefix = (key: string) => `nucleus-site-editor/questionLibrary/${key}`;

export const SET_QUESTIONS_PATH = prefix('SET_QUESTIONS_PATH');
export const SET_ALLOWED_QUESTIONS = prefix('SET_ALLOWED_QUESTIONS');
export const CREATE_FOLDER = prefix('CREATE_FOLDER');
export const SET_SHOW_ALERT = prefix('SET_SHOW_ALERT');
export const SET_SAVED_FOLDER = prefix('SET_SAVED_FOLDER');
export const SET_SAVED_SOURCE = prefix('SET_SAVED_SOURCE');
export const SET_SAVED_SEARCH = prefix('SET_SAVED_SEARCH');
export const SET_SHOW_SELECTED = prefix('SET_SHOW_SELECTED');
export const SET_SAVED_CURRENT_PAGE = prefix('SET_SAVED_CURRENT_PAGE');
export const SET_SAVED_SELECTED_QUESTIONS = prefix('SET_SAVED_SELECTED_QUESTIONS');
export const SET_QUESTION_ARRAY = prefix('SET_QUESTION_ARRAY');
export const SET_FOLDER_QUESTIONS = prefix('SET_FOLDER_QUESTIONS');
export const BULK_REPLACE_FOLDERS = prefix('BULK_REPLACE_FOLDERS');
export const SET_QUESTION_LIBRARY_SELECTED = prefix('SET_QUESTION_LIBRARY_SELECTED');
