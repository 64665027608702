import { GuestsideFontList } from '@cvent/fonts';
import { getFontFamilyId } from './getFontFamilyId';

/**
 * Get the values for the font family weights based on what each specific font has available.
 *  NOTE: if fontFamily is 'primary' or 'secondary',
 * it should be converted to one of ids in GuestsideFontList or custom fonts first.
 **/
export function getFontFamilyWeights(fontFamily: any, customFonts: any) {
  const fontFamilyId = getFontFamilyId({ fontFamily, customFonts });

  if (customFonts && customFonts[fontFamilyId]) {
    const customFont = customFonts[fontFamilyId];
    if (customFont && customFont.files) {
      const fonts = customFont.files.map((file: any) => ({
        name: `StyleFields_FontWeight_${file.fontWeight}_${file.fontStyle}__resx`,
        value: { fontWeight: file.fontWeight, fontStyle: file.fontStyle }
      }));
      return fonts;
    }
  }

  switch (fontFamilyId) {
    // Regular, bold
    case GuestsideFontList.AmaticSC:
    case GuestsideFontList.Arial:
    case GuestsideFontList.ComicSansMS:
    case GuestsideFontList.CinzelDecorative:
    case GuestsideFontList.Oswald:
      return [
        {
          name: 'StyleFields_FontWeight_400_normal__resx',
          value: { fontWeight: 400, fontStyle: 'normal' }
        },
        {
          name: 'StyleFields_FontWeight_700_normal__resx',
          value: { fontWeight: 700, fontStyle: 'normal' }
        }
      ];
    // Regular, regular italic
    case GuestsideFontList.ChangaOne:
      return [
        {
          name: 'StyleFields_FontWeight_400_normal__resx',
          value: { fontWeight: 400, fontStyle: 'normal' }
        },
        {
          name: 'StyleFields_FontWeight_400_italic__resx',
          value: { fontWeight: 400, fontStyle: 'italic' }
        }
      ];
    // Regular, regular italic, bold
    case GuestsideFontList.Bitter:
    case GuestsideFontList.OldStandardTT:
    case GuestsideFontList.TimesNewRoman:
    case GuestsideFontList.Georgia:
    case GuestsideFontList.Rubik:
      return [
        {
          name: 'StyleFields_FontWeight_400_normal__resx',
          value: { fontWeight: 400, fontStyle: 'normal' }
        },
        {
          name: 'StyleFields_FontWeight_400_italic__resx',
          value: { fontWeight: 400, fontStyle: 'italic' }
        },
        {
          name: 'StyleFields_FontWeight_700_normal__resx',
          value: { fontWeight: 700, fontStyle: 'normal' }
        }
      ];
    // Regular, regular italic, bold, bold italic
    case GuestsideFontList.Alegreya:
    case GuestsideFontList.Arimo:
    case GuestsideFontList.Arvo:
    case GuestsideFontList.Courier:
    case GuestsideFontList.CrimsonText:
    case GuestsideFontList.DroidSerif:
    case GuestsideFontList.LobsterTwo:
    case GuestsideFontList.Lora:
    case GuestsideFontList.Merriweather:
    case GuestsideFontList.Nunito:
    case GuestsideFontList.OpenSans:
    case GuestsideFontList.Palatino:
    case GuestsideFontList.PlayfairDisplay:
    case GuestsideFontList.PTSans:
    case GuestsideFontList.PTSerif:
    case GuestsideFontList.SourceSans:
    case GuestsideFontList.Tahoma:
    case GuestsideFontList.TitilliumWeb:
    case GuestsideFontList.TrebuchetMS:
    case GuestsideFontList.Ubuntu:
    case GuestsideFontList.Verdana:
    case GuestsideFontList.Vollkorn:
      return [
        {
          name: 'StyleFields_FontWeight_400_normal__resx',
          value: { fontWeight: 400, fontStyle: 'normal' }
        },
        {
          name: 'StyleFields_FontWeight_400_italic__resx',
          value: { fontWeight: 400, fontStyle: 'italic' }
        },
        {
          name: 'StyleFields_FontWeight_700_normal__resx',
          value: { fontWeight: 700, fontStyle: 'normal' }
        },
        {
          name: 'StyleFields_FontWeight_700_italic__resx',
          value: { fontWeight: 700, fontStyle: 'italic' }
        }
      ];
    // Light, regular, regular italic, bold, bold italic
    case GuestsideFontList.Helvetica:
      return [
        {
          name: 'StyleFields_FontWeight_100_normal__resx',
          value: { fontWeight: 100, fontStyle: 'normal' }
        },
        {
          name: 'StyleFields_FontWeight_400_normal__resx',
          value: { fontWeight: 400, fontStyle: 'normal' }
        },
        {
          name: 'StyleFields_FontWeight_400_italic__resx',
          value: { fontWeight: 400, fontStyle: 'italic' }
        },
        {
          name: 'StyleFields_FontWeight_700_normal__resx',
          value: { fontWeight: 700, fontStyle: 'normal' }
        },
        {
          name: 'StyleFields_FontWeight_700_italic__resx',
          value: { fontWeight: 700, fontStyle: 'italic' }
        }
      ];
    // Light, light italic, regular, regular italic, bold, bold italic
    case GuestsideFontList.Lato:
    case GuestsideFontList.Montserrat:
    case GuestsideFontList.Raleway:
    case GuestsideFontList.Roboto:
      return [
        {
          name: 'StyleFields_FontWeight_100_normal__resx',
          value: { fontWeight: 100, fontStyle: 'normal' }
        },
        {
          name: 'StyleFields_FontWeight_100_italic__resx',
          value: { fontWeight: 100, fontStyle: 'italic' }
        },
        {
          name: 'StyleFields_FontWeight_400_normal__resx',
          value: { fontWeight: 400, fontStyle: 'normal' }
        },
        {
          name: 'StyleFields_FontWeight_400_italic__resx',
          value: { fontWeight: 400, fontStyle: 'italic' }
        },
        {
          name: 'StyleFields_FontWeight_700_normal__resx',
          value: { fontWeight: 700, fontStyle: 'normal' }
        },
        {
          name: 'StyleFields_FontWeight_700_italic__resx',
          value: { fontWeight: 700, fontStyle: 'italic' }
        }
      ];
    // Regular
    case GuestsideFontList.AlfaSlabOne:
    case GuestsideFontList.ArialBlack:
    case GuestsideFontList.FredokaOne:
    case GuestsideFontList.FugazOne:
    case GuestsideFontList.Impact:
    case GuestsideFontList.Lemon:
    case GuestsideFontList.Lucida:
    case GuestsideFontList.NixieOne:
    case GuestsideFontList.PinyonScript:
    case GuestsideFontList.Satisfy:
    default:
      return [
        {
          name: 'StyleFields_FontWeight_400_normal__resx',
          value: { fontWeight: 400, fontStyle: 'normal' }
        }
      ];
  }
}

export const FontFamilyOptions = [
  {
    name: 'StyleFields_FontFamily_Alegreya__resx',
    value: GuestsideFontList.Alegreya
  },
  {
    name: 'StyleFields_FontFamily_AlfaSlabOne__resx',
    value: GuestsideFontList.AlfaSlabOne
  },
  {
    name: 'StyleFields_FontFamily_AmaticSC__resx',
    value: GuestsideFontList.AmaticSC
  },
  {
    name: '_fieldOption_arial__resx',
    value: GuestsideFontList.Arial
  },
  {
    name: '_fieldOption_arialBlack__resx',
    value: GuestsideFontList.ArialBlack
  },
  {
    name: 'StyleFields_FontFamily_Arimo__resx',
    value: GuestsideFontList.Arimo
  },
  {
    name: 'StyleFields_FontFamily_Arvo__resx',
    value: GuestsideFontList.Arvo
  },
  {
    name: 'StyleFields_FontFamily_Bitter__resx',
    value: GuestsideFontList.Bitter
  },
  {
    name: 'StyleFields_FontFamily_ChangaOne__resx',
    value: GuestsideFontList.ChangaOne
  },
  {
    name: '_fieldOption_cinzelDecorative__resx',
    value: GuestsideFontList.CinzelDecorative
  },
  {
    name: 'StyleFields_FontFamily_ComicSansMS__resx',
    value: GuestsideFontList.ComicSansMS
  },
  {
    name: '_fieldOption_courier__resx',
    value: GuestsideFontList.Courier
  },
  {
    name: 'StyleFields_FontFamily_CrimsonText__resx',
    value: GuestsideFontList.CrimsonText
  },
  {
    name: 'StyleFields_FontFamily_DroidSerif__resx',
    value: GuestsideFontList.DroidSerif
  },
  {
    name: '_fieldOption_fredokaOne__resx',
    value: GuestsideFontList.FredokaOne
  },
  {
    name: 'StyleFields_FontFamily_Fugaz__resx',
    value: GuestsideFontList.FugazOne
  },
  {
    name: '_fieldOption_georgia__resx',
    value: GuestsideFontList.Georgia
  },
  {
    name: '_fieldOption_Helvetica__resx',
    value: GuestsideFontList.Helvetica
  },
  {
    name: 'StyleFields_FontFamily_Impact__resx',
    value: GuestsideFontList.Impact
  },
  {
    name: 'StyleFields_FontFamily_Lato__resx',
    value: GuestsideFontList.Lato
  },
  {
    name: 'StyleFields_FontFamily_Lemon__resx',
    value: GuestsideFontList.Lemon
  },
  {
    name: 'StyleFields_FontFamily_LobsterTwo__resx',
    value: GuestsideFontList.LobsterTwo
  },
  {
    name: 'StyleFields_FontFamily_Lora__resx',
    value: GuestsideFontList.Lora
  },
  {
    name: 'StyleFields_FontFamily_Lucida__resx',
    value: GuestsideFontList.Lucida
  },
  {
    name: 'StyleFields_FontFamily_Merriweather__resx',
    value: GuestsideFontList.Merriweather
  },
  {
    name: '_fieldOption_montserrat__resx',
    value: GuestsideFontList.Montserrat
  },
  {
    name: '_fieldOption_nixieOne__resx',
    value: GuestsideFontList.NixieOne
  },
  {
    name: 'StyleFields_FontFamily_Nunito__resx',
    value: GuestsideFontList.Nunito
  },
  {
    name: '_fieldOption_oldStandard__resx',
    value: GuestsideFontList.OldStandardTT
  },
  {
    name: 'StyleFields_FontFamily_OpenSans__resx',
    value: GuestsideFontList.OpenSans
  },
  {
    name: '_fieldOption_oswald__resx',
    value: GuestsideFontList.Oswald
  },
  {
    name: 'StyleFields_FontFamily_Palatino__resx',
    value: GuestsideFontList.Palatino
  },
  {
    name: '_fieldOption_ptSans__resx',
    value: GuestsideFontList.PTSans
  },
  {
    name: 'StyleFields_FontFamily_PTSerif__resx',
    value: GuestsideFontList.PTSerif
  },
  {
    name: 'StyleFields_FontFamily_PinyonScript__resx',
    value: GuestsideFontList.PinyonScript
  },
  {
    name: 'StyleFields_FontFamily_PlayfairDisplay__resx',
    value: GuestsideFontList.PlayfairDisplay
  },
  {
    name: '_fieldOption_raleway__resx',
    value: GuestsideFontList.Raleway
  },
  {
    name: '_fieldOption_roboto__resx',
    value: GuestsideFontList.Roboto
  },
  {
    name: '_fieldOption_satisfy__resx',
    value: GuestsideFontList.Satisfy
  },
  {
    name: 'StyleFields_FontFamily_SourceSans__resx',
    value: GuestsideFontList.SourceSans
  },
  {
    name: 'StyleFields_FontFamily_Tahoma__resx',
    value: GuestsideFontList.Tahoma
  },
  {
    name: '_fieldOption_timesNewRoman__resx',
    value: GuestsideFontList.TimesNewRoman
  },
  {
    name: '_fieldOption_titilliumWeb__resx',
    value: GuestsideFontList.TitilliumWeb
  },
  {
    name: 'StyleFields_FontFamily_TrebuchetMS__resx',
    value: GuestsideFontList.TrebuchetMS
  },
  {
    name: 'StyleFields_FontFamily_Ubuntu__resx',
    value: GuestsideFontList.Ubuntu
  },
  {
    name: 'StyleFields_FontFamily_Vollkorn__resx',
    value: GuestsideFontList.Vollkorn
  },
  {
    name: 'StyleFields_FontFamily_Verdana__resx',
    value: GuestsideFontList.Verdana
  },
  {
    name: 'Rubik',
    value: GuestsideFontList.Rubik
  }
];
