import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';

import { BaseWidget } from '../BaseWidget';
import { DateTime } from './DateTime';
import ClassNames from './DateTime.less';
import { ThemeableComponentProps } from '../ThemeableComponent';

interface DateTimeWidgetProps extends ThemeableComponentProps {
  type?: string;
  config?: {
    displayDateOption?: string;
  };
  classes?: Record<string, unknown>;
  // Non-configurable appData props.
  startDate: string;
  endDate?: string;
  timeZone: string;
  translateDate: (...args: any[]) => any;
  translateTime: (...args: any[]) => any;
}
/**
 * A basic date time widget that can be wrapped by any application specific implementation to
 * populate the date time fields.
 */
export class DateTimeWidget extends BaseWidget<DateTimeWidgetProps> {
  static displayName = 'DateTimeWidget';
  static defaultProps = {
    classes: ClassNames
  };
  getStyleObject() {
    return {
      ...super.getStyleObject(),
      date: this.getElementInlineStyle('date'),
      timeTimeZone: this.getElementInlineStyle('timeTimeZone')
    };
  }
  render() {
    const {
      startDate,
      endDate,
      config,
      classes,
      translateTime,
      translateDate,
      translate,
      timeZone
    } = this.props;
    const displayFormat = config?.displayDateOption;

    return (
      <DateTime
        {...resolveTestId(this.props)}
        startDate={startDate}
        endDate={endDate}
        timeZone={timeZone}
        displayFormat={displayFormat}
        translate={translate}
        translateDate={translateDate}
        translateTime={translateTime}
        classes={classes}
        style={this.getStyleObject()}
      />
    );
  }
}
