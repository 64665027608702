import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

export const DATE_TIME_TIMEZONE = 'dateTimeTimezone';
export const TIME_TIMEZONE = 'timeTimezone';
export const DATE_TIME = 'dateTime';
export const DATE = 'date';
export const TIME = 'time';

/**
 * A simple function to determine if the event date exist and not blank.
 */
function dateNotPresent(date: any) {
  return isNaN(Date.parse(date));
}

/**
 * A wrapper function that checks whether date exists
 * and return translated date.
 */
function getTranslatedDate(date: any, translateDate: any, dateFormat: any) {
  const dateString = dateNotPresent(date)
    ? ''
    : translateDate(new Date(date), dateFormat || 'long');
  return dateString;
}

/**
 * A wrapper function that checks whether date exists
 * and return translated time.
 */
function getTranslatedTime(date: any, translateTime: any) {
  const timeString = dateNotPresent(date) ? '' : translateTime(new Date(date));
  return timeString;
}

type Props = {
  startDate: string;
  endDate?: string;
  timeZone: string;
  // Used in resolve function // TODO: determine neccesary fields for classes
  classes?: Record<string, unknown>;
  // Used in resolve function
  style?: Record<string, unknown>;
  displayFormat?: any; // TODO: PropTypes.oneOf([DATE_TIME_TIMEZONE, DATE_TIME, DATE, TIME, TIME_TIMEZONE])
  translate: (...args: any[]) => any;
  translateDate: (...args: any[]) => any;
  translateTime: (...args: any[]) => any;
  dateFormat?: ('short' | 'medium' | 'long' | 'full') | any;
};

/**
 * The basic date time range widget UI implementation.
 */
export class DateTime extends React.Component<Props> {
  static displayName = 'DateTimeComponent';
  getDateRange = () => {
    const { translate, translateDate, dateFormat } = this.props;
    const startDate = getTranslatedDate(this.props.startDate, translateDate, dateFormat);
    const endDate = getTranslatedDate(this.props.endDate, translateDate, dateFormat);
    return !endDate || startDate === endDate
      ? startDate
      : translate('Widget_DateTimeWidget_DateRangeText__resx', {
          startDate,
          endDate
        });
  };
  getTimeRange = () => {
    const { startDate, endDate, translateTime, translate } = this.props;
    const startTime = getTranslatedTime(startDate, translateTime);
    const endTime = getTranslatedTime(endDate, translateTime);
    return !endTime
      ? startTime
      : translate('Widget_DateTimeWidget_TimeRangeText__resx', {
          startTime,
          endTime
        });
  };

  render() {
    const { timeZone, displayFormat } = this.props;
    const displayTime =
      displayFormat === DATE_TIME ||
      displayFormat === DATE_TIME_TIMEZONE ||
      displayFormat === TIME ||
      displayFormat === TIME_TIMEZONE;
    const displayTimezone = displayFormat === DATE_TIME_TIMEZONE || displayFormat === TIME_TIMEZONE;
    const displayDate =
      displayFormat === DATE_TIME || displayFormat === DATE_TIME_TIMEZONE || displayFormat === DATE;

    return (
      <div {...resolveTestId(this.props)} {...resolve(this.props, 'container')}>
        <div {...resolve(this.props, 'date')}>{displayDate && this.getDateRange()}</div>
        <div {...resolve(this.props, 'timeTimeZone')}>
          {displayTime && this.getTimeRange()}
          {displayTimezone && <span {...resolve(this.props, 'timeZone')}>{timeZone}</span>}
        </div>
      </div>
    );
  }
}
