import React from 'react';

import { select, resolve } from '@cvent/nucleus-dynamic-css';
import Icon from '@cvent/nucleus-icon';

import { tapOrClick } from '../../touchEventHandlers';

import { removeKeys } from '../../utils/removeKeys';
import { CheckboxFormElement as Checkbox } from './Checkbox';
import { TextboxFormElement as Textbox } from './Textbox';
import { BreakPoint } from '../../buttons';

/**
 * Removes any MultiSelectDropDownMenu specific properties from a props object that would be invalid
 * if applied to a base DOM node. Useful when composing multiple components to prevent
 * passing a component props that it doesn't use.
 */
export function removeMultiSelectDropdownMenuProps(props: any) {
  return removeKeys(props, [
    'options',
    'fieldName',
    'enableFilter',
    'filterFunction',
    'filterLabel',
    'filterPlaceholderText',
    'enableSelectAll',
    'selectAllText',
    'enableDeselectAll',
    'deselectAllText',
    'onNativeChange',
    'selectedValues',
    'classes',
    'inlineLabel',
    'inlineElement'
  ]);
}

/**
MultiSelectMenu Component

onChange = function(fieldName, newValue, newOptions)
**/

function getNewSelectedValues(selectedValues: any, value: any) {
  const newSelectedValues = selectedValues.slice(0);
  const indexInSelectedValues = selectedValues.indexOf(value);
  if (indexInSelectedValues !== -1) {
    newSelectedValues.splice(indexInSelectedValues, 1);
  } else {
    newSelectedValues.push(value);
  }
  return newSelectedValues;
}

type OwnProps = {
  options: any | any[];
  id?: string;
  name?: string;
  fieldName: string;
  enableFilter?: boolean;
  filterFunction?: (...args: any[]) => any;
  filterLabel?: string;
  filterPlaceholderText?: string;
  enableSelectAll?: boolean;
  selectAllText?: string;
  enableDeselectAll?: boolean;
  deselectAllText?: string;
  breakPoint?: BreakPoint;
  onChange?: any; // TODO: isRequiredIf(PropTypes.func, props => !props.hasOwnProperty('onNativeChange'))
  onNativeChange?: any; // TODO: isRequiredIf(PropTypes.func, props => !props.hasOwnProperty('onChange'))
  selectedValues?: any; // TODO: isRequiredIf(PropTypes.array, props => props.hasOwnProperty('onNativeChange'))
  style?: {
    checkbox?: any;
    container?: any;
    header?: any;
    filter?: any;
    selectAll?: any;
    deselectAll?: any;
  };
};

type State = any;

type Props = OwnProps & typeof MultiSelectDropDownMenu.defaultProps;

export class MultiSelectDropDownMenu extends React.Component<Props, State> {
  static displayName = 'MultiSelectDropDownMenu';
  static defaultProps = {
    enableFilter: true,
    enableSelectAll: true,
    enableDeselectAll: true
  };
  constructor(props: Props) {
    super(props);
    this.state = {
      filterText: ''
    };
    this.onChange = this.onChange.bind(this);
    this.onGroupItemChange = this.onGroupItemChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.getFilteredOptions = this.getFilteredOptions.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.deselectAll = this.deselectAll.bind(this);
  }
  /**
   * OnChange function for the Checkbox component. Ideally we would just pass the onChange
   * property straight through to the Checkbox, but that will break things if the filtering
   * feature is being used when the onChange fires.
   */
  onChange(fieldName: any, newValue: any, newOptions: any) {
    // Options are not being filtered, so just pass through the normal onChange behavior.
    if (!this.state.filterText) {
      if (
        this.props.hasOwnProperty('onNativeChange') &&
        this.props.hasOwnProperty('selectedValues')
      ) {
        this.props.onNativeChange(newOptions.selectedValues);
      } else {
        this.props.onChange(fieldName, newValue, newOptions);
      }
      return;
    }
    // This mostly duplicates the Checkbox component's built in onChange function in order to handle
    // changing selections while options are being filtered.
    if (
      this.props.hasOwnProperty('onNativeChange') &&
      this.props.hasOwnProperty('selectedValues')
    ) {
      this.props.onNativeChange(getNewSelectedValues(this.props.selectedValues, newValue));
      return;
    }
    let newOptionList;
    if (this.props.options.hasOwnProperty('selectedValues')) {
      newOptionList = Object.assign({}, this.props.options, {
        selectedValues: getNewSelectedValues(this.props.options.selectedValues, newValue)
      });
    } else {
      newOptionList = this.props.options.map((option: any) => {
        return option.value !== newValue
          ? option
          : Object.assign({}, option, { checked: !option.checked });
      });
    }

    this.props.onChange(fieldName, newValue, newOptionList);
  }

  /**
   * OnChange function for the Checkbox components when the options property is
   * specifying category groups. Each group is created from a separate Checkbox component, so
   * the onChange needs to be customized to fit in with the larger list of options.
   */
  onGroupItemChange(index: any, fieldName: any, newValue: any) {
    const newGrouping = {
      [index]: Object.assign({}, this.props.options[index], {
        items: this.props.options[index].items.map((item: any) => {
          return item.value !== newValue
            ? item
            : Object.assign({}, item, { checked: !item.checked });
        })
      })
    };
    const newOptionList = Object.assign([], this.props.options, newGrouping);
    if (
      this.props.hasOwnProperty('onNativeChange') &&
      this.props.hasOwnProperty('selectedValues')
    ) {
      this.props.onNativeChange(getNewSelectedValues(this.props.selectedValues, newValue));
    } else {
      this.props.onChange(this.props.fieldName, newValue, newOptionList);
    }
  }

  /**
   * Sets the filter text in the state.
   */
  onFilterChange(fieldName: any, newValue: any) {
    this.setState({ filterText: newValue });
  }

  /**
   * Filters the provided options object using the state's filter text.
   */
  getFilteredOptions(options: any) {
    if (!this.state.filterText) {
      return options;
    }
    if (this.props.filterFunction) {
      return this.props.filterFunction(this.state.filterText, options);
    }
    if (options.hasOwnProperty('selectedValues')) {
      return {
        selectedValues: options.selectedValues,
        optionArray: options.optionArray.filter((option: any) => {
          return option.filterText
            ? option.filterText.toUpperCase().includes(this.state.filterText.toUpperCase())
            : option.name.toUpperCase().includes(this.state.filterText.toUpperCase());
        })
      };
    }
    return options.filter((option: any) => {
      return option.filterText
        ? option.filterText.toUpperCase().includes(this.state.filterText.toUpperCase())
        : option.name.toUpperCase().includes(this.state.filterText.toUpperCase());
    });
  }

  /**
   * Select all options in the options property.
   */
  selectAll(e: any) {
    e.preventDefault();
    let newOptions;
    const isNucleusField =
      this.props.hasOwnProperty('onNativeChange') && this.props.hasOwnProperty('selectedValues');

    if (this.props.options.hasOwnProperty('selectedValues')) {
      const selectedValues = this.props.options.optionArray
        .map((option: any) => {
          return !option.disabled || this.props.options.selectedValues.indexOf(option.value) >= 0
            ? option.value
            : null;
        })
        .filter((option: any) => option !== null);
      if (isNucleusField) {
        this.props.onNativeChange(selectedValues);
        return;
      }
      newOptions = Object.assign({}, this.props.options, { selectedValues });
    } else if (this.props.options.length && this.props.options[0].hasOwnProperty('category')) {
      const selectedValues: any = [];
      newOptions = this.props.options.map((category: any) => {
        return Object.assign({}, category, {
          items: category.items.map((option: any) => {
            selectedValues.push(option.value);
            return Object.assign({}, option, option.disabled ? {} : { checked: true });
          })
        });
      });
      if (isNucleusField) {
        this.props.onNativeChange(selectedValues);
        return;
      }
    } else {
      const selectedValues: any = [];
      const filteredOptions = this.getFilteredOptions(this.props.options);
      newOptions = this.props.options.map((option: any) => {
        selectedValues.push(option.value);
        return Object.assign(
          {},
          option,
          option.disabled || !filteredOptions.includes(option) ? {} : { checked: true }
        );
      });
      if (isNucleusField) {
        this.props.onNativeChange(selectedValues);
        return;
      }
    }
    this.props.onChange(this.props.fieldName, null, newOptions);
  }

  /**
   * Deselect all options in the options property.
   */
  deselectAll(e: any) {
    e.preventDefault();
    if (
      this.props.hasOwnProperty('onNativeChange') &&
      this.props.hasOwnProperty('selectedValues')
    ) {
      this.props.onNativeChange([]);
      return;
    }
    let newOptions;
    if (this.props.options.hasOwnProperty('selectedValues')) {
      const selectedDisabledValues = this.props.options.optionArray
        .map((option: any) => {
          return option.disabled && this.props.options.selectedValues.indexOf(option.value) >= 0
            ? option.value
            : null;
        })
        .filter((option: any) => option !== null);
      newOptions = Object.assign({}, this.props.options, {
        selectedValues: selectedDisabledValues
      });
    } else if (this.props.options.length && this.props.options[0].hasOwnProperty('category')) {
      newOptions = this.props.options.map((category: any) => {
        return Object.assign({}, category, {
          items: category.items.map((option: any) => {
            return Object.assign({}, option, option.disabled ? {} : { checked: false });
          })
        });
      });
    } else {
      newOptions = this.props.options.map((option: any) => {
        return Object.assign({}, option, option.disabled ? {} : { checked: false });
      });
    }
    this.props.onChange(this.props.fieldName, null, newOptions);
  }

  render() {
    let content;
    if (this.props.options.length && this.props.options[0].hasOwnProperty('category')) {
      content = this.props.options
        .map((category: any, index: any) => {
          const filteredOptions = this.getFilteredOptions(category.items);
          if (!filteredOptions.length) {
            return null;
          }
          return (
            <Checkbox
              {...this.props}
              {...select(this.props, 'checkbox')}
              key={index}
              id={(this.props.id || this.props.fieldName) + index}
              name={(this.props.name || this.props.fieldName) + index}
              fieldName={category.category}
              label={category.category}
              options={filteredOptions}
              onChange={this.onGroupItemChange.bind(null, index)}
            />
          );
        })
        .filter((component: any) => {
          return component !== null;
        });
    } else {
      content = (
        <Checkbox
          {...this.props}
          {...select(this.props, 'checkbox')}
          label=""
          hideLabel
          options={this.getFilteredOptions(this.props.options)}
          onChange={this.onChange}
          children={undefined}
        />
      );
    }
    const { breakPoint } = this.props;
    const showHeader =
      this.props.enableFilter || this.props.enableSelectAll || this.props.enableDeselectAll;
    return (
      <div {...resolve(this.props, 'container', breakPoint)} ref="menu">
        {showHeader && (
          <div {...resolve(this.props, 'header')}>
            {this.props.enableFilter ? (
              <Textbox
                {...select(this.props, 'filter')}
                fieldName="filter"
                label={this.props.filterLabel}
                placeholder={this.props.filterPlaceholderText}
                value={this.state.filterText}
                onChange={this.onFilterChange}
              />
            ) : null}
            {this.props.enableSelectAll ? (
              <a href="#" {...resolve(this.props, 'selectAll')} {...tapOrClick(this.selectAll)}>
                <Icon icon={'checkFilled'} fallbackText={'Select All'} />
                {this.props.selectAllText}
              </a>
            ) : null}
            {this.props.enableDeselectAll ? (
              <a href="#" {...resolve(this.props, 'deselectAll')} {...tapOrClick(this.deselectAll)}>
                <Icon icon={'closeDeleteFilled'} fallbackText={'Select All'} />
                {this.props.deselectAllText}
              </a>
            ) : null}
          </div>
        )}
        {content}
      </div>
    );
  }
}

/**
#### Data structures for the "options" property
Data structure for the "options" property mirrors that of the Checkbox form element, but each option has an additional
available property called `filterText` that can be used to change the text the options are filtered by.

If filterText is provided, it will be used as the text for filtering instead of name.
If disabled is set, that option will be disabled in the select.

```js
  [
    {name: 'optionNameA', value: 'optionValueA', checked: true, disabled: true},
    {name: 'optionNameB', value: 'optionValueB', checked: false, filterText: 'optionNameA'},
    ...
  ]
```

```js
  [
    {category: 'categoryNameA', items:
      [
        {name: 'optionNameA', value: 'optionValueA', checked: true},
        ...
      ]
    },
    {category: 'categoryNameB', items:
      [
        {name: 'optionNameB', value: 'optionValueB', checked: false},
        ...
      ]
    }
  ]
```

```js
  {
    selectedValues: ['optionValueA'],
    optionArray: [
      {name: 'optionNameA', value: 'optionValueA'},
      {name: 'optionNameB', value: 'optionValueB'},
      ...
    ]
  }
```
**/
