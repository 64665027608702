// @flow

// todo: enable flow checks

import { LOCATION } from 'cvent-event-calendar/lib/CalendarView/Constants';
import calendarFiltersReducer from 'cvent-event-calendar/redux/modules/calendarFilters';
import { ALL_FILTERS_SELECTED } from 'nucleus-core';

import { searchCalendarEvents } from './calendar';
import type { Action, Dispatch, GetState, CalendarFilters, SelectedFilter, Location } from './types';

const OPEN_FILTERS_DIALOG = 'calendar-guest-site/calendarFilters/OPEN_FILTERS_DIALOG';
const CLOSE_FILTERS_DIALOG = 'calendar-guest-site/calendarFilters/CLOSE_FILTERS_DIALOG';

const DEFAULT_STATE = {
  filters: [],
  showFiltersDialog: false
};

const getNotAllFiltersSelectedValues = (selectedValues: $TSFixMe): $TSFixMe => {
  return selectedValues.filter((value: $TSFixMe) => {
    return value !== ALL_FILTERS_SELECTED;
  });
};

/**
 * Returns the filters criteria with updated selections
 */
function getUpdatedSelections(
  filterId?: string,
  selectedValues?: Array<string> | Location,
  // @ts-expect-error TS(1016): A required parameter cannot follow an optional par... Remove this comment to see the full error message
  selectedFilters: SelectedFilter,
  type: string,
  cventFieldType: number
) {
  if (!filterId || !selectedValues) {
    return selectedFilters;
  }
  const newSelection = {};
  newSelection[filterId] = {
    type,
    cventFieldType,
    field: filterId,
    values: type === LOCATION ? selectedValues : getNotAllFiltersSelectedValues(selectedValues)
  };
  return Object.assign({}, selectedFilters, newSelection);
}

/**
 * Updates filter selections
 */
// @ts-expect-error TS(1016): A required parameter cannot follow an optional par... Remove this comment to see the full error message
export function updateSelections(filterId?: string, selectedValues?: Array<string> | Location, type: string, forMonth: boolean = false) {
  return (dispatch: Dispatch, getState: GetState) => {
    const {
      calendarFilters,
      searchCriteria: { filters, ...rest }
    } = getState();
    const selectedFilter = calendarFilters.filters[filterId] || {};
    const updatedCriteria = {
      ...rest,
      filters: getUpdatedSelections(filterId, selectedValues, filters, type, selectedFilter.cventFieldType || null)
    };
    // @ts-expect-error TS(2345): Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
    dispatch(searchCalendarEvents(updatedCriteria, forMonth));
  };
}

/**
 * Resets calendar filters
 */
export function resetFilters(forMonth: boolean = false) {
  return (dispatch: Dispatch, getState: GetState) => {
    const { searchCriteria } = getState();
    const updatedCriteria = {
      ...searchCriteria,
      filters: {}
    };
    // @ts-expect-error TS(2345): Argument of type '(dispatch: Dispatch, getState: G... Remove this comment to see the full error message
    dispatch(searchCalendarEvents(updatedCriteria, forMonth));
  };
}

export function openFiltersDialog(scrollTop: number) {
  return {
    type: OPEN_FILTERS_DIALOG,
    payload: {
      scrollTop
    }
  };
}

export function closeFiltersDialog() {
  return {
    type: CLOSE_FILTERS_DIALOG
  };
}

/**
 * Reducer to handle filters related actions
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function reducer(state: CalendarFilters = DEFAULT_STATE, action: Action): CalendarFilters {
  switch (action.type) {
    case OPEN_FILTERS_DIALOG: {
      // @ts-expect-error TS(2339): Property 'scrollTop' does not exist on type 'Objec... Remove this comment to see the full error message
      const { scrollTop } = action.payload;
      return {
        ...state,
        showFiltersDialog: true,
        // @ts-expect-error TS(2322): Type '{ showFiltersDialog: true; scrollTop: $TSFixMe; f... Remove this comment to see the full error message
        scrollTop
      };
    }
    case CLOSE_FILTERS_DIALOG: {
      return {
        ...state,
        showFiltersDialog: false,
        // @ts-expect-error TS(2322): Type '{ showFiltersDialog: false; scrollTop: numbe... Remove this comment to see the full error message
        scrollTop: 0
      };
    }
    default: {
      // @ts-expect-error TS(2345): Argument of type 'CalendarFilters' is not assignabl... Remove this comment to see the full error message
      return calendarFiltersReducer(state, action);
    }
  }
}
