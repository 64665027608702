import React from 'react';
export const Tooltip = (translate: any, editorTheme: any) => {
  const { PanelStyle } = editorTheme;
  return (
    <React.Fragment>
      <div className={PanelStyle.classes.heading3}>
        {translate('NucleusSiteEditor_ImageEditor_Tooltip_MainHeader__resx')}
      </div>
      {translate('NucleusSiteEditor_ImageEditor_Tooltip_MainContent__resx')}
      <div
        className={`${PanelStyle.classes.heading3} ${PanelStyle.classes.headerWithSpacingOnTop}`}
      >
        {translate('NucleusSiteEditor_ImageEditor_Tooltip_SubHeader__resx')}
      </div>
      {translate('NucleusSiteEditor_ImageEditor_Tooltip_SubContent__resx')}
    </React.Fragment>
  );
};
