import React from 'react';

import { TEMPLATE_TOP_BOTTOM, AlertPanel } from 'nucleus-widgets';
import CanvasFeedbackPanelClasses from 'nucleus-widgets/lib/components/CanvasFeedbackPanel/style.less';
import { isFeatureFlagEnabled } from '@cvent/nucleus-platform';
import { connect } from 'react-redux';

import { Content } from './Content';

type Props = {
  page: any;
  templatePage?: any;
  questionLibraryEnabled: boolean;
};

// eslint-disable-next-line no-unused-vars

/**
 * The default page renderer for guestside sites with NO styles.
 */
export class DefaultPageRendererBase extends React.Component<Props> {
  static displayName = 'DefaultPageRenderer';
  render() {
    const { page, templatePage, questionLibraryEnabled } = this.props;
    if (templatePage && templatePage.type === TEMPLATE_TOP_BOTTOM) {
      return (
        <>
          {questionLibraryEnabled ||
            (isFeatureFlagEnabled('Comments') && (
              <AlertPanel classes={CanvasFeedbackPanelClasses.guestsideContainer} />
            ))}
          <Content rootLayoutItemId={templatePage.rootLayoutItemIds[0]} />
          <Content rootLayoutItemId={page.rootLayoutItemIds[0]} />
          <Content rootLayoutItemId={templatePage.rootLayoutItemIds[1]} />
        </>
      );
    }
    return <Content rootLayoutItemId={page.rootLayoutItemIds[0]} />;
  }
}

export const DefaultPageRenderer = connect((state: any) => {
  return {
    questionLibraryEnabled: 'questionLibrary' in state
  };
})(DefaultPageRendererBase);
