const text1 = {
  text: {
    fontFamily: 'PT Sans, sans-serif',
    fontWeight: 300,
    fontSize: 22,
    color: 'textAccent',
    lineHeight: 1.3
  },
  background: {
    image: {},
    color: null,
    borderRadius: 0
  },
  border: {
    thickness: 0,
    color: null,
    borderStyle: 'solid'
  },
  spacing: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  }
};
const text2 = {
  text: {
    fontFamily: 'PT Sans, sans-serif',
    fontWeight: 300,
    fontSize: 18,
    color: 'textAccent',
    lineHeight: 1.3
  },
  background: {
    image: {},
    color: null,
    borderRadius: 0
  },
  border: {
    thickness: 0,
    color: null,
    borderStyle: 'solid'
  },
  spacing: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  }
};

/**
 * Add new text1 and text2 global theme elements.
 */
export function themeMigrations(theme: any) {
  const updatedTheme = { ...theme };

  // Migrate from version undefined to version 1.
  // Introduction of new text1 and text2 global elements.
  if (!theme.version) {
    updatedTheme.version = 1;
    const newElements = {};
    if (!updatedTheme.global.elements.hasOwnProperty('text1')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'text1' does not exist on type '{}'.
      newElements.text1 = text1;
    }
    if (!updatedTheme.global.elements.hasOwnProperty('text2')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'text2' does not exist on type '{}'.
      newElements.text2 = text2;
    }
    updatedTheme.global = {
      ...theme.global,
      elements: {
        ...theme.global.elements,
        ...newElements
      }
    };
  }

  return updatedTheme;
}
