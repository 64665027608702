import { layoutItemMigrations } from './layoutItems';

/**
 * Version 5 migrations for the website.
 * - Replacing "widgetType: 'Section'" with "sectionType: 'Section'" in section layout items.
 * - If for some reason the item is a 'sectionRow' without a sectionType, add the default "sectionType: 'Section'".
 */
export function v5(website: any) {
  return {
    ...website,
    frameworkVersion: 5,
    layoutItems: layoutItemMigrations(website.layoutItems)
  };
}
