import { getAllChildWidgetsInItem } from './getAllChildWidgetsInItem';

/**
 * Returns widgets on one page by running a recursive function.
 * @param website - website context
 * @param pageId - page id of which widget needs to be returned.
 */
export function getAllWidgetsOnPage(website: any, pageId: any) {
  if (!website.pages[pageId]) {
    return [];
  }
  const widgets: any = [];
  website.pages[pageId].rootLayoutItemIds.forEach((layoutItemId: any) => {
    const item = website.layoutItems[layoutItemId];
    if (item) {
      getAllChildWidgetsInItem(website.layoutItems, item, widgets);
    }
  });
  return widgets;
}
