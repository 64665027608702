/**
 * Tags component for displaying tags as a pill or dot
 */

import React from 'react';

import PropTypes from 'prop-types';

import { TAG_TYPE } from '../Constants';
import { tagsPropType, calendarItemPropTypes } from '../propTypes';
import Dot from './Dot';
import Pill from './Pill';

const Tags = (props: $TSFixMe) => {
  const { type, numberOfTagsToDisplay } = props;
  if (type === TAG_TYPE.PILL) {
    const { tags, classes, style } = props;
    
    return <Pill tags={tags} classes={classes} style={style} numberOfTagsToDisplay={numberOfTagsToDisplay} />;
  }
  const {
    title,
    event: { combinedTags }
  } = props;
  
  return <Dot title={title} tags={combinedTags} numberOfTagsToDisplay={numberOfTagsToDisplay} />;
};

Tags.propTypes = {
  type: PropTypes.oneOf([TAG_TYPE.PILL, TAG_TYPE.DOT]),
  numberOfTagsToDisplay: PropTypes.number,
  translate: PropTypes.func,
  tags: tagsPropType,
  classes: PropTypes.object,
  style: PropTypes.object,
  title: PropTypes.string,
  event: calendarItemPropTypes
};

Tags.displayName = 'Tags';
export default Tags;
