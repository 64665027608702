import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import Icon from '@cvent/nucleus-icon';

import { InteractiveElement } from '../containers/InteractiveElement';
import { tapOrClick } from '../touchEventHandlers';
import { Message } from './Message';

type Props = {
  closeIconText?: string;
  onClose?: (...args: any[]) => any;
  children?: React.ReactNode;
  style?: {
    closeIcon?: any;
  };
  classes?: any;
};

/**
A feedback message component that composes the Message component,
adding a close icon link for the user to provide a close callback.
**/
const FeedbackMessage = (props: Props) => {
  const { closeIconText, onClose, classes = {}, style = {}, children } = props;
  return (
    // @ts-expect-error ts-migrate(2559) FIXME: Type '{ closeIcon?: any; }' has no properties in c... Remove this comment to see the full error message
    <Message {...props} classes={classes} style={style}>
      {children || null}
      {onClose ? (
        <InteractiveElement {...resolve(props, 'closeButton')} {...tapOrClick(props.onClose)}>
          <Icon
            icon="closeDeleteFilled"
            modifier={classes.closeIcon}
            fallbackText={closeIconText}
          />
        </InteractiveElement>
      ) : null}
    </Message>
  );
};
FeedbackMessage.displayName = 'FeedbackMessage';

export { FeedbackMessage };
