export const ALL_FILTERS_SELECTED = 'nucleus-core/ALL_FILTERS_SELECTED';

/**
 * Builds filter values given the provided data
 */
export function buildFilterValues(
  options: any,
  translate: any,
  showAll: any,
  sliceOptions: any,
  numberOfOptionsToShow: any,
  selectedValues: any
) {
  // Build the options with an option selected marked as checked
  let newOptions = options.map((option: any) => {
    return Object.assign({}, option, {
      checked: selectedValues.indexOf(option.value) !== -1
    });
  });
  // Slice the options according to the number provided
  if (sliceOptions && options.length > numberOfOptionsToShow) {
    newOptions = options.slice(0, numberOfOptionsToShow);
  }
  // Add Select All to each filter, if it should be added
  if (showAll && newOptions.length > 1) {
    newOptions.unshift({
      name: translate('NucleusCore_Filters_SelectAll__resx'),
      value: ALL_FILTERS_SELECTED,
      checked: selectedValues.indexOf(ALL_FILTERS_SELECTED) !== -1
    });
  }

  return newOptions;
}
