import ClassNames from './Image.less';
import { ImageWidget } from './ImageWidget';

const ContainerlessImageClasses = {
  ...ClassNames,
  container: undefined
};

/**
 * Modified extension of the default image widget that has no
 * container classes / style properties.
 */
export class ContainerlessImageWidget extends ImageWidget {
  static displayName = 'ContainerlessImageWidget';
  static defaultProps = {
    classes: ContainerlessImageClasses
  };
  getStyleObject(): any {
    return {
      ...super.getStyleObject(),
      container: undefined
    };
  }
}
