/**
 *
 * 1) References to "className" and "style" are removed from the DateHeader function
 *    copied from react-big-calendar@v0.14.0., and replaced by "resolve" and "select" to apply styles.
 * 2) Use InteractiveElement in place of <a href="#" />
 * 3) Add isToday prop to apply styles to the date label that matches today.
 *
 * There are no functional changes.
 */

import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';

import { InteractiveElement } from '../containers/InteractiveElement';

export const dateHeaderStyleKeys = [
  'dateLabel',
  'offRangeDateLabel',
  'currentDateLabel',
  'todayLabel'
];

type Props = {
  label?: string;
  drillDownView?: string;
  onDrillDown?: (...args: any[]) => any;
  isOffRange?: boolean;
  isCurrent?: boolean;
  isToday?: boolean;
  classes?: any;
  style?: {
    dateLabel?: any;
    offRangeDateLabel?: any;
    currentDateLabel?: any;
  };
};

const DateHeader = ({
  label,
  drillDownView,
  onDrillDown,
  isOffRange,
  isCurrent,
  isToday,
  ...props
}: Props) => {
  if (!drillDownView) {
    return (
      <span
        {...resolve(
          props,
          'dateLabel',
          isOffRange ? 'offRangeDateLabel' : undefined,
          isCurrent ? 'currentDateLabel' : undefined,
          isToday ? 'todayLabel' : undefined
        )}
      >
        {label}
      </span>
    );
  }

  return (
    <InteractiveElement element="span" onClick={onDrillDown}>
      {label}
    </InteractiveElement>
  );
};

export { DateHeader };
