import { getBrowserDimensions } from './getBrowserDimensions';

/**
 * Returns float position based on placement in the browser. (element)
 *
 * @param {element} element you are evaluating
 * @return {object} object myFloatPosition Position of Element
 */
export function getFloatPosition(element: any) {
  const myFloatPosition: any = [];
  const browserMeasures = getBrowserDimensions(window, document);
  const elementRect = element.getBoundingClientRect();
  if (elementRect.top > browserMeasures.browserHeight - elementRect.height) {
    myFloatPosition.vert = 'top';
  } else {
    myFloatPosition.vert = 'bottom';
  }

  if (elementRect.left > browserMeasures.browserWidth / 2) {
    myFloatPosition.horz = 'left';
  } else {
    myFloatPosition.horz = 'right';
  }
  return myFloatPosition;
}
