/**
 * A wrapper around modal that displays the event information
 */
import React from 'react';

import { select } from '@cvent/nucleus-dynamic-css';
import Icon from '@cvent/nucleus-icon';
import { Dialog as PopUpDialog, InteractiveElement, DialogRenderedOutsideParentApp, isTouchEnabled } from 'nucleus-core';
import DialogStyles from 'nucleus-core/less/cv/Dialog.less';
import AnimationFadeStyles from 'nucleus-core/less/cv/Dialog.transitionFade.less';
import PropTypes from 'prop-types';

import CalendarItemDetailView from './CalendarItemDetailView';
import CalendarModalStyles from './styles/CalendarModal.less';

const defaultDialogStyles = Object.assign({}, DialogStyles, {
  ...CalendarModalStyles,
  transition: AnimationFadeStyles
});

const DETAIL_MODAL_WIDTH = 700; // default dragContainer width
const DETAIL_MODAL_HEIGHT = 350; // approximate height of dragContainer for event details
const MOBILE_WIDTH_BREAKPOINT_EM = 48; // the size at which the @media rule determines a mobile browser, in ems
const FONT_SIZE = 16; // the default font size of the modal

class CalendarModal extends React.Component<any, any> {
  static displayName = 'CalendarModal';
  static propTypes = {
    classes: PropTypes.object,
    style: PropTypes.object,
    scrollTop: PropTypes.number,
    getInheritedStyles: PropTypes.func,
    handleModalClose: PropTypes.func.isRequired,
    translate: PropTypes.func.isRequired,
    eventDetails: PropTypes.object,
    iconSettings: PropTypes.object,
    isWebWidget: PropTypes.bool,
    dateTimeFormat: PropTypes.object,
    isModalOpen: PropTypes.bool,
    referenceId: PropTypes.string,
    getParentWindowDimensions: PropTypes.func,
    scrollToTop: PropTypes.func,
    parentWindowDimensions: PropTypes.object
  };

  constructor(props: $TSFixMe) {
    super(props);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.state = {
      modalLocation: null
    };
  }

  componentWillReceiveProps(nextProps: $TSFixMe) {
    const { isModalOpen, getParentWindowDimensions, parentWindowDimensions } = nextProps;
    const rootElement = document.documentElement || document.body.parentNode;
    if (!isModalOpen) {
      return;
    }

    if (isTouchEnabled()) {
      // Apply the top property when the modal opens
      // @ts-ignore
      rootElement.style.top = this.props.scrollTop && this.props.scrollTop > 0 ? -this.props.scrollTop + 'px' : null;
    }

    // modal just opened, so check parent window location if needed
    if (window !== window.top && !this.props.isModalOpen) {
      getParentWindowDimensions();
    } else if (parentWindowDimensions && parentWindowDimensions !== this.props.parentWindowDimensions) {
      const yPosition = Math.max(0, parentWindowDimensions.browserHeight / 2 - parentWindowDimensions.calendarY - DETAIL_MODAL_HEIGHT / 2);

      const xPosition = Math.max(0, parentWindowDimensions.browserWidth / 2 - parentWindowDimensions.calendarX - DETAIL_MODAL_WIDTH / 2);

      // if we are in a mobile or narrow browser view, scroll to the top of the iframe, where the modal will display
      if (parentWindowDimensions.browserWidth < MOBILE_WIDTH_BREAKPOINT_EM * FONT_SIZE) {
        this.props.scrollToTop();
      }

      this.setState({ modalLocation: { left: xPosition, top: yPosition } });
    }
  }

  handleModalClose() {
    this.props.handleModalClose();
    if (isTouchEnabled()) {
      const rootElement = document.documentElement || document.body.parentNode;
      // @ts-expect-error TS(2322): Type 'null' is not assignable to type 'string'.
      rootElement.style.top = null;
      // @ts-expect-error
      rootElement.style.position = 'static';
      // @ts-expect-error TS(2345): Argument of type 'void' is not assignable to param... Remove this comment to see the full error message
      setTimeout(window.scrollTo(0, this.props.scrollTop), 250);
    }
  }

  /**
   * Returns the content to be displayed on the popup
   */
  getPopUpContent(props: $TSFixMe) {
    const { translate, eventDetails, iconSettings, dateTimeFormat, referenceId, ...rest } = props;
    const buttonConfig =
      eventDetails && eventDetails.buttonConfig
        ? {
            url:
              eventDetails.buttonConfig.url +
              (referenceId ? (eventDetails.buttonConfig.url.indexOf('?') < 0 ? '?' : '&') + 'Refid=' + referenceId : ''),
            title: translate(eventDetails.buttonConfig.title)
          }
        : null;

    return (
      <CalendarItemDetailView
        {...rest}
        translate={translate}
        isProcessing={eventDetails && eventDetails.isFetching}
        processingLabel={translate('_calendarListView_viewMoreProcessing__resx')}
        dateTimeFormat={dateTimeFormat}
        buttonConfig={buttonConfig}
        iconSettings={iconSettings}
        isWebinar={eventDetails && eventDetails.isWebinar}
        allDay={eventDetails && eventDetails.allDay}
        status={eventDetails && eventDetails.status}
        type={eventDetails ? eventDetails.type : null}
        defaultFields={eventDetails ? eventDetails.defaultFields : []}
        otherFields={eventDetails ? eventDetails.otherFields : []}
        tags={eventDetails ? eventDetails.tags : []}
        {...select(this.props, 'calendarItemDetailView')}
      />
    );
  }

  /**
   * Renders either the pop up as part of the parent app
   * Or outside the parent app, appended to the html body in case of web widget
   */
  render() {
    const { isModalOpen, translate, getInheritedStyles, style } = this.props;
    const closeButtonInlineStyles = style.dialogStyles ? style.dialogStyles.closeButton : {};
    const header = (
      <InteractiveElement
        key="CloseButton"
        className={CalendarModalStyles.button}
        style={closeButtonInlineStyles}
        onClick={event => {
          event.preventDefault();
          // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
          this.handleModalClose('popUpDialog');
        }}
      >
        <Icon icon={'closeDeleteFilled'} fallbackText={translate('_calendarListView_closeButton__resx')} />
      </InteractiveElement>
    );
    const popUpDialogConfig: $TSFixMe = {
      requestClose: this.handleModalClose,
      dialogId: 'popUpDialog',
      isOpen: isModalOpen,
      isModal: true,
      classes: defaultDialogStyles,
      header,
      style: style.dialogStyles
    };
    if (this.state.modalLocation) {
      popUpDialogConfig.initialLeft = this.state.modalLocation.left;
      popUpDialogConfig.initialTop = this.state.modalLocation.top;
    }

    if (!this.props.isWebWidget) {
      return <PopUpDialog {...popUpDialogConfig}>{this.getPopUpContent(this.props)}</PopUpDialog>;
    }
    const styles = {
      content: getInheritedStyles ? getInheritedStyles() : null,
      placeholder: {
        zIndex: 99999
      }
    };
    return (
      <DialogRenderedOutsideParentApp {...popUpDialogConfig} style={styles}>
        {this.getPopUpContent(this.props)}
      </DialogRenderedOutsideParentApp>
    );
  }
}

export default CalendarModal;
