import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import omit from 'lodash/omit';

type Props = {
  label?: string;
  digit: number;
  type: 'days' | 'hours' | 'minutes' | 'seconds';
  total: number;
  fillingAnimationStartAt?: number;
  style?: {
    mask?: any;
    counter?: any;
    wrapper?: any;
    circle?: any;
    label?: any;
    daysFiller?: any;
    hoursFiller?: any;
    minutesFiller?: any;
    secondsFiller?: any;
    daysSpinner?: any;
    hoursSpinner?: any;
    minutesSpinner?: any;
    secondsSpinner?: any;
    daysDigit?: any;
    hoursDigit?: any;
    minutesDigit?: any;
    secondsDigit?: any;
  };
};

/**
# CircleCounter Component
**/
const CircleCounter = (props: Props) => {
  const { label, digit, type, total, fillingAnimationStartAt } = props;
  let degree;
  if (fillingAnimationStartAt && fillingAnimationStartAt > total) {
    degree = 0;
  } else {
    let counterDigit = digit;
    while (counterDigit > total) {
      counterDigit = counterDigit - total; // Mostly used for days; prevent counter from looking wrong
    }
    degree = 360 - Math.floor((counterDigit / total) * 360);
  }
  const rotation = 'rotate(' + degree + 'deg)';
  const halfRotation = 'rotate(' + degree / 2 + 'deg)';
  const spinnerStyle = {
    WebkitTransform: rotation,
    MozTransform: rotation,
    msTransform: rotation,
    Otransform: rotation,
    transform: rotation
  };
  const fillerStyle = {
    WebkitTransform: halfRotation,
    MozTransform: halfRotation,
    msTransform: halfRotation,
    Otransform: halfRotation,
    transform: halfRotation
  };

  const maskCss = resolve(props, 'mask');
  const fillerCss = resolve(props, 'border', type + 'Filler');
  const spinnerCss = resolve(props, 'border', type + 'Spinner');

  // background should only be applied to the cirlcle, not spinner, filler or mask
  fillerCss.style = omit(fillerCss.style, 'backgroundImage', 'backgroundColor');
  spinnerCss.style = omit(spinnerCss.style, 'backgroundImage', 'backgroundColor');

  return (
    <li {...resolveTestId(props)} {...resolve(props, 'counter')}>
      <div {...resolve(props, 'wrapper')}>
        <div {...resolve(props, 'circle')}>
          <div {...maskCss} style={Object.assign({}, maskCss.style, fillerStyle)}>
            <div {...fillerCss} style={Object.assign({}, fillerCss.style, fillerStyle)}></div>
          </div>
          <div {...maskCss}>
            <div {...fillerCss} style={Object.assign({}, fillerCss.style, fillerStyle)}></div>
            <div {...spinnerCss} style={Object.assign({}, spinnerCss.style, spinnerStyle)}></div>
          </div>
          <div {...resolve(props, 'digit', type + 'Digit')}>
            <span>{digit}</span>
          </div>
        </div>
      </div>
      <span {...resolve(props, 'label')}>{label}</span>
    </li>
  );
};

CircleCounter.displayName = 'CircleCounter';

export { CircleCounter };
