import { defaultMemoize } from 'reselect';

/**
 * Recursively traverses a widget style object to replace all instances of `color` with `altColor`.
 */
export const replaceColorWithAltColor = defaultMemoize((widgetStyle: any) => {
  for (const key in widgetStyle) {
    if (typeof widgetStyle[key] === 'object') {
      replaceColorWithAltColor(widgetStyle[key]);
    } else if (key === 'color' && widgetStyle['altColor']) {
      // Ignore eslint to avoid copying large object
      // eslint-disable-next-line no-param-reassign
      widgetStyle[key] = widgetStyle['altColor'];
    }
  }
});
