// Virtually every widget uses this. Include in the main chunk to avoid duplication.
require('./widgets/BaseWidget');

/**
 * Creates the promises that will resolve when default widget resources are loaded per page.
 * @param widgetFactory - widgetFactory to load the resources from widget metadata.
 */
function defaultLoadWidgetResources(widgetFactory: any) {
  return (widgets: any, widgetTypes: any) => {
    return async (dispatch: any) => {
      const loadWidgetTypeResources = (widgetType: any) => {
        const widgetMetadata = widgetFactory.loadMetaData(widgetType);
        return widgetMetadata.loadResources
          ? dispatch(widgetMetadata.loadResources())
          : Promise.resolve();
      };
      await Promise.all(widgetTypes.map(loadWidgetTypeResources));
    };
  };
}

/**
 * A factory to create widgets within the nucleus framework. Widgets can be registered
 * with the factory to allow custom implementations on a per widget type.
 */
export class WidgetFactory {
  creatorsByWidgetType: any;
  internalLoadWidgetResources: any;
  metadataBySectionType: any;
  metadataByWidgetType: any;
  /**
   * A simple constructor which allows widgets to be registered with the factory.
   * If there are any duplicate widgets the last one present in the list will
   * be the one present in the factory.
   *
   * @param widgetRegistrationEntries - The widgets to be registered with the factory.
   * @param { sectionRegistrationEntries, loadWidgetResources } - Optional Parameters
   *   sectionRegistrationEntries - The sections to be registered with the factory.
   *   loadWidgetResources - Function that allows project to customize the resource loading behavior for the widgets
   *      per widget type.
   */
  constructor(
    widgetRegistrationEntries: any = [],
    { sectionRegistrationEntries, loadWidgetResources }: any = {}
  ) {
    this.metadataByWidgetType = {};
    this.creatorsByWidgetType = {};
    this.metadataBySectionType = {};
    this.internalLoadWidgetResources = loadWidgetResources || defaultLoadWidgetResources(this);
    widgetRegistrationEntries.forEach((entry: any) => {
      this.metadataByWidgetType[entry.metadata.type] = entry.metadata;
      this.creatorsByWidgetType[entry.metadata.type] = entry.creator;
    });
    if (sectionRegistrationEntries) {
      sectionRegistrationEntries.forEach((entry: any) => {
        this.metadataBySectionType[entry.metadata.type] = entry.metadata;
      });
    }
  }

  /**
   * Creates a promise that will resolve with the specified widget.
   * If the widget is not found in the factory, and a widget with type `UnrecognizedWidget` has
   * been defined, `UnrecognizedWidget` will be returned
   *
   * @param widgetType - The type of widget to create.
   * @returns A promise that will resolver into the widget. If the widget is not
   * registered with the factory then it will return an empty cell widget.
   */
  loadComponent(widgetType: any): any {
    const widgetCreator = this.creatorsByWidgetType[widgetType];
    if (!widgetCreator) {
      // if this type is not defined, use the 'UnrecognizedWidget' creator if it is defined
      const unrecognizedWidgetCreator = this.creatorsByWidgetType.UnrecognizedWidget;
      if (unrecognizedWidgetCreator) {
        return Promise.resolve(unrecognizedWidgetCreator());
      }
      throw new Error(
        `Undefined Widget Type '${widgetType}' Encountered!
There was an error processing the creator for the ${widgetType} widget.` +
          'Be sure to include the creator in the widget factory.' +
          'See https://stash.cvent.net/projects/NUKE/repos/nucleus/pull-requests/9553/diff#apps/testbed-guestside-site/src/WidgetFactory/index.js' +
          'for an example configuration.'
      );
    }
    return Promise.resolve(widgetCreator());
  }

  /**
   * Returns the metadata for all widgets registered with the factory.
   * @returns The widget metadata for each widget registered in the factory.
   */
  getAllMetaData() {
    return this.metadataByWidgetType;
  }

  /**
   * Returns the metadata for the specific widget.
   * @param widgetType - The type of widget to return the metadata.
   * @returns The widget metadata for the widget. Throws an error if the type is not registered in the factory.
   */
  loadMetaData(widgetType: any) {
    const widgetMetadata = this.metadataByWidgetType[widgetType];
    if (!widgetMetadata) {
      // if this type is not defined, return the metadata 'UnrecognizedWidget' if it is defined
      const unrecognizedWidgetMetadata = this.metadataByWidgetType.UnrecognizedWidget;
      if (unrecognizedWidgetMetadata) {
        return unrecognizedWidgetMetadata;
      }
      throw new Error(
        `Undefined Widget Type '${widgetType}' Encountered!
There was an error processing the meta data for the ${widgetType} widget.` +
          'Be sure to include all the required fields.' +
          'See https://stash.cvent.net/projects/NUKE/repos/nucleus/browse/pkgs/nucleus-site-editor/docs/WIDGET_FACTORY.md for more info.'
      );
    }
    return widgetMetadata;
  }

  /**
   * Returns the metadata for all sections registered with the factory.
   * @returns The section metadata for each section registered in the factory.
   */
  getAllSectionMetaData() {
    return this.metadataBySectionType;
  }

  /**
   * Returns the metadata for the specific section.
   * @param sectionType - The type of section to return the metadata.
   * @returns The seciton metadata for the section. Returns undefined if the type is not registered in the factory
   *    because registering section types is optional, its only necessary if an app wants to take advantage of any
   *    of the additonal functionality of defining section metadata.
   */
  loadSectionMetaData(sectionType: any) {
    const sectionMetadata = this.metadataBySectionType[sectionType];
    return sectionMetadata;
  }

  /**
   * Creates the promises that will resolve when customized (per widget type) widget resources are loaded per page.
   * @param widgets - Widget configs for which the resources need to be loaded.
   */
  loadWidgetResources(widgets = []) {
    return async (dispatch: any) => {
      const widgetTypes = Object.keys(
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'widgetType' does not exist on type 'neve... Remove this comment to see the full error message
        widgets.reduce((types, widget) => ({ ...types, [widget.widgetType]: true }), {})
      );
      await Promise.all([
        ...widgetTypes.map(widgetType => this.loadComponent(widgetType)),
        dispatch(this.internalLoadWidgetResources(widgets, widgetTypes))
      ]);
    };
  }
}
