import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'rheo... Remove this comment to see the full error message
import Rheostat from 'rheostat';

const CustomRheostat = Rheostat;

// This is mostly the render function from rheostat, with the "resolve" function added
// to pull in hashed classnames.
CustomRheostat.prototype.render = function overrideRender() {
  const {
    algorithm,
    children,
    disabled,
    handle: Handle,
    max,
    min,
    orientation,
    pitComponent: PitComponent,
    pitPoints,
    progressBar: ProgressBar,
    snapPoints
  } = this.props;

  const { className: sliderClassName }: any = { ...resolve(this.props, 'slider') };

  /* NOTE: Keyboard and screen-reader support is added onto the handles. */
  /* The following accessibility rules are disabled for the slider bar. */
  return (
    <div {...resolveTestId(this.props)} {...resolve(this.props, 'container')}>
      <div
        className={`${this.state.className} ${sliderClassName}`}
        ref="rheostat"
        onClick={!disabled && this.handleClick}
        style={{ position: 'relative' }}
      >
        <div {...resolve(this.props, 'bar')} />
        {this.state.handlePos.map((pos: any, idx: any) => {
          const handleStyle =
            orientation === 'vertical'
              ? { top: `${pos}%`, position: 'absolute' }
              : { left: `${pos}%`, position: 'absolute' };

          return (
            <Handle
              {...resolve(this.props, 'handle')}
              aria-valuemax={this.getMaxValue(idx)}
              aria-valuemin={this.getMinValue(idx)}
              aria-valuenow={this.state.values[idx]}
              aria-disabled={disabled}
              data-handle-key={idx}
              key={idx}
              onKeyDown={!disabled && this.handleKeydown}
              onMouseDown={!disabled && this.startMouseSlide}
              onTouchStart={!disabled && this.startTouchSlide}
              role="slider"
              style={handleStyle}
              tabIndex={0}
              value={this.state.values[idx]}
              values={this.state.values}
              pitPoints={pitPoints}
              snapPoints={snapPoints}
            />
          );
        })}
        {this.state.handlePos.map((node: any, idx: any, arr: any) => {
          if (idx === 0 && arr.length > 1) {
            return null;
          }

          return (
            <ProgressBar
              {...resolve(this.props, 'progressBar', `progressBar${idx}`)}
              key={idx}
              style={this.getProgressStyle(idx)}
            />
          );
        })}
        {PitComponent &&
          pitPoints.map((n: any) => {
            const pos = algorithm.getPosition(n, min, max);
            const pitStyle =
              orientation === 'vertical'
                ? { top: `${pos}%`, position: 'absolute' }
                : { left: `${pos}%`, position: 'absolute' };

            return (
              <PitComponent {...resolve(this.props, 'pit')} key={n} style={pitStyle}>
                {n}
              </PitComponent>
            );
          })}
        {children}
      </div>
    </div>
  );
};

export { CustomRheostat };
