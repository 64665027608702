export const spacingHeader = {
  component: 'Header',
  options: {
    title: '_fieldCategory_elementSpacing__resx'
  }
};

export const spacingPaddingPaddingBottom = {
  fieldName: 'spacing.padding.paddingBottom',
  component: 'SliderField',
  options: {
    label: '_fieldLabel_paddingBottom__resx',
    min: 0,
    max: 100
  }
};

export const spacingPaddingPaddingLeft = {
  fieldName: 'spacing.padding.paddingLeft',
  component: 'SliderField',
  options: {
    label: '_fieldLabel_paddingLeft__resx',
    min: 0,
    max: 100
  }
};

export const spacingPaddingPaddingRight = {
  fieldName: 'spacing.padding.paddingRight',
  component: 'SliderField',
  options: {
    label: '_fieldLabel_paddingRight__resx',
    min: 0,
    max: 100
  }
};

export const spacingPaddingPaddingTop = {
  fieldName: 'spacing.padding.paddingTop',
  component: 'SliderField',
  options: {
    label: '_fieldLabel_paddingTop__resx',
    min: 0,
    max: 100
  }
};

export default {
  spacingHeader,
  'spacing.padding.paddingBottom': spacingPaddingPaddingBottom,
  'spacing.padding.paddingLeft': spacingPaddingPaddingLeft,
  'spacing.padding.paddingRight': spacingPaddingPaddingRight,
  'spacing.padding.paddingTop': spacingPaddingPaddingTop
};
