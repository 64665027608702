import React from 'react';

import map from 'lodash/map';
import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId, injectTestId } from '@cvent/nucleus-test-automation';

const createStyledErrorMessage =
  (styles: any, fieldId: any) => (errorMessage: any, errorKey: any) => {
    return (
      <div
        key={errorKey}
        {...resolve(styles, 'errorText')}
        {...injectTestId('error-message')}
        role="alert"
        id={`${fieldId}-${errorKey}`}
      >
        {errorMessage}
      </div>
    );
  };

type ErrorsProps = {
  errorMessages?:
    | {
        [key: string]: string;
      }
    | string[];
  fieldId?: string;
};

/**
 * A simple component to display a set of error messages.
 */
const Errors = ({ errorMessages = {}, fieldId = '', ...rest }: ErrorsProps) => {
  if (Object.keys(errorMessages).length === 0) {
    return null;
  }
  const createErrorMessage = createStyledErrorMessage(rest, fieldId);
  return (
    <div {...resolve(rest, 'container')} {...resolveTestId(rest)}>
      {map(errorMessages, createErrorMessage)}
    </div>
  );
};

export { Errors };
