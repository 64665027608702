export const PAGE = 'PAGE';
export const MULTI_PAGE = 'MULTI_PAGE';
export const TEMPLATE_TOP_BOTTOM = 'TEMPLATE_TOP_BOTTOM';

export interface IWebsitePage {
  id: string;
  name: string;
  title: string;
  version: number;
  rootLayoutItemIds: string[];
  type: string;
  templateId: string;
}
