import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';

import { BaseWidget } from '../BaseWidget';
import { CustomFooter } from './CustomFooter';
import { Footer } from './Footer';
import { ThemeableComponentProps } from '../ThemeableComponent';

interface FooterWidgetProps extends ThemeableComponentProps {
  /* Application specific classes for the footer */
  classes?: Record<string, unknown>;
  /* Available style keys */
  style?: {
    footerContainer?: Record<string, unknown>;
    list?: Record<string, unknown>;
    link?: Record<string, unknown>;
    marketingLinks?: Record<string, unknown>;
    policyLinks?: Record<string, unknown>;
  };
  // Non-configurable props that should be passed in by the application specific wrapper.
  /* whether to display the cvent logo or not */
  showCventLogo?: boolean;
  /* whether to display copyright information */
  showCopyrightInformation?: boolean;
  /* whether to display Event Management Software link */
  showEventManagementSoftware?: boolean;
  /* whether to display Mobile Event Apps link */
  showMobileEventApps?: boolean;
  /* whether to display Inquisium link */
  showInquisium?: boolean;
  /* whether to display Event Venues link */
  showEventVenues?: boolean;
  /* whether to display Strategic Meetings Management link */
  showStrategicMeetingsManagement?: boolean;
  /* whether to display privacy policy link */
  showPrivacyPolicy?: boolean;
  /* whether the links are clickable */
  enableHyperlink?: boolean;
  /* account settings to show or hide custom footer*/
  allowCustomFooter?: boolean;
  /* display extra policy links */
  additionalPolicyLinks?: Array<{
    text: string;
    url: string;
  }>;
  /* custom privacy policy URL */
  privacyPolicyUrl?: string;
  /* to show CCPA Opt Out URL */
  showComplianceText?: boolean;
  /* compliance text to be shown for compliance link */
  complianceText?: string;
  /* callback for click on CCPA Opt Out URL */
  onComplianceClick?: (...args: any[]) => any;
  /* to show Manage Cookie Preference link */
  showManageCookiePreferences?: boolean;
  /* callback for click on Manage Cookie Preference Modal */
  onManageCookiePreferences?: () => void;
  /* text to be shown for Manage Cookie Preference link */
  manageCookiePreferencesText?: string;
  /* to show redirect icon or not */
  showRedirectIcon: boolean;
}
/**
 * Data wrapper for the event footer widget.
 */
export class FooterWidget extends BaseWidget<FooterWidgetProps> {
  static displayName = 'FooterWidget';

  static defaultProps = {
    showCventLogo: true,
    showCopyrightInformation: true,
    showEventManagementSoftware: true,
    showMobileEventApps: true,
    showInquisium: true,
    showEventVenues: true,
    showStrategicMeetingsManagement: true,
    showPrivacyPolicy: true,
    enableHyperlink: true,
    showTermsOfUse: false,
    allowCustomFooter: false,
    privacyPolicyUrl: 'https://www.cvent.com/en/privacy-policy.shtml',
    showRedirectIcon: false
  };
  getStyleObject() {
    const links = this.getElementInlineStyle('links');
    return {
      ...super.getStyleObject(),
      link: links,
      copyright: this.getElementInlineStyle('copyright'),
      list: {
        borderColor: links.color
      }
    };
  }

  render() {
    if (this.props.allowCustomFooter) {
      return (
        <CustomFooter
          {...resolveTestId(this.props)}
          {...this.props}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          style={this.getStyleObject()}
        />
      );
    }
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <Footer {...resolveTestId(this.props)} {...this.props} style={this.getStyleObject()} />
    );
  }
}
