import React from 'react';
import ReactDOM from 'react-dom';

import { Dialog } from './Dialog';

type Props = {
  isOpen: boolean;
};

// TODO: Uncomment this in the next major release
// let createRoot: any;
// const reactMajorVersion = parseInt(React.version.split('.')[0], 10);
// if (reactMajorVersion > 17) {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   createRoot = require('react-dom/client').createRoot;
// }

/**
This component renders Dialog component outside the hosting app, so the stacking order
of the rendered dialog won't be limited by the hosting app's stacking order.

This is NOT recommended to be used by single page web apps, as rendering outside the parent app removes
the dialog from React/Redux rendering cycle.

This is intended to be used by widgets embedded on other sites.
*/
class DialogRenderedOutsideParentApp extends React.Component<Props> {
  static displayName = 'DialogRenderedOutsideParentApp';

  node: any;

  componentDidMount() {
    if (typeof document !== 'undefined') {
      this.node = document.createElement('div');
      document.body.appendChild(this.node);
      this.renderDialog(this.props);
    }
  }

  componentWillUnmount() {
    if (typeof document !== 'undefined') {
      // TODO: Uncomment this in the next major release
      // if (reactMajorVersion > 17) {
      //   const dialogRoot = createRoot(this.node);
      //   dialogRoot.unmount();
      // } else {
      ReactDOM.unmountComponentAtNode(this.node);
      // }

      document.body.removeChild(this.node);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (typeof document !== 'undefined') {
      this.renderDialog(nextProps);
    }
  }

  renderDialog(props: any) {
    const { children, ...rest } = props;

    const dialog = <Dialog {...rest}>{children}</Dialog>;
    // TODO: Uncomment this in the next major release
    // if (reactMajorVersion > 17) {
    //   const dialogRoot = createRoot(this.node);
    //   dialogRoot.render(dialog);
    // } else {
    ReactDOM.render(dialog, this.node);
    // }
  }
  render() {
    return null;
  }
}

export { DialogRenderedOutsideParentApp };
