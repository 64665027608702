/**
 * applyTimeZoneToDate - Internal helper function to apply a time zone offset to a given date
 */
function applyTimeZoneToDate(date, timeZone) {
  if (isNaN(Date.parse(date))) {
    throw new Error(`'${date}' is not a valid Date`);
  }
  // Convert the given time zone offset in hours to milliseconds (multiply by 3600000),
  // then add this to the parsed representation of the given date in milliseconds and create a new Date object
  return new Date(Date.parse(date) + 3600000 * timeZone);
}

/**
 * adjustDateByTimeZone - Takes a given date and applies a given time zone, adjusting for browser's time zone
 *
 * @param  {string} isoDateString - Date string in ISO format, e.g., 2017-01-16T09:59:00.000Z
 * @param  {Number} timeZone - Represents the project time zone offset from GMT in hours, e.g., -5
 * @return {Object} Date object stripped of browser time zone with given time zone applied
 */
export default function adjustDateByTimeZone(isoDateString, timeZone) {
  if (isNaN(Date.parse(isoDateString))) {
    throw new Error(`'${isoDateString}' is not a valid Date`);
  }
  let dateObject = new Date(isoDateString);
  // First remove the browser's timezone from the new Date
  // Don't switch the sign of the offset--the return value is already backwards
  // getTimezoneOffset() returns the offset from GMT in minutes. Divide by 60 to get the offset in hours.
  const browserTimeZone = dateObject.getTimezoneOffset() / 60;
  dateObject = applyTimeZoneToDate(dateObject, browserTimeZone);
  // Now apply the desired time zone
  dateObject = applyTimeZoneToDate(dateObject, timeZone);

  return dateObject;
}
