export function loadListFormat(cb: any) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'ListFormat' does not exist on type 'type... Remove this comment to see the full error message
  if (global.Intl && global.Intl.ListFormat) {
    cb();
    return;
  }
  require.ensure(['@formatjs/intl-listformat/polyfill'], require => {
    require('@formatjs/intl-listformat/polyfill');
    cb();
  });
}

function loadListFormatLocaleData(locale: any, cb: any, usingBrowserLocale = false) {
  if (
    global.Intl &&
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ListFormat' does not exist on type 'type... Remove this comment to see the full error message
    global.Intl.ListFormat &&
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'ListFormat' does not exist on type 'type... Remove this comment to see the full error message
    typeof Intl.ListFormat.__addLocaleData !== 'function'
  ) {
    cb();
    return;
  }
  const localeTokens = locale.replace('_', '-').split('-')[0].toLowerCase();
  switch (localeTokens) {
    case 'bg':
      require.ensure(['@formatjs/intl-listformat/locale-data/bg'], require => {
        require('@formatjs/intl-listformat/locale-data/bg');
        cb();
      });
      return;
    case 'cs':
      require.ensure(['@formatjs/intl-listformat/locale-data/cs'], require => {
        require('@formatjs/intl-listformat/locale-data/cs');
        cb();
      });
      return;
    case 'da':
      require.ensure(['@formatjs/intl-listformat/locale-data/da'], require => {
        require('@formatjs/intl-listformat/locale-data/da');
        cb();
      });
      return;
    case 'de':
      require.ensure(['@formatjs/intl-listformat/locale-data/de'], require => {
        require('@formatjs/intl-listformat/locale-data/de');
        cb();
      });
      return;
    case 'el':
      require.ensure(['@formatjs/intl-listformat/locale-data/el'], require => {
        require('@formatjs/intl-listformat/locale-data/el');
        cb();
      });
      return;
    case 'en':
      require.ensure(['@formatjs/intl-listformat/locale-data/en'], require => {
        require('@formatjs/intl-listformat/locale-data/en');
        cb();
      });
      return;
    case 'es':
      require.ensure(['@formatjs/intl-listformat/locale-data/es'], require => {
        require('@formatjs/intl-listformat/locale-data/es');
        cb();
      });
      return;
    case 'fi':
      require.ensure(['@formatjs/intl-listformat/locale-data/fi'], require => {
        require('@formatjs/intl-listformat/locale-data/fi');
        cb();
      });
      return;
    case 'fr':
      require.ensure(['@formatjs/intl-listformat/locale-data/fr'], require => {
        require('@formatjs/intl-listformat/locale-data/fr');
        cb();
      });
      return;
    case 'hr':
      require.ensure(['@formatjs/intl-listformat/locale-data/hr'], require => {
        require('@formatjs/intl-listformat/locale-data/hr');
        cb();
      });
      return;
    case 'hu':
      require.ensure(['@formatjs/intl-listformat/locale-data/hu'], require => {
        require('@formatjs/intl-listformat/locale-data/hu');
        cb();
      });
      return;
    case 'it':
      require.ensure(['@formatjs/intl-listformat/locale-data/it'], require => {
        require('@formatjs/intl-listformat/locale-data/it');
        cb();
      });
      return;
    case 'ja':
      require.ensure(['@formatjs/intl-listformat/locale-data/ja'], require => {
        require('@formatjs/intl-listformat/locale-data/ja');
        cb();
      });
      return;
    case 'ko':
      require.ensure(['@formatjs/intl-listformat/locale-data/ko'], require => {
        require('@formatjs/intl-listformat/locale-data/ko');
        cb();
      });
      return;
    case 'nb':
      require.ensure(['@formatjs/intl-listformat/locale-data/nb'], require => {
        require('@formatjs/intl-listformat/locale-data/nb');
        cb();
      });
      return;
    case 'nl':
      require.ensure(['@formatjs/intl-listformat/locale-data/nl'], require => {
        require('@formatjs/intl-listformat/locale-data/nl');
        cb();
      });
      return;
    case 'pl':
      require.ensure(['@formatjs/intl-listformat/locale-data/pl'], require => {
        require('@formatjs/intl-listformat/locale-data/pl');
        cb();
      });
      return;
    case 'pt':
      require.ensure(['@formatjs/intl-listformat/locale-data/pt'], require => {
        require('@formatjs/intl-listformat/locale-data/pt');
        cb();
      });
      return;
    case 'ro':
      require.ensure(['@formatjs/intl-listformat/locale-data/ro'], require => {
        require('@formatjs/intl-listformat/locale-data/ro');
        cb();
      });
      return;
    case 'ru':
      require.ensure(['@formatjs/intl-listformat/locale-data/ru'], require => {
        require('@formatjs/intl-listformat/locale-data/ru');
        cb();
      });
      return;
    case 'sk':
      require.ensure(['@formatjs/intl-listformat/locale-data/sk'], require => {
        require('@formatjs/intl-listformat/locale-data/sk');
        cb();
      });
      return;
    case 'sl':
      require.ensure(['@formatjs/intl-listformat/locale-data/sl'], require => {
        require('@formatjs/intl-listformat/locale-data/sl');
        cb();
      });
      return;
    case 'sr':
      require.ensure(['@formatjs/intl-listformat/locale-data/sr'], require => {
        require('@formatjs/intl-listformat/locale-data/sr');
        cb();
      });
      return;
    case 'sv':
      require.ensure(['@formatjs/intl-listformat/locale-data/sv'], require => {
        require('@formatjs/intl-listformat/locale-data/sv');
        cb();
      });
      return;
    case 'th':
      require.ensure(['@formatjs/intl-listformat/locale-data/th'], require => {
        require('@formatjs/intl-listformat/locale-data/th');
        cb();
      });
      return;
    case 'tr':
      require.ensure(['@formatjs/intl-listformat/locale-data/tr'], require => {
        require('@formatjs/intl-listformat/locale-data/tr');
        cb();
      });
      return;
    case 'uk':
      require.ensure(['@formatjs/intl-listformat/locale-data/uk'], require => {
        require('@formatjs/intl-listformat/locale-data/uk');
        cb();
      });
      return;
    case 'vi':
      require.ensure(['@formatjs/intl-listformat/locale-data/vi'], require => {
        require('@formatjs/intl-listformat/locale-data/vi');
        cb();
      });
      return;
    case 'zh':
      require.ensure(['@formatjs/intl-listformat/locale-data/zh'], require => {
        require('@formatjs/intl-listformat/locale-data/zh');
        cb();
      });
      return;
    default: {
      // If the locale is not recognized, load the browser's recognized locale,
      // otherwise just load base english because we are out of options.
      if (!usingBrowserLocale && window && window.navigator) {
        const browserLocale =
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'userLanguage' does not exist on type 'Na... Remove this comment to see the full error message
          window.navigator.userLanguage || window.navigator.language;
        loadListFormatLocaleData(browserLocale, cb, true);
      } else {
        require.ensure(['@formatjs/intl-listformat/locale-data/en'], require => {
          require('@formatjs/intl-listformat/locale-data/en');
          cb();
        });
      }
    }
  }
}

export function loadGetCanonicalLocales(cb: any) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'getCanonicalLocales' does not exist on t... Remove this comment to see the full error message
  if (global.Intl && global.Intl.getCanonicalLocales) {
    cb();
    return;
  }
  require.ensure(['@formatjs/intl-getcanonicallocales/polyfill'], require => {
    require('@formatjs/intl-getcanonicallocales/polyfill');
    cb();
  });
}

/**
 * Helper function to load Intl ListFormat polyfill along with its locale data.
 * @param {string} locale - the locale to load data for.
 * @param {function} cb - the callback function to execute after loading the polyfill and locale data.
 */
export default function loadListFormatAndLocaleData(locale: any, cb: any) {
  loadListFormat(() => {
    loadGetCanonicalLocales(() => {
      loadListFormatLocaleData(locale, cb);
    });
  });
}
