import React from 'react';

type Props = {
  row: any;
  layoutItems: any;
  activeDropzone: any;
  component?: (...args: any[]) => any;
};

function DropItem(props: Props) {
  const { row, layoutItems, activeDropzone } = props;
  return (
    // @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'props.component' does not have a... Remove this comment to see the full error message
    <props.component
      quadrant={activeDropzone.quadrant}
      canDrop={activeDropzone.canDrop.value}
      target={layoutItems[activeDropzone.id]}
      parent={row}
      grandparent={layoutItems[row.layout.parentId]}
    />
  );
}
DropItem.displayName = 'DropItem';

/**
 * Generates a drop item component if necessary and returns it
 * in an array with the provided row content at the correct index
 * for proper rendering.
 */
export function addRowDropItem(rowContent: any, props: any) {
  const { row, activeDropzone } = props;
  const content = [rowContent];
  if (activeDropzone && activeDropzone.parent === row) {
    const dropItem = (
      /* Cannot replace div with React.Fragment or else blue line indicating
      where widget will be placed when dropped does not appear when the
      widget would be making a new row */
      <div key={`${row.id}-${activeDropzone.quadrant}`}>
        <DropItem {...props} />
      </div>
    );
    if (activeDropzone.quadrant === 'top') {
      content.unshift(dropItem);
    } else if (activeDropzone.quadrant === 'bottom') {
      content.push(dropItem);
    }
  }
  return content;
}
