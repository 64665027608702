import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';
import PropTypes from 'prop-types';

const SIZES = [
  { class: 'xs', name: 'extraSmall', hide: 'isExtraSmallHidden' },
  { class: 'sm', name: 'small', hide: 'isSmallHidden' },
  { class: 'md', name: 'medium', hide: 'isMediumHidden' },
  { class: 'lg', name: 'large', hide: 'isLargeHidden' }
];

type Props = {
  children?: React.ReactNode;
  className?: string;
  extraSmall?: number;
  extraSmallOffset?: number;
  extraSmallPush?: number;
  extraSmallPull?: number;
  isExtraSmallHidden?: boolean;
  small?: number;
  smallOffset?: number;
  smallPush?: number;
  smallPull?: number;
  isSmallHidden?: boolean;
  medium?: number;
  mediumOffset?: number;
  mediumPush?: number;
  mediumPull?: number;
  isMediumHidden?: boolean;
  large?: number;
  largeOffset?: number;
  largePush?: number;
  largePull?: number;
  isLargeHidden?: boolean;
  style?: Record<string, any>;
  ariaRole?: string;
};

/**
 * The bottom level component that contains any type of child content.
 */
const Column = (props: Props, context: any) => {
  const { ariaRole, children, className = '', style, ...otherProps } = props;
  const { grid } = context;

  const defaultProps = {
    extraSmall: 0,
    extraSmallOffset: 0,
    extraSmallPush: 0,
    extraSmallPull: 0,
    isExtraSmallHidden: false,
    small: 0,
    smallOffset: 0,
    smallPush: 0,
    smallPull: 0,
    isSmallHidden: false,
    medium: 0,
    mediumOffset: 0,
    mediumPush: 0,
    mediumPull: 0,
    isMediumHidden: false,
    large: 0,
    largeOffset: 0,
    largePull: 0,
    isLargeHidden: false
  };

  const mergedProps = { ...defaultProps, ...otherProps };
  const classKeys: any = [];
  SIZES.forEach(entry => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const size = mergedProps[entry.name];
    if (size || size > 0) {
      classKeys.push(`col-${entry.class}-${size}`);
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const pull = mergedProps[entry.name + 'Pull'];
    if (pull || pull > 0) {
      classKeys.push(`col-${entry.class}-pull-${pull}`);
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const offset = mergedProps[entry.name + 'Offset'];
    if (offset || offset >= 0) {
      classKeys.push(`col-${entry.class}-offset-${offset}`);
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const push = mergedProps[entry.name + 'Push'];
    if (push || push > 0) {
      classKeys.push(`col-${entry.class}-push-${push}`);
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    if (mergedProps[entry.hide]) {
      classKeys.push(`hidden-${entry.class}`);
    }
  });
  // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'prev' implicitly has an 'any' type.
  const columnClass = classKeys.reduce((prev, value) => {
    return `${prev} ${grid[value]}`;
  }, `${grid.col} ${className} `);
  return (
    <div
      className={columnClass}
      style={style}
      {...(ariaRole !== undefined && { role: ariaRole })}
      {...resolveTestId(props)}
    >
      {children}
    </div>
  );
};
Column.contextTypes = {
  grid: PropTypes.object
};
Column.displayName = 'Column';

export { Column };
