const LOAD_IMAGE_LOOKUP = 'nucleus-widgets/customFonts/LOAD_IMAGE_LOOKUP';

/**
 * Takes in an object with master image urls as keys and their hashed equivalents and dimensions as values,
 * and triggers an action to load the lookup data into the reducer.
 * @param {*} imageLookup - an object of master image urls and their hashed equivalents and dimensions as values.
 */
export function loadImageLookup(imageLookup: any) {
  // Trigger the action.
  return {
    type: LOAD_IMAGE_LOOKUP,
    payload: {
      imageLookup
    }
  };
}

// ACTIONS
/**
 * Takes in an array of image urls and image lookup service client
 * and returns a thunk action to fetch data and load the returned object into the reducer.
 *
 * To minimize user wait time, we suggest your service to call the image lookup service directly,
 * and send the image lookup object together with the website snapshot / appData to the browser.
 * This action should only be called when the lookup data isn't sent from the service.
 * @param {*} imageArray - an array of image urls in the format that the image lookup service expects:
 *                         [{ url: imageUrl1 }, { url: imageUrl2 }]
 */
export function fetchImageLookup({ imageArray, client }: any) {
  return async (dispatch: any) => {
    if (!imageArray || !imageArray.length || !client) {
      return;
    }
    const { fetchImageLookup: fetchLookup } = client;
    const lookup = await fetchLookup(imageArray);
    dispatch(loadImageLookup(lookup));
  };
}

// REDUCER
/**
 * Reducer to hold our image lookup information so that we can use it throughout the editor.
 */
export function imageLookupReducer(state = {}, action: any) {
  switch (action.type) {
    case LOAD_IMAGE_LOOKUP: {
      return {
        ...state,
        ...action.payload.imageLookup
      };
    }
    default:
      return state;
  }
}
