/* calendar actions */

// actions for initial list view load
export const LOAD_CALENDAR_DATA = 'calendar-guest-site/actions/LOAD_CALENDAR_DATA';

// actions for paging (load more) in list view
export const LOAD_EVENTS_PENDING = 'calendar-guest-site/actions/LOAD_EVENTS_PENDING';
export const LOAD_EVENTS_SUCCESS = 'calendar-guest-site/actions/LOAD_EVENTS_SUCCESS';
export const LOAD_EVENTS_FAILURE = 'calendar-guest-site/actions/LOAD_EVENTS_FAILURE';

// actions for loading events in calendar guest site
export const LOAD_PROPS_PENDING = 'calendar-guest-site/actions/LOAD_PROPS_PENDING';
export const LOAD_PROPS_SUCCESS = 'calendar-guest-site/actions/LOAD_PROPS_SUCCESS';

export const LOAD_GOOGLE_MAP_API_KEY = 'calendar-guest-site/actions/LOAD_GOOGLE_MAP_API_KEY';

export const LOAD_CALENDAR_MIGRATIONS = 'calendar-guest-site/actions/LOAD_CALENDAR_MIGRATIONS';

export const SHOW_SPINNER = 'calendar-guest-site/actions/SHOW_SPINNER';

// actions for keyword/date search
export const SEARCH_EVENTS_PROCESSING = 'calendar-guest-site/actions/SEARCH_EVENTS_PROCESSING';
export const SEARCH_EVENTS_STILL_PROCESSING = 'calendar-guest-site/actions/SEARCH_EVENTS_STILL_PROCESSING';
export const SEARCH_EVENTS_SUCCESS = 'calendar-guest-site/actions/SEARCH_EVENTS_SUCCESS';
export const SEARCH_EVENTS_FAILURE = 'calendar-guest-site/actions/SEARCH_EVENTS_FAILURE';

// action(s) for search/filter criteria
export const UPDATE_CRITERIA = 'calendar-guest-site/actions/UPDATE_CRITERIA';

// actions for feeding data to month view
export const LOADING_MONTH = 'calendar-guest-site/actions/LOADING_MONTH';
export const LOAD_MONTH_SUCCESS = 'calendar-guest-site/actions/LOAD_MONTH_SUCCESS';
export const LOAD_MONTH_FAILURE = 'calendar-guest-site/actions/LOAD_MONTH_FAILURE';
