import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import { removeKeys } from '../utils/removeKeys';
import { BreakPoint } from './Button';

type Props = React.PropsWithChildren<{
  /** Sets the alignment of the buttons within ButtonGroup. */
  alignment?: 'left' | 'center' | 'right';
  /** Sets the window-width at which Button should transition from a stacked-title layout, to a title-on-the-side layout. */
  breakPoint?: BreakPoint;
  style?: {
    buttonGroup?: any;
    left?: any;
    center?: any;
    right?: any;
  };
  classes?: any;
}>;

/**
  ButtonGroup is a simple stateless function component that will render a UL list based on the children objects
  passed by props.
 **/

const ButtonGroup = (props: Props) => {
  const {
    children,
    alignment = 'right',
    breakPoint,
    reverseButtonOrderOnMobile = false,
    ...rest
  } = removeKeys(props, ['classes']);
  const menuList = React.Children.map(children, item => {
    return <li {...resolve(props, breakPoint)}>{item}</li>;
  });

  return (
    <ul
      {...rest}
      {...resolve(
        props,
        'buttonGroup',
        alignment,
        reverseButtonOrderOnMobile ? 'reverseButtonOrderOnMobile' : ''
      )}
      {...resolveTestId(props)}
    >
      {menuList}
    </ul>
  );
};

ButtonGroup.displayName = 'ButtonGroup';

export { ButtonGroup };
