import DateInput from './DateInput';
import { formatDateTimeToLocalISOString } from './localDates';

export default class DateTimeInput extends DateInput {
  /**
   * Constructor
   * @param date JS Date object for just the date
   * @param dateText text user input for date
   * @param timeText text user input for time, shape of { hour, minute, second }
   * @param dateTime JS Date object for the combined date and time
   */
  constructor(date, statusCode, dateText, timeText, dateTime) {
    super(date, statusCode, dateText);
    this.timeText = timeText;
    this.dateTime = dateTime;
  }
  toJSON() {
    // Serialize to a timezone-less ISO8601 date/time, e.g. "YYYY-MM-DD HH:mm:ss"
    // This is the only format that javascript implementations consistently parse as a local date/time (instead of UTC)
    if (!this.dateTime) {
      return null;
    }
    return formatDateTimeToLocalISOString(this.dateTime);
  }
}
