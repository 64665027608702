import { PseudoStateStyleObject, PseudoStateStyleCalculator } from './types';
import merge from 'lodash/merge';
import { getBestContrastColor } from './getBestContrastColor';
import { convertThemePaletteColor } from '../fields/convertThemePaletteColor';
import chroma from 'chroma-js';

function getBackgroundColor(
  baseStyle: Partial<PseudoStateStyleObject>,
  brightnessModifier = 0,
  themePalette: Record<string, string>
): string | undefined {
  const backgroundColor = convertThemePaletteColor(themePalette, baseStyle.background?.color);
  if (!backgroundColor) {
    return undefined;
  }
  return chroma(backgroundColor).darken(brightnessModifier).hex();
}

function getBorderColor(
  baseStyle: Partial<PseudoStateStyleObject>,
  pseudoStateBackgroundColor: string | undefined,
  themePalette: Record<string, string>
): string | undefined {
  const backgroundColor = convertThemePaletteColor(themePalette, baseStyle.background?.color);
  const borderColor = convertThemePaletteColor(themePalette, baseStyle.border?.color);

  if (!borderColor || backgroundColor !== borderColor) {
    return borderColor;
  }

  return pseudoStateBackgroundColor;
}

function getTextColor(
  baseStyle: Partial<PseudoStateStyleObject>,
  pseudoStateBackgroundColor: string | undefined,
  themePalette: Record<string, string>
): string | undefined {
  return getBestContrastColor(
    themePalette,
    convertThemePaletteColor(themePalette, pseudoStateBackgroundColor),
    convertThemePaletteColor(themePalette, baseStyle.text?.color)
  );
}

/**
 * Darkens/lightens the background color and sets an appropriate contrasting text color.
 * @see PseudoStateStyleMode#BRIGHTNESS
 */
export const BrightnessStyleModeCalculator: PseudoStateStyleCalculator = {
  updateStyle(
    baseStyle: Partial<PseudoStateStyleObject>,
    pseudoStateStyle: Partial<PseudoStateStyleObject>,
    themePalette: Record<string, string>,
    options?: Record<string, any>
  ): Partial<PseudoStateStyleObject> {
    if (!options?.brightnessModifier) {
      return baseStyle;
    }

    const backgroundColor = getBackgroundColor(baseStyle, options.brightnessModifier, themePalette);
    const borderColor = getBorderColor(baseStyle, backgroundColor, themePalette);
    const textColor = getTextColor(baseStyle, backgroundColor, themePalette);

    return merge({}, pseudoStateStyle, {
      background: { color: backgroundColor },
      border: { color: borderColor },
      text: { color: textColor }
    });
  }
};
