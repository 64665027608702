import { mapValues } from 'lodash';
import moment from 'moment';
// @ts-expect-error TS(2305): Module '"nucleus-core"' has no exported member 'Fi... Remove this comment to see the full error message
import { Filter, ALL_FILTERS_SELECTED } from 'nucleus-core';
import { createSelector } from 'reselect';

import { ISO_DATE_FORMAT } from './dateFormatUtil';
import { LOCATION } from '../lib/CalendarView/Constants';

export const CALENDAR_ADDITIONAL_ITEM_TYPE = 'ITEM';

/**
 * Converts event's date fields from string to date
 */
function convertEventDateFieldsFromStringToDate(event: $TSFixMe) {
  const startDate = moment(event.startDate, ISO_DATE_FORMAT).toDate();
  const endDate = event.endDate ? moment(event.endDate, ISO_DATE_FORMAT).toDate() : null;

  return {
    startDate,
    endDate
  };
}

/**
 * creates tags data using overridden colors for text and background
 */
function getTagsFromOverriddenColors(overriddenTextColor: $TSFixMe, overriddenBackgroundColor: $TSFixMe, tagValues: $TSFixMe) {
  return tagValues.map((tag: $TSFixMe) => {
    return {
      displayName: tag,
      textColor: overriddenTextColor,
      backgroundColor: overriddenBackgroundColor
    };
  });
}

/**
 * creates tags data using tag configurations
 */
function getTagsFromConfiguration(tagFieldConfiguration: $TSFixMe, tagValues: $TSFixMe, translate: $TSFixMe) {
  const tagFieldConfigurationList = Object.values(tagFieldConfiguration);
  return tagValues.map((tag: $TSFixMe) => {
    const tagOptions = tagFieldConfigurationList.find(
      (tagConfiguration: $TSFixMe) => translate(tagConfiguration.displayName, {}, () => tagConfiguration.choiceLabel) === tag
    );

    if (tagOptions) {
      // @ts-ignore
      const { displayName, backgroundColor, textColor, choiceLabel } = tagOptions;
      return {
        displayName: translate(displayName, {}, () => choiceLabel),
        backgroundColor,
        textColor
      };
    }
    // Render a choice that doesn't exist in tags configuration, with default color values
    return {
      displayName: tag,
      choiceLabel: tag
    };
  });
}

/**
 * Returns tags in the form needed by the widget
 */
export function getTagsConvertedToTagObject(eventTags: $TSFixMe, tagsConfiguration: $TSFixMe, translate: $TSFixMe) {
  if (!eventTags) {
    return [];
  }

  return eventTags.map((tag: $TSFixMe) => {
    const { id: tagId, values: tags, overriddenTextColor, overriddenBackgroundColor } = tag;
    if (overriddenTextColor && overriddenBackgroundColor) {
      // in case of calendar item tag
      return getTagsFromOverriddenColors(overriddenTextColor, overriddenBackgroundColor, tags);
    }
    if (!tagsConfiguration) {
      return [];
    }
    const tagFieldConfiguration = tagsConfiguration[tagId];
    if (!tagFieldConfiguration) {
      return [];
    }
    return getTagsFromConfiguration(tagFieldConfiguration, tags, translate);
  });
}

/**
 * Massages event related fields i.e.
 ** converts date fields from string to date
 ** set isWebinar flag based on the category id
 */
export function getEventAfterMassaging(event: $TSFixMe, tagsConfiguration: $TSFixMe, translate: $TSFixMe) {
  const { description, title, location, timeZone, type, id, spanMultiDay, allDay, tag, status } = event;
  return {
    id,
    title,
    allDay,
    spanMultiDay,
    description,
    location,
    timeZone,
    type,
    status,
    ...convertEventDateFieldsFromStringToDate(event),
    isWebinar: event.categoryId === 18,
    tags: getTagsConvertedToTagObject(tag, tagsConfiguration, translate)
  };
}

/**
 * Get calendar items in the form needed by the widget
 */
export const getCalendarItems = createSelector(
  state => state.calendar.events,
  state => {
    const { tagsConfiguration } = state.calendar.config;
    return tagsConfiguration || null;
  },
  state => state.text.translate,
  (events: $TSFixMe, tagsConfiguration: $TSFixMe, translate: $TSFixMe) => {
    const calendarItems = mapValues(events, (event: $TSFixMe) => {
      return getEventAfterMassaging(event, tagsConfiguration, translate);
    });
    return Object.values(calendarItems);
  }
);

const getUserSelection = (state: $TSFixMe) => state.searchCriteria.filters;
const getAllFilters = (state: $TSFixMe) => state.calendarFilters.filters;

type calendarFiltersFunc = (arg0: unknown) => Array<Filter>;

/**
 * gets all calendar filters. user selection included.
 */
export const getCalendarFilters: calendarFiltersFunc = createSelector([getUserSelection, getAllFilters], (userSelection, allFilters) => {
  if (allFilters instanceof Array && !allFilters.length) {
    return [];
  }
  return Object.values(allFilters).map((filter: $TSFixMe) => {
    const id = filter.name;
    let selectedValues = filter.type === LOCATION ? {} : [];
    if (userSelection.hasOwnProperty(id)) {
      selectedValues = userSelection[id].values;
      // @ts-expect-error TS(2339): Property 'length' does not exist on type '{}'.
      if (filter.type !== LOCATION && selectedValues.length > 0 && selectedValues.length === filter.options.length) {
        // @ts-expect-error TS(2488): Type '{}' must have a '[Symbol.iterator]()' method... Remove this comment to see the full error message
        selectedValues = [...selectedValues, ALL_FILTERS_SELECTED];
      }
    }
    return {
      ...filter,
      selectedValues
    };
  });
});
