/**
 * Migrations related to monthView.
 * Created by s.sachdeva on 7/12/17.
 * Add month view styling elements to existing calendars
 */
export default function monthViewMigrations(widget: $TSFixMe) {
  if (widget.config.style.calendar) {
    return widget;
  }

  const newStyle = {};
  if (!widget.config.style.calendar) {
    // @ts-expect-error TS(2339): Property 'calendar' does not exist on type '{}'.
    newStyle.calendar = {
      calendarMonthHeader: {
        styleMapping: 'header4',
        customSettings: {
          spacing: {
            padding: {
              paddingTop: 0,
              paddingBottom: 0,
              paddingLeft: 0,
              paddingRight: 0
            }
          }
        }
      },
      calendarDays: {
        styleMapping: 'body1'
      },
      calendarDayNumber: {
        styleMapping: 'body1'
      },
      calendarTodayDate: {
        styleMapping: 'primaryButton'
      },
      calendarGridLines: {
        styleMapping: 'custom',
        border: {
          color: 'primary',
          borderStyle: 'solid',
          thickness: 1
        }
      },
      calendarNavigationButtons: {
        styleMapping: 'secondaryButton'
      },
      calendarAllDay: {
        styleMapping: 'text2'
      }
    };

    return {
      ...widget,
      config: {
        ...widget.config,
        style: {
          ...widget.config.style,
          ...newStyle
        }
      }
    };
  }
}
