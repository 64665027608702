import { sessionTimeoutDialogReducer } from './sessionTimeoutDialog/reducer';
import { textReducer } from './text';

export function sessionTimeoutDialogIsOpen(state: any) {
  return state && state.sessionTimeoutDialogConfig && state.sessionTimeoutDialogConfig.isOpen;
}

/**
 * Root application reducer. Note that implementing applications will most likely be
 * overriding this file to replace some of our generic reducers with their own implementations,
 * (for example, guest) so any changes here need to be propagated out to any implementing applications.
 */
export function reducer(state = {}, action: any) {
  return {
    appData: (state as any).appData,
    website: (state as any).website,
    widgetFactory: (state as any).widgetFactory,
    sessionTimeoutDialogConfig: sessionTimeoutDialogReducer(
      (state as any).sessionTimeoutDialogConfig,
      action
    ),
    text: textReducer((state as any).text, action)
  };
}
