import React from 'react';
import { removeKeys } from '../utils/removeKeys';

type Props = {
  value?: number;
};

const Handle = (props: Props) => {
  return (
    <button type="button" {...removeKeys(props, ['pitPoints', 'snapPoints'])}>
      {props.value}
    </button>
  );
};

export { Handle };
