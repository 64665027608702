import React from 'react';

import { Content, DefaultPageRenderer } from 'nucleus-guestside-site';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/**
 * Custom page renderer implementation for the calendar's guest site.
 */
class PageRenderer extends React.Component<$TSFixMe> {
  static displayName = 'PageRenderer';
  static propTypes = {
    page: PropTypes.any,
    isCustomFooterEnabled: PropTypes.bool,
    footerHidden: PropTypes.bool
  };
  render() {
    const { page, isCustomFooterEnabled, footerHidden } = this.props;
    let footer;
    // show cvent footer when custom footer is not enabled
    if (!isCustomFooterEnabled && !footerHidden) {
      footer = <Content rootLayoutItemId="CventFooterContainer" />;
    }
    return (
      <div>
        <DefaultPageRenderer page={page} />
        {footer}
      </div>
    );
  }
}

export default connect((state: $TSFixMe) => {
  return {
    isCustomFooterEnabled: state.isCustomFooterEnabled,
    footerHidden: state.embeddedSettings.footerHidden
  };
})(PageRenderer);
