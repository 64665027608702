import React from 'react';

import { select } from '@cvent/nucleus-dynamic-css';

import { Slider } from '../../sliders/Slider';
import { FormElement } from '../FormElement';

type Props = React.PropsWithChildren<{
  fieldName?: string;
  values?: any[];
  onChange?: (...args: any[]) => any;
  onValuesUpdated?: (...args: any[]) => any;
}>;

/**
FormElement wrapper around the Slider component.

onChange = function(fieldName, newValue)
**/
export class SliderFormElement extends React.Component<Props> {
  static displayName = 'SliderFormElement';
  constructor(props: Props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onValuesUpdated = this.onValuesUpdated.bind(this);
  }
  onChange(value: any) {
    if (this.props.onChange) {
      this.props.onChange(this.props.fieldName, value.values);
    }
  }
  onValuesUpdated(value: any) {
    if (this.props.onValuesUpdated) {
      this.props.onValuesUpdated(this.props.fieldName, value.values);
    }
  }
  render() {
    const { values, children, ...rest } = this.props;
    return (
      <FormElement {...rest} onChange={this.onChange}>
        <Slider
          {...rest}
          {...select(this.props, 'slider')}
          values={values}
          onChange={this.onChange}
          onValuesUpdated={this.onValuesUpdated}
        />
        {children}
      </FormElement>
    );
  }
}
