import { BULK_REPLACE_FOLDERS } from './actions';
import { IQuestionFolder, IFolder } from '../../../types/questions';
export function initialSetQuestionFolders() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const client = state.clients.questionLibraryClient;

    try {
      const res = await client.fetchQuestionFolders();
      const newFolders: IQuestionFolder = {};
      res.data.map((folder: IFolder) => {
        newFolders[folder.id] = { name: folder.name, questions: [] };
      });
      dispatch({
        type: BULK_REPLACE_FOLDERS,
        payload: { newFolders }
      });
      return newFolders;
    } catch (err) {
      return false;
    }
  };
}
