import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { injectTestId } from '@cvent/nucleus-test-automation';
import { Button } from 'nucleus-core';

type OwnProps = {
  classes?: {
    buttons?: any;
    container: string;
    heroImage?: string;
    header: string;
    description: string;
    buttonContainer: string;
  };
  style?: any;
  resourceKeys?: {
    headerText?: string;
    instructionalText?: string;
    continueSessionText?: string;
    endSessionText?: string;
  };
  setSessionTimeoutDialogConfig?: any;
  closeDialog: (...args: any[]) => any;
  refreshSession?: (...args: any[]) => any;
  endSession?: (...args: any[]) => any;
  translate: (...args: any[]) => any;
};

type Props = OwnProps & typeof SessionTimeoutDialog.defaultProps;

/**
 * Dialog to trigger when the session is about to expire to allow the user
 * to either continue the session or end the session.
 */
export class SessionTimeoutDialog extends React.Component<Props> {
  static displayName = 'SessionTimeoutDialog';

  static defaultProps = {
    resourceKeys: {
      headerText: 'SessionTimeoutDialog_HeaderText__resx',
      instructionalText: 'SessionTimeoutDialog_InstructionalText__resx',
      continueSessionText: 'SessionTimeoutDialog_ContinueButtonText__resx',
      endSessionText: 'SessionTimeoutDialog_LogOutButtonText__resx'
    }
  };

  closeDialog = () => {
    if (this.props.closeDialog) {
      this.props.closeDialog();
    }
  };

  onContinue = (event: any) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    if (this.props.refreshSession) {
      this.props.refreshSession();
    }
    this.closeDialog();
  };

  onLogout = (event: any) => {
    if (event && event.stopPropagation) {
      event.stopPropagation();
    }
    if (this.props.endSession) {
      this.props.endSession();
    }
    this.closeDialog();
  };

  render() {
    const { translate, resourceKeys, classes, style } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'buttons' does not exist on type '{ butto... Remove this comment to see the full error message
    const { buttons: ButtonClasses } = classes;
    return (
      <div {...resolve(this.props, 'container')}>
        <div {...resolve(this.props, 'heroImage')} />
        <div {...resolve(this.props, 'header')}>{translate(resourceKeys.headerText)}</div>
        <div {...resolve(this.props, 'description')}>
          {translate(resourceKeys.instructionalText)}
        </div>
        <div {...resolve(this.props, 'buttonContainer')}>
          <Button
            {...injectTestId('session-timeout-continue')}
            classes={ButtonClasses}
            style={{
              primary: style?.primary
            }}
            kind="primary"
            onClick={this.onContinue}
          >
            {translate(resourceKeys.continueSessionText)}
          </Button>
          <Button
            {...injectTestId('session-timeout-logout')}
            classes={ButtonClasses}
            style={{
              // @ts-expect-error ts-migrate()
              tertiary: style?.secondary
            }}
            kind="tertiary"
            onClick={this.onLogout}
          >
            {translate(resourceKeys.endSessionText)}
          </Button>
        </div>
      </div>
    );
  }
}
