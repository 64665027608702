import { getAllChildWidgetsInItem } from './getAllChildWidgetsInItem';

/**
 * Finds all of the child widgets contained in a section and returns an
 * object containing the maximum minimum cell size across all of the
 * child widgets, and an array with an item for each section column that
 * contains the array of widgets in that section column.
 */
export function getAllChildWidgetsInSection(
  state: any,
  section: any,
  widgetFactory: any,
  includeDescendants = false
) {
  const sectionColumnWidgets = section.layout.childIds.map((childId: any) => {
    const widgets: any = [];
    getAllChildWidgetsInItem(state, state[childId], widgets, includeDescendants);
    if (!widgets.length) {
      return {
        widgets: [],
        minCellSize: 0
      };
    }
    // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'widget1' implicitly has an 'any' type.
    const minCellSizedWidget = widgets.reduce((widget1, widget2) => {
      const widget1MetaData = widgetFactory.loadMetaData(widget1.widgetType);
      const widget2MetaData = widgetFactory.loadMetaData(widget2.widgetType);
      return widget1MetaData.minCellSize > widget2MetaData.minCellSize ? widget1 : widget2;
    });
    return {
      widgets,
      minCellSize: widgetFactory.loadMetaData(minCellSizedWidget.widgetType).minCellSize
    };
  });
  return {
    widgetColumns: sectionColumnWidgets,
    minCellSize: sectionColumnWidgets.reduce((column1: any, column2: any) =>
      column1.minCellSize > column2.minCellSize ? column1 : column2
    ).minCellSize
  };
}
