/**
 * This is reducer for calendar Filters
 * @type {string}
 */

import { Dispatch } from 'react';

import { keyBy, uniqBy } from 'lodash';

import { LOCATION, CHECKBOX } from '../../lib/CalendarView/Constants';

const LOAD_FILTERS = 'cvent-event-calendar/calendarFilters/LOAD_FILTERS';
const LOCATION_ID = '9a9a7027-8eda-43e0-873a-3ddc2191daee';

/**
 * functions to create the filter options for UI
 * @param options
 * @param translate
 * @returns {Array}
 */
function convertOptionsToUiOptions(options = [], translate: $TSFixMe) {
  return options.map(option => {
    // @ts-expect-error TS(2339): Property 'resourceKey' does not exist on type 'nev... Remove this comment to see the full error message
    const translatedValue = translate(option.resourceKey, {}, () => option.name);
    return {
      name: translatedValue,
      value: translatedValue
    };
  });
}

/**
 * function to create a new filter config using the existing
 *
 * @param filters
 * @param translate
 * @returns {Array}
 */
function convertFilterToUiFilters(filters: $TSFixMe, translate: $TSFixMe) {
  return filters.map((filter: $TSFixMe) => {
    return {
      title: translate(filter.resourceKey, {}, () => filter.name),
      name: filter.id,
      options: convertOptionsToUiOptions(filter.options, translate),
      type: filter.id === LOCATION_ID ? LOCATION : CHECKBOX,
      cventFieldType: filter.typeId
    };
  });
}

/**
 * Reloads filters with more choices if any, retaining the user selection
 */
export function reloadFilters(newFilters: $TSFixMe) {
  return (dispatch: Dispatch<$TSFixMe>, getState: $TSFixMe) => {
    const { filters } = getState().calendarFilters;
    const keyedNewFilters = keyBy(convertFilterToUiFilters(newFilters, getState().text.translate), 'name');
    // merge existing filter choices into new
    const newFiltersWithOptionsMergedIntoOld = Object.values(filters).map((filter: $TSFixMe) => {
      if (keyedNewFilters.hasOwnProperty(filter.name)) {
        const newFilter = keyedNewFilters[filter.name];
        return {
          ...newFilter,
          options: uniqBy([...filter.options, ...newFilter.options], (option: $TSFixMe) => option.name)
        };
      }
      return filter;
    });
    const mergedFilters = newFiltersWithOptionsMergedIntoOld.concat(
      Object.values(keyedNewFilters).filter((keyedNewFilter: $TSFixMe) => !filters.hasOwnProperty(keyedNewFilter.name))
    );
    // load merged filters in store
    dispatch({
      type: LOAD_FILTERS,
      payload: {
        filters: mergedFilters
      }
    });
  };
}

/**
 * Load calendar filters
 */
export function loadFilters(filters: $TSFixMe) {
  return (dispatch: Dispatch<$TSFixMe>, getState: $TSFixMe) => {
    const mappedFilters = convertFilterToUiFilters(filters, getState().text.translate);
    dispatch({
      type: LOAD_FILTERS,
      payload: { filters: mappedFilters }
    });
  };
}

/**
 * Reducer to keep track of the filters config
 *
 * @param state
 * @param action
 * @returns {*}
 */
export default function calendarFilters(state = { filters: {} }, action: $TSFixMe) {
  switch (action.type) {
    case LOAD_FILTERS: {
      return { ...state, filters: keyBy(action.payload.filters, 'name') };
    }
    default:
      return state;
  }
}
