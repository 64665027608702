import uniqueId from 'lodash/uniqueId';
import { getParentPage, getTempId } from '../../../utils';
import { ADD_COMMENT, PRESERVE_DRAFT, COMMENT_POST_ERROR } from './actions';
import {
  COMMENT_ERRORS,
  getCommentError,
  removeCommentError,
  setCommentErrorLoading
} from './errors';

export function preserveDraft(widgetId: string, newDraft: string) {
  return { type: PRESERVE_DRAFT, payload: { widgetId, newDraft } };
}

function setPostCommentError(widgetId: string, isAlert = true) {
  const type = COMMENT_ERRORS.Post;
  const data = { widgetId };

  return {
    type: COMMENT_POST_ERROR,
    payload: { id: uniqueId(), retrying: false, isAlert, type, data }
  };
}

function saveComment(widgetId: string, commentText: string) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const client = state.clients.commentsClient;
    const isGuestSide = !!state.pathInfo?.currentPageId;
    let pageId;
    if (!isGuestSide) {
      pageId = getParentPage(state, state.website.layoutItems[widgetId]).id;
    }

    const id = getTempId();
    const createdDate = Date.now();
    const commentData = { id, text: commentText, createdDate, widgetId };

    try {
      const newComment = await client.createComment(widgetId, commentData);

      const payload = {
        ...newComment,
        widgetId,
        ...(!isGuestSide ? { pageId } : {})
      };

      dispatch({ type: ADD_COMMENT, payload });
      dispatch(preserveDraft(widgetId, ''));

      return true;
    } catch (err) {
      return false;
    }
  };
}

export function retryAddComment(widgetId: string) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const comments = state.comments;
    const comment = comments.drafts[widgetId];

    const error = getCommentError(comments.errors, COMMENT_ERRORS.Post, {
      widgetId
    });

    if (!comment) {
      dispatch(removeCommentError(error.id));
      return;
    }

    dispatch(setCommentErrorLoading(error.id, true));

    const success = await dispatch(saveComment(widgetId, comment));

    if (success) {
      dispatch(removeCommentError(error.id));
    } else {
      dispatch(setCommentErrorLoading(error.id, false));
    }
  };
}

export function addComment(widgetId: string, commentText: string) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const comments = state.comments;

    const error = getCommentError(comments.errors, COMMENT_ERRORS.Post, {
      widgetId
    });
    if (error) {
      dispatch(removeCommentError(error.id));
    }

    const success = await dispatch(saveComment(widgetId, commentText));

    if (!success) {
      dispatch(setPostCommentError(widgetId));
    }

    return success === false;
  };
}
