import getAllRegExpMatches from './getAllRegExpMatches';

/**
 * Escapes any of the characters found in any regular expression matches in the given text string.
 * @param {string} text - the input text.
 * @param {[string]} charactersToEscape - an array of characters to escape in the matched text.
 * @param {string} escapeCharacter - The character (string) to escape the charactersToEscape with.
 * @param {RegExp} formatRegExp - The regular expression for finding matches in the input text.
 * @returns {string} - the escaped text string.
 */
export function escapeText(
  text: any,
  charactersToEscape: any,
  escapeCharacter: any,
  formatRegExp: any
) {
  const matches = getAllRegExpMatches(formatRegExp, text);
  let newText = text;
  matches.forEach(match => {
    let escapedMatch = match;
    charactersToEscape.forEach((character: any) => {
      escapedMatch = escapedMatch.replace(character, `${escapeCharacter}${character}`);
    });
    newText = newText.replace(match, escapedMatch);
  });
  return newText;
}

/**
 * Escapes any of the characters found in any regular expression matches in the text strings of the given object.
 * @param {object} object - the input object contianing the text strings to escape.
 * @param {[string]} charactersToEscape - an array of characters to escape in the matched text.
 * @param {string} escapeCharacter - The character (string) to escape the charactersToEscape with.
 * @param {RegExp} formatRegExp - The regular expression for finding matches in the input text.
 * @returns {string} - an identical object containing the escaped text strings instead.
 */
export default function escapeTextInObject(
  object: any,
  charactersToEscape: any,
  escapeCharacter: any,
  formatRegExp: any
) {
  const textEscapedObject: { [key: string]: any } = {};
  for (const key in object) {
    if (object.hasOwnProperty(key)) {
      textEscapedObject[key] = escapeText(
        object[key],
        charactersToEscape,
        escapeCharacter,
        formatRegExp
      );
    }
  }
  return textEscapedObject;
}
