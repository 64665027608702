import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

type Props = React.PropsWithChildren<{
  label?: string;
  style?: {
    message?: any;
    label?: any;
  };
}>;

/**
A simple message component to render child content with an optional label element before it.
**/
const Message = (props: Props) => {
  const { children, label } = props;
  return (
    <div {...resolve(props, 'message')} {...resolveTestId(props)}>
      {label ? <span {...resolve(props, 'label')}>{label}</span> : null}
      {children || null}
    </div>
  );
};
Message.displayName = 'Message';

export { Message };
