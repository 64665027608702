import noop from 'lodash/noop';

/**
 * Creates a fact provider function given the passed in config.
 */
export function createFactProvider(
  config: any,
  baseFactProvider = noop,
  frameworkFactProvider = noop
) {
  return (prevState: any, action: any, nextState: any) => {
    if (!config || !config.enabled || !config.appId) {
      return null;
    }

    // Build the base information to apply to all facts.
    const baseFactInfo = {
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      ...baseFactProvider(prevState, action, nextState),
      app_id: config.appId,
      ...(config.baseFactProvider ? config.baseFactProvider(prevState, action, nextState) : {})
    };

    // Get any facts generated from the framework and/or app specific facts.
    const facts = [];
    const frameworkFact = frameworkFactProvider(prevState, action, nextState);
    // @ts-expect-error ts-migrate(1345) FIXME: An expression of type 'void' cannot be tested for ... Remove this comment to see the full error message
    if (frameworkFact) {
      // @ts-expect-error ts-migrate(2358) FIXME: The left-hand side of an 'instanceof' expression m... Remove this comment to see the full error message
      const isArray = frameworkFact instanceof Array;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'length' does not exist on type 'void'.
      if (isArray && frameworkFact.length) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'forEach' does not exist on type 'void'.
        frameworkFact.forEach((fact: any) => {
          facts.push({
            ...baseFactInfo,
            ...fact
          });
        });
      } else if (!isArray) {
        facts.push({
          ...baseFactInfo,
          // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...frameworkFact
        });
      }
    }
    const appSpecificFact =
      config.factProvider && config.factProvider(prevState, action, nextState);
    if (appSpecificFact) {
      const isArray = appSpecificFact instanceof Array;
      if (isArray && appSpecificFact.length) {
        appSpecificFact.forEach((fact: any) => {
          facts.push({
            ...baseFactInfo,
            ...fact
          });
        });
      } else if (!isArray) {
        facts.push({
          ...baseFactInfo,
          ...appSpecificFact
        });
      }
    }

    return facts;
  };
}
