import { mapValues } from 'lodash';

import layoutItemMigrations from './layoutItem';

/**
 * Version 2 migrations for the calendar website.
 * - Migrate new addToCalendar setting into the CalendarListWidget.
 */
export default function v2(website: $TSFixMe) {
  return {
    ...website,
    calendarWebsiteVersion: 2,
    layoutItems: mapValues(website.layoutItems, layoutItemMigrations)
  };
}
