/**
 * Generates a fact from the given state and action if necessary.
 */
// eslint-disable-next-line no-unused-vars
function factProvider(prevState: any, action: any, nextState: any) {
  switch (action.type) {
    // TODO: add some framework level facts.
    default:
      return null;
  }
}

export { factProvider };
