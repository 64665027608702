import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import PropTypes from 'prop-types';

/**
 * Renders an item in the panel i.e. a filter
 */
const PanelListItem = (props: any) => {
  const { title, children } = props;
  return (
    <div {...resolve(props, 'panelListItem')}>
      {title && <span {...resolve(props, 'filterListTitle')}>{title}</span>}
      {children}
    </div>
  );
};

PanelListItem.propsTypes = {
  /* Children to render, will typically be a filter to display inside panel list item */
  children: PropTypes.node,
  /* The name of the filter, displayed to the user */
  title: PropTypes.string,
  /* Classes to use by the component */
  classes: PropTypes.object,
  /* Available style keys */
  style: PropTypes.shape({
    panelListItem: PropTypes.object,
    filterListTitle: PropTypes.object
  })
};

PanelListItem.displayName = 'PanelListItem';

export { PanelListItem };
