import React from 'react';

import { BlockTypes } from '@cvent/rich-text-renderer';
import { resolveTestId } from '@cvent/nucleus-test-automation';

import { renderRichText } from '../../utils/renderRichText';
import { BaseWidget } from '../BaseWidget';
import { Text } from './Text';
import ClassNames from './Text.less';
import { ThemeableComponentProps } from '../ThemeableComponent';

type BlockTypes =
  | 'unstyled'
  | 'paragraph'
  | 'header-one'
  | 'header-two'
  | 'header-three'
  | 'header-four'
  | 'header-five'
  | 'header-six';
interface TextWidgetProps extends ThemeableComponentProps {
  type?: string;
  config?: {
    text?: Record<string, unknown> | string;
    baseBlockType: BlockTypes;
  };
  classes: Record<string, unknown>;
}
/**
 * Data wrapper for the text widget.
 */
export class TextWidget extends BaseWidget<TextWidgetProps> {
  static displayName = 'TextWidget';

  static defaultProps = {
    classes: ClassNames
  };
  getStyleObject() {
    return {
      ...super.getStyleObject(),
      text: this.getElementInlineStyle('textInput')
    };
  }
  render() {
    const { config, translate, classes, style } = this.props;
    const styleObject = this.getStyleObject();
    return (
      <Text
        {...resolveTestId(this.props)}
        classes={classes}
        style={styleObject}
        text={renderRichText(config?.text, translate, style?.palette, styleObject.text, {
          baseBlockType: config?.baseBlockType
        })}
      />
    );
  }
}
