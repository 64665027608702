/**
 * Gets the value out of the provided root object that is specified by the provided path.
 * @param {array} rootObject - The root JSON object.
 * @param {string|array} path - The path to the desired value in the root object.
 *     Can either be a string with keys separated by periods, or an array of keys.
 * @returns The specified value in the root object..
 */
export function getJSONValue(rootObject: any, path: any) {
  let value = rootObject;
  let keys;
  if (path instanceof Array) {
    keys = path.slice(0);
  } else {
    keys = path.split('.');
  }
  keys.forEach((key: any) => {
    if (!value) {
      return;
    }
    value = value[key];
  });
  return value;
}
