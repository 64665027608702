import React from 'react';

import Logger from '@cvent/nucleus-logging';
import { injectTestId, resolveTestId } from '@cvent/nucleus-test-automation';

import {
  CONTAINER,
  SECTION_ROW,
  SECTION_COLUMN,
  ROW,
  WIDGET
} from '../../utils/layout/LayoutTypes';
import { Container } from '../shared/layoutItems/Container';
import { SectionColumn } from '../shared/layoutItems/SectionColumn';
import { SectionRow } from '../shared/layoutItems/SectionRow/SectionRow';
import { Row } from '../shared/layoutItems/Row/Row';
import { Widget } from '../shared/layoutItems/Widget/Widget';
import { WidgetRenderer } from '../shared/WidgetRenderer';
import { SectionContainer } from './section/SectionContainer';

const LOG = new Logger('nucleus-widgets/renderers/readOnlyContent');

type OwnProps = {
  rootLayoutItemId: string;
  isGuest?: boolean;
  device?: string;
  theme?: any;
  imageLookup?: any;
  browserFeatures?: {
    supportsWebp?: boolean;
  };
  layoutItems?: any;
  widgetFactory?: any;
  guestTranslate?: (...args: any[]) => any;
  widgetContainerComponent?: React.ReactElement | React.ComponentType<any>;
  sectionContainerComponent?: React.ReactElement;
  sectionComponent?: React.ReactElement;
};

export type ReadOnlyContentProps = OwnProps & typeof ReadOnlyContent.defaultProps;

/**
 * A custom renderer that generates read only content.
 */
export class ReadOnlyContent extends React.Component<ReadOnlyContentProps> {
  static displayName = 'ReadOnlyContent';
  static defaultProps = {
    widgetContainerComponent: WidgetRenderer,
    sectionContainerComponent: SectionContainer,
    sectionComponent: SectionRow
  };
  constructor(props: ReadOnlyContentProps) {
    super(props);
    this.generateLayout = this.generateLayout.bind(this);
  }
  shouldComponentUpdate(nextProps: ReadOnlyContentProps) {
    return (
      this.props.rootLayoutItemId !== nextProps.rootLayoutItemId ||
      this.props.theme !== nextProps.theme ||
      this.props.imageLookup !== nextProps.imageLookup ||
      this.props.layoutItems !== nextProps.layoutItems ||
      this.props.device !== nextProps.device
    );
  }
  getChildren(layoutItem: any, testId: any) {
    const { layoutItems } = this.props;
    const childLayoutItems = layoutItem.layout.childIds.map((id: any) => layoutItems[id]);
    return childLayoutItems.map((child: any, index: any) =>
      this.generateLayout(child, resolveTestId(testId, `-child-${index}`))
    );
  }
  // Generate the component instance representing the given layoutItem.
  generateLayout(layoutItem: any, testId: any) {
    const {
      isGuest,
      device,
      theme,
      layoutItems,
      widgetFactory,
      guestTranslate,
      widgetContainerComponent,
      sectionContainerComponent,
      imageLookup,
      browserFeatures: { supportsWebp } = {} as any
    } = this.props;
    switch (layoutItem.layout.type) {
      case CONTAINER: {
        return <Container key={layoutItem.id}>{this.getChildren(layoutItem, testId)}</Container>;
      }
      case SECTION_ROW: {
        return (
          <this.props.sectionComponent
            key={layoutItem.id}
            section={layoutItem}
            theme={theme}
            imageLookup={imageLookup}
            supportsWebp={supportsWebp}
            contentWidth={theme.global.dimensions.contentWidth}
            sectionContainerComponent={sectionContainerComponent}
          >
            {this.getChildren(layoutItem, testId)}
          </this.props.sectionComponent>
        );
      }
      case ROW: {
        return <Row key={layoutItem.id}>{this.getChildren(layoutItem, testId)}</Row>;
      }
      case SECTION_COLUMN: {
        return (
          <SectionColumn key={layoutItem.id} layoutItem={layoutItem}>
            {this.getChildren(layoutItem, testId)}
          </SectionColumn>
        );
      }
      case WIDGET: {
        return (
          <Widget
            key={layoutItem.id}
            widget={layoutItem}
            layoutItems={layoutItems}
            theme={theme}
            widgetContainerComponent={widgetContainerComponent}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; key: any; widget: any; layo... Remove this comment to see the full error message
            isGuest={isGuest}
            device={device}
            widgetMetaData={widgetFactory.loadMetaData(layoutItem.widgetType)}
            widgetFactory={widgetFactory}
            guestTranslate={guestTranslate}
          >
            {this.getChildren(layoutItem, testId)}
          </Widget>
        );
      }
      default:
        LOG.error(`Undefined Layout Type '${layoutItem.layout.type}' Encountered!`);
    }
  }
  render() {
    const { rootLayoutItemId, layoutItems } = this.props;
    const layoutItem = layoutItems[rootLayoutItemId];
    if (!layoutItem) {
      return null;
    }
    return this.generateLayout(layoutItem, injectTestId('canvas'));
  }
}
