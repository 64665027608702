/* eslint react/prop-types: [2, { ignore: ["setFocus", "onChange", "fieldName", "id", "name"] }] */
import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

import { tapOrClick } from '../touchEventHandlers';
import { getFloatPosition } from '../utils/getFloatPosition';
import { ColorFormBase } from './ColorFormBase';
import { ColorPicker } from './ColorPicker';
import { HexColorInput } from './HexColorInput';

/**
Custom Form Element wrapper around the ColorPicker component.
Allows us to pass in a theme palette of colors to choose from in
addition to the normal color picker.
**/
export class PaletteFlyout extends ColorFormBase {
  static displayName = 'PaletteFlyout';
  constructor(props: any) {
    super(props);
    this.onSwap = this.onSwap.bind(this);
    this.onPaletteMouseDown = this.onPaletteMouseDown.bind(this);
  }
  componentDidMount() {
    const floatPosition = getFloatPosition(this.refs.colorFlyout);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'triggerItem' does not exist on type 'Rea... Remove this comment to see the full error message
    const horz = this.props.triggerItem === 'box' ? floatPosition.horz : 'left';
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'React... Remove this comment to see the full error message
    this.refs.colorFlyout.className = resolve(
      this.props,
      'colorFlyout',
      floatPosition.vert,
      horz
    ).className;
  }
  onPaletteMouseDown(e: any) {
    e.preventDefault();
  }
  onSwap(color: any) {
    return () => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'valueType' does not exist on type 'Reado... Remove this comment to see the full error message
      const hslValue = this.getHslValue(color.value, this.props.valueType);
      this.onChange(hslValue, color.id);
    };
  }
  render() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
      fieldName,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
      value,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'paletteId' does not exist on type 'Reado... Remove this comment to see the full error message
      paletteId,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type 'Readonl... Remove this comment to see the full error message
      palette,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'paletteLabel' does not exist on type 'Re... Remove this comment to see the full error message
      paletteLabel,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'triggerItem' does not exist on type 'Rea... Remove this comment to see the full error message
      triggerItem,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'size' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      size,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type 'Readonly<... Remove this comment to see the full error message
      style,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'Readonl... Remove this comment to see the full error message
      classes,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'cursorRadius' does not exist on type 'Re... Remove this comment to see the full error message
      cursorRadius,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'barWidthRatio' does not exist on type 'R... Remove this comment to see the full error message
      barWidthRatio
    } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'hsl' does not exist on type 'Readonly<{}... Remove this comment to see the full error message
    const { hsl } = this.state;
    let paletteColor: any = null;
    let paletteColors = null;

    if (palette) {
      paletteColor = palette.find((color: any) => color.id === paletteId);
      paletteColors = palette.map((color: any, index: any) => {
        return (
          <div
            key={'color' + index}
            {...tapOrClick(this.onSwap(color))}
            {...resolve(
              this.props,
              'colorBox',
              color.value ? '' : 'empty',
              paletteColor && color.id === paletteColor.id ? 'selected' : null
            )}
            style={{ background: color.value }}
          />
        );
      });
    }
    const hexInput = (
      <HexColorInput
        ref="hexinput"
        fieldName={fieldName}
        hsl={hsl}
        isBlank={paletteColor ? paletteColor.value === null : value === null}
        onClick={this.onClick}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onColorChange={this.onChange}
        style={style}
        classes={classes}
        inFlyout
      />
    );

    return (
      <div {...resolveTestId(this.props)} {...resolve(this.props, 'colorFlyout')} ref="colorFlyout">
        <ColorPicker
          {...resolveTestId(this.props, '-color-picker')}
          hsl={hsl}
          size={size}
          onColorChange={this.onChange}
          cursorRadius={cursorRadius}
          barWidthRatio={barWidthRatio}
          // @ts-expect-error ts-migrate()
          style={style}
          classes={classes}
        />
        {palette ? (
          <div className={classes.themeTitle}>
            {paletteLabel} {paletteColor ? ` | ${paletteColor.name}` : null}
          </div>
        ) : null}
        {palette ? (
          <div className={classes.themePalette} onMouseDown={this.onPaletteMouseDown}>
            {paletteColors}
          </div>
        ) : null}
        {triggerItem === 'box' ? hexInput : null}
      </div>
    );
  }
}
