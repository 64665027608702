import React from 'react';

import { Tooltip } from '@cvent/carina/components/Tooltip';
import { resolve } from '@cvent/nucleus-dynamic-css';
import { Trigger } from 'nucleus-core';
import { connect } from 'react-redux';
import { injectTestId } from '@cvent/nucleus-test-automation';

import { CommentListFlyout } from '../../components/Comments/CommentListFlyout';
import { getEnabledCommentCount } from '../../redux/modules/comments/utils';
import { NewCommentIcon } from './NewCommentIcon';
import Classes from './WidgetWrapper.less';

const MAX_DISPLAY_COUNT = 9;

type WidgetWrapperBaseProps = {
  isGuest: boolean;
  widget: any;
  comments: Comment[];
  children: React.ReactNode;
  translate: any;
  classes?: any;
};

/**
 * Wrapper component for the WidgetRenderer component that provides additional functionality
 * such as adding comments to widget
 */
export class WidgetWrapperBase extends React.Component<WidgetWrapperBaseProps> {
  static displayName = 'WidgetWrapper';

  constructor(props: WidgetWrapperBaseProps) {
    super(props);
  }

  static defaultProps = {
    classes: Classes
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  };

  onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      // @ts-expect-error ts(2339) Property 'trigger' does not exist on type 'WidgetWrapperBase'.
      this.trigger?.handleShow();
    }
  };

  getCommentCountBadge = (commentCount: number, isGuest: boolean, ariaLabel: string) => {
    return (
      <div
        {...resolve(this.props, 'commentWrapper', 'commentCount', isGuest && 'isGuest')}
        {...injectTestId('comment-count-icon')}
        // @ts-expect-error ts(2339) Property 'inputElement' does not exist on type 'WidgetWrapperBase'.
        ref={div => (this.inputElement = div)}
        onKeyDown={e => this.onKeyDown(e)}
        onKeyUp={e => this.onKeyUp(e)}
        aria-label={ariaLabel}
        role="button"
      >
        {commentCount > MAX_DISPLAY_COUNT ? `${MAX_DISPLAY_COUNT}+` : commentCount}
      </div>
    );
  };

  getNewCommentIcon = (isGuest: boolean, ariaLabel: string) => {
    return (
      <div
        {...resolve(this.props, 'commentPost', 'messageIcon', isGuest && 'isGuest')}
        {...injectTestId('add-new-comment-icon')}
        onKeyDown={e => this.onKeyDown(e)}
        onKeyUp={e => this.onKeyUp(e)}
        aria-label={ariaLabel}
        role="button"
      >
        <NewCommentIcon />
      </div>
    );
  };

  shouldCloseFlyout = (target: HTMLElement) => {
    return !!target?.closest('#comment-alert-panel');
  };

  render() {
    const { isGuest, widget, children, translate, comments = [] }: any = this.props;
    const commentCount = getEnabledCommentCount(comments);
    const hasComments = commentCount > 0;
    const commentCountBadge = translate('NucleusWidgets_Comments_CommentBadge_Count__resx', {
      commentCount
    });
    const addNewComment = translate('NucleusWidgets_Comments_AddNewComment__resx');
    const viewCommentsTooltip = translate('NucleusWidgets_Comments_ViewsCommentsTooltip__resx');
    const leaveCommentsTooltip = translate('NucleusWidgets_Comments_PostCommentTooltip__resx');

    const countBadgeIconAriaLabel = commentCountBadge.concat(', '.concat(viewCommentsTooltip));
    const newCommentIconAriaLabel = addNewComment.concat(', '.concat(leaveCommentsTooltip));

    return isGuest ? (
      <div {...resolve(this.props, 'wrapper', isGuest && 'isGuest')}>
        <Trigger
          allowMouse={false}
          isTriggerFocusable={false}
          allowToggle
          // @ts-expect-error ts(2339) Property 'trigger' does not exist on type 'WidgetWrapperBase'.
          ref={t => (this.trigger = t)}
          mouseEnterTimeout={50}
          mouseLeaveTimeout={0}
          shouldCloseFlyout={this.shouldCloseFlyout}
        >
          {
            <Tooltip
              text={hasComments ? viewCommentsTooltip : leaveCommentsTooltip}
              trigger={
                hasComments
                  ? this.getCommentCountBadge(commentCount, isGuest, countBadgeIconAriaLabel)
                  : this.getNewCommentIcon(isGuest, newCommentIconAriaLabel)
              }
            />
          }
          <CommentListFlyout
            key={widget.id}
            comments={comments}
            translate={translate}
            widgetId={widget.id}
            isGuest={isGuest}
            // @ts-expect-error ts(2339) Property 'trigger' does not exist on type 'WidgetWrapperBase'.
            onCancel={this.trigger?.handleHide}
          />
        </Trigger>
        {children}
      </div>
    ) : (
      <div {...resolve(this.props, 'wrapper')}>
        {commentCount > 0 ? (
          <div {...resolve(this.props, 'commentCount')} aria-label={countBadgeIconAriaLabel}>
            {commentCount > MAX_DISPLAY_COUNT ? `${MAX_DISPLAY_COUNT}+` : commentCount}
          </div>
        ) : (
          <div aria-label={newCommentIconAriaLabel} />
        )}
        {children}
      </div>
    );
  }
}

export const WidgetWrapper = connect((state: any, props: any) => {
  return {
    comments: state.comments?.comments?.[props.widget.id]
  };
})(WidgetWrapperBase);
