/**
 * This component extends the EventEndingRow component from react-big-calendar@v0.14.0.
 *
 * 1) References to "className" and "style" are removed from the functions copied from the base component,
 *    and replaced by "resolve" and "select" to apply styles and pass styles to child components.
 * 2) Split 'let' declarations into multiple statements, to comply with our coding standards.
 * 3) Disable eslint on continue statement and no loop function to pass eslint checks.
 * 4) Move show more link to its own component, and modify renderShowMore function to use ShowMore component.
 * 5) Pass through keepFlyoutOpenOnDocumentClick prop.
 * 6) Add newFlyout param to showMore function, to pass back the flyout instance back to onShowMore callback.
 *
 * There are no functional changes.
 */

import React from 'react';

import { resolve, select } from '@cvent/nucleus-dynamic-css';
import PropTypes from 'prop-types';
import CalendarEventEndingRow from 'react-big-calendar/lib/EventEndingRow';
import { eventLevels } from 'react-big-calendar/lib/utils/eventLevels';
import { EventRowMixin, eventRowMixinStyleKeys } from './EventRowMixin';
import { ShowMore, showMoreStyleKeys } from './ShowMore';

const isSegmentInSlot = (seg: any, slot: any) => seg.left <= slot && seg.right >= slot;
const eventsInSlot = (segments: any, slot: any) =>
  segments.filter((seg: any) => isSegmentInSlot(seg, slot)).length;

export class EventEndingRow extends CalendarEventEndingRow {
  static displayName = 'EventEndingRow';
  static propTypes = {
    ...CalendarEventEndingRow.propTypes,
    keepFlyoutOpenOnDocumentClick: PropTypes.bool
  };
  static defaultProps = { ...CalendarEventEndingRow.defaultProps };

  render() {
    const { segments, slots: slotCount } = this.props;
    const rowSegments = eventLevels(segments).levels[0];

    let current = 1;
    let lastEnd = 1;
    const row = [];
    while (current <= slotCount) {
      const key = '_lvl_' + current;
      /* eslint-disable no-loop-func */
      const { event, left, right, span } =
        rowSegments.filter((seg: any) => isSegmentInSlot(seg, current))[0] || {}; //eslint-disable-line
      /* eslint-enable no-loop-func */

      if (!event) {
        current++;
        /* eslint-disable no-continue */
        continue;
        /* eslint-enable no-continue */
      }

      const gap = Math.max(0, left - lastEnd);

      if (this.canRenderSlotEvent(left, span)) {
        const content = EventRowMixin.renderEvent(this.props, event);

        if (gap) {
          row.push(EventRowMixin.renderSpan(this.props, gap, key + '_gap'));
        }

        row.push(EventRowMixin.renderSpan(this.props, span, key, content));

        lastEnd = current = right + 1;
      } else {
        if (gap) {
          row.push(EventRowMixin.renderSpan(this.props, gap, key + '_gap'));
        }

        row.push(
          EventRowMixin.renderSpan(this.props, 1, key, this.renderShowMore(segments, current))
        );
        lastEnd = current = current + 1;
      }
    }

    return <div {...resolve(this.props, 'row')}>{row}</div>;
  }

  renderShowMore(segments: Array<Record<string, unknown>>, slot: number) {
    const count = eventsInSlot(segments, slot);
    const { popup, renderOverlay, messages, keepFlyoutOpenOnDocumentClick } = this.props;

    return count ? (
      <ShowMore
        count={count}
        slot={slot}
        onClick={this.showMore}
        popup={popup}
        renderOverlay={renderOverlay}
        messages={messages}
        keepFlyoutOpenOnDocumentClick={keepFlyoutOpenOnDocumentClick}
        {...select(this.props, ...showMoreStyleKeys)}
      />
    ) : (
      false
    );
  }

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'React$Element'.
  showMore = (slot: number, newFlyout: React$Element<any>, e: Event) => {
    e.preventDefault();
    this.props.onShowMore(slot, newFlyout);
  };
}

export const eventEndingRowStyleKeys = ['row', ...eventRowMixinStyleKeys, ...showMoreStyleKeys];
