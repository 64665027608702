/**
 * This component extends the DateContentRow component from react-big-calendar@v0.14.0.
 *
 * 1) References to "className" and "style" are removed from the functions copied from the base component,
 *    and replaced by "resolve" and "select" to apply styles and pass styles down to child components.
 * 2) add check for ref (this.eventRow) in getRowLimit function.
 * 3) Comment out code related to function "qsa".
 * 4) Pass renderOverlay, range, startAccessor, and endAccessor to EventEndingRow component.
 * 5) Pass through keepFlyoutOpenOnDocumentClick prop.
 * 6) Add newFlyout param to handleShowMore function, to pass back flyout instance back to onShowMore callback.
 *
 * There are no functional changes.
 */

import React from 'react';

import { resolve, select } from '@cvent/nucleus-dynamic-css';
import PropTypes from 'prop-types';
import CalendarDateContentRow from 'react-big-calendar/lib/DateContentRow';
import dates from 'react-big-calendar/lib/utils/dates';
import {
  segStyle,
  eventSegments,
  endOfRange,
  eventLevels
} from 'react-big-calendar/lib/utils/eventLevels';

import { BackgroundCells, backgroundCellsStyleKeys } from './BackgroundCells';
import { EventEndingRow, eventEndingRowStyleKeys } from './EventEndingRow';
import { EventRow, eventRowStyleKeys } from './EventRow';

export const dateContentRowStyleKeys = [
  ...backgroundCellsStyleKeys,
  ...eventRowStyleKeys,
  ...eventEndingRowStyleKeys,
  'monthRow',
  'monthRowContent',
  'row',
  'dateCell',
  'day',
  'now'
];

const isSegmentInSlot = (seg: any, slot: any) => seg.left <= slot && seg.right >= slot;

export class DateContentRow extends CalendarDateContentRow {
  static displayName = 'DateContentRow';
  static propTypes = {
    ...CalendarDateContentRow.propTypes,
    keepFlyoutOpenOnDocumentClick: PropTypes.bool,
    // Add date specific cell style or className
    dateCellStyleGetter: PropTypes.func
  };
  static defaultProps = { ...CalendarDateContentRow.defaultProps };

  createEventRef: any;
  createHeadingRef: any;
  eventRow: any;
  getContainer: any;
  handleSelectSlot: any;
  props: any;
  segments: any;

  getRowLimit() {
    if (this.eventRow) {
      return super.getRowLimit();
    }
  }

  // @ts-expect-error ts-migrate(2304) FIXME: Cannot find name 'React$Element'.
  handleShowMore = (slot: number, newFlyout: React$Element<any>) => {
    const { range, onShowMore } = this.props;
    /**
     * Comment out the lines in this function to avoid importing qsa function from dom-helpers module,
     * which isn't in our dependency list.
     * Also, our flyout is based on its trigger position, so we don't need the dimensions of the cells.
     */
    // let row = qsa(findDOMNode(this), '.rbc-row-bg')[0];

    let cell;
    // if (row) cell = row.children[slot-1];

    const events = this.segments
      .filter((seg: any) => isSegmentInSlot(seg, slot))
      .map((seg: any) => seg.event);
    onShowMore(events, range[slot - 1], cell, slot, newFlyout);
  };

  renderHeadingCell = (date: Date, index: number) => {
    const { renderHeader, range } = this.props;
    const resolvedStyles = resolve(
      this.props,
      'dateCell',
      dates.eq(date, this.props.now, 'day') ? 'now' : undefined
    );

    return renderHeader({
      date,
      key: `header_${index}`,
      style: {
        ...segStyle(1, range.length),
        ...resolvedStyles.style
      },
      className: resolvedStyles.className
    });
  };

  renderDummy = () => {
    const { range, renderHeader } = this.props;
    return (
      <div {...resolve(this.props, 'monthRow')}>
        <div {...resolve(this.props, 'monthRowContent')}>
          {renderHeader && (
            <div {...resolve(this.props, 'row')} ref={this.createHeadingRef}>
              {range.map(this.renderHeadingCell)}
            </div>
          )}
          <div {...resolve(this.props, 'row')} ref={this.createEventRef}>
            <div {...resolve(this.props, 'rowSegment')} style={segStyle(1, range.length)}>
              <div {...resolve(this.props, 'event')}>
                <div {...resolve(this.props, 'eventContent')}>&nbsp;</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      rtl,
      events,
      range,
      selectable,
      renderForMeasure,
      startAccessor,
      endAccessor,
      renderHeader,
      minRows,
      maxRows,
      dateCellWrapper,
      eventComponent,
      eventWrapperComponent,
      onSelectStart,
      onSelectEnd,
      renderOverlay,
      keepFlyoutOpenOnDocumentClick,
      dateCellStyleGetter,
      ...props
    } = this.props;

    if (renderForMeasure) {
      return this.renderDummy();
    }

    const { first, last } = endOfRange(range);

    const segments = (this.segments = events.map((evt: any) =>
      eventSegments(
        evt,
        first,
        last,
        {
          startAccessor,
          endAccessor
        },
        range
      )
    ));

    const { levels, extra } = eventLevels(segments, Math.max(maxRows - 1, 1));
    while (levels.length < minRows) levels.push([]);
    return (
      <div {...resolve(this.props, 'monthRow')}>
        {/** @ts-expect-error 'EventRow' cannot be used as a JSX component. */}
        <BackgroundCells
          rtl={rtl}
          range={range}
          selectable={selectable}
          container={this.getContainer}
          onSelectStart={onSelectStart}
          onSelectEnd={onSelectEnd}
          onSelectSlot={this.handleSelectSlot}
          cellWrapperComponent={dateCellWrapper}
          dateCellStyleGetter={dateCellStyleGetter}
          {...select(this.props, ...backgroundCellsStyleKeys)}
        />

        <div {...resolve(this.props, 'monthRowContent')}>
          {renderHeader && (
            <div {...resolve(this.props, 'row')} ref={this.createHeadingRef}>
              {range.map(this.renderHeadingCell)}
            </div>
          )}
          {levels.map((segs: any, idx: any) => (
            /** @ts-expect-error 'EventRow' cannot be used as a JSX component. */
            <EventRow
              {...props}
              key={idx}
              start={first}
              end={last}
              segments={segs}
              slots={range.length}
              eventComponent={eventComponent}
              eventWrapperComponent={eventWrapperComponent}
              startAccessor={startAccessor}
              endAccessor={endAccessor}
              {...select(this.props, ...eventRowStyleKeys)}
            />
          ))}
          {!!extra.length && (
            /** @ts-expect-error 'EventEndingRow' cannot be used as a JSX component. */
            <EventEndingRow
              {...props}
              start={first}
              end={last}
              segments={extra}
              renderOverlay={renderOverlay}
              range={range}
              onShowMore={this.handleShowMore}
              eventComponent={eventComponent}
              eventWrapperComponent={eventWrapperComponent}
              keepFlyoutOpenOnDocumentClick={keepFlyoutOpenOnDocumentClick}
              startAccessor={startAccessor}
              endAccessor={endAccessor}
              {...select(this.props, ...eventEndingRowStyleKeys)}
            />
          )}
        </div>
      </div>
    );
  }
}
