const standardPadding = [
  'spacingHeader',
  'spacing.padding.paddingTop',
  'spacing.padding.paddingRight',
  'spacing.padding.paddingBottom',
  'spacing.padding.paddingLeft'
];
const standardPaddingWithStyleKey = [
  'spacingHeader',
  'style.spacing.padding.paddingTop',
  'style.spacing.padding.paddingRight',
  'style.spacing.padding.paddingBottom',
  'style.spacing.padding.paddingLeft'
];

const customPadding = [
  'spacingHeader',
  'customSettings.spacing.padding.paddingTop',
  'customSettings.spacing.padding.paddingRight',
  'customSettings.spacing.padding.paddingBottom',
  'customSettings.spacing.padding.paddingLeft'
];

const textStyling = [
  'textHeader',
  'text.fontFamily',
  'text.fontWeight',
  'text.fontSize',
  'text.color'
];

const textStylingBlocks = ['textHeader', 'text.fontFamily', 'text.fontWeight', 'text.fontSize'];

const customTextStyling = [
  'textHeader',
  'customSettings.text.fontFamily',
  'customSettings.text.fontWeight',
  'text.fontSize',
  'text.color'
];

const textHoverStyling = ['textHeader', 'hover.text.color'];

const backgroundStyling = [
  'backgroundImageHeader',
  'background.image.url',
  'background.image.scale',
  'background.image.repeat.x',
  'background.image.repeat.y',
  'background.image.position.xOffset',
  'background.image.position.yOffset',
  'backgroundHeader',
  'background.color',
  'background.colorAlpha',
  'background.borderRadius'
];

const backgroundStylingBlocks = [
  'backgroundImageHeader',
  'background.image.url',
  'background.image.scale',
  'background.image.repeat.x',
  'background.image.repeat.y',
  'background.image.position.xOffset',
  'background.image.position.yOffset',
  'backgroundHeader',
  'background.colorAlpha',
  'background.borderRadius'
];

const backgroundHoverStyling = [
  'backgroundHeader',
  'hover.background.color',
  'hover.background.colorAlpha'
];

const customBackgroundColorStyling = [
  'backgroundImageHeader',
  'background.image.url',
  'background.image.scale',
  'background.image.repeat.x',
  'background.image.repeat.y',
  'background.image.position.xOffset',
  'background.image.position.yOffset',
  'backgroundHeader',
  'customSettings.background.color',
  'customSettings.background.colorAlpha',
  'background.borderRadius'
];

const backgroundWithStyleKey = [
  'backgroundImageHeader',
  'style.background.image.url',
  'style.background.image.scale',
  'style.background.image.repeat.x',
  'style.background.image.repeat.y',
  'style.background.image.position.xOffset',
  'style.background.image.position.yOffset',
  'backgroundHeader',
  'style.background.color',
  'style.background.colorAlpha',
  'style.background.borderRadius'
];

const backgroundWithStyleKeyBlocks = [
  'backgroundImageHeader',
  'style.background.image.url',
  'style.background.image.scale',
  'style.background.image.repeat.x',
  'style.background.image.repeat.y',
  'style.background.image.position.xOffset',
  'style.background.image.position.yOffset'
];

const borderStyling = ['borderHeader', 'border.color', 'border.thickness', 'border.borderStyle'];

const borderStylingBlocks = ['borderHeader', 'border.thickness', 'border.borderStyle'];

const borderHoverStyling = ['borderHeader', 'hover.border.color'];

const borderWithStyleKey = [
  'borderHeader',
  'style.border.color',
  'style.border.thickness',
  'style.border.borderStyle'
];

const customCssStyling = ['customCSSHeader', 'customCSSStyles'];
const customCssStylingWithStyleKey = ['customCSSHeader', 'style.customCSSStyles'];

const widgetStyles = [
  ...backgroundStyling,
  ...borderStyling,
  ...standardPadding,
  ...customCssStyling
];
const sectionStyles = [
  ...backgroundWithStyleKey,
  ...borderWithStyleKey,
  ...standardPaddingWithStyleKey,
  ...customCssStylingWithStyleKey
];

const sectionStylesBlocks = [
  ...backgroundWithStyleKeyBlocks,
  ...standardPaddingWithStyleKey,
  ...customCssStylingWithStyleKey
];

const widgetElementStyles = [
  'styleMapping',
  ...textStyling,
  'customSettings.text.textAlign',
  ...backgroundStyling,
  ...borderStyling,
  ...standardPadding,
  ...customCssStyling
];

const widgetElementStylesCustomPadding = [
  'styleMapping',
  ...textStyling,
  'customSettings.text.textAlign',
  ...backgroundStyling,
  ...borderStyling,
  ...customPadding,
  ...customCssStyling
];

const widgetElementStylesCustomBackground = [
  'styleMapping',
  ...textStyling,
  'customSettings.text.textAlign',
  ...customBackgroundColorStyling,
  ...borderStyling,
  ...standardPadding,
  ...customCssStyling
];

const widgetElementStylesCustomText = [
  'styleMapping',
  ...customTextStyling,
  'customSettings.text.textAlign',
  ...backgroundStyling,
  ...borderStyling,
  ...standardPadding,
  ...customCssStyling
];

const elementStyles = [
  ...textStyling,
  ...backgroundStyling,
  ...borderStyling,
  ...standardPadding,
  ...customCssStyling
];
const elementStylesBlocks = [
  ...textStylingBlocks,
  ...backgroundStylingBlocks,
  ...borderStylingBlocks,
  ...standardPadding,
  ...customCssStyling
];

/*
  DEPRECATED.
  Use widgetElementStylesWithPseudoStateTabsV2 instead.
*/
const elementHoverStyles = [
  ...textHoverStyling,
  ...backgroundHoverStyling,
  ...borderHoverStyling,
  ...customCssStyling,
  'resetHoverStyles'
];

const elementHoverStylesV2 = [
  'hover.styleMode',
  'hover.brightnessModifier',
  'hoverTextHeader',
  'hover.text.color',
  'hoverBackgroundHeader',
  'hover.background.color',
  'hover.background.colorAlpha',
  'hoverBorderHeader',
  'hover.border.color'
];

const elementHoverStylesV2Blocks = [
  'hover.styleMode',
  'hover.brightnessModifier',
  'hover.text.fontBoldToggle',
  'hoverBackgroundHeader',
  'hover.background.color',
  'hover.background.colorAlpha',
  'hoverBorderHeader',
  'hover.border.color'
];

const elements = [
  'elements.header1',
  'elements.header2',
  'elements.header3',
  'elements.header4',
  'elements.text1',
  'elements.text2',
  'elements.body1',
  'elements.body2',
  'elements.link',
  'elements.primaryButton',
  'elements.secondaryButton',
  'elements.input',
  'elements.label'
];

/*
  DEPRECATED.
  Use widgetElementStylesWithPseudoStateTabsV2 instead.
 */
const widgetElementStylesWithPseudoStateTabs = ['styleMapping', 'widgetElementPseudoStateTabs'];
const widgetElementStylesWithPseudoStateTabsV2 = ['styleMapping', 'widgetElementPseudoStateTabsV2'];

export const FieldGroupings = {
  standardPadding,
  standardPaddingWithStyleKey,
  customPadding,
  customTextStyling,
  textStyling,
  backgroundStyling,
  customBackgroundColorStyling,
  backgroundWithStyleKey,
  borderStyling,
  borderWithStyleKey,
  widgetStyles,
  sectionStyles,
  sectionStylesBlocks,
  widgetElementStyles,
  /*
    DEPRECATED.
    Use widgetElementStylesWithPseudoStateTabsV2 instead.
  */
  widgetElementStylesWithPseudoStateTabs,
  widgetElementStylesWithPseudoStateTabsV2,
  widgetElementStylesCustomPadding,
  widgetElementStylesCustomBackground,
  widgetElementStylesCustomText,
  elementStyles,
  elementStylesBlocks,
  /*
    DEPRECATED.
    Use widgetElementStylesWithPseudoStateTabsV2 instead.
  */
  elementHoverStyles,
  elementHoverStylesV2,
  elementHoverStylesV2Blocks,
  elements,
  customCssStyling
};
