import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';
import { removeKeys } from '../utils/removeKeys';

/**
 * Keeps track of the stack of focus traps.
 */
let focusTrapStack: FocusTrap[] = [];

type OwnProps = {
  disableTabIndex?: boolean;
  focusOnMount?: boolean;
  disableFocus?: boolean;
};

type State = {
  anchor: HTMLElement | null;
};

type Props = React.HTMLProps<HTMLDivElement> & OwnProps;

/**
 * FocusTrap - A container to maintain browser focus. When tabbing through
 * elements or otherwise changing focus, you will be unable to focus onto
 * any elements outside of the FocusTrap. Mounting a new FocusTrap will put
 * it on top of the 'stack', maintaining focus within it. When that FocusTrap
 * is unmounted, focus will return to the next element on the stack.
 */
export class FocusTrap extends React.Component<Props, State> {
  static displayName = 'FocusTrap';
  static defaultProps = {
    focusOnMount: true,
    tabIndex: 0
  };

  root: HTMLDivElement | null | undefined;

  constructor(props: Props) {
    super(props);

    this.state = {
      anchor: document?.activeElement ? (document.activeElement as HTMLElement) : null
    };
  }

  componentDidMount() {
    const { focusOnMount, disableFocus } = this.props;
    focusTrapStack.push(this);
    // https://jira.cvent.com/browse/PROD-168703
    // window.self === window.top This check is to identify if the component is rendered within a
    // iframe as embedded widget.
    if (focusOnMount && !disableFocus && window.self === window.top) {
      this.trapFocus();
    }

    // Only attach the listener when disableFocus is not true
    if (!this.props.disableFocus) {
      document?.addEventListener('focus', this.onBlur, true);
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.disableFocus !== this.props.disableFocus) {
      if (this.props.disableFocus) {
        document?.removeEventListener('focus', this.onBlur, true);
      } else {
        document?.addEventListener('focus', this.onBlur, true);
      }
    }
  }

  componentWillUnmount() {
    focusTrapStack = focusTrapStack.filter((element: FocusTrap) => element !== this);
    if (!this.props.disableFocus) {
      document?.removeEventListener('focus', this.onBlur, true);
    }
    this.returnFocus();
  }

  onBlur = (event: FocusEvent) => {
    const current = focusTrapStack[focusTrapStack.length - 1];
    if (current && current.contains(event.target) === false) {
      event.preventDefault();
      this.trapFocus();
    }
  };

  returnFocus = () => {
    const { anchor } = this.state;
    anchor?.focus();
  };

  trapFocus = () => {
    const element = focusTrapStack[focusTrapStack.length - 1];
    element?.focus();
  };

  contains = (element: any) => this.root?.contains(element);

  focus = () => this.root?.focus();

  render() {
    let props = this.props.disableTabIndex ? removeKeys(this.props, ['tabIndex']) : this.props;
    props = removeKeys(props, ['focusOnMount', 'classes', 'disableTabIndex']);

    return (
      <div
        {...props}
        {...resolveTestId(this.props)}
        style={{ outline: 'none' }}
        ref={c => (this.root = c)}
        id="focusTrap"
      />
    );
  }
}
