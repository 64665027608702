import React from 'react';

import { select } from '@cvent/nucleus-dynamic-css';

import { Trigger, TriggerProps } from '../containers/Trigger';
import { FlyoutMessage, FlyoutMessagePlacementProps } from './FlyoutMessage';

export type InfoFlyoutProps = React.PropsWithChildren<
  FlyoutMessagePlacementProps &
    TriggerProps & {
      useEventPosition?: boolean;
      classes?: any;
      style?: {
        trigger?: any;
        flyout?: any;
      };
    }
>;

/**
Display info flyout on hover of Trigger Element
**/
export class InfoFlyout extends React.Component<InfoFlyoutProps> {
  static displayName = 'InfoFlyout';
  static defaultProps = {
    flyoutDirection: 'horizontal',
    forceDirection: {
      vert: null,
      horz: null
    },
    useEventPosition: false
  };
  constructor(props: InfoFlyoutProps) {
    super(props);
  }

  render() {
    return (
      <Trigger
        {...this.props}
        {...select(this.props, 'trigger')}
        useEventPosition={this.props.useEventPosition}
      >
        {/* @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'. */}
        {this.props.children[0]}
        <FlyoutMessage
          flyoutDirection={this.props.flyoutDirection}
          forceDirection={this.props.forceDirection}
          {...select(this.props, 'flyout')}
        >
          {/* @ts-expect-error ts-migrate(2533) FIXME: Object is possibly 'null' or 'undefined'. */}
          {this.props.children[1]}
        </FlyoutMessage>
      </Trigger>
    );
  }
}
