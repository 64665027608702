import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import Icon from '@cvent/nucleus-icon';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import { InteractiveElement, Trigger } from 'nucleus-core';

import { HAMBURGER, TOPLEVEL, SUBLEVEL } from './Constants';

type OwnProps = {
  id?: string;
  selectedPage?: {
    id?: string;
    name?: string;
  };
  pageData?: any[];
  navigateToPage?: (...args: any[]) => any;
  pageLinksArrangement?: any; // TODO: PropTypes.oneOf([HORIZONTAL, HAMBURGER])
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  dimensions?: any;
  // Used in resolve function TODO: Determine required fields
  classes?: Record<string, unknown>;
  // Used in resolve function TODO: Determine required fields
  style?: Record<string, unknown>;
};

type Props = OwnProps & typeof WebsiteNavigator.defaultProps;

/**
 * Normal view for the website navigator.
 */
export class WebsiteNavigator extends React.Component<Props> {
  static displayName = 'WebsiteNavigator';
  static defaultProps = {
    dimensions: {
      maxWidth: 1024,
      contentWidth: 800
    }
  };
  subTriggers: any;
  trigger: any;
  constructor(props: Props) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.subTriggers = {};

    this.renderTrigger = this.renderTrigger.bind(this);
    this.renderMenuItem = this.renderMenuItem.bind(this);
    this.renderSubMenuOrItem = this.renderSubMenuOrItem.bind(this);
    this.renderNavigationMenu = this.renderNavigationMenu.bind(this);
  }
  onClick(id: any) {
    return () => {
      if (this.trigger) {
        this.trigger.handleHide();
      }
      Object.keys(this.subTriggers).forEach(
        subTriggerKey =>
          this.subTriggers[subTriggerKey] && this.subTriggers[subTriggerKey].handleHide()
      );
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.navigateToPage(id);
    };
  }
  // Make trigger for dropdown or hamburger menu
  // Level switches the menu from a top level hamburger = 1 or other level, if not set defaults to 0..
  renderTrigger(
    menu: any,
    level: any,
    name: any,
    showAsSelectedGroup: any,
    id: any,
    isRightMostItem: any
  ) {
    const { pageLinksArrangement, selectedPage, classes, style, dimensions } = this.props;

    const isEntireScreenAndFullWidth = !dimensions.maxWidth && !dimensions.contentWidth;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const textAlign = this.props.style.navigation.textAlign;
    let dropDownShouldRightAlign = false;
    if (isEntireScreenAndFullWidth) {
      if (pageLinksArrangement === 'HAMBURGER') {
        dropDownShouldRightAlign = textAlign === 'right';
      } else {
        dropDownShouldRightAlign = textAlign === 'right' ? isRightMostItem : false;
      }
    }
    const transDropdownStyles = resolve(
      this.props,
      'transDropDown',
      dropDownShouldRightAlign ? 'rightAlign' : 'leftAlign'
    );
    const containerClass = {
      ...classes,
      // eslint-disable-next-line
      transitionContainer:
        // eslint-disable-next-line no-nested-ternary
        level === TOPLEVEL
          ? transDropdownStyles.className
          : pageLinksArrangement === HAMBURGER
          ? classes?.transInline
          : transDropdownStyles.className
    };

    const showAsSelectedLink = level === TOPLEVEL || showAsSelectedGroup;
    const pageLinkStyles = resolve(this.props, 'pageLinks', showAsSelectedLink ? 'selected' : '');
    pageLinkStyles.style.textAlign = 'left';
    return (
      <Trigger
        classes={containerClass}
        style={style}
        allowMouse={false}
        isTriggerFocusable
        ref={(ref: any) => {
          /**
           * PROD-86548: need to separate subMenu item trigger and toplevel trigger
           * when there're 2 layers of dropdown to make it collapse correctly.
           */
          if (level === SUBLEVEL) {
            this.subTriggers[id] = ref;
          } else {
            this.trigger = ref;
          }
        }}
      >
        <div {...pageLinkStyles}>
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          <span className={classes.selectedPage}>{name || selectedPage?.name}</span>
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          <Icon icon={level === TOPLEVEL ? 'menuFilled' : 'expand'} modifier={classes.menuIcon} />
        </div>
        {menu}
      </Trigger>
    );
  }
  // Individual link item
  renderMenuItem(page: any, menuType: any) {
    const { selectedPage } = this.props;
    const pageLinks = resolve(
      this.props,
      'pageLinks',
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      page.id === selectedPage.id ? 'selected' : ''
    );
    pageLinks.style.textAlign = 'left';

    return (
      <li
        key={page.id}
        aria-current={selectedPage?.id === page.id ? 'true' : null}
        {...resolve(this.props, 'menuItem', menuType)}
      >
        <InteractiveElement {...pageLinks} onClick={this.onClick(page.id)} role="link">
          {page.name}
        </InteractiveElement>
      </li>
    );
  }
  // Created Trigger and Sub Group pages
  renderSubMenuOrItem(item: any, menuType: any, itemIndex: any) {
    if (!item.childPages) {
      return this.renderMenuItem(item, menuType);
    }
    // @ts-expect-error ts-migrate(2365) FIXME: Operator '>' cannot be applied to types 'boolean' ... Remove this comment to see the full error message
    if (!item.childPages.length > 0) {
      return null;
    }

    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const isRightMostItem = itemIndex === this.props.pageData.length - 1;
    const subMenu = (
      <nav {...resolve(this.props, 'subMenu', menuType)}>
        {item.childPages.map((childItem: any) => this.renderSubMenuOrItem(childItem, 'sub', null))}
      </nav>
    );
    const { selectedPage } = this.props;
    const selectedPageInGroup = item.childPages
      .map((childPage: any) => childPage.id)
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      .includes(selectedPage.id);
    return (
      <li
        key={item.id}
        aria-current={selectedPage?.id === item.id ? 'true' : null}
        {...resolve(this.props, 'menuItem', menuType)}
      >
        {this.renderTrigger(
          subMenu,
          SUBLEVEL,
          item.name,
          selectedPageInGroup,
          item.id,
          isRightMostItem
        )}
      </li>
    );
  }
  // Parent Navigation UL - Create sub menu or direct link...
  renderNavigationMenu() {
    const { pageData, pageLinksArrangement } = this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const textAlign = this.props.style.pageLinks.textAlign;
    const menuType = pageLinksArrangement === HAMBURGER ? 'vertical' : 'horizontal';
    return (
      <nav {...resolve(this.props, textAlign, 'menu', `${menuType}Menu`)}>
        <ul>{pageData?.map((item, index) => this.renderSubMenuOrItem(item, menuType, index))}</ul>
      </nav>
    );
  }
  render() {
    const { leftContent, rightContent, pageLinksArrangement, id } = this.props;
    // Create Parent Navigation Container - Hamburger for desktop check...
    let content;
    if (pageLinksArrangement === HAMBURGER) {
      content = (
        <div {...resolve(this.props, 'hamburgerWrapper')}>
          {this.renderTrigger(this.renderNavigationMenu(), TOPLEVEL, null, null, id, null)}
        </div>
      );
    } else {
      content = this.renderNavigationMenu();
    }
    return (
      <div {...resolveTestId(this.props)} {...resolve(this.props, 'container')}>
        {leftContent && <div {...resolve(this.props, 'leftContent')}>{leftContent}</div>}
        <div {...resolve(this.props, 'navigation')}>{content}</div>
        {rightContent && <div {...resolve(this.props, 'rightContent')}>{rightContent}</div>}
      </div>
    );
  }
}
