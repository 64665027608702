/**
 * This is site-editor specific.
 * These are the constants for the tabs that opens different editor panels.
 */
export const EditorTabTypes = {
  BUILD: 'EditorTabTypes.BUILD',
  STYLE: 'EditorTabTypes.STYLE',
  ARRANGE: 'EditorTabTypes.ARRANGE',
  REVIEW: 'EditorTabTypes.REVIEW',
  CUSTOM: 'EditorTabTypes.CUSTOM',
  SECTION: 'EditorTabTypes.SECTION'
};
