export function throttle(this: any, func: any, wait: any) {
  let context: any;
  let args: any;
  let result: any;
  let timeout: any = null;
  let previous = 0;

  const later = () => {
    previous = new Date().getTime();
    timeout = null;
    result = func.apply(context, args);
    if (!timeout) context = args = null;
  };
  return () => {
    const now = new Date().getTime();
    const remaining = wait - (now - previous);
    context = this;
    // eslint-disable-next-line prefer-rest-params
    args = arguments;
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
      previous = now;
      result = func.apply(context, args);
      if (!timeout) context = args = null;
    } else if (!timeout) {
      timeout = setTimeout(later, remaining);
    }
    return result;
  };
}

// This is to handle accessing event properties in an asynchronous way
// https://facebook.github.io/react/docs/events.html#syntheticevent
export function debounceEventHandler(...args: any[]) {
  // @ts-expect-error ts-migrate(2556) FIXME: Expected 2 arguments, but got 0 or more.
  const throttled = throttle(...args);
  return (event: any) => {
    if (event) {
      event.persist();
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
      return throttled(event);
    }
    return throttled();
  };
}
