import { getTagsConvertedToTagObject } from 'cvent-event-calendar/utils/selectors';
import { textReducer } from 'nucleus-guestside-site';
import { combineReducers } from 'redux';
import { createSelector } from 'reselect';

import calendarReducer from './calendar';
import calendarFiltersReducer from './calendarFilters';
import embeddedSettingsReducer from './embeddedSettings';
import eventDetailsReducer from './eventDetails';
import searchCriteriaReducer from './searchCriteria';
import websiteReducer, * as fromWebsite from './website';
import { LOAD_GOOGLE_MAP_API_KEY } from '../actionTypes';
import { Action } from './types';

function googleMapsApiKeyReducer(state = '', action: Action) {
  switch (action.type) {
    case LOAD_GOOGLE_MAP_API_KEY: {
      // @ts-expect-error TS(2339): Property 'googleMapsApiKey' does not exist on type... Remove this comment to see the full error message
      const { googleMapsApiKey } = action.payload;
      return googleMapsApiKey;
    }
    default:
      return state;
  }
}

/* Combine all reducers into the root reducer. The name of each reducer becomes the key name of
 * that sub object.
 */
const reducer = combineReducers({
  text: textReducer,
  calendar: calendarReducer,
  searchCriteria: searchCriteriaReducer,
  calendarFilters: calendarFiltersReducer,
  eventDetails: eventDetailsReducer,
  website: websiteReducer,
  accessToken: (state = {}) => state,
  googleMapsApiKey: googleMapsApiKeyReducer,
  widgetFactory: (state = {}) => state,
  isCustomFooterEnabled: (state = {}) => state,
  embeddedSettings: embeddedSettingsReducer
});

export default reducer;

export function getDefaultWebsitePageId(state: $TSFixMe) {
  return state.website && fromWebsite.getDefaultWebsitePageId(state.website);
}

/**
 * Returns tags of an event whose details are being loaded
 */
export const getTags = createSelector(
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  state => state.calendar.config.tagsConfiguration || null,
  state => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const eventId = state.eventDetails.eventLoading;
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const eventDetails = state.eventDetails[eventId];
    if (eventDetails) {
      return eventDetails.tagsField;
    }
    return null;
  },
  state => {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const eventId = state.eventDetails.eventLoading;
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const eventDetails = state.eventDetails[eventId];
    if (eventDetails && eventDetails.calendarItemTagColors) {
      // keep the sequence same as returned by ui-service
      return {
        overriddenBackgroundColor: eventDetails.calendarItemTagColors.fieldValue[0],
        overriddenTextColor: eventDetails.calendarItemTagColors.fieldValue[1]
      };
    }
    return {};
  },
  // @ts-expect-error TS(2571): Object is of type 'unknown'.
  state => state.text.translate,
  (tagsConfiguration, tagsField, calendarItemTagColors, translate) => {
    if (!tagsField) {
      return [];
    }
    const { overriddenTextColor, overriddenBackgroundColor } = calendarItemTagColors;
    const tags = tagsField.map((tag: $TSFixMe) => {
      return {
        id: tag.fieldId,
        values: tag.fieldValue,
        overriddenTextColor,
        overriddenBackgroundColor
      };
    });
    return getTagsConvertedToTagObject(tags, tagsConfiguration, translate);
  }
);
