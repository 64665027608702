import React from 'react';

import omit from 'lodash/omit';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import CircleClasses from 'nucleus-core/less/cv/Countdown.Circle.structure.less';
import FlipClasses from 'nucleus-core/less/cv/Countdown.Flip.structure.less';

import { BaseWidget } from '../BaseWidget';
import { CountdownTimer } from './CountdownTimer';
import { ThemeableComponentProps } from '../ThemeableComponent';

interface CountdownWidgetProps extends ThemeableComponentProps {
  // TODO: determine neccesary fields for config
  config: Record<string, unknown>;
}

const CountdownCircleStyle = {
  container: CircleClasses.container,
  countdown: CircleClasses.countdown,
  circle: CircleClasses
};
const CountdownFlipStyle = {
  container: FlipClasses.container,
  countdown: FlipClasses.countdown,
  flip: FlipClasses
};

/**
 * Data wrapper for the CountdownTimer widget.
 */
export class CountdownTimerWidget extends BaseWidget<CountdownWidgetProps> {
  static displayName = 'CountdownTimerWidget';
  getStyleObject() {
    const counterBackground = this.getElementBackground('counter');
    if (this.props.config.type === 'flip') {
      counterBackground.borderRadius = 5;
    }
    // Removing hover styles from the `counter` element.
    // Because of the way the countdown component was built, hover styles
    // would only be applied to part of the circles on hover.
    return {
      ...super.getStyleObject(),
      spacing: this.getElementSpacing('counter'),
      background: omit(counterBackground, 'hover'),
      digit: omit(this.getElementText('counter'), 'hover'),
      timer: omit(this.getElementInlineStyle('counter'), 'hover'),
      label: this.getElementInlineStyle('text')
    };
  }
  render() {
    const { config, translate } = this.props;
    const inlineStyles = this.getStyleObject();
    const mergedStyles = {
      countdown: inlineStyles.container,
      circle: {
        counter: inlineStyles.spacing,
        circle: inlineStyles.background,
        digit: inlineStyles.digit,
        label: inlineStyles.label,
        border: inlineStyles.timer
      },
      flip: {
        counter: inlineStyles.spacing,
        top: inlineStyles.background,
        bottom: inlineStyles.background,
        flip: inlineStyles.background,
        digit: inlineStyles.digit,
        digitTop: inlineStyles.digit,
        label: inlineStyles.label
      }
    };
    const classes = config.type === 'circle' ? CountdownCircleStyle : CountdownFlipStyle;
    const endDate = new Date(config.date as string);
    return (
      <CountdownTimer
        {...resolveTestId(this.props)}
        classes={classes}
        endDate={endDate}
        style={mergedStyles}
        type={config.type}
        daysLabel={translate('_dateLabels_days__resx')}
        showDays={config.showDays}
        hoursLabel={translate('_dateLabels_hours__resx')}
        showHours={config.showHours}
        minutesLabel={translate('_dateLabels_minutes__resx')}
        showMinutes={config.showMinutes}
        secondsLabel={translate('_dateLabels_seconds__resx')}
        showSeconds={config.showSeconds}
      />
    );
  }
}
