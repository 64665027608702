import merge from 'lodash/merge';

import { getFontWeightAndStyle, getFontStack } from '../../widgets/SharedFields/Fonts//utils';
import { getEms } from './getEms';
import { getStyleObjectWithPseudoState } from './getStyleObjectWithPseudoState';
import { convertThemePaletteColor } from '../fields/convertThemePaletteColor';

function getTextColor({ styleObject, customStyleSettings, colorPalette }: any) {
  const mergedStyleObject = merge({}, styleObject, customStyleSettings);
  if (!mergedStyleObject || !mergedStyleObject.text) {
    return {};
  }
  const { color } = mergedStyleObject.text;
  return { color: convertThemePaletteColor(colorPalette, color) };
}

export function getText(
  styleObject = {},
  customStyleSettings = {},
  colorPalette: any,
  fontPalette: any,
  customFonts: any
) {
  const mergedStyleObject = merge({}, styleObject, customStyleSettings);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'text' does not exist on type '{}'.
  if (!mergedStyleObject || !mergedStyleObject.text) {
    return {};
  }
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'text' does not exist on type '{}'.
  const { fontStyle, fontWeight } = mergedStyleObject.text;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'text' does not exist on type '{}'.
  let { fontSize, color, fontFamily } = mergedStyleObject.text;

  // Convert font sizes that are larger than 18px to rem, to scale them down on smaller browser viewports,
  // where global base font size can be reduced by 25%.
  // 18px and smaller stay as is, to keep text legible on smaller browser viewports.
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
  fontSize = fontSize <= 18 ? fontSize : `${getEms(fontSize)}rem`;

  if (fontPalette && fontPalette[fontFamily]) {
    fontFamily = fontPalette[fontFamily];
  }

  color = convertThemePaletteColor(colorPalette, color);

  return {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'text' does not exist on type '{}'.
    ...mergedStyleObject.text,
    fontSize,
    fontFamily: getFontStack(fontFamily, customFonts),
    ...getFontWeightAndStyle({
      fontFamily,
      fontWeight,
      fontStyle,
      customFonts
    }),
    color,
    hover: getTextColor({
      ...getStyleObjectWithPseudoState(styleObject, customStyleSettings, 'hover'),
      colorPalette
    })
  };
}
