export const STYLE_DEFAULTS = {
  background: {
    image: {
      // The image scale setting should always be true if it is undefined,
      // so inserting here when cleaning parent style objects will always
      // insert it into the editor if needed.
      scale: true
    },
    color: null,
    borderRadius: 0
  },
  border: {
    color: '#FFFFFF',
    thickness: 0,
    borderStyle: 'solid'
  },
  spacing: {
    padding: {
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0
    }
  }
};

/**
 * Resets style settings of a style object to default.
 * Currently resets background, border, and spacing settings.
 */
export function resetStylesToDefault(styleObject = {}, defaults = {}) {
  return {
    ...styleObject,
    ...STYLE_DEFAULTS,
    palette: {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type '{}'.
      ...defaults.palette,
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'palette' does not exist on type '{}'.
      ...styleObject.palette
    },
    background: {
      ...STYLE_DEFAULTS.background,
      // Preserve the parent background settings in a hidden field
      // so that underlying widgets can access it in special cases.
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'background' does not exist on type '{}'.
      parentBackground: styleObject && styleObject.background
    },
    customCSSStyles: undefined
  };
}
