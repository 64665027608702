import React from 'react';

import { select } from '@cvent/nucleus-dynamic-css';

import { Spinner } from '../progress/Spinner';
import { BreakPoint, Button } from './Button';

type Props = React.PropsWithChildren<{
  /** The text and title of the SpinnerButton component */
  title?: string;
  /** Applies styles for the button kind. */
  kind?: string;
  /** Determine whether to show spinner or not. */
  isProcessing?: boolean;
  /** Sets the window-width at which Button should transition from a stacked-title layout, to a title-on-the-side layout. */
  breakPoint?: BreakPoint;
  /** Determines if the Button is disabled */
  disabled?: boolean;
  style?: {
    button?: any;
    spinner?: any;
  };
  classes?: any;
}>;

/**
SpinnerButton is a stateless function component that will render
a progress spinner inside of a button component
depending on isProcessing prop being true or false passed by props.
**/
const SpinnerButton = React.forwardRef<any, Props>((props, ref) => {
  const { isProcessing, disabled, title, children, ...rest } = props;
  const spinner = <Spinner numberOfCircles={2} {...select(props, 'spinner')} />;
  return (
    <Button
      forwardedRef={ref}
      {...rest}
      title={title}
      disabled={isProcessing || disabled}
      {...select(props, 'button')}
    >
      {isProcessing ? spinner : null} {children || title}
    </Button>
  );
});
SpinnerButton.displayName = 'SpinnerButton';
export { SpinnerButton };
