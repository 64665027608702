import uniqueId from 'lodash/uniqueId';
import { RESOLVE_COMMENT, COMMENT_RESOLVE_ERROR } from './actions';
import {
  COMMENT_ERRORS,
  getCommentError,
  removeCommentError,
  setCommentErrorLoading
} from './errors';

function setResolveComment(widgetId: string, commentId: string, isResolved: boolean) {
  return async (dispatch: any, getState: any) => {
    const client = getState().clients.commentsClient;

    try {
      await client.resolve(commentId, isResolved);

      dispatch({ type: RESOLVE_COMMENT, payload: { widgetId, commentId, isResolved } });
      return true;
    } catch (err) {
      return false;
    }
  };
}

function setResolveCommentError(
  widgetId: string,
  commentId: string,
  isResolved: boolean,
  isAlert: any = true
) {
  const type = COMMENT_ERRORS.Resolve;
  const data = { widgetId, commentId, isResolved };

  return {
    type: COMMENT_RESOLVE_ERROR,
    payload: { id: uniqueId(), retrying: false, isAlert, type, data }
  };
}

export function resolveComment(widgetId: string, commentId: string, isResolved: boolean) {
  return async (dispatch: any, getState: any) => {
    // TODO: Fix for initial undefined state
    const state = getState();
    const comments = state.comments;

    const error = getCommentError(comments.errors, COMMENT_ERRORS.Resolve, {
      widgetId,
      commentId,
      isResolved
    });

    if (error) {
      dispatch(removeCommentError(error.id));
    }

    const success = await dispatch(setResolveComment(widgetId, commentId, isResolved));

    if (!success) {
      dispatch(setResolveCommentError(widgetId, commentId, isResolved));
    }
  };
}

export function retryResolveComment(widgetId: string, commentId: string, isResolved: boolean) {
  // For code readability, set 'old' value to opposite of target value in isResolved
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const comments = state.comments;

    const error = getCommentError(comments.errors, COMMENT_ERRORS.Resolve, {
      widgetId,
      commentId,
      isResolved
    });

    dispatch(setCommentErrorLoading(error.id, true));

    const success = await dispatch(setResolveComment(widgetId, commentId, isResolved));

    if (success) {
      dispatch(removeCommentError(error.id));
    } else {
      dispatch(setCommentErrorLoading(error.id, false));
    }
  };
}
