import React from 'react';

import { select } from '@cvent/nucleus-dynamic-css';
import Icon from '@cvent/nucleus-icon';
import { resolveTestId } from '@cvent/nucleus-test-automation';

import { BreakPoint, Button } from './Button';

export type IconButtonPosition = 'left' | 'right';

type Props = React.PropsWithChildren<{
  /** The text and title of the IconButton component. */
  title?: string;
  /** Icon's position. Left or right. Default: right. */
  position?: IconButtonPosition;
  /** Applies styles for the button kind. */
  kind?: string;
  /** Determine whether to display only icon or not. */
  iconOnly?: boolean;
  /** Type of the Nucleus Icon to display. */
  icon?: string;
  /** Nucleus Icon's fallback text. */
  fallbackText?: string;
  /** Sets the window-width at which Button should transition from a stacked-title layout, to a title-on-the-side layout. */
  breakPoint?: BreakPoint;
  /** Nucleus Icon's modifier. */
  modifier?: string;
  style?: {
    button?: any;
    icon?: any;
  };
  classes?: any;
}>;

/**
  IconButton - Composes the Button and Icon components to create a button
  with an icon inside of it.
 **/

const IconButton = (props: Props) => {
  const {
    icon = '',
    fallbackText,
    modifier,
    title,
    children,
    iconOnly = false,
    position = 'right',
    ...rest
  } = props;
  const IconObject = (
    <Icon
      icon={icon}
      fallbackText={fallbackText}
      modifier={modifier}
      {...resolveTestId(props, '-icon')}
    />
  );
  return (
    <Button {...rest} {...select(props, 'button')} title={title}>
      {position === 'left' ? IconObject : null}
      {!iconOnly && !children ? <span>{title}</span> : children}
      {position === 'right' ? IconObject : null}
    </Button>
  );
};

IconButton.displayName = 'IconButton';

export { IconButton };
