import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

import { FormElementMode } from './PropTypes';

type Props = {
  mode?: FormElementMode;
  fieldId?: string;
  label?: React.ReactNode;
  labelId?: string;
  additionalLabelText?: React.ReactNode;
  hideLabel?: boolean;
  showAdditionalLabelTextInViewMode?: boolean;
  required?: boolean;
  style?: {
    label?: any;
    labelText?: any;
    additionalLabelText?: any;
    hidden?: any;
    required?: any;
  };
  element?: string;
  classes?: any;
};

/**
Label used for a Form Element Input that support a "view" and "edit" mode.
**/
const FormLabel = (props: Props) => {
  const {
    mode = 'edit',
    required = false,
    label,
    labelId,
    additionalLabelText,
    fieldId,
    hideLabel = false,
    showAdditionalLabelTextInViewMode = false,
    element: Element = 'label'
  } = props;

  if (mode === 'view') {
    return (
      <span {...resolve(props, 'label', hideLabel ? 'hidden' : null)}>
        {label}
        {additionalLabelText && showAdditionalLabelTextInViewMode && (
          <span {...resolve(props, 'additionalLabelText')}>{additionalLabelText}</span>
        )}
      </span>
    );
  }

  return (
    <Element
      {...resolve(props, 'label', hideLabel ? 'hidden' : null)}
      htmlFor={fieldId}
      id={labelId}
    >
      <span {...resolve(props, 'labelText')}>
        {required && !hideLabel && <span {...resolve(props, 'required')}>&#42;&nbsp;</span>}
        <span {...resolveTestId(props)}>{label}</span>
      </span>
      {additionalLabelText && (
        <span {...resolve(props, 'additionalLabelText')}>{additionalLabelText}</span>
      )}
    </Element>
  );
};

FormLabel.displayName = 'FormLabel';
export { FormLabel };
