import React, { Component } from 'react';

import { createReference } from './videoUtils';

type Props = {
  videoPath?: string;
  recordVideoViewActivity?: ((...args: any[]) => any) | undefined;
};

/**
 * The basic UI implementation for the Video widget when it contains a Facebook video
 */
export class EmbeddedFacebook extends Component<Props> {
  divRef: any;
  window: any;
  wrapper: any;

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    // To make the widget appear on Preview Mode since, this is inside an Iframe
    createReference.call(this);

    if (typeof this.window.FB === 'undefined') {
      this.appendEmbedScript();
      this.registerVideoPlayer();
    } else {
      // Re-loading the plugin when the widget gets re-mounted with existing videoPath for it
      this.registerVideoPlayer();
      this.window.FB.XFBML.parse();
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.videoPath !== this.props.videoPath) {
      // Re-loading the plugin when videoPath is updated by the user
      if (typeof this.window.FB !== 'undefined') {
        this.window.FB.XFBML.parse();
      }
    }
  }

  appendEmbedScript() {
    if (!document.querySelector('#fb-js')) {
      // Adding the script to the page to embed Facebook plugin when this widget gets mounted on the DOM
      const facebookScript = this.window.document.createElement('script');
      facebookScript.src = '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
      facebookScript.async = true;
      facebookScript.id = 'fb-js';
      this.window.document.body.appendChild(facebookScript);
    }
  }

  registerVideoPlayer() {
    const { videoPath } = this.props;
    this.window.fbPlayerScripts = this.window.fbPlayerScripts || [];
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    if (!this.window.fbPlayers || !this.window.fbPlayers[videoPath]) {
      this.window.fbPlayerScripts.push((fb: any) => {
        fb.Event.subscribe('xfbml.ready', (msg: any) => {
          if (msg.type === 'video' && msg.id === `facebook_player_${videoPath}`) {
            this.bindPlayerActions(msg.instance);
          }
        });
      });
    }
    if (this.window.FB) {
      this.window.fbPlayerScripts.forEach((script: any) => script(this.window.FB));
      this.window.fbPlayerScripts = [];
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'fbAsyncInit' does not exist on type 'Win... Remove this comment to see the full error message
      window.fbAsyncInit = () => {
        this.window.fbPlayerScripts.forEach((script: any) => script(this.window.FB));
        this.window.fbPlayerScripts = [];
      };
    }
  }

  bindPlayerActions(player: any) {
    const { videoPath, recordVideoViewActivity } = this.props;
    this.window.fbPlayers = this.window.fbPlayers || {};
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    this.window.fbPlayers[videoPath] = player;
    const startedPlayingAction = player.subscribe('startedPlaying', () => {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      const videoId = videoPath.split('videos/')[1].split('/')[0];
      if (recordVideoViewActivity) {
        recordVideoViewActivity(`Facebook Video ${videoId}`);
      }
      startedPlayingAction.release('startedPlaying');
    });
  }

  /**
   * Returns the code which will help embed the plugin on the page.
   * @param {*} videoPath Path to the video which needs to be embedded
   */
  getFacebookEmbedVideo(videoPath: any) {
    const dataHref =
      videoPath.includes('watch/') && !videoPath.includes('watch/?v=')
        ? `https://fb.${videoPath}`
        : `https://www.facebook.com/${videoPath}`;
    // This div will be replaced by the content from the Facebook script.
    return {
      __html: `
        <div
          id="facebook_player_${videoPath}"
          class="fb-video"
          data-href="${dataHref}"
          data-autoplay="false"
          data-allowfullscreen="true"
          data-show-captions="true"
        ></div>`
    };
  }

  render() {
    const { videoPath } = this.props;

    return (
      <div ref={this.wrapper}>
        <div
          ref={element => (this.divRef = element)}
          className="facebookContainer"
          dangerouslySetInnerHTML={this.getFacebookEmbedVideo(videoPath)}
        />
      </div>
    );
  }
}
