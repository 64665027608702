import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

type Props = {
  // Used in resolve function // TODO: determine neccesary fields for classes
  classes?: Record<string, unknown>;
  // Used in resolve function
  style?: {
    divider?: {
      borderColor?: string;
      borderWidth?: string;
      borderStyle?: 'none' | 'dotted' | 'dashed' | 'solid' | 'groove' | 'inset';
    };
  };
};

/**
 * The Divider widget.
 */
export class Divider extends React.Component<Props> {
  static displayName = 'Divider';
  render() {
    const { style } = this.props;
    return (
      <div {...resolveTestId(this.props)} {...resolve(this.props, 'container')}>
        <div
          style={{
            borderTopColor: style?.divider?.borderColor,
            borderTopWidth: style?.divider?.borderWidth,
            borderTopStyle: style?.divider?.borderStyle
          }}
        />
      </div>
    );
  }
}
