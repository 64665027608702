import {
  backgroundImageUrl,
  backgroundImageScale,
  backgroundImageRepeatX,
  backgroundImageRepeatY,
  backgroundImagePositionXOffset,
  backgroundImagePositionYOffset,
  backgroundColor,
  backgroundColorAlpha,
  backgroundBorderRadius,
  hideBackgroundImageSetting
} from './background';
import { borderColor, borderThickness, borderBorderStyle } from './border';
import {
  spacingPaddingPaddingTop,
  spacingPaddingPaddingBottom,
  spacingPaddingPaddingLeft,
  spacingPaddingPaddingRight
} from './spacing';
import { customCSSStyling } from './customCSS';

function rootHideBackgroundImageSetting(state: any, valueObject: any) {
  return hideBackgroundImageSetting(state, valueObject.style);
}

export const styleBackgroundBorderRadius = {
  ...backgroundBorderRadius,
  fieldName: 'style.background.borderRadius'
};

export const styleBackgroundColor = {
  ...backgroundColor,
  fieldName: 'style.background.color'
};

export const styleBackgroundColorAlpha = {
  ...backgroundColorAlpha,
  fieldName: 'style.background.colorAlpha'
};

export const styleBackgroundImageScale = {
  ...backgroundImageScale,
  fieldName: 'style.background.image.scale',
  hideField: rootHideBackgroundImageSetting
};

export const styleBackgroundImageRepeatX = {
  ...backgroundImageRepeatX,
  fieldName: 'style.background.image.repeat.x',
  hideField: rootHideBackgroundImageSetting
};

export const styleBackgroundImageRepeatY = {
  ...backgroundImageRepeatY,
  fieldName: 'style.background.image.repeat.y',
  hideField: rootHideBackgroundImageSetting
};

export const styleBackgroundImagePositionXOffset = {
  ...backgroundImagePositionXOffset,
  fieldName: 'style.background.image.position.xOffset',
  hideField: rootHideBackgroundImageSetting
};

export const styleBackgroundImagePositionYOffset = {
  ...backgroundImagePositionYOffset,
  fieldName: 'style.background.image.position.yOffset',
  hideField: rootHideBackgroundImageSetting
};

export const styleBackgroundImageUrl = {
  ...backgroundImageUrl,
  fieldName: 'style.background.image.url'
};

export const styleBorderBorderStyle = {
  ...borderBorderStyle,
  fieldName: 'style.border.borderStyle'
};

export const styleBorderColor = {
  ...borderColor,
  fieldName: 'style.border.color'
};

export const styleBorderThickness = {
  ...borderThickness,
  fieldName: 'style.border.thickness'
};

export const styleCustomCSSStyles = {
  ...customCSSStyling,
  fieldName: 'style.customCSSStyles'
};

export const styleSpacingPaddingPaddingBottom = {
  ...spacingPaddingPaddingBottom,
  fieldName: 'style.spacing.padding.paddingBottom'
};

export const styleSpacingPaddingPaddingLeft = {
  ...spacingPaddingPaddingLeft,
  fieldName: 'style.spacing.padding.paddingLeft'
};

export const styleSpacingPaddingPaddingRight = {
  ...spacingPaddingPaddingRight,
  fieldName: 'style.spacing.padding.paddingRight'
};

export const styleSpacingPaddingPaddingTop = {
  ...spacingPaddingPaddingTop,
  fieldName: 'style.spacing.padding.paddingTop'
};

export default {
  'style.background.borderRadius': styleBackgroundBorderRadius,
  'style.background.color': styleBackgroundColor,
  'style.background.colorAlpha': styleBackgroundColorAlpha,
  'style.background.image.position.yOffset': styleBackgroundImagePositionYOffset,
  'style.background.image.position.xOffset': styleBackgroundImagePositionXOffset,
  'style.background.image.repeat.x': styleBackgroundImageRepeatX,
  'style.background.image.repeat.y': styleBackgroundImageRepeatY,
  'style.background.image.scale': styleBackgroundImageScale,
  'style.background.image.url': styleBackgroundImageUrl,
  'style.border.borderStyle': styleBorderBorderStyle,
  'style.border.color': styleBorderColor,
  'style.border.thickness': styleBorderThickness,
  'style.customCSSStyles': styleCustomCSSStyles,
  'style.spacing.padding.paddingBottom': styleSpacingPaddingPaddingBottom,
  'style.spacing.padding.paddingLeft': styleSpacingPaddingPaddingLeft,
  'style.spacing.padding.paddingTop': styleSpacingPaddingPaddingTop,
  'style.spacing.padding.paddingRight': styleSpacingPaddingPaddingRight
};
