import chroma from 'chroma-js';

/**
 * Represents a variant of a color that whose brightness has been darkened/lightened.
 */
export interface ColorVariant {
  // The color of this variant.
  color: string | undefined;
  // True if this variant was the initial base color provided.
  isDefault?: boolean;
  // A number that represents how much the base color was darkened/lightened by. Positive values indicate
  // the base color was darkened. Negative values indicate the base color was lightened.
  modifier: number;
}

/**
 * Represents an ordered list of color variants.
 */
export interface ColorVariantScale {
  colorVariants: ColorVariant[];
  /**
   * If the `modifier` is present in the `colorVariants`, the related variant will be returned. Otherwise, the
   * variant closest to `modifier` will be returned.
   *
   * Example:
   * If the `colorVariant` modifier values are [-1.0, -0.5, 0, 1.0], and the given `modifier` is `1.5`, then
   * the returned variant will be that which `modifier == 1.0` because it's the closest value available
   * within `colorVariants`.
   *
   * @param modifier the `ColorVariant#modifer` value to be found.
   */
  findByModifier: (modifier: number) => ColorVariant | undefined;
}

// The incremental modifier value to be used to darken/lighten a color variant.
const BRIGHTNESS_MODIFIER_INCREMENT = 0.5;
// The default length of variations to be generated.
const DEFAULT_BRIGHTNESS_VARIANTS_LENGTH = 7;
// The minimum contrast required between two neighboring colors to be considered a valid color.
const NEIGHBORING_CONTRAST_RATIO_REQUIREMENT = 1.25;

/**
 * Checks if two colors have enough of a difference in contrast to be considered a valid color variant. This is
 * important in cases where a dark color has been provided, and darkening the color doesn't provide any meaningful
 * color difference.
 */
function meetsContrastRequirement(
  colorA: string | chroma.Color,
  colorB: string | chroma.Color
): boolean {
  return chroma.contrast(colorA, colorB) > NEIGHBORING_CONTRAST_RATIO_REQUIREMENT;
}

/**
 * @see ColorVariantScale#findByModifier
 */
function findColorVariantByModifier(
  colorVariants: ColorVariant[],
  modifierValue: number
): ColorVariant | undefined {
  const foundVariant = colorVariants.find(({ modifier }) => modifier === modifierValue);
  if (foundVariant) {
    return foundVariant;
  }

  const adjustedModifier = colorVariants
    .map(({ modifier }) => modifier)
    .reduce((prev, curr) =>
      Math.abs(curr - modifierValue) < Math.abs(prev - modifierValue) ? curr : prev
    );
  return colorVariants.find(({ modifier }) => modifier === adjustedModifier);
}

/**
 * Creates an ordered list of colors, varying in brightness for the given `color`. The list of colors is adjusted,
 * such that each color has an appropriate contrast difference between its neighbor (i.e., if a lighter color
 * is provided, the list returned will contain mostly darker color variants and vice versa).
 *
 * @param color the color to create variants for
 * @param length the number of variants to be returned
 * @return an ordered list of color variants, from lightest to darkest
 */
export function createBrightnessVariants(
  color?: string,
  length = DEFAULT_BRIGHTNESS_VARIANTS_LENGTH
): ColorVariantScale {
  const colorVariants: ColorVariant[] = [{ color, modifier: 0, isDefault: true }];
  if (!color || !chroma.valid(color) || length <= 1) {
    return {
      colorVariants,
      findByModifier: () => colorVariants[0]
    };
  }

  const chromaColor = chroma(color);
  let currentModifier = BRIGHTNESS_MODIFIER_INCREMENT;

  while (colorVariants.length < length) {
    const lightVariant = chromaColor.darken(-currentModifier);
    const firstVariantColor = colorVariants[0]?.color;
    if (firstVariantColor && meetsContrastRequirement(lightVariant, firstVariantColor)) {
      colorVariants.unshift({
        color: lightVariant.hex(),
        modifier: -currentModifier
      });
    }

    const darkVariant = chromaColor.darken(currentModifier);
    const lastVariantColor = colorVariants[colorVariants.length - 1]?.color;
    if (
      lastVariantColor &&
      meetsContrastRequirement(darkVariant, lastVariantColor) &&
      // The length might already be reached if a lightVariant was just added above.
      colorVariants.length !== length
    ) {
      colorVariants.push({
        color: darkVariant.hex(),
        modifier: currentModifier
      });
    }

    currentModifier += BRIGHTNESS_MODIFIER_INCREMENT;
  }

  return {
    colorVariants,
    findByModifier: modifier => findColorVariantByModifier(colorVariants, modifier)
  };
}
