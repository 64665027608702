import React from 'react';

import { defaultMemoize } from 'reselect';

import {
  getElementBackground,
  getElementBorder,
  getElementInlineStyle,
  getElementSpacing,
  getElementText,
  getParentBackground,
  getStyleObject
} from './utils';
import { memoizeOn } from '../utils';

export interface ThemeableComponentProps {
  themeImages?: Record<string, any>;
  imageLookup?: Record<string, any>;
  browserFeatures?: {
    supportsWebp?: boolean;
  };
  translate: (...args: any[]) => any | null;
  metaData?: Record<string, any>;
  style?: Record<string, any>;
  pseudoState?: string;
  isSelectedItem?: boolean;
  currentElementKey?: string;
}

/**
 * A base class for components (such as widgets) that can use nucleus-site-editor styling
 */
export class ThemeableComponent<
  T extends ThemeableComponentProps,
  S = Record<string, unknown>
> extends React.Component<T, S> {
  /**
   * The default getStyleObject function. This function should be called
   * by every widget wrapper to convert the passed in theme style object
   * into an inline style object that can be consumed by the corresponding
   * pure UI component. This can be overridden on a per widget basis if
   * that widget needs to more specifically style more of its sub-elements.
   */
  getStyleObject(): any {
    const {
      themeImages,
      style,
      metaData = {},
      imageLookup,
      browserFeatures: { supportsWebp } = {}
    } = this.props;
    return this._getStyleObject(
      style,
      themeImages,
      metaData.disableInput,
      imageLookup,
      supportsWebp
    );
  }

  _getStyleObject = defaultMemoize(getStyleObject);

  /**
   * Gets the style for an element in a merged theme style object.
   * Either inherits from a mapped parent element or uses the custom
   * settings defined in the object.
   */
  getElementInlineStyle(key: any) {
    const {
      themeImages,
      style,
      pseudoState,
      isSelectedItem,
      currentElementKey,
      imageLookup,
      browserFeatures: { supportsWebp } = {}
    } = this.props;
    return this._getElementInlineStyle(
      key,
      style,
      themeImages,
      pseudoState,
      isSelectedItem,
      currentElementKey,
      imageLookup,
      supportsWebp
    );
  }

  _getElementInlineStyle = memoizeOn((key: any) => key, getElementInlineStyle);

  /**
   * Gets the border style for an element in a merged theme style object.
   * Either inherits from a mapped parent element or uses the custom
   * settings defined in the object.
   */
  getElementBorder(key: any) {
    const { style, pseudoState, isSelectedItem, currentElementKey } = this.props;
    return this._getElementBorder(key, style, pseudoState, isSelectedItem, currentElementKey);
  }

  _getElementBorder = memoizeOn((key: any) => key, getElementBorder);

  /**
   * Gets the background style for an element in a merged theme style object.
   * Either inherits from a mapped parent element or uses the custom
   * settings defined in the object.
   */
  getElementBackground(key: any) {
    const {
      themeImages,
      style,
      pseudoState,
      isSelectedItem,
      currentElementKey,
      imageLookup,
      browserFeatures: { supportsWebp } = {}
    } = this.props;
    return this._getElementBackground(
      key,
      style,
      themeImages,
      pseudoState,
      isSelectedItem,
      currentElementKey,
      imageLookup,
      supportsWebp
    );
  }

  _getElementBackground = memoizeOn((key: any) => key, getElementBackground);

  /**
   * Gets the background style of the parent container. This is only to be used
   * for special cases where a widget has a flyout trigger or something similar
   * where a background needs to be applied, but the widget itself has no
   * background set.
   */
  getParentBackground() {
    const { themeImages, style, imageLookup, browserFeatures: { supportsWebp } = {} } = this.props;
    return this._getParentBackground(style, themeImages, imageLookup, supportsWebp);
  }

  _getParentBackground = defaultMemoize(getParentBackground);

  /**
   * Gets the background style for an element in a merged theme style object.
   * Either inherits from a mapped parent element or uses the custom
   * settings defined in the object.
   */
  getElementSpacing(key: any) {
    const { style } = this.props;
    return this._getElementSpacing(key, style);
  }

  _getElementSpacing = memoizeOn((key: any) => key, getElementSpacing);

  /**
   * Gets the text/font style for an element in a merged theme style object.
   * Either inherits from a mapped parent element or uses the custom
   * settings defined in the object.
   */
  getElementText(key: any) {
    const { style, pseudoState, isSelectedItem, currentElementKey } = this.props;
    return this._getElementText(key, style, pseudoState, isSelectedItem, currentElementKey);
  }

  _getElementText = memoizeOn((key: any) => key, getElementText);
}
