import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';
import PropTypes from 'prop-types';

type Props = {
  children?: React.ReactNode;
  isFluid?: boolean;
  className?: string;
  style?: Record<string, any>;
};

/**
 * Container component that wraps a group of rows.
 */
const Container = (props: Props, context: any) => {
  const { children, isFluid = false, className = '' } = props;
  const { grid } = context;
  return (
    <div
      className={`${className} ${isFluid ? grid['container-fluid'] : grid.container}`}
      style={props.style}
      {...resolveTestId(props)}
    >
      {children}
    </div>
  );
};
Container.contextTypes = {
  grid: PropTypes.object
};
Container.displayName = 'Container';

export { Container };
