import merge from 'lodash/merge';
import { WebsiteV8 } from './WebsiteV8';

/**
 * Adds default data to support setting the logo image of a site from the global theme editor
 */
export function addDefaultLogoToWebsiteNavigator(website: WebsiteV8) {
  if (website.siteInfo?.sharedConfigs?.WebsiteNavigator?.logo) {
    return website;
  }

  return {
    ...website,
    siteInfo: merge({}, website.siteInfo, {
      sharedConfigs: {
        WebsiteNavigator: {
          logo: {
            altText: '',
            asset: {},
            hyperlink: ''
          }
        }
      }
    })
  };
}
