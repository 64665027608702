import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';
import { defaultMemoize } from 'reselect';

import { BaseWidget } from '../BaseWidget';
import { Button } from './Button';
import ButtonStyles from './Button.less';
import ClassNames from './Button.less';
import { ThemeableComponentProps } from '../ThemeableComponent';

const Classes = {
  ...ClassNames,
  button: {
    ...ButtonStyles,
    disabled: ClassNames.disabled
  }
};

export interface ButtonWidgetProps extends ThemeableComponentProps {
  isGuest?: boolean;
  type?: string;
  config: {
    text?: Record<string, unknown> | string;
    link?: {
      enabled: boolean;
      text: string;
    };
  };
  classes: Record<string, unknown>;
  kind?: string;
  clickHandler: (...args: any[]) => any;
  linkClickHandler: (...args: any[]) => any;
  disabled?: boolean;
}

/**
 * Data wrapper for the default button widget.
 */
export class ButtonWidget extends BaseWidget<ButtonWidgetProps> {
  static displayName = 'ButtonWidget';

  static defaultProps = {
    classes: Classes
  };

  constructor(props: any) {
    super(props);
    this.onClick = this.onClick.bind(this);
    this.onLinkClick = this.onLinkClick.bind(this);
    this.getLinkConfig = defaultMemoize(this.getLinkConfig);
  }

  onClick() {
    const { isGuest } = this.props;
    if (!isGuest) {
      return;
    }

    this.props.clickHandler(this.props);
  }

  onLinkClick() {
    const { isGuest, linkClickHandler } = this.props;
    if (isGuest && linkClickHandler) {
      linkClickHandler(this.props);
    }
  }

  getLinkConfig(config: any, onClick: any, translate: any) {
    return config && { ...config, onClick, text: translate(config.text) };
  }

  getStyleObject() {
    const buttonStyles = this.getElementInlineStyle('button');
    // Removes text alignment from the button text [PROD-101039 / NUKE-7037]
    delete buttonStyles.textAlign;
    if (this.props.disabled) {
      delete buttonStyles.backgroundColor;
      delete buttonStyles.borderColor;
      delete buttonStyles.borderBottomColor;
      delete buttonStyles.color;
    }
    return {
      ...super.getStyleObject(),
      button: {
        button: buttonStyles
      },
      buttonWrapper: { textAlign: this.getElementText('button').textAlign },
      linkWrapper: {
        textAlign: this.getElementText('link').textAlign,
        overflow: this.getElementText('link').overflow
      },
      link: this.getElementInlineStyle('link')
    };
  }

  render() {
    const { config, translate, classes, kind, disabled } = this.props;

    // Originally, buttons were implemented with rich text editors, but since then
    // they have been updated to be normal textboxes. So we have to allow the text
    // property to come in as rich text or normal plain text. https://jira/browse/FLEX-6581
    let isRichText = false;
    let text = config.text;
    if (config.text?.hasOwnProperty('htmlContent')) {
      isRichText = true;
      text = (config.text as Record<string, string>).htmlContent;
    }

    return (
      <Button
        {...resolveTestId(this.props)}
        classes={classes}
        style={this.getStyleObject()}
        kind={kind}
        text={translate(text)}
        onClick={this.onClick}
        disabled={disabled}
        link={this.getLinkConfig(config.link, this.onLinkClick, translate)}
        isRichText={isRichText}
      />
    );
  }
}
