/* eslint-disable react/no-multi-comp */
import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { injectTestId, resolveTestId } from '@cvent/nucleus-test-automation';
import { FormElement, WithFormProps, resolveValidationAccessibilityProps } from '../FormElement';

function getNewSelectedValues(oldSelectionValues: any, value: any) {
  const newSelectedValues = oldSelectionValues.slice(0);
  const indexInSelectedValues = newSelectedValues.indexOf(value);
  if (indexInSelectedValues !== -1) {
    newSelectedValues.splice(indexInSelectedValues, 1);
  } else {
    newSelectedValues.push(value);
  }
  return newSelectedValues;
}

export type CheckboxDisplay = 'horizontal' | 'vertical';

/*
(ts-migrate) TODO: Migrate the remaining prop types
...FormElement.propTypes
*/
type OwnCheckboxProps = {
  id?: string;
  /** An array of options, each contains a 'name', 'value', 'checked', 'disabled' property and prop dropping onto the input. */
  options: any;
  style?: Record<string, unknown>;
  onChange?: (...args: any[]) => any;
  name?: string;
  /** Corresponds to individual option's fieldName defined in options. */
  fieldName?: string;
  label?: string;
  onNativeChange?: (...args: any[]) => any;
  selectedValues?: any;
  /** Checkbox's display style. Horizontal or vertical. */
  display?: CheckboxDisplay;
  /** Determines if all options in Checkbox should be disabled. */
  disabled?: boolean;
  /** Determines if the Checkbox group should be marked as required. */
  required?: boolean;
};

type OwnCheckboxNativeProps = {
  id?: string;
  /** An array of option objects, each contains a 'name', 'value', 'checked', 'disabled' property and prop dropping onto the input. */
  options: any;
  style?: Record<string, unknown>;
  onChange?: (...args: any[]) => any;
  name?: string;
  /** Corresponds to individual option's fieldName defined in options. */
  fieldName?: string;
  label?: string;
  onNativeChange: (...args: any[]) => any;
  selectedValues: any;
  /** Checkbox's display style. Horizontal or vertical. */
  display?: CheckboxDisplay;
  /** Determines if all options in Checkbox should be disabled. */
  disabled?: boolean;
  /** Determines if the Checkbox group should be marked as required. */
  required?: boolean;
};

type CheckboxProps = (OwnCheckboxProps | OwnCheckboxNativeProps) & typeof Checkbox.defaultProps;

export class Checkbox extends React.Component<CheckboxProps> {
  static displayName = 'Checkbox';
  static defaultProps = {
    required: false
  };

  constructor(props: CheckboxProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setFocus' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.setFocus) {
      if (typeof document !== 'undefined') {
        document
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<C... Remove this comment to see the full error message
          .getElementsByName(this.props.name ? this.props.name : this.props.fieldName)[0]
          .focus();
      }
    }
  }

  onChange(index: any, value: any) {
    let newOptionList = [];
    if (
      this.props.hasOwnProperty('onNativeChange') &&
      this.props.hasOwnProperty('selectedValues')
    ) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onNativeChange' does not exist on type '... Remove this comment to see the full error message
      this.props.onNativeChange(getNewSelectedValues(this.props.selectedValues, value));
    } else {
      if (!this.props.options.hasOwnProperty('selectedValues')) {
        const changes = {
          [index]: Object.assign({}, this.props.options[index], {
            checked: !this.props.options[index].checked
          })
        };
        newOptionList = Object.assign([], this.props.options, changes);
      } else {
        newOptionList = Object.assign({}, this.props.options, {
          selectedValues: getNewSelectedValues(this.props.options.selectedValues, value)
        });
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(this.props.fieldName, value, newOptionList);
    }
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'Readonly<Che... Remove this comment to see the full error message
    const { id, fieldName, errorMessages, required = false } = this.props;

    let hasSelectedValues: any;
    let options;
    let selectedValues: any;
    if (this.props.hasOwnProperty('selectedValues')) {
      hasSelectedValues = true;
      selectedValues = this.props.selectedValues;
      options = this.props.options;
    } else if (this.props.options.hasOwnProperty('selectedValues')) {
      hasSelectedValues = true;
      selectedValues = this.props.options.selectedValues;
      options = this.props.options.optionArray;
    } else {
      hasSelectedValues = false;
      options = this.props.options;
    }

    const checkboxLabelStyles = resolve(this.props, 'checkboxLabel');

    const listItems = options.map((option: any, index: any) => {
      const optionChecked =
        (hasSelectedValues && selectedValues.indexOf(option.value) !== -1) ||
        option.checked ||
        false;
      const elementId = this.props.name
        ? this.props.name + '_' + index
        : this.props.fieldName + '_' + index;
      const defaultDisabledStyle = option.disabled || this.props.disabled ? { opacity: 0.4 } : null;
      const resolvedStyle = {
        style: {
          ...defaultDisabledStyle,
          ...checkboxLabelStyles.style
        }
      };
      if (checkboxLabelStyles.className) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type '{ sty... Remove this comment to see the full error message
        resolvedStyle.className = checkboxLabelStyles.className;
      }
      const name = this.props.name ? this.props.name : this.props.fieldName;
      return (
        <li key={elementId} {...injectTestId(`option-${elementId}`)}>
          <input
            {...resolve(this.props, option.className)}
            id={elementId}
            name={name}
            type="checkbox"
            value={index.toString()}
            checked={optionChecked}
            aria-checked={optionChecked}
            onChange={this.onChange.bind(null, index, option.value)}
            required={required}
            disabled={option.disabled || this.props.disabled}
            {...resolveValidationAccessibilityProps(id || fieldName, errorMessages)}
          />
          <label {...resolvedStyle} htmlFor={elementId}>
            {option.name}
          </label>
        </li>
      );
    });

    return (
      <ul
        {...resolveTestId(this.props)}
        id={this.props.id || this.props.fieldName}
        style={this.props.style}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
        className={this.props.className}
      >
        {listItems}
      </ul>
    );
  }
}

type CheckboxFormElementProps = WithFormProps & {
  children?: React.ReactNode;
  display?: string;
  style?: {
    checkbox?: any;
    horizontal?: any;
  };
};

/**
FormElement wrapper around Checkbox. This is the default export.
**/
const CheckboxFormElement = (props: CheckboxFormElementProps) => {
  const { children, display = 'horizontal', isFieldSet, ...rest } = props;
  const isFieldSetValue = isFieldSet !== undefined ? isFieldSet : true;
  return (
    <FormElement {...rest} isFieldSet={isFieldSetValue}>
      <Checkbox {...rest} {...resolve(props, 'checkbox', display)} {...injectTestId('checkbox')} />
      {children}
    </FormElement>
  );
};
CheckboxFormElement.displayName = 'CheckboxFormElement';

/**
#### Data structure for the "options" property

```js
  [
    {name: 'optionNameA', value: 'optionValueA', checked: true, disabled: true},
    {name: 'optionNameB', value: 'optionValueB', checked: false},
    ...
  ]
```

```js
  {
    selectedValues: ['optionValueA'],
    optionArray: [
      {name: 'optionNameA', value: 'optionValueA'},
      {name: 'optionNameB', value: 'optionValueB'},
      ...
    ]
  }
```

"disabled" is an optional property. When it's set to true, the checkbox is disabled.

Note: if you choose to use the second one, values for the "value" property and in the "selectedValues"
array need to be primitive.
**/

export { CheckboxFormElement };
