/* eslint react/prop-types: [2, { ignore: ["setFocus", "onChange", "onNativeChange", "fieldName", "id", "name",
  "required", "className"] }] */
/* eslint-disable react/no-multi-comp */
import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId, injectTestId } from '@cvent/nucleus-test-automation';
import { FormElement, WithFormProps } from '../FormElement';

/**
Display browser default input toggle.

onChange = function(fieldName, newValue, newOptions)
**/

function getNewSelectedValues(oldSelectionValues: any, value: any) {
  const newSelectedValues = oldSelectionValues.slice(0);
  const indexInSelectedValues = newSelectedValues.indexOf(value);
  if (indexInSelectedValues !== -1) {
    newSelectedValues.splice(indexInSelectedValues, 1);
  } else {
    newSelectedValues.push(value);
  }
  return newSelectedValues;
}

/*
(ts-migrate) TODO: Migrate the remaining prop types
...FormElement.propTypes
*/
type OwnToggleProps = React.PropsWithChildren<{
  classes?: any;
  display?: string;
  options: any;
  style?: Record<string, unknown>;
  selectedValues?: any; // TODO: isRequiredIf(PropTypes.array, props => props.hasOwnProperty('onNativeChange'))
}>;

type ToggleProps = React.PropsWithChildren<OwnToggleProps & typeof Toggle.defaultProps>;

export class Toggle extends React.Component<ToggleProps> {
  static displayName = 'Toggle';
  static defaultProps = {
    required: false
  };

  constructor(props: ToggleProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setFocus' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.setFocus) {
      if (typeof document !== 'undefined') {
        document
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<T... Remove this comment to see the full error message
          .getElementsByName(this.props.name ? this.props.name : this.props.fieldName)[0]
          .focus();
      }
    }
  }

  onChange(index: any, value: any) {
    let newOptionList = [];
    if (!this.props.options.hasOwnProperty('selectedValues')) {
      const changes = {
        [index]: Object.assign({}, this.props.options[index], {
          checked: !this.props.options[index].checked
        })
      };
      newOptionList = Object.assign([], this.props.options, changes);
    } else {
      const newSelectedValues = getNewSelectedValues(this.props.options.selectedValues, value);
      newOptionList = Object.assign({}, this.props.options, { selectedValues: newSelectedValues });
    }

    if (
      this.props.hasOwnProperty('onNativeChange') &&
      this.props.hasOwnProperty('selectedValues')
    ) {
      const newSelectedValues = getNewSelectedValues(this.props.selectedValues, value);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onNativeChange' does not exist on type '... Remove this comment to see the full error message
      this.props.onNativeChange(newSelectedValues);
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(this.props.fieldName, value, newOptionList);
    }
  }

  render() {
    let hasSelectedValues: any;
    let options;
    let selectedValues: any;
    if (this.props.hasOwnProperty('selectedValues')) {
      hasSelectedValues = true;
      options = this.props.options;
      selectedValues = this.props.selectedValues;
    } else if (this.props.options.hasOwnProperty('selectedValues')) {
      hasSelectedValues = true;
      options = this.props.options.optionArray;
      selectedValues = this.props.options.selectedValues;
    } else {
      hasSelectedValues = false;
      options = this.props.options;
    }

    const listItems = options.map((option: any, index: any) => {
      const optionChecked =
        (hasSelectedValues && selectedValues.indexOf(option.value) !== -1) ||
        option.checked ||
        false;
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<T... Remove this comment to see the full error message
      const elementId = this.props.name
        ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<T... Remove this comment to see the full error message
          this.props.name + '_' + index
        : // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
          this.props.fieldName + '_' + index;
      return (
        <li key={elementId} {...injectTestId(`option-${elementId}`)}>
          <label
            {...resolve(this.props, 'toggleLabel')}
            style={option.disabled ? { opacity: 0.4 } : null}
            htmlFor={elementId}
          >
            <input
              id={elementId}
              {...resolve(this.props, 'toggle', 'toggleRound')}
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<T... Remove this comment to see the full error message
              name={this.props.name ? this.props.name : this.props.fieldName}
              type="checkbox"
              defaultValue={index.toString()}
              checked={optionChecked}
              onChange={this.onChange.bind(null, index, option.value)}
              required={this.props.required}
              disabled={option.disabled}
            />
            <div {...resolve(this.props, 'switch')} />
            <span {...resolve(this.props, 'toggleOption')}>
              {option.name}
              {option.tooltip}
            </span>
            {option.description && <p className={option.descriptionStyle}>{option.description}</p>}
          </label>
        </li>
      );
    });
    const classNameObject = `${resolve(this.props, 'toggleItem', this.props.display).className} ${
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
      this.props.className || null
    }`;
    return (
      <ul
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'Readonly<Tog... Remove this comment to see the full error message
        id={this.props.id || this.props.fieldName}
        style={this.props.style}
        {...resolveTestId(this.props)}
        className={classNameObject}
      >
        {listItems}
      </ul>
    );
  }
}

type ToggleFormElementProps = WithFormProps &
  React.PropsWithChildren<{
    children?: React.ReactNode;
    display?: string;
    style?: {
      checkbox?: any;
      horizontal?: any;
    };
    options: any;
  }>;

/**
FormElement wrapper around toggle. This is the default export.
**/
const ToggleFormElement = ({
  children,
  display = 'horizontal',
  ...rest
}: ToggleFormElementProps) => {
  return (
    <FormElement display={display} {...rest} isFieldSet>
      <Toggle display={display} {...rest} {...injectTestId('input')} />
      {children}
    </FormElement>
  );
};
ToggleFormElement.displayName = 'ToggleFormElement';

/**
#### Data structure for the "options" property

```js
  [
    {name: 'optionNameA', value: 'optionValueA', checked: true, disabled: true},
    {name: 'optionNameB', value: 'optionValueB', checked: false},
    ...
  ]
```

```js
  {
    selectedValues: ['optionValueA'],
    optionArray: [
      {name: 'optionNameA', value: 'optionValueA'},
      {name: 'optionNameB', value: 'optionValueB'},
      ...
    ]
  }
```

"disabled" is an optional property. When it's set to true, the toggle is disabled.

Note: if you choose to use the second one, values for the "value" property and in the "selectedValues"
array need to be primitive.
**/

export { ToggleFormElement };
