/* @flow */
import calendarWebsiteMigrations from 'cvent-event-calendar/migrations/website';
import { websiteMigrations as coreWebsiteMigrations } from 'nucleus-widgets';

import pluginDataReducer, * as fromPluginData from './pluginData';
import { LOAD_CALENDAR_MIGRATIONS } from '../../actionTypes';

/**
 * The reducer for the website
 */
export default function reducer(state: $TSFixMe = {}, action: $TSFixMe) {
  if (action.type === LOAD_CALENDAR_MIGRATIONS) {
    const { website } = action.payload;
    return {
      ...state,
      ...calendarWebsiteMigrations(coreWebsiteMigrations(website))
    };
  }
  const { pluginData } = state;
  const newPluginData = pluginDataReducer(pluginData, action);
  if (newPluginData === pluginData) {
    return state;
  }
  return { ...state, pluginData: newPluginData };
}

export function getDefaultWebsitePageId(state: $TSFixMe = {}) {
  return fromPluginData.getDefaultWebsitePageId(state.pluginData);
}
