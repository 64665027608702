/**
 * Returns the local ISO Date given the date object
 */
export function getLocalISODate(date: Date) {
  const tzOffset = new Date().getTimezoneOffset() * 60000; // offset in milliseconds
  const localISOTime = new Date(date.getTime() - tzOffset).toISOString();
  return localISOTime.slice(0, -14); // only want date part
}

/**
 * Returns whether two dates are equal
 */
export function areTwoDatesEqual(date1: Date, date2: Date) {
  const date1ISOEquivalent = getLocalISODate(date1);
  const date2ISOEquivalent = getLocalISODate(date2);
  return date1ISOEquivalent === date2ISOEquivalent;
}

