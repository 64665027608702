import { getTempId } from '../../../utils';
import { CREATE_FOLDER } from './actions';

export function createFolder(folderName: string) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const client = state.clients.questionLibraryClient;
    const uuid = getTempId();

    try {
      const response = await client.postCreateFolder(uuid, folderName);
      dispatch({ type: CREATE_FOLDER, payload: { uuid, folderName } });
      return response;
    } catch (err) {
      return false;
    }
  };
}
