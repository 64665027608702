import { FieldGroupings } from './FieldGroupings';
import { elementStylesPseudoStateTabs, elementStylesPseudoStateTabsV2 } from './StyleMenuFields';

const Elements = {
  'elements.header1': {
    menu: '_fieldMenu_header1__resx',
    fields: [...FieldGroupings.elementStyles]
  },
  'elements.header2': {
    menu: '_fieldMenu_header2__resx',
    fields: [...FieldGroupings.elementStyles]
  },
  'elements.header3': {
    menu: '_fieldMenu_header3__resx',
    fields: [...FieldGroupings.elementStyles]
  },
  'elements.header4': {
    menu: '_fieldMenu_header4__resx',
    fields: [...FieldGroupings.elementStyles]
  },
  'elements.text1': {
    menu: '_fieldMenu_text1__resx',
    fields: [...FieldGroupings.elementStyles]
  },
  'elements.text2': {
    menu: '_fieldMenu_text2__resx',
    fields: [...FieldGroupings.elementStyles]
  },
  'elements.body1': {
    menu: '_fieldMenu_body1__resx',
    fields: [...FieldGroupings.elementStyles]
  },
  'elements.body2': {
    menu: '_fieldMenu_body2__resx',
    fields: [...FieldGroupings.elementStyles]
  },
  'elements.link': {
    menu: '_fieldMenu_link__resx',
    fields: [...FieldGroupings.elementStyles]
  },
  'elements.primaryButton': {
    menu: '_fieldMenu_primaryButton__resx',
    fields: [...FieldGroupings.elementStyles]
  },
  'elements.secondaryButton': {
    menu: '_fieldMenu_secondaryButton__resx',
    fields: [...FieldGroupings.elementStyles]
  },
  'elements.input': {
    menu: '_fieldMenu_input__resx',
    fields: [...FieldGroupings.elementStyles]
  },
  'elements.label': {
    menu: '_fieldMenu_label__resx',
    fields: [...FieldGroupings.elementStyles]
  }
};

/**
 * Editor fields config object that includes pseudo state tabs.
 */
const ElementsWithPseudoStates = {
  elementPseudoStateTabs: { ...elementStylesPseudoStateTabs },
  'elements.header1': {
    ...Elements['elements.header1'],
    fields: ['elementPseudoStateTabs']
  },
  'elements.header2': {
    ...Elements['elements.header2'],
    fields: ['elementPseudoStateTabs']
  },
  'elements.header3': {
    ...Elements['elements.header3'],
    fields: ['elementPseudoStateTabs']
  },
  'elements.header4': {
    ...Elements['elements.header4'],
    fields: ['elementPseudoStateTabs']
  },
  'elements.text1': {
    ...Elements['elements.text1'],
    fields: ['elementPseudoStateTabs']
  },
  'elements.text2': {
    ...Elements['elements.text2'],
    fields: ['elementPseudoStateTabs']
  },
  'elements.body1': {
    ...Elements['elements.body1'],
    fields: ['elementPseudoStateTabs']
  },
  'elements.body2': {
    ...Elements['elements.body2'],
    fields: ['elementPseudoStateTabs']
  },
  'elements.link': {
    ...Elements['elements.link'],
    fields: ['elementPseudoStateTabs']
  },
  'elements.primaryButton': {
    ...Elements['elements.primaryButton'],
    fields: ['elementPseudoStateTabs']
  },
  'elements.secondaryButton': {
    ...Elements['elements.secondaryButton'],
    fields: ['elementPseudoStateTabs']
  },
  'elements.input': {
    ...Elements['elements.input'],
    fields: ['elementPseudoStateTabs']
  },
  'elements.label': {
    ...Elements['elements.label'],
    fields: ['elementPseudoStateTabs']
  }
};

/**
 * Editor fields config object that includes pseudo-state tabs and pseudo-state style modes.
 */
const ElementsWithPseudoStatesV2 = {
  ...Elements,
  'elements.primaryButton': {
    ...Elements['elements.primaryButton'],
    fields: ['elementPseudoStateTabs']
  },
  'elements.secondaryButton': {
    ...Elements['elements.secondaryButton'],
    fields: ['elementPseudoStateTabs']
  },
  elementPseudoStateTabs: { ...elementStylesPseudoStateTabsV2 }
};

export { Elements, ElementsWithPseudoStates, ElementsWithPseudoStatesV2 };
