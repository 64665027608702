/* ACTION TYPES */
export const OPEN_DIALOG = 'nucleus-guestside-site/dialog/OPEN_DIALOG';
export const CLOSE_DIALOG = 'nucleus-guestside-site/dialog/CLOSE_DIALOG';
export const SHOW_LOADING_DIALOG = 'nucleus-guestside-site/loading/SHOW_LOADING_DIALOG';
export const HIDE_LOADING_DIALOG = 'nucleus-guestside-site/loading/HIDE_LOADING_DIALOG';
export const PAGE_TRANSITION_SHOW_LOADING =
  'nucleus-guestside-site/loading/PAGE_TRANSITION_SHOW_LOADING';
export const PAGE_TRANSITION_HIDE_LOADING =
  'nucleus-guestside-site/loading/PAGE_TRANSITION_HIDE_LOADING';
export const HIDE_LOADING = 'nucleus-guestside-site/loading/HIDE_LOADING';

/* ACTIONS */
export const openDialogContainer = (component: any, onClose: any, dialogConfig = {}) => {
  return { type: OPEN_DIALOG, payload: { component, onClose, dialogConfig } };
};

export const openThemedDialogContainer = (component: any, onClose: any, dialogConfig = {}) => {
  return { type: OPEN_DIALOG, payload: { component, onClose, dialogConfig, isThemedDialog: true } };
};

export const closeDialogContainer = () => {
  return { type: CLOSE_DIALOG };
};

export function showLoadingDialog() {
  return { type: SHOW_LOADING_DIALOG };
}

export function hideLoadingDialog() {
  return { type: HIDE_LOADING_DIALOG };
}

export function pageTransitionShowLoading() {
  return { type: PAGE_TRANSITION_SHOW_LOADING };
}

export function pageTransitionHideLoading() {
  return { type: PAGE_TRANSITION_HIDE_LOADING };
}

export function hideLoadingOnError() {
  return { type: HIDE_LOADING };
}

/**
 * A higher order function that wraps an action to signify the loading dialog should
 * be present until complete.
 * @return The method wrapped between loading actions.
 */
export function withLoading(method: any, withPageTransition = false) {
  /**
   * @param args - The args which will be passed to the provided method.
   */
  return (...args: any[]) => {
    return async (dispatch: any) => {
      try {
        dispatch(withPageTransition ? pageTransitionShowLoading() : showLoadingDialog());
        const returnValue = await dispatch(method(...args));
        dispatch(withPageTransition ? pageTransitionHideLoading() : hideLoadingDialog());
        return returnValue;
      } catch (error) {
        dispatch(hideLoadingOnError());
        throw error;
      }
    };
  };
}

/* REDUCER */
const initialState = {
  dialog: {
    isOpen: false,
    children: '',
    isThemedDialog: false
  },
  isLoading: 0,
  pageTransitionLoading: 0
};

export function dialogContainerReducer(state = initialState, action: any) {
  switch (action.type) {
    case OPEN_DIALOG: {
      return {
        ...state,
        dialog: {
          ...action.payload.dialogConfig,
          requestClose: action.payload.onClose,
          children: action.payload.component,
          isOpen: true,
          isThemedDialog: action.payload.isThemedDialog
        }
      };
    }
    case CLOSE_DIALOG: {
      return {
        ...state,
        dialog: initialState.dialog
      };
    }
    case SHOW_LOADING_DIALOG:
      return {
        ...state,
        isLoading: state.isLoading + 1
      };
    case HIDE_LOADING_DIALOG:
      return {
        ...state,
        isLoading: Math.max(state.isLoading - 1, 0)
      };
    case PAGE_TRANSITION_SHOW_LOADING: {
      return {
        ...state,
        pageTransitionLoading: state.pageTransitionLoading + 1
      };
    }
    case PAGE_TRANSITION_HIDE_LOADING: {
      return {
        ...state,
        pageTransitionLoading: Math.max(state.pageTransitionLoading - 1, 0)
      };
    }
    case HIDE_LOADING: {
      return {
        ...state,
        pageTransitionLoading: 0,
        isLoading: 0
      };
    }
    default: {
      return state;
    }
  }
}
