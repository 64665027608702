import sanitizeText from '../sanitize/sanitizeText';
import { SupportedLocales } from '../types/supportedLocalizationTypes';
import decodeHtml from './decodeHtml';

/**
 * Should be called on every retrieved datatag so that any html in them will render properly.
 * Sanitize is also run here so that any malformed tags are fixed and dangerous things are removed.
 */
function decodeAndSanitizeHtml(text: any) {
  return sanitizeText(decodeHtml(text));
}

/**
 * Calls the user defined service call function to get the datatag resolutions array and maps
 * them back to the array of datatags.
 * @param {function} dataTagMakeServiceCall - function to call to load the data tag resolutions.
 * @param {[string]} dataTags - string array of datatags to get resolutions for.
 * @param {function} successCallback - function called on success of dataTagMakeServiceCall.
 * @param {function} failureCallback - function called on failure of dataTagMakeServiceCall.
 * @param {string} context - context to get the resolutions for.
 * @param {string} locale - language to get the resolutions for.
 * @param {RegExp} dataTagFormatRegExp - Regular expression for selecting datatags.
 * @param {func} translate - Text translation function. Generally used inside of the dataTagMakeServiceCall function if
 *    its implemented to return example resolutions. When calling an actual datatag service to get real resolutions,
 *    we would generally expect the service to use the locale property to return the correct text instead.
 */
export default function getDataTagResolutions(
  dataTagMakeServiceCall: any,
  dataTags: string[],
  successCallback: any,
  failureCallback: any,
  context?: any,
  locale?: SupportedLocales,
  dataTagFormatRegExp?: any,
  translate?: any
) {
  dataTagMakeServiceCall(dataTags, {
    successCallback: (resolutions: any) => {
      const dataTagMappings: { [key: string]: any } = {};
      resolutions.forEach((dataTagText: any, index: any) => {
        dataTagMappings[dataTags[index]] = decodeAndSanitizeHtml(dataTagText);
      });
      successCallback(dataTagMappings);
    },
    failureCallback,
    context,
    locale,
    dataTagFormatRegExp,
    translate
  });
}
