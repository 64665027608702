import React from 'react';

import { select, resolve } from '@cvent/nucleus-dynamic-css';

import { InteractiveElement } from '../containers/InteractiveElement';
import { removeKeys } from '../utils/removeKeys';
import { DayItem } from './DayItem';

function isSameDay(d1: any, d2: any) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

function monthIsAfter(d: any, year: any, mon: any) {
  return year > d.getFullYear() || (year === d.getFullYear() && mon > d.getMonth());
}

function monthIsBefore(d: any, year: any, mon: any) {
  return year < d.getFullYear() || (year === d.getFullYear() && mon < d.getMonth());
}

/**
 * Removes any Calendar specific properties from a props object that would be invalid
 * if applied to a base DOM node. Useful when composing multiple components to prevent
 * passing a component props that it doesn't use.
 */
export function removeCalendarProps(props: any) {
  return removeKeys(props, [
    'selectedDate',
    'minDate',
    'maxDate',
    'range',
    'monthLabels',
    'dayLabels',
    'startDay',
    'classes'
  ]);
}

type OwnProps = {
  selectedDate?: any; // TODO: function isDateOrNull(props, propName, componentName) { if (!(props[propName] instanceof Date || props[propName] === null)) { return new Error( `Invalid prop 'value' of "${props[propName]}" supplied to ${componentName} expected one of [null, Date].` ); } }
  minDate?: any; // TODO: PropTypes.instanceOf(Date)
  maxDate?: any; // TODO: PropTypes.instanceOf(Date)
  range?: number;
  monthLabels: any[];
  dayLabels: any[];
  startDay: number;
  onClick: (...args: any[]) => any;
  navButtonPreviousMonthLabel?: string;
  navButtonNextMonthLabel?: string;
  style?: {
    day?: any;
    dayName?: any;
    calendar?: any;
    header?: any;
    previousLink?: any;
    nextLink?: any;
    selectMonth?: any;
    selectYear?: any;
    hidden?: any;
  };
};

type State = any;

type Props = OwnProps & typeof Calendar.defaultProps;

/**
  Calendar - Renders a calendar with the ability to select a date given
  some kind of valid date range.
**/
export class Calendar extends React.Component<Props, State> {
  static displayName = 'Calendar';
  static defaultProps = {
    range: 10,
    startDay: 0,
    selectedDate: null,
    navButtonPreviousMonthLabel: 'Previous Month',
    navButtonNextMonthLabel: 'Next Month'
  };
  constructor(props: Props) {
    super(props);
    const today = new Date();
    this.state = {
      selectedDate: props.selectedDate,
      month: props.selectedDate ? props.selectedDate.getMonth() : today.getMonth(),
      year: props.selectedDate ? props.selectedDate.getFullYear() : today.getFullYear()
    };
    this.isDateInRange = this.isDateInRange.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.monthChanged = this.monthChanged.bind(this);
    this.onClickPrevLink = this.onClickPrevLink.bind(this);
    this.onClickNextLink = this.onClickNextLink.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const currentDate = this.props.selectedDate ? this.props.selectedDate : new Date();
    const newDate = nextProps.selectedDate ? nextProps.selectedDate : currentDate;
    this.setState({
      selectedDate: newDate,
      month: newDate.getMonth(),
      year: newDate.getFullYear()
    });
  }

  onMonthChange(month: any, year: any) {
    if (month < 0) {
      this.setState({ month: 11, year: year - 1 });
    } else if (month > 11) {
      this.setState({ month: 0, year: year + 1 });
    } else {
      this.setState({ month, year });
    }
  }

  onClickPrevLink(year: any, mon: any) {
    if (this.props.minDate && monthIsBefore(this.props.minDate, year, mon - 1)) {
      return;
    }
    this.onMonthChange(mon - 1, year);
  }

  onClickNextLink(year: any, mon: any) {
    if (this.props.maxDate && monthIsAfter(this.props.maxDate, year, mon + 1)) {
      return;
    }
    this.onMonthChange(mon + 1, year);
  }

  isDateInRange(date: any) {
    return (
      (!this.props.minDate || this.props.minDate < date || isSameDay(this.props.minDate, date)) &&
      (!this.props.maxDate || this.props.maxDate > date || isSameDay(this.props.maxDate, date))
    );
  }

  monthChanged() {
    const newMonth = this.refs.monthOptions;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'options' does not exist on type 'ReactIn... Remove this comment to see the full error message
    const monthNumber = parseInt(newMonth.options[newMonth.selectedIndex].value, 10);
    const newYear = this.refs.yearOptions;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'options' does not exist on type 'ReactIn... Remove this comment to see the full error message
    const yearNumber = parseInt(newYear.options[newYear.selectedIndex].value, 10);
    this.onMonthChange(monthNumber, yearNumber);
  }

  render() {
    let year;
    let month;
    if (
      this.props.minDate &&
      monthIsBefore(this.props.minDate, this.state.year, this.state.month)
    ) {
      year = this.props.minDate.getFullYear();
      month = this.props.minDate.getMonth();
    } else if (
      this.props.maxDate &&
      monthIsAfter(this.props.maxDate, this.state.year, this.state.month)
    ) {
      year = this.props.maxDate.getFullYear();
      month = this.props.maxDate.getMonth();
    } else {
      year = this.state.year;
      month = this.state.month;
    }

    // Create an array of different month options.
    const monthSelectOptions = [];
    const monthStart =
      this.props.minDate && this.props.minDate.getFullYear() === year
        ? this.props.minDate.getMonth()
        : 0;
    const monthEnd =
      this.props.maxDate && this.props.maxDate.getFullYear() === year
        ? this.props.maxDate.getMonth()
        : 11;
    for (let i = monthStart; i <= monthEnd; i++) {
      monthSelectOptions.push(
        <option key={i} value={i}>
          {this.props.monthLabels[i]}
        </option>
      );
    }

    // Creates an array of different year options.
    const yearSelectOptions = [];
    const curYear = new Date().getFullYear();
    const minYear = this.props.minDate
      ? this.props.minDate.getFullYear()
      : curYear - this.props.range;
    const maxYear = this.props.maxDate
      ? this.props.maxDate.getFullYear()
      : curYear + this.props.range;
    for (let i = minYear; i <= maxYear; i++) {
      yearSelectOptions.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    // adds an option in the year dropdown if the selected year is out of the range already provided
    if (year < minYear || year > maxYear) {
      yearSelectOptions.push(
        <option key={'selected'} value={year}>
          {year}
        </option>
      );
    }

    // Creates an array of day options for the current month/year.
    const days = [];
    let firstDay = new Date(year, month, 1).getDay();
    // if the startDay is not 0 (Sunday), and firstDay is less than startDay, the firstDay needs to adjust,
    // otherwise the date in the first row won't match the correct day
    if (firstDay < this.props.startDay) {
      firstDay += 7;
    }
    // adds dates that occur before the 1st of the current month but must be displayed on the calendar.
    // For example, if Mar 1st is a Tuesday, must also display Feb 27 and 28
    const lastDateInPrevMonth = new Date(year, month, 0);
    const lastDateInCurMonth = new Date(year, month + 1, 0);
    // Remove once LESS migration is deprecated
    const day = select(this.props, 'day');
    const mergedClasses = {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'classes' does not exist on type 'Readonl... Remove this comment to see the full error message
      ...this.props.classes,
      ...day.classes
    };
    for (
      let i = lastDateInPrevMonth.getDate() - firstDay + this.props.startDay;
      i < lastDateInPrevMonth.getDate();
      i++
    ) {
      days.push(
        <DayItem
          key={'previous_' + i}
          selectedDate={this.props.selectedDate}
          day={i + 1}
          month={month - 1}
          year={year}
          isActive={this.isDateInRange(new Date(year, month - 1, i + 1))}
          onClick={this.props.onClick}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; selectedDate: any; day: numbe... Remove this comment to see the full error message
          classes={mergedClasses}
        />
      );
    }
    // adds dates in the current month
    for (let i = 1; i <= lastDateInCurMonth.getDate(); i++) {
      days.push(
        <DayItem
          key={'current_' + i}
          selectedDate={this.props.selectedDate}
          day={i}
          month={month}
          year={year}
          isActive={this.isDateInRange(new Date(year, month, i))}
          onClick={this.props.onClick}
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; selectedDate: any; day: numbe... Remove this comment to see the full error message
          classes={mergedClasses}
        />
      );
    }
    // adds dates of the next month that still must be displayed on this calendar.
    // For example, if Jan 31st is a Friday, must also display Feb 1st.
    if (7 - lastDateInCurMonth.getDay() + this.props.startDay <= 7) {
      for (let i = 1; i < 7 - lastDateInCurMonth.getDay() + this.props.startDay; i++) {
        days.push(
          <DayItem
            key={'next_' + i}
            selectedDate={this.props.selectedDate}
            day={i}
            month={month + 1}
            year={year}
            isActive={this.isDateInRange(new Date(year, month + 1, i))}
            onClick={this.props.onClick}
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ key: string; selectedDate: any; day: numbe... Remove this comment to see the full error message
            classes={mergedClasses}
          />
        );
      }
    }
    // Get the day names
    const dayNames = [];
    for (let i = 0; i < 7; i++) {
      dayNames.push(
        <th key={i} {...resolve(this.props, 'dayName')}>
          {this.props.dayLabels[i]}
        </th>
      );
    }

    return (
      <div {...resolve(this.props, 'calendar')}>
        <ul {...resolve(this.props, 'header')}>
          <li {...resolve(this.props, 'liPrevious')}>
            <InteractiveElement
              element="span"
              {...resolve(this.props, 'previousLink')}
              aria-label={this.props.navButtonPreviousMonthLabel}
              onClick={this.onClickPrevLink.bind(null, year, month)}
            >
              &#x3c;
            </InteractiveElement>
          </li>
          <li {...resolve(this.props, 'liMonth')}>
            <label {...resolve(this.props, 'hidden')} htmlFor="monthOptions">
              <span>months</span>
            </label>
            <select
              {...resolve(this.props, 'selectMonth')}
              tabIndex={-1}
              onChange={this.monthChanged}
              value={month}
              ref="monthOptions"
              name="monthOptions"
              id="monthOptions"
            >
              {monthSelectOptions}
            </select>
          </li>
          <li {...resolve(this.props, 'liYear')}>
            <label {...resolve(this.props, 'hidden')} htmlFor="yearOptions">
              <span>years</span>
            </label>
            <select
              {...resolve(this.props, 'selectYear')}
              tabIndex={-1}
              onChange={this.monthChanged}
              value={year}
              ref="yearOptions"
              name="yearOptions"
              id="yearOptions"
            >
              {yearSelectOptions}
            </select>
          </li>
          <li {...resolve(this.props, 'liNext')}>
            <InteractiveElement
              element="span"
              {...resolve(this.props, 'nextLink')}
              aria-label={this.props.navButtonNextMonthLabel}
              onClick={this.onClickNextLink.bind(null, year, month)}
            >
              &#x3e;
            </InteractiveElement>
          </li>
        </ul>
        <table {...resolve(this.props, 'calendarTable')}>
          <thead>
            <tr>{dayNames}</tr>
          </thead>
          <tbody>
            <tr>{days.slice(0, 7)}</tr>
            <tr>{days.slice(7, 14)}</tr>
            <tr>{days.slice(14, 21)}</tr>
            <tr>{days.slice(21, 28)}</tr>
            <tr>{days.slice(28, 35)}</tr>
            <tr>{days.slice(35, 42)}</tr>
          </tbody>
        </table>
      </div>
    );
  }
}
