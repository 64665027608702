import { themeMigrations } from './theme';

/**
 * Version 4 migrations for the website.
 * - Introduction of global font "palette" to the global theme object.
 * - Remove useless version property from theme object.
 */
export function v4(website: any) {
  return {
    ...website,
    frameworkVersion: 4,
    theme: themeMigrations(website.theme)
  };
}
