import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

type Props = {
  // Used in resolve function // TODO: determine neccesary fields for classes
  classes?: Record<string, unknown>;
  // Used in resolve function
  style?: Record<string, unknown>;
};
/**
 * An empty widget that is used to populate a layout column that contains no other widget content.
 * Can also be left in the layout to create empty space if needed.
 */
export class EmptyCell extends React.Component<Props> {
  static displayName = 'EmptyCell';
  render() {
    return <div {...resolveTestId(this.props)} {...resolve(this.props, 'container')} />;
  }
}
