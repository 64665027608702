import { connect } from 'react-redux';

import {
  retryAddComment,
  COMMENT_ERRORS,
  removeCommentError,
  retryDeleteComment,
  retryLoadComments,
  retryResolveComment,
  retryGetPageLevelCommentCount
} from '../../redux/modules/comments';
import { showQuestionAlert } from '../../redux/modules/questionLibrary/showQuestionAlert';
import { CanvasFeedbackPanel } from './CanvasFeedbackPanel';

const DISMISS_INTERVAL = 4000;

const COMMON_ALERT_PROPS = {
  appearance: 'danger',
  action: 'NucleusWidgets_CommentAlert_Retry__resx'
};

const QUESTION_LIBRARY_CODES = {
  Success: 'QUESTION_LIBRARY_SUCCESS'
};

const ALERT_PROPS_BY_TYPE = {
  [COMMENT_ERRORS.Load]: {
    content: 'NucleusWidgets_LoadCommentAlert__resx',
    accessibility: 'NucleusWidgets_LoadCommentAlert_Accessibility__resx'
  },
  [COMMENT_ERRORS.Post]: {
    content: 'NucleusWidgets_PostCommentAlert__resx',
    accessibility: 'NucleusWidgets_PostCommentAlert_Accessibility__resx'
  },
  [COMMENT_ERRORS.Delete]: {
    content: 'NucleusWidgets_RemoveCommentAlert__resx',
    accessibility: 'NucleusWidgets_RemoveCommentAlert_Accessibility__resx'
  },
  [COMMENT_ERRORS.Resolve]: {
    content: 'NucleusWidgets_ResolveCommentAlert__resx',
    accessibility: 'NucleusWidgets_ResolveCommentAlert_Accessibility__resx'
  },
  [COMMENT_ERRORS.GetPageLevelCommentCount]: {
    content: 'NucleusWidgets_AggregateCommentCountAlert__resx',
    accessibility: 'NucleusWidgets_AggregateCommentCountAlert_Accessibility__resx'
  }
};

const dismissError = (dispatch: any, data: any) => {
  const { id } = data;
  dispatch(removeCommentError(id));
};

const dismissSuccess = (dispatch: any, data: any) => {
  const { setShow } = data;
  dispatch(showQuestionAlert(setShow));
};

const ALERT_ACTIONS_BY_TYPE = {
  [COMMENT_ERRORS.Load]: {
    dismiss: dismissError,
    action: async (dispatch: any) => {
      await dispatch(retryLoadComments());
    }
  },
  [COMMENT_ERRORS.Post]: {
    dismiss: dismissError,
    action: async (dispatch: any, data: any) => {
      const { metadata } = data;
      const { widgetId } = metadata;
      await dispatch(retryAddComment(widgetId));
    }
  },
  [COMMENT_ERRORS.Delete]: {
    dismiss: dismissError,
    action: async (dispatch: any, data: any) => {
      const { metadata } = data;
      const { widgetId, commentId } = metadata;
      await dispatch(retryDeleteComment(widgetId, commentId));
    }
  },
  [COMMENT_ERRORS.Resolve]: {
    dismiss: dismissError,
    action: async (dispatch: any, data: any) => {
      const { metadata } = data;
      const { widgetId, commentId, isResolved } = metadata;
      await dispatch(retryResolveComment(widgetId, commentId, isResolved));
    }
  },
  [COMMENT_ERRORS.GetPageLevelCommentCount]: {
    dismiss: dismissError,
    action: async (dispatch: any, data: { selectedPageId?: string }) => {
      const { selectedPageId } = data;
      await dispatch(retryGetPageLevelCommentCount(selectedPageId));
    }
  },
  [QUESTION_LIBRARY_CODES.Success]: {
    dismiss: dismissSuccess,
    action: () => {
      return;
    }
  }
};

interface IError {
  data: { widgetId: string };
  id: string;
  isAlert: boolean;
  retrying: boolean;
  type: string;
}

const generateAlerts = (errors: IError[] = [], showQuestionLibraryAlert: boolean) => {
  const alerts: any[] = errors
    .filter((err: any) => err.isAlert)
    .map((err: any) => {
      const { id, type, data, retrying } = err;
      const props = ALERT_PROPS_BY_TYPE[type];

      return {
        id,
        retrying,
        data: { id, type, metadata: data },
        ...props,
        ...COMMON_ALERT_PROPS
      };
    });
  if (showQuestionLibraryAlert) {
    alerts.push({
      appearance: 'success',
      id: 'questionLibraryAlert',
      content: 'NucleusWidgets_QuestionLibrarySuccessAlert__resx',
      data: { type: 'QUESTION_LIBRARY_SUCCESS', setShow: false },
      dismissInterval: DISMISS_INTERVAL
    });
  }
  return alerts;
};

export const AlertPanel = connect(
  (state: any) => {
    const { translate } = state.text;
    const { errors } = state?.comments ?? { errors: [] };
    const alerts = generateAlerts(errors, state.questionLibrary?.showAlert);

    return { alerts, translate };
  },
  dispatch => {
    return {
      onAction: (data: any) => {
        const action = ALERT_ACTIONS_BY_TYPE[data.type].action;
        action(dispatch, data);
      },
      onDismiss: (data: any) => {
        const dismiss = ALERT_ACTIONS_BY_TYPE[data.type].dismiss;
        dismiss(dispatch, data);
      }
    };
  }
)(CanvasFeedbackPanel);
