/**
 * Parse an ISO formatted  date in the local timezone
 * @param dateString
 */
export function parseLocalISODate(dateString) {
  const [year, month, day, hour = 0, minute = 0, second = 0] = dateString.split(
    /\D/
  );
  return new Date(year, month - 1, day, hour, minute, second);
}

/**
 * Parse an ISO formatted date to a UTC data
 * @param dateString
 */
export function parseUTCDate(dateString) {
  const [year, month, day, hour = 0, minute = 0, second = 0] = dateString.split(
    /\D/
  );
  return new Date(Date.UTC(year, month - 1, day, hour, minute, second));
}

/**
 * Format a JS Date to an ISO8061 string that can reliably be deserialized into a JS Date. This means it must follow the
 * format "YYYY-MM-DD HH:mm:ss". The time of the date produced will always by 00:00:00.
 * @param date
 * @returns {*}
 */
export function formatDateToLocalISOString(date) {
  // Adjust for browser timezone offset
  const adjustedDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  );
  const str = adjustedDate.toISOString();
  // Remove time separator and Z from end of string (signifying UTC timezone) and zero out time
  return `${str.slice(0, 10)} 00:00:00`;
}

/**
 * Format a JS Date to an ISO8061 string that can reliably be deserialized into a JS Date. This means it must follow the
 * format "YYYY-MM-DD HH:mm:ss".
 * @param date
 * @returns {*}
 */
export function formatDateTimeToLocalISOString(date) {
  // Adjust for browser timezone offset
  const adjustedDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  );
  const str = adjustedDate.toISOString();
  // Remove time separator and Z from end of string (signifying UTC timezone) and zero out time
  return `${str.slice(0, 10)} ${str.slice(11, 19)}`;
}
