'use client';
import React, { createContext, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { initializeLocalization, initializeLocalizationSync } from './localization';

// TODO fill in LocalizationContextValue types. This will be a breaking change to types.
export type LocalizationContextValue = any;

export const LocalizationContext = createContext<LocalizationContextValue>({
  'not connected to provider': true
});

const defaultSsrResolver = {
  resolveTranslation: () => undefined,
  number: () => undefined,
  date: () => undefined,
  currency: () => undefined,
  time: () => undefined,
  dateTime: () => undefined
};

const makeSsrResolver = (
  serverSideLocales: any,
  locale: any,
  textResolverOptions: any,
  defaultLocale: any,
  defaultLocaleOverrides: any
) => {
  // Initialize a TextResolver if we have locales from the server-side
  if (serverSideLocales && serverSideLocales[locale]) {
    return initializeLocalizationSync(
      locale,
      serverSideLocales,
      textResolverOptions,
      defaultLocale,
      defaultLocaleOverrides
    );
  }
  return defaultSsrResolver;
};

type Props = React.PropsWithChildren<{
  locale?: string | ((...args: any[]) => any);
  locales?: any;
  clientSideLocales?: any;
  serverSideLocales?: any;
  defaultLocale?: string;
  defaultLocaleOverrides?: { [key: string]: string };
  isRtl?: boolean;
  textResolverOptions?: any;
  translate?: (...args: any[]) => any;
}>;

export const LocalizationProvider: any = (props: Props) => {
  const {
    locale,
    locales,
    clientSideLocales,
    serverSideLocales,
    defaultLocale = 'en-US',
    defaultLocaleOverrides,
    isRtl = false,
    children,
    textResolverOptions,
    translate,
    ...restProps
  } = props;
  const computedLocale =
    locale ||
    (typeof window !== 'undefined' &&
      (localStorage.getItem('locale') || sessionStorage.getItem('locale'))) ||
    'en-US';
  const _locale = typeof computedLocale === 'function' ? computedLocale() : computedLocale;

  const ssrResolver = useMemo(
    () =>
      makeSsrResolver(
        serverSideLocales,
        _locale,
        textResolverOptions,
        defaultLocale,
        defaultLocaleOverrides
      ),
    [serverSideLocales, _locale, textResolverOptions, defaultLocale, defaultLocaleOverrides]
  );

  const [textResolver, setTextResolver] = useState({
    ...ssrResolver
  });

  const [localeObject, setLocaleObject] = useState({});

  // Initialize a client-side TextResolver
  useEffect(() => {
    initializeLocalization(
      _locale,
      clientSideLocales || locales,
      textResolverOptions,
      defaultLocale,
      defaultLocaleOverrides
    ).then(({ textResolver: resolver, localeObject: localeFile }) => {
      setTextResolver(resolver);
      setLocaleObject(localeFile);
    });
  }, [
    _locale,
    locales,
    clientSideLocales,
    textResolverOptions,
    defaultLocale,
    defaultLocaleOverrides
  ]);

  const providerInitValue = useMemo<LocalizationContextValue>(
    () => ({
      textResolver: {
        ...textResolver,
        translate: (...args: any[]) => {
          const resolveTranslation = (...resolveArgs: any[]) =>
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'resolveTranslation' does not exist on ty... Remove this comment to see the full error message
            textResolver.resolveTranslation(...resolveArgs) || '';

          // Use translate override function if provided
          if (typeof translate === 'function') {
            return translate(resolveTranslation, ...args);
          }

          return resolveTranslation(...args);
        }
      },
      localeObject,
      isRtl,
      'not connected to provider': true,
      ...restProps
    }),
    [isRtl, localeObject, restProps, textResolver, translate]
  );

  return (
    <LocalizationContext.Provider value={providerInitValue}>
      {children}
    </LocalizationContext.Provider>
  );
};

LocalizationProvider.propTypes = {
  /** React elements to be rendered (will have access to this context) */
  children: PropTypes.any,
  /** Locale for which to provide translations */
  locale: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** Same as `clientSideLocales`; present for backwards compatibility */
  locales: PropTypes.object,
  /** An object of key/value pairs associating locale keys with dynamic imports of json translation files */
  clientSideLocales: PropTypes.object,
  /** Same as `clientSideLocales` except pre-populated with an object of localization key/values rather than dynamic import statements */
  serverSideLocales: PropTypes.object,
  /** Locale to use if provided locale cannot be resolved */
  defaultLocale: PropTypes.string,
  /** Override locale mappings when matching a given locale to the provided locales from either clientSideLocales or serverSideLocales */
  defaultLocaleOverrides: PropTypes.objectOf(PropTypes.string),
  /** Writing direction of current language, left-to-right(default) or right-to-left */
  isRtl: PropTypes.bool,
  /** Options passed to the TextResolver constructor https://wiki.cvent.com/x/kLAhAg */
  textResolverOptions: PropTypes.object,
  /** Optional translate override function. (originalTranslate, ...args) => string */
  translate: PropTypes.func
  /* What other properties should be standard? */
  // language?
  // date format?
  // version?
};
