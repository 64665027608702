/* @flow */
import { combineReducers } from 'redux';

import calendarWebsiteNavigation, * as fromCalendarWebsiteNavigation from './calendarWebsiteNavigation';

const pluginDataReducer = combineReducers({
  calendarWebsiteNavigation
});

export default pluginDataReducer;

export function getDefaultWebsitePageId(state: $TSFixMe = {}) {
  return fromCalendarWebsiteNavigation.getDefaultWebsitePageId(state.calendarWebsiteNavigation);
}
