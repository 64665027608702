const MOBILE_DATE_STRING_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
/**
 * HTML5 date inputs (used by mobile) always return their value as a string
 * of the form {(4 digit year)-(month with leading 0)-(day with leading 0)}.
 * This function will parse that string into a date object with a zero'ed out
 * time component.
 * @param {string} dateString - an html5 formatted mobile date string.
 * @returns {Date} A new Date object with the specified year, month, day of the string.
 */
export default function parseMobileDateString(dateString) {
  if (!dateString || !MOBILE_DATE_STRING_REGEX.test(dateString)) {
    return null;
  }
  const stringArray = dateString.split('-');
  const year = parseInt(stringArray[0], 10);
  const month = parseInt(stringArray[1], 10) - 1;
  const day = parseInt(stringArray[2], 10);
  return new Date(year, month, day);
}
