import React, { useState } from 'react';

import { Button } from '@cvent/carina/components/Button';
import CheckCircleIcon from '@cvent/carina/components/Icon/CheckCircle';
import TrashIcon from '@cvent/carina/components/Icon/Trash';
import { LoadingIcon } from '@cvent/carina/components/LoadingIcon';
import Classes from './Comments.less';
import { deleteComment, resolveComment } from '../../redux/modules';
import { useDispatch, useSelector } from 'react-redux';
import { Comment } from '../../types/comment';
import { injectTestId } from '@cvent/nucleus-test-automation';

const dateOptions: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
const timeOptions: Intl.DateTimeFormatOptions = { hour: 'numeric', minute: 'numeric' };
const iconColor = '#69717A';

export interface ICommentRow {
  comment: Comment;
  locale: string;
  translate: (key: string) => string;
  widgetId: string;
  additionalStyle: Record<string, string>;
}

export function CommentItem({
  comment,
  translate,
  widgetId,
  additionalStyle,
  locale
}: ICommentRow) {
  const dispatch = useDispatch();
  const reviewerId = useSelector((state: any) => state.clients.commentsClient.reviewer.id);
  const commentRef = React.useRef<HTMLDivElement>(null);
  const [deleteProcessing, setDeleteProcessing] = useState(false);
  const [commentResolving, setCommentResolving] = useState(false);
  const [resolveProcessing, setResolveProcessing] = useState(false);
  const [confirmation, setConfirmation] = useState(false);

  const { id, reviewer, created, text, resolved } = comment;
  const commentId: string = id;

  const authorName =
    reviewer?.firstName && reviewer?.lastName
      ? reviewer?.lastName + ', ' + reviewer?.firstName
      : reviewer?.email;
  const authorId: string = reviewer.id;
  const dateObject = new Date(created);
  const date = dateObject.toLocaleDateString(locale, dateOptions);
  const time = dateObject.toLocaleTimeString(locale, timeOptions);
  const dateString = `${date} at ${time}`;

  const dateClass = resolved ? Classes.dateResolved : Classes.date;

  const toggleCommentResolve = async () => {
    setResolveProcessing(true);
    setCommentResolving(true);
    // @ts-expect-error Argument of type '(dispatch: any, getState: any) => Promise<void>' is not assignable to parameter of type 'AnyAction'.
    await dispatch(resolveComment(widgetId, commentId, !resolved));
    setResolveProcessing(false);
    setCommentResolving(false);
  };

  const commentDelete = async () => {
    setDeleteProcessing(true);
    // @ts-expect-error Argument of type '(dispatch: any, getState: any) => Promise<void>' is not assignable to parameter of type 'AnyAction'.
    await dispatch(deleteComment(widgetId, commentId));
    setDeleteProcessing(false);
  };

  return (
    <div
      key={commentId}
      className={resolved ? Classes.commentResolved : Classes.comment}
      {...injectTestId('comment-list')}
      tabIndex={0}
      ref={commentRef}
    >
      <div className={Classes.author}>
        <span className={Classes.authorContainer}>
          {resolved && (
            <div
              className={Classes.leftSideResolveIcon}
              {...injectTestId('comment-left-resolved-icon')}
            >
              <CheckCircleIcon size="m" color={iconColor} />
            </div>
          )}
          <div className={Classes.name} title={authorName} {...injectTestId('comment-author-name')}>
            {authorName}
          </div>
        </span>
        {!confirmation && (
          <div className={Classes.actionIconGroup}>
            {!resolveProcessing || !commentResolving ? (
              <button
                title={
                  resolved
                    ? translate('NucleusSiteEditor_CommentAction_Unresolve')
                    : translate('NucleusSiteEditor_CommentAction_Resolve')
                }
                className={Classes.actionIcons}
                onClick={() => {
                  toggleCommentResolve();
                }}
                {...injectTestId(resolved ? 'unresolve-comment-icon' : 'resolve-comment-icon')}
              >
                <CheckCircleIcon size="m" color={iconColor} />
              </button>
            ) : (
              commentResolving && (
                <span className={Classes.actionIcons} {...injectTestId('resolving-comment-icon')}>
                  <LoadingIcon size="m" />
                </span>
              )
            )}
            {authorId === reviewerId ? (
              <button
                title={translate('NucleusSiteEditor_CommentAction_Delete')}
                className={Classes.actionIcons}
                onClick={() => {
                  setConfirmation(true);
                }}
                {...injectTestId('delete-comment-icon')}
              >
                <TrashIcon size="m" color={iconColor} />
              </button>
            ) : (
              <></>
            )}
          </div>
        )}
        {!commentResolving && (
          <div className={dateClass} {...injectTestId('comment-date')}>
            {dateString}
          </div>
        )}
      </div>
      <div className={Classes.text} {...injectTestId('comment-text')}>
        {text}
      </div>
      {confirmation && (
        <div className={additionalStyle.trashConfirmation}>
          <div className={additionalStyle.confirmationContainer}>
            <div className={Classes.title}>
              {translate('NucleusSiteEditor_TrashConfirmation_title__resx')}
            </div>
            <div className={`${additionalStyle.btnContainer}`}>
              {!deleteProcessing && (
                <Button
                  className={additionalStyle.commentBtn}
                  onClick={() => commentDelete()}
                  text={translate('NucleusSiteEditor_TrashConfirmation_confirm__resx')}
                  size="m"
                  disabled={deleteProcessing}
                  appearance="primary"
                  testID="delete-comment"
                />
              )}
              {!deleteProcessing && (
                <Button
                  className={additionalStyle.commentBtn}
                  onClick={() => {
                    setConfirmation(false);
                  }}
                  text={translate('NucleusSiteEditor_TrashConfirmation_cancel__resx')}
                  size="m"
                  appearance="secondary"
                  testID="cancel-delete-comment"
                />
              )}
              {deleteProcessing && <LoadingIcon size="m" />}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
