/**
 * Gets the first parent of the given field in the value object that has
 * a style mapping field, if one exists.
 * @param {array} valueObject - The object containing the value specified by the fieldName.
 * @param {string} fieldName - The fieldName to key into the value object.
 *     This can be infinitely nested in the config object.
 *     The structure should be as such: fieldName.subFieldName.subSubFieldName...
 *     Should also be able to handle array indexes as field names: fieldName.0.arraySubField...
 * @returns The parent field that contains a style mapping if one exists
 *     and its field name relative to the valueObject, otherwise null.
 */
export function getParentStyleMappingField(valueObject: any, fieldName: any) {
  let parent = null;
  let parentIndex = 0;
  let value = valueObject;
  const keys = fieldName.split('.');
  keys.forEach((key: any, index: any) => {
    if (!value) {
      return;
    }
    if (value.styleMapping) {
      parent = value;
      parentIndex = index;
    }
    value = value[key];
  });

  if (!parent) {
    return null;
  }

  return {
    object: parent,
    fieldName: keys.slice(0, -(keys.length - parentIndex)).join('.'),
    childFieldName: keys.slice(parentIndex, keys.length).join('.')
  };
}
