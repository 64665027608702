import { pageMigrations } from './pages';
import { themeMigrations } from './theme';

/**
 * Version 1 migrations for the website.
 * - Introduction of template page support.
 * - Introduction of new text1 and text2 global theme elements.
 */
export function v1(website: any) {
  return {
    ...website,
    frameworkVersion: 1,
    pages: pageMigrations(website.pages),
    theme: themeMigrations(website.theme)
  };
}
