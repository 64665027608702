/**
 * Slightly altered version of localizer class from react-big-calendar's master branch.
 * This altered version allows us to make use of certain api changes needed in the luxon localizer without taking
 * breaking changes in later versions of react-big-calendar
 *
 * Source: https://github.com/jquense/react-big-calendar/blob/master/src/localizer.js
 */

/* eslint-disable complexity */
import PropTypes from 'prop-types';
import dates from 'react-big-calendar/lib/utils/dates';

const {
  merge,
  inRange,
  lt,
  lte,
  gt,
  gte,
  eq,
  neq,
  startOf,
  endOf,
  add,
  range,
  diff,
  ceil,
  min,
  max,
  firstVisibleDay,
  lastVisibleDay,
  visibleDays,
  minutes,
  isJustDate
} = dates;
const localePropType = PropTypes.oneOfType([PropTypes.string, PropTypes.func]);

function _format(localizer: any, formatter: any, value: any, format: any, culture: any) {
  const result =
    typeof format === 'function'
      ? format(value, culture, localizer)
      : formatter.call(localizer, value, format, culture);

  return result;
}

/**
 * This date conversion was moved out of TimeSlots.js, to
 * allow for localizer override
 * @param {Date} dt - The date to start from
 * @param {Number} minutesFromMidnight
 * @param {Number} offset
 * @returns {Date}
 */
function getSlotDate(dt: any, minutesFromMidnight: any, offset: any) {
  return new Date(
    dt.getFullYear(),
    dt.getMonth(),
    dt.getDate(),
    0,
    minutesFromMidnight + offset,
    0,
    0
  );
}

function getDstOffset(start: any, end: any) {
  return start.getTimezoneOffset() - end.getTimezoneOffset();
}

// if the start is on a DST-changing day but *after* the moment of DST
// transition we need to add those extra minutes to our minutesFromMidnight
function getTotalMin(start: any, end: any) {
  return diff(start, end, 'minutes') + getDstOffset(start, end);
}

function getMinutesFromMidnight(start: any) {
  const daystart = startOf(start, 'day');
  return diff(daystart, start, 'minutes') + getDstOffset(daystart, start);
}

// These two are used by DateSlotMetrics
function continuesPrior(start: any, first: any) {
  return lt(start, first, 'day');
}

function continuesAfter(start: any, end: any, last: any) {
  const singleDayDuration = eq(start, end, 'minutes');
  return singleDayDuration ? gte(end, last, 'minutes') : gt(end, last, 'minutes');
}

// These two are used by eventLevels
function sortEvents({
  evtA: { start: aStart, end: aEnd, allDay: aAllDay },
  evtB: { start: bStart, end: bEnd, allDay: bAllDay }
}: any) {
  const startSort = +startOf(aStart, 'day') - +startOf(bStart, 'day');

  const durA = diff(aStart, ceil(aEnd, 'day'), 'day');

  const durB = diff(bStart, ceil(bEnd, 'day'), 'day');

  return (
    startSort || // sort by start Day first
    Math.max(durB, 1) - Math.max(durA, 1) || // events spanning multiple days go first
    // @ts-expect-error ts-migrate()
    !!bAllDay - !!aAllDay || // then allDay single day events
    +aStart - +bStart || // then sort by start time
    +aEnd - +bEnd // then sort by end time
  );
}

function inEventRange({ event: { start, end }, range: { start: rangeStart, end: rangeEnd } }: any) {
  const eStart = startOf(start, 'day');

  const startsBeforeEnd = lte(eStart, rangeEnd, 'day');
  // when the event is zero duration we need to handle a bit differently
  const sameMin = neq(eStart, end, 'minutes');
  const endsAfterStart = sameMin ? gt(end, rangeStart, 'minutes') : gte(end, rangeStart, 'minutes');
  return startsBeforeEnd && endsAfterStart;
}

// other localizers treats 'day' and 'date' equality very differently, so we
// abstract the change the 'localizer.eq(date1, date2, 'day') into this
// new method, where they can be treated correctly by the localizer overrides
function isSameDate(date1: any, date2: any) {
  return eq(date1, date2, 'day');
}

function startAndEndAreDateOnly(start: any, end: any) {
  return isJustDate(start) && isJustDate(end);
}

function error() {
  throw new Error(
    'You have not selected a localization strategy for Big Calendar. ' +
      'Please use either of the two included.'
  );
}

export class DateLocalizer {
  propType: any;
  formats: any;
  firstOfWeek: any;
  startOfWeek: any;
  merge: any;
  inRange: any;
  lt: any;
  lte: any;
  gt: any;
  gte: any;
  eq: any;
  neq: any;
  startOf: any;
  endOf: any;
  add: any;
  range: any;
  diff: any;
  ceil: any;
  min: any;
  max: any;
  minutes: any;
  firstVisibleDay: any;
  lastVisibleDay: any;
  visibleDays: any;
  getSlotDate: any;
  getTimezoneOffset: any;
  getDstOffset: any;
  getTotalMin: any;
  getMinutesFromMidnight: any;
  continuesPrior: any;
  continuesAfter: any;
  sortEvents: any;
  inEventRange: any;
  isSameDate: any;
  startAndEndAreDateOnly: any;
  segmentOffset: any;
  format: (...args: any[]) => any;
  parse: (value: any, format: any, culture: any) => any;

  constructor(spec: any) {
    this.propType = spec.propType || localePropType;

    this.formats = spec.formats;
    this.firstOfWeek = spec.firstOfWeek;
    this.startOfWeek = spec.firstOfWeek;
    this.merge = spec.merge || merge;
    this.inRange = spec.inRange || inRange;
    this.lt = spec.lt || lt;
    this.lte = spec.lte || lte;
    this.gt = spec.gt || gt;
    this.gte = spec.gte || gte;
    this.eq = spec.eq || eq;
    this.neq = spec.neq || neq;
    this.startOf = spec.startOf || startOf;
    this.endOf = spec.endOf || endOf;
    this.add = spec.add || add;
    this.range = spec.range || range;
    this.diff = spec.diff || diff;
    this.ceil = spec.ceil || ceil;
    this.min = spec.min || min;
    this.max = spec.max || max;
    this.minutes = spec.minutes || minutes;
    this.firstVisibleDay = spec.firstVisibleDay || firstVisibleDay;
    this.lastVisibleDay = spec.lastVisibleDay || lastVisibleDay;
    this.visibleDays = spec.visibleDays || visibleDays;

    this.getSlotDate = spec.getSlotDate || getSlotDate;
    this.getTimezoneOffset = spec.getTimezoneOffset || ((value: any) => value.getTimezoneOffset());
    this.getDstOffset = spec.getDstOffset || getDstOffset;
    this.getTotalMin = spec.getTotalMin || getTotalMin;
    this.getMinutesFromMidnight = spec.getMinutesFromMidnight || getMinutesFromMidnight;
    this.continuesPrior = spec.continuesPrior || continuesPrior;
    this.continuesAfter = spec.continuesAfter || continuesAfter;
    this.sortEvents = spec.sortEvents || sortEvents;
    this.inEventRange = spec.inEventRange || inEventRange;
    this.isSameDate = spec.isSameDate || isSameDate;
    this.startAndEndAreDateOnly = spec.startAndEndAreDateOnly || startAndEndAreDateOnly;
    this.segmentOffset = spec.browserTZOffset ? spec.browserTZOffset() : 0;

    // @ts-expect-error ts-migrate()
    this.format = (...args) => _format(this, spec.format, ...args);

    this.parse = (value, format, culture) => {
      const result = spec.parse.call(this, value, format, culture);

      return result;
    };
  }
}

let localizer = {
  parse: error,
  format: error,
  startOfWeek: error
};

export function set(newLocalizer: any) {
  if (!newLocalizer.__isLocalizer__) {
    // eslint-disable-next-line
    newLocalizer = new DateLocalizer(newLocalizer);
    // eslint-disable-next-line
    newLocalizer.__isLocalizer__ = true;
  }

  localizer = newLocalizer;
  return localizer;
}

export const exp = {
  parse(...args: any) {
    // @ts-expect-error ts-migrate()
    return localizer.parse(...args);
  },

  format(...args: any) {
    // @ts-expect-error ts-migrate()
    return localizer.format(...args);
  },

  startOfWeek(...args: any) {
    // @ts-expect-error ts-migrate()
    return localizer.startOfWeek(...args);
  }
};
