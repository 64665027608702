import loadChinese from './zh';
import loadDanish from './da';
import loadDutch from './nl';
import loadEnglish from './en';
import loadFrench from './fr';
import loadGerman from './de';
import loadItalian from './it';
import loadJapanese from './ja';
import loadKorean from './ko';
import loadPortuguese from './pt';
import loadRussian from './ru';
import loadSpanish from './es';
import loadListFormatAndLocaleData from './loadListFormatAndLocaleData';

/**
 * Loads the Intl polyfill if the browser does not implement it natively.
 * @param {function} cb - the callback function to execute after loading the polyfill.
 */
export function loadIntl(cb: any) {
  if (global.Intl) {
    cb();
    return;
  }
  require.ensure(['intl'], require => {
    require('intl');
    cb();
  });
}

/**
 * Loads Intl polyfill locale data for the given locale. Keep in mind you can always not
 * use this function and manually require.ensure any given locale that is available in the
 * Intl polyfill. This function is for convenience for our general use cases.
 * WARNING: Your build will create a chunk for every possible locale this function can load
 *  in order to allow async loading of locale data to work.
 * @param {string} locale - the locale to load data for.
 * @param {function} cb - the callback function to execute after loading the locale data.
 * @param {bool} usingBrowserLocale - flag indicating whether the provided locale was
 *  detected from the browser navigator object. If the provided locale is not supported,
 *  the browser locale is detected and we automatically try to load that with this flag
 *  set to true to avoid an infinite loop if we also don't support the browser locale.
 */
export function loadLocaleData(locale: any, cb: any, usingBrowserLocale = false) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'IntlPolyfill' does not exist on type 'Gl... Remove this comment to see the full error message
  if (!global.IntlPolyfill) {
    cb();
    return;
  }
  const localeTokens = locale.replace('_', '-').split('-');
  switch (localeTokens[0].toLowerCase()) {
    case 'zh':
      loadChinese(locale, cb);
      return;
    case 'da':
      loadDanish(locale, cb);
      return;
    case 'nl':
      loadDutch(locale, cb);
      return;
    case 'en':
      loadEnglish(locale, cb);
      return;
    case 'fr':
      loadFrench(locale, cb);
      return;
    case 'de':
      loadGerman(locale, cb);
      return;
    case 'it':
      loadItalian(locale, cb);
      return;
    case 'ja':
      loadJapanese(locale, cb);
      return;
    case 'ko':
      loadKorean(locale, cb);
      return;
    case 'pt':
      loadPortuguese(locale, cb);
      return;
    case 'ru':
      loadRussian(locale, cb);
      return;
    case 'es':
      loadSpanish(locale, cb);
      return;
    default: {
      // If the locale is not recognized, load the browser's recognized locale,
      // otherwise just load base english because we are out of options.
      if (!usingBrowserLocale && window && window.navigator) {
        const browserLocale =
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'userLanguage' does not exist on type 'Na... Remove this comment to see the full error message
          window.navigator.userLanguage || window.navigator.language;
        loadLocaleData(browserLocale, cb, true);
      } else {
        loadEnglish('en', cb);
      }
    }
  }
}

/**
 * Helper function to load Intl polyfill along with a set of initial locale data.
 * @param {string} locale - the locale to load data for.
 * @param {function} cb - the callback function to execute after loading the polyfill and locale data.
 */
export function loadIntlWithLocale(locale: any, cb: any) {
  if (!global.Intl) {
    loadIntl(() => {
      loadLocaleData(locale, () => {
        loadListFormatAndLocaleData(locale, cb);
      });
    });
    return;
  }
  loadListFormatAndLocaleData(locale, cb);
}
