import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

import { VideoTypes, VimeoEventUrlFormat } from '../../constants/Video';
import { renderRichText } from '../../utils/renderRichText';
import { getVideoIdFromUrl } from '../../utils/video/getVideoIdFromUrl';
import { getVideoTypeFromUrl } from '../../utils/video/getVideoTypeFromUrl';
import { BaseWidget } from '../BaseWidget';
import { EmbeddedFacebook } from './EmbeddedFacebook';
import { EmbeddedLiveStreamVideo } from './EmbeddedLiveStreamVideo';
import { EmbeddedTwitter } from './EmbeddedTwitter';
import { EmbeddedVimeoVideo } from './EmbeddedVimeoVideo';
import { EmbeddedWistiaVideo } from './EmbeddedWistiaVideo';
import { EmbeddedYoutubeVideo } from './EmbeddedYoutubeVideo';
import VideoStyle from './Video.less';
import { ThemeableComponentProps } from '../ThemeableComponent';
import videoPlaceholder from '../../../resources/images/video-placeholder.svg';
import { Placeholder } from '../../components/Placeholder/Placeholder';
import { LoadingSpinner } from '@cvent/carina/components/LoadingSpinner';
import { iterativeAsyncCall } from '../../utils/iterativeAsyncCall';

interface VideoWidgetProps extends ThemeableComponentProps {
  // Config required due to object destructuring in render code
  config: {
    [x: string | number | symbol]: unknown;
    title: string;
    description: string;
    videoUrl?: string;
  };
  id?: string;
  unsupportedVideoTypes?: VideoTypes[];
  recordVideoViewActivity?: ((...args: any[]) => any) | undefined;
  areCookiesAllowed?: boolean;
}

/**
 * The widget as used by the site editor and guestside
 */
export class VideoWidget extends BaseWidget<VideoWidgetProps> {
  state = {
    showSpinner: false,
    showPlaceholder: false
  };
  videoWrapperRef = React.createRef<HTMLDivElement>();

  getStyleObject() {
    return {
      ...super.getStyleObject(),
      titleContainer: this.getElementInlineStyle('titleContainer'),
      descriptionContainer: this.getElementInlineStyle('descriptionContainer'),
      videoContainer: this.getElementInlineStyle('videoContainer')
    };
  }

  pickVideoComponent(
    videoType: VideoTypes,
    videoId: string,
    translate: (p: string) => string,
    widgetId: string,
    recordVideoViewActivity: ((...args: any[]) => any) | undefined,
    videoUrl = ''
  ) {
    switch (videoType) {
      case VideoTypes.YOUTUBE:
        return (
          <EmbeddedYoutubeVideo
            videoId={videoId}
            translate={translate}
            widgetId={widgetId}
            recordVideoViewActivity={recordVideoViewActivity}
          />
        );
      case VideoTypes.WISTIA:
        return (
          <EmbeddedWistiaVideo
            videoId={videoId}
            recordVideoViewActivity={recordVideoViewActivity}
          />
        );
      case VideoTypes.VIMEO: {
        let isEvent = false;
        if (videoUrl.indexOf(VimeoEventUrlFormat) > -1) {
          isEvent = true;
        }
        return (
          <EmbeddedVimeoVideo
            videoId={videoId}
            translate={translate}
            isEvent={isEvent}
            recordVideoViewActivity={recordVideoViewActivity}
          />
        );
      }
      case VideoTypes.FACEBOOK:
        return (
          <EmbeddedFacebook videoPath={videoId} recordVideoViewActivity={recordVideoViewActivity} />
        );
      case VideoTypes.TWITTER:
        return (
          <EmbeddedTwitter videoPath={videoId} recordVideoViewActivity={recordVideoViewActivity} />
        );
      case VideoTypes.LIVESTREAM:
        return <EmbeddedLiveStreamVideo videoId={videoId} translate={translate} />;
      default:
    }
  }

  getEmbeddedVideoComponent(videoType: VideoTypes, videoId: string | undefined, widgetId: any) {
    if (videoId === undefined) {
      return null;
    }

    const {
      translate,
      config: { videoUrl },
      unsupportedVideoTypes,
      recordVideoViewActivity
    } = this.props;

    if (unsupportedVideoTypes && videoType && unsupportedVideoTypes.includes(videoType)) {
      return null;
    }

    return this.pickVideoComponent(
      videoType,
      videoId,
      translate,
      widgetId,
      recordVideoViewActivity,
      videoUrl
    );
  }

  private checkIfVideoAvailable = () => {
    if (this.videoWrapperRef?.current?.clientHeight === 0) {
      /* eslint-disable react/no-did-mount-set-state */
      this.setState({ showSpinner: true, showPlaceholder: false });
    }
    iterativeAsyncCall(10, 1000, async (_, done) => {
      const videoNotAvailable = this.videoWrapperRef?.current?.clientHeight === 0;
      if (videoNotAvailable && !done) {
        this.setState({ showSpinner: true, showPlaceholder: false });
        return false;
      }
      if (videoNotAvailable && done) {
        this.setState({ showSpinner: false, showPlaceholder: true });
        return false;
      }
      if (!videoNotAvailable) {
        this.setState({ showSpinner: false, showPlaceholder: false });
        return true;
      }
      return false;
    });
  };

  componentDidMount(): void {
    this.checkIfVideoAvailable();
  }

  componentDidUpdate(prevProps: Readonly<VideoWidgetProps>): void {
    if (prevProps.config.videoUrl !== this.props.config.videoUrl) {
      if (getVideoTypeFromUrl(this.props.config.videoUrl) !== VideoTypes.UNSUPPORTED)
        this.checkIfVideoAvailable();
    }
  }

  render() {
    const { title, description, videoUrl } = this.props.config;
    const { translate, style, id: widgetId, areCookiesAllowed } = this.props;
    const { showSpinner, showPlaceholder } = this.state;
    const videoType = getVideoTypeFromUrl(videoUrl);
    const videoId = getVideoIdFromUrl(videoType, videoUrl);
    const videoStyles = {
      classes: VideoStyle,
      style: this.getStyleObject()
    };

    // Do not render video widget if all cookies are not allowed on guestside.
    if (!areCookiesAllowed) {
      return null;
    }

    return (
      <div {...resolve(videoStyles, 'container')} {...resolveTestId(this.props)}>
        <div {...resolve(videoStyles, 'titleContainer')} {...resolveTestId(this.props)}>
          {translate(title)}
        </div>
        <div
          {...resolve(videoStyles, 'descriptionContainer')}
          {...resolveTestId(this.props)}
          dangerouslySetInnerHTML={{
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'header' does not exist on type '{ titleC... Remove this comment to see the full error message
            __html: renderRichText(description, translate, style.palette, videoStyles.style.header)
          }}
        />
        {showSpinner && videoType !== 0 && <LoadingSpinner size="m" />}
        {showPlaceholder && videoType !== 0 && (
          <Placeholder
            srcImage={videoPlaceholder}
            instUpper={translate('NucleusWidgets_Video_Placeholder__resx')}
            instLower={translate('NucleusWidgets_Video_Placeholder_description__resx')}
          />
        )}

        <div
          {...resolve(videoStyles, 'videoContainer')}
          {...resolveTestId(this.props)}
          id={`widget-video-${widgetId}`}
          ref={this.videoWrapperRef}
        >
          {!showPlaceholder && this.getEmbeddedVideoComponent(videoType, videoId, widgetId)}
        </div>
      </div>
    );
  }
}
