import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

import { addUrlDefaultProtocol } from '../../utils/addUrlDefaultProtocol';

type OwnProps = {
  text: string;
  url: string;
  linkTarget: string;
  enableHyperlink: boolean;
  // Used in resolve function // TODO: determine neccesary fields for classes
  classes?: Record<string, unknown>;
  // Used in resolve function
  style?: Record<string, unknown>;
};

type Props = OwnProps & typeof LinkButton.defaultProps;

export class LinkButton extends React.Component<Props> {
  static defaultProps = {
    linkTarget: '_self',
    enableHyperlink: true
  };

  render() {
    const { text, url, linkTarget, enableHyperlink } = this.props;
    return (
      <div {...resolve(this.props, 'container')} {...resolveTestId(this.props)}>
        <div {...resolve(this.props, 'buttonContainer')}>
          <a
            href={enableHyperlink ? addUrlDefaultProtocol(url) : undefined}
            target={linkTarget}
            {...resolve(this.props, 'button')}
          >
            {text}
          </a>
        </div>
      </div>
    );
  }
}
