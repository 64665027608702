import { VideoTypes } from '../../constants/Video';

export function getVideoTypeFromUrl(videoUrl: string | undefined = ''): VideoTypes {
  let res = VideoTypes.UNSUPPORTED;

  if (videoUrl.includes('wistia')) {
    res = VideoTypes.WISTIA;
  } else if (videoUrl.includes('youtube') || videoUrl.includes('youtu.be')) {
    res = VideoTypes.YOUTUBE;
  } else if (videoUrl.includes('vimeo')) {
    res = VideoTypes.VIMEO;
  } else if (videoUrl.includes('facebook') || videoUrl.includes('fb')) {
    res = VideoTypes.FACEBOOK;
  } else if (videoUrl.includes('twitter')) {
    res = VideoTypes.TWITTER;
  } else if (videoUrl.includes('livestream')) {
    res = VideoTypes.LIVESTREAM;
  }

  return res;
}
