import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import { sanitizeInnerHtml } from 'nucleus-text';

type OwnProps = {
  text?: string;
  shouldRenderHtml?: boolean;
  // Used in resolve function // TODO: determine neccesary fields for classes
  classes?: Record<string, unknown>;
  // Used in resolve function
  style?: Record<string, unknown>;
};

type Props = OwnProps & typeof Description.defaultProps;

/**
 * The basic description widget UI implementation.
 */
export class Description extends React.Component<Props> {
  static displayName = 'Description';
  static defaultProps = {
    shouldRenderHtml: true
  };
  render() {
    const { text, shouldRenderHtml } = this.props;
    return (
      <div {...resolveTestId(this.props)} {...resolve(this.props, 'container')}>
        <div {...resolve(this.props, 'text')}>
          {shouldRenderHtml ? (
            <div dangerouslySetInnerHTML={sanitizeInnerHtml(text)} />
          ) : (
            <p>{text}</p>
          )}
        </div>
      </div>
    );
  }
}
