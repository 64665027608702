/**
 * Returns current X,Y position of an Element. (element, offsetItem)
 *
 * @param {DOMElement} element Element you are getting the position of
 * @param {DOMElement} offsetByElement Optional Element to return offset of item position
 * @return {object} elementPosition Position of Element X/Y
 */
export function getElementPosition(element: any, offsetByElement: any) {
  const elementPosition: any = [];
  const elementRect = element.getBoundingClientRect();
  if (offsetByElement) {
    const offsetRect = offsetByElement.getBoundingClientRect();
    elementPosition.y = Math.round(elementRect.top - offsetRect.top);
    elementPosition.x = Math.round(elementRect.left - offsetRect.left);
  } else {
    elementPosition.y = Math.round(elementRect.top);
    elementPosition.x = Math.round(elementRect.left);
    elementPosition.bottom = elementRect.bottom;
    elementPosition.top = elementRect.top;
  }
  return elementPosition;
}
