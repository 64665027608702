import { createSelector } from 'reselect';

import { EditorTabTypes } from './EditorTabTypes';

/**
 * Functions in this file are site-editor specific.
 * They used to live under `nucleus-site-editor/src/redux/modules/editor`.
 */

/**
 * Gets the id of the page currently selected by the user.
 */
export function getSelectedPageId(state: any) {
  return state.pageId;
}

/**
 * Gets the list of currently selected item ids.
 */
export function getSelectedItemIds(editor: any) {
  return editor.editorPanelTabNavigation[EditorTabTypes.BUILD].selectedItemIds || [];
}

/**
 * Gets the selected item ids if it contains given id or return id wrapped in an array.
 * idTocheck - widget/section id to check in the selected items list.
 */
export function getSelectedItemIdsOrGivenId(editor: any, idTocheck: string) {
  const selectedItemIds = getSelectedItemIds(editor);
  return !idTocheck || selectedItemIds.includes(idTocheck) ? selectedItemIds : [idTocheck];
}

/**
 * @depprecated - Use getSelectedItemIds instead.
 * Gets the first selectedItemId even if there are multiple items selected.
 * This is here for backwards compatibility
 */
export function getSelectedItemId(state: any) {
  const ids = getSelectedItemIds(state);
  return ids.length ? ids[0] : null;
}

/** Returns true if given item id is selected, false otherwise. */
export function isSelectedItem(state: any, id: string) {
  const ids = getSelectedItemIds(state);
  return ids.some((i: any) => i === id);
}

/**
 * Gets the context editor navigation stack information.
 */
export const getSelectedEditorPanelTab = createSelector(
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  state => state.selectedEditorPanelTab,
  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
  state => state.editorPanelTabNavigation,
  (selectedEditorPanelTab, editorPanelTabNavigation) => {
    return editorPanelTabNavigation[selectedEditorPanelTab];
  }
);
