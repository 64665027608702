import merge from 'lodash/merge';
import { WebsiteV8 } from './WebsiteV8';

/**
 * Sets the default theme section for newly created sections by assigning the ID of the desired theme
 * section to `defaultThemeSectionId`.
 */
export function addDefaultThemeSectionId(website: WebsiteV8) {
  if (!website.theme.sections.mood.id || website.theme.global?.defaultThemeSectionId) {
    return website;
  }

  return merge({}, website, {
    theme: {
      global: {
        defaultThemeSectionId: website.theme.sections.mood.id
      }
    }
  });
}
