/* eslint react/prop-types: [2, { ignore: ["setFocus", "onChange", "onNativeChange", "fieldName", "id", "name"] }] */
/* eslint-disable react/no-multi-comp */
import React from 'react';

import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { injectTestId, resolveTestId } from '@cvent/nucleus-test-automation';
import { FormElement, WithFormProps, removeFormElementProps } from '../FormElement';
import { BreakPoint } from '../../buttons';

export function getSelectedValue(value: any, options: any, flattenOptions: any) {
  // Get the new selected value.
  let newValue;
  const valueIndexes = value.split('~');
  const newOptions = flattenOptions ? options : options.optionArray;
  if (valueIndexes.length > 1) {
    newValue = newOptions[valueIndexes[0]]?.items?.[valueIndexes[1]]?.value;
  } else {
    if (value === '-1') {
      newValue = null;
    } else {
      newValue = newOptions[valueIndexes[0]]?.value;
    }
  }
  return newValue !== undefined ? newValue : null;
}

/*
(ts-migrate) TODO: Migrate the remaining prop types
...FormElement.propTypes
*/
type OwnSelectProps = {
  options: any | any[];
  disabled?: boolean;
  breakPoint?: BreakPoint;
  style?: Record<string, unknown>;
  selectedValue?: any;
  ariaLabel?: string;
};

type SelectProps = OwnSelectProps & typeof Select.defaultProps;

/**
Display browser default select dropdown

onChange = function(fieldName, newValue, newOptions)
**/
export class Select extends React.Component<SelectProps> {
  static displayName = 'Select';
  static defaultProps = {
    required: false,
    disabled: false
  };

  select: any;

  constructor(props: SelectProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setFocus' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.setFocus && this.select) {
      this.select.focus();
    }
  }

  onChange(event: any) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const target = event.target ? event.target : window.event.srcElement;
    const newValue = getSelectedValue(
      target.value,
      this.props.options,
      this.props.hasOwnProperty('onNativeChange') && this.props.hasOwnProperty('selectedValue')
    );
    const newOptionList = Object.assign({}, this.props.options, { selectedValue: newValue });
    if (this.props.hasOwnProperty('onNativeChange') && this.props.hasOwnProperty('selectedValue')) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onNativeChange' does not exist on type '... Remove this comment to see the full error message
      this.props.onNativeChange(newValue);
    } else {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(this.props.fieldName, newValue, newOptionList);
    }
  }

  render() {
    const { options, selectedValue, ariaLabel, ...rest } = this.props;
    let groupList: any = [];
    let selectedIndex = -1;
    let selectedOptionValue: any;
    let optionsArray;
    if (this.props.hasOwnProperty('selectedValue')) {
      selectedOptionValue = selectedValue;
      optionsArray = options;
    } else {
      selectedOptionValue = options.selectedValue;
      optionsArray = options.optionArray;
    }
    const optionsList = optionsArray.map((option: any, index: any) => {
      if (option.hasOwnProperty('category')) {
        // when category is specified, render optgroup
        groupList = [];
        option.items.forEach((item: any, itemIndex: any) => {
          const indexValue = index.toString() + '~' + itemIndex.toString();
          if (isEqual(item.value, selectedOptionValue)) {
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
            selectedIndex = indexValue;
          }
          groupList.push(
            <option
              {...injectTestId(`option-${index}-${itemIndex}`)}
              {...(item.disabled && { disabled: true })}
              {...(item.ariaLabel && { 'aria-label': item.ariaLabel })}
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
              key={this.props.fieldName + index + itemIndex}
              value={indexValue}
            >
              {item.name}
            </option>
          );
        });
        return (
          <optgroup
            {...injectTestId(`option-group-${index}`)}
            label={option.category}
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
            key={this.props.fieldName + index}
          >
            {groupList}
          </optgroup>
        );
      }

      const indexValue = index.toString();
      if (isEqual(option.value, selectedOptionValue)) {
        selectedIndex = indexValue;
      }

      return (
        <option
          {...injectTestId(`option-${index}`)}
          {...(option.disabled && { disabled: true })}
          {...(option.ariaLabel && { 'aria-label': option.ariaLabel })}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
          key={this.props.fieldName + index}
          value={indexValue}
        >
          {option.name}
        </option>
      );
    });

    return (
      <select
        {...removeFormElementProps(rest)}
        {...resolveTestId(rest)}
        ref={c => (this.select = c)}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'Readonly<Sel... Remove this comment to see the full error message
        id={this.props.id || this.props.fieldName}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<S... Remove this comment to see the full error message
        name={this.props.name || this.props.fieldName}
        value={selectedIndex}
        onChange={this.onChange}
        aria-label={isNil(ariaLabel) ? '' : ariaLabel}
      >
        {optionsList}
      </select>
    );
  }
}

type SelectFormElementProps = WithFormProps & {
  children?: React.ReactNode;
  size?: string;
  breakPoint?: BreakPoint;
  style?: {
    select?: any;
  };
  errorMessages?: {
    [key: string]: string;
  };
};

/**
FormElement wrapper around Select. This is the default export.
**/
const SelectFormElement = (props: SelectFormElementProps) => {
  const { children, size = '', breakPoint, errorMessages = {}, ...rest } = props;
  const errorsPresent = Object.keys(errorMessages).length > 0;
  const validationState = errorsPresent && 'error';
  return (
    <FormElement errorMessages={errorMessages} {...rest}>
      <Select
        {...rest}
        {...resolve(props, 'select', size, breakPoint, validationState)}
        {...injectTestId('input')}
        style={{
          ...resolve(props, 'select', size, breakPoint, validationState).style,
          paddingRight: '1em'
        }}
      />
      {children}
    </FormElement>
  );
};
SelectFormElement.displayName = 'SelectFormElement';

/**
#### Data structure for the "options" property
a) Option List without optgroup

```js
  {
    selectedValue: 'optionValueA',
    optionArray: [
      {name: 'optionNameA', value: 'optionValueA'},
      {name: 'optionNameB', value: 'optionValueB'},
      ...
    ]
  }
```


b) Option List with optgroup

```js
  {
    selectedValue: 'optionValueA',
    optionArray: [
      {
        category: 'categoryNameA',
        items: [
          {name: 'optionNameA', value: 'optionValueA'},
          ...
        ]
      },
      {
        category: 'categoryNameB',
        items: [
          {name: 'optionNameB', value: 'optionValueB'},
          ...
        ]
      }
    ]
  }
```
**/

export { SelectFormElement };
