/**
 * Checks to see if TouchEvents are valid for Mobile items.
 * @return {boolean} - can the device support touch events.
 */
let isEnabled: any = null;
export function isTouchEnabled() {
  if (isEnabled === null) {
    if (
      typeof window !== 'undefined' &&
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'DocumentTouch' does not exist on type 'W... Remove this comment to see the full error message
      ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch))
    ) {
      isEnabled = true;
    } else {
      isEnabled = false;
    }
  }
  return isEnabled;
}
