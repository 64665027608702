import React from 'react';

type Props = {
  widget: any;
  layoutItems: any;
  activeDropzone: any;
  component?: (...args: any[]) => any;
};

function DropItem(props: Props) {
  const { widget, layoutItems, activeDropzone } = props;
  const parentRow = layoutItems[widget.layout.parentId];
  const parentContainer = layoutItems[parentRow.layout.parentId];
  return (
    // @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'props.component' does not have a... Remove this comment to see the full error message
    <props.component
      quadrant={activeDropzone.quadrant}
      canDrop={activeDropzone.canDrop.value}
      target={widget}
      parent={parentRow}
      grandparent={parentContainer}
    />
  );
}
DropItem.displayName = 'DropItem';

/**
 * Generates a drop item component if necessary and returns it
 * in an array with the provided widget content at the correct index
 * for proper rendering.
 */
export function addWidgetDropItem(widgetContent: any, props: any) {
  const { widget, activeDropzone } = props;
  const content = [widgetContent];
  if (activeDropzone && activeDropzone.id === widget.id) {
    // Because of Safari limitations, we cannot use the actual Column component,
    // we just need a base div with the flex style and relative position so that
    // the DropPreview can properly position and size itself.
    const dropItem = (
      <div key={`${widget.id}-${activeDropzone.quadrant}`} style={{ position: 'relative' }}>
        <DropItem {...props} />
      </div>
    );
    if (activeDropzone.quadrant === 'left') {
      content.unshift(dropItem);
    } else if (activeDropzone.quadrant === 'right') {
      content.push(dropItem);
    }
  }
  return content;
}
