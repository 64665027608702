import { DATA_TAG_REG_EXP as DatatagRegex } from 'nucleus-text';

/**
 * Common function to auto append a default protocol on a user entered URL field if they did not provide one.
 * Specifically, we allow users to use http://, https://, //, mailto:, or make the URL just be string in the datatag
 * format. Otherwise, we will auto append http:// to the string.
 * @param {string} url
 */
export function addUrlDefaultProtocol(url: any) {
  if (!url || !(typeof url === 'string' || url instanceof String)) {
    return undefined;
  }

  const trimmedUrl = url.trim();
  if (!trimmedUrl) {
    return undefined;
  }

  // Check if the url is a datatag. Datatags should already fully resolve with a protocol.
  const regex = DatatagRegex;
  regex.lastIndex = 0;
  const match = regex.exec(trimmedUrl);
  if (match !== null && trimmedUrl.length === match[0].length) {
    return trimmedUrl;
  }

  // Allow mailto links.
  if (trimmedUrl.startsWith('mailto:')) {
    return trimmedUrl;
  }

  // Allow http or https web links.
  if (trimmedUrl.startsWith('http://') || trimmedUrl.startsWith('https://')) {
    return trimmedUrl;
  }

  // Allow relative protocol links.
  if (trimmedUrl.startsWith('//')) {
    return trimmedUrl;
  }

  // Add the default protocol since there isnt one. Ideally this would be https, but this was originally
  // implemented as http and changing it could potentially break client websites.
  return `http://${trimmedUrl}`;
}
