import filtersMigrations from './filtersMigrations';
import monthViewMigrations from './monthViewMigrations';

/**
 * migration for website layout items
 */
export default function layoutItemMigrations(layoutItem: $TSFixMe) {
  if (layoutItem.layout.type !== 'widget') {
    return layoutItem;
  }
  switch (layoutItem.widgetType) {
    // migration for default calendar view configuration
    case 'CalendarList': {
      // Add filters styling elements if not present
      let newLayoutItem = filtersMigrations(layoutItem);
      // Add month view styling elements if not present
      newLayoutItem = monthViewMigrations(newLayoutItem);
      // set viewFormat if not present
      if (!layoutItem.config.viewFormat) {
        return {
          ...newLayoutItem,
          config: {
            ...newLayoutItem.config,
            viewFormat: 'listView'
          }
        };
      }
      return newLayoutItem;
    }
    default:
      return layoutItem;
  }
}
