import mapValues from 'lodash/mapValues';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import { createSelector, createSelectorCreator, defaultMemoize } from 'reselect';

import { evaluateFunctionOrValue } from '../utils/evaluateFunctionOrValue';
import { getJSONValue } from '../utils/fields/getJSONValue';
import { getLocalizedSharedData, getLocalizedAppData } from '../utils/getLocalizedUserText';

/**
 * Map shared and appData values into a widget's config
 */
export function withMappedWidgetConfig(component: any) {
  return connect(() => {
    const selectResolvedAppDataPaths = createSelectorCreator(defaultMemoize, isEqual)(
      (state: any, rawConfig: any, type: any, widgetId: any) => {
        const { appDataFieldPaths } = state.widgetFactory.loadMetaData(type);
        if (!appDataFieldPaths) {
          return {};
        }
        return mapValues(appDataFieldPaths, path =>
          evaluateFunctionOrValue(path, state, rawConfig, widgetId)
        );
      },
      paths => paths
    );

    const selectAppData = createSelector(
      selectResolvedAppDataPaths,
      state => state.appData,
      state => state.localizedUserText,
      (appDataFieldPaths, appData, localizedUserText) =>
        mapValues(appDataFieldPaths, path =>
          getLocalizedAppData({
            baseText: getJSONValue(appData, path),
            localizedUserText,
            path
          })
        )
    );

    const selectSharedData = createSelector(
      (state: any, type: any) => state.website.siteInfo.sharedConfigs[type],
      (state: any, type: any) => type,
      state => state.localizedUserText,
      (baseText, type, localizedUserText) =>
        getLocalizedSharedData({
          baseText,
          localizedUserText,
          widgetType: type
        })
    );

    return createSelector(
      (state: any, props: any) => props.config,
      (state: any, props: any) => selectSharedData(state, props.type),
      (state: any, props: any) => selectAppData(state, props.config, props.type, props.id),
      (rawConfig, shared, appData) => {
        return {
          config: {
            ...rawConfig,
            shared: {
              ...(rawConfig.shared || {}),
              ...shared
            },
            appData
          }
        };
      }
    );
  })(component);
}
