import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';

import { KEY_RETURN, KEY_SPACE } from '../utils/keyCodes';
import { removeKeys } from '../utils/removeKeys';

type OwnProps = {
  onClick?: (...args: any[]) => any;
  onKeyDown?: (...args: any[]) => any;
  element?: string;
  role?: string;
  isDisabled?: boolean;
} & React.HTMLAttributes<any>;

type Props = React.PropsWithChildren<OwnProps & typeof InteractiveElement.defaultProps>;

/**
A wrapper component to add "enter" keydown event and focusable state to non-interactive elements,
such as div, span, and li.

NOTE: When choosing interacive elements to use, button (or Button component from this library)
should be considered first, before landing on this component.
**/

export class InteractiveElement extends React.Component<Props> {
  static displayName = 'InteractiveElement';
  static defaultProps = {
    tabIndex: 0,
    element: 'div',
    role: 'button'
  };

  element: any;

  constructor(props: Props) {
    super(props);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.focus = this.focus.bind(this);
  }

  focus() {
    if (this.element) this.element.focus();
  }

  handleKeyDown(e: any) {
    if ((e.keyCode === KEY_RETURN || e.keyCode === KEY_SPACE) && this.props.onClick) {
      this.props.onClick(e);
      if (e.keyCode === KEY_SPACE || e.keyCode === KEY_RETURN) {
        e.preventDefault();
      }
    }
  }

  render() {
    const { children, onKeyDown, role, isDisabled, ...rest } = removeKeys(this.props, ['element']);
    const newTabIndex = isDisabled ? null : 0;
    return (
      // @ts-expect-error ts-migrate()
      <this.props.element
        {...resolveTestId(this.props)}
        {...rest}
        role={role}
        ref={(c: any) => (this.element = c)}
        onKeyDown={onKeyDown || this.handleKeyDown}
        tabIndex={newTabIndex}
        disabled={isDisabled}
      >
        {children}
      </this.props.element>
    );
  }
}
