import React from 'react';

import { resolve, select } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

import { CircleCounter } from './CircleCounter';
import { FlipCounter } from './FlipCounter';
import { TextCounter } from './TextCounter';

type OwnProps = {
  endDate: any; // TODO: PropTypes.instanceOf(Date)
  showDays?: boolean;
  daysLabel?: string;
  showHours?: boolean;
  hoursLabel?: string;
  showMinutes?: boolean;
  minutesLabel?: string;
  showSeconds?: boolean;
  secondsLabel?: string;
  type?: 'circle' | 'flip' | 'text';
  style?: {
    countdown?: any;
    circle?: any;
    flip?: any;
    text?: any;
  };
  classes?: any;
};

type State = any;

type Props = OwnProps & typeof Countdown.defaultProps;

/**
# Countdown Component
**/
export class Countdown extends React.Component<Props, State> {
  static displayName = 'Countdown';
  static defaultProps = {
    showDays: true,
    showHours: true,
    showMinutes: true,
    showSeconds: true,
    type: 'circle'
  };
  countdownTimer: any;
  constructor(props: Props) {
    super(props);
    this.getCoundownStateFromEndDate = this.getCoundownStateFromEndDate.bind(this);
    this.clearCountdown = this.clearCountdown.bind(this);
    this.startCountdown = this.startCountdown.bind(this);
    this.state = this.getCoundownStateFromEndDate(props.endDate);
    this.countdownTimer = null;
  }
  componentDidMount() {
    this.startCountdown();
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    if (nextProps.endDate.getTime() !== this.props.endDate.getTime()) {
      this.clearCountdown();
      this.setState(this.getCoundownStateFromEndDate(nextProps.endDate));
      this.startCountdown();
    }
  }
  componentWillUnmount() {
    this.clearCountdown();
  }
  getCoundownStateFromEndDate(endDate: any) {
    if (endDate <= new Date()) {
      return {
        daysLeft: 0,
        hoursLeft: 0,
        minutesLeft: 0,
        secondsLeft: 0,
        millisecondsLeft: 0,
        inProgress: false
      };
    }
    let milliseconds = endDate.getTime() - new Date().getTime();
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    const days = parseInt(milliseconds / 86400000, 10);
    milliseconds = milliseconds % 86400000;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    const hours = parseInt(milliseconds / 3600000, 10);
    milliseconds = milliseconds % 3600000;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    const minutes = parseInt(milliseconds / 60000, 10);
    milliseconds = milliseconds % 60000;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    const seconds = parseInt(milliseconds / 1000, 10);
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    milliseconds = parseInt(milliseconds % 1000, 10);
    return {
      daysLeft: days,
      hoursLeft: hours,
      minutesLeft: minutes,
      secondsLeft: seconds,
      millisecondsLeft: milliseconds,
      inProgress: true
    };
  }
  clearCountdown() {
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer);
      this.countdownTimer = null;
    }
  }
  startCountdown() {
    if (this.state.inProgress) {
      setTimeout(() => {
        this.setState(this.getCoundownStateFromEndDate(this.props.endDate));
        this.countdownTimer = setInterval(() => {
          if (this.state.inProgress) {
            this.setState(this.getCoundownStateFromEndDate(this.props.endDate));
          } else {
            clearInterval(this.countdownTimer);
            this.countdownTimer = null;
          }
        }, 1000);
      }, this.state.millisecondsLeft);
    }
  }
  render() {
    let CounterComponent;
    switch (this.props.type) {
      case 'circle':
        CounterComponent = CircleCounter;
        break;
      case 'flip':
        CounterComponent = FlipCounter;
        break;
      case 'text':
        CounterComponent = TextCounter;
        break;
      default:
        throw new Error('Provided countdown type is not supported: ' + this.props.type);
    }
    return (
      <ul {...resolveTestId(this.props)} {...resolve(this.props, 'countdown')}>
        {this.props.showDays ? (
          <CounterComponent
            {...select(this.props, this.props.type)}
            label={this.props.daysLabel}
            digit={this.state.daysLeft}
            total={30}
            type="days"
          />
        ) : null}
        {this.props.showHours ? (
          <CounterComponent
            {...select(this.props, this.props.type)}
            label={this.props.hoursLabel}
            digit={this.state.hoursLeft}
            total={24}
            type="hours"
          />
        ) : null}
        {this.props.showMinutes ? (
          <CounterComponent
            {...select(this.props, this.props.type)}
            label={this.props.minutesLabel}
            digit={this.state.minutesLeft}
            total={60}
            type="minutes"
          />
        ) : null}
        {this.props.showSeconds ? (
          <CounterComponent
            {...select(this.props, this.props.type)}
            label={this.props.secondsLabel}
            digit={this.state.secondsLeft}
            total={60}
            type="seconds"
          />
        ) : null}
      </ul>
    );
  }
}
