import React from 'react';

import PropTypes from 'prop-types';

import GridStyles from './styles/grid.less';

type Props = React.PropsWithChildren<typeof Grid.defaultProps>;

/**
 * Top level component of the Grid layout.
 */
class Grid extends React.Component<Props> {
  static displayName = 'Grid';
  static childContextTypes = {
    /** a bootstrap grid system  **/
    grid: PropTypes.object
  };
  static defaultProps = {
    grid: GridStyles
  };

  constructor(props: any) {
    super(props);
  }
  getChildContext() {
    return { grid: this.props.grid };
  }
  render() {
    return this.props.children;
  }
}

export { Grid };
