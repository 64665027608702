/**
 * Do an iterative async call, given the time (delay) and the max iteration you want on doing/checking something in the callback.
 * @param delay The time (delay) in milliseconds that you want to wait.
 * @param maxIteration The maximum number of iterations you want to perform on the callback, given the delay.
 * @param callback The action you want to perform by the maximum number of iterations, given the delay.
 */
export async function iterativeAsyncCall(
  delay: number,
  maxIteration: number,
  callback: (iteration: number, done: boolean) => Promise<boolean>
) {
  let currentIteration = 0;
  const handler = setInterval(async () => {
    currentIteration++;
    const done = currentIteration >= maxIteration;
    const fulfilled = await callback(currentIteration, done);
    if (done || fulfilled) {
      clearInterval(handler);
    }
  }, delay);
}
