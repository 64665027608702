import React from 'react';

import { BlockTypes } from '@cvent/rich-text-renderer';
import { resolveTestId } from '@cvent/nucleus-test-automation';

import { getImageFromLookup } from '../../utils/getImageFromLookup';
import { renderRichText } from '../../utils/renderRichText';
import { BaseWidget } from '../BaseWidget';
import { Image } from './Image';
import ClassNames from './Image.less';
import { ThemeableComponentProps } from '../ThemeableComponent';

type BlockTypes =
  | 'unstyled'
  | 'paragraph'
  | 'header-one'
  | 'header-two'
  | 'header-three'
  | 'header-four'
  | 'header-five'
  | 'header-six';
interface ImageWidgetProps extends ThemeableComponentProps {
  type?: string;
  breakpoints?: Array<number>;
  config?: {
    asset?: Record<string, unknown>;
    altText?: string;
    hyperlink?: string;
    description?: Record<string, unknown> | string;
    decorativeImage?: boolean;
    baseBlockType: BlockTypes;
  };
  classes?: Record<string, unknown>;
  enableHyperlink?: boolean;
  onLoad?: (...args: any[]) => any;
}
/**
 * Data wrapper for the Image widget.
 */
export class ImageWidget extends BaseWidget<ImageWidgetProps> {
  static displayName = 'ImageWidget';
  static defaultProps: any = {
    classes: ClassNames,
    breakpoints: [320, 480, 768, 992]
  };
  getImageContainerAlignment(textAlignValue: any) {
    // Map textAlign values to respective flex variants
    const alignMapping = {
      left: 'flex-start',
      right: 'flex-end',
      center: 'center',
      justify: 'space-between'
    };
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return alignMapping[textAlignValue];
  }
  getStyleObject(): any {
    // The style set in the canvas for the image alignment actually needs to be applied to the container.
    // There is no textAlign style option for the container, so we can just merge the alignment style in here.
    return {
      container: {
        ...super.getStyleObject().container,
        justifyContent: this.getImageContainerAlignment(this.getElementText('image').textAlign)
      },
      image: {
        ...this.getElementText('image'),
        borderRadius: `${this.getElementBackground('image').borderRadius}%`
      },
      imageText: this.getElementInlineStyle('imageText')
    };
  }
  render() {
    const {
      classes,
      config,
      translate,
      enableHyperlink,
      style,
      imageLookup,
      onLoad,
      breakpoints,
      browserFeatures: { supportsWebp } = {}
    } = this.props;
    const styleObject = this.getStyleObject();
    const imageUrl = config?.asset ? config.asset.assetUri : '';
    const { hashedURL: url, width } = getImageFromLookup(imageLookup, translate(imageUrl));
    return (
      <Image
        {...resolveTestId(this.props)}
        {...(onLoad ? { onLoad } : {})}
        classes={classes}
        style={styleObject}
        imgUrl={url}
        altText={config?.decorativeImage ? null : config?.altText}
        width={width}
        breakpoints={breakpoints}
        // @ts-expect-error ts-migrate(2322) FIXME: boolean type not assignable to string
        hyperlink={enableHyperlink && config?.hyperlink}
        description={renderRichText(
          config?.description,
          translate,
          style?.palette,
          styleObject.imageText,
          { baseBlockType: config?.baseBlockType }
        )}
        translate={translate}
        supportsWebp={supportsWebp}
      />
    );
  }
}
