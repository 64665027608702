import { defaultFactProvider } from 'nucleus-analytics-middleware';
import { createFactProvider as createFactProviderBase } from 'nucleus-widgets';

import { factProvider } from './factProvider';

function baseFactProvider(prevState: any, action: any, nextState: any) {
  const defaults = defaultFactProvider(prevState, action);
  if (!defaults) {
    return null;
  }
  return {
    location: defaults.location,
    device_id: defaults.deviceId,
    ts: defaults.ts
  };
}

export function createFactProvider(config: any) {
  return createFactProviderBase(config, baseFactProvider, factProvider);
}
