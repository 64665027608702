import React from 'react';

import { CustomRheostat } from './CustomRheostat';
import { Handle } from './Handle';

type Props = {
  max?: number;
  min?: number;
  values?: number[] | null;
  algorithm?: {
    getValue?: (...args: any[]) => any;
    getPosition?: (...args: any[]) => any;
  };
  disabled?: boolean;
  handle?: ((...args: any[]) => any) | string;
  progressBar?: ((...args: any[]) => any) | string;
  pitComponent?: ((...args: any[]) => any) | string;
  pitPoints?: number[];
  /** A boolean which controls the slider's snapping behavior. */
  snap?: boolean;
  /** An array of values on the slider where the slider should snap to.
   * If snap is set to true and no snapPoints are set then the slider is snapped into an absolute position.
   * For example, on a scale of 1-10 if the slider is let go at the 54% mark it'll pick the value 5 and snap to 50%. */
  snapPoints?: number[];
  orientation?: 'horizontal' | 'vertical';
  onChange?: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;
  onKeyPress?: (...args: any[]) => any;
  onSliderDragEnd?: (...args: any[]) => any;
  onSliderDragMove?: (...args: any[]) => any;
  onSliderDragStart?: (...args: any[]) => any;
  onValuesUpdated?: (...args: any[]) => any;
  className?: string;
  classes: any;
  style?: {
    container?: any;
    slider?: any;
    bar?: any;
    progressBar?: any;
    handle?: any;
    pit?: any;
  };
};

/**
 * Slider component with better accessibility supports
 */
export const Slider: React.FC<Props> = ({
  min = 0,
  max = 100,
  values = [1],
  orientation = 'horizontal',
  className = 'slider',
  snap = false,
  disabled = false,
  handle = Handle,
  ...rest
}: Props) => (
  <CustomRheostat
    min={min}
    max={max}
    values={values}
    orientation={orientation}
    className={className}
    snap={snap}
    disabled={disabled}
    handle={handle}
    {...rest}
  />
);
Slider.displayName = 'Slider';
