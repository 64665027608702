export enum VideoTypes {
  UNSUPPORTED,
  YOUTUBE,
  WISTIA,
  VIMEO,
  FACEBOOK,
  TWITTER,
  LIVESTREAM
}

export const VimeoEventUrlFormat = 'vimeo.com/event/';
