import Logger from '@cvent/nucleus-logging';
const LOG = new Logger('nucleus-widgets/utils/safeHtmlDecode');

/**
 * Safely decode html entities in the given string input. Any HTML tags in the input string will be removed, only
 * text content will be returned.
 * If the browser does not have DOMParser or parseFromString (some older browsers we dont officially support dont),
 * this will just return the input string.
 */
export function safeHtmlDecode(input: any) {
  if (!DOMParser) {
    LOG.error('Current browser does not support DOMParser. Text will not be HTML decoded.');
    return input;
  }

  const parser = new DOMParser();
  if (!parser || !parser.parseFromString) {
    LOG.error(
      'Current browser does not support DOMParser.parseFromString. Text will not be HTML decoded.'
    );
    return input;
  }

  const document = parser.parseFromString(input, 'text/html');
  return document.documentElement.textContent;
}
