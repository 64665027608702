import { actionReporterMiddleware, mockAnalyticsReporter } from 'nucleus-analytics-middleware';
import { compose, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';

import { createFactProvider } from './analytics/createFactProvider';

/**
 * Function to create the store for a site editor application.
 */
export function devCreateStoreWithMiddleware(
  reducer: any,
  initialState: any,
  {
    analyticsConfig = { enabled: false, appId: '' },
    enableDevTools = true,
    optionalMiddleware = [],
    optionalEnhancers = [],
    thunkExtraArgument
  }: any = {}
) {
  // Set up the internal middleware according to the passed in configuration.
  const internalMiddleware = [
    thunkExtraArgument !== undefined
      ? thunkMiddleware.withExtraArgument(thunkExtraArgument)
      : thunkMiddleware
  ];
  if (analyticsConfig && analyticsConfig.enabled && analyticsConfig.appId) {
    internalMiddleware.unshift(
      actionReporterMiddleware({
        factProvider: createFactProvider(analyticsConfig),
        analyticsReporter: mockAnalyticsReporter()
      })
    );
  }
  // Set up the internal enhancers according to the passed in configuration.
  const internalEnhancers = [];
  if (enableDevTools && (window as any).__REDUX_DEVTOOLS_EXTENSION__) {
    internalEnhancers.push((window as any).__REDUX_DEVTOOLS_EXTENSION__());
  }

  // Create the store, applying our internal middleware, any optionally passed in
  // middleware, any optionally passed in enhancers, and our internal enhancers.
  const getStore = compose(
    applyMiddleware(...internalMiddleware, ...optionalMiddleware),
    ...optionalEnhancers,
    ...internalEnhancers
  )(createStore);

  return getStore(reducer, initialState);
}
