import React from 'react';

import isFinite from 'lodash/isFinite';
import { resolve, select } from '@cvent/nucleus-dynamic-css';
import { CloseDeleteFilledIcon } from '@cvent/nucleus-icon';
import Logger from '@cvent/nucleus-logging';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import ReactDOM from 'react-dom';

import { ButtonGroup } from '../buttons/ButtonGroup';
import { DragContainer } from '../containers/DragContainer';
import { DragHandle } from '../containers/DragHandle';
import { FocusTrap } from '../containers/FocusTrap';
import { InteractiveElement } from '../containers/InteractiveElement';
import { Transition } from '../containers/Transition';
import { tapOrClick } from '../touchEventHandlers';
import { centerElement } from '../utils/centerElement';
import { isTouchEnabled } from '../utils/isTouchEnabled';

const LOG = new Logger('Dialog');

const flexInlineStyle = {
  display: 'flex',
  flexDirection: 'column'
};

export function requiredWhenOpen(props: any, propName: any, componentName: any) {
  if (props.isOpen && !props[propName]) {
    return new Error(propName + ' in ' + componentName + ' is required when Dialog is Open');
  }
  return null;
}

type OwnProps = React.PropsWithChildren<{
  dialogId?: string;
  header?: string | React.ReactNode;
  actions?: any[];
  initialLeft?: number;
  initialTop?: number;
  actionAlignment?: 'left' | 'right' | 'center';
  isModal?: boolean;
  isFullScreen?: boolean;
  isAnimatable?: boolean;
  allowDragging?: boolean;
  isOpen: boolean;
  isFixedHeader?: boolean;
  requestClose?: (...args: any[]) => any;
  closeDialogOnOverlayClick?: boolean;
  closeLabel?: string;
  closeFallback?: string;
  defaultTimeout?: number;
  ariaLabel?: string;
  disableTabIndex?: boolean;
  disableFocusTrap?: boolean;
  classes?: Record<string, string> | { transition: Record<string, string> };
  style?: {
    content?: any;
    placeholder?: any;
    empty?: any;
    transitionWrapper?: any;
    header?: any;
    exit?: any;
    overlay?: any;
    actions?: any;
  };
  scrollPageToTop?: boolean;
}>;

type State = any;

type Props = React.PropsWithChildren<OwnProps & typeof Dialog.defaultProps>;

/**
  The Dialog Component uses the isOpen property to display the child content, if animatiable it displays inside of the
  CSS Transition Component. requestClose is a required function that tells the parent application the Dialogs intent to
  close and should be managed inside parent application.
**/
export class Dialog extends React.Component<Props, State> {
  static displayName = 'Dialog';
  static defaultProps = {
    isModal: true,
    isFullScreen: false,
    isAnimatable: true,
    isFixedHeader: false,
    allowDragging: false,
    defaultTimeout: 500,
    actionAlignment: 'center',
    closeFallback: 'close'
  };

  _closeOverlayTimeout: any;
  _isMounted: any;
  bodyScrollTop: any;
  bodyWidth: any;
  saveBodyWidthAndScrollPosition: any;
  selectItem: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      overlay: props.isOpen
    };
    this.centerDialog = this.centerDialog.bind(this);
    this.requestClose = this.requestClose.bind(this);
    this.saveBodyWidthAndScrollPosition = this.saveScrollPosition.bind(this);
    this.preventBodyScroll = this.preventBodyScroll.bind(this);
    this.restoreBodyScroll = this.restoreBodyScroll.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onOverlayClick = this.onOverlayClick.bind(this);
    this.cancelClick = this.cancelClick.bind(this);
    this.bodyScrollTop = 0;
    this.bodyWidth = '100%';
    this._isMounted = false;
    this._closeOverlayTimeout = null;
  }

  UNSAFE_componentWillMount() {
    this.saveScrollPosition();
    if (this.props.isOpen) {
      this.preventBodyScroll();
      this.setState({ overlay: true });
    }
  }
  componentDidMount() {
    // Center dialog window based on browser size and add listender for resizing of browser.
    this._isMounted = true;
    this.centerDialog();
  }
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // Check to see if new Props were passed in, and change state.overlay accoridingly.
    if (!this.props.isOpen && nextProps.isOpen) {
      this.preventBodyScroll();
      clearTimeout(this._closeOverlayTimeout);
      this.setState({ overlay: true });
    } else if (this.props.isOpen && !nextProps.isOpen) {
      if (window.removeEventListener) {
        window.removeEventListener('resize', this.centerDialog);
      }
      this.restoreBodyScroll();
      // if it's not animated then just change state for now
      if (!this.props.isAnimatable) {
        this.setState({ overlay: false });
      } else {
        // Allow time for transition to perform leave, then change state
        this._closeOverlayTimeout = setTimeout(() => {
          if (this._isMounted) {
            this.setState({ overlay: false });
          }
        }, nextProps.defaultTimeout);
      }
    }
  }
  // Center dialog window based on browser size and add listener for resizing of browser.
  // setTimeout is added to let the content inside the dialog finish rendering so dialog could adjust its height,
  // when max-height, not height, is applied to the content of the dialog.
  componentDidUpdate() {
    setTimeout(() => {
      this.centerDialog();
    }, 0);
  }

  componentWillUnmount() {
    if (window.removeEventListener) {
      window.removeEventListener('resize', this.centerDialog);
    }
    this.restoreBodyScroll();
    this._isMounted = false;
  }

  onKeyUp(event: any) {
    // Inform the parent of close request on escape key press.
    if (event.keyCode === 27) {
      // If this component is responding to the escape key press, no parent component should also respond to it.
      event.preventDefault();
      event.stopPropagation();
      this.requestClose(event);
    }
  }

  centerDialog() {
    if (this.props.initialLeft || this.props.initialTop) {
      return;
    }
    if (this.props.isOpen && ReactDOM.findDOMNode(this.refs.dialog as any)) {
      centerElement(ReactDOM.findDOMNode(this.refs.dialog as any));
    }
    if (!this.props.allowDragging) {
      if (typeof window !== 'undefined' && window.addEventListener) {
        window.addEventListener('resize', this.centerDialog);
      }
    }
  }

  saveScrollPosition() {
    if (typeof document !== 'undefined') {
      const topLevelElement = document.documentElement || document.body.parentNode || document.body;
      this.bodyScrollTop = this.props.scrollPageToTop ? 0 : topLevelElement.scrollTop;
      this.bodyWidth = topLevelElement.style.width;
    }
  }

  preventBodyScroll() {
    if (typeof document !== 'undefined') {
      const topLevelElement = document.documentElement || document.body.parentNode || document.body;
      this.saveScrollPosition();
      if (this.props.isModal) {
        topLevelElement.style.overflow = 'hidden';
        if (isTouchEnabled()) {
          topLevelElement.style.position = 'fixed';
          topLevelElement.style.top = isFinite(this.bodyScrollTop)
            ? `-${this.bodyScrollTop}px`
            : '0';
        }
      }
    }
  }

  restoreBodyScroll() {
    if (typeof document !== 'undefined') {
      const topLevelElement = document.documentElement || document.body.parentNode || document.body;
      if (this.props.isModal) {
        topLevelElement.style.overflow = 'visible';
        if (isTouchEnabled()) {
          topLevelElement.style.position = 'static';
          topLevelElement.style.top = '0';
        }
      }
      topLevelElement.style.width = this.bodyWidth;
      topLevelElement.scrollTop = this.bodyScrollTop;
    }
  }

  requestClose(event: any) {
    event.preventDefault();
    if (this.props.requestClose) {
      this.props.requestClose(this.props.dialogId);
    }
  }

  onOverlayClick() {
    if (this.props.requestClose && this.props.closeDialogOnOverlayClick) {
      this.props.requestClose(this.props.dialogId);
    }
  }

  cancelClick(e: any) {
    // stop click event inside the dialog from bubbling up and closing the dialog
    if (this.props.closeDialogOnOverlayClick) {
      e.stopPropagation();
    }
  }

  render() {
    const {
      classes = {},
      style = {},
      isModal,
      isAnimatable,
      isFullScreen,
      isOpen,
      closeLabel,
      closeFallback,
      ariaLabel,
      isFixedHeader
    } = this.props;

    let header;
    const headerRole = this.props.header ? null : 'none';
    const fixedHeaderInlineStyle = isFixedHeader ? flexInlineStyle : null;
    if (typeof this.props.header === 'string') {
      header = (
        // @ts-expect-error ts-migrate(2559) FIXME: Type '{ content?: any; placeholder?: any; empty?: ... Remove this comment to see the full error message
        <DragHandle classes={classes} style={style}>
          <h2
            {...resolve(this.props, 'header')}
            {...resolveTestId(this.props, '-header')}
            role={headerRole}
          >
            {this.props.header}
            {this.props.requestClose && (
              <InteractiveElement
                {...resolve(this.props, 'exit')}
                onClick={this.requestClose}
                {...tapOrClick(this.selectItem)}
                {...resolveTestId(this.props, '-close-button')}
              >
                {closeLabel || null}
                <CloseDeleteFilledIcon fallbackText={closeFallback} />
              </InteractiveElement>
            )}
          </h2>
        </DragHandle>
      );
    } else {
      header = (
        // @ts-expect-error ts-migrate(2559) FIXME: Type '{ content?: any; placeholder?: any; empty?: ... Remove this comment to see the full error message
        <DragHandle classes={classes} style={style}>
          {this.props.header}
        </DragHandle>
      );
    }

    let actions;
    if (this.props.actions) {
      actions = (
        <ButtonGroup {...select(this.props, 'actions')} alignment={this.props.actionAlignment}>
          {this.props.actions}
        </ButtonGroup>
      );
    }

    const content = (
      <div {...resolveTestId(this.props, '-content')} {...resolve(this.props, 'content')}>
        {this.props.children || <div />}
      </div>
    );

    const screenSizeStyles = resolve(
      this.props,
      'dragContainer',
      isFullScreen ? 'fullScreen' : null
    );
    const dialogClasses = {
      ...classes,
      dragContainer: screenSizeStyles.className
    };
    const dialogInline = {
      ...style,
      dragContainer: {
        ...screenSizeStyles.style,
        ...fixedHeaderInlineStyle
      }
    };

    const dialogContent = (
      <FocusTrap
        onKeyUp={this.onKeyUp}
        focusOnMount={!isAnimatable}
        role="dialog"
        aria-label={ariaLabel ?? 'Dialog'}
        onClick={this.cancelClick}
        {...(this.props.disableTabIndex ? { disableTabIndex: true } : {})}
        disableFocus={this.props.disableFocusTrap}
      >
        <DragContainer
          ref="dialog"
          centerOnMount={!(this.props.initialLeft || this.props.initialTop)}
          allowDragging={this.props.allowDragging}
          initialLeft={this.props.initialLeft}
          initialTop={this.props.initialTop}
          classes={dialogClasses}
          style={dialogInline}
        >
          {header}
          {content}
          {actions}
        </DragContainer>
      </FocusTrap>
    );

    let transitionStyles = select(this.props, 'transition');
    if (
      isAnimatable &&
      !Object.keys(transitionStyles.classes).length &&
      !Object.keys(transitionStyles.style).length
    ) {
      LOG.warn('This feature has been deprecated...');
      LOG.warn('Css transitions are being moved out of the core LESS file.');
      LOG.warn('Please see Nucleus Tesbed Dialogs Demo for updated implementation.');
      transitionStyles = {
        classes: this.props.classes,
        style: this.props.style
      };
    }
    const dialogOverlay = <div {...resolve(this.props, 'overlay')} />;
    return (
      // eslint-disable-next-line
      <div
        {...resolveTestId(this.props)}
        {...resolve(this.props, this.state.overlay ? 'placeholder' : 'empty')}
        onClick={this.onOverlayClick}
      >
        {isModal && this.state.overlay ? dialogOverlay : null}
        {isAnimatable ? (
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'displayName' does not exist on type 'Fun... Remove this comment to see the full error message
          <Transition defaultName={this.constructor.displayName} {...transitionStyles}>
            {isOpen
              ? [
                  <div key="dialog" {...resolve(transitionStyles, 'transitionWrapper')}>
                    {dialogContent}
                  </div>
                ]
              : []}
          </Transition>
        ) : null}
        {isOpen && !isAnimatable ? ( // wrapper to be removed
          <div {...resolve(this.props, 'wrapper', 'dialogContainer')}>{dialogContent}</div>
        ) : null}
      </div>
    );
  }
}
