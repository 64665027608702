/**
 * Version 3 migrations for the website. Cleanup migration.
 * - Remove unused schemaVersion property from website.
 * - Remove deprecated version property from website (replaced with frameworkVersion)
 */
export function v3(website: any) {
  const newWebsite = {
    ...website,
    frameworkVersion: 3
  };

  if (newWebsite.schemaVersion) {
    delete newWebsite.schemaVersion;
  }
  if (newWebsite.version) {
    delete newWebsite.version;
  }

  return newWebsite;
}
