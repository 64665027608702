import { GuestsideFontStacks, GuestsideFontList } from '@cvent/fonts';

/**
 *  We used to save the entire font stack, i.e. 'Arial,Helvetica,sans-serif', as the value for fontFamily,
 * before we switched to use constants.
 * This is to convert font stacks to constants for backward compatibility.
 **/
export const getFontFamilyId = ({
  fontFamily,
  fontStacks = GuestsideFontStacks,
  customFonts = {}
}: any) => {
  if (
    customFonts[fontFamily] ||
    fontStacks[fontFamily] ||
    fontFamily === 'primary' ||
    fontFamily === 'secondary'
  ) {
    return fontFamily;
  }
  return (
    Object.keys(fontStacks).find(key => fontStacks[key] === fontFamily) || GuestsideFontList.Arial
  );
};
