/**
 * This component extends the EventRow component from react-big-calendar@v0.14.0.
 *
 * 1) References to "className" and "style" are removed from the functions copied from the base component,
 *    and replaced by "resolve" and "select" to apply styles and pass styles down to child components.
 *
 * There are no functional changes.
 */

import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import CalendarEventRow from 'react-big-calendar/lib/EventRow';
import { EventRowMixin, eventRowMixinStyleKeys } from './EventRowMixin';

export class EventRow extends CalendarEventRow {
  static displayName = 'EventRow';
  static propTypes = { ...CalendarEventRow.propTypes };
  static defaultProps = { ...CalendarEventRow.defaultProps };

  render() {
    const { segments } = this.props;
    let lastEnd = 1;
    return (
      <div {...resolve(this.props, 'row')}>
        {segments.reduce((row: any, { event, left, right, span }: any, li: any) => {
          const key = '_lvl_' + li;
          const gap = left - lastEnd;

          const content = EventRowMixin.renderEvent(this.props, event);

          if (gap) {
            row.push(EventRowMixin.renderSpan(this.props, gap, key + '_gap'));
          }

          row.push(EventRowMixin.renderSpan(this.props, span, key, content));

          lastEnd = right + 1;

          return row;
        }, [])}
      </div>
    );
  }
}

export const eventRowStyleKeys = ['row', ...eventRowMixinStyleKeys];
