import React from 'react';

import debounce from 'lodash/debounce';
import { resolve } from '@cvent/nucleus-dynamic-css';
import { injectTestId, resolveTestId } from '@cvent/nucleus-test-automation';

import { InteractiveElement } from '../containers/InteractiveElement';
import { swipe } from '../touchEventHandlers';
import { Item } from './Item';
import { Thumbnail } from './Thumbnail';

const MIN_INTERVAL = 500;

type OwnProps = {
  classes: any;
  items: any[];
  showNav?: boolean;
  autoPlay?: boolean;
  infinite?: boolean;
  showIndex?: boolean;
  showBullets?: boolean;
  showThumbnails?: boolean;
  showDescriptionOnTop?: boolean;
  overlayDescriptionOnImage?: boolean;
  overlayNavigationDots?: boolean;
  slideOnThumbnailHover?: boolean;
  disableThumbnailScroll?: boolean;
  disableArrowKeys?: boolean;
  defaultImage?: string;
  indexSeparator?: string;
  startIndex?: number;
  slideInterval?: number;
  onSlide?: (...args: any[]) => any;
  onPause?: (...args: any[]) => any;
  onPlay?: (...args: any[]) => any;
  onClick?: (...args: any[]) => any;
  breakpoints?: number[];
  supportsWebp?: boolean;
  onImageLoad?: (...args: any[]) => any;
  onImageError?: (...args: any[]) => any;
  onThumbnailError?: (...args: any[]) => any;
  renderItem?: (...args: any[]) => any;
  loadingMessage?: string;
  preloadComponent?: React.ReactNode;
  colorPalette?: any;
  itemPlaceHolder?: string | React.ReactNode;
  thumbnailPlaceHolder?: string | React.ReactNode;
  translate?: (...args: any[]) => any;
  swipeConfig?: {
    timeout?: number;
    threshold?: number;
    restraint?: number;
  };
};

type State = any;

type Props = React.PropsWithChildren<OwnProps & typeof ImageGallery.defaultProps>;

/**
  Image Gallery / Carousel Component
  Takes in an Array of objects/images/components
*/
export class ImageGallery extends React.Component<Props, State> {
  static displayName = 'ImageGallery';
  static defaultProps = {
    items: [],
    showNav: true,
    autoPlay: false,
    infinite: true,
    showIndex: false,
    showBullets: false,
    showThumbnails: true,
    showDescriptionOnTop: true,
    overlayDescriptionOnImage: true,
    overlayNavigationDots: true,
    slideOnThumbnailHover: false,
    disableThumbnailScroll: false,
    disableArrowKeys: false,
    indexSeparator: ' / ',
    startIndex: 0,
    slideInterval: 3000,
    colorPalette: {
      secondary: '#FFFFFF',
      text: '#182261'
    },
    swipeConfig: {
      timeout: 500,
      threshold: 150,
      restraint: 100
    }
  };
  _thumbnailTimer: any;
  imageGallery: any;
  intervalId: any;
  thumbnailDelay: any;
  thumbnails: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      currentIndex: props.startIndex || 0,
      thumbsTranslateX: 0,
      offsetPercentage: 0,
      galleryWidth: 0
    };
    this.handleOnSwipe = this.handleOnSwipe.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.slideLeft = debounce(this.slideLeft.bind(this), MIN_INTERVAL, { leading: true });

    this.slideRight = debounce(this.slideRight.bind(this), MIN_INTERVAL, { leading: true });

    this.handleResize = this.handleResize.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.thumbnailDelay = 300;
  }

  componentDidMount() {
    // delay initial resize to get the accurate this.imageGallery.offsetWidth
    if (typeof window !== 'undefined') {
      window.setTimeout(() => this.handleResize(), 500);
    }
    if (this.props.autoPlay) {
      this.play();
    }
    if (window.addEventListener) {
      if (!this.props.disableArrowKeys) {
        window.addEventListener('keydown', this.handleKeyDown);
      }
      window.addEventListener('resize', this.handleResize);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.galleryWidth !== this.state.galleryWidth ||
      prevProps.showThumbnails !== this.props.showThumbnails
    ) {
      // adjust thumbnail container when window width is adjusted
      this.setThumbsTranslateX(
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        -this.getThumbsTranslateX(this.state.currentIndex > 0 ? 1 : 0) * this.state.currentIndex
      );
    }

    if (prevState.currentIndex !== this.state.currentIndex) {
      if (this.props.onSlide) {
        this.props.onSlide(this.state.currentIndex);
      }

      this.updateThumbnailTranslateX(prevState);
    }
    if (prevProps.autoPlay !== this.props.autoPlay) {
      if (this.props.autoPlay) {
        this.play();
      } else {
        this.pause();
      }
    }
    if (prevProps.slideInterval !== this.props.slideInterval && this.props.autoPlay) {
      this.pause();
      this.play();
    }
  }

  componentWillUnmount() {
    if (window.removeEventListener) {
      if (!this.props.disableArrowKeys) {
        window.removeEventListener('keydown', this.handleKeyDown);
      }
      window.removeEventListener('resize', this.handleResize);
      if (this.intervalId) {
        window.clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }
  }

  onImageLoad(e: any) {
    this.setState({
      // @ts-expect-error ts-migrate(2542) FIXME: Index signature in type 'Readonly<any>' only permi... Remove this comment to see the full error message
      loadedCount: (this.state.loadedCount += 1)
    });
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.onImageLoad(e);
  }

  getCurrentIndex() {
    return this.state.currentIndex;
  }

  getAlignmentClassName(index: any) {
    const { currentIndex } = this.state;
    let alignment = '';
    const LEFT = 'left';
    const CENTER = 'center';
    const RIGHT = 'right';

    switch (index) {
      case currentIndex - 1:
        alignment = LEFT;
        break;
      case currentIndex:
        alignment = CENTER;
        break;
      case currentIndex + 1:
        alignment = RIGHT;
        break;
      default:
        break;
    }

    if (this.props.items.length >= 3 && this.props.infinite) {
      if (index === 0 && currentIndex === this.props.items.length - 1) {
        // set first slide as right slide if were sliding right from last slide
        alignment = RIGHT;
      } else if (index === this.props.items.length - 1 && currentIndex === 0) {
        // set last slide as left slide if were sliding left from first slide
        alignment = LEFT;
      }
    }
    return alignment;
  }

  getSlideStyle(index: any) {
    const { currentIndex, offsetPercentage, previousIndex } = this.state;
    const basetranslateX = -100 * currentIndex;
    const totalSlides = this.props.items.length - 1;

    let translateX = basetranslateX + index * 100 + offsetPercentage;
    let zIndex = 1;

    // current index has more zIndex so slides wont fly by toggling infinite
    if (index === currentIndex) {
      zIndex = 3;
    } else if (index === previousIndex) {
      zIndex = 2;
    }
    // move last item around without showing on screen
    if (
      (previousIndex === 0 && currentIndex !== totalSlides && index === totalSlides) ||
      (currentIndex === 0 && previousIndex !== totalSlides && index === totalSlides)
    ) {
      zIndex = 0;
    }

    const lastfirst = currentIndex === 0 && index === totalSlides;
    const firstlast = currentIndex === totalSlides && index === 0;
    if (this.props.infinite && this.props.items.length > 2) {
      if (lastfirst) {
        // make the last slide the slide before the first
        translateX = -100 + offsetPercentage;
      } else if (firstlast) {
        // make the first slide the slide after the last
        translateX = 100 + offsetPercentage;
      }
    }

    const translate3d = `translate3d(${translateX}%, 0, 0)`;
    const styleObject = {
      WebkitTransform: translate3d,
      MozTransform: translate3d,
      msTransform: translate3d,
      OTransform: translate3d,
      transform: translate3d,
      transition: lastfirst || firstlast ? 'none !important' : 'transform .45s ease-out',
      zIndex
    };

    return styleObject;
  }

  getThumbnailStyle() {
    const translate3d = `translate3d(${this.state.thumbsTranslateX}px, 0, 0)`;
    return {
      WebkitTransform: translate3d,
      MozTransform: translate3d,
      msTransform: translate3d,
      OTransform: translate3d,
      transform: translate3d
    };
  }

  getNavStyle() {
    return {
      color: this.props.colorPalette.secondary
    };
  }

  getBulletStyle(index: any) {
    if (this.state.currentIndex === index) {
      return {
        background: '#000'
      };
    }
    return {
      background: '#FFF'
    };
  }

  getThumbnailActiveStyle(index: any) {
    if (this.state.currentIndex === index) {
      return {
        border: `2px solid ${this.props.colorPalette.text}`
      };
    }
  }

  setThumbsTranslateX(thumbsTranslateX: any) {
    this.setState({ thumbsTranslateX });
  }

  getThumbsTranslateX(indexDifference: any) {
    if (this.props.disableThumbnailScroll) {
      return 0;
    }

    if (this.thumbnails) {
      if (this.thumbnails.scrollWidth <= this.state.galleryWidth) {
        return 0;
      }
      const totalThumbnails = this.thumbnails.children.length;
      // total scroll-x required to see the last thumbnail
      const totalScrollX = this.thumbnails.scrollWidth - this.state.galleryWidth;
      // scroll-x required per index change
      const perIndexScrollX = totalScrollX / (totalThumbnails - 1);

      return indexDifference * perIndexScrollX;
    }
  }

  updateThumbnailTranslateX(prevState: any) {
    if (this.state.currentIndex === 0) {
      this.setThumbsTranslateX(0);
    } else {
      const indexDifference = Math.abs(prevState.currentIndex - this.state.currentIndex);
      const scrollX = this.getThumbsTranslateX(indexDifference);
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (scrollX > 0) {
        if (prevState.currentIndex < this.state.currentIndex) {
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          this.setThumbsTranslateX(this.state.thumbsTranslateX - scrollX);
        } else if (prevState.currentIndex > this.state.currentIndex) {
          this.setThumbsTranslateX(this.state.thumbsTranslateX + scrollX);
        }
      }
    }
  }

  play(callback = true) {
    if (this.intervalId) {
      return;
    }
    const { slideInterval } = this.props;
    this.intervalId = window.setInterval(
      () => {
        if (!this.state.hovering) {
          if (!this.props.infinite && !this.canSlideRight()) {
            this.pause();
          } else {
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
            this.slideToIndex(this.state.currentIndex + 1);
          }
        }
      },
      slideInterval > MIN_INTERVAL ? slideInterval : MIN_INTERVAL
    );

    if (this.props.onPlay && callback) {
      this.props.onPlay(this.state.currentIndex);
    }
  }

  pause(callback = true) {
    if (this.intervalId) {
      window.clearInterval(this.intervalId);
      this.intervalId = null;
    }

    if (this.props.onPause && callback) {
      this.props.onPause(this.state.currentIndex);
    }
  }

  fullScreen() {
    const gallery = this.imageGallery;

    if (gallery.requestFullscreen) {
      gallery.requestFullscreen();
    } else if (gallery.msRequestFullscreen) {
      gallery.msRequestFullscreen();
    } else if (gallery.mozRequestFullScreen) {
      gallery.mozRequestFullScreen();
    } else if (gallery.webkitRequestFullscreen) {
      gallery.webkitRequestFullscreen();
    }
  }

  slideToIndex(index: any, event: any) {
    if (event) {
      // event.preventDefault();
      if (this.intervalId) {
        // user triggered event while ImageGallery is playing, reset interval
        this.pause(false);
        this.play(false);
      }
    }

    const slideCount = this.props.items.length - 1;
    let currentIndex = index;

    if (index < 0) {
      currentIndex = slideCount;
    } else if (index > slideCount) {
      currentIndex = 0;
    }

    this.setState({
      previousIndex: this.state.currentIndex,
      currentIndex,
      offsetPercentage: 0
    });
  }

  handleResize() {
    if (this.imageGallery) {
      this.setState({ galleryWidth: this.imageGallery.offsetWidth });
    }
  }

  handleKeyDown(event: any) {
    const LEFT_ARROW = 37;
    const RIGHT_ARROW = 39;
    const key = parseInt(event.keyCode || event.which || 0, 10);

    switch (key) {
      case LEFT_ARROW:
        if (this.canSlideLeft() && !this.intervalId) {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
          this.slideLeft();
        }
        break;
      case RIGHT_ARROW:
        if (this.canSlideRight() && !this.intervalId) {
          // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
          this.slideRight();
        }
        break;
      default:
        break;
    }
  }

  handleMouseOverThumbnails(index: any) {
    if (this.props.slideOnThumbnailHover) {
      this.setState({ hovering: true });
      if (this._thumbnailTimer) {
        window.clearTimeout(this._thumbnailTimer);
        this._thumbnailTimer = null;
      }
      this._thumbnailTimer = window.setTimeout(() => {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
        this.slideToIndex(index);
      }, this.thumbnailDelay);
    }
  }

  handleMouseLeaveThumbnails() {
    if (this._thumbnailTimer) {
      window.clearTimeout(this._thumbnailTimer);
      this._thumbnailTimer = null;
      if (this.props.autoPlay === true) {
        this.play(false);
      }
    }
    this.setState({ hovering: false });
  }

  handleMouseOver() {
    this.setState({ hovering: true });
  }

  handleMouseLeave() {
    this.setState({ hovering: false });
  }
  /* eslint no-param-reassign: 0 */
  handleImageError(event: any) {
    if (this.props.defaultImage && event.target.src.indexOf(this.props.defaultImage) === -1) {
      event.target.src = this.props.defaultImage;
    }
  }

  handleOnSwipe(event: any, direction: any) {
    let slideTo = this.state.currentIndex;
    if (direction === 'right') {
      slideTo++;
    } else if (direction === 'left') {
      slideTo--;
    }
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
    this.slideToIndex(slideTo);
  }

  canNavigate() {
    return this.props.items.length >= 2;
  }
  /* eslint no-else-return: 0 */
  canSlideLeft() {
    if (this.props.infinite) {
      return true;
    } else {
      return this.state.currentIndex > 0;
    }
  }

  canSlideRight() {
    if (this.props.infinite) {
      return true;
    } else {
      return this.state.currentIndex < this.props.items.length - 1;
    }
  }

  slideLeft(event: any) {
    this.slideToIndex(this.state.currentIndex - 1, event);
  }

  slideRight(event: any) {
    this.slideToIndex(this.state.currentIndex + 1, event);
  }

  renderItem(item: any, index: any) {
    const onImageError = this.props.onImageError || this.handleImageError;
    return (
      <Item
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: any; classes: any; style: any; w... Remove this comment to see the full error message
        classes={this.props.classes}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type 'Readonly<... Remove this comment to see the full error message
        style={this.props.style}
        width={item.width}
        original={item.original || null}
        originalAlt={item.decorativeImage ? null : item.originalAlt || null}
        srcSet={item.srcSet || null}
        sizes={item.sizes || null}
        breakpoints={item.breakpoints || this.props.breakpoints}
        itemId={'image-' + index}
        supportsWebp={this.props.supportsWebp}
        description={item.description}
        altText={item.decorativeImage ? null : item.altText || ''}
        showDescriptionOnTop={this.props.showDescriptionOnTop}
        overlayDescriptionOnImage={this.props.overlayDescriptionOnImage}
        orientation={item.orientation || 'landscape'}
        preloadComponent={this.props.preloadComponent}
        placeHolderImageUrl={this.props.itemPlaceHolder}
        onLoad={this.onImageLoad.bind(this)}
        onError={onImageError.bind(this)}
        hyperlink={item.hyperlink}
      >
        {item.component || null}
      </Item>
    );
  }

  render() {
    const { classes, overlayNavigationDots, translate = x => x } = this.props;
    const { currentIndex } = this.state;
    const thumbnailStyle = this.getThumbnailStyle();
    const navStyle = this.getNavStyle();

    const slideLeft = this.slideLeft.bind(this);
    const slideRight = this.slideRight.bind(this);

    const slides: any = [];
    const thumbnails: any = [];
    const bullets: any = [];
    const numImages = this.props.items.length;

    this.props.items.map((item, index) => {
      const alignment = this.getAlignmentClassName(index);
      const renderItem = item.renderItem || this.props.renderItem || this.renderItem.bind(this);

      const slide = (
        <InteractiveElement
          key={'slide-' + index}
          isDisabled
          {...resolve(this.props, 'slide', alignment)}
          style={this.getSlideStyle(index)}
          onClick={this.props.onClick}
          aria-current={currentIndex === index ? 'true' : 'false'}
          aria-label={1 + index + ' of ' + numImages}
          role="tabpanel"
          aria-roledescription="slide"
        >
          {renderItem(item, index)}
        </InteractiveElement>
      );

      slides.push(slide);

      const onThumbnailError = this.props.onThumbnailError || this.handleImageError;

      if (this.props.showThumbnails) {
        thumbnails.push(
          <InteractiveElement
            element="span"
            key={'thumbnail-' + index}
            {...injectTestId('image-gallery-thumbnail-' + index)}
            {...resolve(this.props, 'thumbnail')}
            aria-current={currentIndex === index ? 'true' : 'false'}
            aria-roledescription="thumbnail"
            style={this.getThumbnailActiveStyle(index)}
            aria-label={1 + index + ' of ' + numImages}
            onMouseOver={this.handleMouseOverThumbnails.bind(this, index)}
            onMouseLeave={this.handleMouseLeaveThumbnails.bind(this, index)}
            onTouchStart={(event: any) => {
              this.slideToIndex.call(this, index, event);
            }}
            onClick={event => {
              this.slideToIndex.call(this, index, event);
            }}
          >
            <Thumbnail
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'ReactNode' is not assignable to type '(React... Remove this comment to see the full error message
              placeHolderImageUrl={this.props.thumbnailPlaceHolder}
              classes={this.props.classes}
              thumbnail={item.component ? null : item.thumbnail}
              thumbnailAlt={item.component ? null : item.thumbnailAlt}
              onError={onThumbnailError.bind(this)}
            >
              {item.component ? item.thumbnail : null}
            </Thumbnail>
          </InteractiveElement>
        );
      }

      if (this.props.showBullets) {
        bullets.push(
          <InteractiveElement
            element="li"
            key={'bullet-' + index}
            {...injectTestId('image-gallery-bullet-' + index)}
            {...resolve(this.props, 'bullet', currentIndex === index ? 'active' : '')}
            style={this.getBulletStyle(index)}
            role="tab"
            aria-current={currentIndex === index ? 'true' : 'false'}
            aria-label={1 + index + ' of ' + numImages}
            aria-roledescription="bullet"
            onTouchStart={(event: any) => {
              this.slideToIndex.call(this, index, event);
            }}
            onClick={event => {
              this.slideToIndex.call(this, index, event);
            }}
          />
        );
      }
    });
    const { swipeConfig } = this.props;
    let slidesOutput;
    if (this.canNavigate()) {
      slidesOutput = [
        this.props.showNav && (
          <span key={'navigation'}>
            {this.canSlideLeft() && (
              <InteractiveElement
                element="span"
                {...resolve(this.props, 'leftNav')}
                style={navStyle}
                {...injectTestId('image-gallery-left-nav')}
                onClick={slideLeft}
                aria-label={translate('NucleusWidgets_ImageCarousel_Previous__resx')}
              />
            )}
            {this.canSlideRight() && (
              <InteractiveElement
                element="span"
                {...resolve(this.props, 'rightNav')}
                style={navStyle}
                {...injectTestId('image-gallery-right-nav')}
                onClick={slideRight}
                aria-label={translate('NucleusWidgets_ImageCarousel_Next__resx')}
              />
            )}
          </span>
        ),

        <div
          {...swipe(
            this.handleOnSwipe,
            swipeConfig.timeout,
            swipeConfig.threshold,
            swipeConfig.restraint
          )}
          key={'slides'}
          {...resolve(this.props, 'slides')}
        >
          {slides}
        </div>
      ];
    } else {
      slidesOutput = (
        <div key={'slides'} {...resolve(this.props, 'slides')}>
          {slides}
        </div>
      );
    }
    return (
      <section
        ref={i => (this.imageGallery = i)}
        {...resolveTestId(this.props)}
        {...resolve(this.props, 'gallery')}
        aria-roledescription="carousel"
        role="region"
        aria-label="carousel"
      >
        <div
          key={'content'}
          onMouseOver={this.handleMouseOver.bind(this)}
          onMouseLeave={this.handleMouseLeave.bind(this)}
          {...resolve(this.props, 'content')}
        >
          {slidesOutput}
          {this.props.showBullets && (
            <div
              key={'bullets'}
              {...resolve(this.props, overlayNavigationDots ? 'bullets' : 'bulletsBelowSlide')}
            >
              <ul {...resolve(this.props, 'bulletsContainer')} role="tablist">
                {bullets}
              </ul>
            </div>
          )}
          {this.props.showIndex && (
            <div key={'index'} className={classes.index}>
              <span className={classes.current}>{this.state.currentIndex + 1}</span>
              <span className={classes.separator}>{this.props.indexSeparator}</span>
              <span className={classes.total}>{this.props.items.length}</span>
            </div>
          )}
        </div>

        {this.props.showThumbnails && (
          <div key={'thumbnails'} {...resolve(this.props, 'thumbnails')}>
            <div
              ref={t => (this.thumbnails = t)}
              {...resolve(this.props, 'thumbnailsContainer')}
              style={thumbnailStyle}
            >
              {thumbnails}
            </div>
          </div>
        )}
      </section>
    );
  }
}
