/* eslint react/prop-types: [2, { ignore: ["setFocus", "onChange", "onNativeChange", "fieldName", "id", "name"] }] */
/* eslint-disable react/no-multi-comp */
import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { injectTestId, resolveTestId } from '@cvent/nucleus-test-automation';

import {
  FormElement,
  removeFormElementProps,
  resolveValidationAccessibilityProps,
  WithFormProps
} from '../FormElement';
import { BreakPoint } from '../../buttons';

/*
(ts-migrate) TODO: Migrate the remaining prop types
...FormElement.propTypes
*/
type TextboxProps = WithFormProps<{
  value: any;
  type?: 'text' | 'password' | 'email' | 'url' | 'date' | 'number';
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  breakPoint?: BreakPoint;
  style?: Record<string, unknown>;
  autoComplete?: string;
  ariaDescribedby?: string;
}>;

/**
Display browser default textbox

onChange = function(fieldName, newValue)
**/
export class Textbox extends React.PureComponent<TextboxProps> {
  static displayName = 'Textbox';
  static defaultProps = {
    type: 'text',
    required: false,
    disabled: false,
    readOnly: false,
    placeholder: '',
    maxLength: null
  };
  textbox: any;
  constructor(props: any) {
    super(props);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onFocus = this.onFocus.bind(this);
  }

  componentDidMount() {
    if (this.props.setFocus) {
      this.focus();
    }
  }

  onChange(event: any) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const target = event.target ? event.target : window.event.srcElement;
    if (this.props.onNativeChange) {
      this.props.onNativeChange(event);
    } else {
      this.props.onChange(this.props.fieldName, target.value);
    }
  }

  onBlur(e: any) {
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  onFocus(e: any) {
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  select() {
    if (typeof document !== 'undefined' && this.textbox) {
      this.textbox.select();
    }
  }

  focus() {
    if (
      typeof document !== 'undefined' &&
      this.textbox &&
      document.activeElement !== this.textbox
    ) {
      // Firing "focus" opens native date picker on iOS, but does nothing on Android
      this.textbox.focus();
      if (this.props.type === 'date') {
        // Firing "click" opens native date picker on Android, but does nothing on iOS
        this.textbox.click();
      }
    }
  }

  render() {
    const { id, name, fieldName, errorMessages, label, autoComplete, required, ariaDescribedby } =
      this.props;
    const labelProp: any = {};
    if (label) labelProp.label = label;
    return (
      <input
        {...removeFormElementProps(this.props)}
        {...resolveTestId(this.props)}
        {...labelProp}
        required={required}
        ref={c => {
          this.textbox = c;
        }}
        id={id || fieldName}
        name={name || fieldName}
        onBlur={this.onBlur}
        onChange={this.onChange}
        onFocus={this.onFocus}
        autoComplete={autoComplete}
        aria-describedby={ariaDescribedby ?? fieldName}
        {...resolveValidationAccessibilityProps(id || fieldName, errorMessages)}
      />
    );
  }
}

type OwnTextboxFormElementProps = React.PropsWithChildren<{
  size?: string;
  breakPoint?: BreakPoint;
  style?: {
    textbox?: any;
  };
  errorMessages?: {
    [key: string]: string;
  };
  displayValid?: boolean;
  validationState?: any; // TODO: PropTypes.oneOf(['valid', 'error', undefined])
}>;

type TextboxFormElementProps = React.PropsWithChildren<
  OwnTextboxFormElementProps & typeof TextboxFormElement.defaultProps
>;

/**
FormElement wrapper around Textbox. This is the default export.
**/
class TextboxFormElement extends React.PureComponent<TextboxFormElementProps> {
  static displayName = 'TextboxFormElement';

  static defaultProps = {
    size: '',
    errorMessages: {}
  };

  textbox: any;

  focus() {
    if (this.textbox) {
      this.textbox.focus();
    }
  }

  select() {
    if (this.textbox) {
      this.textbox.select();
    }
  }

  render() {
    const { children, size, breakPoint, displayValid, validationState, ...rest } = this.props;
    const { errorMessages } = rest;
    const errorsPresent = Object.keys(errorMessages).length > 0;
    const validState = displayValid ? 'valid' : undefined;
    const resolvedValidationState = validationState || (errorsPresent ? 'error' : validState);
    return (
      // @ts-expect-error error TS2769: No overload matches this call
      <FormElement {...rest}>
        <div {...resolve(this.props, 'textboxContainer')}>
          <Textbox
            {...rest}
            {...resolve(this.props, 'textbox', breakPoint, size, resolvedValidationState)}
            {...injectTestId('input')}
            ref={(c: any) => (this.textbox = c)}
          />
          {children}
        </div>
      </FormElement>
    );
  }
}

export { TextboxFormElement };
