import Logger from '@cvent/nucleus-logging';
import { setLogClient } from '@cvent/nucleus-logging';
import { BrowserDatadogLogClient } from 'cvent-event-calendar/lib/logging/BrowserDatadogLogClient';
import { ServiceError } from 'cvent-event-calendar/lib/logging/ServiceError';
import { debounce } from 'lodash';

const LOG = new Logger('calendar-guest-site/GLOBAL');
const LOG_PATH = 'api/calendar/v1/log';

const logDevelopmentError = debounce(
  (exception: $TSFixMe) => {
    if (exception.stack && exception.stack.indexOf('invokeGuardedCallbackDev') >= 0) {
      /*
       * Ignore errors that will be processed by componentDidCatch.
       * SEE: https://github.com/facebook/react/issues/10474
       * This should only matter with development build because errors are rethrown to facilitate debugging
       */
      return;
    }
    // eslint-disable-next-line no-alert
    alert(
      'A fatal error occurred. This will cause the instance page to appear in production. ' +
        'Look at console for more details on what occurred. To enable fatal error page ' +
        'run the command "IS_DEBUG=false pnpm dev"'
    );
  },
  30000,
  { leading: true, trailing: false }
);

let displayFatalErrorDialog: $TSFixMe;

export function setDisplayFatalErrorDialog(value: $TSFixMe) {
  displayFatalErrorDialog = value;
}

const errorActions = [
  {
    when: (exception: $TSFixMe) => exception instanceof ServiceError && typeof displayFatalErrorDialog === 'function',
    then: (exception: $TSFixMe) => displayFatalErrorDialog(exception),
    userExperience: 'FATAL_ERROR_DIALOG'
  },
  {
    when: (_: $TSFixMe, isDebug: $TSFixMe) => isDebug,
    then: logDevelopmentError,
    userExperience: 'YOU_ARE_A_DEVELOPER_FIGURE_IT_OUT'
  }
];

export function logErrorAndRedirect(
  message: $TSFixMe,
  exception: $TSFixMe,
  isDebug: $TSFixMe,
  calendarId: $TSFixMe,
  domain: $TSFixMe,
  environment: $TSFixMe
) {
  const errorType = exception instanceof ServiceError ? 'ServiceError' : 'ClientError';
  try {
    const errorAction = errorActions.find(action => action.when(exception, isDebug));

    if (errorAction) {
      LOG.error(
        message,
        {
          errorType,
          userExperience: errorAction.userExperience
        },
        exception
      );

      errorAction.then(exception, `${domain.replace(/\/+$/, '')}/${LOG_PATH}`, calendarId, environment);
    }
  } catch (e) {
    LOG.error('Error logging.', e);
  }
}

export const initializeDatadogRum = (
  datadogEnvironment: $TSFixMe,
  logToServer: $TSFixMe,
  loggerContext: $TSFixMe,
  domain: $TSFixMe,
  isWebWidget: $TSFixMe
) => {
  const datadogLogClient = BrowserDatadogLogClient.init({
    applicationId: '7d43daff-f447-4000-8ed6-629aa69b980f',
    clientToken: 'pubb9dfcf8a06def5fd903608627274545c',
    service: 'calendar-guest-site',
    env: datadogEnvironment,
    version: process.env.npm_package_version,
    sessionSampleRate: isWebWidget ? 10 : 100,
    sessionReplaySampleRate: isWebWidget ? 0 : 50,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    isDebug: logToServer,
    trackViewsManually: false,
    loggerRumContext: loggerContext
  });
  datadogLogClient.setContext(loggerContext);
  setLogClient(datadogLogClient);
  const calendarId = loggerContext.calendar.id;
  window.onunhandledrejection = async event => {
    let reason;
    try {
      reason = await event.reason;
      if (!reason) {
        reason = { message: 'unhandledrejection event had no reason', event };
      }
    } catch (error) {
      reason = error;
      if (!reason) {
        reason = { message: 'unhandledrejection event reason promise rejected with no value', event };
      }
    }
    logErrorAndRedirect('Global Unhandled Promise Rejection', reason, logToServer, calendarId, domain, datadogEnvironment);
  };
  initializeWindowError(logToServer, calendarId, domain, datadogEnvironment);
};

function initializeWindowError(isDebug: $TSFixMe, calendarId: $TSFixMe, domain: $TSFixMe, environment: $TSFixMe) {
  window.onerror = (message, file, line, column, error) => {
    if (message === 'ResizeObserver loop limit exceeded' && !error) {
      /*
       * Ignore errors that is caused by some extension in Chrome and Edge like Grammarly
       * SEE: https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
       */
      return;
    }
    if (message === 'Script error.' && !error) {
      /*
       * Ignore errors that is caused in Safari browser
       * SEE: https://sentry.io/answers/script-error/#:~:text=This%20isn
       */
      return;
    }
    if (message === 'ResizeObserver loop completed with undelivered notifications.' && !error) {
      /*
       * Ignore error caused in firefox
       * Safe to ignore as per this thread:
       * https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded/50387233#50387233
       */
      return;
    }
    logErrorAndRedirect('Global Unhandled Error', error, isDebug, calendarId, domain, environment);
  };
}
