import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { removeKeys } from '../utils/removeKeys';

type Props = React.PropsWithChildren<{
  classes?: any;
  type?: 'menu' | 'list';
  style?: {
    menu?: any;
    list?: any;
  };
}>;

/**
  List
 **/

const List = (props: Props) => {
  const { children, type = 'list', ...rest } = removeKeys(props, ['classes']);
  const menuList = React.Children.map(children, item => {
    return <li>{item}</li>;
  });
  return (
    <ul {...rest} {...resolve(props, type)}>
      {menuList}
    </ul>
  );
};
List.displayName = 'List';
export { List };
