// Constants File for Calendar List Widget //

export const MONTH_VIEW_FORMAT = 'monthView';
export const LIST_VIEW_FORMAT = 'listView';
export const SCREEN_READER_DATE_FORMAT = 'dddd, DD-MMM-YYYY';
export const ONGOING_EVENTS_HEADER = 'Ongoing';
export const MONTHS_RESX = [
  'months_january__resx',
  'months_february__resx',
  'months_march__resx',
  'months_april__resx',
  'months_may__resx',
  'months_june__resx',
  'months_july__resx',
  'months_august__resx',
  'months_september__resx',
  'months_october__resx',
  'months_november__resx',
  'months_december__resx'
];
export const DAY_SHORT_RESX = [
  'calendar_label_day_sun',
  'calendar_label_day_mon',
  'calendar_label_day_tue',
  'calendar_label_day_wed',
  'calendar_label_day_thu',
  'calendar_label_day_fri',
  'calendar_label_day_sat'
];

export const DEFAULT_BREAKPOINTS = {
  SMALL: 480,
  MEDIUM: 768,
  LARGE: 992
};

export const LOCATION = 'location';
export const CHECKBOX = 'checkbox';
export const DEFAULT_RADIUS = 20;

export const TAG_TYPE = {
  DOT: 'dot',
  PILL: 'pill'
};

export const KEY_CODES = {
  TAB: 9,
  ESCAPE: 27,
  BACKSPACE: 8,
  DELETE: 46,
  SPACE: 32
};

export const EVENT_STATUS_CLOSED = 'Closed';
