import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

type Props = {
  header?: string;
  subHeader?: React.ReactElement;
  // Used in resolve function // TODO: determine neccesary fields for classes
  classes?: Record<string, unknown>;
  // Used in resolve function
  style?: Record<string, unknown>;
};

/**
 * The basic header widget UI implementation.
 */
export class Header extends React.Component<Props> {
  static displayName = 'Header';
  render() {
    const { header, subHeader } = this.props;
    return (
      <div {...resolveTestId(this.props)} {...resolve(this.props, 'container')}>
        <h1 {...resolve(this.props, 'header')}>{header}</h1>
        {subHeader}
      </div>
    );
  }
}
