import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';

import { BaseWidget } from '../BaseWidget';
import { Header } from './Header';
import ClassNames from './Header.less';
import { ThemeableComponentProps } from '../ThemeableComponent';

interface HeaderWidgetProps extends ThemeableComponentProps {
  type?: string;
  config?: Record<string, unknown>;
  classes?: Record<string, unknown>;
  // Non-configurable appData props that should be added by the application specific wrapper.
  header?: string;
  subHeader?: JSX.Element;
}
/**
 * A basic header widget that can be wrapped by any application specific implementation to
 * populate the header fields.
 */
export class HeaderWidget extends BaseWidget<HeaderWidgetProps> {
  static displayName = 'HeaderWidget';
  static defaultProps = {
    classes: ClassNames
  };
  getStyleObject() {
    return {
      ...super.getStyleObject(),
      header: this.getElementInlineStyle('header')
    };
  }
  render() {
    const { header, classes, subHeader } = this.props;
    return (
      <Header
        {...resolveTestId(this.props)}
        classes={classes}
        style={this.getStyleObject()}
        header={header}
        subHeader={subHeader}
      />
    );
  }
}
