import React from 'react';
import { connect } from 'react-redux';

import MessageStyles from './BrowserSupportMessage.less';

type Props = {
  translate: (...args: any[]) => any;
  onDismiss: (...args: any[]) => any;
};

/**
 * Warning message for unsupported browsers
 */
export class BrowserSupportMessageBase extends React.Component<Props> {
  static displayName = 'BrowserSupportMessage';
  render() {
    const { translate } = this.props;
    return (
      <div className={MessageStyles.container}>
        <span className={MessageStyles.icon}>&#9888;</span>
        <div className={MessageStyles.message}>
          {translate('NucleusGuest_UnspportedBrowser_WarningMessageText__resx')}
        </div>
        <button
          className={MessageStyles.closeBtn}
          onClick={this.props.onDismiss}
          title={translate('NucleusGuest_UnspportedBrowser_DismissButtonTooltipText__resx')}
        >
          &#10005;
        </button>
      </div>
    );
  }
}

export const BrowserSupportMessage = connect(state => {
  return {
    translate: (state as any).text.translate
  };
})(BrowserSupportMessageBase);
