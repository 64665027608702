import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { resolve } from '@cvent/nucleus-dynamic-css';
import PropTypes from 'prop-types';

import { EmbeddedVideoBase } from './EmbeddedVideoBase';
import VideoStyle from './Video.less';

const YOUTUBE_VIDEO_ID = 'YOUTUBE_VIDEO_ID - ';

/**
 * The basic UI implementation for the Video widget when it contains a YouTube video
 */
export class EmbeddedYoutubeVideo extends EmbeddedVideoBase {
  static propTypes = {
    videoId: PropTypes.string,
    widgetId: PropTypes.string,
    translate: PropTypes.func,
    recordVideoViewActivity: PropTypes.func
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    this.appendEmbedScript();
    this.registerVideoPlayer();
  }

  componentWillUnmount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'widgetId' does not exist on type 'Readon... Remove this comment to see the full error message
    const { widgetId } = this.props;
    if (!isEmpty(this.window.youtubePlayers) && !isEmpty(this.window.youtubePlayers[widgetId])) {
      this.window.youtubePlayers[widgetId].destroy();
      delete this.window.youtubePlayers[widgetId];
    }
  }

  appendEmbedScript() {
    if (!document.querySelector('#yt-js') && typeof this.window.YT === 'undefined') {
      const youtubeScript = this.window.document.createElement('script');
      youtubeScript.id = 'yt-js';
      youtubeScript.src = 'https://www.youtube.com/iframe_api';
      youtubeScript.async = true;
      this.window.document.body.appendChild(youtubeScript);
    }
  }

  registerVideoPlayer() {
    this.window.ytPlayerScripts = this.window.ytPlayerScripts || [];
    this.window.ytPlayerScripts.push((yt: any) => {
      const player = new yt.Player(
        `${YOUTUBE_VIDEO_ID}${
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'widgetId' does not exist on type 'Readon... Remove this comment to see the full error message
          this.props.widgetId
        }`,
        this.bindPlayerEvents(yt)
      );
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'widgetId' does not exist on type 'Readon... Remove this comment to see the full error message
      this.window.youtubePlayers[this.props.widgetId] = player;
    });
    this.window.youtubePlayers = this.window.youtubePlayers || {};
    if (this.window.YT) {
      this.window.ytPlayerScripts.forEach((script: any) => script(this.window.YT));
      this.window.ytPlayerScripts = [];
    } else {
      this.window.onYouTubeIframeAPIReady = () => {
        this.window.ytPlayerScripts.forEach((script: any) => script(this.window.YT));
        this.window.ytPlayerScripts = [];
      };
    }
  }

  bindPlayerEvents(yt: any) {
    return {
      events: {
        onStateChange: (event: any) => {
          const playerStatus = event.data;
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'recordVideoViewActivity' does not exist ... Remove this comment to see the full error message
          if (playerStatus === yt.PlayerState.UNSTARTED && this.props.recordVideoViewActivity) {
            const videoTitle = event.target.playerInfo.videoData.title;
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'recordVideoViewActivity' does not exist ... Remove this comment to see the full error message
            this.props.recordVideoViewActivity(videoTitle);
          }
        }
      }
    };
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoId' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { videoId, widgetId, translate } = this.props;
    const videoStyles = { classes: VideoStyle };
    return (
      <div {...resolve(videoStyles, 'youtubeContainer')} ref={node => (this.node = node)}>
        <iframe
          id={`${YOUTUBE_VIDEO_ID}${widgetId}`}
          src={`https://www.youtube.com/embed/${videoId}?enablejsapi=1&rel=0`}
          aria-label={translate('EventWidgets_Video_YouTube__resx')}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          webkitallowfullscreen="webkitallowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'boolean |... Remove this comment to see the full error message
          allowFullScreen="allowFullScreen"
        ></iframe>
      </div>
    );
  }
}
