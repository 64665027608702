import merge from 'lodash/merge';

import { getEms } from './getEms';

export function getSpacing(styleObject: any, customStyleSettings: any) {
  const mergedStyleObject = merge({}, styleObject, customStyleSettings);
  if (!mergedStyleObject || !mergedStyleObject.spacing) {
    return {};
  }
  const paddings = mergedStyleObject.spacing.padding;
  const newPaddings = {};
  Object.keys(paddings).forEach(key => {
    const padding = paddings[key];
    // Convert paddings that are larger than 15px to rem to reduce spacing on smaller browser viewports.
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    newPaddings[key] = padding <= 15 ? padding : `${getEms(padding)}rem`;
  });
  return newPaddings;
}
