import React, { cloneElement } from 'react';

import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

import { resolve, select } from '@cvent/nucleus-dynamic-css';
import { injectTestId, resolveTestId } from '@cvent/nucleus-test-automation';

import { removeKeys } from '../utils/removeKeys';
import { Errors } from './ErrorMessages';
import FormElementClasses from './FormElement.less';
import { FormLabel } from './FormLabel';
import { FormElementMode } from './PropTypes';

export type WithFormProps<T = Record<string, unknown>> = React.PropsWithChildren<
  T & {
    mode?: FormElementMode;
    view?: React.ReactNode;
    id?: string;
    name?: string;
    fieldName?: string;
    label?: React.ReactNode;
    labelId?: string;
    additionalLabelText?: React.ReactNode;
    hideLabel?: boolean;
    showAdditionalLabelTextInViewMode?: boolean;
    isFieldSet?: boolean;
    required?: boolean;
    tooltip?: React.ReactNode;
    onChange?: any; // TODO: isRequiredIf(PropTypes.func, props => !props.hasOwnProperty('onNativeChange'))
    onNativeChange?: any; // TODO: isRequiredIf(PropTypes.func, props => !props.hasOwnProperty('onChange'))
    onClick?: (...args: any[]) => any;
    onKeyDown?: (...args: any[]) => any;
    onBlur?: (...args: any[]) => any;
    onFocus?: (...args: any[]) => any;
    setFocus?: boolean;
    errorMessages?:
      | {
          [key: string]: string;
        }
      | string[];
    style?: {
      checkbox?: any;
      horizontal?: any;
      element?: any;
      inputContainer?: any;
      label?: any;
      view?: any;
      textarea?: any;
      tooltip?: any;
      select?: any;
    };
  }
>;

type State = any;

type Props = React.PropsWithChildren<WithFormProps>;

/**
A wrapper to use for different form element components (select, textbox, etc) to add
common functionality like labels, required field indicators, etc.
**/
export class FormElement extends React.Component<Props, State> {
  static displayName = 'FormElement';

  static defaultProps = {
    mode: 'edit',
    view: null,
    required: false,
    hideLabel: false,
    showAdditionalLabelTextInViewMode: false,
    classes: {}
  };

  state = {
    key: undefined
  };

  /**
   * Updating the value for CSS float property on <legend> tag after this component is mounted
   * causes the <legend> tag disappears or overlaps with the content below it in Safari.
   * The fix is to update the key on this component when the float value is changed, either through classes or style,
   * to force a full re-instantiation of this component.
   */
  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { isFieldSet, mode } = nextProps;
    const renderAsFieldSet = isFieldSet && mode === 'edit';
    if (!renderAsFieldSet) {
      return;
    }

    let newKey;
    const classObject = this.getLabelStyle(this.props);
    const nextClassObject = this.getLabelStyle(nextProps);
    if (nextClassObject.classes.label !== classObject.classes.label) {
      newKey = nextClassObject.classes.label;
    }
    if (
      (nextClassObject.style.label &&
        classObject.style.label &&
        nextClassObject.style.label.float !== classObject.style.label.float) ||
      (nextClassObject.style.label && !classObject.style.label && nextClassObject.style.label.float)
    ) {
      newKey = newKey
        ? `${newKey}-${nextClassObject.style.label.float}`
        : nextClassObject.style.label.float;
    } else if (
      !nextClassObject.style.label &&
      classObject.style.label &&
      classObject.style.label.float
    ) {
      newKey = newKey ? `${newKey}-no-inline-float` : 'no-inline-float';
    }
    if (newKey) {
      this.setState({
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type '{ key... Remove this comment to see the full error message
        key: `${this.state.fieldName}-${newKey}`
      });
    }
  }

  getLabelStyle = (props: any) => {
    // Shallow Labels out if they exist in nested form //
    const labelStyle = select(props, 'label');
    return {
      classes: {
        ...props.classes,
        ...labelStyle.classes
      },
      style: {
        ...props.style,
        ...labelStyle.style
      }
    };
  };
  render() {
    const {
      mode,
      view,
      id,
      fieldName,
      required,
      tooltip,
      label,
      labelId,
      additionalLabelText,
      hideLabel,
      showAdditionalLabelTextInViewMode,
      errorMessages,
      children,
      isFieldSet
    } = this.props;

    // Determine if there is a view that can be rendered.
    const viewToRender =
      mode === 'view' && view
        ? cloneElement(view as JSX.Element, resolve(this.props, 'view'))
        : null;

    // Determine whether to render the view component or the children.
    const elementToRender = mode === 'view' && viewToRender ? viewToRender : children;

    const renderAsFieldSet = isFieldSet && mode === 'edit';
    const WrapperTag = renderAsFieldSet ? 'fieldset' : 'div';

    const resolvedStyles = resolve(this.props, 'element');
    const wrapperStyle = resolvedStyles.style;
    const classObject = this.getLabelStyle(this.props);
    const errorsPresent = Array.isArray(errorMessages)
      ? errorMessages.length > 0
      : Object.keys(errorMessages || {}).length > 0;
    const className = errorsPresent
      ? `${resolvedStyles.className} ${FormElementClasses.formElementWithErrors}`
      : resolvedStyles.className;
    return (
      <WrapperTag
        className={className}
        style={wrapperStyle}
        {...resolveTestId(this.props)}
        key={this.state.key}
      >
        <FormLabel
          mode={mode}
          label={label}
          additionalLabelText={additionalLabelText}
          hideLabel={hideLabel}
          showAdditionalLabelTextInViewMode={showAdditionalLabelTextInViewMode}
          fieldId={id || fieldName}
          labelId={labelId}
          required={required}
          element={renderAsFieldSet ? 'legend' : 'label'}
          classes={classObject.classes}
          style={classObject.style}
          {...injectTestId('label')}
        />
        <div {...resolve(this.props, 'inputContainer')}>
          {elementToRender}
          {errorsPresent && (
            <Errors
              {...select(this.props, 'errorMessages')}
              {...injectTestId('error-messages')}
              errorMessages={errorMessages}
              fieldId={id || fieldName}
            />
          )}
        </div>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        {tooltip ? cloneElement(tooltip, resolve(this.props, 'tooltip')) : null}
      </WrapperTag>
    );
  }
}

/**
 * Removes any FormElement specific properties from a props object that would be invalid
 * if applied to a base DOM node. Useful when composing multiple components to prevent
 * passing a component props that it doesn't use.
 */
export function removeFormElementProps(props: any) {
  return removeKeys(props, [
    'ariaDescribedby',
    'mode',
    'view',
    'fieldName',
    'label',
    'additionalLabelText',
    'hideLabel',
    'tooltip',
    'setFocus',
    'classes',
    'onNativeChange',
    'selectedValue',
    'selectedValues',
    'errorMessages',
    'showAdditionalLabelTextInViewMode',
    'formatMask'
  ]);
}

/**
 *
 * @param {string} fieldId
 * @param {object} errorMessages
 * @returns {object} Accessibility props for validation.
 */
export function resolveValidationAccessibilityProps(fieldId: any, errorMessages: any) {
  const errorsPresent = !isEmpty(errorMessages);

  return {
    ...(errorsPresent && {
      'aria-invalid': errorsPresent
    }),
    ...(errorsPresent &&
      fieldId && {
        'aria-describedby': map(errorMessages, (errorMessage, key) => `${fieldId}-${key}`).join(' ')
      })
  };
}
