import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';
import PropTypes from 'prop-types';

type Props = {
  children?: React.ReactNode;
  style?: Record<string, any>;
  className?: string;
  ariaRole?: string;
  ariaDescribedby?: string;
  ariaLabelledby?: string;
  ariaReadonly?: boolean;
};

/**
 * Row component that wraps a group of columns.
 */
const Row = (props: Props, context: any) => {
  const {
    ariaRole,
    ariaDescribedby,
    ariaLabelledby,
    ariaReadonly,
    children,
    className = ''
  } = props;
  const { grid } = context;
  return (
    <div
      className={`${grid.row} ${className}`}
      style={props.style}
      {...(ariaRole !== undefined && { role: ariaRole })}
      {...(ariaDescribedby !== undefined && { 'aria-describedby': ariaDescribedby })}
      {...(ariaLabelledby !== undefined && { 'aria-labelledby': ariaLabelledby })}
      {...(ariaReadonly !== undefined && { 'aria-readonly': ariaReadonly })}
      {...resolveTestId(props)}
    >
      {children}
    </div>
  );
};
Row.contextTypes = {
  grid: PropTypes.object
};
Row.displayName = 'Row';

export { Row };
