import React from 'react';

import PropTypes from 'prop-types';

import { EmbeddedVideoBase } from './EmbeddedVideoBase';

/**
 * The basic UI implementation for the Video widget when it contains a Wistia video
 */
export class EmbeddedWistiaVideo extends EmbeddedVideoBase {
  static propTypes = {
    videoId: PropTypes.string,
    recordVideoViewActivity: PropTypes.func
  };

  constructor(props: any) {
    super(props);
    this.state = {
      width: 0
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.updateDimensions();
    this.window.addEventListener('resize', this.updateDimensions);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoId' does not exist on type 'Readonl... Remove this comment to see the full error message
    if (this.props.videoId) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoId' does not exist on type 'Readonl... Remove this comment to see the full error message
      this.appendEmbedScripts(this.props.videoId);
      this.registerVideoPlayer();
    }
  }

  componentWillUnmount() {
    this.window.removeEventListener('resize', this.updateDimensions);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoId' does not exist on type 'Readonl... Remove this comment to see the full error message
    this.window.Wistia?.api(this.props.videoId)?.remove();
  }

  registerVideoPlayer() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'wistiaPlayers' does not exist on type 'W... Remove this comment to see the full error message
    if (!window.wistiaPlayers || !window.wistiaPlayers[this.props.videoId]) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property '_wq' does not exist on type 'Window & ty... Remove this comment to see the full error message
      window._wq = window._wq || [];
      // @ts-expect-error ts-migrate(2339) FIXME: Property '_wq' does not exist on type 'Window & ty... Remove this comment to see the full error message
      window._wq.push({
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoId' does not exist on type 'Readonl... Remove this comment to see the full error message
        id: this.props.videoId,
        onReady: (player: any) => this.bindPlayerActions(player)
      });
    }
  }

  bindPlayerActions(player: any) {
    this.window.wistiaPlayers = this.window.wistiaPlayers || [];
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoId' does not exist on type 'Readonl... Remove this comment to see the full error message
    this.window.wistiaPlayers[this.props.videoId] = player;
    const props = this.props;
    player.bind('play', () => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'recordVideoViewActivity' does not exist ... Remove this comment to see the full error message
      if (props.recordVideoViewActivity) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'recordVideoViewActivity' does not exist ... Remove this comment to see the full error message
        props.recordVideoViewActivity(player.data.media.name);
      }
      return player.unbind;
    });
  }

  updateDimensions = () => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoId' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { videoId } = this.props;
    // Fetching the wrapper of Wistia in which the Video will be embedded
    const wrapper = this.window.document.getElementsByClassName(`wistia_async_${videoId}`);
    let width = 0;
    if (wrapper.length > 1) {
      // Width of the div created in case of Preview Mode
      width = wrapper[1].offsetWidth;
    } else if (wrapper.length > 0) {
      // Width of the original div created
      width = wrapper[0].offsetWidth;
    }
    this.setState({
      width
    });
  };

  appendEmbedScripts(videoId: any) {
    const script1Id = `wistiaScript-${videoId}`;
    let script1 = this.window.document.getElementById(script1Id);
    if (!script1 || script1.src.indexOf(videoId) === -1) {
      script1 = this.window.document.createElement('script');
      script1.src = `//fast.wistia.com/embed/medias/${videoId}.jsonp`;
      script1.async = true;
      script1.id = script1Id;
      this.window.document.body.appendChild(script1);
    }

    const script2Id = 'wistiaScript2';
    if (!this.window.document.getElementById(script2Id)) {
      const script2 = this.window.document.createElement('script');
      script2.src = '//fast.wistia.com/assets/external/E-v1.js';
      script2.async = true;
      script2.id = script2Id;
      this.window.document.body.appendChild(script2);
    }
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoId' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { videoId } = this.props;

    // Player does not appear if some value for height is not set here
    const ratioOfHeightToWidth = 2 / 3;
    const embedStyles = {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'width' does not exist on type 'Readonly<... Remove this comment to see the full error message
      height: this.state.width * ratioOfHeightToWidth
    };

    return (
      <div
        className={`wistia_embed wistia_async_${videoId}`}
        ref={node => (this.node = node)}
        style={embedStyles}
      >
        &nbsp;
      </div>
    );
  }
}
