import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';

type Props = {
  headerText?: string;
  classes?: any;
  style?: {
    panelHeaderText?: any;
  };
};

/**
 * Renders the header for interactive filters panel
 */
const PanelHeader = (props: Props) => {
  const { headerText } = props;
  return (
    <div {...resolve(props, 'panelHeaderText')}>
      <span>{headerText}</span>
    </div>
  );
};

PanelHeader.displayName = 'PanelHeader';

export { PanelHeader };
