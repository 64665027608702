/**
 * - Replace any instances of "widgetType: 'Section'" in any layout items with "sectionType: 'Section'"
 * - If for some reason the item is a 'sectionRow' without a sectionType, add the default "sectionType: 'Section'".
 */
export function layoutItemMigrations(layoutItems: any) {
  const newLayoutItems = {};

  Object.keys(layoutItems).forEach(id => {
    const newLayoutItem = { ...layoutItems[id] };

    // Convert any "widgetType: 'Section'" with "sectionType: 'Section'"
    if (newLayoutItem.widgetType && newLayoutItem.widgetType === 'Section') {
      delete newLayoutItem.widgetType;
      newLayoutItem.sectionType = 'Section';
    }

    // If for some reason the item is a 'sectionRow' without a sectionType, add the default "sectionType: 'Section'".
    if (!newLayoutItem.sectionType && newLayoutItem.layout.type === 'sectionRow') {
      newLayoutItem.sectionType = 'Section';
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    newLayoutItems[id] = newLayoutItem;
  });

  return newLayoutItems;
}
