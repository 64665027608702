import { getJSONValue, pseudoStateCascadedChange } from '../../../utils';
import {
  getFontWeightAndStyle,
  resolvePaletteFont,
  getCustomFontFamilyOptions,
  getGroupedFontFamilyOptions
} from '../Fonts/utils';
import { getFontFamilyId } from '../Fonts/getFontFamilyId';
import { FontFamilyOptions, getFontFamilyWeights } from '../Fonts/getFontFamilyWeights';
import { getStyleObjectValue } from '../getStyleObjectValue';

export const textColor = {
  fieldName: 'text.color',
  component: 'ColorPickerField',
  cascadedChange: pseudoStateCascadedChange(),
  options: {
    label: '_fieldLabel_textColor__resx',
    usePalette: true
  }
};

export const textHeader = {
  component: 'Header',
  options: {
    title: '_fieldCategory_elementText__resx'
  }
};

export const textFontFamily = {
  fieldName: 'text.fontFamily',
  component: 'SelectField',
  cascadedChange: (fieldName: any, value: any, onChange: any, valueObject: any, state: any) => {
    const path = fieldName.substring(0, fieldName.lastIndexOf('.'));
    const fontWeight = getJSONValue(valueObject, 'text.fontWeight');
    const fontStyle = getJSONValue(valueObject, 'text.fontStyle');
    const { fontWeight: newFontWeight, fontStyle: newFontStyle } = getFontWeightAndStyle({
      fontFamily: resolvePaletteFont(state, value),
      fontWeight,
      fontStyle,
      customFonts: state.customFonts
    });

    if (fontWeight !== newFontWeight) {
      onChange(`${path}.fontWeight`, newFontWeight);
    }
    if (fontStyle !== newFontStyle) {
      onChange(`${path}.fontStyle`, newFontStyle);
    }
  },
  options: {
    label: '_fieldLabel_fontFamily__resx',
    getOptionArray: (state: any) => {
      const fontPalette = state.website.theme.global.fontPalette || {};
      const fontOptions = [];
      const customFontFamilyOptions = getCustomFontFamilyOptions(state.customFonts) || [];
      const fontFamilyOptions = [...FontFamilyOptions, ...customFontFamilyOptions];
      const primaryFontPalette = getFontFamilyId({
        fontFamily: fontPalette.primary,
        customFonts: state.customFonts
      });
      const secondaryFontPalette = getFontFamilyId({
        fontFamily: fontPalette.secondary,
        customFonts: state.customFonts
      });
      // If the primary font exists and is found in the options,
      // add a special entry for it at the beginning of the list.
      const primaryFont = fontFamilyOptions.find(font => font.value === primaryFontPalette);
      if (primaryFont) {
        fontOptions.push({
          name: state.text.translate('NucleusSiteEditor_Theme_PrimaryFontOption__resx', {
            fontName: state.text.translate(primaryFont.name)
          }),
          value: 'primary'
        });
      }

      // If the secondary font exists and is found in the options,
      // add a special entry for it at the beginning of the list.
      const secondaryFont = fontFamilyOptions.find(font => font.value === secondaryFontPalette);
      if (secondaryFont) {
        fontOptions.push({
          name: state.text.translate('NucleusSiteEditor_Theme_SecondaryFontOption__resx', {
            fontName: state.text.translate(secondaryFont.name)
          }),
          value: 'secondary'
        });
      }

      return [
        ...fontOptions,
        ...getGroupedFontFamilyOptions(FontFamilyOptions, customFontFamilyOptions)
      ];
    },
    getSelectedValue: (state: any, props: any) => {
      return getFontFamilyId({
        fontFamily: getStyleObjectValue(state, props, 'text.fontFamily'),
        customFonts: state.customFonts
      });
    }
  }
};

export const textFontSize = {
  fieldName: 'text.fontSize',
  component: 'SliderField',
  options: {
    label: '_fieldLabel_fontSize__resx',
    min: 2,
    max: 100
  }
};

export const textFontWeight = {
  // Font weight actually represents both font-weight and font-style css properties,
  // and it needs to be dynamic based off of what font family is selected.
  fieldName: 'text.fontWeight',
  component: 'SelectField',
  cascadedChange: (fieldName: any, value: any, onChange: any) => {
    // Set the actual font weight instead of the object we have in the options array.
    onChange(fieldName, value.fontWeight);

    // Set the font style.
    const path = fieldName.substring(0, fieldName.lastIndexOf('.'));
    onChange(`${path}.fontStyle`, value.fontStyle);
  },
  options: {
    label: '_fieldLabel_fontWeight__resx',
    getSelectedValue: (state: any, props: any) => {
      const fontWeight = getStyleObjectValue(state, props, 'text.fontWeight');
      const fontStyle = getStyleObjectValue(state, props, 'text.fontStyle') || 'normal';

      return getFontWeightAndStyle({
        fontFamily: resolvePaletteFont(state, getStyleObjectValue(state, props, 'text.fontFamily')),
        fontWeight,
        fontStyle,
        customFonts: state.customFonts
      });
    },
    getOptionArray: (state: any, props: any) => {
      const fontFamily = resolvePaletteFont(
        state,
        getStyleObjectValue(state, props, 'text.fontFamily')
      );
      return getFontFamilyWeights(fontFamily, state.customFonts);
    }
  }
};

export default {
  textHeader,
  'text.color': textColor,
  'text.fontFamily': textFontFamily,
  'text.fontSize': textFontSize,
  'text.fontWeight': textFontWeight
};
