import { setJSONValue } from './fields/setJSONValue';

// Returns localized user entered text.
export function getLocalizedUserText({ localizedUserText = {}, path, baseText }: any) {
  if (
    !localizedUserText.localizations ||
    !localizedUserText.localizations[localizedUserText.currentLocale]
  ) {
    return baseText;
  }

  const localization = localizedUserText.localizations[localizedUserText.currentLocale];

  if (typeof baseText === 'object') {
    return Object.entries(localization).reduce((localizedValue, [key, value]) => {
      const newPath = `${path}.`;
      if (key.startsWith(newPath)) {
        const newKey = key.replace(newPath, '');
        return setJSONValue(localizedValue, newKey, value);
      }
      return localizedValue;
    }, baseText);
  }

  if (localization[path] === undefined) {
    return baseText;
  }

  return localization[path];
}

export function getLocalizedNavigationGroupName({ localizedUserText, id, baseText }: any) {
  return getLocalizedUserText({
    localizedUserText,
    path: `website.pluginData.websiteNavigation.navigationGroups.${id}.name`,
    baseText
  });
}

export function getLocalizedPageName({ localizedUserText, id, baseText, key = 'name' }: any) {
  return getLocalizedUserText({
    localizedUserText,
    path: `website.pages.${id}.${key}`,
    baseText
  });
}

export function getLocalizedSharedData({ localizedUserText, baseText, widgetType }: any) {
  return getLocalizedUserText({
    localizedUserText,
    path: `website.siteInfo.sharedConfigs.${widgetType}`,
    baseText
  });
}

export function getLocalizedAppData({ localizedUserText, baseText, path }: any) {
  const newPath = Array.isArray(path) ? path.join('.') : path;
  return getLocalizedUserText({
    localizedUserText,
    path: `appData.${newPath}`,
    baseText
  });
}
