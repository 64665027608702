import unset from 'lodash/unset';
import { WebsiteV7 } from './WebsiteV7';

function isV1HoverStyling(element: {
  hover?: { hoverMode?: string; background?: { colorAlpha?: number } };
}) {
  return element?.hover?.hoverMode === undefined && element?.hover?.background?.colorAlpha === 75;
}

/**
 * Unsets the hover styling for both primary and secondary buttons in the website theme if they are
 * using the V1 hover styling.
 *
 * The V1 hover styling is identified by the absence of a hoverMode and a colorAlpha value of 75.
 *
 * @param {Website} website - The website object which contains the theme details.
 * @returns {Website} - The updated website object with the V1 hover styling unset.
 */
export function unsetV1HoverStyling(website: WebsiteV7): WebsiteV7 {
  if (isV1HoverStyling(website.theme.global.elements.primaryButton)) {
    unset(website, 'theme.global.elements.primaryButton.hover');
  }

  if (isV1HoverStyling(website.theme.global.elements.secondaryButton)) {
    unset(website, 'theme.global.elements.secondaryButton.hover');
  }

  if (isV1HoverStyling(website.theme.global.elements.input)) {
    unset(website, 'theme.global.elements.input.hover');
  }

  return website;
}
