import unescape from 'lodash/unescape';

import { AllQuestions, IChoice } from '../../../types';
import { SET_FOLDER_QUESTIONS } from './actions';

function decodeHtmlText(questionsArray: AllQuestions[]) {
  const questions = questionsArray.map(question => {
    const questionType = question.questionTypeInfo?.questionType;
    if (
      questionType === 'SingleChoice' ||
      questionType === 'MultiChoice' ||
      questionType === 'MatrixSinglePerRow' ||
      questionType === 'MatrixMultiPerRow'
    ) {
      const choices = question.questionTypeInfo.choices.map((choice: IChoice) => ({
        ...choice,
        text: unescape(choice.text)
      }));
      if (questionType === 'MatrixSinglePerRow' || questionType === 'MatrixMultiPerRow') {
        const categories = question.questionTypeInfo.categories.map((category: IChoice) => ({
          ...category,
          text: unescape(category.text)
        }));
        return {
          ...question,
          questionTypeInfo: {
            ...question.questionTypeInfo,
            choices,
            categories
          }
        };
      }
      return {
        ...question,
        questionTypeInfo: {
          ...question.questionTypeInfo,
          choices
        }
      };
    }
    return question;
  });
  return questions;
}

export function setFolderQuestions(folderId: string, decodeText = true) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const client = state.clients.questionLibraryClient;

    try {
      const res = await client.fetchQuestions(folderId);
      dispatch({ type: SET_FOLDER_QUESTIONS, payload: { folderId, questionArray: res.data } });
      if (decodeText) {
        return decodeHtmlText(res.data);
      }
      return res.data;
    } catch (err) {
      return false;
    }
  };
}
