import { EditorPermissions as Permissions } from './EditorPermissions';
import { getPermissions } from './getPermissions';

/**
 * A field permission can only be more restrictive than its parent field's permission. So if the parent is hidden,
 * the field must be hidden. If the parent is read only, the field can be read only or hidden.
 */
function resolveWithParentPermission(fieldPermission: any, parentPermission: any) {
  switch (parentPermission) {
    case Permissions.NONE:
      return Permissions.NONE;
    case Permissions.READ_ONLY:
      return fieldPermission === Permissions.NONE ? Permissions.NONE : Permissions.READ_ONLY;
    default:
      return fieldPermission;
  }
}

/**
 * Get an editor field's permission based off the getPermissions function in combination
 * with any passed in parent field permission.
 */
export function getFieldPermission({
  state,
  item,
  itemType,
  fieldId,
  field,
  fieldNamePrefix,
  parentPermission = Permissions.FULL
}: any) {
  // Get the processed fieldName for the field.
  let fieldName: any;
  if (field.menu) {
    if (field.key === undefined) {
      fieldName = fieldNamePrefix ? `${fieldNamePrefix}.${fieldId}` : fieldId;
    } else if (field.key) {
      fieldName = fieldNamePrefix ? `${fieldNamePrefix}.${field.key}` : field.key;
    } else {
      fieldName = fieldNamePrefix || '';
    }
  } else {
    if (fieldNamePrefix && field.fieldName) {
      fieldName = `${fieldNamePrefix}.${field.fieldName}`;
    } else if (fieldNamePrefix && !field.fieldName) {
      fieldName = fieldNamePrefix;
    } else if (!fieldNamePrefix && field.fieldName) {
      fieldName = field.fieldName;
    }
  }

  // Get the field permissons for the given item type.
  const fieldTypePermissions = getPermissions(state).fields[itemType];
  if (!fieldTypePermissions) {
    return resolveWithParentPermission(Permissions.FULL, parentPermission);
  }

  // Get base permission
  let permission = fieldTypePermissions.base || Permissions.FULL;

  // Check for any regex matches against the field name.
  if (fieldTypePermissions.fieldNamesRegex) {
    Object.keys(fieldTypePermissions.fieldNamesRegex).forEach(regexString => {
      // Test both the processed fieldName and the raw fieldId against the regex.
      const regex = new RegExp(regexString);
      if (regex.test(fieldName) || regex.test(fieldId)) {
        permission = fieldTypePermissions.fieldNamesRegex[regexString];
      }
    });
  }

  if (itemType === 'layoutItem') {
    // Check for any specific layout item type overrides.
    const { layoutItemTypes } = fieldTypePermissions;
    if (layoutItemTypes) {
      Object.keys(layoutItemTypes).forEach(layoutItemType => {
        if (item.widgetType === layoutItemType || item.sectionType === layoutItemType) {
          const layoutItemFieldNameOverrides = layoutItemTypes[layoutItemType];
          if (layoutItemFieldNameOverrides.hasOwnProperty(fieldName)) {
            permission = layoutItemFieldNameOverrides[fieldName];
          } else if (layoutItemFieldNameOverrides.hasOwnProperty(fieldId)) {
            permission = layoutItemFieldNameOverrides[fieldId];
          }
        }
      });
    }
  } else if (itemType === 'theme') {
    // Check for any specific layout item type overrides.
    const { themeSectionTypes } = fieldTypePermissions;
    if (themeSectionTypes) {
      Object.keys(themeSectionTypes).forEach(themeSectionType => {
        if (item.id === themeSectionType) {
          const themeSectionFieldNameOverrides = themeSectionTypes[themeSectionType];
          if (themeSectionFieldNameOverrides.hasOwnProperty(fieldName)) {
            permission = themeSectionFieldNameOverrides[fieldName];
          } else if (themeSectionFieldNameOverrides.hasOwnProperty(fieldId)) {
            permission = themeSectionFieldNameOverrides[fieldId];
          }
        }
      });
    }
  }

  return resolveWithParentPermission(permission, parentPermission);
}
