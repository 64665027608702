import { addDefaultLogoToWebsiteNavigator } from './addDefaultLogoToWebsiteNavigator';
import { addBlocksMood } from './addBlocksMood';
import { addDefaultThemeSectionId } from './addDefaultThemeSectionId';
import { WebsiteV8 } from './WebsiteV8';
import { addBlocksSectionThemes } from './addBlocksSectionThemes';
import { migrateExistingSectionStyles } from './migrateExistingSectionStyles';

/**
 * Version 8 migrations for the website.
 */
export function v8(website: WebsiteV8) {
  const withFrameworkVersion = {
    ...website,
    frameworkVersion: 8
  };
  const withDefaultLogo = addDefaultLogoToWebsiteNavigator(withFrameworkVersion);
  const withBlocksMood = addBlocksMood(withDefaultLogo);
  const withBlocksSections = addBlocksSectionThemes(withBlocksMood);
  const withDefaultThemeSectionId = addDefaultThemeSectionId(withBlocksSections);
  const withMigratedSections = migrateExistingSectionStyles(withDefaultThemeSectionId);
  return withMigratedSections;
}
