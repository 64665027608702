import React, { cloneElement } from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';

import { KEY_RETURN, KEY_SPACE } from '../utils/keyCodes';

type OwnProps = React.PropsWithChildren<{
  fieldId?: string;
  fieldName?: string;
  frameName?: string;
  method?: 'put' | 'post';
  formAction: string;
  deferSubmit?: boolean;
  fileFormats?: any[];
  maxFileSize?: number;
  disabled?: boolean;
  onFileUploadStart?: (...args: any[]) => any;
  onFileUploadResponse?: (...args: any[]) => any;
  onFileSizeError?: (...args: any[]) => any;
  onFileTypeError?: (...args: any[]) => any;
  isChildPlainHtml?: boolean;
  ariaLabel?: string;
  classes?: Record<string, unknown>;
  style?: Record<string, unknown>;
}>;

type State = any;

type Props = OwnProps & typeof FileUpload.defaultProps;
/**
 * IE9+ Compatible FileUpload Component
 * Uses a hidden iFrame to upload file to a given endpoint
 * */

class FileUpload extends React.Component<Props, State> {
  static displayName = 'FileUpload';

  static defaultProps = {
    maxFileSize: 10485760,
    ariaLabel: ''
  };

  defaultFrameName: any;
  inlineHidden: any;
  uploadForm: any;
  uploadIframe: any;
  uploadInput: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isSubmitting: false,
      fileSelected: false
    };
    // If frameName is not provided use simple generated hash for form target //
    this.defaultFrameName = `target${((Math.random() * 0xffffff) << 0).toString(16)}`;
    this.onFileChange = this.onFileChange.bind(this);
    this.onIFrameLoad = this.onIFrameLoad.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.triggerUpload = this.triggerUpload.bind(this);
    this.clearSelectedFile = this.clearSelectedFile.bind(this);
    this.keyPressDown = this.keyPressDown.bind(this);

    this.inlineHidden = {
      position: 'absolute',
      clip: 'rect(0 0 0 0)',
      border: '0',
      height: '1px',
      width: '1px',
      margin: '-1px',
      overflow: 'hidden',
      padding: '0',
      visibility: 'hidden'
    };
  }

  componentDidMount() {
    const frame = this.uploadIframe;
    if (frame.addEventListener) {
      frame.addEventListener('load', () => this.onIFrameLoad());
    } else if (frame.attachEvent) {
      frame.attachEvent('load', () => this.onIFrameLoad());
    }
  }

  onFileChange() {
    const form = this.uploadInput;
    this.setState({
      fileSelected: true
    });
    if (this.isValidFileType(form) && this.isValidFileSize(form)) {
      if (!this.props.deferSubmit) {
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
        this.submitForm();
      }
    }
  }

  onIFrameLoad() {
    if (this.state.isSubmitting) {
      this.setState({ isSubmitting: false });
      const frame = this.uploadIframe;
      const content = frame.contentDocument || frame.contentWindow.document;

      let response;
      if (content.body && content.body.innerText) {
        response = JSON.parse(content.body.innerText);
      }
      if (this.props.onFileUploadResponse) {
        this.props.onFileUploadResponse(response);
      }
    }
  }

  clearSelectedFile() {
    this.uploadForm.reset();
    this.setState({
      fileSelected: false
    });
  }

  isValidFileType(form: any) {
    const fileName = form.files && form.files.length ? form.files[0].name : form.value;
    // Take care of the case where the widget tries to fire upload without a file
    if (!fileName || fileName === '') {
      return false;
    }
    const { fileFormats } = this.props;
    if (!fileFormats) {
      return true;
    }
    // Match exact file extension (without .)
    const extension = fileName.split('.').pop();
    const isValid = new RegExp('^(' + fileFormats.join('|') + ')$', 'i').test(extension);
    if (!isValid && this.props.onFileTypeError) {
      this.props.onFileTypeError(extension);
    }
    return isValid;
  }

  isValidFileSize(form: any) {
    const fileSize = form.files && form.files.length ? form.files[0].size : 0;
    const { maxFileSize } = this.props;
    const isValid = fileSize > 0 && fileSize <= maxFileSize;
    if (!isValid && this.props.onFileSizeError) {
      this.props.onFileSizeError();
    }
    return isValid;
  }

  submitForm(evt: any) {
    this.setState({ isSubmitting: true });
    this.uploadForm.submit(evt);
    if (this.props.onFileUploadStart) {
      this.props.onFileUploadStart();
    }
  }

  keyPressDown(evt: any) {
    if (evt.keyCode === KEY_SPACE || evt.keyCode === KEY_RETURN) {
      this.triggerUpload(evt);
    }
  }

  triggerUpload = (evt: any) => {
    if (evt) {
      evt.preventDefault();
    }
    this.uploadInput.click();
  };

  render() {
    const { children, disabled, fieldId, fieldName, method = 'post', formAction } = this.props;
    const elements = this.props.isChildPlainHtml
      ? children
      : React.Children.map(children, child => {
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          return cloneElement(child, {
            triggerUpload: this.triggerUpload,
            uploadInput: this.uploadInput,
            clearSelectedFile: this.clearSelectedFile,
            fileSelected: this.state.fileSelected
          });
        });

    return (
      <div {...resolve(this.props, 'container')}>
        <iframe
          key="uploadFrame"
          src=""
          id={this.props.frameName || this.defaultFrameName}
          name={this.props.frameName || this.defaultFrameName}
          style={this.inlineHidden}
          ref={c => (this.uploadIframe = c)}
          height="0"
          width="0"
          frameBorder="0"
          aria-hidden="true"
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
          tabIndex="-1"
        />
        <form
          {...resolve(this.props, 'form')}
          ref={c => (this.uploadForm = c)}
          action={formAction}
          method={method}
          encType="multipart/form-data"
          target={this.props.frameName || this.defaultFrameName}
          onSubmit={this.submitForm}
        >
          <label
            htmlFor={fieldId || fieldName}
            aria-label={this.props.ariaLabel}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
            tabIndex="0"
            role="button"
            onKeyDown={this.keyPressDown}
          >
            {/*
              <input type="file" /> cannot be nested inside <label> for Edge browser < 15.15
              https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8282613/
            */}
            {elements}
          </label>
          <input
            {...resolve(this.props, 'fileInput')}
            disabled={disabled}
            type="file"
            id={fieldId || fieldName}
            name={fieldName}
            ref={c => (this.uploadInput = c)}
            onChange={this.onFileChange}
            aria-hidden="true"
            tabIndex="-1"
          />
        </form>
      </div>
    );
  }
}

export { FileUpload };
