import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

/**
 * A simple function to determine if the text is present and not blank.
 */
function textPresent(text: any) {
  return text && text.trim();
}

type Props = {
  location?: string;
  address?: {
    address1?: string;
    address2?: string;
    address3?: string;
    city?: string;
    stateCode?: string;
    postalCode?: string;
    countryCode?: string;
    countryName?: string;
  };
};

/**
 * A component to display a location and address. If any items are missing
 * they will simply not appear in the component.
 */
export class Location extends React.Component<Props> {
  static displayName = 'Location';
  render() {
    const { location, address } = this.props;
    const cityPadding =
      address && (textPresent(address.stateCode) || textPresent(address.postalCode)) ? ', ' : '';
    const stateCodePadding = address && textPresent(address.postalCode) ? ' ' : '';
    return (
      <div {...resolveTestId(this.props)} {...resolve(this.props, 'container')}>
        <h2 {...resolve(this.props, 'name')}>{location}</h2>
        <div {...resolve(this.props, 'address')}>
          {address && (
            <div>
              {/* TODO: Determine if these div's can be removed */}
              <div>{address.address1}</div>
              <div>{address.address2}</div>
              <div>{address.address3}</div>
              <div>
                {textPresent(address.city) && (
                  // TODO: Determine if this span can be removed
                  <span>{address.city + cityPadding}</span>
                )}
                {textPresent(address.stateCode) && (
                  // TODO: Determine if this span can be removed
                  <span>{address.stateCode + stateCodePadding}</span>
                )}
                {/* TODO: Determine if this span can be removed */}
                <span>{address.postalCode}</span>
              </div>
              <div>{address.countryName ? address.countryName : address.countryCode}</div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
