import switchLocale from './switchLocale';

// Memorizing the results by locale
const formats = {};
/**
 * Returns an object containing time formatting information based on local.
 * This function creates a known time, and matches it with a regex.
 * Defaults to 24 hour format, with colon as the separator b/w hours and minutes.
 * @param {string} - locale
 * @return {object}
 **/
function getLocaleTimeFormat(locale) {
  if (formats[locale]) return formats[locale];
  let format;
  // The first capturing group captures text matching hours, 14 or 2 or 02.
  // The second capturing group captures separator if it exists.
  // The last capturing group stores periods, e.g. AM, PM, or p.m.
  const regex = new RegExp('^(14|2|02)(\\W)?30\\s*(.*?)$');
  const options = { hour: '2-digit', minute: '2-digit' };
  const afternoonTime = new Date(2003, 1, 1, 14, 30);
  const morningTime = new Date(2003, 1, 1, 2, 30);
  const getCapturingGroups = time => {
    let formattedTimeString;
    try {
      formattedTimeString = new Intl.DateTimeFormat(locale, options);
    } catch (error) {
      formattedTimeString = new Intl.DateTimeFormat(
        switchLocale(locale),
        options
      );
    }
    // IE11 adds hidden characters when converting a date to string. .replace(/[^\x00-\x7F]/g, '') strips them out.
    // $FlowIssue Not recognizing Intl as a built-in object object https://github.com/facebook/flow/issues/2801
    formattedTimeString = formattedTimeString
      .format(time)
      .replace(/[^\x00-\x7F]/g, '');
    return formattedTimeString.match(regex);
  };
  const afternoonTimeArray = getCapturingGroups(afternoonTime);
  // separator defaults to ":"
  const separator = (afternoonTimeArray && afternoonTimeArray[2]) || ':';
  const hours = afternoonTimeArray && afternoonTimeArray[1];
  const afterNoonLabel = afternoonTimeArray && afternoonTimeArray[3];
  const morningTimeArray = getCapturingGroups(morningTime);
  const beforeNoonLabel = morningTimeArray && morningTimeArray[3];
  if (hours && parseInt(hours, 10) === 2 && afterNoonLabel && beforeNoonLabel) {
    format = {
      separatorSymbol: separator,
      use24hours: false,
      periodConfig: {
        beforeNoonLabel,
        beforeNoonKey: beforeNoonLabel[0].toLocaleLowerCase(),
        afterNoonLabel,
        afterNoonKey: afterNoonLabel[0].toLocaleLowerCase()
      }
    };
  } else {
    format = {
      separatorSymbol: separator,
      use24hours: true
    };
  }
  formats[locale] = format;
  return format;
}

export default getLocaleTimeFormat;
