import { getSeparator, stripHiddenChars } from './getLocaleDateString';

/**
 * Returns a date given a string. Returns null if input cannot be paresed into a valid date.
 * @param {string} - string to be converted into a date
 * @param {string} - format the conversion bases on
 * @return {date}
 **/
function resolveDateFromString(dateString, format = 'mm/dd/yyyy') {
  const separator = getSeparator(format);
  const inputStringArray = dateString.split(separator);
  if (inputStringArray.length < 3) {
    return null;
  }

  // Set values for day, month and year
  let month;
  let day;
  let year;
  const formatArray = format.split(separator);
  if (formatArray.length < inputStringArray.length) {
    return null;
  }

  formatArray.forEach((c, index) => {
    const chunk = stripHiddenChars(c);
    let input = stripHiddenChars(inputStringArray[index]);
    if (chunk === 'd' || chunk === 'dd') {
      // Addition of new check for checking extra space at start in day.
      day =
        (input.length > 2 && input[0] === '0') ||
        (input.length === 2 && input[1] === '.')
          ? null
          : Number(input);
    } else if (chunk === 'm' || chunk === 'mm') {
      // Addition of new check for checking extra space at start in month.
      month =
        (input.length > 2 && input[0] === '0') ||
        (input.length === 2 && input[1] === '.')
          ? null
          : Number(input);
    } else if (chunk === 'yy') {
      year = input.length !== chunk.length ? null : Number(input);
      // Adjust two digit years if necessary.
      if (year && year < 50) {
        year += 2000;
      } else if (year && year < 100) {
        year += 1900;
      }
    } else if (chunk === 'yyyy') {
      // Check for removing extra space from start and end in case of date having separator period.
      if (input.length === 5) {
        if (input[0] === ' ') {
          input = input.slice(1);
        } else {
          input = input.slice(0, -1);
        }
      }
      year = input.length !== chunk.length ? null : Number(input);
    }
  });

  // Make sure the month, day, and year exist.
  if (!month || !day || !year || year.toString().length < 4) {
    return null;
  }

  // Create the date object.
  const newDate = new Date(year, month - 1, day);
  // Checks to see if month and day are valid.
  if (newDate.getDate() !== day || newDate.getMonth() !== month - 1) {
    return null;
  }

  return newDate;
}

export default resolveDateFromString;
