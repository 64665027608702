import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

type OwnProps = {
  numberOfCircles?: 1 | 2 | 3 | '1' | '2' | '3';
  fallbackText?: string;
  hasBackground?: boolean;
  useTheme?: boolean;
  outerCircleBackgroundColor?: string;
  outerCircleColor?: string;
  outerCircleLightendColor?: string;
  classes?: {
    container?: string;
    hidden?: string;
    outerCircle?: string;
    middleCircle?: string;
    innerCircle?: string;
  };
  style?: {
    container?: any;
    hidden?: any;
    outerCircle?: any;
    middleCircle?: any;
    innerCircle?: any;
  };
};

type Props = OwnProps & typeof Spinner.defaultProps;

/**
Spinner with three nested circles spinning at different speed.
**/
export class Spinner extends React.PureComponent<Props> {
  static displayName = 'Spinner';
  static defaultProps = {
    numberOfCircles: 3
  };
  render() {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
    const numberOfCircles = parseInt(this.props.numberOfCircles, 10);
    const { fallbackText, hasBackground, useTheme } = this.props;

    const bgColor = this.props.outerCircleBackgroundColor || '#f7f8f9';
    const spinnerColor = this.props.outerCircleColor || '#006ae1';
    const lightenSpinnerColor = this.props.outerCircleLightendColor || '#aed4ff';
    const outerCircleComp = useTheme
      ? {
          ...resolve(this.props, 'outerCircle'),
          style: { borderColor: spinnerColor, borderBottomColor: lightenSpinnerColor }
        }
      : { ...resolve(this.props, 'outerCircle') };
    const spinner = (
      <div {...resolve(this.props, 'container')} {...resolveTestId(this.props)}>
        {fallbackText && <div {...resolve(this.props, 'hidden')}>{fallbackText}</div>}
        <div {...outerCircleComp} />
        {numberOfCircles > 1 && <div {...resolve(this.props, 'middleCircle')} />}
        {numberOfCircles > 2 && <div {...resolve(this.props, 'innerCircle')} />}
      </div>
    );
    return hasBackground ? (
      <div
        {...resolve(this.props, 'outerContainer')}
        // @ts-expect-error ts-migrate()
        {...resolveTestId(`${this.props}_background`)}
        style={{ background: bgColor }}
      >
        {spinner}
      </div>
    ) : (
      spinner
    );
  }
}
