import { PseudoStateStyleObject, PseudoStateStyleCalculator } from './types';
import { InvertedStyleModeCalculator } from './InvertedStyleModeCalculator';
import { BrightnessStyleModeCalculator } from './BrightnessStyleModeCalculator';
import { convertThemePaletteColor } from '../fields/convertThemePaletteColor';
import chroma from 'chroma-js';

const BRIGHTNESS_MODIFIER_VALUE = 1.0;
const CONTRAST_RATIO_TO_DARKEN_BRIGHTNESS = 1.5;

function getBrightnessModifier(
  baseStyle: Partial<PseudoStateStyleObject>,
  themePalette: Record<string, string>
): number {
  const backgroundColor = convertThemePaletteColor(themePalette, baseStyle.background?.color);
  if (!backgroundColor) {
    return 0;
  }

  if (
    chroma.contrast(
      backgroundColor,
      chroma(chroma(backgroundColor)).darken(BRIGHTNESS_MODIFIER_VALUE)
    ) > CONTRAST_RATIO_TO_DARKEN_BRIGHTNESS
  ) {
    return BRIGHTNESS_MODIFIER_VALUE;
  }

  return -BRIGHTNESS_MODIFIER_VALUE;
}

/**
 * Automatically sets BRIGHTNESS or INVERTED mode.
 * @see PseudoStateStyleMode#AUTOMATIC
 */
export const AutomaticStyleModeCalculator: PseudoStateStyleCalculator = {
  updateStyle(
    baseStyle: Partial<PseudoStateStyleObject>,
    pseudoStateStyle: Partial<PseudoStateStyleObject>,
    themePalette: Record<string, string>
  ): Partial<PseudoStateStyleObject> {
    if (!baseStyle.background?.color) {
      return InvertedStyleModeCalculator.updateStyle(baseStyle, pseudoStateStyle, themePalette);
    }

    return BrightnessStyleModeCalculator.updateStyle(baseStyle, pseudoStateStyle, themePalette, {
      brightnessModifier: getBrightnessModifier(baseStyle, themePalette)
    });
  }
};
