import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import { Button, SelectFormElement as Select, removeKeys } from 'nucleus-core';

import { DisplayFormats } from './DisplayFormats';
import Classes from './LanguageSelector.less';

// Renders the language list in a horizontal list with each language being a link.
function ListSelector(props: any) {
  const { currentLocale, options = [], onSelectLanguage, style } = props;
  const onClickLanguage = (value: any) => () => {
    if (value !== currentLocale && onSelectLanguage) {
      onSelectLanguage(value);
    }
  };

  return (
    <ul
      {...resolve(
        {
          classes: Classes,
          style: { list: { textAlign: style.link.textAlign } }
        },
        'list'
      )}
    >
      {options.map((option: any) => {
        const buttonStyle = {
          link: {
            ...style.link,
            ...(option.value === currentLocale ? { fontWeight: 'bold' } : {})
          }
        };
        return (
          <li
            key={option.value}
            {...resolve(
              {
                classes: Classes,
                style: { listItem: { borderColor: style.link.color } }
              },
              'listItem'
            )}
          >
            <Button
              classes={Classes}
              style={buttonStyle}
              kind="link"
              onClick={onClickLanguage(option.value)}
            >
              {option.name}
            </Button>
          </li>
        );
      })}
    </ul>
  );
}

// Renders the language list in a dropdown.
function DropdownSelector(props: any) {
  const { selectorLabel, currentLocale, options = [], onSelectLanguage, style, widgetID } = props;
  const changeLanguage = (fieldName: any, value: any) => {
    if (value !== currentLocale && onSelectLanguage) {
      onSelectLanguage(value);
    }
  };
  const { paddingTop, paddingBottom, paddingLeft, paddingRight, textAlign } = style.select || {};

  // Apply paddings to the entire dropdown, including its label,
  // to keep the label and the select aligned.
  const selectStyles = {
    ...style,
    element: {
      ...style.element,
      paddingTop,
      paddingBottom,
      paddingLeft,
      paddingRight,
      textAlign
    },
    select: {
      ...removeKeys(style.select || {}, [
        'paddingLeft',
        'paddingTop',
        'paddingBottom',
        'paddingRight',
        'textAlign'
      ])
    }
  };
  return (
    <Select
      classes={Classes}
      style={selectStyles}
      fieldName={`${widgetID}:language-selector`}
      label={selectorLabel}
      options={{
        selectedValue: currentLocale,
        optionArray: options
      }}
      onChange={changeLanguage}
    />
  );
}

type LanguageSelectorProps = {
  displayFormat?: any; // TODO: PropTypes.oneOf([DisplayFormats.LIST, DisplayFormats.DROPDOWN])
  options?: any[];
  selectorLabel?: string;
  currentLocale?: string;
  onSelectLanguage?: (...args: any[]) => any;
  style?: any;
  widgetID?: string;
};

/**
 * Language Selector renders the language list in a drop-down or a link list.
 */
export function LanguageSelector(props: LanguageSelectorProps) {
  const { displayFormat = DisplayFormats.DROPDOWN, options } = props;
  if (options?.length === 0) {
    return null;
  }

  return (
    <div {...resolveTestId(props)} {...resolve(props, 'container')}>
      {displayFormat === DisplayFormats.DROPDOWN ? (
        <DropdownSelector {...props} />
      ) : (
        <ListSelector {...props} />
      )}
    </div>
  );
}

LanguageSelector.displayName = 'LanguageSelector';
