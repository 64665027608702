import switchLocale from './switchLocale';

/**
 * Checks and returns a common date format separator
 * @param {string} format - can be a formatted date string or a date format
 * @return {string}
 **/
export function getSeparator(format) {
  if (format.indexOf('/') !== -1) {
    return '/';
  }

  if (format.indexOf('-') !== -1) {
    return '-';
  }

  if (format.indexOf('.') !== -1) {
    return '.';
  }

  return false;
}

/**
 * IE11 adds hidden characters when converting a date to string
 * This function strips a value of any hidden charaters
 * @param {string} value
 * @return {string}
 **/
export function stripHiddenChars(value) {
  return value.replace(/[^\x00-\x7F]/g, '');
}

/**
 * Builds and returns a date format.
 * Also sets the day, month, and year indexes
 * Uses an array that has been split by a separator
 * @param {string} - formattedDateString
 * @param {string} - separator
 * @return {string}
 **/
function getFormatFromDateArray(formattedDateString, separator) {
  const dateArray = formattedDateString.split(separator);
  let localeDateFormat = '';
  if (dateArray.length >= 3) {
    dateArray.forEach((chunk, index) => {
      switch (stripHiddenChars(chunk)) {
        case '1':
          localeDateFormat += 'd';
          break;
        case '01':
          localeDateFormat += 'dd';
          break;
        case '2':
          localeDateFormat += 'm';
          break;
        case '02':
          localeDateFormat += 'mm';
          break;
        case '03':
          localeDateFormat += 'yy';
          break;
        case '2003':
          localeDateFormat += 'yyyy';
          break;
        default:
          break;
      }
      if ((index < 2 || index === 3) && localeDateFormat) {
        localeDateFormat += separator;
      }
    });
  }

  return localeDateFormat;
}

/**
 * Returns the locale date string format
 * This function creates a known date and then uses a separator to split the date string
 * From there it can determine the order of the locale's date format
 * If no date format can be found then we default to mm/dd/yyyy
 * @param {string} - locale
 * @return {string}
 **/
function getLocaleDateString(locale, options) {
  const knownDate = new Date(2003, 1, 1);
  let formattedDateString;
  try {
    formattedDateString = new Intl.DateTimeFormat(locale, options);
  } catch (error) {
    formattedDateString = new Intl.DateTimeFormat(
      switchLocale(locale),
      options
    );
  }

  formattedDateString = formattedDateString
    .format(knownDate)
    .replace(/\s/g, '');
  const separator = getSeparator(formattedDateString);
  if (!separator) {
    return false;
  }

  let localeDateFormat = getFormatFromDateArray(formattedDateString, separator);
  if (
    localeDateFormat.indexOf('d') === -1 ||
    localeDateFormat.indexOf('m') === -1 ||
    localeDateFormat.indexOf('y') === -1
  ) {
    localeDateFormat = 'mm/dd/yyyy';
  }

  return localeDateFormat;
}

export default getLocaleDateString;
