import React, { cloneElement } from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import ReactDOM from 'react-dom';
import { getBrowserDimensions } from '../utils/getBrowserDimensions';
import { removeKeys } from '../utils/removeKeys';
import { DragHandle } from './DragHandle';

/**
 * Removes any DragContainer specific properties from a props object that would be invalid
 * if applied to a base DOM node. Useful when composing multiple components to prevent
 * passing a component props that it doesn't use.
 */
export function removeDragContainerProps(props: any) {
  return removeKeys(props, [
    'children',
    'allowOutOfViewport',
    'containerHeight',
    'containerWidth',
    'allowDragging',
    'centerOnMount',
    'initialLeft',
    'initialTop',
    'maxLeft',
    'maxTop',
    'minLeft',
    'minTop',
    'onDragHandler',
    'classes'
  ]);
}

const DRAG_CONTAINER_MARGIN = 25;

type OwnProps = React.PropsWithChildren<{
  allowDragging?: boolean;
  allowOutOfViewport?: boolean;
  centerOnMount?: boolean;
  initialLeft?: number;
  initialTop?: number;
  maxLeft?: number;
  maxTop?: number;
  minLeft?: number;
  minTop?: number;
  containerHeight?: number;
  containerWidth?: number;
  style?: {
    dragContainer?: any;
  };
  classes?: any;
  onDragHandler?: (...args: any[]) => any;
}>;

type State = any;

type Props = React.PropsWithChildren<OwnProps & typeof DragContainer.defaultProps>;

/**
DragContainer - A wrapper component to make the child content draggable. Must have a direct DragHandle
child component to accept the dragStart function. The container will always be forced to have an
absolute position style, as well as internally computed top and left styles.
**/
export class DragContainer extends React.Component<Props, State> {
  static displayName = 'DragContainer';
  static defaultProps = {
    allowDragging: true,
    allowOutOfViewport: true,
    centerOnMount: false,
    initialLeft: 0,
    initialTop: 0,
    maxLeft: Number.MAX_VALUE,
    maxTop: Number.MAX_VALUE,
    minLeft: 0,
    minTop: 0
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      left: props.initialLeft,
      top: props.initialTop
    };
    this.dragFunction = this.dragFunction.bind(this);
    this.endDragFunction = this.endDragFunction.bind(this);
    this.dragStart = this.dragStart.bind(this);
  }

  componentDidMount() {
    if (this.props.centerOnMount) {
      // Center the container.
      // We can't do this until after the container is mounted because we don't know how big it is until then.
      const container = ReactDOM.findDOMNode(this.refs.draggable as any);
      const browserMeasures = getBrowserDimensions(window, document);
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const containerWidth = Math.max(container.clientWidth, container.offsetWidth);
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const containerHeight = Math.max(container.clientHeight, container.offsetHeight);
      const positionTop = browserMeasures.browserHeight / 2 - containerHeight / 2;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      container.style.top = positionTop > 0 ? positionTop + 'px' : 0;
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      container.style.left =
        browserMeasures.amountScrolledX +
        browserMeasures.browserWidth / 2 -
        containerWidth / 2 +
        'px';

      /* eslint-disable react/no-did-mount-set-state */
      // This doesn't fire render again, but keeps the state in sync.
      this.setState({
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        left: container.style.left,
        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        top: container.style.top
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { initialLeft: newLeft, initialTop: newTop } = nextProps;
    const { initialLeft, initialTop } = this.props;
    if (initialLeft !== newLeft || initialTop !== newTop) {
      this.setState({
        left: newLeft,
        top: newTop
      });
    }
  }

  dragFunction(e: any) {
    // Re-render the container with the new left and top values.
    const {
      maxLeft,
      maxTop,
      minLeft,
      minTop,
      containerWidth,
      containerHeight,
      allowOutOfViewport
    } = this.props;
    const { differenceX, differenceY } = this.state;

    let newLeft = Math.min(maxLeft, Math.max(minLeft, e.clientX - this.state.differenceX));
    let newTop = Math.min(maxTop, Math.max(minTop, e.clientY - this.state.differenceY));

    if (!allowOutOfViewport && containerHeight && containerWidth) {
      const { browserWidth, browserHeight, amountScrolledY } = getBrowserDimensions(
        window,
        document
      );
      const pageHeight = document.body.scrollHeight;
      // If the flyout is touching browser's right border
      if (e.clientX + containerWidth > browserWidth) {
        newLeft = -differenceX + browserWidth - containerWidth + DRAG_CONTAINER_MARGIN;
      }
      // If the flyout is touching browser's bottom border
      if (e.clientY + containerHeight + amountScrolledY > pageHeight) {
        newTop = -differenceY + browserHeight - containerHeight + DRAG_CONTAINER_MARGIN;
      }
      // If the flyout is touching browser's left border
      if (e.clientX <= DRAG_CONTAINER_MARGIN) {
        newLeft = -differenceX + DRAG_CONTAINER_MARGIN;
      }
      // If the flyout is touching browser's top border
      if (e.clientY + amountScrolledY <= DRAG_CONTAINER_MARGIN) {
        newTop = -differenceY + DRAG_CONTAINER_MARGIN;
      }
    }

    this.setState({
      left: newLeft,
      top: newTop
    });
    if (this.props.onDragHandler) {
      this.props.onDragHandler(newLeft, newTop);
    }
  }

  endDragFunction() {
    // Remove the drag functions.
    if (typeof document !== 'undefined' && document.removeEventListener) {
      document.removeEventListener('mousemove', this.dragFunction, false);
      document.removeEventListener('mouseup', this.endDragFunction, false);
      document.removeEventListener('touchend', this.endDragFunction, false);
    }
  }

  dragStart(e: any) {
    // Stop item from Dragging if prop is set to false
    if (!this.props.allowDragging) {
      return;
    }
    // Save the difference in x and y positions to be used when dragging the container around.
    const container = ReactDOM.findDOMNode(this.refs.draggable as any);
    this.setState({
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      differenceX: e.clientX - container.offsetLeft,
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      differenceY: e.clientY - container.offsetTop
    });
    // Attach the drag and end drag events.
    if (typeof document !== 'undefined' && document.addEventListener) {
      document.addEventListener('mousemove', this.dragFunction, false);
      document.addEventListener('mouseup', this.endDragFunction, false);
      document.addEventListener('touchend', this.endDragFunction, false);
    }

    // Prevent default so that the mouse click doesnt start selecting text or elements on
    // the page while you are dragging.
    if (e.preventDefault) {
      e.preventDefault();
    }
  }

  startDragAtNewPostion(left: any, top: any, clientX: any, clientY: any) {
    this.setState(
      {
        left,
        top
      },
      () => {
        this.dragStart({ clientX, clientY });
      }
    );
  }

  applyDragStartOnDragHandle(childNodes: any) {
    let dragHandleExists = false;
    const children: any = React.Children.map(childNodes, child => {
      if (child && child.type === DragHandle) {
        dragHandleExists = true;
        return cloneElement(child, { dragStart: this.dragStart });
      } else if (child?.props?.children) {
        const data = this.applyDragStartOnDragHandle(child.props.children);

        if (!dragHandleExists) {
          dragHandleExists = data.dragHandleExists;
        }
        if (data.dragHandleExists) {
          return cloneElement(child, child.props, data.children);
        }
      }
      return child;
    });

    return { dragHandleExists, children };
  }

  render() {
    // Pass dragStart function to any DragHandle children.
    const { dragHandleExists, children } = this.applyDragStartOnDragHandle(this.props.children);

    // If no drag handle exists in the children, wrap the entire content in a drag handle.
    const content = dragHandleExists ? (
      children
    ) : (
      <DragHandle dragStart={this.dragStart}>{children}</DragHandle>
    );

    const styleObject = resolve(this.props, 'dragContainer');
    styleObject.style.position = 'absolute';
    styleObject.style.left = this.state.left;
    styleObject.style.top = this.state.top;
    return (
      <div
        {...resolveTestId(this.props)}
        {...removeDragContainerProps(this.props)}
        {...styleObject}
        ref="draggable"
      >
        {content}
      </div>
    );
  }
}
