import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';

import { BaseWidget } from '../BaseWidget';
import { EmptyCell } from './EmptyCell';
import ClassNames from './EmptyCell.less';
import { ThemeableComponentProps } from '../ThemeableComponent';

interface EmptyCellWidgetProps extends ThemeableComponentProps {
  type?: string;
  config?: Record<string, unknown>;
  classes?: Record<string, unknown>;
  style?: Record<string, unknown>;
}
/**
 * Data wrapper for the empty cell widget.
 */
export class EmptyCellWidget extends BaseWidget<EmptyCellWidgetProps> {
  static displayName = 'EmptyCellWidget';
  static defaultProps = {
    classes: ClassNames
  };
  render() {
    const { classes, style } = this.props;
    return (
      <EmptyCell
        {...resolveTestId(this.props)}
        classes={classes}
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
        style={this.getStyleObject(style)}
      />
    );
  }
}
