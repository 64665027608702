/* eslint react/prop-types: [2, { ignore: ["setFocus", "onChange", "onNativeChange", "fieldName", "id", "name",
  "required", "className"] }] */
/* eslint-disable react/no-multi-comp */
import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { injectTestId, resolveTestId } from '@cvent/nucleus-test-automation';
import { FormElement, WithFormProps, resolveValidationAccessibilityProps } from '../FormElement';

/*
(ts-migrate) TODO: Migrate the remaining prop types
...FormElement.propTypes
*/
type OwnRadioButtonProps = {
  options: any | any[];
  style?: Record<string, unknown>;
  onNativeChange?: (...args: any[]) => any;
  selectedValue?: any | any; // TODO: PropTypes.oneOf([null])
  disabled?: boolean;
};

type RadioButtonProps = OwnRadioButtonProps & typeof RadioButton.defaultProps;

/**
Display browser default input radio button.

onChange = function(fieldName, newValue, newOptions)
**/
export class RadioButton extends React.Component<RadioButtonProps> {
  static displayName = 'RadioButton';
  static defaultProps = {
    required: false,
    isPartOfRadioGroup: false
  };

  constructor(props: RadioButtonProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setFocus' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.setFocus) {
      document
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<R... Remove this comment to see the full error message
        .getElementsByName(this.props.name ? this.props.name : this.props.fieldName)[0]
        .focus();
    }
  }

  onChange(index: any, value: any) {
    let newOptionList;
    if (this.props.hasOwnProperty('onNativeChange') && this.props.hasOwnProperty('selectedValue')) {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.onNativeChange(value);
    } else {
      if (!this.props.options.hasOwnProperty('selectedValue')) {
        newOptionList = this.props.options.map((option: any, i: any) => {
          if (index === i && !option.checked) {
            return Object.assign({}, option, { checked: true });
          } else if (index !== i && option.checked) {
            return Object.assign({}, option, { checked: false });
          }
          return option;
        });
      } else {
        newOptionList = Object.assign({}, this.props.options, { selectedValue: value });
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(this.props.fieldName, value, newOptionList);
    }
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'Readonly<Rad... Remove this comment to see the full error message
    const { id, fieldName, errorMessages, isPartOfRadioGroup } = this.props;
    let hasSelectedValue: any;
    let selectedValue: any;
    let options;
    if (this.props.hasOwnProperty('selectedValue')) {
      // this prop is passed in by NucleusField
      hasSelectedValue = true;
      selectedValue = this.props.selectedValue;
      options = this.props.options;
    } else {
      if (this.props.options.hasOwnProperty('selectedValue')) {
        hasSelectedValue = true;
        options = this.props.options.optionArray;
        selectedValue = this.props.options.selectedValue;
      } else {
        hasSelectedValue = false;
        options = this.props.options;
      }
    }

    const radioButtonLabelStyles = resolve(this.props, 'radiobuttonLabel');

    const listItems = options.map((option: any, index: any) => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<R... Remove this comment to see the full error message
      const elementId = this.props.name
        ? // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<R... Remove this comment to see the full error message
          this.props.name + '_' + index
        : // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
          this.props.fieldName + '_' + index;
      const defaultDisabledStyle = option.disabled || this.props.disabled ? { opacity: 0.4 } : null;
      const resolvedStyle = {
        style: {
          ...defaultDisabledStyle,
          ...radioButtonLabelStyles.style
        }
      };
      if (radioButtonLabelStyles.className) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type '{ sty... Remove this comment to see the full error message
        resolvedStyle.className = radioButtonLabelStyles.className;
      }
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<R... Remove this comment to see the full error message
      const name = this.props.name ? this.props.name : this.props.fieldName;
      return (
        <li
          {...resolve(this.props, option.itemClassName)}
          {...injectTestId(option.testId || `option-${elementId}`)}
          key={elementId}
        >
          <input
            id={elementId}
            name={name}
            type="radio"
            value={index.toString()}
            {...resolve(this.props, option.className)}
            checked={hasSelectedValue ? option.value === selectedValue : !!option.checked}
            onChange={this.onChange.bind(null, index, option.value)}
            required={!isPartOfRadioGroup && this.props.required}
            disabled={option.disabled || this.props.disabled}
            {...resolveValidationAccessibilityProps(id || fieldName, errorMessages)}
          />
          <label {...resolvedStyle} htmlFor={elementId}>
            {option.name}
          </label>
          {option.description && (
            <span {...resolve(this.props, option.descriptionClassName)}>{option.description}</span>
          )}
        </li>
      );
    });

    return (
      <ul
        {...resolveTestId(this.props)}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'Readonly<Rad... Remove this comment to see the full error message
        id={this.props.id || this.props.fieldName}
        style={this.props.style}
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
        className={this.props.className}
      >
        {listItems}
      </ul>
    );
  }
}

type RadioButtonFormElementProps = WithFormProps & {
  children?: React.ReactNode;
  display?: string;
  baseClass?: string;
  style?: {
    radiobutton?: any;
    horizontal?: any;
  };
  isPartOfRadioGroup?: boolean;
};

/**
FormElement wrapper around RadioButton. This is the default export.
**/
const RadioButtonFormElement = ({ ...props }: RadioButtonFormElementProps) => {
  const {
    children,
    display = 'horizontal',
    baseClass = 'radiobutton',
    isPartOfRadioGroup = false,
    ...rest
  } = props;

  return (
    <FormElement {...rest} isFieldSet>
      <RadioButton
        {...rest}
        isPartOfRadioGroup={isPartOfRadioGroup}
        {...resolve(props, baseClass, display)}
        {...injectTestId('input')}
      />
      {children}
    </FormElement>
  );
};
RadioButtonFormElement.displayName = 'RadioButtonFormElement';

/**
#### Data structures for the "options" property

```js
  [
    {name: 'optionNameA', value: 'optionValueA', checked: true, disabled: true},
    {name: 'optionNameB', value: 'optionValueB', checked: false},
    ...
  ]
```

```js
  {
    selectedValue: 'optionValueA',
    optionArray: [
      {name: 'optionNameA', value: 'optionValueA', disabled: true},
      {name: 'optionNameB', value: 'optionValueB'},
      ...
    ]
  }
```

Note: If you choose to use the second one, values for the "value" and "selectedValue" properties need to be primitive.
**/

export { RadioButtonFormElement };
