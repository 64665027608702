import chroma from 'chroma-js';

/**
 * WCAG 2.0 level AA requires a contrast ratio of at least 4.5:1 for normal text.
 * @see https://www.w3.org/TR/WCAG20-TECHS/G18.html
 */
const DEFAULT_CONTRAST_RATIO_TO_ACCEPT_DEFAULT_COLOR = 4.5;

/**
 * Finds the best, contrasting color for a given `sourceColor`, either from the `defaultContrastColor` provided, or
 * from a color present within the theme palette.
 *
 * @param themePalette the theme palette for the editor.
 * @param sourceColor the source color to find a good, contrasting color for.
 * @param defaultContrastColor If provided, this will take priority as the return value if it has the
 *   appropriate contrast ratio.
 * @param contrastRatioToAcceptDefault the contrast ratio required to accept `defaultContrastColor`
 * @return the best, contrasting color for `sourceColor`
 */
export function getBestContrastColor(
  themePalette: Record<string, string>,
  sourceColor: string | undefined,
  defaultContrastColor: string | undefined,
  contrastRatioToAcceptDefault = DEFAULT_CONTRAST_RATIO_TO_ACCEPT_DEFAULT_COLOR
): string | undefined {
  if (!sourceColor) {
    return undefined;
  }

  // `defaultContrastColor` should always take priority. If `defaultContrastColor` has a sufficient contrast
  // ratio with `sourceColor`, return that.
  let bestContrastColor = defaultContrastColor;
  let bestContrastRatio = bestContrastColor ? chroma.contrast(sourceColor, bestContrastColor) : 0;
  if (bestContrastRatio > contrastRatioToAcceptDefault) {
    return bestContrastColor;
  }

  // Otherwise find a color in themePalette that contrasts well with `sourceColor`.
  for (const themeKey of Object.keys(themePalette)) {
    const themeColorContrastRatio = chroma.contrast(sourceColor, themePalette[themeKey]);
    if (themeColorContrastRatio > bestContrastRatio) {
      bestContrastColor = themeKey;
      bestContrastRatio = themeColorContrastRatio;
    }
  }

  return bestContrastColor;
}
