import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { resolve } from '@cvent/nucleus-dynamic-css';
import PropTypes from 'prop-types';

import { EmbeddedVideoBase } from './EmbeddedVideoBase';
import VideoStyle from './Video.less';

/**
 * The basic UI implementation for the Video widget when it contains a Vimeo video
 */
export class EmbeddedVimeoVideo extends EmbeddedVideoBase {
  static propTypes = {
    videoId: PropTypes.string,
    translate: PropTypes.func,
    /**
     * There are two types of videos in Vimeo.
     * One is the normal video uploaded by the user,
     * and other is the Live video, aka, Vimeo Event (as per their terminology).
     * Live video can only be used for an event in Vimeo.
     * 'isEvent' prop will tell the component, if the video URL passed as prop, is for an event or not.
     */
    isEvent: PropTypes.bool,
    recordVideoViewActivity: PropTypes.func
  };

  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    super.componentDidMount();
    this.appendEmbedScript();
    this.registerVideoPlayer();
  }

  componentWillUnmount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoId' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { videoId } = this.props;
    if (!isEmpty(this.window.vimeoPlayers) && !isEmpty(this.window.vimeoPlayers[videoId])) {
      this.window.vimeoPlayers[videoId].destroy();
      delete this.window.vimeoPlayers[videoId];
    }
  }

  appendEmbedScript() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'Vimeo' does not exist on type 'Window & ... Remove this comment to see the full error message
    if (!document.querySelector('#vim-js') && typeof window.Vimeo === 'undefined') {
      const vimeoScript = window.document.createElement('script');
      vimeoScript.id = 'vim-js';
      vimeoScript.src = 'https://player.vimeo.com/api/player.js';
      vimeoScript.async = true;
      window.document.body.appendChild(vimeoScript);
    }
  }

  registerVideoPlayer() {
    this.window.vimeoPlayers = this.window.vimeoPlayers || {};
    const script = document.querySelector('#vim-js');
    if (this.window.Vimeo) {
      this.bindPlayerActions(this.window.Vimeo);
    } else {
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      script.addEventListener('load', () => this.bindPlayerActions(this.window.Vimeo));
    }
  }

  bindPlayerActions(vm: any) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoId' does not exist on type 'Readonl... Remove this comment to see the full error message
    const iframe = document.getElementById(`vimeoPlayer-${this.props.videoId}`);
    const player = new vm.Player(iframe);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoId' does not exist on type 'Readonl... Remove this comment to see the full error message
    this.window.vimeoPlayers[this.props.videoId] = player;
    let videoTitle = '';

    player.getVideoTitle().then((title: any) => (videoTitle = title));
    player.on('play', () => {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'recordVideoViewActivity' does not exist ... Remove this comment to see the full error message
      if (this.props.recordVideoViewActivity) {
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'recordVideoViewActivity' does not exist ... Remove this comment to see the full error message
        this.props.recordVideoViewActivity(videoTitle);
      }
      player.off('play');
    });
  }

  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'videoId' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { videoId, translate, isEvent } = this.props;
    const videoStyles = { classes: VideoStyle };
    const vimeoURL = isEvent
      ? `https://vimeo.com/event/${videoId}/embed?api=1&player_id=vimeoPlayer-${videoId}`
      : `https://player.vimeo.com/video/${videoId}?api=1&player_id=vimeoPlayer-${videoId}`;

    return (
      <div {...resolve(videoStyles, 'vimeoContainer')} ref={node => (this.node = node)}>
        <iframe
          id={`vimeoPlayer-${videoId}`}
          src={vimeoURL}
          aria-label={translate('EventWidgets_Video_Vimeo__resx')}
          frameBorder="0"
          // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; src: string; "aria-label": any... Remove this comment to see the full error message
          webkitallowfullscreen
          mozallowfullscreen
          allowFullScreen
        ></iframe>
      </div>
    );
  }
}
