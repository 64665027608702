import mapValues from 'lodash/mapValues';
import { defaultMemoize } from 'reselect';

/**
 * Create a factory for use in connect that wraps mapStateToProps with memoized functions.
 *
 * E.g.
 * connect(withMemoizedFunctions({ f, g })(memoizedFunctions => (state, props) => {
 *   return {
 *     x: memoizedFunctions.f(state.a1, props.b1),
 *     y: memoizedFunctions.g(state.a2)
 *   };
 * }));
 *
 * will have the same result as
 * connect((state, props) => {
 *   return {
 *     x: f(state.a1, props.b1),
 *     y: g(state.a2)
 *   };
 * });
 *
 * except with memoization so that the identities (i.e. equality comparison via ===) of x and y will not change unless
 * the identities of one of state.a1, props.b1 or state.a2 changes.
 */
export function withMemoizedFunctions(functions: any) {
  const memoizedFunctions = mapValues(functions, f => defaultMemoize(f));
  return (f: any) => f(memoizedFunctions);
}
