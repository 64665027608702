import sanitize from '@mapbox/sanitize-caja';

/**
 * Sanitizes dangerous tags, malformed tags, potentially dangerous executable
 * script etc from an input string and returns a safe, renderable string.
 * Primarily used to make any possible user inputted markup safe. Currently uses
 * an npm package wrapper around the Google Caja sanitizer to achieve this.
 * See also:
 * https://www.npmjs.com/package/sanitize-caja
 * https://code.google.com/p/google-caja/wiki/JsHtmlSanitizer
 **/

export default function sanitizeText(inputText: any) {
  return sanitize(inputText);
}
