import React, { Component } from 'react';

import flatten from 'lodash/flatten';
import map from 'lodash/map';

import {
  parseLocalISODate,
  DateTimeInput,
  EMPTY_DATE_AND_TIME,
  switchLocale,
  DATE_CHANGE_SUCCESS,
  DATE_CHANGE_EMPTY_DATE
} from '@cvent/nucleus-core-datetime-utils';
import { select } from '@cvent/nucleus-dynamic-css';
import { injectTestId, resolveTestId } from '@cvent/nucleus-test-automation';
import { defaultMemoize } from 'reselect';
import { FormElement } from '../FormElement';
import { removeDatePickerProps } from '../PickADateBase';
import { PickADateFormElement as PickADate } from './PickADate';
import { TimePickerFormElement as TimePicker, removeTimePickerProps } from './TimePicker';

/*
(ts-migrate) TODO: Migrate the remaining prop types
...FormElement.propTypes
*/
type OwnDateTimePickerProps = {
  value?: any; // TODO: (props, propName, componentName) => { if ( !( props[propName] instanceof Date || props[propName] === null || props[propName] === EMPTY_DATE_AND_TIME || typeof props[propName] === 'string' || props[propName] instanceof DateTimeInput ) ) { return new Error(`Invalid value is assigned to prop "${propName}" in ${componentName}. Expecting one of null, a valid date, the EMPTY_DATE_AND_TIME constant, a string in the format "YYYY-MM-DD HH:mm:ss" or an instance of DateTimeInput.`); } }
  translate?: any; // TODO: isRequiredIf( PropTypes.func, props => !props.hasOwnProperty('dateInputLabel') || !props.hasOwnProperty('timeInputLabel') )
  dateInputLabel?: any; // TODO: isRequiredIf(PropTypes.string, props => !props.hasOwnProperty('translate'))
  timeInputLabel?: any; // TODO: isRequiredIf(PropTypes.string, props => !props.hasOwnProperty('translate'))
  dateInputLabelTextResourceId?: string;
  timeInputLabelTextResourceId?: string;
  parseDate?: (...args: any[]) => any;
  onDayFocus?: (...args: any[]) => any;
  showInstructionalText: boolean;
  timeInstructionalText: string;
  ariaDescribedby?: string;
};

type DateTimePickerState = any;

type DateTimePickerProps = OwnDateTimePickerProps & typeof DateTimePicker.defaultProps;

export class DateTimePicker extends Component<DateTimePickerProps, DateTimePickerState> {
  static displayName = 'DateTimePicker';

  static defaultProps = {
    dateInputLabelTextResourceId: 'NucleusCoreComponent_DateTimePicker_DateInputLabel__resx',
    timeInputLabelTextResourceId: 'NucleusCoreComponent_DateTimePicker_TimeInputLabel__resx',
    formatOptions: {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }
  };

  constructor(props: DateTimePickerProps) {
    super(props);

    this.onDateChange = this.onDateChange.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);

    const { value } = this.props;
    let dateTimeInput;
    if (value instanceof DateTimeInput) {
      dateTimeInput = value;
    } else if (value instanceof Date) {
      dateTimeInput = this.constructDateTimeInput(
        value,
        DATE_CHANGE_SUCCESS,
        this.getStringFromDate(value),
        this.getTimeObject(value),
        value
      );
    } else if (typeof value === 'string') {
      const date = this.parseLocalISODate(value);
      dateTimeInput = this.constructDateTimeInput(
        date,
        DATE_CHANGE_SUCCESS,
        this.getStringFromDate(date),
        this.getTimeObject(date),
        date
      );
    } else {
      dateTimeInput = this.constructDateTimeInput(null, DATE_CHANGE_EMPTY_DATE, '', null, null);
    }
    this.state = {
      dateTimeInput
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    const { value } = nextProps;
    let dateTimeInput;
    if (value instanceof DateTimeInput) {
      dateTimeInput = value;
    } else if (value instanceof Date) {
      if (value === this.state.dateTimeInput.date) {
        return;
      }
      dateTimeInput = this.constructDateTimeInput(
        value,
        DATE_CHANGE_SUCCESS,
        this.getStringFromDate(value, nextProps),
        this.getTimeObject(value),
        value
      );
    } else if (typeof value === 'string') {
      const date = this.parseLocalISODate(value);
      dateTimeInput = this.constructDateTimeInput(
        date,
        DATE_CHANGE_SUCCESS,
        this.getStringFromDate(date, nextProps),
        this.getTimeObject(date),
        date
      );
    } else if (value === EMPTY_DATE_AND_TIME) {
      dateTimeInput = this.constructDateTimeInput(null, DATE_CHANGE_EMPTY_DATE, '', null, null);
    } else {
      return;
    }
    const newState = { dateTimeInput };
    this.setState(newState);
  }

  getStringFromDate(date: any, props = this.props) {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'formatDate' does not exist on type 'Read... Remove this comment to see the full error message
    if (props.formatDate) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'formatDate' does not exist on type 'Read... Remove this comment to see the full error message
      return props.formatDate(date);
    }
    let dateString;
    try {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'locale' does not exist on type 'Readonly... Remove this comment to see the full error message
      dateString = new Intl.DateTimeFormat(props.locale, props.formatOptions).format(date);
    } catch (error) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'locale' does not exist on type 'Readonly... Remove this comment to see the full error message
      dateString = new Intl.DateTimeFormat(switchLocale(props.locale), props.formatOptions).format(
        date
      );
    }
    return dateString;
  }

  isTimeValid(timeObject: any, showSeconds: any) {
    const { hour, minute, second } = timeObject;
    return (
      (!showSeconds && hour !== undefined && minute !== undefined) ||
      (showSeconds && hour !== undefined && minute !== undefined && second !== undefined)
    );
  }

  onDateChange(
    fieldName: any,
    newDate: any,
    textValue: any,
    statusCode: any,
    dateName: any,
    dateInput: any
  ) {
    const newDateTime = this.createDateTime(newDate, this.state.dateTimeInput.timeText);
    const dateTimeInput = this.constructDateTimeInput(
      newDate,
      dateInput.statusCode,
      dateInput.dateText,
      this.state.dateTimeInput.timeText,
      newDateTime
    );
    this.setState({ dateTimeInput });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.onChange) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
        this.props.fieldName,
        newDateTime,
        textValue,
        statusCode,
        '',
        dateTimeInput
      );
    }
  }

  createDateTime = defaultMemoize((date: any, timeText: any) => {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'showSeconds' does not exist on type 'Rea... Remove this comment to see the full error message
    if (date instanceof Date && this.isTimeValid(timeText, this.props.showSeconds)) {
      const { hour, minute, second } = timeText;
      const dateTime = new Date(date);
      dateTime.setHours(hour);
      dateTime.setMinutes(minute);
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'showSeconds' does not exist on type 'Rea... Remove this comment to see the full error message
      if (this.props.showSeconds) dateTime.setSeconds(second);
      return dateTime;
    }
    return null;
  });

  parseLocalISODate = defaultMemoize(parseLocalISODate);

  constructDateTimeInput = defaultMemoize(
    (date: any, statusCode: any, dateText: any, timeText: any, dateTime: any) => {
      return new DateTimeInput(date, statusCode, dateText, timeText || {}, dateTime);
    }
  );

  onTimeChange(fieldName: any, newValue: any, textValue: any, statusCode: any) {
    const newDateTime = this.createDateTime(this.state.dateTimeInput.date, newValue);
    const dateTimeInput = this.constructDateTimeInput(
      this.state.dateTimeInput.date,
      this.state.dateTimeInput.statusCode,
      this.state.dateTimeInput.dateText,
      newValue,
      newDateTime
    );
    this.setState({ dateTimeInput });
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.onChange) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.onChange(
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
        this.props.fieldName,
        newDateTime,
        textValue,
        statusCode,
        '',
        dateTimeInput
      );
    }
  }

  getTimeObject = defaultMemoize((date: any) => {
    if (date && date instanceof Date) {
      return {
        hour: date.getHours(),
        minute: date.getMinutes(),
        second: date.getSeconds()
      };
    }
    return {};
  });

  render() {
    const {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'validationState' does not exist on type ... Remove this comment to see the full error message
      validationState,
      dateInputLabel,
      timeInputLabel,
      dateInputLabelTextResourceId,
      timeInputLabelTextResourceId,
      ariaDescribedby,
      ...rest
    } = this.props;
    const { translate } = rest;
    return (
      <div {...resolveTestId(this.props)}>
        <PickADate
          {...removeTimePickerProps(rest)}
          {...select(this.props, 'datePicker')}
          {...injectTestId('date-picker')}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
          fieldName={`${this.props.fieldName}.date`}
          value={this.state.dateTimeInput}
          label={dateInputLabel || (translate ? translate(dateInputLabelTextResourceId) : '')}
          hideLabel
          onChange={this.onDateChange}
          validationState={validationState.date}
          onDayFocus={this.props.onDayFocus}
          ariaDescribedby={ariaDescribedby}
        />
        <TimePicker
          {...removeDatePickerProps(rest)}
          {...select(this.props, 'timePicker')}
          {...injectTestId('time-picker')}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
          fieldName={`${this.props.fieldName}.time`}
          value={this.state.dateTimeInput.timeText}
          label={timeInputLabel || (translate ? translate(timeInputLabelTextResourceId) : '')}
          hideLabel
          onChange={this.onTimeChange}
          validationState={validationState.time}
          ariaDescribedby={ariaDescribedby}
        />
      </div>
    );
  }
}

type DateTimePickerFormElementProps = {
  children?: React.ReactNode;
  style?: {
    input?: any;
  };
  displayValid?: boolean;
  errorMessages?: {
    date?: {
      [key: string]: string;
    };
    time?: {
      [key: string]: string;
    };
    group?: {
      [key: string]: string;
    };
  };
  fieldName?: string;
};

/**
FormElement wrapper around DateTimePicker. This is the default export.
**/
const DateTimePickerFormElement = (props: DateTimePickerFormElementProps) => {
  const { children, errorMessages, ...rest } = props;
  const { displayValid } = rest;
  const validState = displayValid ? 'valid' : undefined;
  const validationState = {};
  if (errorMessages) {
    const dateErrorPresent = errorMessages.date && Object.keys(errorMessages.date).length > 0;
    const timeErrorPresent = errorMessages.time && Object.keys(errorMessages.time).length > 0;
    const groupErrorPresent = errorMessages.group && Object.keys(errorMessages.group).length > 0;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'date' does not exist on type '{}'.
    validationState.date = dateErrorPresent || groupErrorPresent ? 'error' : validState;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'time' does not exist on type '{}'.
    validationState.time = timeErrorPresent || groupErrorPresent ? 'error' : validState;
  }

  const flatErrorMessages = flatten(map(errorMessages, group => map(group, m => m)));

  return (
    /** @ts-expect-error no overload matches this call */
    <FormElement {...rest} errorMessages={flatErrorMessages} isFieldSet>
      <DateTimePicker
        {...rest}
        {...injectTestId('input')}
        ariaDescribedby={
          flatErrorMessages
            ? map(flatErrorMessages, (errorMessage, key) => `${props.fieldName}-${key}`).join(' ')
            : ''
        }
        /* @ts-expect-error ts-migrate() */
        validationState={validationState}
      />
      {children}
    </FormElement>
  );
};
DateTimePickerFormElement.displayName = 'DateTimePickerFormElement';

export { DateTimePickerFormElement };
