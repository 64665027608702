import merge from 'lodash/merge';

import { getBackground } from './getBackground';
import { getBorder } from './getBorder';
import { getCustomCSSStyles } from './getCustomCSSStyles';
import { getSpacing } from './getSpacing';
import { getText } from './getText';

/**
 * Generates an inline style object given the provided theme style object.
 * To make things easier on the caller, themeStyleObject can also be passed
 * in as an array of themeStyleObjects, and they will automatically be merged
 * into a single object using the merge utility.
 */
export function getInlineStyle(
  themeStyleObject = {},
  customStyleSettings = {},
  colorPalette = {},
  fontPalette = {},
  themeImages = {},
  {
    useText = true,
    useBackground = true,
    useBorder = true,
    useSpacing = true,
    customFonts = {},
    imageLookup = {},
    supportsWebp = false
  } = {}
) {
  let styleObject = themeStyleObject;
  if (Array.isArray(styleObject)) {
    styleObject = merge({}, ...styleObject);
  }
  let inlineStyles = {};
  Object.keys(styleObject).forEach(property => {
    switch (property) {
      case 'text': {
        if (useText) {
          const textStyles = getText(
            styleObject,
            customStyleSettings,
            colorPalette,
            fontPalette,
            customFonts
          );
          inlineStyles = {
            ...inlineStyles,
            ...textStyles,
            hover: {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'hover' does not exist on type '{}'.
              ...inlineStyles.hover,
              ...textStyles.hover
            }
          };
        }
        break;
      }
      case 'background': {
        if (useBackground) {
          const backgroundStyles = getBackground(
            styleObject,
            customStyleSettings,
            colorPalette,
            themeImages,
            imageLookup,
            supportsWebp
          );
          inlineStyles = {
            ...inlineStyles,
            ...backgroundStyles,
            hover: {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'hover' does not exist on type '{}'.
              ...inlineStyles.hover,
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'hover' does not exist on type '{}'.
              ...backgroundStyles.hover
            }
          };
        }
        break;
      }
      case 'border': {
        if (useBorder) {
          const borderStyles = getBorder(styleObject, customStyleSettings, colorPalette);
          inlineStyles = {
            ...inlineStyles,
            ...borderStyles,
            hover: {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'hover' does not exist on type '{}'.
              ...inlineStyles.hover,
              ...borderStyles.hover
            }
          };
        }
        break;
      }
      case 'spacing': {
        if (useSpacing) {
          inlineStyles = {
            ...inlineStyles,
            ...getSpacing(styleObject, customStyleSettings)
          };
        }
        break;
      }
      case 'customCSSStyles': {
        inlineStyles = {
          ...inlineStyles,
          ...getCustomCSSStyles(styleObject, customStyleSettings)
        };
        break;
      }
      default: {
        break;
      }
    }
  });
  return inlineStyles;
}
