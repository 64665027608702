import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';
import ButtonGroupStyles from 'nucleus-core/less/cv/ButtonGroup.less';

import { BaseWidget } from '../BaseWidget';
import { LinearNavigator } from './LinearNavigator';
import LinearNavigatorStyles from './LinearNavigator.less';
import { ThemeableComponentProps } from '../ThemeableComponent';
const LinearNavigatorClasses = { ...LinearNavigatorStyles, ...ButtonGroupStyles };

interface LinearNavigatorWidgetProps extends ThemeableComponentProps {
  config: {
    displayText?: {
      backward: string;
      forward: string;
      complete: string;
      exit: string;
    };
    textRenderer?: {
      backward?: (str: string) => JSX.Element;
      forward?: (str: string) => JSX.Element;
      complete?: (str: string) => JSX.Element;
      exit?: (str: string) => JSX.Element;
    };
  };
  classes: Record<string, unknown>;
  // Non-configurable appData props that should be added by the application specific wrapper.
  currentPosition?: string;
  disableForwardNavigation?: boolean;
  onNavigateRequest: (...args: any[]) => any;
  showCompleteForAllSteps?: boolean;
  showBackwardButton?: boolean;
  showExitButton?: boolean;
  renderExitAsLink?: boolean;
  reverseButtonOrderOnMobile?: boolean;
  disableAllButtons?: boolean;
  useDefaultCursorWhenDisabled?: boolean;
}
/**
 * A widget to allow navigation through a linear process. This widget is intended
 * to be wrapped in order to place the hooks to navigate the defined linear process.
 */
export class LinearNavigatorWidget extends BaseWidget<LinearNavigatorWidgetProps> {
  static displayName = 'LinearNavigatorWidget';
  static defaultProps = {
    classes: LinearNavigatorClasses,
    showCompleteForAllSteps: false,
    showBackwardButton: true,
    showExitButton: true,
    renderExitAsLink: false,
    disableAllButtons: false,
    useDefaultCursorWhenDisabled: false
  };
  getStyleObject() {
    return {
      ...super.getStyleObject(),
      forward: {
        button: this.getElementInlineStyle('forward')
      },
      backward: {
        button: this.getElementInlineStyle('backward')
      },
      complete: {
        button: this.getElementInlineStyle('complete')
      },
      exit: {
        button: this.getElementInlineStyle('exit'),
        link: this.getElementInlineStyle('exit')
      }
    };
  }
  render() {
    const {
      config: { displayText, textRenderer },
      currentPosition,
      disableForwardNavigation,
      onNavigateRequest,
      classes,
      translate,
      showCompleteForAllSteps,
      showBackwardButton,
      showExitButton,
      renderExitAsLink,
      reverseButtonOrderOnMobile,
      disableAllButtons,
      useDefaultCursorWhenDisabled
    } = this.props;
    const linearNavigatorClasses =
      disableAllButtons && useDefaultCursorWhenDisabled
        ? { ...classes, button: classes.buttonUseDefaultCursor }
        : classes;
    return (
      <LinearNavigator
        {...resolveTestId(this.props)}
        currentPosition={currentPosition}
        disableForwardNavigation={disableForwardNavigation}
        onNavigateRequest={onNavigateRequest}
        displayText={{
          backward: translate(displayText?.backward),
          forward: translate(displayText?.forward),
          complete: translate(displayText?.complete),
          exit: translate(displayText?.exit)
        }}
        textRenderer={textRenderer}
        classes={linearNavigatorClasses}
        style={this.getStyleObject()}
        showCompleteForAllSteps={showCompleteForAllSteps}
        showExitButton={showExitButton}
        showBackwardButton={showBackwardButton}
        renderExitAsLink={renderExitAsLink}
        {...(reverseButtonOrderOnMobile ? { reverseButtonOrderOnMobile } : {})}
        disableAllButtons={disableAllButtons}
      />
    );
  }
}
