import { getAllWidgetsOnPage } from './getAllWidgetsOnPage';
/**
 * Load any resources that need to be loaded before switching to a page
 * @param website the site editor website object
 * @param pageId id of the page being switched to
 */
export function loadPageResources(website: any, pageId: any, setLoading: any) {
  return async (dispatch: any, getState: any) => {
    const pageWidgets = getAllWidgetsOnPage(website, pageId);
    const templateWidgets =
      website.pages[pageId] && website.pages[pageId].templateId
        ? getAllWidgetsOnPage(website, website.pages[pageId].templateId)
        : [];
    const allWidgets = [...pageWidgets, ...templateWidgets];
    if (allWidgets.length > 0 && setLoading) {
      dispatch(setLoading(true));
    }
    const factory = getState().widgetFactory;
    // Some site editors leaves widgetFactory empty.
    if (factory && factory.loadWidgetResources) {
      await dispatch(factory.loadWidgetResources(allWidgets));
    }

    if (allWidgets.length > 0 && setLoading) {
      dispatch(setLoading(false));
    }
  };
}
