import React from 'react';

import Alerts from '@cvent/carina/components/Alert_NEXT';
import { getActionLink } from '../../utils/getActionLink';

const initializeAlertProps = (props: any) => {
  const { alerts, translate, onDismiss, onAction } = props;

  return alerts.map((alert: any) => {
    const newAlert = { ...alert };

    if (alert.id) {
      newAlert.testID = `canvas-feedback-panel-${alert.id}`;
    }

    if (alert.action) {
      newAlert.action = getActionLink({
        translate,
        transkey: alert.action,
        retrying: alert.retrying,
        onClick: () => onAction(alert.data)
      });
    }

    if (alert.content) {
      newAlert.content = translate(alert.content);
    }

    if (alert.accessibility) {
      newAlert.accessibilityLabel = translate(alert.accessibility);
    }

    newAlert.onDismiss = () => onDismiss(alert.data);

    return newAlert;
  });
};

export const CanvasFeedbackPanel = (props: any) => {
  const { classes } = props;

  const alerts = initializeAlertProps(props);

  if (!alerts?.length) {
    return null;
  }

  return (
    <div className={classes} id="comment-alert-panel">
      <Alerts variant={'user'} alerts={alerts} testID={'canvas-feedback-panel'} />
    </div>
  );
};
