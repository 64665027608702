import React from 'react';

import { BlockTypes } from '@cvent/rich-text-renderer';
import { resolveTestId } from '@cvent/nucleus-test-automation';

import { renderRichText } from '../../utils/renderRichText';
import { BaseWidget } from '../BaseWidget';
import ClassNames from './Date.less';
import { DateComponent } from './DateComponent';
import { ThemeableComponentProps } from '../ThemeableComponent';

type BlockTypes =
  | 'unstyled'
  | 'paragraph'
  | 'header-one'
  | 'header-two'
  | 'header-three'
  | 'header-four'
  | 'header-five'
  | 'header-six';
interface DateWidgetProps extends ThemeableComponentProps {
  classes?: Record<string, unknown>;
  config?: {
    displayDateOption: string;
    baseBlockType: BlockTypes;
  };
  date: string;
  label: Record<string, unknown> | string;
  timeZone: string;
  translateDate: (...args: any[]) => any;
  translateTime: (...args: any[]) => any;
}
/**
 * A wrapper for the date component that displays a combination of
 * date, time and timeZone based on user's selection.
 */
export class DateWidget extends BaseWidget<DateWidgetProps> {
  static displayName = 'DateWidget';
  static defaultProps = {
    classes: ClassNames
  };
  getStyleObject() {
    return {
      ...super.getStyleObject(),
      text: this.getElementInlineStyle('text'),
      dateTimeTimeZone: this.getElementInlineStyle('dateTimeTimeZone')
    };
  }
  render() {
    const {
      classes,
      config,
      date,
      label,
      translate,
      translateTime,
      translateDate,
      timeZone,
      style
    } = this.props;
    let dateString: any = Date.parse(date);
    let time;
    if (isNaN(dateString)) {
      dateString = '';
      time = '';
    } else {
      dateString = translateDate(new Date(date), 'long');
      time = translateTime(new Date(date), 'short');
    }
    const displayFormat = config?.displayDateOption;
    const styleObject = this.getStyleObject();
    return (
      <DateComponent
        {...resolveTestId(this.props)}
        classes={classes}
        date={dateString as string}
        displayFormat={displayFormat}
        style={styleObject}
        text={renderRichText(label, translate, style?.palette, styleObject.text, {
          baseBlockType: config?.baseBlockType
        })}
        time={time}
        timeZone={timeZone}
      />
    );
  }
}
