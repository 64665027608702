import { WebsiteV8 } from './WebsiteV8';
import pickBy from 'lodash/pickBy';
import mapValues from 'lodash/mapValues';
import merge from 'lodash/merge';

/**
 * Takes the applied section theme of all sections and converts the related data into section styles. This
 * removes any dependencies that existing sections have on section themes.
 */
export function migrateExistingSectionStyles(website: WebsiteV8) {
  const sectionRows = pickBy(website.layoutItems, item => item.layout.type === 'sectionRow');
  const migratedSectionRows = mapValues(sectionRows, section => {
    const sectionThemeBackgroundStyle = website.theme.sections[section.config.themeId].background;
    const sectionStyle = section.config.style;
    const migratedStyle = merge({}, { background: sectionThemeBackgroundStyle }, sectionStyle);
    return {
      ...section,
      config: {
        ...section.config,
        style: migratedStyle,
        themeId: 'mood'
      }
    };
  });
  return merge({}, website, { layoutItems: migratedSectionRows });
}
