/**  @flow */
import Logger from '@cvent/nucleus-logging';
const LOG = new Logger('calendarWebsiteNavigation');

export default function reducer(state: $TSFixMe = {}) {
  return state;
}

/** return the pageId for the default website **/
export function getDefaultWebsitePageId(state: $TSFixMe = {}) {
  const { defaultPageId } = state;
  if (!defaultPageId) {
    LOG.error('failed to find valid defaultWebsitePageId.');
    return defaultPageId;
  }
  return defaultPageId;
}
