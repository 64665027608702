/**
 * Compares 2 paths to see if they are equal. The paths are expected to be either
 * dot notation strings or arrays of string keys.
 * @returns True if the paths are equivalent, false otherwise.
 */
export function comparePaths(path1: any, path2: any) {
  const convertedPath1 = path1 instanceof Array ? path1.join('.') : path1;
  const convertedPath2 = path2 instanceof Array ? path2.join('.') : path2;
  return convertedPath1 === convertedPath2;
}
