import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import ResponsiveImage from '@cvent/nucleus-image';
import { resolveTestId } from '@cvent/nucleus-test-automation';

import { addUrlDefaultProtocol } from '../../utils/addUrlDefaultProtocol';

type ImageProps = {
  imgUrl?: string;
  altText?: string | null;
  hyperlink?: string;
  description?: string;
  translate: (...args: any[]) => any;
  onLoad?: (...args: any[]) => any;
  supportsWebp?: boolean;
  width?: number;
  breakpoints?: Array<number>;
  sizes?: string;
  // Used in resolve function
  classes?: Record<string, unknown>;
  srcSet?: string;
  style?: { image: { borderRadius: string }; [x: string | number | symbol]: unknown };
  usePlaceholderImage?: boolean;
};
/**
 * The Image widget.
 */
export const Image = React.memo((props: ImageProps) => {
  const {
    imgUrl,
    altText,
    description,
    hyperlink,
    translate,
    supportsWebp,
    width,
    breakpoints,
    srcSet,
    sizes,
    style,
    usePlaceholderImage = true
  } = props;

  const onLoad = (event: any) => {
    event.persist();
    if (props.onLoad) {
      props.onLoad(event);
    }
  };

  function getImage(url: any) {
    if (!url) {
      return !usePlaceholderImage ? <div /> : <div {...resolve(props, 'placeholder')} />;
    }
    return (
      <ResponsiveImage
        {...(props.onLoad ? { onLoad } : {})}
        src={url}
        width={width}
        alt={altText}
        sizes={sizes}
        srcSet={srcSet}
        breakpoints={breakpoints}
        supportsWebp={supportsWebp}
        style={{
          ...style,
          maxWidth: width,
          borderRadius: style?.image.borderRadius
        }}
      />
    );
  }

  return (
    <div {...resolveTestId(props)} {...resolve(props, 'container')}>
      <div {...resolve(props, 'image', !imgUrl && 'limitSize')}>
        <div {...resolve(props, 'imageWrapper')}>
          {hyperlink ? (
            <a href={translate(addUrlDefaultProtocol(hyperlink))} target="_blank" rel="noreferrer">
              {getImage(imgUrl)}
            </a>
          ) : (
            getImage(imgUrl)
          )}
        </div>
        {description && (
          <div {...resolve(props, 'imageText')} dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </div>
    </div>
  );
});
