import PropTypes from 'prop-types';

function propIsRequired(condition: any, props: any, propName: any, componentName: any) {
  if (Boolean(condition)) {
    if (typeof condition === 'boolean') {
      return condition;
    } else if (typeof condition === 'function') {
      return condition(props, propName, componentName);
    }
  }
  return false;
}

function isCustomReactPropType(validator: any) {
  return Object.keys(PropTypes).every(propType => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return PropTypes[propType] !== validator;
  });
}

function propExists(props: any, propName: any, componentName: any) {
  if (!props.hasOwnProperty(propName) || !Boolean(props[propName])) {
    return new Error(
      `Warning: Failed propType: Required ${props[propName]} '${propName}' was not specified in '${componentName}'.`
    );
  }
  return true;
}

/**
 * A function to conditionally require propTypes based on other props and variables.
 * Taken from https://github.com/evcohen/react-proptype-conditional-require and updated
 * to adhere to new React standards.
 * @param {typeValidator} validator - A React PropType or a custom PropType definition.
 * @param {bool|func} condition - A boolean value or a function that returns a boolean
 *   value that indicates whether the property should be required or not.
 * @returns a conditionally required PropType validator.
 */
export function isRequiredIf(validator: any, condition: any) {
  return (props: any, propName: any, componentName: any, ...rest: any[]) => {
    if (propIsRequired(condition, props, propName, componentName)) {
      if (isCustomReactPropType(validator)) {
        const exists = propExists(props, propName, componentName);
        if (exists instanceof Error) {
          return exists;
        }
        return validator(props, propName, componentName, ...rest);
      }
      return validator.isRequired(props, propName, componentName, ...rest);
    }
    // Is not required, so just run validator.
    return validator(props, propName, componentName, ...rest);
  };
}
