import {
  widgetElementStylesPseudoStateTabs,
  widgetElementStylesPseudoStateTabsV2
} from '../StyleMenuFields';
import { getPermissions, EditorPermissions } from '../../../permissions';
export const resetStyles = {
  fieldName: 'resetStyles',
  component: 'ResetStylesButton',
  options: {
    label: '_widgetEditor_resetWidgetStyles__resx'
  }
};
export const resetHoverStyles = {
  fieldName: 'resetHoverStyles',
  component: 'ResetStylesButton',
  options: {
    label: 'NucleusSiteEditor_ResetHoverStyles__resx'
  }
};
const hideCustomFieldByPermissions = (state: any) => {
  return getPermissions(state).fields?.theme?.globalTheming === EditorPermissions.READ_ONLY;
};
export const styleMapping = {
  fieldName: 'styleMapping',
  component: 'SelectField',
  options: {
    label: '_fieldLabel_styleMapping__resx',
    getOptionArray: (state: any) => {
      const baseOptions = [
        {
          name: '_fieldMenu_header1__resx',
          value: 'header1'
        },
        {
          name: '_fieldMenu_header2__resx',
          value: 'header2'
        },
        {
          name: '_fieldMenu_header3__resx',
          value: 'header3'
        },
        {
          name: '_fieldMenu_header4__resx',
          value: 'header4'
        },
        {
          name: '_fieldMenu_text1__resx',
          value: 'text1'
        },
        {
          name: '_fieldMenu_text2__resx',
          value: 'text2'
        },
        {
          name: '_fieldMenu_body1__resx',
          value: 'body1'
        },
        {
          name: '_fieldMenu_body2__resx',
          value: 'body2'
        },
        {
          name: '_fieldMenu_link__resx',
          value: 'link'
        },
        {
          name: '_fieldMenu_primaryButton__resx',
          value: 'primaryButton'
        },
        {
          name: '_fieldMenu_secondaryButton__resx',
          value: 'secondaryButton'
        },
        {
          name: '_fieldMenu_input__resx',
          value: 'input'
        },
        {
          name: '_fieldMenu_label__resx',
          value: 'label'
        }
      ];
      if (!hideCustomFieldByPermissions(state)) {
        baseOptions.push({
          name: '_fieldOption_custom__resx',
          value: 'custom'
        });
      }
      return [...baseOptions];
    }
  }
};
export const widgetElementPseudoStateTabs = {
  ...widgetElementStylesPseudoStateTabs
};

export const widgetElementPseudoStateTabsV2 = {
  ...widgetElementStylesPseudoStateTabsV2
};

export default {
  resetStyles,
  resetHoverStyles,
  styleMapping,
  widgetElementPseudoStateTabs,
  widgetElementPseudoStateTabsV2
};
