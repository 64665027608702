import { evaluateFunctionOrValue } from '../utils/evaluateFunctionOrValue';
import { getJSONValue } from '../utils/fields/getJSONValue';
import { getLocalizedSharedData, getLocalizedAppData } from '../utils/getLocalizedUserText';
import { ThemeableComponent, ThemeableComponentProps } from './ThemeableComponent';

/**
 * A base widget class that implements any necessary default functions.
 */
export class BaseWidget<
  T extends ThemeableComponentProps,
  S = Record<string, unknown>
> extends ThemeableComponent<T, S> {
  static displayName = 'BaseWidget';

  /**
   * If a widget has to do any manipulations for its config properties outside
   * of whatever is saved in the widget's config object, this function
   * can be overridden by that widget to describe how to get the config. The
   * widget would then use this in its mapStateToProps function, and the
   * site editor always uses this function to get the config to use for editing
   * the widget.
   * By default, this function will map any shared config fields for the widget
   * from the siteInfo state object as well as any application data driven fields
   * from the appData state object. So if a widget simply has shared config
   * fields between its instances or simple appData, it can just call this without
   * overridding. If a widget needs to do anything more complicated, it could
   * override this function, but this normally shouldn't be necessary.
   * @param {object} state - the current redux application state.
   * @param {object} rawConfig - the current raw config object for the widget.
   * @param {string} type - The type of widget.
   * @returns {object} the mutated config object to use for the widget.
   */
  static getConfig(state: any, rawConfig: any, type: any, widgetId: any) {
    const { localizedUserText } = state;
    const sharedConfigs = getLocalizedSharedData({
      baseText: state.website.siteInfo.sharedConfigs[type],
      localizedUserText,
      widgetType: type
    });
    const sharedValues = {
      ...(rawConfig.shared || {}),
      ...sharedConfigs
    };

    // Get the necessary appData values according to the passed in metaData.
    const appDataValues: Record<string, any> = {};
    const metaData = state.widgetFactory.loadMetaData(type);
    if (metaData.appDataFieldPaths) {
      Object.keys(metaData.appDataFieldPaths).forEach(key => {
        const resolvedPath = evaluateFunctionOrValue(
          metaData.appDataFieldPaths[key],
          state,
          rawConfig,
          widgetId
        );

        appDataValues[key] = getLocalizedAppData({
          localizedUserText,
          path: resolvedPath,
          baseText: getJSONValue(state.appData, resolvedPath)
        });
      });
    }

    return {
      ...rawConfig,
      shared: sharedValues,
      appData: appDataValues
    };
  }
}
