import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';

// The placeholder used to be a webpack import of ../resources/images/thumbnail.gif. However, the url generated
// was relative and therefore by default would not use the asset host based and would fail to resolve. Since the
// image was small it was decided to base 64 encode and just apply directly.
// eslint-disable-next-line max-len
const placeHolder =
  'data:image/gif;base64,R0lGODlhZABLAIAAAK+vrwAAACwAAAAAZABLAAACYISPqcvtD6OctNqLs968+w+G4kiW5omm6sq27gvH8kzX9o3n+s73/g8MCofEovGITCqXzKbzCY1Kp9Sq9YrNarfcrvcLDovH5LL5jE6r1+y2+w2Py+f0uv2Oz+v3/P6+AAA7';

type OwnProps = React.PropsWithChildren<{
  thumbnail?: string;
  thumbnailAlt?: string;
  placeHolderImageUrl?: React.ReactNode;
  onLoad?: (...args: any[]) => any;
  onError?: (...args: any[]) => any;
}>;

type State = any;

type Props = React.PropsWithChildren<OwnProps & typeof Thumbnail.defaultProps>;

/**
  Thumbnail Component for Image Gallery / Carousel
  Can be either an Image or Component
*/
export class Thumbnail extends React.Component<Props, State> {
  static displayName = 'Thumbnail';

  static defaultProps = {
    placeHolderImageUrl: placeHolder
  };

  constructor(props: Props) {
    super(props);
    this.state = {
      isLoaded: false
    };
    this.onLoad = this.onLoad.bind(this);
    this.onError = this.onError.bind(this);
  }
  onLoad(e: any) {
    if (this.props.onLoad) {
      this.props.onLoad(e);
    }
    this.setState({
      isLoaded: true
    });
  }
  onError() {
    if (this.props.onError) {
      this.props.onError();
    }
  }
  render() {
    const { children, thumbnail, thumbnailAlt, placeHolderImageUrl } = this.props;
    const { isLoaded } = this.state;
    return (
      <span>
        {!children ? (
          <img
            {...resolve(this.props, isLoaded ? '' : 'loading')}
            src={thumbnail || null}
            alt={thumbnailAlt || ''}
            onLoad={this.onLoad}
            onError={this.onError}
          />
        ) : (
          children
        )}
        {isLoaded || children ? null : <img src={placeHolderImageUrl} alt={thumbnailAlt || ''} />}
      </span>
    );
  }
}
