/**
 * migration for website layout items
 */
export default function layoutItemMigrations(layoutItem: $TSFixMe) {
  if (layoutItem.layout.type !== 'widget') {
    return layoutItem;
  }
  switch (layoutItem.widgetType) {
    case 'CalendarList': {
      if (typeof layoutItem.config.display.addToCalendar === 'undefined') {
        return {
          ...layoutItem,
          config: {
            ...layoutItem.config,
            display: {
              ...layoutItem.config.display,
              addToCalendar: true
            }
          }
        };
      }
      return layoutItem;
    }
    default:
      return layoutItem;
  }
}
