import { CONTAINER } from './LayoutTypes';
export const CUSTOM_CONTAINER = 'CUSTOM_CONTAINER';

/**
 * Finds the parent page of the given layout item, or the
 * CUSTOM_CONTAINER constant if there is no parent page.
 */
// @ts-expect-error ts-migrate(7023) FIXME: 'getParentPage' implicitly has return type 'any' b... Remove this comment to see the full error message
export function getParentPage(state: any, item: any) {
  const { layoutItems, pages } = state.website;
  const parentLayoutItem = layoutItems[item.layout.parentId];
  if (parentLayoutItem.layout.type === CONTAINER) {
    const pageId = Object.keys(pages).find(id =>
      pages[id].rootLayoutItemIds.includes(parentLayoutItem.id)
    );
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    return pages[pageId] || CUSTOM_CONTAINER;
  }
  return getParentPage(state, parentLayoutItem);
}
