import { getAllChildWidgetsInItem } from './getAllChildWidgetsInItem';

/**
 * Gets the instances of any given widget types across all specified pages.
 * @param {object} layoutItems - the layoutItems portion of application state.
 * @param {array} pages - the pages to check for instances of the widget.
 * @param {array} widgetComparators - an array of widget comparators. A comparator can either
 *   just be the widgetType string (used as the key and comparison value), or an object containing two fields:
 *     key - the key of the widget to use in the widget counts map.
 *     compare(widgetInstance) - a compare function to determine if a widget is an instance of this item.
 *  or a function which should return a key if the widget is an instance of this item or null if not
 * @returns {object} A map of all of the provided widget identifiers to an array of their instances.
 */
export function getWidgetsAcrossPages(layoutItems: any, pages: any, widgetComparators: any) {
  const widgetsMap = {};
  const widgets: any = [];
  pages.forEach((page: any) => {
    page?.rootLayoutItemIds?.forEach((id: any) => {
      getAllChildWidgetsInItem(layoutItems, layoutItems[id], widgets);
    });
  });
  widgetComparators.forEach((comparator: any) => {
    if (typeof comparator === 'string') {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      widgetsMap[comparator] = widgets.filter(instance => instance.widgetType === comparator);
      return;
    }
    if (typeof comparator === 'function') {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'instance' implicitly has an 'any' type.
      widgets.forEach(instance => {
        const key = comparator(instance);
        if (key) {
          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          if (!widgetsMap[key]) {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            widgetsMap[key] = [instance];
          } else {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            widgetsMap[key].push(instance);
          }
        }
      });
      return;
    }
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    widgetsMap[comparator.key] = widgets.filter(instance => comparator.compare(instance));
  });
  return widgetsMap;
}
