/**
 * Formats a javascript date object into a string.
 *
 * @param date {Date} The date to format.
 * @param format {string} The format string. mm = month with 0, M = month, m = month,
 *   dd = day with 0, D = day, d = day, Y = 2 digit year, yy = 4 digit year, yyyy = 4 digit year
 * @return {Object} The date properly formatted as a string.
 */
export default function formatDateToString(date, format) {
  let selectedFormat = format;
  selectedFormat = selectedFormat.replace(/M/, date.getMonth() + 1);
  selectedFormat = selectedFormat.replace(/D/, date.getDate());
  selectedFormat = selectedFormat.replace(/Y/, date.getFullYear() % 1000);
  selectedFormat = selectedFormat.replace(
    /mm/,
    ('0' + (date.getMonth() + 1)).slice(-2)
  );
  selectedFormat = selectedFormat.replace(/m/, date.getMonth() + 1);
  selectedFormat = selectedFormat.replace(
    /dd/,
    ('0' + date.getDate()).slice(-2)
  );
  selectedFormat = selectedFormat.replace(/d/, date.getDate());
  selectedFormat = selectedFormat.replace(/yyyy/, date.getFullYear());
  selectedFormat = selectedFormat.replace(/yy/, date.getFullYear());

  return selectedFormat;
}
