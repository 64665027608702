import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';

import { BaseWidget } from '../BaseWidget';
import { LinkButton } from './LinkButton';
import Styles from './LinkButton.less';
import { ThemeableComponentProps } from '../ThemeableComponent';

export interface LinkButtonWidgetProps extends ThemeableComponentProps {
  enableHyperlink: boolean;
  config: {
    text: string;
    link: string;
    target: string;
  };
}
/**
 * <LinkButtonWidget />
 * ---
 * Allows users to insert block-level links presented as buttons by default.
 * Validation rules enforce not-empty for text and an absolute url for link.
 */
export class LinkButtonWidget extends BaseWidget<LinkButtonWidgetProps> {
  static defaultProps = {
    config: {
      text: '',
      link: '',
      target: '_self'
    }
  };

  getStyleObject() {
    const buttonStyles = this.getElementInlineStyle('button');
    // Removes text alignment from the button text [PROD-101039 / NUKE-7037]
    delete buttonStyles.textAlign;
    const { textAlign } = this.getElementText('button');
    return {
      ...super.getStyleObject(),
      buttonContainer: { textAlign },
      button: buttonStyles
    };
  }

  render() {
    const { translate, config, enableHyperlink } = this.props;
    const { link, text, target } = config;
    return (
      <LinkButton
        classes={Styles}
        text={translate(text)}
        url={translate(link)}
        enableHyperlink={!!enableHyperlink}
        style={this.getStyleObject()}
        linkTarget={target}
        {...resolveTestId(this.props)}
      />
    );
  }
}
