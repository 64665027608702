import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import ReactDOM from 'react-dom';

import { DragContainer } from '../containers/DragContainer';
import { hslToRgb, rgbToHexString } from './conversions';

const SPECTRUM_CURSOR_HEIGHT = 10;
const SPECTURM_CURSOR_MIDPOINT = SPECTRUM_CURSOR_HEIGHT / 2;

type OwnProps = {
  size?: number;
  barWidthRatio?: number;
  cursorRadius?: number;
  hsl?: any;
  onColorChange?: (...args: any[]) => any;
  classes?: any;
};

type Props = React.PropsWithChildren<OwnProps & typeof ColorPicker.defaultProps>;

/** Color Picker UI (hsl values) can be placed in a window or inline. **/
export class ColorPicker extends React.Component<Props> {
  static displayName = 'ColorPicker';

  static defaultProps = {
    size: 150,
    cursorRadius: 5,
    barWidthRatio: 5.2,
    hsl: { h: 0, s: 0, l: 0 }
  };

  constructor(props: any, context: any) {
    super(props, context);
    this.renderSpectrumControl = this.renderSpectrumControl.bind(this);
    this.renderSaturationControl = this.renderSaturationControl.bind(this);
    this.onSaturationCursorDrag = this.onSaturationCursorDrag.bind(this);
    this.onSaturationMouseDown = this.onSaturationMouseDown.bind(this);
    this.onHueCursorDrag = this.onHueCursorDrag.bind(this);
    this.onSpectrumMouseDown = this.onSpectrumMouseDown.bind(this);
    this.onSpectrumSelect = this.onSpectrumSelect.bind(this);
    this.onSpectrumTouchStart = this.onSpectrumTouchStart.bind(this);
    this.onSaturationSelect = this.onSaturationSelect.bind(this);
    this.onSaturationTouchStart = this.onSaturationTouchStart.bind(this);
  }

  componentDidMount() {
    this.renderSpectrumControl();
    this.renderSaturationControl();
  }

  componentDidUpdate(prevProps: Props) {
    const { size, hsl } = prevProps;
    if (size !== this.props.size) {
      this.renderSpectrumControl();
    }
    const { h: h1 } = this.props.hsl;
    const { h: h2 } = hsl;
    // re-render on hue change
    if (h1 !== h2) {
      this.renderSaturationControl();
    }
  }

  onSaturationCursorDrag(left: any, top: any) {
    const { cursorRadius, size, hsl } = this.props;
    const { h } = hsl;
    const x = left + cursorRadius;
    const y = top + cursorRadius;
    const s = x / size;
    const l = 1 - y / size;
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.onColorChange({ h, s, l });
  }

  onHueCursorDrag(left: any, top: any) {
    const { size, hsl } = this.props;
    const { s, l } = hsl;
    const h = (top + SPECTURM_CURSOR_MIDPOINT) / size;
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.props.onColorChange({ h, s, l });
  }

  onSaturationMouseDown(e: any) {
    e.preventDefault();
    this.onSaturationSelect(e.clientX, e.clientY);
  }

  onSaturationTouchStart(e: any) {
    e.preventDefault();
    const first = e.touches[0];
    this.onSaturationSelect(first.clientX, first.clientY);
  }

  onSaturationSelect(clientX: any, clientY: any) {
    const { size, cursorRadius } = this.props;
    const node = ReactDOM.findDOMNode(this.refs.saturation as any);
    // check to make flow happy, will only be false if render() changes.
    if (node instanceof Element) {
      const x = Math.min(size, clientX - node.getBoundingClientRect().left) - cursorRadius;
      const y = Math.min(size, clientY - node.getBoundingClientRect().top) - cursorRadius;
      const l = 1 - y / size;
      const s = x / size;
      const { h } = this.props.hsl;
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.onColorChange({ h, s, l });
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'startDragAtNewPostion' does not exist on... Remove this comment to see the full error message
      this.refs.dragContainer.startDragAtNewPostion(x, y, clientX, clientY);
    }
  }

  onSpectrumMouseDown(e: any) {
    e.preventDefault();
    this.onSpectrumSelect(e.clientX, e.clientY);
  }

  onSpectrumTouchStart(e: any) {
    e.preventDefault();
    const first = e.touches[0];
    this.onSpectrumSelect(first.clientX, first.clientY);
  }

  onSpectrumSelect(clientX: any, clientY: any) {
    const { size } = this.props;
    const node = ReactDOM.findDOMNode(this.refs.spectrum as any);
    // check to make flow happy, will only be false if render() changes.
    if (node instanceof Element) {
      const y =
        Math.min(size, clientY - node.getBoundingClientRect().top) + SPECTURM_CURSOR_MIDPOINT;
      const h = y / size;
      const { s, l } = this.props.hsl;
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      this.props.onColorChange({ h, s, l });
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'startDragAtNewPostion' does not exist on... Remove this comment to see the full error message
      this.refs.spectrumDragContainer.startDragAtNewPostion(0, y, clientX, clientY);
    }
  }

  renderSpectrumControl() {
    const { size, barWidthRatio } = this.props;
    const barWidth = Math.round(size / barWidthRatio);
    let spectrumColor;
    const node = ReactDOM.findDOMNode(this.refs.spectrum as any);
    // check to make flow happy, will only be false if render() changes.
    if (node instanceof HTMLCanvasElement) {
      const context = node.getContext('2d');
      // check to make flow happy, in practice should not be null when passed 2d
      if (!context) {
        return;
      }
      node.setAttribute('width', `${barWidth}px`);
      node.setAttribute('height', `${size}px`);
      for (let row = 0; row <= size; row++) {
        spectrumColor = Math.floor((row / size) * 360);
        context.fillStyle = `hsl(${spectrumColor}, 100%, 50%)`;
        context.fillRect(0, row, barWidth, 1);
      }
    }
  }

  renderSaturationControl() {
    const { size, hsl } = this.props;
    const node = ReactDOM.findDOMNode(this.refs.saturation as any);
    if (node instanceof HTMLCanvasElement) {
      const context = node.getContext('2d');
      // check to make flow happy, in practice should not be null when passed 2d
      if (!context) {
        return;
      }
      node.setAttribute('width', `${size}px`);
      node.setAttribute('height', `${size}px`);
      let { h: hue } = hsl;
      hue = hue * 360;
      for (let row = 0; row <= size; row += 1) {
        const hslGradient = context.createLinearGradient(0, 0, size, 0);
        const lightness = (1 - row / size) * 100;
        hslGradient.addColorStop(1, 'hsl(' + hue + ', 100%, ' + lightness + '%)');
        hslGradient.addColorStop(0, 'hsl(' + hue + ', 0%, ' + lightness + '%)');
        context.fillStyle = hslGradient;
        context.fillRect(0, row, size, 1);
      }
    }
  }

  render() {
    const { hsl, cursorRadius, size, barWidthRatio } = this.props;
    const barLeft = 1; // (size / barWidthRatio) / 2 - cursorRadius;
    const barWidth = Math.round(size / barWidthRatio);
    const { h, s, l } = hsl;
    const y = l * size;
    const x = s * size;
    return (
      <div {...resolveTestId(this.props)} {...resolve(this.props, 'container')} ref="container">
        <div {...resolve(this.props, 'saturationWrapper')} ref="container">
          <DragContainer
            ref="dragContainer"
            initialLeft={x - cursorRadius}
            initialTop={size - y - cursorRadius}
            maxLeft={size - cursorRadius}
            maxTop={size - cursorRadius}
            minLeft={0 - cursorRadius}
            minTop={0 - cursorRadius}
            onDragHandler={this.onSaturationCursorDrag}
          >
            <div
              {...resolveTestId(this.props, '-saturation-indicator')}
              {...resolve(this.props, 'indicatorSaturation')}
              style={{
                width: `${3 * cursorRadius}px`,
                height: `${3 * cursorRadius}px`,
                background: rgbToHexString(hslToRgb(hsl))
              }}
            />
          </DragContainer>
          <canvas
            {...resolveTestId(this.props, '-saturation-canvas')}
            onMouseDown={this.onSaturationMouseDown}
            onTouchStart={this.onSaturationTouchStart}
            {...resolve(this.props, 'saturation')}
            ref="saturation"
          />
        </div>
        <div {...resolve(this.props, 'spectrumWrapper')}>
          <DragContainer
            ref="spectrumDragContainer"
            initialLeft={barLeft}
            initialTop={h * size - SPECTURM_CURSOR_MIDPOINT}
            maxLeft={barLeft}
            maxTop={size - SPECTURM_CURSOR_MIDPOINT}
            minLeft={barLeft}
            minTop={0 - SPECTURM_CURSOR_MIDPOINT}
            onDragHandler={this.onHueCursorDrag}
          >
            <div
              {...resolveTestId(this.props, '-spectrum-indicator')}
              {...resolve(this.props, 'indicatorSpectrum')}
              style={{
                width: `${barWidth}px`,
                height: `${SPECTRUM_CURSOR_HEIGHT}px`
              }}
            />
          </DragContainer>
          <canvas
            {...resolveTestId(this.props, '-spectrum-canvas')}
            onMouseDown={this.onSpectrumMouseDown}
            onTouchStart={this.onSpectrumTouchStart}
            {...resolve(this.props, 'spectrum')}
            ref="spectrum"
          />
        </div>
      </div>
    );
  }
}
