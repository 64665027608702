import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';

type Props = React.PropsWithChildren<{
  digit?: number;
  total?: number;
  success?: boolean;
  style?: {
    mask?: any;
    filler?: any;
    spinner?: any;
    successCircle?: any;
    container?: any;
    indicator?: any;
    backCircle?: any;
    message?: any;
    successMessage?: any;
  };
}>;

/**
Display a Progress Circle item
**/
export class ProgressCircle extends React.Component<Props> {
  static displayName = 'ProgressCircle';
  render() {
    let degree = 0;
    let counterDigit = this.props.digit;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    while (counterDigit > this.props.total) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      counterDigit = counterDigit - this.props.total; // Mostly used for days; prevent counter from looking wrong
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    degree = Math.floor((counterDigit / this.props.total) * 360);

    const rotation = 'rotate(' + degree + 'deg)';
    const halfRotation = 'rotate(' + degree / 2 + 'deg)';
    const spinnerStyle = {
      WebkitTransform: rotation,
      MozTransform: rotation,
      msTransform: rotation,
      Otransform: rotation,
      transform: rotation
    };
    const fillerStyle = {
      WebkitTransform: halfRotation,
      MozTransform: halfRotation,
      msTransform: halfRotation,
      Otransform: halfRotation,
      transform: halfRotation
    };

    const maskCss = resolve(this.props, 'mask');
    const fillerCss = resolve(this.props, 'filler', this.props.success ? 'successCircle' : null);
    const spinnerCss = resolve(this.props, 'spinner', this.props.success ? 'successCircle' : null);

    return (
      <div {...resolve(this.props, 'container')}>
        <div {...resolve(this.props, 'indicator')}>
          <div {...resolve(this.props, 'backCircle')} />
          <div {...maskCss} style={Object.assign({}, maskCss.style, fillerStyle)}>
            <div {...fillerCss} style={Object.assign({}, fillerCss.style, fillerStyle)}></div>
          </div>
          <div {...maskCss}>
            <div {...fillerCss} style={Object.assign({}, fillerCss.style, fillerStyle)}></div>
            <div {...spinnerCss} style={Object.assign({}, spinnerCss.style, spinnerStyle)}></div>
          </div>
          <div {...resolve(this.props, 'message', this.props.success ? 'successMessage' : null)}>
            {this.props.children ? this.props.children : null}
          </div>
        </div>
      </div>
    );
  }
}
