import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import PropTypes from 'prop-types';

import { InteractiveElement } from '../../containers/InteractiveElement';
import { Checkbox } from '../../forms/elements/Checkbox';
import { PanelListItem } from '../PanelListItem';
import { buildFilterValues, ALL_FILTERS_SELECTED } from '../utils/buildFilterValues';

/**
 * Selects all the values in a filter
 */
const selectAllValues = (options: any) => {
  const selectedValues = options.map((option: any) => {
    return option.value;
  });
  selectedValues.push(ALL_FILTERS_SELECTED);
  return selectedValues;
};

type OwnCheckboxFilterProps = {
  title?: string;
  name?: string;
  selectedValues?: any[];
  options?: any[];
  translate?: (...args: any[]) => any;
  onChange: (...args: any[]) => any;
  numberOfChoicesToShowByDefault?: number;
  showSelectAll?: boolean;
  classes?: any;
  style?: {
    showMoreLink?: any;
    panelListItem?: any;
    filterListTitle?: any;
  };
};

type CheckboxFilterState = any;

type CheckboxFilterProps = OwnCheckboxFilterProps & typeof CheckboxFilter.defaultProps;

/**
 * Create a filter that displays filter values as a list of checkboxes
 * i.e. selection filter
 */
class CheckboxFilter extends React.Component<CheckboxFilterProps, CheckboxFilterState> {
  static displayName = 'CheckboxFilter';

  static defaultProps = {
    showSelectAll: true,
    numberOfChoicesToShowByDefault: 10,
    selectedValues: []
  };
  static DefaultProps = {
    showSelectAll: PropTypes.bool,
    numberOfChoicesToShowByDefault: PropTypes.number,
    selectedValues: PropTypes.array
  };

  constructor(props: CheckboxFilterProps) {
    super(props);
    // Set the initial state
    this.state = {
      showAllFilterOptions: false,
      selectedValues: props.selectedValues
    };
    this.onChange = this.onChange.bind(this);
    this.onToggleShowMore = this.onToggleShowMore.bind(this);
    this.renderShowMoreEntry = this.renderShowMoreEntry.bind(this);
  }

  /**
   * Show more or less filter option values
   */
  onToggleShowMore(e: any) {
    e.preventDefault();
    this.setState({
      showAllFilterOptions: !this.state.showAllFilterOptions
    });
  }

  /**
   * Select or deselect filter option value
   */
  onChange(fieldName: any, fieldValue: any, newOptions: any) {
    let selectedValues = newOptions.selectedValues.filter((value: any) => {
      return value !== ALL_FILTERS_SELECTED;
    });
    if (fieldValue === ALL_FILTERS_SELECTED) {
      // Select All is selected or deselected
      // Select All is selected
      if (this.state.selectedValues.indexOf(ALL_FILTERS_SELECTED) === -1) {
        selectedValues = selectAllValues(this.props.options);
      } else {
        // Select is deselected, just set selectedValues to an empty array
        selectedValues = [];
      }
    } else {
      // Anything other than Select All is selected or deselected
      if (this.state.selectedValues.indexOf(fieldValue) === -1) {
        // Anything other than Select All is selected
        // if all values are selected, select "All"
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        if (newOptions.selectedValues.length === this.props.options.length) {
          selectedValues = selectAllValues(this.props.options);
        }
      }
    }
    // Set the values selected by the user (causes re-render)
    this.setState({
      selectedValues
    });
    // Call the handler
    this.props.onChange(fieldName, selectedValues);
  }

  UNSAFE_componentWillReceiveProps(nextProps: any) {
    // Set the selected values, expected to be changed when Reset All is clicked
    if (this.props.selectedValues !== nextProps.selectedValues) {
      this.setState({
        selectedValues: nextProps.selectedValues
      });
    }
  }

  /**
   * Renders show more link if it's to be displayed
   */
  renderShowMoreEntry() {
    const { showAllFilterOptions } = this.state;
    const { translate } = this.props;
    const showMoreOrLessEntryText = showAllFilterOptions
      ? // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        translate('NucleusCore_Filters_ShowLess__resx')
      : // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        translate('NucleusCore_Filters_ShowMore__resx');

    return (
      <InteractiveElement {...resolve(this.props, 'showMoreLink')} onClick={this.onToggleShowMore}>
        {showMoreOrLessEntryText}
      </InteractiveElement>
    );
  }

  render() {
    const { options, name, translate, numberOfChoicesToShowByDefault, showSelectAll } = this.props;

    const { showAllFilterOptions, selectedValues } = this.state;

    // If show more entry to be displayed
    const displayShowMoreEntry =
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      numberOfChoicesToShowByDefault > 0 && options.length > numberOfChoicesToShowByDefault;
    // Build the filter options
    const filterOptions = buildFilterValues(
      options,
      translate,
      showSelectAll,
      displayShowMoreEntry && !showAllFilterOptions,
      numberOfChoicesToShowByDefault,
      selectedValues
    );
    // Form the checkbox component
    const checkbox = (
      <Checkbox
        {...resolve(this.props, 'checkbox')}
        display="vertical"
        hideLabel
        fieldName={name}
        options={{ optionArray: filterOptions, selectedValues }}
        onChange={this.onChange}
      />
    );
    // Render the list of choices
    return (
      <PanelListItem {...this.props}>
        {checkbox}
        {displayShowMoreEntry && this.renderShowMoreEntry()}
      </PanelListItem>
    );
  }
}

export { CheckboxFilter };
