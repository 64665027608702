/**
 * Component for displaying tags as a dot
 */
import React from 'react';

import PropTypes from 'prop-types';

import { tagsPropType } from '../propTypes';
import TagStyles from '../styles/Tags.less';

const Dot = (props: $TSFixMe) => {
  const { title, tags, numberOfTagsToDisplay } = props;
  const tagList = tags.slice(0, numberOfTagsToDisplay);

  return (
    <span>
      {tagList.map((tag: $TSFixMe, i: $TSFixMe) => {
        const tagStyle = {
          backgroundColor: tag.backgroundColor
        };
        return <span key={i} className={TagStyles.tagDot} style={tagStyle} title={tag.displayName}></span>;
      })}
      {title}
    </span>
  );
};

Dot.propTypes = {
  numberOfTagsToDisplay: PropTypes.number,
  title: PropTypes.string,
  tags: tagsPropType
};

Dot.defaultProps = {
  numberOfTagsToDisplay: 2
};

Dot.displayName = 'Dot';

export default Dot;
