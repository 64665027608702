import { pseudoStateCascadedChange } from '../../../utils';

export const borderBorderStyle = {
  fieldName: 'border.borderStyle',
  component: 'SelectField',
  options: {
    label: '_fieldLabel_borderStyle__resx',
    optionArray: [
      {
        name: '_fieldOption_solid__resx',
        value: 'solid'
      },
      {
        name: '_fieldOption_dotted__resx',
        value: 'dotted'
      },
      {
        name: '_fieldOption_dashed__resx',
        value: 'dashed'
      }
    ]
  }
};

export const borderColor = {
  fieldName: 'border.color',
  component: 'ColorPickerField',
  cascadedChange: pseudoStateCascadedChange(),
  options: {
    label: '_fieldLabel_borderColor__resx',
    usePalette: true
  }
};

export const borderHeader = {
  component: 'Header',
  options: {
    title: '_fieldCategory_elementBorder__resx'
  }
};

export const borderThickness = {
  fieldName: 'border.thickness',
  component: 'SliderField',
  options: {
    label: '_fieldLabel_borderThickness__resx',
    min: 0,
    max: 100
  }
};

export default {
  borderHeader,
  'border.borderStyle': borderBorderStyle,
  'border.color': borderColor,
  'border.thickness': borderThickness
};
