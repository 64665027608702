import { ADD_UNDO_STATE, REDO, UNDO } from './undoableActions';
import {
  COMMENTS_LOAD_PENDING,
  COMMENTS_LOAD_SUCCESS,
  COMMENTS_LOAD_ERROR,
  COMMENT_DELETE_ERROR,
  COMMENT_RESOLVE_ERROR,
  COMMENT_POST_ERROR,
  RESOLVE_COMMENT,
  REMOVE_COMMENT_ERROR,
  UPDATE_COMMENT_ERROR,
  ADD_COMMENT,
  DELETE_ALL_WIDGET_COMMENTS,
  DELETE_ALL_SECTION_COMMENTS,
  DELETE_ALL_PAGE_COMMENTS,
  DELETE_COMMENT,
  PRESERVE_DRAFT,
  GET_TEMPLATE_WIDGETS,
  STORE_PAGE_LEVEL_COMMENT_COUNTS,
  STORE_PAGE_LEVEL_COMMENT_COUNTS_ERROR
} from './actions';
import { toggleCommentActiveStatus } from './utils';
import { Comment } from '../../../types/comment';

const initialState = {
  errors: [],
  drafts: {},
  comments: null,
  initialCommentsLoading: false,
  undoIndex: 0,
  undoStack: [],
  templatePageWidgets: [],
  pageCommentCounts: null
};

export function commentsReducer(state: any = initialState, action: any) {
  const pageCommentCounts = state.pageCommentCounts || {};
  switch (action.type) {
    case COMMENTS_LOAD_PENDING:
      return {
        ...state,
        initialCommentsLoading: action.payload.loading
      };
    case COMMENTS_LOAD_SUCCESS:
      return {
        ...state,
        comments: { ...state.comments, ...action.payload.comments }
      };
    case STORE_PAGE_LEVEL_COMMENT_COUNTS:
      return {
        ...state,
        pageCommentCounts: {
          ...pageCommentCounts,
          ...action.payload.pageCount
        }
      };
    case COMMENTS_LOAD_ERROR:
    case COMMENT_DELETE_ERROR:
    case COMMENT_RESOLVE_ERROR:
    case COMMENT_POST_ERROR: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }
    case STORE_PAGE_LEVEL_COMMENT_COUNTS_ERROR: {
      if (state.pageCommentCounts) {
        return {
          ...state,
          errors: [...state.errors, action.payload]
        };
      }
      return {
        ...state,
        pageCommentCounts: {},
        errors: [...state.errors, action.payload]
      };
    }
    case RESOLVE_COMMENT: {
      const { widgetId, commentId, isResolved } = action.payload;
      const widgetComments = state.comments[widgetId] || [];

      if (!widgetComments.length) {
        return state;
      }

      const updatedWidgetComments = widgetComments.map((comment: Comment) => {
        if (comment.id === commentId) {
          return {
            ...comment,
            resolved: isResolved
          };
        }
        return comment;
      });

      return {
        ...state,
        comments: {
          ...state.comments,
          [widgetId]: updatedWidgetComments
        }
      };
    }
    case REMOVE_COMMENT_ERROR: {
      const { id } = action.payload;
      const errors = state.errors.filter((err: any) => err.id !== id);
      return { ...state, errors };
    }
    case UPDATE_COMMENT_ERROR: {
      const { id, updatedValues } = action.payload;
      const errors = state.errors.map((err: any) =>
        err.id === id ? { ...err, ...updatedValues } : err
      );
      return { ...state, errors };
    }
    case ADD_COMMENT: {
      const { widgetId, pageId, ...comment } = action.payload;
      const comments = state.comments;
      const widgetComments = comments[widgetId] || [];
      const newComments = {
        ...comments,
        [widgetId]: [...widgetComments, comment]
      };
      if (pageId) {
        return {
          ...state,
          comments: newComments,
          pageCommentCounts: {
            ...pageCommentCounts,
            [pageId]: (state.pageCommentCounts[pageId] || 0) + 1
          }
        };
      }
      return {
        ...state,
        comments: newComments
      };
    }
    case DELETE_ALL_WIDGET_COMMENTS: {
      const { widgetId, pageId } = action.payload;
      const { found, updatedComments, updatedCommentsCount } = toggleCommentActiveStatus(
        state.comments,
        [widgetId]
      );
      if (!found) {
        return state;
      }

      /*
       * Handle the scenario where a user deletes a widget that has comments,
       * Undo/Redo the action will break the comments on widget level.
       */
      const newUndoStack = [...state.undoStack];
      newUndoStack[state.undoIndex] = {
        type: 'DELETE_ALL_WIDGET_COMMENTS',
        widgetId,
        pageId
      };
      return {
        ...state,
        comments: updatedComments,
        pageCommentCounts: {
          ...pageCommentCounts,
          [pageId]: (state.pageCommentCounts[pageId] || 0) - updatedCommentsCount
        },
        undoStack: newUndoStack
      };
    }
    case DELETE_ALL_SECTION_COMMENTS: {
      const { widgetIds, pageId } = action.payload;
      const { found, updatedComments, updatedCommentsCount } = toggleCommentActiveStatus(
        state.comments,
        widgetIds
      );
      if (!found) {
        return state;
      }

      /*
       * Handle the scenario where a user deletes a section of widgets that have comments,
       * Undo/Redo the action will break the comments on widget level.
       */
      const newUndoStack = [...state.undoStack];
      newUndoStack[state.undoIndex] = {
        type: 'DELETE_ALL_SECTION_COMMENTS',
        widgetIds,
        pageId
      };
      return {
        ...state,
        comments: updatedComments,
        pageCommentCounts: {
          ...pageCommentCounts,
          [pageId]: (state.pageCommentCounts[pageId] || 0) - updatedCommentsCount
        },
        undoStack: newUndoStack
      };
    }
    case DELETE_ALL_PAGE_COMMENTS: {
      const { widgetIds, pageId } = action.payload;
      const { found, updatedComments, updatedCommentsCount } = toggleCommentActiveStatus(
        state.comments,
        widgetIds
      );
      if (!found) {
        return state;
      }

      const newUndoStack = [...state.undoStack];
      newUndoStack[state.undoIndex] = {
        type: 'DELETE_ALL_PAGE_COMMENTS',
        widgetIds,
        pageId
      };
      return {
        ...state,
        comments: updatedComments,
        pageCommentCounts: {
          ...pageCommentCounts,
          [pageId]: (state.pageCommentCounts[pageId] || 0) - updatedCommentsCount
        },
        undoStack: newUndoStack
      };
    }
    case DELETE_COMMENT: {
      const { widgetId, commentId, pageId } = action.payload;
      const widgetComments = state.comments[widgetId] || [];

      if (!widgetComments.length) {
        return state;
      }

      const updatedWidgetComments = widgetComments.map((comment: Comment) => {
        if (comment.id === commentId) {
          return {
            ...comment,
            active: false
          };
        }
        return comment;
      });

      const newComment = {
        ...state.comments,
        [widgetId]: updatedWidgetComments
      };

      if (pageId) {
        return {
          ...state,
          comments: newComment,
          pageCommentCounts: {
            ...pageCommentCounts,
            [pageId]: (state.pageCommentCounts[pageId] || 0) - 1
          }
        };
      }

      return {
        ...state,
        comments: newComment
      };
    }
    case PRESERVE_DRAFT: {
      const { widgetId, newDraft } = action.payload;
      const drafts = state.drafts;

      if (!newDraft) {
        delete drafts[widgetId];
        return { ...state, ...drafts };
      }

      return {
        ...state,
        drafts: {
          ...drafts,
          [widgetId]: newDraft
        }
      };
    }
    case GET_TEMPLATE_WIDGETS: {
      const { templatePageWidgets } = action.payload;
      return {
        ...state,
        templatePageWidgets
      };
    }
    case ADD_UNDO_STATE: {
      // If there's already Undo Stack for comments, then push an empty state for mimicing undoable reducer.
      if (state.undoStack[0]) {
        // Clear all actions redoable for the undoStack behind the currentIndex if an undoable action is performed
        const newUndoStack = state.undoStack.slice(0, state.undoIndex + 1);
        newUndoStack.push({});
        return {
          ...state,
          undoIndex: state.undoIndex + 1,
          undoStack: newUndoStack
        };
      }

      return state;
    }
    case UNDO: {
      if (!state.undoStack[state.undoIndex]) {
        return state;
      }
      const { type } = state.undoStack[state.undoIndex];
      switch (type) {
        case 'DELETE_ALL_WIDGET_COMMENTS': {
          const { widgetId, pageId } = state.undoStack[state.undoIndex];
          const { updatedComments, updatedCommentsCount } = toggleCommentActiveStatus(
            state.comments,
            [widgetId]
          );

          return {
            ...state,
            comments: updatedComments,
            pageCommentCounts: {
              ...pageCommentCounts,
              [pageId]: (state.pageCommentCounts[pageId] || 0) + updatedCommentsCount
            },
            undoIndex: state.undoIndex - 1
          };
        }
        case 'DELETE_ALL_SECTION_COMMENTS': {
          const { widgetIds, pageId } = state.undoStack[state.undoIndex];
          const { updatedComments, updatedCommentsCount } = toggleCommentActiveStatus(
            state.comments,
            widgetIds
          );
          return {
            ...state,
            comments: updatedComments,
            pageCommentCounts: {
              ...pageCommentCounts,
              [pageId]: (state.pageCommentCounts[pageId] || 0) + updatedCommentsCount
            },
            undoIndex: state.undoIndex - 1
          };
        }
        case 'DELETE_ALL_PAGE_COMMENTS': {
          const { widgetIds, pageId } = state.undoStack[state.undoIndex];
          const { updatedComments, updatedCommentsCount } = toggleCommentActiveStatus(
            state.comments,
            widgetIds
          );
          return {
            ...state,
            comments: updatedComments,
            pageCommentCounts: {
              ...pageCommentCounts,
              [pageId]: (state.pageCommentCounts[pageId] || 0) + updatedCommentsCount
            },
            undoIndex: state.undoIndex - 1
          };
        }
        default: {
          return {
            ...state,
            undoIndex: state.undoIndex - 1
          };
        }
      }
    }
    case REDO: {
      if (state.undoStack[state.undoIndex + 1]) {
        const { type } = state.undoStack[state.undoIndex + 1];
        switch (type) {
          case 'DELETE_ALL_WIDGET_COMMENTS': {
            const { widgetId, pageId } = state.undoStack[state.undoIndex + 1];
            const { updatedComments, updatedCommentsCount } = toggleCommentActiveStatus(
              state.comments,
              [widgetId]
            );
            return {
              ...state,
              comments: updatedComments,
              pageCommentCounts: {
                ...pageCommentCounts,
                [pageId]: (state.pageCommentCounts[pageId] || 0) - updatedCommentsCount
              },
              undoIndex: state.undoIndex + 1
            };
          }
          case 'DELETE_ALL_SECTION_COMMENTS': {
            const { widgetIds, pageId } = state.undoStack[state.undoIndex + 1];
            const { updatedComments, updatedCommentsCount } = toggleCommentActiveStatus(
              state.comments,
              widgetIds
            );
            return {
              ...state,
              comments: updatedComments,
              pageCommentCounts: {
                ...pageCommentCounts,
                [pageId]: (state.pageCommentCounts[pageId] || 0) - updatedCommentsCount
              },
              undoIndex: state.undoIndex + 1
            };
          }
          case 'DELETE_ALL_PAGE_COMMENTS': {
            const { widgetIds, pageId } = state.undoStack[state.undoIndex + 1];
            const { updatedComments, updatedCommentsCount } = toggleCommentActiveStatus(
              state.comments,
              widgetIds
            );
            return {
              ...state,
              comments: updatedComments,
              pageCommentCounts: {
                ...pageCommentCounts,
                [pageId]: (state.pageCommentCounts[pageId] || 0) - updatedCommentsCount
              },
              undoIndex: state.undoIndex + 1
            };
          }
          default: {
            return {
              ...state,
              undoIndex: state.undoIndex + 1
            };
          }
        }
      }
      return { ...state };
    }
    default:
      return state;
  }
}
