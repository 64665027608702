import uniqueId from 'lodash/uniqueId';
import { Comment } from '../../../types';
import { getAllWidgetsOnPage } from '../../../utils';
import {
  GET_TEMPLATE_WIDGETS,
  COMMENTS_LOAD_PENDING,
  COMMENTS_LOAD_SUCCESS,
  COMMENTS_LOAD_ERROR
} from './actions';
import {
  COMMENT_ERRORS,
  getCommentError,
  removeCommentError,
  setCommentErrorLoading
} from './errors';
import { getPageLevelCommentCount } from './getPageLevelCommentCount';

function getCurrentPageTemplateWidgets(website: any, templatePageId: string) {
  const templatePageWidgets = getAllWidgetsOnPage(website, templatePageId).map(
    (widget: any) => widget.id
  );
  return { type: GET_TEMPLATE_WIDGETS, payload: { templatePageWidgets } };
}

function loadCommentsPending(loading: boolean) {
  return { type: COMMENTS_LOAD_PENDING, payload: { loading } };
}

function loadCommentsSuccess(comments: Comment[]) {
  return { type: COMMENTS_LOAD_SUCCESS, payload: { comments } };
}

function setLoadCommentsError(isAlert = true) {
  const type = COMMENT_ERRORS.Load;
  const data = {};

  return {
    type: COMMENTS_LOAD_ERROR,
    payload: { id: uniqueId(), retrying: false, isAlert, type, data }
  };
}

export function loadComments(pageId: string) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    // site editor or guetside site
    const client = state.clients.commentsClient;
    dispatch(loadCommentsPending(true));

    try {
      const widgetIds = getAllWidgetsOnPage(state.website, pageId).map((widget: any) => widget.id);

      const templatePageId = state.website.pages[pageId]?.templateId;
      // Load Header and Footer's comments which tie to the current page
      if (templatePageId) {
        dispatch(getCurrentPageTemplateWidgets(state.website, templatePageId));
        const templateWidgetIds = getAllWidgetsOnPage(state.website, templatePageId).map(
          (widget: any) => widget.id
        );
        widgetIds.concat(templateWidgetIds);
      }

      const comments = await client.get(widgetIds);

      const isGuestSide = !!state.pathInfo?.currentPageId;
      if (!isGuestSide && !state.comments.pageCommentCounts) {
        dispatch(getPageLevelCommentCount());
      }

      dispatch(loadCommentsSuccess(comments));
    } catch (err) {
      // Determine if current site is guestside? Yes -> Diplay Alert error.
      const isGuestSide = !!state.pathInfo?.currentPageId;
      dispatch(setLoadCommentsError(isGuestSide));
    }

    dispatch(loadCommentsPending(false));
  };
}

export function retryLoadComments() {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const oldComments = state.comments;

    const error = getCommentError(oldComments.errors, COMMENT_ERRORS.Load, {});

    dispatch(setCommentErrorLoading(error.id, true));

    try {
      const pageId = state.pathInfo?.currentPageId || state.editor.pageId;
      const client = state.clients.commentsClient;
      const widgetIds = getAllWidgetsOnPage(state.website, pageId).map((widget: any) => widget.id);
      const templatePageId = state.website.pages[pageId]?.templateId;
      // Load Header and Footer's comments which tie to the current page
      if (templatePageId) {
        const templateWidgetIds = getAllWidgetsOnPage(state.website, templatePageId).map(
          (templateWidget: any) => templateWidget.id
        );
        widgetIds.push(...templateWidgetIds);
      }
      const comments = await client.get(widgetIds);

      dispatch(loadCommentsSuccess(comments));

      const isGuestSide = !!state.pathInfo?.currentPageId;
      if (!isGuestSide && !state.comments.pageCommentCounts) {
        dispatch(getPageLevelCommentCount());
      }

      dispatch(removeCommentError(error.id));
    } catch (err) {
      dispatch(setCommentErrorLoading(error.id, false));
    }
  };
}
