import React from 'react';

import { FeedbackMessage } from './FeedbackMessage';

type Props = {
  type?: 'success' | 'warning' | 'error';
  style?: {
    success?: any;
    warning?: any;
    error?: any;
  };
  classes?: any;
};

/**
Composes the FeedbackMessage component with Cvent specific styles to mimic planner side flash messages.
**/
const FlashMessage = (props: Props) => {
  const { type = 'success', classes, style } = props;
  const newClasses = !classes ? undefined : Object.assign({}, classes, { message: classes[type] });
  const newStyles = !style ? undefined : Object.assign({}, style, { message: style[type] });
  // @ts-expect-error ts-migrate(2322) FIXME: Type '({ success?: any; warning?: any; error?: any... Remove this comment to see the full error message
  return <FeedbackMessage {...props} classes={newClasses} style={newStyles} />;
};
FlashMessage.displayName = 'FlashMessage';

export { FlashMessage };
