/**
 * Convert an array to a map
 * @param items the array to convert
 * @param getKey a function to get the key from an item in the array, by default gets the property 'id'
 * @param getValue a function to get the value from an item in the array, by default gets the item
 */
export function arrayToMap(items: any, getKey = (x: any) => x.id, getValue = (x: any) => x) {
  const result = {};
  items.forEach((item: any) => {
    if (item) {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      result[getKey(item)] = getValue(item);
    }
  });
  return result;
}

/**
 * Convert a map to an array
 * @param map the map to convert
 * @param getItem function to convert the key and value to an item, by default return the value
 */
export function mapToArray(map: any, getItem = (key: any, value: any) => value) {
  const result = [];
  for (const key in map) {
    if (map.hasOwnProperty(key) && map[key] !== null && map[key] !== undefined) {
      result.push(getItem(key, map[key]));
    }
  }
  return result;
}
