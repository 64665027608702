import switchLocale from './switchLocale';

/**
 * Returns an array of localized names for 12 months, starting from January
 *
 * @param locale {string}
 * @param format {string} - the representation of the name, possible values are "short" or "long"
 * @return {Array} - array of localized names for 12 months, starting from January.
 */

const months = {};

const shouldOverrideLocale = locale => {
  return locale.toLowerCase().includes('de-');
};

export default function getMonthNames(locale, format = 'short') {
  if (months[locale] && months[locale][format]) return months[locale][format];
  if (!months[locale]) months[locale] = {};
  months[locale][format] = [];
  const d = new Date(2012, 0, 1);
  for (let i = 0; i < 12; i++) {
    d.setMonth(i);
    let month;
    try {
      month = shouldOverrideLocale(locale)
        ? new Intl.DateTimeFormat('de-DE', { month: format }).format(d)
        : new Intl.DateTimeFormat(locale, { month: format }).format(d);
    } catch (error) {
      month = new Intl.DateTimeFormat(switchLocale(locale), {
        month: format
      }).format(d);
    }
    months[locale][format].push(month);
  }
  return months[locale][format];
}
