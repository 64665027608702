import React from 'react';

import Icon from '@cvent/nucleus-icon';
import {
  InteractiveElement,
  Transition,
  InfoFlyout,
  InfoFlyoutProps,
  FlyoutMessagePlacementProps
} from 'nucleus-core';

import { EditorThemeContext } from '../../../context/EditorThemeContext';
import { FieldLabel } from './FieldLabel';
import { InlineValidation } from './InlineValidation';

export interface IBaseFieldProps {
  itemId?: string;
  itemType: 'layoutItem' | 'theme' | 'page';
  fieldName?: string;
  options: Record<string, any> & {
    name?: string;
    label?: string;
    description?: string;
    isRequired?: boolean;
    tooltip?: string | ((translate: (...args: any[]) => any, editorTheme: any) => string);
    tooltipIconFallbackText?: string;
    tooltipPlacement?: FlyoutMessagePlacementProps;
  };
  validations?: any[];
  selectedValidation?: any;
  onChange?: (...args: any[]) => void;
  translate: (...args: any[]) => any;
  guestTranslate?: (...args: any[]) => any;
  addContextEditorNavItem?: (...args: any[]) => any;
  popContextEditorNavigation?: (...args: any[]) => any;
  clearClientValidation?: (...args: any[]) => any;
}

/**
 * A base field class that implements any necessary default functions / prop types / context types.
 */
export abstract class BaseField<
  T extends IBaseFieldProps,
  S = Record<string, unknown>
> extends React.Component<T, S> {
  static displayName = 'BaseField';

  constructor(props: T) {
    super(props);
    this.getSimpleLabel = this.getSimpleLabel.bind(this);
    this.getSimpleInlineValidation = this.getSimpleInlineValidation.bind(this);
    this.getValidationLevel = this.getValidationLevel.bind(this);
  }

  /**
   * Resolves a label for a simple field editor that only contains one field. If your field editor
   * is complex and contains multiple fields, you would need to create your labels separately. This is
   * a convenience method to reduce code duplication in simple fields.
   * @param {string} text - text to use in place of the options.label property. Necessary in case the field
   *    needs to make simple modifications of the configured label. Even with this property, if you're using
   *    this function to get the label of more than one item in your editor field, you're probably doing it wrong.
   * @param {boolean} false - set classname on simple label to prevent nested instances of classnames
   *    for some editor fields.
   */
  getSimpleLabel(text: any, addLabelStyle = false) {
    const { options, selectedValidation, translate } = this.props;
    return (
      <FieldLabel
        text={text || options?.label}
        addLabelStyle={addLabelStyle}
        isRequired={options?.isRequired}
        selectedValidation={selectedValidation}
        description={options?.description}
        translate={translate}
      />
    );
  }

  /**
   * Resolves an inline validation component for a simple field editor that only contains one field.
   * If your field editor is complex and contains multiple fields, you would need to create your inline
   * validation components separately depending on the field being validated. This is a convenience
   * method to reduce code duplication in simple fields.
   */
  getSimpleInlineValidation() {
    const { validations, translate } = this.props;
    return (
      <EditorThemeContext.Consumer>
        {editorTheme => (
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'Validations' does not exist on type '{}'... Remove this comment to see the full error message
          <Transition classes={editorTheme.Validations.classes}>
            {validations && validations.length && (
              <InlineValidation
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'Validations' does not exist on type '{}'... Remove this comment to see the full error message
                classes={editorTheme.Validations.classes}
                validations={validations}
                translate={translate}
              />
            )}
          </Transition>
        )}
      </EditorThemeContext.Consumer>
    );
  }

  /**
   * Detects the highest level of validation in the provided selectedValidation and validations list.
   */
  getValidationLevel(selectedValidation: any, validations: any) {
    let level;
    if (selectedValidation && selectedValidation.level) {
      level = selectedValidation.level;
    }
    if (validations && validations.length) {
      const hasError = validations.find((validation: any) => validation.level === 'error');
      const hasWarning = validations.find((validation: any) => validation.level === 'warning');
      if (hasError) {
        level = 'error';
      } else if (level !== 'error' && hasWarning) {
        level = 'warning';
      }
    }
    return level;
  }

  /**
   * Display an information icon, which opens a flyout upon hover or click
   */
  renderTooltip(flyoutProps: Partial<InfoFlyoutProps> = {}) {
    const { translate, options } = this.props;
    return (
      <EditorThemeContext.Consumer>
        {editorTheme => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'InfoFlyout' does not exist on type '{}'.
          const { InfoFlyout: InfoFlyoutClasses } = editorTheme;
          const mergedFlyoutProps: Partial<InfoFlyoutProps> = {
            forceDirection: {
              horz: 'left'
            },
            classes: {
              trigger: InfoFlyoutClasses,
              flyout: InfoFlyoutClasses
            },
            ...flyoutProps,
            ...(options.tooltipPlacement ?? {})
          };
          return (
            <InfoFlyout {...mergedFlyoutProps}>
              <InteractiveElement>
                <Icon
                  icon="information"
                  fallbackText={translate(options.tooltipIconFallbackText)}
                />
              </InteractiveElement>
              <div className={InfoFlyoutClasses.content}>
                {options?.tooltip &&
                  (typeof options?.tooltip === 'string'
                    ? translate(options.tooltip)
                    : options?.tooltip(translate, editorTheme))}
              </div>
            </InfoFlyout>
          );
        }}
      </EditorThemeContext.Consumer>
    );
  }
}
