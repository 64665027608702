/**
 * Loads Intl polyfill data for an Danish based locale.
 * @param {string} locale - the Danish locale string to use.
 * @param {function} cb - the callback function to execute after loading the locale data.
 */
export default function loadDanish(locale: any, cb: any) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'IntlPolyfill' does not exist on type 'Gl... Remove this comment to see the full error message
  if (!global.IntlPolyfill) {
    cb();
    return;
  }
  const localeTokens = locale.replace('_', '-').split('-');
  switch (localeTokens[1] ? localeTokens[1].toUpperCase() : null) {
    case 'DK':
    default:
      require.ensure(['intl/locale-data/jsonp/da-DK'], require => {
        require('intl/locale-data/jsonp/da-DK');
        cb();
      });
  }
}
