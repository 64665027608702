import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';

import { BaseWidget } from '../BaseWidget';
import {
  BEGINNING,
  MIDDLE,
  END,
  SINGLE_STEP,
  FORWARD,
  BACKWARD,
  COMPLETE,
  EXIT
} from '../LinearNavigator/LinearNavigator';
import { LinearNavigatorWidget } from '../LinearNavigator/LinearNavigatorWidget';
import { ThemeableComponentProps } from '../ThemeableComponent';
/**
 * Gets the position of the page within the linear pages.
 * @param {string} currentPageId - The id of the current page in the linear process.
 * @param {Array<string>} pageIds - All pages by id.
 * @returns The position within the linear pages.
 */
const getPostition = (currentPageId: any, pageIds: any) => {
  const position = pageIds.indexOf(currentPageId);
  const isAProcessPage = position !== -1;
  if (!isAProcessPage) {
    return undefined;
  }

  const beginning = 0;
  if (position === beginning) {
    const singlePage = pageIds.length === 1;
    return singlePage ? SINGLE_STEP : BEGINNING;
  }

  const end = pageIds.length - 1;
  return position === end ? END : MIDDLE;
};

interface LinearPageNavigatorWidgetProps extends ThemeableComponentProps {
  config: {
    displayText?: {
      backward: string;
      forward: string;
      complete: string;
      exit: string;
    };
  };
  classes: Record<string, unknown>;
  currentPageId: string;
  pageIds: Array<string>;
  onNavigateRequest: (...args: any[]) => any;
  onCompleteRequest: (...args: any[]) => any;
  onExitRequest: (...args: any[]) => any;
  disableForwardNavigation?: boolean;
  router?: Record<string, unknown>;
  reverseButtonOrderOnMobile?: boolean;
  disableAllButtons?: boolean;
  useDefaultCursorWhenDisabled?: boolean;
}
/**
 * A widget for navigating through the pages of a linear process. Allows a user
 * to move forward, backwards and to exit or complete the pages in a linear process. Configuration
 * includes items such as preventing forward navigation if business rules have not
 * been met.
 */
export class LinearPageNavigatorWidget extends BaseWidget<LinearPageNavigatorWidgetProps> {
  static displayName = 'LinearPageNavigatorWidget';

  onNavigateRequest = (direction: any) => {
    const { pageIds, currentPageId, onNavigateRequest, onCompleteRequest, onExitRequest, router } =
      this.props;
    const position = pageIds.indexOf(currentPageId);
    switch (direction) {
      case BACKWARD:
        onNavigateRequest(pageIds[position - 1], false, router);
        break;
      case FORWARD:
        onNavigateRequest(pageIds[position + 1], true, router);
        break;
      case COMPLETE:
        onCompleteRequest(router);
        break;
      case EXIT:
        onExitRequest(router);
        break;
      default:
    }
  };
  render() {
    const {
      currentPageId,
      pageIds,
      disableForwardNavigation,
      reverseButtonOrderOnMobile,
      disableAllButtons = false,
      useDefaultCursorWhenDisabled = false,
      ...other
    } = this.props;
    const currentPosition = getPostition(currentPageId, pageIds);
    if (!currentPosition) {
      // If the page is not in the list of process pages, the widget
      // will not be displayed.
      return <div />;
    }

    return (
      <LinearNavigatorWidget
        {...resolveTestId(this.props)}
        {...other}
        currentPosition={currentPosition}
        disableForwardNavigation={disableForwardNavigation}
        onNavigateRequest={this.onNavigateRequest}
        {...(reverseButtonOrderOnMobile ? { reverseButtonOrderOnMobile } : {})}
        disableAllButtons={disableAllButtons}
        useDefaultCursorWhenDisabled={useDefaultCursorWhenDisabled}
      />
    );
  }
}
