/**
 * A reducer for storing the data (config, events etc.) to be displayed on the calendar
 */

const LOAD_CALENDAR_DATA = 'event-calendar/calendar/LOAD_CALENDAR_DATA';

export const LIST_VIEW_PAGE_SIZE = 20;

/**
 * Load the initial calendar data (config + events + everything)
 */
export function loadCalendarData(calendarData: $TSFixMe) {
  return {
    type: LOAD_CALENDAR_DATA,
    payload: {
      calendarData
    }
  };
}

/**
 * Reducer to keep track of the information displayed on the calendar
 */
export default function calendar(state = {}, action: $TSFixMe) {
  switch (action.type) {
    case LOAD_CALENDAR_DATA: {
      const { calendarData } = action.payload;
      return {
        offset: LIST_VIEW_PAGE_SIZE + 1,
        searchId: calendarData.searchId,
        id: calendarData.id,
        totalEventsCount: calendarData.totalCount,
        events: calendarData.events || {},
        config: calendarData.config
      };
    }
    default:
      return state;
  }
}
