import { SECTION_ROW } from './LayoutTypes';

/**
 * Finds the first parent of the given item that is a section.
 */
// @ts-expect-error ts-migrate(7023) FIXME: 'getParentSection' implicitly has return type 'any... Remove this comment to see the full error message
export function getParentSection(widgetsState: any, item: any) {
  const parent = widgetsState[item.layout.parentId];
  if (parent.layout.type === SECTION_ROW) {
    return parent;
  }
  return getParentSection(widgetsState, parent);
}
