import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { injectTestId } from '@cvent/nucleus-test-automation';

interface IInlineValidationProps {
  validations: any[];
  hideArrow: boolean;
  classes?: any;
  translate: (...args: any[]) => any;
}

/**
 * An inline validation component for an editor field.
 */
export class InlineValidation extends React.Component<IInlineValidationProps> {
  static displayName = 'InlineValidation';
  static defaultProps = {
    hideArrow: false
  };

  render() {
    const { validations, translate } = this.props;
    const level = validations.find(validation => validation.level === 'error')
      ? 'error'
      : 'warning';
    // NOTE: The ul has paddings on it, which show immediately.
    // Adding a wrapper div helps to animate the message from 0px.
    return (
      <div>
        <ul {...resolve(this.props, 'container', level)} key="inline-validation">
          {validations.map((validation, index) => {
            if (validation.message && validation.message.key) {
              const tokens = {};
              if (validation.message.tokens) {
                Object.entries(validation.message.tokens).forEach(keyValuePair => {
                  const key = keyValuePair[0];
                  const value = keyValuePair[1];
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  tokens[key] = translate(value);
                });
              }
              return (
                <li
                  key={index}
                  {...resolve(this.props, 'inline', validation.level)}
                  {...injectTestId(`nucleus-site-editor-inline-message:${validation.message.key}`)}
                >
                  {translate(validation.message.key, tokens)}
                </li>
              );
            }
            if (validation.message && React.isValidElement(validation.message)) {
              return (
                <li key={index} {...resolve(this.props, 'inline', validation.level)}>
                  {validation.message}
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  }
}
