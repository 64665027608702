/**
 * Function to check if a string / an object has any content. We have two
 * different rich text scenarios that we need to support.
 * 1) For the legacy rich text editor (nucleus-core RTE), the site editor would store an object of the
 *    form { htmlContent, editorState, colors }. In this case, if htmlContent is truthy, the function returns true.
 * 2) For the carina rich text editor, we need to check if there's at least
 *    one block (the property DraftJS uses to store content) and that only block has text.
 */
export function hasRichTextContent(value: any) {
  if (typeof value === 'string' && value) {
    return true;
  }
  if (value && value.hasOwnProperty('htmlContent') && value.htmlContent) {
    return true;
  }
  return (
    value &&
    value.content &&
    value.content.blocks &&
    (value.content.blocks.length > 1 ||
      (value.content.blocks.length === 1 && value.content.blocks[0].text))
  );
}
