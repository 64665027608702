import React from 'react';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Classes from './CommentHeader.less';

export const CommentHeaderBase = (props: any) => {
  const { translate, commentMode, topPos = 0 } = props;
  return (
    <>
      {commentMode && (
        <div id="comments-header" className={`${Classes.commentWrapper} ${Classes.wrapper}`}>
          <div className={Classes.container} style={{ top: topPos }}>
            {translate('NucleusGuest_CommentsMode__resx')}
          </div>
        </div>
      )}
    </>
  );
};

CommentHeaderBase.propTypes = {
  translate: PropTypes.func,
  commentMode: PropTypes.bool,
  topPos: PropTypes.string
};

export const CommentHeader = connect((state: any) => {
  const translate = state.text.translate;
  const { reviewModeEnabled } = state;

  return {
    translate,
    commentMode: reviewModeEnabled
  };
})(CommentHeaderBase);
