/**
 * This component extends the Toolbar component from react-big-calendar@v0.14.0.
 *
 * 1) References to "className" and "style" are removed from the functions copied from the base component,
 *    and replaced by "resolve" and "select" to apply styles.
 * 2) Replace button elemnet with Button component to render "Today",
      and use InteractiveElement and Icon components to render next and previous navigation.
 * 3) Render for view names groups is left as is in the base component, with className="rbc-btn-group" being removed.
 *    b/c it's hidden for current month-view only implementation.
 *
 * There are no functional changes.
 */

import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import Icon from '@cvent/nucleus-icon';
import CalendarToolbar from 'react-big-calendar/lib/Toolbar';
import { navigate } from 'react-big-calendar/lib/utils/constants';
import message from 'react-big-calendar/lib/utils/messages';

import { Button } from '../buttons/Button';
import { InteractiveElement } from '../containers/InteractiveElement';

export class Toolbar extends CalendarToolbar {
  static displayName = 'Toolbar';
  static propTypes = { ...CalendarToolbar.propTypes };

  render() {
    // eslint-disable-next-line prefer-const
    let { messages, label } = this.props;
    messages = message(messages);

    const iconClasses = {
      container: resolve(this.props, 'iconContainer').className
    };
    return (
      <div {...resolve(this.props, 'toolbar')}>
        <div {...resolve(this.props, 'toolbarButtonGroup')}>
          <Button
            onClick={this.navigate.bind(null, navigate.TODAY)}
            classes={{
              button: this.props.classes.toolbarButton
            }}
            style={{
              button: this.props.style.toolbarButton
            }}
          >
            {messages.today}
          </Button>
          <span>
            <InteractiveElement
              onClick={this.navigate.bind(null, navigate.PREVIOUS)}
              element="span"
              {...resolve(this.props, 'toolbarButton', 'toolbarIconContainer')}
              title={messages.previous}
              aria-label={messages.previous}
            >
              <Icon icon="back" classes={iconClasses} modifier={this.props.classes.icon} />
            </InteractiveElement>
            <InteractiveElement
              onClick={this.navigate.bind(null, navigate.NEXT)}
              element="span"
              {...resolve(this.props, 'toolbarButton', 'toolbarIconContainer')}
              title={messages.next}
              aria-label={messages.next}
            >
              <Icon icon="forward" classes={iconClasses} modifier={this.props.classes.icon} />
            </InteractiveElement>
          </span>
        </div>

        <span {...resolve(this.props, 'toolbarLabel')}>{label}</span>

        <span>{this.viewNamesGroup(messages)}</span>
      </div>
    );
  }
}

export const toolbarStyleKeys = [
  'toolbar',
  'toolbarButtonGroup',
  'toolbarLabel',
  'toolbarButton',
  'toolbarIconContainer',
  'iconContainer',
  'icon'
];
