import React, { Component } from 'react';

import { sanitizeUrl } from '@braintree/sanitize-url';
import { resolve } from '@cvent/nucleus-dynamic-css';
import Logger from '@cvent/nucleus-logging';
import { TAG_TYPE, EVENT_STATUS_CLOSED } from 'cvent-event-calendar/lib/CalendarView/Constants';
import { tagsPropType } from 'cvent-event-calendar/lib/CalendarView/propTypes';
import Tags from 'cvent-event-calendar/lib/CalendarView/tags/Tags';
import { getFormattedDate } from 'cvent-event-calendar/utils/dateFormatUtil';
import { sortBy } from 'lodash';
import moment from 'moment';
import { Button } from 'nucleus-core';
import { sanitizeInnerHtml } from 'nucleus-text';
import PropTypes from 'prop-types';

import ItemDetail from './ItemDetail';
import { CALENDAR_URL } from '../clients/CalendarEventsClient';

const LOG = new Logger('CalendarItemDetailView');

export default class CalendarItemDetailView extends Component<any, any> {
  static displayName = 'CalendarItemDetailView';
  static propTypes = {
    defaultFields: PropTypes.arrayOf(
      PropTypes.shape({
        fieldName: PropTypes.string,
        fieldValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.number]),
        fieldSequence: PropTypes.number,
        showFieldName: PropTypes.bool,
        fieldType: PropTypes.string
      })
    ),
    otherFields: PropTypes.array,
    tags: PropTypes.arrayOf(tagsPropType),
    eventId: PropTypes.string,
    calendarId: PropTypes.string,
    dateTimeFormat: PropTypes.object,
    buttonConfig: PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string.isRequired
    }),
    isWebinar: PropTypes.bool,
    allDay: PropTypes.bool,
    status: PropTypes.string,
    type: PropTypes.string,
    translate: PropTypes.func.isRequired,
    isProcessing: PropTypes.bool,
    iconSettings: PropTypes.object.isRequired,
    processingLabel: PropTypes.string,
    showAddToCalendarLink: PropTypes.bool,
    classes: PropTypes.object,
    style: PropTypes.shape({
      /* Style applied to field's header (h6) */
      fieldName: PropTypes.object,
      /* Style applied to event's description */
      description: PropTypes.object,
      /* Style applied to event's location */
      location: PropTypes.object,
      /* Style applied to event's start date, end date */
      eventDate: PropTypes.object,
      /* Style applied to event's registration deadline date */
      date: PropTypes.object,
      /* Style applied to event custom fields */
      custom: PropTypes.object,
      /* Style applied to loading text */
      loadingText: PropTypes.object,
      /* Style applied to event's title */
      title: PropTypes.object
    })
  };

  calendarLink: $TSFixMe;

  constructor(props: $TSFixMe) {
    super(props);
    this.getMappedFields = this.getMappedFields.bind(this);
    this.downloadICalendarFile = this.downloadICalendarFile.bind(this);
  }

  /**
   * Method that downloads an ICal file
   **/
  downloadICalendarFile() {
    const { eventId, calendarId, type } = this.props;
    const typeQuery = `type=${type}`;
    window.open(`${CALENDAR_URL}/${String(calendarId)}/events/${String(eventId)}/ical?${typeQuery}`);
  }

  getMappedFields(fields: $TSFixMe, showIcons = false, fallbackText = null) {
    const { dateTimeFormat, translate, classes, style, isWebinar, allDay, status, showAddToCalendarLink } = this.props;
    return sortBy(fields, 'fieldSequence').map((fieldObj: $TSFixMe, index: $TSFixMe) => {
      let children = null;
      let icon = null;
      let newFieldObj: $TSFixMe = {
        fieldName:
          fieldObj.fieldType === 'custom' && fieldObj.fieldId
            ? /*
               * 41 = event custom field entity type id.
               * This is the format which the translations-service returns us the translations with
               */
              translate(`41.${fieldObj.fieldId}.qstn_text_main_001`, {}, () => fieldObj.fieldName)
            : translate(fieldObj.fieldName),
        fieldType: fieldObj.fieldType,
        showFieldName: fieldObj.showFieldName,
        fieldValue: fieldObj.fieldValue,
        fieldSequence: fieldObj.fieldSequence
      };
      switch (fieldObj.fieldType) {
        case 'location': {
          icon = {
            isVisible: showIcons,
            imageName: isWebinar ? 'webinar' : 'location',
            className: 'locationIconWrapper',
            fallbackText: fallbackText ? fallbackText.location : null
          };
          children = (
            <span {...resolve(this.props, 'locationWrapper')}>
              {fieldObj.fieldValue.location && <span {...resolve(this.props, 'eventLocation')}>{fieldObj.fieldValue.location}</span>}
              <span>{fieldObj.fieldValue.address}</span>
            </span>
          );
          break;
        }
        case 'eventDate': {
          icon = { isVisible: showIcons, imageName: 'time', className: 'dateIconWrapper', fallbackText: fallbackText ? fallbackText.date : null };
          const formattedDate = getFormattedDate(dateTimeFormat, fieldObj.fieldValue, translate, allDay);
          const addToCalendar = translate('ews_ecl_cust_label_text_0001');
          children = (
            <span>
              <span>{formattedDate.startDate}</span>
              {formattedDate.endDate ? <span aria-label="to"> - </span> : null}
              {formattedDate.endDate ? <span>{formattedDate.endDate}</span> : null}
              {!allDay && <span>&nbsp;{formattedDate.timeZone}</span>}
              {allDay && <span aria-label={translate('calendar_allday__resx')}>&nbsp;&#8226;&nbsp;{translate('calendar_allday__resx')}</span>}
              {status === EVENT_STATUS_CLOSED && (
                <span aria-label={translate('EventCalendar_ClosedEvents__resx')}>
                  &nbsp;&#8226;&nbsp;{translate('EventCalendar_ClosedEvents__resx')}
                </span>
              )}
              {showAddToCalendarLink && (
                <div>
                  <a
                    {...resolve(this.props, 'addToCalendar')}
                    href="#"
                    onClick={this.downloadICalendarFile}
                    ref={c => {
                      this.calendarLink = c;
                    }}
                  >
                    {addToCalendar}
                  </a>
                </div>
              )}
            </span>
          );
          break;
        }
        case 'date': {
          newFieldObj.isAdditionalField = true;
          const formattedDate = getFormattedDate(dateTimeFormat, fieldObj.fieldValue, translate, false);
          children = <span>{formattedDate}</span>;
          break;
        }
        case 'title': {
          children = <h5>{fieldObj.fieldValue}</h5>;
          break;
        }
        case 'description':
        case 'html': {
          newFieldObj = {
            fieldName: translate('_calendarListView_description__resx'),
            fieldType: fieldObj.fieldType,
            showFieldName: fieldObj.showFieldName,
            isAdditionalField: true
          };
          children = <span dangerouslySetInnerHTML={sanitizeInnerHtml(fieldObj.fieldValue)} />;
          break;
        }
        case 'custom': {
          newFieldObj.isAdditionalField = true;
          try {
            if (moment.utc(fieldObj.fieldValue, moment.defaultFormat, true).isValid()) {
              // We'll only use the date time formatting for values coming in strict UTC format
              const formattedDate = getFormattedDate(dateTimeFormat, fieldObj.fieldValue, translate, false);
              children = <span>{formattedDate}</span>;
            } else {
              children = <span>{fieldObj.fieldValue}</span>;
            }
          } catch (exception) {
            LOG.error('Exception for Custom Event Field: ', fieldObj.fieldValue, exception);
            children = <span>{fieldObj.fieldValue}</span>;
          }
          break;
        }
        default: {
          newFieldObj.isAdditionalField = true;
          children = <span>{fieldObj.fieldValue}</span>;
        }
      }
      return (
        <ItemDetail icon={icon} fieldObj={newFieldObj} key={index} classes={classes} style={style}>
          {children}
        </ItemDetail>
      );
    });
  }

  componentDidUpdate() {
    // focus on add to calendar link when dialog is opened
    if (this.calendarLink) {
      this.calendarLink.focus();
    }
  }

  render() {
    const { isProcessing, processingLabel, buttonConfig, defaultFields, iconSettings, otherFields, tags = [], classes, style } = this.props;
    // get icon settings
    const { showIcons, fallbackText } = iconSettings;
    /** To sort the array in order of sequence in ascending order */
    const mappedDefaultFields = this.getMappedFields(defaultFields, showIcons, fallbackText);
    const mappedOtherFields = this.getMappedFields(otherFields);
    const collectionOfAllTags = [].concat(...tags);

    let button = null;
    if (buttonConfig) {
      buttonConfig.url = sanitizeUrl(buttonConfig.url);
      if (buttonConfig.url !== 'about:blank') {
        button = (
          <Button
            // @ts-expect-error TS(2322): Type '{ children: any; id: string; key: string; cl... Remove this comment to see the full error message
            id="calendarList_websiteButton"
            key="NextButton"
            classes={classes.button}
            style={style.button}
            kind="primary"
            onClick={() => {
              window.open(buttonConfig.url, '_blank');
            }}
          >
            {buttonConfig.title}
          </Button>
        );
      }
    }

    return (
      <div {...resolve(this.props, 'wrapper', isProcessing ? 'loadingText' : null)} key="eventFields">
        {isProcessing ? (
          processingLabel
        ) : (
          <div>
            {mappedDefaultFields}
            {collectionOfAllTags.length > 0 && <Tags tags={collectionOfAllTags} classes={classes} style={style} type={TAG_TYPE.PILL} />}
            {button}
            {mappedOtherFields}
          </div>
        )}
      </div>
    );
  }
}
