import { getValueObjectWithPseudoState } from '../fields/getJSONValueWithPseudoState';

export function getStyleObjectWithPseudoState(
  styleObject: any,
  customStyleSettings: any,
  pseudoState: any
) {
  const styleObjectWithPseudoState = getValueObjectWithPseudoState(pseudoState)({
    ...styleObject,
    ...(customStyleSettings ? { customSettings: customStyleSettings } : {})
  });

  return {
    styleObject: styleObjectWithPseudoState[pseudoState],
    customStyleSettings: styleObjectWithPseudoState.customSettings
      ? styleObjectWithPseudoState.customSettings[pseudoState]
      : {}
  };
}
