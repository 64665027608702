/**
 * A set of pseudo-state style modes, where each mode dictates how the pseudo-state style of a widget will be applied.
 */
export enum PseudoStateStyleMode {
  // Removes styling. Pseudo-state styling will be the same as default styling.
  NONE = 'none',
  // Automatically chooses between BRIGHTNESS or INVERTED, based on the styling of the widget.
  AUTOMATIC = 'automatic',
  // Displays a range of pre-determined, selectable list of brightness variations, based on the widget's background color.
  BRIGHTNESS = 'brightness',
  // Inverts the background and border colors.
  INVERTED = 'inverted',
  // Allows full customization of the pseudo-state style.
  CUSTOM = 'custom'
}
