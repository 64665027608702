import { SafeMode, ThemeColors } from '@cvent/blocks/utils/types';
import { buildThemes } from '@cvent/blocks/formulas';
import chroma from 'chroma-js';
import { getLightMood } from './moodUtils';
import merge from 'lodash/merge';
import { isFeatureFlagEnabled } from '@cvent/nucleus-platform';

export const NEW_SECTION_THEME_IDS = ['primary', 'secondary', 'mood', 'background'];

interface BlockPalette {
  primary: string;
  secondary: string;
  text: string;
  textAccent: string;
  accent: string;
}

interface BlockSectionThemePalette {
  [key: string]: BlockPalette;
}

export const defaultColorStyleMappings = {
  isColorStyle: true,
  background: {
    color: 'primary',
    image: null
  },
  elements: {
    header1: {
      text: { color: 'text' }
    },
    header2: {
      text: { color: 'text' }
    },
    header3: {
      text: { color: 'text' }
    },
    header4: {
      text: { color: 'text' }
    },
    text1: {
      text: { color: 'text' }
    },
    text2: {
      text: { color: 'text' }
    },
    body1: {
      text: { color: 'text' }
    },
    body2: {
      text: { color: 'text' }
    },
    link: {
      text: { color: 'text' }
    },
    primaryButton: {
      text: { color: 'textAccent' },
      background: {
        color: 'secondary'
      },
      border: {
        color: 'secondary'
      },
      hover: {
        styleMode: 'none'
      }
    },
    secondaryButton: {
      text: {
        color: 'secondary'
      },
      background: { color: null },
      border: {
        color: 'secondary'
      },
      hover: {
        styleMode: 'none'
      }
    },
    label: {
      text: { color: 'accent' }
    },
    input: {
      text: {
        color: 'secondary'
      },
      background: { color: 'accent' },
      border: {
        color: 'secondary'
      },
      hover: {
        styleMode: 'none'
      }
    }
  }
};

export function createBlocksPalette(
  themeColors: ThemeColors,
  safeMode?: SafeMode
): BlockSectionThemePalette {
  const { primary, secondary, mood, background } = buildThemes(themeColors, safeMode);
  return {
    primary: {
      primary: chroma(primary.block.base as string).hex(),
      secondary: chroma(primary.backgroundColor.click.base as string).hex(),
      text: chroma(primary.font.color.base as string).hex(),
      textAccent: chroma(primary.font.color.fill as string).hex(),
      accent: chroma(primary.block.accent as string).hex()
    },
    secondary: {
      primary: chroma(secondary.block.base as string).hex(),
      secondary: chroma(secondary.backgroundColor.click.base as string).hex(),
      text: chroma(secondary.font.color.base as string).hex(),
      textAccent: chroma(secondary.font.color.fill as string).hex(),
      accent: chroma(secondary.block.accent as string).hex()
    },
    background: {
      primary: chroma(background.block.base as string).hex(),
      secondary: chroma(background.backgroundColor.click.base as string).hex(),
      text: chroma(background.font.color.base as string).hex(),
      textAccent: chroma(background.font.color.fill as string).hex(),
      accent: chroma(background.block.accent as string).hex()
    },
    mood: {
      primary: chroma(mood.block.base as string).hex(),
      secondary: chroma(mood.backgroundColor.click.base as string).hex(),
      text: chroma(mood.font.color.base as string).hex(),
      textAccent: chroma(mood.font.color.fill as string).hex(),
      accent: chroma(mood.block.accent as string).hex()
    }
  };
}

function addBlocksMoodTheme(theme: any) {
  if (theme.global.blocks) {
    return theme;
  }

  const palette = theme.global.palette;
  const mood = getLightMood({
    primary: palette.text,
    secondary: palette.textAccent
  });
  const blocks = {
    moodType: mood.id,
    safeMode: false,
    themeColors: mood.moodPalette
  };

  return merge({}, theme, {
    global: {
      blocks
    }
  });
}

export function buildSectionTheme(id: string, blockPalette: Record<string, any>) {
  /*
  For existing websites, the "mood" section theme (or "color style") initially acts as a legacy color style, such that
  all sections have it applied by default. As such, no element mappings should be present.
   */
  if (id === 'mood') {
    return {
      id,
      name: 'Color Style',
      background: {
        color: 'primary'
      }
    };
  }

  return {
    id,
    name: 'Color Style',
    palette: blockPalette,
    ...defaultColorStyleMappings
  };
}

function addBlocksSectionThemesTheme(theme: any) {
  const blocks = theme.global.blocks;
  if (!blocks) {
    return theme;
  }

  const blocksPalette = createBlocksPalette(blocks.themeColors, blocks.safeMode);
  const newSectionThemes = NEW_SECTION_THEME_IDS.reduce((accumulator, blockName) => {
    return Object.assign(accumulator, {
      [blockName]: buildSectionTheme(blockName, (blocksPalette as any)[blockName])
    });
  }, {});

  return merge({}, theme, {
    sections: {
      ...theme.sections,
      ...newSectionThemes
    }
  });
}

function addDefaultThemeSectionIdTheme(theme: any) {
  if (!theme.sections.mood.id || theme.global?.defaultThemeSectionId) {
    return theme;
  }

  return merge({}, theme, {
    global: {
      defaultThemeSectionId: theme.sections.mood.id
    }
  });
}

export function blocksThemeMigrations(theme: any) {
  const withBlocksMood = addBlocksMoodTheme(theme);
  const withBlocksSections = addBlocksSectionThemesTheme(withBlocksMood);
  return addDefaultThemeSectionIdTheme(withBlocksSections);
}

export function migrateLegacyAccountThemes(themes: any, blocksThemingEnabled: boolean) {
  const updatedThemes = { ...themes };
  if (
    (isFeatureFlagEnabled('BlocksTheming') || blocksThemingEnabled) &&
    Object.keys(themes)?.length
  ) {
    Object.entries(updatedThemes).forEach(([key, theme]: [string, any]) => {
      if (!theme.global?.blocks) {
        updatedThemes[key] = blocksThemeMigrations(theme);
      }
    });
  }
  return updatedThemes;
}
