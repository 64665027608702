/**
 * This component extends the Month component from react-big-calendar@v0.14.0.
 *
 * 1) References to "className" and "style" are removed from the functions copied from the base component,
 *    and replaced by "resolve" and "select" to apply styles and pass styles down to child components.
 * 2) Correct readerDateHeading to renderDateHeading.
 * 3) Add isToday flag to renderDateHeading function, and pass it down to DateHeader component.
 * 4) Comment out postition assignment in handleShowMore function.
 * 5) Completely override renderOverlay function to use our FlyoutMessage component.
 * 6) Pass renderOverlay and popup props to DateContentRow component.
 * 7) Remove {this.props.popup && this.renderOverlay()} from render function,
 *    b/c the result of renderOverlay is passed to ShowMore component to display.
 * 8) Use inRange function defined in this file, instead of importing from react-big-calendar.
 * 9) Pass through keepFlyoutOpenOnDocumentClick prop.
 * 10) Add newFlyout param to handleShowMore function to close previously opened flyout.
 *
 */

import React from 'react';

import chunk from 'lodash/chunk';
import { resolve, select } from '@cvent/nucleus-dynamic-css';
import PropTypes from 'prop-types';
import Header from 'react-big-calendar/lib/Header';
import localizer from 'react-big-calendar/lib/localizer';
import Month from 'react-big-calendar/lib/Month';
import { accessor as get } from 'react-big-calendar/lib/utils/accessors';
import { navigate, views } from 'react-big-calendar/lib/utils/constants';
import dates from 'react-big-calendar/lib/utils/dates';
import { segStyle, sortEvents } from 'react-big-calendar/lib/utils/eventLevels';
import { notify } from 'react-big-calendar/lib/utils/helpers';
import { isSelected } from 'react-big-calendar/lib/utils/selection';

import { FlyoutMessage, flyoutMessageStyleKeys } from '../flyout/FlyoutMessage';
import { DateContentRow, dateContentRowStyleKeys as dateContentRowStyles } from './DateContentRow';
import { DateHeader, dateHeaderStyleKeys as dateHeaderStylesKeys } from './DateHeader';
import { EventCell } from './EventCell';

/**
 * The inRange function below fix the issue in inRange from react-big-calendar/lib/utils/eventLevels
 * which incorrectly extends events (that ends at the start time of the following week)
 * to the first day of the following weeek.
 *
 */
function inRange(e: any, start: any, end: any, { startAccessor, endAccessor }: any) {
  const eStart = dates.startOf(get(e, startAccessor), 'day');
  const eEnd = get(e, endAccessor);

  const startsBeforeEnd = dates.lte(eStart, end, 'day');
  let endsAfterStart;
  if (dates.lt(eStart, eEnd, 'day') && dates.eq(eEnd, start, 'second')) {
    endsAfterStart = false;
  } else {
    endsAfterStart = dates.gte(eEnd, start, 'day');
  }
  return startsBeforeEnd && endsAfterStart;
}

const eventsForWeek = (evts: any, start: any, end: any, props: any) =>
  evts.filter((e: any) => inRange(e, start, end, props));

export class CalendarMonth extends Month {
  static displayName = 'CalendarMonth';
  static propTypes = {
    ...Month.propTypes,
    keepFlyoutOpenOnDocumentClick: PropTypes.bool,
    // Add date specific cell style or className
    dateCellStyleGetter: PropTypes.func
  };

  handleShowMore = (events: any, date: any, cell: any, slot: any, newFlyout: any) => {
    const { popup, onDrillDown, onShowMore, getDrilldownView } = this.props;
    // cancel any pending selections so only the event click goes through.
    this.clearSelection();

    if (popup) {
      /**
       * Comment out position as our flyout's positoin is based on its trigger position, not the cell's it's in.
       */
      // let position = getPosition(cell, findDOMNode(this));

      if (this._currentFlyout && this._currentFlyout !== newFlyout) {
        this._currentFlyout.handleHide();
      }
      this._currentFlyout = newFlyout;

      this.setState({
        overlay: { date, events }
      });
    } else {
      notify(onDrillDown, [date, getDrilldownView(date) || views.DAY]);
    }
    notify(onShowMore, [events, date, slot]);
  };

  renderOverlay = () => {
    const { date, events } = this.state.overlay;
    const { dayHeaderFormat, culture, selected, components, ...rest } = this.props;
    const { classes, style } = select(this.props, 'eventPlain', 'eventContent');
    return (
      <FlyoutMessage
        {...select(this.props, ...flyoutMessageStyleKeys)}
        flyoutDirection="vertical"
        forceDirection={{ horz: 'center' }}
      >
        <div {...resolve(this.props, 'flyoutContent')}>
          <h6 {...resolve(this.props, 'flyoutHeader')}>
            {localizer.format(date, dayHeaderFormat, culture)}
          </h6>
          <ul {...resolve(this.props, 'eventList')}>
            {events.map((event: any, idx: any) => (
              <li {...resolve(this.props, 'eventListItem')} key={idx}>
                {/* @ts-expect-error 'EventCell' cannot be used as a JSX component. */}
                <EventCell
                  {...rest}
                  classes={{
                    ...classes,
                    event: classes && classes.eventPlain ? classes.eventPlain : undefined
                  }}
                  style={{
                    ...style,
                    event: style && style.eventPlain ? style.eventPlain : undefined
                  }}
                  event={event}
                  eventComponent={components.event}
                  eventWrapperComponent={components.eventWrapper}
                  selected={isSelected(event, selected)}
                  onSelect={this.handleSelectEvent}
                />
              </li>
            ))}
          </ul>
        </div>
      </FlyoutMessage>
    );
  };

  renderHeaders(row: any, format: any, culture: any) {
    const first = row[0];
    const last = row[row.length - 1];
    const HeaderComponent = this.props.components.header || Header;
    const resolvedStyles = resolve(this.props, 'header');
    return dates.range(first, last, 'day').map((day: any, idx: any) => (
      <div
        key={'header_' + idx}
        className={resolvedStyles.className}
        style={{
          ...segStyle(1, 7),
          ...resolvedStyles.style
        }}
      >
        <HeaderComponent
          date={day}
          label={localizer.format(day, format, culture)}
          localizer={localizer}
          format={format}
          culture={culture}
        />
      </div>
    ));
  }

  renderDateHeading = ({ date, className, style, ...props }: any) => {
    const { date: currentDate, getDrilldownView, dateFormat, culture } = this.props;

    const isOffRange = dates.month(date) !== dates.month(currentDate);
    const isCurrent = dates.eq(date, currentDate, 'day');
    const isToday = dates.eq(date, new Date(), 'day');
    const drilldownView = getDrilldownView(date);
    const label = localizer.format(date, dateFormat, culture);
    const DateHeaderComponent = this.props.components.dateHeader || DateHeader;
    const resolvedStyles = resolve(
      this.props,
      isOffRange ? 'offRangeDate' : undefined,
      isCurrent ? 'currentDate' : undefined,
      isToday ? 'today' : undefined
    );
    return (
      <div
        {...props}
        className={`${resolvedStyles.className} ${className}`}
        style={{
          ...style,
          ...resolvedStyles.style
        }}
      >
        <DateHeaderComponent
          label={label}
          date={date}
          drilldownView={drilldownView}
          isOffRange={isOffRange}
          isCurrent={isCurrent}
          isToday={isToday}
          onDrillDown={(e: any) => this.handleHeadingClick(date, drilldownView, e)}
          {...select(this.props, ...dateHeaderStylesKeys)}
        />
      </div>
    );
  };

  renderWeek = (week: any, weekIdx: any) => {
    const {
      components,
      selectable,
      titleAccessor,
      startAccessor,
      endAccessor,
      allDayAccessor,
      eventPropGetter,
      messages,
      selected,
      now,
      popup,
      keepFlyoutOpenOnDocumentClick,
      dateCellStyleGetter
    } = this.props;

    let { events } = this.props;

    const { needLimitMeasure, rowLimit } = this.state;

    events = eventsForWeek(events, week[0], week[week.length - 1], this.props);
    events.sort((a: any, b: any) => sortEvents(a, b, this.props));

    return (
      /* @ts-expect-error 'DateContentRow' cannot be used as a JSX component. */
      <DateContentRow
        key={weekIdx}
        ref={weekIdx === 0 ? 'slotRow' : undefined}
        container={this.getContainer}
        {...select(this.props, ...dateContentRowStyles)}
        now={now}
        range={week}
        events={events}
        maxRows={rowLimit}
        selected={selected}
        selectable={selectable}
        messages={messages}
        titleAccessor={titleAccessor}
        startAccessor={startAccessor}
        endAccessor={endAccessor}
        allDayAccessor={allDayAccessor}
        eventPropGetter={eventPropGetter}
        renderHeader={this.renderDateHeading}
        renderForMeasure={needLimitMeasure}
        onShowMore={this.handleShowMore}
        onSelect={this.handleSelectEvent}
        onSelectSlot={this.handleSelectSlot}
        eventComponent={components.event}
        eventWrapperComponent={components.eventWrapper}
        dateCellWrapper={components.dateCellWrapper}
        popup={popup}
        renderOverlay={this.renderOverlay}
        keepFlyoutOpenOnDocumentClick={keepFlyoutOpenOnDocumentClick}
        dateCellStyleGetter={dateCellStyleGetter}
      />
    );
  };

  render() {
    const { date, culture, weekdayFormat } = this.props;
    const month = dates.visibleDays(date, culture);
    const weeks = chunk(month, 7);
    this._weekCount = weeks.length;

    return (
      <div {...resolve(this.props, 'monthViewContainer')}>
        <div {...resolve(this.props, 'monthViewHeaderContainer')}>
          {this.renderHeaders(weeks[0], weekdayFormat, culture)}
        </div>
        {weeks.map((week, idx) => this.renderWeek(week, idx))}
      </div>
    );
  }
}

CalendarMonth.navigate = (date: any, action: any) => {
  switch (action) {
    case navigate.PREVIOUS:
      return dates.add(date, -1, 'month');

    case navigate.NEXT:
      return dates.add(date, 1, 'month');

    default:
      return date;
  }
};

CalendarMonth.range = (date: any, { culture }: any) => {
  const start = dates.firstVisibleDay(date, culture);
  const end = dates.lastVisibleDay(date, culture);
  return { start, end };
};
