import { Tooltip } from '../ImagePreviewTooltip';
import { pseudoStateCascadedChange } from '../../../utils';

export function hideBackgroundImageSetting(state: any, valueObject: any) {
  return (
    !valueObject ||
    !valueObject.background ||
    !valueObject.background.image ||
    !valueObject.background.image.url ||
    !valueObject.background.image.url.assetUri
  );
}

export const backgroundBorderRadius = {
  fieldName: 'background.borderRadius',
  component: 'SliderField',
  options: {
    label: '_fieldLabel_borderRadius__resx',
    min: 0,
    max: 100
  }
};

export const backgroundColor = {
  fieldName: 'background.color',
  component: 'ColorPickerField',
  cascadedChange: pseudoStateCascadedChange(),
  options: {
    label: '_fieldLabel_backgroundColor__resx',
    usePalette: true,
    allowTransparent: true
  }
};

export const backgroundColorAlpha = {
  fieldName: 'background.colorAlpha',
  component: 'ColorAlphaField',
  options: {
    label: 'NucleusSiteEditor_StyleFields_backgroundColorAlphaLabel__resx',
    min: 0,
    max: 100
  }
};

export const backgroundHeader = {
  component: 'Header',
  options: {
    title: '_fieldCategory_elementBackground__resx'
  }
};

export const backgroundImageHeader = {
  component: 'Header',
  options: {
    title: '_fieldLabel_backgroundImage__resx'
  }
};

export const backgroundImageScale = {
  fieldName: 'background.image.scale',
  component: 'ToggleField',
  hideField: hideBackgroundImageSetting,
  options: {
    name: 'NucleusSiteEditor_EditorPanel_BackgroundImageScale__resx'
  }
};

export const backgroundImageRepeatX = {
  fieldName: 'background.image.repeat.x',
  component: 'ToggleField',
  hideField: hideBackgroundImageSetting,
  options: {
    name: 'NucleusSiteEditor_EditorPanel_BackgroundImageRepeatX__resx'
  }
};

export const backgroundImageRepeatY = {
  fieldName: 'background.image.repeat.y',
  component: 'ToggleField',
  hideField: hideBackgroundImageSetting,
  options: {
    name: 'NucleusSiteEditor_EditorPanel_BackgroundImageRepeatY__resx'
  }
};

export const backgroundImageUrl = {
  fieldName: 'background.image.url',
  component: 'ImagePreviewField',
  options: {
    label: '_fieldLabel_backgroundImage__resx',
    tooltip: Tooltip
  }
};

export const backgroundImagePositionXOffset = {
  fieldName: 'background.image.position.xOffset',
  component: 'SelectField',
  hideField: hideBackgroundImageSetting,
  options: {
    label: 'NucleusSiteEditor_EditorPanel_BackgroundImageHorizontalAlign__resx',
    optionArray: [
      {
        name: 'NucleusSiteEditor_EditorPanel_BackgroundImageAlignOptions_Left__resx',
        value: 'left'
      },
      {
        name: 'NucleusSiteEditor_EditorPanel_BackgroundImageAlignOptions_Center__resx',
        value: 'center'
      },
      {
        name: 'NucleusSiteEditor_EditorPanel_BackgroundImageAlignOptions_Right__resx',
        value: 'right'
      }
    ]
  }
};

export const backgroundImagePositionYOffset = {
  fieldName: 'background.image.position.yOffset',
  component: 'SelectField',
  hideField: hideBackgroundImageSetting,
  options: {
    label: 'NucleusSiteEditor_EditorPanel_BackgroundImageVerticalAlign__resx',
    optionArray: [
      {
        name: 'NucleusSiteEditor_EditorPanel_BackgroundImageAlignOptions_Top__resx',
        value: 'top'
      },
      {
        name: 'NucleusSiteEditor_EditorPanel_BackgroundImageAlignOptions_Center__resx',
        value: 'center'
      },
      {
        name: 'NucleusSiteEditor_EditorPanel_BackgroundImageAlignOptions_Bottom__resx',
        value: 'bottom'
      }
    ]
  }
};

export default {
  backgroundHeader,
  backgroundImageHeader,
  'background.borderRadius': backgroundBorderRadius,
  'background.color': backgroundColor,
  'background.colorAlpha': backgroundColorAlpha,
  'background.image.position.xOffset': backgroundImagePositionXOffset,
  'background.image.position.yOffset': backgroundImagePositionYOffset,
  'background.image.repeat.x': backgroundImageRepeatX,
  'background.image.repeat.y': backgroundImageRepeatY,
  'background.image.scale': backgroundImageScale,
  'background.image.url': backgroundImageUrl
};
