import { FontFamilyOptions, getFontFamilyWeights } from './getFontFamilyWeights';
import memoize from 'lodash/memoize';
import { GuestsideStandardFontList, GuestsideFontStacks, GuestsideFontList } from '@cvent/fonts';

type FontOption = { name: string; value: string };

/**
 * This function groups font family options into three categories, to display in the Font Family drop-down menu.
 * The three categories are: Custom Fonts, Standard Fonts, and Advanced Fonts.
 * The first category only appears if there's custom fonts.
 */
export const getGroupedFontFamilyOptions = memoize(
  (options = FontFamilyOptions, customFontFamilyOptions) => {
    const standardFontOptions: FontOption[] = [];
    const advancedFontOptions: FontOption[] = [];
    options.forEach((font: any) => {
      if (GuestsideStandardFontList.includes(font.value)) {
        standardFontOptions.push(font);
      } else {
        advancedFontOptions.push(font);
      }
    });

    const sortFonts = (fonts: any) => {
      fonts.sort((a: FontOption, b: FontOption) =>
        a.value.toLowerCase().localeCompare(b.value.toLowerCase(), 'en')
      );
      return fonts;
    };
    return [
      ...(customFontFamilyOptions && customFontFamilyOptions.length
        ? [
            {
              category: 'NucleusSiteEditor_Theme_UserDefinedCustomFontOptionGroup__resx',
              items: customFontFamilyOptions
            }
          ]
        : []),
      {
        category: 'NucleusSiteEditor_Theme_StandardFontOptionGroup__resx',
        items: sortFonts(standardFontOptions)
      },
      {
        category: 'NucleusSiteEditor_Theme_CustomFontOptionGroup__resx',
        items: sortFonts(advancedFontOptions)
      }
    ];
  }
);

// This function checks the list of available font weights/styles given the new font family,
// and reset font-weight to 400 and font-style to normal if their current value doesn't exist for the new font family.
// NOTE: if fontFamily is 'primary' or 'secondary',
// it should be converted to one of ids in FontFamilyIds or custom fonts first.
export function getFontWeightAndStyle({ fontFamily, fontWeight, fontStyle, customFonts }: any) {
  let newFontWeight = fontWeight;
  let newFontStyle = fontStyle;
  const fontFamilyWeights = getFontFamilyWeights(fontFamily, customFonts);
  // If the current font weight doesn't exist for the new font family,
  // reset the font weight to 400 (regular), if 400 is in the fontFamilyWeights array,
  // otherwise reset it to the middle one in the list.
  if (
    !fontWeight ||
    !fontFamilyWeights.find((weight: any) => weight.value.fontWeight === fontWeight)
  ) {
    if (fontFamilyWeights.find((weight: any) => weight.value.fontWeight === 400)) {
      newFontWeight = 400;
    } else {
      newFontWeight = fontFamilyWeights[Math.floor(fontFamilyWeights.length / 2)].value.fontWeight;
    }
  }

  // If the current font style doesn't exist for the new font family,
  // reset the font style to 'normal' if 'normal' in the list,
  // otherwise reset it to the middle one in the list.
  if (
    !fontStyle ||
    !fontFamilyWeights.find(
      (weight: any) =>
        weight.value.fontWeight === newFontWeight && weight.value.fontStyle === fontStyle
    )
  ) {
    if (fontFamilyWeights.find((weight: any) => weight.value.fontStyle === 'normal')) {
      newFontStyle = 'normal';
    } else {
      newFontStyle = fontFamilyWeights[Math.floor(fontFamilyWeights.length / 2)].value.fontStyle;
    }
  }

  return {
    fontWeight: newFontWeight,
    fontStyle: newFontStyle
  };
}

/**
 * Returns an array of custom font family options to display in the Font Family drop-down menu.
 * The options are ordered alphabetically by 'fontFamily' value.
 */
export const getCustomFontFamilyOptions = memoize(customFonts => {
  if (!customFonts || !Object.keys(customFonts).length) {
    return undefined;
  }

  return Object.values(customFonts)
    .map(font => {
      const singleQuotedFontFamilyName =
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        font.fontFamily[0] === "'" && font.fontFamily[font.fontFamily.length - 1] === "'";
      const fontFamilyName = singleQuotedFontFamilyName
        ? // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          font.fontFamily.slice(1, -1)
        : // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
          font.fontFamily;
      return {
        name: fontFamilyName,
        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
        value: font.id
      };
    })
    .sort((a, b) => {
      const nameA = a.name.toUpperCase();
      const nameB = b.name.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
});

/**
 * Returns an array of font stacks for the passed in custom fonts.
 */
export const getCustomFontStacks = memoize(customFonts => {
  if (!customFonts || !Object.keys(customFonts).length) {
    return undefined;
  }
  const fontStacks = {};
  Object.values(customFonts).forEach(font => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    fontStacks[font.id] = `${font.fontFamily}, ${GuestsideFontStacks[font.fallbackFont]}`;
  });
  return fontStacks;
});

/**
 * Returns font stack for a given font family id.
 * For backward compatibility, if a font stack is passed in as the first parameter,
 * it checks if the font stack matches one of the framework-provide fonts.
 * If so, it just returns the font stack;
 * if not, it returns the font stack of Arial.
 */
export function getFontStack(fontFamily: any, customFonts: any) {
  let fontStack = fontFamily;
  const customFontStacks = getCustomFontStacks(customFonts);
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (customFontStacks && customFontStacks[fontFamily]) {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    fontStack = customFontStacks[fontFamily];
  } else if (GuestsideFontStacks[fontFamily]) {
    fontStack = GuestsideFontStacks[fontFamily];
  } else if (!Object.values(GuestsideFontStacks).includes(fontFamily)) {
    // if the font family isn't one of the custom fonts user provided,
    // and it isn't one of the fonts site editor provides,
    // reset font-family to Arial.
    fontStack = GuestsideFontStacks[GuestsideFontList.Arial];
  }
  return fontStack;
}

/**
 * Resolves 'primary' and 'secondary' fontFamily values to font family id or font stack
 * defined in 'fontPalette' of the global theme.
 */
export function resolvePaletteFont(state: any, fontFamily: any) {
  if (
    (fontFamily === 'primary' || fontFamily === 'secondary') &&
    state.website.theme &&
    state.website.theme.global &&
    state.website.theme.global.fontPalette
  ) {
    return state.website.theme.global.fontPalette[fontFamily];
  }
  return fontFamily;
}
