import React, { cloneElement } from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

import { InteractiveElement } from '../containers/InteractiveElement';
import { tapOrClick } from '../touchEventHandlers';
import { getElementSize } from '../utils/getElementSize';
import { KEY_RETURN, KEY_SPACE } from '../utils/keyCodes';

// Increment on this number to make sure each accordion panel gets a unique id,
// to associate its header with its content.
let id = 0;

type OwnProps = React.PropsWithChildren<{
  header: string | React.ReactNode;
  isOpen?: boolean;
  onToggle?: (...args: any[]) => any;
  style?: {
    panel?: any;
    header?: any;
    indicatorDown?: any;
    indicatorLeft?: any;
    body?: any;
    content?: any;
  };
  classes?: any;
  headingLevel?: '1' | '2' | '3' | '4' | '5' | '6';
  ariaDisabled?: boolean;
  isCustomHeaderFocusable?: boolean;
  enableAnimation?: boolean;
  ariaRole?: string;
}>;

type State = any;

type Props = React.PropsWithChildren<OwnProps & typeof AccordionPanel.defaultProps>;

/**
AccordionPanel
**/
export class AccordionPanel extends React.Component<Props, State> {
  static displayName = 'AccordionPanel';
  static defaultProps = {
    isOpen: false,
    headingLevel: '4',
    enableAnimation: true
  };

  body: any;
  content: any;
  id: any;

  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: props.isOpen,
      maxHeight: 'none'
    };
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.setIsOpen = this.setIsOpen.bind(this);
    if (this.id === undefined) {
      id = id + 1;
      this.id = id;
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.setIsOpen(this.props.isOpen);
    } else if (
      prevProps.children !== this.props.children ||
      (prevProps.header !== this.props.header && this.state.isOpen)
    ) {
      // Contents of the body have changed, we need to reset the max height
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ maxHeight: 'none' });
    }
  }
  calculateContentHeight() {
    const contentElement = this.content;
    if (!this.content) {
      return 'none';
    }
    return getElementSize(contentElement).height;
  }
  setIsOpen(isOpen: any) {
    const maxHeight = this.calculateContentHeight();
    if (
      !isOpen &&
      this.body &&
      (!this.body.style.maxHeight || this.body.style.maxHeight === 'none') &&
      this.props.enableAnimation &&
      window &&
      typeof window.requestAnimationFrame === 'function'
    ) {
      // Special handling for the first time that the panel gets closed. The max height will not have been calculated
      // previously because of the performance impact of calculating it for every accordion panel at once when the
      // page first loads. Max height will have started at 'none' and needs to animate to 0, but transitions involving
      // 'none' will not be animated. Instead, we need to set the max height, wait for that to be rendered and then
      // start the transition.
      this.body.style.maxHeight = maxHeight + 'px';
      window.requestAnimationFrame(() => this.setState({ isOpen, maxHeight }));
    } else {
      this.setState({ isOpen, maxHeight });
    }
  }
  onKeyDown(e: any) {
    if (e.keyCode === KEY_RETURN || e.keyCode === KEY_SPACE) {
      this.onToggle();
      if (e.keyCode === KEY_SPACE) {
        e.preventDefault();
      }
    }
  }

  onToggle() {
    if (this.props.onToggle) {
      this.props.onToggle(this.state.isOpen);
    } else {
      this.setIsOpen(!this.state.isOpen);
    }
  }

  bodyStyle(panelBody: any) {
    const { isOpen, maxHeight } = this.state;
    const { enableAnimation } = this.props;
    if (isOpen) {
      if (enableAnimation) {
        return { ...panelBody.style, maxHeight, visibility: 'visible' };
      }
      return { ...panelBody.style, visibility: 'visible' };
    }
    if (enableAnimation) {
      return { ...panelBody.style, maxHeight: '0', visibility: 'hidden' };
    }
    return { ...panelBody.style, display: 'none' };
  }

  render() {
    const { isOpen } = this.state;
    let header;
    const { ariaDisabled, isCustomHeaderFocusable, headingLevel, ariaRole } = this.props;
    const headerId = `${this.id}-header`;
    const panelId = `${this.id}-panel`;
    const ariaAttributes = {
      'aria-expanded': isOpen,
      'aria-disabled': ariaDisabled,
      id: headerId,
      'aria-controls': panelId
    };

    if (this.props.header) {
      if (typeof this.props.header === 'string') {
        header = (
          <div {...resolve(this.props, 'header', isOpen ? 'indicatorDown' : 'indicatorLeft')}>
            {this.props.header}
          </div>
        );
      } else {
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        header = cloneElement(this.props.header, {
          isOpen,
          ...(isCustomHeaderFocusable ? ariaAttributes : null)
        });
      }
    }

    if (isCustomHeaderFocusable) {
      header = (
        <div {...tapOrClick(this.onToggle)} onKeyDown={this.onKeyDown}>
          {header}
        </div>
      );
    } else {
      header = (
        <InteractiveElement
          {...tapOrClick(this.onToggle)}
          onKeyDown={this.onKeyDown}
          {...ariaAttributes}
        >
          {header}
        </InteractiveElement>
      );
    }
    const panelBody = resolve(this.props, 'body', isOpen ? 'open' : 'close');
    return (
      <div {...resolveTestId(this.props)} {...resolve(this.props, 'panel')}>
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '"1" | "2" | "3" | "4" | "5" | "6"' is not as... Remove this comment to see the full error message */}
        <div role="heading" aria-level={headingLevel}>
          {header}
        </div>
        <div
          className={panelBody.className}
          style={this.bodyStyle(panelBody)}
          ref={c => (this.body = c)}
          role="region"
          aria-labelledby={headerId}
          id={panelId}
        >
          <div
            {...resolve(this.props, 'content')}
            ref={c => (this.content = c)}
            {...(ariaRole !== undefined && { role: ariaRole })}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}
