import { getFontWeightAndStyle, resolvePaletteFont } from './Fonts/utils';
import { getFontFamilyId } from './Fonts/getFontFamilyId';
import { getFontFamilyWeights } from './Fonts/getFontFamilyWeights';
import { getStyleObjectValue } from './getStyleObjectValue';
import { StyleFields } from './StyleFields';

function hideBackgroundImageSetting(state: any, valueObject: any) {
  return (
    !valueObject ||
    !valueObject.customSettings.background ||
    !valueObject.customSettings.background.image ||
    !valueObject.customSettings.background.image.url ||
    !valueObject.customSettings.background.image.url.assetUri
  );
}

const borderColor = {
  fieldName: 'customSettings.border.color',
  component: 'ColorPickerField',
  options: {
    label: '_fieldLabel_borderColor__resx',
    usePalette: true
  }
};

const backgroundColor = {
  fieldName: 'customSettings.background.color',
  component: 'ColorPickerField',
  options: {
    label: '_fieldLabel_backgroundColor__resx',
    usePalette: true,
    allowTransparent: true
  }
};

const backgroundColorAlpha = {
  fieldName: 'customSettings.background.colorAlpha',
  component: 'ColorAlphaField',
  options: {
    label: 'NucleusSiteEditor_StyleFields_backgroundColorAlphaLabel__resx',
    min: 0,
    max: 100
  }
};

export const CustomStyleFields = {
  'customSettings.text.fontSize': {
    fieldName: 'customSettings.text.fontSize',
    component: 'SliderField',
    options: {
      label: '_fieldLabel_fontSize__resx',
      min: 2,
      max: 100
    }
  },
  'customSettings.text.textAlign': {
    fieldName: 'customSettings.text.textAlign',
    component: 'CustomRadioField',
    options: {
      label: '_fieldLabel_textAlign__resx',
      baseClass: 'alignmentRadio',
      optionArray: [
        {
          name: '_fieldOption_left__resx',
          icon: 'alignLeft',
          value: 'left',
          className: 'left'
        },
        {
          name: '_fieldOption_center__resx',
          icon: 'alignCenter',
          value: 'center',
          className: 'center'
        },
        {
          name: '_fieldOption_right__resx',
          icon: 'alignRight',
          value: 'right',
          className: 'right'
        },
        {
          name: '_fieldOption_justify__resx',
          icon: 'alignJustify',
          value: 'justify',
          className: 'justify'
        }
      ]
    }
  },
  'customSettings.text.fontFamily': {
    ...StyleFields['text.fontFamily'],
    fieldName: 'customSettings.text.fontFamily',
    options: {
      ...StyleFields['text.fontFamily'].options,
      getSelectedValue: (state: any, props: any) => {
        return getFontFamilyId({
          fontFamily: getStyleObjectValue(state, props, 'customSettings.text.fontFamily'),
          customFonts: state.customFonts
        });
      }
    }
  },
  'customSettings.text.fontWeight': {
    ...StyleFields['text.fontWeight'],
    fieldName: 'customSettings.text.fontWeight',
    options: {
      ...StyleFields['text.fontFamily'].options,
      getSelectedValue: (state: any, props: any) => {
        const fontWeight = getStyleObjectValue(state, props, 'customSettings.text.fontWeight');
        const fontStyle =
          getStyleObjectValue(state, props, 'customSettings.text.fontStyle') || 'normal';

        return getFontWeightAndStyle({
          fontFamily: resolvePaletteFont(
            state,
            getStyleObjectValue(state, props, 'customSettings.text.fontFamily')
          ),
          fontWeight,
          fontStyle,
          customFonts: state.customFonts
        });
      },
      getOptionArray: (state: any, props: any) => {
        const fontFamily = resolvePaletteFont(
          state,
          getStyleObjectValue(state, props, 'customSettings.text.fontFamily')
        );
        return getFontFamilyWeights(fontFamily, state.customFonts);
      }
    }
  },
  'customSettings.background.image.url': {
    fieldName: 'customSettings.background.image.url',
    component: 'ImagePreviewField',
    options: {
      label: '_fieldLabel_backgroundImage__resx'
    }
  },
  'customSettings.background.image.scale': {
    fieldName: 'customSettings.background.image.scale',
    component: 'ToggleField',
    hideField: hideBackgroundImageSetting,
    options: {
      name: 'NucleusSiteEditor_EditorPanel_BackgroundImageScale__resx'
    }
  },
  'customSettings.background.image.repeat.x': {
    fieldName: 'customSettings.background.image.repeat.x',
    component: 'ToggleField',
    hideField: hideBackgroundImageSetting,
    options: {
      name: 'NucleusSiteEditor_EditorPanel_BackgroundImageRepeatX__resx'
    }
  },
  'customSettings.background.image.repeat.y': {
    fieldName: 'customSettings.background.image.repeat.y',
    component: 'ToggleField',
    hideField: hideBackgroundImageSetting,
    options: {
      name: 'NucleusSiteEditor_EditorPanel_BackgroundImageRepeatY__resx'
    }
  },
  'customSettings.background.image.position.xOffset': {
    fieldName: 'customSettings.background.image.position.xOffset',
    component: 'SelectField',
    hideField: hideBackgroundImageSetting,
    options: {
      label: 'NucleusSiteEditor_EditorPanel_BackgroundImageHorizontalAlign__resx',
      optionArray: [
        {
          name: 'NucleusSiteEditor_EditorPanel_BackgroundImageAlignOptions_Left__resx',
          value: 'left'
        },
        {
          name: 'NucleusSiteEditor_EditorPanel_BackgroundImageAlignOptions_Center__resx',
          value: 'center'
        },
        {
          name: 'NucleusSiteEditor_EditorPanel_BackgroundImageAlignOptions_Right__resx',
          value: 'right'
        }
      ]
    }
  },
  'customSettings.background.image.position.yOffset': {
    fieldName: 'customSettings.background.image.position.yOffset',
    component: 'SelectField',
    hideField: hideBackgroundImageSetting,
    options: {
      label: 'NucleusSiteEditor_EditorPanel_BackgroundImageVerticalAlign__resx',
      optionArray: [
        {
          name: 'NucleusSiteEditor_EditorPanel_BackgroundImageAlignOptions_Top__resx',
          value: 'top'
        },
        {
          name: 'NucleusSiteEditor_EditorPanel_BackgroundImageAlignOptions_Center__resx',
          value: 'center'
        },
        {
          name: 'NucleusSiteEditor_EditorPanel_BackgroundImageAlignOptions_Bottom__resx',
          value: 'bottom'
        }
      ]
    }
  },
  'customSettings.background.color': backgroundColor,
  'customSettings.hover.background.color': {
    ...backgroundColor,
    fieldName: 'customSettings.hover.background.color'
  },
  'customSettings.background.colorAlpha': backgroundColorAlpha,
  'customSettings.hover.background.colorAlpha': {
    ...backgroundColorAlpha,
    fieldName: 'customSettings.hover.background.colorAlpha'
  },
  'customSettings.background.borderRadius': {
    fieldName: 'customSettings.background.borderRadius',
    component: 'SliderField',
    options: {
      label: '_fieldLabel_borderRadius__resx',
      min: 0,
      max: 100
    }
  },
  'customSettings.border.color': borderColor,
  'customSettings.hover.border.color': {
    ...borderColor,
    fieldName: 'customSettings.hover.border.color'
  },
  'customSettings.border.thickness': {
    fieldName: 'customSettings.border.thickness',
    component: 'SliderField',
    options: {
      label: '_fieldLabel_borderThickness__resx',
      min: 0,
      max: 100
    }
  },
  'customSettings.border.borderStyle': {
    fieldName: 'customSettings.border.borderStyle',
    component: 'SelectField',
    options: {
      label: '_fieldLabel_borderStyle__resx',
      optionArray: [
        {
          name: '_fieldOption_solid__resx',
          value: 'solid'
        },
        {
          name: '_fieldOption_dotted__resx',
          value: 'dotted'
        },
        {
          name: '_fieldOption_dashed__resx',
          value: 'dashed'
        }
      ]
    }
  },
  'customSettings.spacing.padding.paddingTop': {
    fieldName: 'customSettings.spacing.padding.paddingTop',
    component: 'SliderField',
    options: {
      label: '_fieldLabel_paddingTop__resx',
      min: 0,
      max: 100
    }
  },
  'customSettings.spacing.padding.paddingBottom': {
    fieldName: 'customSettings.spacing.padding.paddingBottom',
    component: 'SliderField',
    options: {
      label: '_fieldLabel_paddingBottom__resx',
      min: 0,
      max: 100
    }
  },
  'customSettings.spacing.padding.paddingLeft': {
    fieldName: 'customSettings.spacing.padding.paddingLeft',
    component: 'SliderField',
    options: {
      label: '_fieldLabel_paddingLeft__resx',
      min: 0,
      max: 100
    }
  },
  'customSettings.spacing.padding.paddingRight': {
    fieldName: 'customSettings.spacing.padding.paddingRight',
    component: 'SliderField',
    options: {
      label: '_fieldLabel_paddingRight__resx',
      min: 0,
      max: 100
    }
  }
};
