/**
 * There is inconsistent browser support for date/time formatting
 * for traditional and simplified chinese language code.
 * This function is to switch to their equivalents if the provided code isn't supported.
 * @param {string} locale
 */
function switchLocale(locale) {
  let newLocale;
  switch (locale.toLowerCase()) {
    case 'zh-cht':
      newLocale = 'zh-hant';
      break;
    case 'zh-chs':
      newLocale = 'zh-hans';
      break;
    case 'zh-hant':
      newLocale = 'zh-cht';
      break;
    case 'zh-hans':
      newLocale = 'zh-chs';
      break;
    default:
      newLocale = locale;
  }
  return newLocale;
}

export default switchLocale;
