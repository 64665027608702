import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';
import { Countdown } from 'nucleus-core';

/**
 * The CountdownTimer widget.
 */
export const CountdownTimer = (props: any) => {
  return (
    <div {...resolveTestId(props)} {...resolve(props, 'container')}>
      <Countdown {...props} />
    </div>
  );
};

CountdownTimer.displayName = 'CountdownTimer';
