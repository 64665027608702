import merge from 'lodash/merge';

import { getParentSection } from '../layout/getParentSection';
import { performStyleMappings } from './performStyleMappings';
import { resetStylesToDefault } from './resetStylesToDefault';

/**
 * Gets the style object to use when editing a widget. Merges all of
 * the necessary style objects together into a single object
 */
export function getWidgetStyleObject(state: any, widget: any, widgetConfig: any) {
  const globalTheme = state.website.theme.global;
  const section = getParentSection(state.website.layoutItems, widget);
  const themeSection = state.website.theme.sections[section.config.themeId];
  const mergedObject = merge(
    {},
    resetStylesToDefault(globalTheme),
    resetStylesToDefault(themeSection),
    resetStylesToDefault(section.config.style),
    widgetConfig.style
  );
  performStyleMappings(mergedObject, mergedObject);
  return mergedObject;
}
