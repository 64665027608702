import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import { CSSTransitionGroup as ReactCSSTransitionGroup } from 'react-transition-group';

import { getReactCSSTransitionGroupClasses } from '../utils/getReactCSSTransitionGroupClasses';
import { removeKeys } from '../utils/removeKeys';

/**
 * Removes any Transition specific properties from a props object that would be invalid
 * if applied to a base DOM node. Useful when composing multiple components to prevent
 * passing a component props that it doesn't use.
 */
export function removeTransitionProps(props: any) {
  return removeKeys(props, [
    'defaultName',
    'transitionEnterTimeout',
    'transitionLeaveTimeout',
    'transitionAppearTimeout',
    'transitionAppear',
    'classes'
  ]);
}

export type TransitionProps = React.PropsWithChildren<{
  defaultName?: string;
  transitionEnterTimeout?: number;
  transitionLeaveTimeout?: number;
  transitionAppearTimeout?: number;
  transitionAppear?: boolean;
  style?: {
    transitionContainer?: any;
    enter?: any;
    enterActive?: any;
    leave?: any;
    leaveActive?: any;
    appear?: any;
    appearActive?: any;
  };
}>;

/**
Transition - A wrapper component to add CSS Transition to the opening/closing of content.
**/
export class Transition extends React.Component<TransitionProps> {
  static displayName = 'Transition';
  static defaultProps = {
    defaultName: 'transition',
    transitionEnterTimeout: 200,
    transitionLeaveTimeout: 200,
    transitionAppearTimeout: 200,
    transitionAppear: true,
    style: {
      transitionContainer: {},
      enter: {},
      enterActive: {},
      leave: {},
      leaveActive: {},
      appear: {},
      appearActive: {}
    }
  };

  render() {
    const containerClass = resolve(this.props, 'transitionContainer');
    const transitionGroupClassName =
      containerClass && containerClass.className ? containerClass.className : '';
    return (
      <ReactCSSTransitionGroup
        className={transitionGroupClassName}
        transitionAppear={this.props.transitionAppear}
        transitionName={getReactCSSTransitionGroupClasses(this.props, this.props.defaultName)}
        transitionEnterTimeout={this.props.transitionEnterTimeout}
        transitionAppearTimeout={this.props.transitionAppearTimeout}
        transitionLeaveTimeout={this.props.transitionLeaveTimeout}
      >
        {this.props.children}
      </ReactCSSTransitionGroup>
    );
  }
}
