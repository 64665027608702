import merge from 'lodash/merge';

import { resetStylesToDefault } from './resetStylesToDefault';

/**
 * Gets the style object to use when editing a section. Merges all of
 * the necessary style objects together into a single object.
 */
export function getSectionStyleObject(state: any, section: any) {
  const globalTheme = state.website.theme.global;
  const cleanGlobalTheme = resetStylesToDefault(globalTheme);
  const themeSection = state.website.theme.sections[section.config.themeId];
  return merge({}, cleanGlobalTheme, themeSection, section.config.style);
}
