import omit from 'lodash/omit';

import { getJSONValue } from './getJSONValue';
import { setJSONValue } from './setJSONValue';

/**
 * Creates a copy of the provided root object with the value at the specified path removed.
 * @param {object} rootObject - The root object the field is contained in.
 * @param {string|array} path - The path to the desired value in the root object.
 *     Can either be a string with keys separated by periods, or an array of keys.
 * @returns {object} a copy of the rootObject with the specified field omitted
 **/
export function deleteJSONValue(rootObject = {}, path: any) {
  let keys;
  if (path instanceof Array) {
    keys = path;
  } else {
    keys = path.split('.');
  }
  const property = keys[keys.length - 1];
  const prefix = keys.slice(0, keys.length - 1);
  return setJSONValue(rootObject, prefix, omit(getJSONValue(rootObject, prefix), property));
}
