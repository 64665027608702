import { PAGE } from '../../../renderers/shared/pageTypes';

/**
 * Add template page support to pages.
 */
export function pageMigrations(pages: any) {
  const newPages = {};
  Object.keys(pages).forEach(id => {
    const newPage = { ...pages[id] };

    // Migrate from version undefined to version 1.
    // Introduction of template page support.
    if (!newPage.version) {
      // Increment version
      newPage.version = 1;
      // Change the rootLayoutItemId string to an array of rootLayoutItemIds.
      if (newPage.rootLayoutItemId) {
        newPage.rootLayoutItemIds = [newPage.rootLayoutItemId];
        delete newPage.rootLayoutItemId;
      }
      // Add the type property onto pages if it doesnt exist.
      if (!newPage.type) {
        newPage.type = PAGE;
      }
    }

    // Update the page in the object.
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    newPages[id] = newPage;
  });
  return newPages;
}
