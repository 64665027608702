/**
 * This overrid from react-big-calendar@v0.14.0.
 *
 * 1) References to "className" and "style" are removed from the functions,
 *    copied from EventRowMixin object in react-big-calendar@v0.14.0,
 *    and replaced by "resolve" and "select" to apply styles and pass styles down to child components.
 *
 * There are no functional changes.
 */

import React from 'react';

import { resolve, select } from '@cvent/nucleus-dynamic-css';
import CalendarEventRowMixin from 'react-big-calendar/lib/EventRowMixin';
import { segStyle } from 'react-big-calendar/lib/utils/eventLevels';
import { isSelected } from 'react-big-calendar/lib/utils/selection';
import { EventCell, eventCellStyleKeys } from './EventCell';

export const EventRowMixin = {
  ...CalendarEventRowMixin,
  renderEvent(props: any, event: any) {
    const {
      eventPropGetter,
      selected,
      start,
      end,
      startAccessor,
      endAccessor,
      titleAccessor,
      allDayAccessor,
      eventComponent,
      eventWrapperComponent,
      onSelect
    } = props;

    return (
      /* @ts-expect-error 'EventCell' cannot be used as a JSX component. */
      <EventCell
        event={event}
        eventWrapperComponent={eventWrapperComponent}
        eventPropGetter={eventPropGetter}
        onSelect={onSelect}
        selected={isSelected(event, selected)}
        startAccessor={startAccessor}
        endAccessor={endAccessor}
        titleAccessor={titleAccessor}
        allDayAccessor={allDayAccessor}
        slotStart={start}
        slotEnd={end}
        eventComponent={eventComponent}
        {...select(props, ...eventCellStyleKeys)}
      />
    );
  },
  renderSpan(props: any, len: any, key: any, content = ' ') {
    const { slots } = props;
    const resolvedStyles = resolve(props, 'rowSegment');
    return (
      <div
        key={key}
        {...resolve(props, 'rowSegment')}
        className={resolvedStyles.className}
        style={{
          ...segStyle(Math.abs(len), slots),
          ...resolvedStyles.style
        }}
      >
        {content}
      </div>
    );
  }
};

export const eventRowMixinStyleKeys = ['rowSegment', ...eventCellStyleKeys];
