import React from 'react';
import { BaseWidget } from '../BaseWidget';
import { ThemeableComponentProps } from '../ThemeableComponent';
import { Code, CodeProps } from './Code';
import Classes from './CodeWidget.less';

type DefaultProps = {
  metaData: { disableInput: boolean };
  classes: { container: string; overlay: string };
};
export type CodeWidgetProps = ThemeableComponentProps &
  Pick<DefaultProps, 'metaData'> &
  Omit<CodeProps, 'disableInput'>;

export class CodeWidget extends BaseWidget<CodeWidgetProps> {
  static defaultProps: DefaultProps = {
    classes: Classes,
    metaData: {
      disableInput: true
    }
  };
  render(): JSX.Element {
    return <Code disableInput={this.props.metaData.disableInput} {...this.props} />;
  }
}
