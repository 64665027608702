/**
 * Migrations related to filters.
 * Add filters related styling elements to existing calendars
 *
 * @author s.sachdeva
 */
export default function filtersMigrations(widget: $TSFixMe) {
  if (widget.config.style.filters) {
    return widget;
  }

  const newStyle = {};
  if (!widget.config.style.filters) {
    // @ts-expect-error TS(2339): Property 'filters' does not exist on type '{}'.
    newStyle.filters = {
      filtersHeader: {
        styleMapping: 'text1'
      },
      filtersCategory: {
        styleMapping: 'text2'
      },
      filtersLabel: {
        styleMapping: 'label'
      },
      filtersLink: {
        styleMapping: 'link'
      },
      filtersCategorySeparator: {
        styleMapping: 'custom',
        border: {
          color: 'primary',
          borderStyle: 'solid',
          thickness: 1
        }
      }
    };

    return {
      ...widget,
      config: {
        ...widget.config,
        style: {
          ...widget.config.style,
          ...newStyle
        }
      }
    };
  }
}
