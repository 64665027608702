import { CONTAINER } from './LayoutTypes';

/**
 * Finds the root layout item of the given layout item..
 */
// @ts-expect-error ts-migrate(7023) FIXME: 'getRootLayoutItem' implicitly has return type 'an... Remove this comment to see the full error message
export function getRootLayoutItem(widgetsState: any, item: any) {
  const parent = widgetsState[item.layout.parentId];
  if (parent.layout.type === CONTAINER) {
    return parent;
  }
  return getRootLayoutItem(widgetsState, parent);
}
