/* eslint react/prop-types: [2, { ignore: ["setFocus", "onChange", "fieldName", "id", "name"] }] */
import React from 'react';

import { ColorFormBase } from '../../colors/ColorFormBase';
import { HexColorInput } from '../../colors/HexColorInput';
import { PaletteFlyout } from '../../colors/PaletteFlyout';
import { Trigger } from '../../containers/Trigger';
import { tapOrClick } from '../../touchEventHandlers';
import { FormElement } from '../FormElement';

/**
Custom Form Element wrapper around the ColorPicker component.
Allows us to pass in a theme palette of colors to choose from in
addition to the normal color picker.
**/
class ColorPicker extends ColorFormBase {
  static displayName = 'ColorPicker';
  onClick(e: any) {
    super.onClick(e);
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'focusTextbox' does not exist on type 'Re... Remove this comment to see the full error message
    if (this.refs.hexinput && this.refs.hexinput.focusTextbox) {
      // @ts-expect-error ts-migrate(2339) FIXME: Property 'focusTextbox' does not exist on type 'Re... Remove this comment to see the full error message
      this.refs.hexinput.focusTextbox();
    }
  }
  render() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'fieldName' does not exist on type 'Reado... Remove this comment to see the full error message
    const { fieldName, value, paletteId, palette, classes, style, triggerItem, children } =
      this.props;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'hsl' does not exist on type 'Readonly<{}... Remove this comment to see the full error message
    const { hsl } = this.state;
    let paletteColor;
    if (paletteId !== undefined && palette) {
      paletteColor = palette.find((color: any) => color.id === paletteId);
    }
    const hexInput = (
      <HexColorInput
        ref="hexinput"
        fieldName={fieldName}
        hsl={hsl}
        isBlank={paletteColor ? paletteColor.value === null : value === null}
        onClick={this.onClick}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onColorChange={this.onChange}
        classes={classes}
        style={style}
      />
    );
    const hexBox = (
      <div
        className={classes.triggerColor}
        {...tapOrClick(this.onClick)}
        style={{ background: this.getValue(hsl, 'hex') }}
      />
    );

    return (
      <div className={classes.wrapper} ref="colorFlyout">
        {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ children: Element[]; ref: string; classes:... Remove this comment to see the full error message */}
        <Trigger {...this.props} ref="trigger" classes={this.props.classes}>
          {triggerItem === 'box' ? hexBox : hexInput}
          <div className={classes.colorWrapper}>
            <PaletteFlyout {...this.props} />
          </div>
        </Trigger>
        {children}
      </div>
    );
  }
}

type Props = {
  children?: React.ReactNode;
};

/**
FormElement wrapper around ColorPicker. This is the default export.
**/
const ColorPickerFormElement = (props: Props) => {
  const { children, ...rest } = props;
  return (
    <FormElement {...rest}>
      <ColorPicker {...rest} />
      {children}
    </FormElement>
  );
};
ColorPickerFormElement.displayName = 'ColorPickerFormElement';

export { ColorPickerFormElement };
