import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import BlockAlertCss from './BlockAlert.less';

type Props = {
  spot: 'lock' | 'search' | 'error';
  message?: string;
  children?: React.ReactNode;
};

/**
 * This component renders alert messages with a spot illustration on top of the actual message.
 */
export class BlockAlert extends React.PureComponent<Props> {
  static displayName = 'BlockAlert';
  render() {
    const { children, message, spot } = this.props;
    const PanelStyle = { classes: { ...BlockAlertCss } };

    return (
      <div {...resolve(PanelStyle, 'blockAlert')}>
        <div {...resolve(PanelStyle, 'blockAlertSpotIllustration', spot)} />
        <p>{message}</p>
        {children}
      </div>
    );
  }
}
