// A pass through to prevent file links from breaking.
import {
  SET_LOCALE,
  setLocale,
  registerTranslation,
  registerDatatags,
  setCurrencyCode,
  refreshResolvers,
  fetchDatatagResolutions,
  fetchAllDatatagResolutions,
  createTextReducer,
  textReducer
} from 'nucleus-widgets';

export {
  SET_LOCALE,
  setLocale,
  registerTranslation,
  registerDatatags,
  setCurrencyCode,
  refreshResolvers,
  fetchDatatagResolutions,
  fetchAllDatatagResolutions,
  createTextReducer,
  textReducer
};
