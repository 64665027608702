import React, { Component } from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { instance } from '@cvent/nucleus-iframe-messaging';
import { getContentImages } from '@cvent/nucleus-themes/getImages';
import ProcessingOverlay from 'cvent-event-calendar/lib/CalendarView/ProcessingOverlay';
import LoadMoreSpinnerStyles from 'cvent-event-calendar/lib/CalendarView/styles/LoadMoreSpinner.less';
import { AppContainer } from 'nucleus-guestside-site';
import { ThemeImagesContext } from 'nucleus-widgets';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import EventDetailsModal from './EventDetailsModal';
import PageRenderer from './PageRenderer';
import AppStyles from './styles/App.less';
import { hideFooter, registerIframeInstance } from '../redux/modules/embeddedSettings';
import { getDefaultWebsitePageId } from '../redux/modules/reducer';

type AppProps = {
  assetRoot: string;
  pageId: string;
  pages: any;
  theme: any;
  dispatch?: Function;
  hideFooter: Function;
  registerIframeInstance: Function;
  translate: (res: string, tokens?: Object) => string;
  hasPropsLoaded: boolean;
  displaySpinner: boolean;
};

type AppState = {
  themeImages: any;
};

const IFRAME_LOAD_WAIT_TIME = 500;

class App extends Component<AppProps, AppState> {
  static displayName = 'App';
  static propTypes = {
    assetRoot: PropTypes.string,
    pageId: PropTypes.string,
    pages: PropTypes.any,
    theme: PropTypes.any,
    dispatch: PropTypes.func,
    hideFooter: PropTypes.func,
    registerIframeInstance: PropTypes.func,
    translate: PropTypes.func,
    hasPropsLoaded: PropTypes.bool,
    displaySpinner: PropTypes.bool
  };
  static childContextTypes = {
    themeImages: PropTypes.any
  };
  getChildContext() {
    const { assetRoot } = this.props;
    return {
      themeImages: {
        ...getContentImages(assetRoot)
      }
    };
  }
  constructor(props: AppProps) {
    super(props);

    // in case calendar is displayed in an iframe we want to connect to the parent window
    if (window !== window.top) {
      const iframeInstance = instance(this.handler);
      props.registerIframeInstance(iframeInstance);
      const observer = new MutationObserver(() => {
        if (this.props.hasPropsLoaded) {
          iframeInstance.then((parent: $TSFixMe) => {
            setTimeout(() => {
              parent.action({ action: 'height', data: document.body.scrollHeight });
            }, IFRAME_LOAD_WAIT_TIME);
          });
        }
      });
      observer.observe(document.body, { attributes: true, subtree: true, characterData: true, childList: true });
    }
    this.state = {
      themeImages: {
        ...getContentImages(props.assetRoot)
      }
    };
  }

  handler = (actionPayload: $TSFixMe) => {
    switch (actionPayload.action) {
      case 'hideFooter':
        return this.props.hideFooter();
      case 'width':
        // @ts-expect-error TS(2339): Property 'width' does not exist on type 'HTMLEleme... Remove this comment to see the full error message
        return (document.body.width = `${actionPayload.value}px`);
      default:
        return;
    }
  };

  render() {
    const { pageId, pages, theme, translate, hasPropsLoaded, displaySpinner } = this.props;
    // @ts-ignore
    return (
      <ThemeImagesContext.Provider value={this.state.themeImages}>
        <div id="temporary-app-wrapper" className={AppStyles.wrapper}>
          {!hasPropsLoaded && displaySpinner && (
            <div {...resolve({ classes: { spinner: AppStyles.spinner } }, 'spinner')}>
              <ProcessingOverlay translate={translate} classes={LoadMoreSpinnerStyles} />
            </div>
          )}
          {hasPropsLoaded && (
            <AppContainer theme={theme}>
              <PageRenderer page={pages[pageId]} />
            </AppContainer>
          )}
          {hasPropsLoaded && <EventDetailsModal />}
        </div>
      </ThemeImagesContext.Provider>
    );
  }
}

export default connect(
  (state: $TSFixMe) => {
    const hasPropsLoaded = state.calendar && state.calendar.hasPropsLoaded;
    return {
      pages: state.website.pages,
      pageId: (hasPropsLoaded && getDefaultWebsitePageId(state)) || '',
      theme: state.website.theme,
      translate: state.text.translate,
      hasPropsLoaded,
      displaySpinner: state.calendar && state.calendar.displaySpinner
    };
  },
  { hideFooter, registerIframeInstance }
)(App);
