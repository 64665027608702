import BackgroundStyleFields from './background';
import BorderStyleFields from './border';
import CoreStyleFields from './core';
import CustomCSSStyleFields from './customCSS';
import HoverStyleFields from './hover';
import SpacingStyleFields from './spacing';
import StyleStyleFields from './style';
import TextStyleFields from './text';

// StyleFields were all previously in one file. Some functions were individually exported, some weren't.
// We're exporting them all here for backwards-compatibility just to be safe.
export * from './background';
export * from './border';
export * from './core';
export * from './hover';
export * from './spacing';
export * from './text';

export const StyleFields = {
  ...BackgroundStyleFields,
  ...BorderStyleFields,
  ...CoreStyleFields,
  ...CustomCSSStyleFields,
  ...HoverStyleFields,
  ...SpacingStyleFields,
  ...StyleStyleFields,
  ...TextStyleFields
};
