import mapValues from 'lodash/mapValues';
import values from 'lodash/values';

/**
 * Add global font "palette" to the global theme object.
 * Remove useless version property from theme object.
 */
export function themeMigrations(theme: any) {
  const updatedTheme = { ...theme };

  // Ensure the global theme doesnt already have a fontPalette object set up.
  if (!updatedTheme.global.hasOwnProperty('fontPalette')) {
    // Determine the primary and secondary fonts in the existing global theme by counting the usage numbers.
    const fontFamilyCounts = {};
    let primaryCount = 0;
    let secondaryCount = 0;
    let primaryFontFamily: any;
    let secondaryFontFamily;
    values(updatedTheme.global.elements).forEach(element => {
      const fontFamily = element.text.fontFamily;
      if (!fontFamilyCounts.hasOwnProperty(fontFamily)) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        fontFamilyCounts[fontFamily] = 1;
      } else {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        fontFamilyCounts[fontFamily] += 1;
      }
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (fontFamilyCounts[fontFamily] > primaryCount) {
        if (primaryFontFamily !== fontFamily) {
          secondaryCount = primaryCount;
          secondaryFontFamily = primaryFontFamily;
          primaryFontFamily = fontFamily;
        }
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        primaryCount = fontFamilyCounts[fontFamily];
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      } else if (fontFamilyCounts[fontFamily] > secondaryCount) {
        // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        secondaryCount = fontFamilyCounts[fontFamily];
        secondaryFontFamily = fontFamily;
      }
    });

    const fontPalette = {
      primary: primaryFontFamily,
      secondary: secondaryFontFamily || primaryFontFamily
    };

    // Modify the font families in the global theme elements as necessary.
    const elements = mapValues(updatedTheme.global.elements, element => {
      let fontFamily = element.text.fontFamily;
      if (fontFamily === fontPalette.primary) {
        fontFamily = 'primary';
      } else if (fontFamily === fontPalette.secondary) {
        fontFamily = 'secondary';
      }
      return {
        ...element,
        text: {
          ...element.text,
          fontFamily
        }
      };
    });

    // Apply new fonts and elements info to the global theme.
    updatedTheme.global = {
      ...updatedTheme.global,
      fontPalette,
      elements
    };
  }

  // The version property is useless, so remove it.
  if (updatedTheme.hasOwnProperty('version')) {
    delete updatedTheme.version;
  }

  return updatedTheme;
}
