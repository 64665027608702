/**
 * This component extends the EventCell component from react-big-calendar@v0.14.0.
 *
 * 1) References to "className" and "style" are removed from the functions copied from the base component,
 *    and replaced by "resolve" and "select" to apply styles and pass styles down to child components.
 * 2) Split 'let' declarations into multiple statements and replace "var" with "let",
 *    to be compliant with our coding standards.
 * 3) Use InteractiveElement in place of the div with onClick event handler on it,
 *    and add aria-label to it for screen readers.
 * 4) Update the logics for isAllDay, continuesPrior and continuesAfter,
 *    to make sure events get correct the right styles.
 *
 * There are no functional changes.
 */

import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import CalendarEventCell from 'react-big-calendar/lib/EventCell';
import { accessor as get } from 'react-big-calendar/lib/utils/accessors';
import dates from 'react-big-calendar/lib/utils/dates';

import { InteractiveElement } from '../containers/InteractiveElement';

export class EventCell extends CalendarEventCell {
  static displayName = 'EventCell';
  static propTypes = { ...CalendarEventCell.propTypes };
  static defaultProps = { ...CalendarEventCell.defaultProps };
  props: any;
  render() {
    const {
      event,
      selected,
      eventPropGetter,
      startAccessor,
      endAccessor,
      titleAccessor,
      slotStart,
      slotEnd,
      onSelect,
      eventComponent: Event,
      eventWrapperComponent: EventWrapper,
      ...props
    } = this.props;

    const title = get(event, titleAccessor);
    const end = get(event, endAccessor);
    const start = get(event, startAccessor);

    const isAllDay =
      get(event, props.allDayAccessor) || dates.gt(end, start, 'day') ? 'allDayEvent' : undefined;
    const continuesPrior = isAllDay && dates.lt(start, slotStart, 'day');
    const continuesAfter = isAllDay && dates.gt(end, slotEnd, 'second');

    // NOTE: The library uses var key word inside if, which violates our coding standards.
    // Fix it by using let instead.
    let style;
    let xClassName;
    if (eventPropGetter) {
      const customStyles = eventPropGetter(event, start, end, selected);
      style = customStyles.style;
      xClassName = customStyles.className;
    }
    const resolvedStyles = resolve(
      this.props,
      'event',
      xClassName,
      selected ? 'selectedEvent' : undefined,
      isAllDay,
      continuesPrior ? 'eventContinuesPrior' : undefined,
      continuesAfter ? 'eventContinuesAfter' : undefined
    );

    return (
      <EventWrapper event={event}>
        <InteractiveElement
          style={{ ...props.style, ...resolvedStyles.style, ...style }}
          className={resolvedStyles.className}
          onClick={e => onSelect(event, e)}
          aria-label={event.ariaLabel ? event.ariaLabel : null}
        >
          <div {...resolve(this.props, 'eventContent')} title={title}>
            {Event ? <Event event={event} title={title} /> : title}
          </div>
        </InteractiveElement>
      </EventWrapper>
    );
  }
}

export const eventCellStyleKeys = [
  'event',
  'selectedEvent',
  'allDayEvent',
  'eventContinuesPrior',
  'eventContinuesAfter',
  'eventContent'
];
