import uniqueId from 'lodash/uniqueId';
import { getAllWidgetsOnPage } from '../../../utils';
import { STORE_PAGE_LEVEL_COMMENT_COUNTS, STORE_PAGE_LEVEL_COMMENT_COUNTS_ERROR } from './actions';
import {
  COMMENT_ERRORS,
  getCommentError,
  removeCommentError,
  setCommentErrorLoading
} from './errors';

function storePageLevelCommentCounts(pageCount: Record<string, number>) {
  return { type: STORE_PAGE_LEVEL_COMMENT_COUNTS, payload: { pageCount } };
}

function setPageLevelCommentCountError(selectedPageId?: string) {
  const type = COMMENT_ERRORS.GetPageLevelCommentCount;
  const data = { selectedPageId };

  return {
    type: STORE_PAGE_LEVEL_COMMENT_COUNTS_ERROR,
    payload: { id: uniqueId(), retrying: false, isAlert: true, type, data }
  };
}

export function getPageLevelCommentCount(selectedPageId?: string) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const client = state.clients.commentsClient;
    try {
      const pages = state.website.pages;
      const pageIds = Object.keys(pages);
      if (selectedPageId) {
        const widgetIds = getAllWidgetsOnPage(state.website, selectedPageId).map(
          (widget: any) => widget.id
        );
        // For async load page with 0 widget, do nothing.
        if (widgetIds.length !== 0) {
          const selectedPageCount = await client.getAggregateCount(widgetIds);
          dispatch(storePageLevelCommentCounts({ [selectedPageId]: selectedPageCount }));
        }
      } else {
        // Create an array of promises
        const pageCountPromises = pageIds.map(async (pageId: string) => {
          const widgetIds = getAllWidgetsOnPage(state.website, pageId).map(
            (widget: any) => widget.id
          );
          // For async load pages/page with 0 widget, immediately resolve with 0
          if (widgetIds.length === 0) {
            return { pageId, count: 0 };
          }
          const singlePageCount = await client.getAggregateCount(widgetIds);
          return { pageId, count: singlePageCount };
        });

        const resolvedPageCounts = await Promise.all(pageCountPromises);

        const pageCount: Record<string, number> = {};
        resolvedPageCounts.forEach(({ pageId, count }) => {
          pageCount[pageId] = count;
        });

        dispatch(storePageLevelCommentCounts(pageCount));
      }
    } catch (_error) {
      dispatch(setPageLevelCommentCountError(selectedPageId));
    }
  };
}

export function retryGetPageLevelCommentCount(selectedPageId?: string) {
  return async (dispatch: any, getState: any) => {
    const state = getState();
    const comments = state.comments;
    const error = getCommentError(comments.errors, COMMENT_ERRORS.GetPageLevelCommentCount, {
      selectedPageId
    });
    if (error) {
      dispatch(removeCommentError(error.id));
    }
    dispatch(setCommentErrorLoading(error.id, true));

    const success = await dispatch(getPageLevelCommentCount(selectedPageId));

    if (success) {
      dispatch(removeCommentError(error.id));
    } else {
      dispatch(setCommentErrorLoading(error.id, false));
    }
  };
}
