import { VideoTypes, VimeoEventUrlFormat } from '../../constants/Video';

export function getWistiaVideoIdFromUrl(videoUrl: string): string | undefined {
  const wistiaUrlFormats = [
    'wistia.com/medias/',
    'wistia.com/embed/',
    'wi.st/medias/',
    'wi.st/embed/',
    'fast.wistia.net/embed/iframe/'
  ];

  for (const urlFormat of wistiaUrlFormats) {
    if (videoUrl.includes(urlFormat)) {
      const videoId = videoUrl.split(urlFormat)[1];
      // Valid video ID will be alphanumeric, only return ID if it is alphanumeric
      if (/^[a-z0-9]+$/i.test(videoId)) {
        return videoId;
      }

      return undefined;
    }
  }

  return undefined;
}

export function getYoutubeVideoIdFromUrl(videoUrl: string) {
  const match = videoUrl.match(
    /(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/
  );

  if (!match || match.length < 2) {
    return undefined;
  }

  return match[1];
}

export function getVimeoVideoIdFromUrl(videoUrl: string) {
  const match = videoUrl.match(/(?:https?:\/{2})?(?:w{3}\.)?vimeo.com\/([0-9a-z\-_]+)/);

  if (!match || match.length < 2) {
    return undefined;
  }

  return match[1];
}

export function getVimeoEventIdFromUrl(videoUrl: string) {
  const match = videoUrl.match(/(?:https?:\/{2})?(?:w{3}\.)?vimeo.com\/event\/([0-9a-z\-_]+)/);

  if (!match || match.length < 2) {
    return undefined;
  }

  return match[1];
}

export function getFacebookVideoPathFromUrl(videoUrl: string) {
  // Example: https://www.facebook.com/foooo/videos/0000000000000/
  const match1 = videoUrl.match(/(?:https?:\/{2})?(?:w{3}\.)?(facebook|fb).com\/(.*\/videos\/.*)+/);
  // Example: https://www.facebook.com/watch/?v=000000000000000
  const match2 = videoUrl.match(/(?:https?:\/{2})?(?:w{3}\.)?(facebook).com\/(watch\/\?v=.*)+/);
  // Example: https://fb.watch/aB0cd1eFGH/
  const match3 = videoUrl.match(/(?:https?:\/{2})?(fb.)(watch\/[a-zA-Z0-9]*)+/);

  const match = match1 || match2 || match3;
  if (!match || match.length < 3) {
    return undefined;
  }

  return match[2];
}

export function getTwitterVideoPathFromUrl(videoUrl: string) {
  const match = videoUrl.match(/(?:https?:\/{2})?(?:w{3}\.)?twitter.com\/(.*\/status\/.*)+/);

  if (!match || match.length < 2) {
    return undefined;
  }

  return match[1];
}

export function getLiveStreamVideoPathFromURL(videoUrl: string) {
  const match = videoUrl.match(/(?:https?:\/{2})?(?:w{3}\.)?livestream.com\/(.*\/events\/.*)+/);

  if (!match || match.length < 2) {
    return undefined;
  }
  return match[1];
}

export function getVideoIdFromUrl(videoType: VideoTypes, videoUrl?: string) {
  let res: string | undefined;

  if (!videoUrl) {
    return res;
  }

  switch (videoType) {
    case VideoTypes.WISTIA:
      res = getWistiaVideoIdFromUrl(videoUrl);
      break;

    case VideoTypes.YOUTUBE:
      res = getYoutubeVideoIdFromUrl(videoUrl);
      break;

    case VideoTypes.VIMEO:
      if (videoUrl.indexOf(VimeoEventUrlFormat) > -1) {
        res = getVimeoEventIdFromUrl(videoUrl);
      } else {
        res = getVimeoVideoIdFromUrl(videoUrl);
      }
      break;

    case VideoTypes.FACEBOOK:
      res = getFacebookVideoPathFromUrl(videoUrl);
      break;

    case VideoTypes.TWITTER:
      res = getTwitterVideoPathFromUrl(videoUrl);
      break;

    case VideoTypes.LIVESTREAM:
      res = getLiveStreamVideoPathFromURL(videoUrl);
      break;

    default:
      res = undefined;
  }

  return res;
}
