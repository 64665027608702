import React from 'react';

import { FlexRow as GridRow } from 'nucleus-core';

type Props = {
  children?: React.ReactNode;
};

/**
 * Default renderer for a ROW type layout item.
 */
export class Row extends React.Component<Props> {
  static displayName = 'Row';
  render() {
    const { children } = this.props;
    return <GridRow>{children}</GridRow>;
  }
}
