import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { removeKeys } from '../../utils/removeKeys';
import GridStyles from './styles/Grid.less';

type Props = React.PropsWithChildren<{
  style?: any;
  classes?: any;
  breakPoint?: 'large' | 'medium' | 'small';
  ariaRole?: string;
}>;

export const GridContext = React.createContext<Pick<Props, 'style' | 'classes' | 'breakPoint'>>({});

/**
 * Top level component of the flexbox grid layout.
 * LESS Style object set as default classes context
 */
export function FlexGrid(props: Props) {
  const { children, ariaRole, style, classes = GridStyles, breakPoint } = props;

  const childContext = {
    classes,
    style,
    breakPoint
  };

  return (
    <GridContext.Provider value={childContext}>
      <div
        {...removeKeys(props, ['classes', 'style', 'breakPoint', 'children', 'ariaRole'])}
        {...resolve({ ...props, classes }, 'grid')}
        {...(ariaRole !== undefined && { role: ariaRole })}
      >
        {children}
      </div>
    </GridContext.Provider>
  );
}

FlexGrid.displayName = 'Grid';
