export const Headers = {
  stylingOptionsHeader: {
    component: 'Header',
    options: {
      title: 'NucleusSiteEditor_WidgetConfig_StylingOptionsHeaderLabel__resx'
    }
  },
  configurationOptionsHeader: {
    component: 'Header',
    options: {
      title: 'NucleusSiteEditor_WidgetConfig_ConfigurationOptionsHeaderLabel__resx'
    }
  }
};
