import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

type Props = {
  text?: string;
  // Used in resolve function // TODO: determine neccesary fields for classes
  classes?: Record<string, unknown>;
  // Used in resolve function
  style?: Record<string, unknown>;
};

/**
 * The text widget.
 */
export class Text extends React.Component<Props> {
  static displayName = 'Text';
  render() {
    const { text } = this.props;

    return (
      <div {...resolveTestId(this.props)} {...resolve(this.props, 'container')}>
        <div {...resolve(this.props, 'text')} dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    );
  }
}
