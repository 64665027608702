import { PseudoStateStyleObject, PseudoStateStyleCalculator } from './types';
import merge from 'lodash/merge';
import { getBestContrastColor } from './getBestContrastColor';
import { convertThemePaletteColor } from '../fields/convertThemePaletteColor';

function getBackgroundColor(
  baseStyle: Partial<PseudoStateStyleObject>,
  themePalette: Record<string, string>
): string | undefined {
  const backgroundColor = baseStyle.border?.color ?? baseStyle.text?.color;
  return convertThemePaletteColor(themePalette, backgroundColor);
}

function getTextColor(
  baseStyle: Partial<PseudoStateStyleObject>,
  pseudoStateBackgroundColor: string | undefined,
  themePalette: Record<string, string>
): string | undefined {
  return getBestContrastColor(
    themePalette,
    convertThemePaletteColor(themePalette, pseudoStateBackgroundColor),
    convertThemePaletteColor(themePalette, baseStyle.text?.color)
  );
}

/**
 * Fills in the background color with the text color.
 * @see PseudoStateStyleMode#INVERTED
 */
export const InvertedStyleModeCalculator: PseudoStateStyleCalculator = {
  updateStyle(
    baseStyle: Partial<PseudoStateStyleObject>,
    pseudoStateStyle: Partial<PseudoStateStyleObject>,
    themePalette: Record<string, string>
  ): Partial<PseudoStateStyleObject> {
    const backgroundColor = getBackgroundColor(baseStyle, themePalette);
    const textColor = getTextColor(baseStyle, backgroundColor, themePalette);

    return merge({}, pseudoStateStyle, {
      background: { color: backgroundColor },
      text: { color: textColor }
    });
  }
};
