// The value for each type will be used directly in css,
// so they should match the naming convention of CSS pseudo class selectors, i.e. using all lower case.
export const PseudoStateTypes = {
  HOVER: 'hover'
};

// Returns tab field config data for pseudo state tabs.
export const getPseudoStateTabs = ({ defaultTabFields, hoverTabFields }: any) => {
  const tabs = [];
  if (defaultTabFields) {
    tabs.push({
      tab: 'NucleusSiteEditor_EditorPanel_PseudoStateTabs_DefaultTab_Name',
      key: null,
      type: null,
      fields: [...defaultTabFields]
    });
  }

  if (hoverTabFields) {
    tabs.push({
      tab: 'NucleusSiteEditor_EditorPanel_PseudoStateTabs_HoverTab_Name',
      key: null,
      type: PseudoStateTypes.HOVER,
      fields: [...hoverTabFields]
    });
  }

  return {
    // tabGroupId, `pseudoStateTabs`, is used by the editor to update hover state values
    // and switch widgets b/w default and hover states on the canvas.
    tabGroupId: 'pseudoStateTabs',
    tabs
  };
};
