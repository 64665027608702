/**
 * Function to recursively parse through a style object and copy any
 * parent element settings into any nested objects that contain a
 * style mapping.
 */
export function performStyleMappings(currentObject: any, styleObject: any) {
  if (!currentObject || !(currentObject instanceof Object)) {
    return;
  }
  Object.keys(currentObject).forEach(key => {
    if (!currentObject[key]) {
      return;
    }
    if (!currentObject[key].styleMapping) {
      performStyleMappings(currentObject[key], styleObject);
      return;
    }
    if (currentObject[key].styleMapping && currentObject[key].styleMapping !== 'custom') {
      // eslint-disable-next-line no-param-reassign
      currentObject[key] = {
        ...styleObject.elements[currentObject[key].styleMapping],
        styleMapping: currentObject[key].styleMapping,
        customSettings: currentObject[key].customSettings
      };
    }
  });
}
