import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';
import { defaultMemoize } from 'reselect';

import { getImageFromLookup } from '../../utils/getImageFromLookup';
import { BaseWidget } from '../BaseWidget';
import { ImageCarousel } from './ImageCarousel';
import ClassNames from './ImageCarousel.less';
import { ThemeableComponentProps } from '../ThemeableComponent';

const useCacheableImages = defaultMemoize((imageLookup: any, images: any) => {
  return images.map((image: any) => {
    const { hashedURL: url, width } = getImageFromLookup(imageLookup, image.asset.assetUri);
    return {
      ...image,
      asset: {
        ...image.asset,
        assetUri: url,
        width,
        ...(image.asset.thumbnailImageUri
          ? {
              thumbnailImageUri: getImageFromLookup(imageLookup, image.asset.thumbnailImageUri)
                .hashedURL
            }
          : {})
      }
    };
  });
});

export interface ImageCarouselWidgetProps extends ThemeableComponentProps {
  classes?: Record<string, unknown>;
  breakpoints?: Array<number>;
  config: {
    [K: string | number | symbol]: unknown;
    slideshowConfig: {
      autoplay: boolean;
      transitionTime: number;
      showForwardAndBackArrows: boolean;
      showNavigationDots: boolean;
      showThumbnails: boolean;
    };
  };
  activeImageIndexId?: string;
  // Added this in order to disable autoplay on the website canvas.
  // Carousel only needs to autoplay on guestside.
  dontAutoPlay?: boolean;
  displayPlaceholderText?: boolean;
  supportLossy?: boolean;
}

/**
 * Data wrapper for the Image carousel widget.
 */
export class ImageCarouselWidget extends BaseWidget<ImageCarouselWidgetProps> {
  static displayName = 'ImageCarouselWidget';
  static defaultProps = {
    classes: ClassNames,
    breakpoints: [320, 480, 768, 992],
    displayPlaceholderText: false
  };
  getStyleObject() {
    return {
      ...super.getStyleObject(),
      descriptionBottom: this.getElementInlineStyle('description')
    };
  }

  render() {
    const {
      classes,
      translate,
      config,
      activeImageIndexId,
      dontAutoPlay,
      style,
      imageLookup,
      breakpoints,
      displayPlaceholderText,
      browserFeatures: { supportsWebp } = {},
      supportLossy
    } = this.props;
    const {
      autoplay,
      transitionTime,
      showForwardAndBackArrows,
      showNavigationDots,
      showThumbnails
    } = config.slideshowConfig;
    return (
      <ImageCarousel
        {...resolveTestId(this.props)}
        classes={classes}
        style={this.getStyleObject()}
        translate={translate}
        showForwardAndBackArrows={showForwardAndBackArrows}
        showNavigationDots={showNavigationDots}
        showThumbnails={showThumbnails}
        autoplay={!dontAutoPlay && autoplay}
        transitionTime={transitionTime}
        // eslint-disable-next-line react-hooks/rules-of-hooks
        images={useCacheableImages(imageLookup, config.images)}
        activeImageIndexId={activeImageIndexId}
        colorPalette={style?.palette}
        displayPlaceholderText={displayPlaceholderText}
        breakpoints={breakpoints}
        supportsWebp={supportsWebp}
        supportLossy={supportLossy}
      />
    );
  }
}
