/* eslint-disable react/no-multi-comp */
import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId, injectTestId } from '@cvent/nucleus-test-automation';
import { FormElement, WithFormProps } from '../FormElement';

/*
(ts-migrate) TODO: Migrate the remaining prop types
...FormElement.propTypes
*/
type OwnToggleRadioButtonProps = {
  classes?: any;
  display?: string;
  options: any[];
  style?: Record<string, unknown>;
  selectedValue?: string;
  disabled?: boolean;
};

type ToggleRadioButtonProps = OwnToggleRadioButtonProps & typeof ToggleRadioButton.defaultProps;

export class ToggleRadioButton extends React.Component<ToggleRadioButtonProps> {
  static displayName = 'ToggleRadioButton';
  static defaultProps = {
    required: false,
    disabled: false
  };

  constructor(props: ToggleRadioButtonProps) {
    super(props);
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'setFocus' does not exist on type 'Readon... Remove this comment to see the full error message
    if (this.props.setFocus) {
      if (typeof document !== 'undefined') {
        document
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<T... Remove this comment to see the full error message
          .getElementsByName(this.props.name ? this.props.name : this.props.fieldName)[0]
          .focus();
      }
    }
  }

  onChange(value: any) {
    if (this.props.disabled) {
      return;
    }

    // @ts-expect-error ts-migrate(2339) FIXME: Property 'onChange' does not exist on type 'Readon... Remove this comment to see the full error message
    this.props.onChange(this.props.fieldName, value);
  }

  render() {
    const selected = this.props.selectedValue;
    const options = this.props.options;
    const required = this.props.required;
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'name' does not exist on type 'Readonly<T... Remove this comment to see the full error message
    const name = this.props.name ? this.props.name : this.props.fieldName;
    const element1Id = name + '_1';
    const element2Id = name + '_2';
    const elem1 = options[0];
    const elem2 = options[1];

    const inputProps = {
      ...resolve(this.props, 'toggle', 'toggleRound'),
      name,
      type: 'radio',
      required: this.props.required,
      disabled: this.props.disabled
    };

    return (
      <ul
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type 'Readonly<Tog... Remove this comment to see the full error message
        id={this.props.id || this.props.fieldName}
        style={this.props.style}
        {...resolveTestId(this.props)}
        className={resolve(this.props, 'radioToggle', 'toggleItem', this.props.display).className}
      >
        <li
          {...resolve(this.props, 'toggleLabel')}
          {...injectTestId(`option-${name}`)}
          style={this.props.disabled ? { opacity: 0.4 } : null}
        >
          <label htmlFor={element1Id}>
            <span {...resolve(this.props, 'toggleOption', 'toggleOption1')}>{elem1.name}</span>
          </label>
          <input
            {...inputProps}
            id={element1Id}
            defaultValue={elem1.value}
            checked={selected === elem1.value}
            onChange={this.onChange.bind(null, elem1.value)}
            required={required}
          />
          {/* eslint-disable jsx-a11y/interactive-supports-focus, jsx-a11y/no-static-element-interactions,
            jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */}
          <div
            {...resolve(this.props, 'switch')}
            onClick={this.onChange.bind(null, selected === elem1.value ? elem2.value : elem1.value)}
          />
          {/* eslint-enable */}
          <label htmlFor={element2Id}>
            <span {...resolve(this.props, 'toggleOption', 'toggleOption2')}>{elem2.name}</span>
          </label>
          <input
            {...inputProps}
            id={element2Id}
            defaultValue={elem2.value}
            checked={selected === elem2.value}
            onChange={this.onChange.bind(null, elem2.value)}
            required={required}
          />
        </li>
      </ul>
    );
  }
}

type ToggleRadioButtonFormElementProps = WithFormProps & {
  children?: React.ReactNode;
  display?: string;
  style?: Record<string, unknown>;
};

/**
 FormElement wrapper around toggle. This is the default export.
 **/
const ToggleRadioButtonFormElement = (props: ToggleRadioButtonFormElementProps) => {
  const { children, ...rest } = props;
  return (
    <FormElement {...rest} isFieldSet>
      {/* @ts-expect-error ts-migrate */}
      <ToggleRadioButton {...rest} {...injectTestId('input')} />
      {children}
    </FormElement>
  );
};
ToggleRadioButtonFormElement.displayName = 'ToggleRadioButtonFormElement';

/**
 #### Data structure for the "options" property

 ```js
  [
    {name: 'optionNameA', value: 'optionValueA'},
    {name: 'optionNameB', value: 'optionValueB'}
  ]
 ```

 **/

export { ToggleRadioButtonFormElement };
