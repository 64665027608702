import React from 'react';

import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import merge from 'lodash/merge';

import { injectTestId } from '@cvent/nucleus-test-automation';
import { connect } from 'react-redux';
import { defaultMemoize } from 'reselect';

import { ParentWidgetsContext } from '../../context/ParentWidgetsContext';
import { ThemeImagesContext } from '../../context/ThemeImagesContext';
import { fetchDatatagResolutions } from '../../redux/modules/text';
import { EditorTabTypes } from '../../utils/editor/EditorTabTypes';
import { getSelectedEditorPanelTab, isSelectedItem } from '../../utils/editor/selectors';
import { setJSONValue } from '../../utils/fields/setJSONValue';
import { resetStylesToDefault } from '../../utils/style/resetStylesToDefault';
import { WidgetWrapper } from './WidgetWrapper';
import { isFeatureFlagEnabled } from '@cvent/nucleus-platform';
import { replaceColorWithAltColor } from './replaceColorWithAltColor';

/**
 * Debounce the fetch datatags call across all widgets, there is no need to be calling it that often.
 * A single call for each state change is enough.
 * We store a weak map of textResolver references to their corresponding debounced fetch functions. We want
 * the calls to be debounced between widgets, but still make it be possible to have multiple pieces of content
 * on the page that use different textResolvers if the use case ever comes up.
 */
const fetchDatatagsFunctions = new WeakMap();
function createNewFetchDatatagsFunction(textResolver: any, fetchResolutions: any) {
  return debounce(() => {
    fetchResolutions(textResolver);
  }, 50);
}

type Props = {
  widget: any;
  widgetMetaData: any;
  parentStyle?: any;
  widgetFactory?: any;
  guestTranslate?: (...args: any[]) => any;
  isGuest?: boolean;
  textResolver?: any;
  fetchDatatagResolutions?: (...args: any[]) => any;
  customFonts?: any;
  imageLookup?: any;
  browserFeatures?: {
    supportsWebp?: boolean;
  };
  pseudoState?: string;
  isSelectedItem?: boolean;
  currentElementKey?: string;
  widgetConfig?: any;
  children?: React.ReactNode;
  // If the widget is currently selected in site editor canvas, the value is true.
  // Site editor specific prop, used in ThemeableComponent to show
  // what the widget looks like when pseudo state styles are applied.
  isSelected: boolean;
  reviewMode: boolean;
  blocksThemingEnabled?: boolean;
  device?: string;
};

type State = any;

/**
 * Wrapper component around any widget content to load the widget
 * instance and process parent widgets / styling.
 */
export class WidgetRendererBase extends React.Component<Props, State> {
  static displayName = 'WidgetRenderer';
  cancelLoadWidget: any;
  constructor(props: Props) {
    super(props);
    this.cancelLoadWidget = noop;
    this.state = {
      widgetComponent: null,
      prevWidgetType: props.widget.widgetType
    };
  }
  static getDerivedStateFromProps(props: any, state: any) {
    // When a widget is being added or moved, we must load the component
    // for the new widget type. The widgetComponent state is temporarily
    // set to null while the new widget type is loading to prevent an
    // intermediate 'undefined' rendering state for the widget.
    return null;
  }
  componentWillUnmount() {
    this.cancelLoadWidget();
  }
  componentDidMount() {
    this.loadWidget(this.props.widgetFactory, this.props.widget.widgetType);
    this.fetchDatatags();
  }
  componentDidUpdate(prevProps: Props) {
    this.fetchDatatags();
    if (prevProps.widget.widgetType !== this.props.widget.widgetType) {
      this.loadWidget(this.props.widgetFactory, this.props.widget.widgetType);
    }
  }
  // If a textResolver prop exists, creates a debounced fetch datatags function to map to it (if not created yet)
  // and calls it.
  fetchDatatags = () => {
    if (this.props.textResolver && this.props.fetchDatatagResolutions) {
      if (!fetchDatatagsFunctions.has(this.props.textResolver)) {
        fetchDatatagsFunctions.set(
          this.props.textResolver,
          createNewFetchDatatagsFunction(
            this.props.textResolver,
            this.props.fetchDatatagResolutions
          )
        );
      }
      fetchDatatagsFunctions.get(this.props.textResolver)();
    }
  };

  // Resolves the widget's internal style settings with any style settings
  // from all of its parents, and merge them all into one style object.
  getWidgetStyle = (parentWidgets: any) => {
    const { widget, parentStyle, customFonts } = this.props;
    return this._getWidgetStyle(widget, parentStyle, parentWidgets, customFonts);
  };
  _getWidgetStyle = defaultMemoize(
    (widget: any, parentStyle: any, parentWidgets: any, customFonts: any) => {
      const parentWidgetStyle = merge(
        {},
        ...parentWidgets.map((parent: any) => parent.config.style)
      );
      const widgetStyle = widget.config.style;
      if (
        (isFeatureFlagEnabled('BlocksTheming') || this.props.blocksThemingEnabled) &&
        parentStyle.isColorStyle
      ) {
        replaceColorWithAltColor(widgetStyle);
      }

      return merge(
        {},
        resetStylesToDefault(parentStyle),
        resetStylesToDefault(parentWidgetStyle),
        widgetStyle,
        { customFonts }
      );
    }
  );

  // Get the parent widgets array for any child widgets of this widget. Should be used as the theme context
  // provider value.
  getChildParentWidgets = (parentWidgets: any) => {
    const { widget } = this.props;
    return this._getChildParentWidgets(widget, parentWidgets);
  };
  _getChildParentWidgets = defaultMemoize((widget: any, parentWidgets: any) => {
    return parentWidgets.concat([widget]);
  });

  loadWidget = (factory: any, widgetType: any) => {
    this.cancelLoadWidget();
    let loadingWasCancelled = false;
    this.cancelLoadWidget = () => {
      loadingWasCancelled = true;
    };
    factory.loadComponent(widgetType).then((widgetComponent: any) => {
      if (!loadingWasCancelled) {
        this.setState({ widgetComponent });
      }
    });
  };

  render() {
    const {
      widget,
      widgetMetaData,
      guestTranslate,
      children,
      isGuest,
      pseudoState,
      currentElementKey,
      isSelected,
      imageLookup,
      browserFeatures,
      widgetConfig,
      reviewMode,
      device
    } = this.props;

    // Check if the widget component is loaded yet.
    if (!this.state.widgetComponent) {
      return <div />;
    }
    return (
      <ParentWidgetsContext.Consumer>
        {parentWidgets => (
          <ParentWidgetsContext.Provider value={this.getChildParentWidgets(parentWidgets)}>
            <ThemeImagesContext.Consumer>
              {themeImages => {
                const widgetProps = {
                  ...injectTestId(`widget-${widget.widgetType}-${widget.id}`),
                  isGuest,
                  id: widget.id,
                  config: widgetConfig || widget.config,
                  layout: widget.layout,
                  type: widget.widgetType,
                  metaData: widgetMetaData,
                  style: this.getWidgetStyle(parentWidgets),
                  translate: guestTranslate,
                  themeImages,
                  pseudoState,
                  isSelectedItem: isSelected,
                  imageLookup,
                  browserFeatures,
                  currentElementKey,
                  device
                };
                // Only render wrapper when in review mode
                return reviewMode ? (
                  <WidgetWrapper
                    isGuest={isGuest}
                    widget={widget}
                    widgetMetaData={widgetMetaData}
                    translate={guestTranslate}
                    device={device}
                  >
                    <this.state.widgetComponent {...widgetProps}>
                      {children}
                    </this.state.widgetComponent>
                  </WidgetWrapper>
                ) : (
                  <this.state.widgetComponent {...widgetProps}>
                    {children}
                  </this.state.widgetComponent>
                );
              }}
            </ThemeImagesContext.Consumer>
          </ParentWidgetsContext.Provider>
        )}
      </ParentWidgetsContext.Consumer>
    );
  }
}

/**
 * Function to configure datatag replacement in the canvas.
 */
function getDatatagSpecificProps(state: any, props: any) {
  // To enable datatags, either explicitly pass in textResolver and translateWithDatatags props,
  // or the system will attempt to match the passed in guestTranslate prop with the translate function
  // on state.guestText (if it exists) or state.text (if it exists) and use the textResolver and
  // translateWithDatatags associated with it instead.
  let textResolver;
  let translateWithDatatags;
  if (props.textResolver && props.translateWithDatatags) {
    textResolver = props.textResolver;
    translateWithDatatags = props.translateWithDatatags;
  } else if (
    state.guestText &&
    state.guestText.translateWithDatatags &&
    state.guestText.translate.renderId &&
    state.guestText.translate.renderId === props.guestTranslate.renderId
  ) {
    textResolver = state.guestText.resolver;
    translateWithDatatags = state.guestText.translateWithDatatags;
  } else if (
    state.text &&
    state.text.translateWithDatatags &&
    state.text.translate.renderId &&
    state.text.translate.renderId === props.guestTranslate.renderId
  ) {
    textResolver = state.text.resolver;
    translateWithDatatags = state.text.translateWithDatatags;
  }

  // If no text resolver found or there is no translateWithDatatags function, just return nothing.
  if (!textResolver || !translateWithDatatags) {
    return {};
  }

  return {
    textResolver,
    guestTranslate: translateWithDatatags
  };
}

/**
 * This function returns props that only exist and should be used in site editor.
 * They are passed down to ThemeableComponent to show
 * what the widget looks like when pseudo state tab is selected.
 */
function getEditorSpecificProps(state: any, props: any) {
  if (!state.editor) {
    return;
  }

  const pseudoState = state.editor.editorTabFields.pseudoStateTabs;
  const isSelected = isSelectedItem(state.editor, props.widget.id);
  const navTab = getSelectedEditorPanelTab(state.editor) || {};
  const { navigationStack } = navTab;
  const navStackKey =
    navigationStack &&
    navigationStack[navigationStack.length - 1] &&
    navigationStack[navigationStack.length - 1].key;
  const currentElementKey = navStackKey && navStackKey.split('.').pop();

  return {
    pseudoState,
    isSelected,
    currentElementKey
  };
}

const getWidgetConfigSpecificProps = defaultMemoize((widget: any, localization: any) => {
  // Localize user text in widget data
  return Object.entries(localization).reduce((newConfig, [key, value]) => {
    if (key.startsWith(`website.layoutItems.${widget.id}.config.`)) {
      const newKey = key.replace(`website.layoutItems.${widget.id}.config.`, '');
      return setJSONValue(newConfig, newKey, value);
    }
    return newConfig;
  }, widget.config);
});

export const WidgetRenderer = connect(
  (state: any, props: any) => {
    const { localizedUserText, reviewModeEnabled } = state;
    const { isGuest } = props;

    let isReviewMode = false;

    if (isFeatureFlagEnabled('Comments')) {
      isReviewMode = isGuest
        ? reviewModeEnabled
        : reviewModeEnabled && state.editor?.selectedEditorPanelTab === EditorTabTypes.REVIEW;
    }

    return {
      ...getDatatagSpecificProps(state, props),
      ...getEditorSpecificProps(state, props),
      widgetConfig:
        localizedUserText && localizedUserText.localizations[localizedUserText.currentLocale]
          ? getWidgetConfigSpecificProps(
              props.widget,
              localizedUserText.localizations[localizedUserText.currentLocale]
            )
          : props.widget.config,
      customFonts: state.customFonts,
      imageLookup: state.imageLookup,
      browserFeatures: state.browserFeatures,
      reviewMode: isReviewMode,
      blocksThemingEnabled: state.blocksThemingEnabled
    };
  },
  { fetchDatatagResolutions }
)(WidgetRendererBase as any);
