import React from 'react';
import { resolve } from '@cvent/nucleus-dynamic-css';
import VideoStyle from './Video.less';

type Props = {
  videoId?: string;
  translate?: (...args: any[]) => any;
};

export const EmbeddedLiveStreamVideo = (props: Props) => {
  const { videoId, translate } = props;
  const videoStyles = { classes: VideoStyle };

  const liveStreamURL = `//livestream.com/${videoId}/player?&enableInfoAndActivity=true&autoPlay=false&mute=false`;
  const embedId = `ls_embed_${videoId}`;
  return (
    <div {...resolve(videoStyles, 'liveStreamContainer')}>
      <iframe
        id={embedId}
        src={liveStreamURL}
        frameBorder="0"
        scrolling="no"
        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        aria-label={translate('EventWidgets_LiveStream__resx')}
        allowFullScreen
      ></iframe>
    </div>
  );
};
