/**
 * Sets the browser window title to props.title
 */
const Title = (props: any) => {
  if (props.title && global && global.document) {
    global.document.title = props.title;
  }
  return null;
};

export { Title };
