import { SET_SESSION_TIMEOUT_DIALOG_CONFIG } from './constants';

export function sessionTimeoutDialogReducer(state: any, action: any) {
  switch (action.type) {
    case SET_SESSION_TIMEOUT_DIALOG_CONFIG: {
      return {
        ...state,
        ...action.payload.config
      };
    }
    default: {
      return state;
    }
  }
}
