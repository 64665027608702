// Lists of regex's pulled from https://github.com/monperrus/crawler-user-agents/blob/master/crawler-user-agents.json
// and trimmed to reduce bundle size. In splunk google bot was the primary offender
// of the errors logged at around 95%.
const botRegexs = [
  'Googlebot\\/',
  'Googlebot-Mobile',
  'Googlebot-Image',
  'Googlebot-News',
  'Googlebot-Video',
  'AdsBot-Google([^-]|$)',
  'AdsBot-Google-Mobile',
  'Feedfetcher-Google',
  'Mediapartners-Google',
  'Mediapartners \\(Googlebot\\)',
  'APIs-Google'
];

/**
 * This code only needs to fire if there was a failure. To alleviate loading
 * a large regex it is deferred until there is an issue.
 */
let botRegex: RegExp;

export default (navigator?: { userAgent?: string }): boolean => {
  // Prevent server side errors if not defined.
  if (typeof navigator === 'undefined') {
    return false;
  }

  botRegex = botRegex || new RegExp(botRegexs.join('|'));
  return !!(navigator && navigator.userAgent && botRegex.test(navigator.userAgent));
};
