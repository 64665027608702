import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import Icon from '@cvent/nucleus-icon';

import { EditorThemeContext } from '../../../context/EditorThemeContext';

type Props = {
  text?: string | ((...args: any[]) => any);
  description?: string;
  selectedValidation?: any;
  isRequired?: boolean;
  addLabelStyle?: boolean;
  translate?: (...args: any[]) => any;
};

/**
 * A label component for use in editor fields.
 */
export class FieldLabel extends React.PureComponent<Props> {
  static displayName = 'FieldLabel';
  constructor(props: Props) {
    super(props);
  }
  render() {
    const { text, selectedValidation, isRequired, translate, addLabelStyle } = this.props;
    if (text === undefined) {
      return false;
    }
    return (
      <EditorThemeContext.Consumer>
        {editorTheme => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'Validations' does not exist on type '{}'... Remove this comment to see the full error message
          const { Validations, Forms } = editorTheme;
          let label = (
            <span className={addLabelStyle ? Forms.label.label : null}>
              {/* @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message */}
              {typeof text === 'function' ? text(translate) : translate(text)}
            </span>
          );
          // Add required indicator.
          if (isRequired) {
            label = (
              <React.Fragment>
                {label}
                <span {...resolve(Validations, 'requiredField')}>*</span>
              </React.Fragment>
            );
          }
          // Add selected validation indicator.
          if (selectedValidation) {
            const level = selectedValidation.level;
            label = (
              <span {...resolve(Validations, 'indicator', level || 'error')}>
                <Icon icon={level || 'error'} />
                {label}
              </span>
            );
          }
          return label;
        }}
      </EditorThemeContext.Consumer>
    );
  }
}
