import React from 'react';
import LoadingIcon from '@cvent/carina/components/LoadingIcon';

type Props = {
  onClick: () => void;
  translate: (text: string) => string;
  transkey: string;
  retrying: boolean;
};

export const getActionLink = ({ onClick, translate, transkey, retrying }: Props) => {
  return retrying ? (
    <LoadingIcon size="m" />
  ) : (
    <a
      href="#"
      onClick={e => {
        e.preventDefault();
        onClick();
      }}
    >
      {translate(transkey)}
    </a>
  );
};
