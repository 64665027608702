import merge from 'lodash/merge';

/**
 * Adds all the chosen custom class names into one string to be used
 * by resolve to reference the style object added in the head style object
 * with id='customCSSStyles'
 */

export function getCustomCSSStyles(styleObject: any, customStyleSettings: any) {
  const mergedStyleObject = merge({}, styleObject, customStyleSettings);
  if (!mergedStyleObject || !mergedStyleObject.customCSSStyles) {
    return {};
  }

  return { customClass: mergedStyleObject.customCSSStyles.join(' ').trim() };
}
