import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

type Props = {
  text?: string;
  date?: string;
  // Used in resolve function // TODO: determine neccesary fields for classes
  classes?: Record<string, unknown>;
  // Used in resolve function // TODO: determine neccesary fields for style
  style?: Record<string, unknown>;
  time?: string;
  timeZone?: string;
  displayFormat?: string;
};

/**
 * The basic date widget UI implementation.
 */
export class DateComponent extends React.Component<Props> {
  static displayName = 'DateComponent';
  render() {
    const { text, date, time, timeZone, displayFormat } = this.props;
    const displayTime = displayFormat === 'dateTime' || displayFormat === 'dateTimeTimezone';
    const displayTimezone = displayFormat === 'dateTimeTimezone';
    return (
      <div {...resolveTestId(this.props)} {...resolve(this.props, 'container')}>
        <div {...resolve(this.props, 'text')} dangerouslySetInnerHTML={{ __html: text }} />
        <div {...resolve(this.props, 'dateTimeTimeZone')}>
          {date}
          {displayTime ? <span {...resolve(this.props, 'time')}>{time}</span> : null}
          {displayTimezone ? <span {...resolve(this.props, 'timeZone')}>{timeZone}</span> : null}
        </div>
      </div>
    );
  }
}
