import { createRef } from 'react';

/**
 * To make the widget appear on Preview Mode since, this is inside an Iframe
 */
export function createReference(this: any) {
  this.wrapper = createRef();
  try {
    const node = this.wrapper.current;
    if (node) {
      this.window = node && node.ownerDocument.defaultView;
    } else {
      this.window = window;
    }
  } catch (e) {
    this.window = window;
  }
}
