/* eslint-disable  no-nested-ternary */

/** Return 6 or if possible the 3 character hex code for the rgb color passed in **/
export function rgbToHexString(rgb: any) {
  const { r, g, b } = rgb;
  const hexColor = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
  return '#' + hexColor;
}

/** Create short hex version if possible **/
export function shortenHex(hex: any) {
  let convertedHex = hex;
  const [c1, c2, c3, c4, c5, c6] = hex.split('');
  if (c1 === c2 && c3 === c4 && c5 === c6) {
    // return 3 character version of color
    convertedHex = `#${c1}${c3}${c5}`;
  }
  return convertedHex;
}

/** convert a string to an RgbColor or  a ColorError **/
export function hexStringToRgb(hex: any) {
  // const isShortHex = /^#[0-9A-F]{3}$/i.test(hex);
  const isShortHex = false;
  const isLongHex = /^#[0-9A-F]{6}$/i.test(hex);
  if (!isLongHex && !isShortHex) {
    return { error: 'INVALID_HEX_COLOR' };
  }
  let hexColor = hex.replace('#', '');
  if (isShortHex) {
    const [c1, c2, c3] = hexColor.split('');
    hexColor = `${c1}${c1}${c2}${c2}${c3}${c3}`;
  }
  const newHex = parseInt(hexColor.replace('#', ''), 16);
  const r = newHex >> 16;
  const g = (newHex >> 8) & 0xff;
  const b = newHex & 0xff;
  return { r, g, b };
}
/** test 2 RgbColors for equality **/
export function rgbColorEqual(rgb1: any, rgb2: any) {
  const components = ['r', 'g', 'b'];

  for (const c of components) {
    if (rgb1[c] !== rgb2[c]) {
      return false;
    }
  }
  return true;
}
/** test 2 HslColors for equality **/
export function hslColorEqual(hsl1: any, hsl2: any) {
  const components = ['h', 's', 'l'];

  for (const c of components) {
    if (hsl1[c] !== hsl2[c]) {
      return false;
    }
  }
  return true;
}

// internal helper function for hslToRgb
function hue2rgb(p: any, q: any, t: any) {
  let adjustedT = t;
  if (t < 0) adjustedT += 1;
  if (t > 1) adjustedT -= 1;

  if (adjustedT < 1 / 6) return p + (q - p) * 6 * adjustedT;
  if (adjustedT < 1 / 2) return q;
  if (adjustedT < 2 / 3) return p + (q - p) * (2 / 3 - adjustedT) * 6;
  return p;
}

/** convert a hsl colr to an rgb (255) color **/
export function hslToRgb(hsl: any) {
  const { h, s, l } = hsl;
  let r;
  let g;
  let b;

  if (s === 0) {
    r = g = b = l;
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return { r: Math.round(r * 255), g: Math.round(g * 255), b: Math.round(b * 255) };
}

/** convert an 0-255 color to a HslColor **/
export function rgbToHsl(rgb: any) {
  let { r, g, b } = rgb;
  r /= 255;
  g /= 255;
  b /= 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  let h = (max + min) / 2;
  let s = h;
  const l = h;
  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }

  return { h, s, l };
}

/** Returns rgb of a color that has been reduced by p percentage **/
export function lightenRGB(r: any, g: any, b: any, p: any) {
  const lightenR = r + (255 - r) * p;
  const lightenG = g + (255 - g) * p;
  const lightenB = b + (255 - b) * p;

  return { lightenR, lightenG, lightenB };
}
