import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { resolveTestId } from '@cvent/nucleus-test-automation';

type Props = {
  label?: string;
  digit: number;
  style?: {
    counter?: any;
    wrapper?: any;
    digitWrapper?: any;
    current?: any;
    top?: any;
    flip?: any;
    flipping?: any;
    digitTop?: any;
    next?: any;
    bottom?: any;
    digit?: any;
    label?: any;
  };
};

type State = any;

/**
# FlipCounter Component
**/
export class FlipCounter extends React.Component<Props, State> {
  static displayName = 'FlipCounter';
  constructor(props: Props) {
    super(props);
    this.state = {
      flipping: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        flipping: true
      });
    });
  }

  componentDidUpdate(prevProps: Props) {
    /* eslint-disable react/no-did-update-set-state */
    // Need to verify if both not numbers becuase equality does not check this.
    if (isNaN(prevProps.digit) && isNaN(this.props.digit)) {
      return;
    }
    if (prevProps.digit === this.props.digit) {
      return;
    }
    this.setState({ flipping: false });
    setTimeout(() => this.setState({ flipping: true }), 20);
  }

  render() {
    return (
      <li {...resolveTestId(this.props)} {...resolve(this.props, 'counter')}>
        <div {...resolve(this.props, 'wrapper')}>
          <div {...resolve(this.props, 'digitWrapper')}>
            <div
              {...resolve(
                this.props,
                'current',
                'top',
                'flip',
                this.state.flipping ? 'flipping' : ''
              )}
            >
              <div {...resolve(this.props, 'digitTop')}>
                <span>{this.props.digit + 1 === 60 ? 0 : this.props.digit + 1}</span>
              </div>
            </div>
            <div {...resolve(this.props, 'next', 'top', 'flip')}>
              <div {...resolve(this.props, 'digitTop')}>
                <span>{this.props.digit}</span>
              </div>
            </div>
            <div {...resolve(this.props, 'current', 'bottom', 'flip')}>
              <div {...resolve(this.props, 'digit')}>
                <span>{this.props.digit + 1 === 60 ? 0 : this.props.digit + 1}</span>
              </div>
            </div>
            <div
              {...resolve(
                this.props,
                'next',
                'bottom',
                'flip',
                this.state.flipping ? 'flipping' : ''
              )}
            >
              <div {...resolve(this.props, 'digit')}>
                <span>{this.props.digit}</span>
              </div>
            </div>
          </div>
        </div>
        <span {...resolve(this.props, 'label')}>{this.props.label}</span>
      </li>
    );
  }
}
