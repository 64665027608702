import { ReadOnlyContent, ReadOnlyContentProps } from 'nucleus-widgets';
import { connect } from 'react-redux';

export const Content: any = connect(state => {
  return {
    isGuest: true,
    theme: (state as any).website.theme,
    imageLookup: (state as any).imageLookup,
    browserFeatures: (state as any).browserFeatures,
    layoutItems: (state as any).website.layoutItems,
    widgetFactory: (state as any).widgetFactory,
    guestTranslate: (state as any).text.translate
  };
})(ReadOnlyContent as unknown as React.ComponentType<ReadOnlyContentProps>);
