import PropTypes from 'prop-types';

import { LOCATION, CHECKBOX } from './Constants';

export const tagsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    /* Name of the tag to display */
    displayName: PropTypes.string.isRequired,
    /* Font color of this tag */
    textColor: PropTypes.string.isRequired,
    /* Background color to use */
    backgroundColor: PropTypes.string.isRequired
  })
);

export const calendarItemPropTypes = PropTypes.shape({
  id: PropTypes.string,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date),
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  location: PropTypes.string,
  timeZone: PropTypes.string,
  isWebinar: PropTypes.bool,
  type: PropTypes.string,
  tags: PropTypes.arrayOf(tagsPropType)
});

/* data used by month or list component */
export const requiredPropTypes = {
  calendarItems: PropTypes.arrayOf(calendarItemPropTypes).isRequired,
  // TODO: Write a custom prop type checker
  filterSettings: PropTypes.shape({
    showFiltersDialog: PropTypes.bool,
    filters: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        selectedValues: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.object]),
        options: PropTypes.arrayOf(PropTypes.object),
        type: PropTypes.oneOf([LOCATION, CHECKBOX]),
        minLength: PropTypes.number
      })
    ).isRequired,
    headerText: PropTypes.string.isRequired,
    disableClearAll: PropTypes.bool,
    googleMapsApiKey: PropTypes.string
  }),
  searchSettings: PropTypes.shape({
    criteria: PropTypes.shape({
      keyword: PropTypes.string,
      fromDate: PropTypes.object,
      toDate: PropTypes.object,
      currentMonth: PropTypes.object
    }),
    searching: PropTypes.bool,
    searchTakingLonger: PropTypes.bool,
    searchFailed: PropTypes.bool,
    noResultsMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    disableInput: PropTypes.bool
  }).isRequired
};

export const calendarListPropTypes = {
  viewMoreItems: PropTypes.shape({
    isVisible: PropTypes.bool,
    buttonLabel: PropTypes.string,
    isLoading: PropTypes.bool,
    processingLabel: PropTypes.string,
    onClickHandler: PropTypes.func,
    errorMessage: PropTypes.string
  }),
  dateTimeFormat: PropTypes.shape({
    headerFormat: PropTypes.object,
    formatter: PropTypes.func,
    delimiter: PropTypes.string,
    dateFormat: PropTypes.object,
    timeFormat: PropTypes.object
  }).isRequired
};
