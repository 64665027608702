function hideCustomCSSSettings(state: any) {
  if (!state.editorConfig.customCss?.enable || !state?.website?.theme?.customCSS?.enabled) {
    return true;
  }
  const classNames = state?.website?.theme?.customCSS?.classNames || [];
  return !classNames.length;
}

export const customCSSHeader = {
  component: 'Header',
  hideField: hideCustomCSSSettings,
  options: {
    title: '_fieldCategory_elementCustomCSS__resx'
  }
};

export const customCSSStyling = {
  fieldName: 'customCSSStyles',
  component: 'MultiSelectField',
  hideField: hideCustomCSSSettings,
  options: {
    optionArray: (state: any) => {
      const customCSSClassNames = state?.website?.theme?.customCSS?.classNames || [];

      return customCSSClassNames
        .map((className: any) => {
          return {
            id: className,
            text: className
          };
        })
        .sort((a: any, b: any) =>
          a.text.localeCompare(b.text, state.text.locale, { sensitivity: 'base' })
        );
    },
    multiSelectLabel: '_fieldLabel_customCSS__resx',
    testIdPrefix: 'manage-custom-css-',
    enableSelectAll: true,
    enableDeselectAll: true,
    label: '_fieldLabel_customCSS_Selected__resx'
  }
};

export default {
  customCSSHeader,
  customCSSStyles: customCSSStyling
};
