import { getJSONValue } from '../../utils/fields/getJSONValue';
import { SECTION_ROW } from '../../utils/layout/LayoutTypes';
import { getSectionStyleObject } from '../../utils/style/getSectionStyleObject';
import { getThemeStyleObject } from '../../utils/style/getThemeStyleObject';
import { getWidgetStyleObject } from '../../utils/style/getWidgetStyleObject';

// Gets a value out of the style object for the item being edited. The value must
// exist at the same level as field currently being edited.
export function getStyleObjectValue(state: any, props: any, fieldName: any) {
  const { website } = state;
  const layoutItem = website.layoutItems[props.itemId];
  if (layoutItem) {
    if (layoutItem.layout.type === SECTION_ROW) {
      // Section
      const styleObject = getSectionStyleObject(state, layoutItem);
      return getJSONValue({ style: styleObject }, `${props.fieldNamePrefix}.${fieldName}`);
    }
    // Widget
    const styleObject = getWidgetStyleObject(state, layoutItem, layoutItem.config);
    return getJSONValue({ style: styleObject }, `${props.fieldNamePrefix}.${fieldName}`);
  }
  if (props.itemId === 'global') {
    // Global Theme
    if (props.fieldNamePrefix.startsWith('dialog')) {
      // Dialog
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 2 arguments, but got 1.
      const styleObject = getThemeStyleObject(state);
      return getJSONValue(styleObject, `${props.fieldNamePrefix}.${fieldName}`);
    }
    return getJSONValue(website.theme.global, `${props.fieldNamePrefix}.${fieldName}`);
  }
  // Theme Section
  const styleObject = getThemeStyleObject(state, website.theme.sections[props.itemId]);
  const fieldNamePrefix = props.fieldNamePrefix;
  return getJSONValue(
    // This conditional is to handle a difference in pathing for hover state elements from
    // the color style menu at the theme level.
    fieldNamePrefix.split('.')[0] !== 'style' ? styleObject : { style: styleObject },
    `${fieldNamePrefix}.${fieldName}`
  );
}
