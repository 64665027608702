/**
 * Get the locale that is a case insensitive match in the available locales
 * @param languageCode the language code for the locale to look-up
 * @param countryCode the country code for the locale to look-up
 * @param availableLocales an object where the keys are locale codes and the values are locales
 * @returns {*}
 */
function getAvailableLocale(languageCode: any, countryCode: any, availableLocales: any) {
  const targetLocaleHyphen = `${languageCode}-${countryCode}`.toLowerCase();
  const targetLocaleUnderscore = `${languageCode}_${countryCode}`.toLowerCase();
  const localeCode = Object.keys(availableLocales).find(locale => {
    const lowerCaseLocale = locale.toLowerCase();
    return lowerCaseLocale === targetLocaleHyphen || lowerCaseLocale === targetLocaleUnderscore;
  });
  if (localeCode) {
    return availableLocales[localeCode];
  }
}

/**
 * Default mappings for 2-letter locale codes to 4-letter locale codes
 */
const baseDefaultLocales = {
  bg: 'bg-BG',
  cs: 'cs-CZ',
  da: 'da-DK',
  de: 'de-DE',
  el: 'el-GR',
  en: 'en-US',
  es: 'es-ES',
  fi: 'fi-FI',
  fr: 'fr-FR',
  hr: 'hr-HR',
  hu: 'hu-HU',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  nb: 'no-NO',
  nl: 'nl-NL',
  no: 'no-NO',
  pl: 'pl-PL',
  pt: 'pt-PT',
  ro: 'ro-RO',
  ru: 'ru-RU',
  sk: 'sk-SK',
  sl: 'sl-SL',
  sr: 'sr-RS',
  sv: 'sv-SE',
  th: 'th-TH',
  tr: 'tr-TR',
  uk: 'uk-UA',
  vi: 'vi-VN',
  zh: 'zh-CN'
};

/**
 * Get the locale that is the closest match to the one provided. Will first check for an exact match, then fall-back to
 * default locale for that language.
 * @param localeCode the code for the locale to look-up
 * @param availableLocales an object where the keys are locale codes and the values are locales
 * @param defaultLocales override for the default locale codes for languages
 * @param fallbackLocale locale to default to if no match could be found
 * @returns {*}
 */
export default function getMatchingLocale(
  localeCode: any,
  availableLocales: any,
  defaultLocales = {},
  fallbackLocale?: any
) {
  const [languageCode, countryCode] = localeCode.replace('_', '-').split('-');
  let availableLocale;
  // checks full 4-letter codes
  if (countryCode) {
    availableLocale = getAvailableLocale(languageCode, countryCode, availableLocales);
    if (availableLocale) {
      return availableLocale;
    }
  }
  // checks defaultLocales against a 2-letter languageCode
  const mergedDefaultLocales: { [key: string]: any } = { ...baseDefaultLocales, ...defaultLocales };
  const defaultLocale = mergedDefaultLocales[languageCode];
  if (defaultLocale) {
    const [defaultLanguageCode, defaultCountryCode] = defaultLocale.split('-');
    availableLocale = getAvailableLocale(defaultLanguageCode, defaultCountryCode, availableLocales);
    if (availableLocale) {
      return availableLocale;
    }
  }
  // uses provided fallback locale if available
  if (fallbackLocale) {
    const [fallbackLanguageCode, fallbackCountryCode] = fallbackLocale.split('-');
    availableLocale = getAvailableLocale(
      fallbackLanguageCode,
      fallbackCountryCode,
      availableLocales
    );
    if (availableLocale) {
      return availableLocale;
    }
  }

  throw new Error(`no available locale matching ${localeCode}`);
}
