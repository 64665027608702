import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

let initialized = false;

export function resetInitialization() {
  initialized = false;
}

function initDatadogRum(common: $TSFixMe, config: $TSFixMe, loggerRumContext: $TSFixMe) {
  datadogRum.init({
    ...common,
    applicationId: config.applicationId,
    trackResources: config.trackResources,
    trackLongTasks: config.trackLongTasks,
    trackUserInteractions: config.trackUserInteractions,
    trackViewsManually: config.trackViewsManually,
    defaultPrivacyLevel: config.defaultPrivacyLevel,
    sessionSampleRate: config.sessionSampleRate,
    sessionReplaySampleRate: config.sessionReplaySampleRate,
    allowedTracingUrls: [
      window.location.origin
      // Don't add something like this with making sure it works with CORS: /.*\.cvent\.(com|org|cloud)$/
    ]
  });
  datadogRum.setGlobalContext(loggerRumContext);
}

function initDatadogLogs(common: $TSFixMe, config: $TSFixMe) {
  datadogLogs.init({
    ...common,
    forwardErrorsToLogs: true,
    sessionSampleRate: config.sessionSampleRate,
    ...(config.beforeSend ? { beforeSend: config.beforeSend } : {})
  });
}

export function initLogs(config: $TSFixMe) {
  const { applicationId, clientToken, service, env, version, loggerRumContext, initRUM = true } = config;
  if (!initialized) {
    if (applicationId && clientToken) {
      const common = {
        clientToken,
        service,
        env,
        version,
        trackSessionAcrossSubdomains: true,
        useSecureSessionCookie: true,
        useCrossSiteSessionCookie: true
      };
      initDatadogLogs(common, config);
      if (initRUM) {
        initDatadogRum(common, config, loggerRumContext);
      }
    }
    initialized = true;
  }
  return datadogLogs.logger;
}
