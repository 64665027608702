// ACTION TYPES.
export const RECORD_FACT = 'nucleus-widgets/analytics/RECORD_FACT';

// ACTION
/**
 * Action to record a specific fact outside of the normal redux flow.
 * Generally should be used to explicitly record a user action that has
 * no good normal redux action to passively hook into.
 */
export function recordFact(fact: any) {
  return {
    type: RECORD_FACT,
    payload: {
      fact
    }
  };
}
