/**
 * A file containing utils needed for the initialization of the app
 */

import { DebugLevels, setDebugLevel } from '@cvent/nucleus-logging';
import { loadIntlWithLocale, getMatchingLocale, convertFlatTranslationsToObject } from 'nucleus-text';

import { initializeDatadogRum } from '../utils/loggingAndErrors';

async function merge(...promises: Promise<{ default: Record<string, string | null> }>[]): Promise<Record<string, string>> {
  return Object.assign({}, ...(await Promise.all(promises)).map(m => m.default));
}

type ResolveType = (arg0: Promise<Record<string, string>>) => $TSFixMe;

const locales = {
  calendarGuest: {
    'bg-BG': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/bg-BG.json'), import('nucleus-guestside-site/resources/locales/bg-BG.json'))),
    'cs-CZ': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/cs-CZ.json'), import('nucleus-guestside-site/resources/locales/cs-CZ.json'))),
    'da-DK': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/da-DK.json'), import('nucleus-guestside-site/resources/locales/da-DK.json'))),
    'de-DE': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/de-DE.json'), import('nucleus-guestside-site/resources/locales/de-DE.json'))),
    'el-GR': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/el-GR.json'), import('nucleus-guestside-site/resources/locales/el-GR.json'))),
    'en-US': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/en-US.json'), import('nucleus-guestside-site/resources/locales/en-US.json'))),
    'es-ES': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/es-ES.json'), import('nucleus-guestside-site/resources/locales/es-ES.json'))),
    'fi-FI': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/fi-FI.json'), import('nucleus-guestside-site/resources/locales/fi-FI.json'))),
    'fr-CA': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/fr-CA.json'), import('nucleus-guestside-site/resources/locales/fr-CA.json'))),
    'fr-FR': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/fr-FR.json'), import('nucleus-guestside-site/resources/locales/fr-FR.json'))),
    'hu-HU': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/hu-HU.json'), import('nucleus-guestside-site/resources/locales/hu-HU.json'))),
    'hr-HR': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/hr-HR.json'), import('nucleus-guestside-site/resources/locales/hr-HR.json'))),
    'it-IT': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/it-IT.json'), import('nucleus-guestside-site/resources/locales/it-IT.json'))),
    'ja-JP': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/ja-JP.json'), import('nucleus-guestside-site/resources/locales/ja-JP.json'))),
    'ko-KR': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/ko-KR.json'), import('nucleus-guestside-site/resources/locales/ko-KR.json'))),
    'nb-NO': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/nb-NO.json'), import('nucleus-guestside-site/resources/locales/nb-NO.json'))),
    'nl-NL': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/nl-NL.json'), import('nucleus-guestside-site/resources/locales/nl-NL.json'))),
    'pl-PL': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/pl-PL.json'), import('nucleus-guestside-site/resources/locales/pl-PL.json'))),
    'pt-PT': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/pt-PT.json'), import('nucleus-guestside-site/resources/locales/pt-PT.json'))),
    'pt-BR': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/pt-BR.json'), import('nucleus-guestside-site/resources/locales/pt-BR.json'))),
    'ru-RU': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/ru-RU.json'), import('nucleus-guestside-site/resources/locales/ru-RU.json'))),
    'ro-RO': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/ro-RO.json'), import('nucleus-guestside-site/resources/locales/ro-RO.json'))),
    'sr-RS': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/sr-RS.json'), import('nucleus-guestside-site/resources/locales/sr-RS.json'))),
    'sl-SI': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/sl-SI.json'), import('nucleus-guestside-site/resources/locales/sl-SI.json'))),
    'sk-SK': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/sk-SK.json'), import('nucleus-guestside-site/resources/locales/sk-SK.json'))),
    'sv-SE': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/sv-SE.json'), import('nucleus-guestside-site/resources/locales/sv-SE.json'))),
    'th-TH': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/th-TH.json'), import('nucleus-guestside-site/resources/locales/th-TH.json'))),
    'tr-TR': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/tr-TR.json'), import('nucleus-guestside-site/resources/locales/tr-TR.json'))),
    'uk-UA': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/uk-UA.json'), import('nucleus-guestside-site/resources/locales/uk-UA.json'))),
    'vi-VN': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/vi-VN.json'), import('nucleus-guestside-site/resources/locales/vi-VN.json'))),
    'zh-CHT': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/zh-CHT.json'), import('nucleus-guestside-site/resources/locales/zh-CHT.json'))),
    'zh-CN': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/zh-CN.json'), import('nucleus-guestside-site/resources/locales/zh-CN.json'))),
    'zh-Hant': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/zh-Hant.json'), import('nucleus-guestside-site/resources/locales/zh-Hant.json'))),
    'zh-Hans': (resolve: ResolveType) =>
      resolve(merge(import('../../resources/locales/calendarGuest/zh-Hans.json'), import('nucleus-guestside-site/resources/locales/zh-Hans.json')))
  },
  eventGuest: {
    'bg-BG': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/bg-BG.json'))),
    'cs-CZ': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/cs-CZ.json'))),
    'da-DK': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/da-DK.json'))),
    'de-DE': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/de-DE.json'))),
    'el-GR': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/el-GR.json'))),
    'en-US': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/en-US.json'))),
    'es-ES': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/es-ES.json'))),
    'fi-FI': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/fi-FI.json'))),
    'fr-CA': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/fr-CA.json'))),
    'fr-FR': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/fr-FR.json'))),
    'hu-HU': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/hu-HU.json'))),
    'hr-HR': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/hr-HR.json'))),
    'it-IT': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/it-IT.json'))),
    'ja-JP': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/ja-JP.json'))),
    'ko-KR': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/ko-KR.json'))),
    'nb-NO': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/nb-NO.json'))),
    'nl-NL': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/nl-NL.json'))),
    'pt-BR': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/pt-BR.json'))),
    'pt-PT': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/pt-PT.json'))),
    'pl-PL': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/pl-PL.json'))),
    'ro-RO': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/ro-RO.json'))),
    'ru-RU': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/ru-RU.json'))),
    'sr-RS': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/sr-RS.json'))),
    'sl-SI': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/sl-SI.json'))),
    'sk-SK': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/sk-SK.json'))),
    'sv-SE': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/sv-SE.json'))),
    'th-TH': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/th-TH.json'))),
    'tr-TR': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/tr-TR.json'))),
    'uk-UA': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/en-US.json'))),
    'vi-VN': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/vi-VN.json'))),
    'zh-CHT': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/zh-CHT.json'))),
    'zh-CN': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/zh-CN.json'))),
    'zh-Hant': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/zh-Hant.json'))),
    'zh-Hans': (resolve: ResolveType) => resolve(merge(import('../../resources/locales/eventGuest/zh-Hans.json')))
  }
};

const fallbackLocale = 'en-US';

/**
 * Registers translations using the passed in culture code
 */
export async function registerTranslations(
  calendarCultureCode: $TSFixMe,
  textResolver: { registerTranslations: (arg0: unknown, arg1: string, arg2: $TSFixMe) => void },
  translatedAndCustomizedText: $TSFixMe
) {
  const loadIntlWithLocalePromise = new Promise(resolve => loadIntlWithLocale(calendarCultureCode, resolve));

  const customTranslations = convertFlatTranslationsToObject(translatedAndCustomizedText);

  // register translations for calendar guest and event guest
  const eventTranslationsPromise = new Promise(getMatchingLocale(calendarCultureCode, locales.eventGuest, {}, fallbackLocale));
  const calendarTranslationsPromise = new Promise(getMatchingLocale(calendarCultureCode, locales.calendarGuest, {}, fallbackLocale));
  const loadLocalesPromise = Promise.all([
    textResolver.registerTranslations(await eventTranslationsPromise, 'default', calendarCultureCode),
    textResolver.registerTranslations(await calendarTranslationsPromise, 'default', calendarCultureCode)
  ]);

  await Promise.all([loadIntlWithLocalePromise, loadLocalesPromise]);
  // make sure anything from custom is not overridden
  textResolver.registerTranslations(customTranslations, 'default', calendarCultureCode);
}

/**
 * Sets up logging client if set to true, for logging errors to the server
 */
export function setUpLogging(
  logUiErrorsToServerEnabled: $TSFixMe,
  domain: $TSFixMe,
  datadogEnvironment: $TSFixMe,
  loggerContext: { calendar: { id: $TSFixMe; name: $TSFixMe; version: $TSFixMe; cultureCode: $TSFixMe } },
  isWebWidget: $TSFixMe
) {
  setDebugLevel(DebugLevels.WARN);
  initializeDatadogRum(datadogEnvironment, logUiErrorsToServerEnabled, loggerContext, domain, isWebWidget);
}
