import { formatDateToLocalISOString } from './localDates';

export default class DateInput {
  /**
   * Constructor
   * @param date JS Date object
   * @param statusCode status code for date validity
   * @param dateText text user input for date
   */
  constructor(date, statusCode, dateText) {
    this.date = date;
    this.statusCode = statusCode;
    this.dateText = dateText;
  }
  toJSON() {
    // Serialize to a timezone-less ISO8601 date/time, e.g. "YYYY-MM-DD HH:mm:ss"
    // This is the only format that javascript implementations consistently parse as a local date/time (instead of UTC)
    if (!this.date) {
      return null;
    }
    return formatDateToLocalISOString(this.date);
  }
}
