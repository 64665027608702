import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';
import { injectTestId, resolveTestId } from '@cvent/nucleus-test-automation';
import { InteractiveElement } from 'nucleus-core';
import CventFooterStyles from './Footer.less';
import { ExternalLinkIcon } from '@cvent/carina/components/Icon';
/* eslint-disable no-script-url */
const noOp = 'javascript:void(0);';

type OwnProps = {
  translate: (...args: any[]) => any;
  showCventLogo?: boolean;
  showCopyrightInformation?: boolean;
  showEventManagementSoftware?: boolean;
  showMobileEventApps?: boolean;
  showInquisium?: boolean;
  showEventVenues?: boolean;
  showStrategicMeetingsManagement?: boolean;
  showPrivacyPolicy?: boolean;
  enableHyperlink?: boolean;
  showTermsOfUse?: boolean;
  additionalPolicyLinks?: {
    text: string;
    url: string;
  }[];
  privacyPolicyUrl?: string;
  showComplianceText?: boolean;
  complianceText?: string;
  onComplianceClick?: (...args: any[]) => any;
  showManageCookiePreferences?: boolean;
  onManageCookiePreferences?: () => void;
  manageCookiePreferencesText?: string;
  footerExternalLinkIconText?: string;
  showRedirectIcon: boolean;
};

type Props = OwnProps & typeof Footer.defaultProps;

/**
 * A component that display the cvent's footer content.
 */
export class Footer extends React.Component<Props> {
  static displayName = 'Footer';
  static defaultProps = {
    classes: CventFooterStyles,
    privacyPolicyUrl: 'https://www.cvent.com/en/privacy-policy.shtml',
    showComplianceText: false,
    onComplianceClick: noOp,
    complianceText: 'NucleusWidgets_Ccpa_CCPALinkText__resx',
    showRedirectIcon: false
  };

  getHrefValue = (href: any) => (this.props.enableHyperlink ? href : noOp);

  renderLogo() {
    return (
      <a
        {...resolve(this.props, 'logoImage')}
        href={this.getHrefValue('http://www.cvent.com/')}
        target="_blank"
      >
        Cvent
      </a>
    );
  }

  renderLink(href: any, localizationString: any, key: any) {
    const linkStyle = resolve(this.props, 'link');
    const color = linkStyle.style.color;
    return (
      <li {...resolve(this.props, 'list')} key={key}>
        <a {...resolve(this.props, 'link')} href={this.getHrefValue(href)} target="_blank">
          {this.props.translate(localizationString)}
          <span {...resolve(this.props, 'externalLinkIcon')}>
            <ExternalLinkIcon
              size="s"
              color={color}
              title={
                this.props.footerExternalLinkIconText ||
                this.props.translate('_cventFooter_externalLinkIcon_title__resx')
              }
            />
          </span>
        </a>
      </li>
    );
  }

  renderLinkWithCallback(callback: any, localizationString: any, showRedirectIcon = false) {
    const linkStyle = resolve(this.props, 'link');
    const color = linkStyle.style.color;
    return (
      <InteractiveElement
        {...resolve(this.props, 'callbackFuncLink', 'link')}
        element="span"
        onClick={() => {
          callback();
        }}
      >
        {this.props.translate(localizationString)}
        {showRedirectIcon && (
          <span
            {...resolve(this.props, 'externalLinkIcon')}
            {...injectTestId('external-link-icon')}
          >
            <ExternalLinkIcon
              size="s"
              color={color}
              title={
                this.props.footerExternalLinkIconText ||
                this.props.translate('_cventFooter_externalLinkIcon_title__resx')
              }
            />
          </span>
        )}
      </InteractiveElement>
    );
  }

  render() {
    const {
      translate,
      showCventLogo,
      showCopyrightInformation,
      showEventManagementSoftware,
      showMobileEventApps,
      showInquisium,
      showEventVenues,
      showStrategicMeetingsManagement,
      showPrivacyPolicy,
      showTermsOfUse,
      additionalPolicyLinks,
      privacyPolicyUrl,
      showComplianceText,
      onComplianceClick,
      complianceText,
      showManageCookiePreferences,
      onManageCookiePreferences,
      manageCookiePreferencesText,
      showRedirectIcon
    } = this.props;

    return (
      <footer {...resolveTestId(this.props)} {...resolve(this.props, 'container')}>
        {showComplianceText && (
          <div>
            {this.renderLinkWithCallback(onComplianceClick, complianceText, showRedirectIcon)}
          </div>
        )}
        {showCventLogo && this.renderLogo()}
        {showCopyrightInformation && (
          <span {...resolve(this.props, 'copyright')}>
            {translate('GuestWebsite_Footer_Copyright__resx', { year: new Date().getFullYear() })}
          </span>
        )}
        <ul {...resolve(this.props, 'marketingLinks')}>
          {[
            showEventManagementSoftware &&
              this.renderLink(
                'https://www.cvent.com/en/event-management-software',
                '_cventFooter_eventManagementSoftware__resx',
                'eventManagementSoftware'
              ),
            showMobileEventApps &&
              this.renderLink(
                'https://www.cvent.com/en/event-marketing-management/mobile-event-apps',
                '_cventFooter_mobileEventApps__resx',
                'mobileEventApps'
              ),
            showInquisium &&
              this.renderLink(
                'https://www.cvent.com/en/event-marketing-management/online-survey-software',
                '_cventFooter_surveySoftware__resx',
                'inquisium'
              ),
            showEventVenues &&
              this.renderLink(
                'https://www.cvent.com/venues/',
                '_cventFooter_eventVenues__resx',
                'eventVenues'
              ),
            showStrategicMeetingsManagement &&
              this.renderLink(
                'https://www.cvent.com/en/event-marketing-management/meetings-management',
                '_cventFooter_strategicMeetingsManagement__resx',
                'strategicMeetingsManagement'
              )
          ]}
        </ul>
        <ul {...resolve(this.props, 'policyLinks')}>
          {[
            showTermsOfUse &&
              this.renderLink(
                'http://www.cvent.com/en/eula.shtml',
                '_cventFooter_endUserLicenceAgreement__resx',
                'termsOfUse'
              ),
            showPrivacyPolicy &&
              this.renderLink(
                privacyPolicyUrl,
                '_cventFooter_privacyPolicy__resx',
                'privacyPolicy'
              ),
            ...((additionalPolicyLinks &&
              additionalPolicyLinks.length >= 1 &&
              additionalPolicyLinks.map((link, index) =>
                this.renderLink(link.url, link.text, 'policyLink' + index)
              )) ||
              []),
            showManageCookiePreferences && (
              <li {...resolve(this.props, 'list')} key={manageCookiePreferencesText}>
                {this.renderLinkWithCallback(
                  onManageCookiePreferences,
                  manageCookiePreferencesText
                )}
              </li>
            )
          ]}
        </ul>
      </footer>
    );
  }
}
