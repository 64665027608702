import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';

import { getImageFromLookup } from '../../utils/getImageFromLookup';
import { BaseWidget } from '../BaseWidget';
import { Image } from './Image';
import ClassNames from './Image.less';
import { ThemeableComponentProps } from '../ThemeableComponent';

interface ControlledImageWidgetProps extends ThemeableComponentProps {
  url: string;
  altText: string;
  classes: Record<string, unknown>;
  // If specified, an <a> tag will be added to wrap the <img> tag.
  hyperlink?: string;
  onLoad?: (...args: any[]) => any;
  usePlaceholderImage?: boolean;
}
/**
 * Unlike ImageWidget, this data wrapper widget doesn't get its data from the `config` prop,
 * because the data, such as image url, isn't configurable by the users.
 * Instead, they should be passed in from the connect function in your application wrapping this component.
 */
export class ControlledImageWidget extends BaseWidget<ControlledImageWidgetProps> {
  static displayName = 'ControlledImageWidget';
  static defaultProps = {
    classes: ClassNames,
    usePlaceholderImage: true
  };

  getStyleObject() {
    // The style set in the canvas for the image alignment actually needs to be applied to the container.
    // There is no textAlign style option for the container, so we can just merge the alignment style in here.
    return {
      container: {
        ...super.getStyleObject().container,
        justifyContent: this.getElementText('image').textAlign
      },
      image: this.getElementText('image')
    };
  }
  render() {
    const {
      classes,
      url,
      altText,
      translate,
      hyperlink,
      onLoad,
      imageLookup,
      usePlaceholderImage
    } = this.props;
    const styleObject = this.getStyleObject();
    let { hashedURL: imageUrl } = getImageFromLookup(imageLookup, translate(url));
    if (imageUrl) {
      imageUrl = imageUrl.replace(/&amp;/g, '&');
    }

    return (
      <Image
        {...resolveTestId(this.props)}
        {...(onLoad ? { onLoad } : {})}
        classes={classes}
        style={styleObject}
        imgUrl={imageUrl}
        altText={translate(altText)}
        hyperlink={hyperlink}
        translate={translate}
        usePlaceholderImage={usePlaceholderImage}
      />
    );
  }
}
