import switchLocale from './switchLocale';

/**
 * Returns an array of localized names in a week, starting from Sunday
 *
 * @param locale {string}
 * @param format {string} - the representation of the name, possible values are "short" or "long"
 * @return {Array} - array of localized names starting from Sunday.
 */
const weekdays = {};

export default function getWeekdayNames(locale, format = 'short') {
  if (weekdays[locale] && weekdays[locale][format]) {
    return weekdays[locale][format];
  }
  if (!weekdays[locale]) weekdays[locale] = {};
  weekdays[locale][format] = [];
  const d = new Date(2017, 0, 15);
  const baseDate = d.getDate() - d.getDay();
  for (let i = 0; i < 7; i++) {
    d.setDate(baseDate + i);
    let weekdayName;
    try {
      weekdayName = new Intl.DateTimeFormat(locale, { weekday: format }).format(
        d
      );
    } catch (error) {
      weekdayName = new Intl.DateTimeFormat(switchLocale(locale), {
        weekday: format
      }).format(d);
    }

    weekdays[locale][format].push(weekdayName);
  }
  return weekdays[locale][format];
}
