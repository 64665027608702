import React from 'react';

import { resolveTestId } from '@cvent/nucleus-test-automation';

import { BaseWidget } from '../BaseWidget';
import { LanguageSelector } from './LanguageSelector';
import { ThemeableComponentProps } from '../ThemeableComponent';

interface LanguageSelectorWidgetProps extends ThemeableComponentProps {
  // A list of languages for users to select from, with locale name as the key.
  // {
  //   'en-US': { displayName: 'English' },
  //   'es-ES': { displayName: 'Español' }
  // }
  languageList: Record<
    string,
    {
      displayName?: string;
    }
  >;
  id?: string;
  config?: {
    [x: string | number | symbol]: unknown;
    id: string;
    shared: {
      [y: string | number | symbol]: unknown;
      displayFormat: string;
      selectorLabel: string;
    };
  };
  // Currently selected locale.
  currentLocale?: string;
  // Callback function when a language is selected.
  onSelectLanguage: (...args: any[]) => any;
}
/**
 * Data wrapper for the Language Selector widget.
 */
export class LanguageSelectorWidget extends BaseWidget<LanguageSelectorWidgetProps> {
  static displayName = 'LanguageSelectorWidget';
  getStyleObject() {
    return {
      ...super.getStyleObject(),
      select: this.getElementInlineStyle('dropdown'),
      labelText: this.getElementText('dropdown'),
      link: this.getElementInlineStyle('link')
    };
  }
  render() {
    const { config, translate, currentLocale, onSelectLanguage, languageList } = this.props;

    // Convert the languageList into an array
    // that can be directly consumed by the Select component.
    const languageOptions = Object.entries(languageList)
      .map(([locale, value]) => {
        return {
          value: locale,
          name: translate(value.displayName)
        };
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    return (
      <LanguageSelector
        {...resolveTestId(this.props)}
        style={this.getStyleObject()}
        options={languageOptions}
        currentLocale={currentLocale}
        displayFormat={config?.shared.displayFormat}
        selectorLabel={translate(config?.shared.selectorLabel)}
        onSelectLanguage={onSelectLanguage}
        widgetID={this.props.id}
      />
    );
  }
}
