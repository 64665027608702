/**
 * Migrations for the "siteInfo" object of the website.
 * - Migrate new shared config settings into siteInfo for LanguageSelector widget.
 */
export function siteInfoMigrations(siteInfo: any) {
  if (siteInfo.sharedConfigs.LanguageSelector) {
    return siteInfo;
  }
  return {
    ...siteInfo,
    sharedConfigs: {
      ...siteInfo.sharedConfigs,
      LanguageSelector: {
        displayFormat: 'Dropdown',
        selectorLabel: 'NucleusWidgets_LanguageSelectorWidget_DefaultLabel__resx'
      }
    }
  };
}
