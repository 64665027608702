import React from 'react';

import { resolve } from '@cvent/nucleus-dynamic-css';

type Props = {
  charactersRemaining: number;
  textCallback: (...args: any[]) => any;
  style?: {
    charactersRemaining?: any;
    negative?: any;
  };
};

/**
  RemainingCharacterCount - a remaining character count indicator to use in conjunction
  with text based input elements.
**/
const RemainingCharacterCount = (props: Props) => {
  const { charactersRemaining, textCallback } = props;
  return (
    <p {...resolve(props, 'charactersRemaining', charactersRemaining < 0 ? 'negative' : '')}>
      {textCallback ? textCallback(charactersRemaining) : null}
    </p>
  );
};

RemainingCharacterCount.displayName = 'RemainingCharacterCount';
export { RemainingCharacterCount };
