/**
 * Finds all of the matches in the given input string that match the provided regular expression.
 * @param {RegExp} regExp - Regular expression to find matches on.
 * @param {string} string - The input string to find matches in.
 * @returns {[string]} - the array of matches found in the string.
 */
export default function getAllRegExpMatches(regExp: any, string: any) {
  // eslint-disable-next-line no-param-reassign
  regExp.lastIndex = 0;

  let match = null;
  const matches = [];
  match = regExp.exec(string);
  while (match !== null) {
    matches.push(match[0]);
    match = regExp.exec(string);
  }
  return matches;
}
