/* eslint-disable no-param-reassign */
import { getBrowserDimensions } from './getBrowserDimensions';

/**
 * Centers Element in the current Browser Viewport. (element)
 * Returns position.x and position.y
 * @param {DOMElement} element element you are trying to center
 * @return {object} position Return position of element X/Y
 */
export function centerElement(element: any) {
  const positon: any = [];
  const browserMeasures = getBrowserDimensions(window, document);

  const containerWidth = Math.max(element.clientWidth, element.offsetWidth);
  const containerHeight = Math.max(element.clientHeight, element.offsetHeight);
  const positionTop = browserMeasures.browserHeight / 2 - containerHeight / 2;

  positon.y = positionTop > 0 ? positionTop : 0;
  positon.x =
    browserMeasures.amountScrolledX + browserMeasures.browserWidth / 2 - containerWidth / 2;

  element.style.top = positon.y + 'px';
  element.style.left = positon.x + 'px';

  return positon;
}
